// This hap modal should include only those data w.r.t hap

import { environment } from "src/environments/environment";

export class HAPModal {
    productType = ['New', 'Portability'];
    maritalStatus = ['SINGLE', 'MARRIED']
    options = ['Yes', 'No'];
    LumsumTax = ['Lump Sum', 'Annual'];
    copaymentPercentTaken = {
        hap: ['No', '10%', '20%'],
        hep: ['No', '5%', '10%', '15%', '20%'],
        renewal: ['20', '30', '40'],
        hapRefiling: ['0%', '10%','20%'],
    };
    dependentChildRelationship = ['SON','DAUGHTER','EMPLOYEE SON', 'EMPLOYEE DAUGHTER']
    voluntaryDeductibleRefiling = ['0', '10000', '20000', '25000', '50000']
    asp80DSection = ['Lump Sum', 'Annual'];
    cibilDiscount = ['Yes', 'No'];
    tenurePeriod = ['1 Year', '2 Years', '3 Years'];
    tenurePeriodASP = ['1 Year'];
    emiInstallments = ['Monthly', 'Quarterly', 'Half Yearly']
    zoneCode = '3828';
    zoneData = ['ZONE 1', 'ZONE 2', 'ZONE 3'];
    zoneRefillingData = ['ZONE A', 'ZONE B', 'ZONE C', 'ZONE D'];
    HEPzoneData = ['Zone A', 'Zone B', 'Zone C','Zone D'];
    hapPolicyRange = ['5L-50L', '75L-3Cr'];
    yes = 'Yes';
    no = 'No';
    prodType: "HGS";
    maxSumInsured = 2500000;
    agent = 'Agent';
    subProductType = {
        hap: 42,
        GS: 40,
        asp: 22,
        hb: 20,
        hep: 33,
        he: 32,
        hsp: 31,
        hs: 30,
        orionHap: 4193
    }
    ProductType = [
        { value: "HADV", Key: 42 },
        { value: "HGS", Key: 40 },
        { value: "ASP", Key: 22 },
        { value: "HBooster", Key: 20 },
        { value: "HBooster", Key: 19 },
        { value: "CHI", Key: 33 },
        { value: "CHI", Key: 32 },  // temp code for hep
        { value: "CHI", Key: 31 },
        { value: "CHI", Key: 34 },
        { value: "CHI", Key: 30 },
        { value: "FS", Key: 23 },
        { value: "CS", Key: 38 }
    ];
    memberCount = [
        { key: 42, adultCount: 2, childCount: 3, refiling: false },//hap
        { key: 42, adultCount: 6, childCount: 3, refiling: true },//hap refiling
        { key: 40, adultCount: 2, childCount: 0, refiling: false },//gs
        { key: 22, adultCount: 2, childCount: 3, refiling: false },//asp
        { key: 20, adultCount: 2, childCount: 3, refiling: false },//hb
        { key: 33, adultCount: 2, childCount: 3, refiling: false },//chi
        { key: 32, adultCount: 2, childCount: 3, refiling: false },//chi
        { key: 31, adultCount: 2, childCount: 3, refiling: false },//chi
        { key: 30, adultCount: 2, childCount: 3, refiling: false },//chi
    ]
    subProductTypeHB = "20";
    toggle_option = [{
        "value": true, "label": 'Yes'
    }, { "value": false, "label": 'No' }];
    volDedutible = 0;
    voluntaryDeductible = ['300000', '400000', '500000']
    ipAddress = environment.ipAddress;
    riskCategory = [{
        "key": "Risk Category 1"
    },
    {
        "key": "Risk Category 2"
    },
    {
        "key": "Risk Category 3"
    }
    ]
    addOnCoverIds = {
        three: 3,
        four: 4,
        five: 5,
        six: 6,
        eight: 8,
        nine: 9,// For critical illness temp setting addon 9
        ten: 10,
        eleven: 11,
        twelve: 12,
        thirteen: 13,
        seventeen: 17,
        eighteen: 18,
        fortyfive: 45,
        fortySix: 46,
        fortyseven: 47,
        fortyeight: 48,
        fortynine: 49,
        fifty: 50,
        fiftyone: 51,
        fiftytwo: 52,
        fiftythree: 53,
        fiftyseven: 57,
        fiftyeight: 58,
        sixtythree: 63,
    }
    title = [
        {
            "value": "0",
            "label": "Mrs."
        },

        {
            "value": "1",
            "label": "Mr."
        },

        {
            "value": "2",
            "label": "Ms."
        },

        {
            "value": "21",
            "label": "Mx."
        }
    ]

    // Number of adult dropdown
    // by default 1A should be shown
    adultCount = [
        { 'label': '1 Adult', 'value': '1', 'type': 'adult', 'selected': true, 'hide': false },
        { 'label': '2 Adult', 'value': '2', 'type': 'adult', 'selected': false, 'hide': false },
    ]

    // Number of child dropdown
    // by default 0A should be shown
    childCount = [
        { 'label': '1 Child', 'value': '1', 'type': 'child', 'selected': false, 'hide': false },
        { 'label': '2 Child', 'value': '2', 'type': 'child', 'selected': false, 'hide': false },
        { 'label': '3 Child', 'value': '3', 'type': 'child', 'selected': false, 'hide': true },
    ]

    memberButtonData = [
        { 'label': 'Adult', 'imgSrc': '../../../../assets/icons/Single Adult.svg',minusImgSrc: '../../../../assets/icons/minus.svg',plusImgSrc:'../../../../assets/icons/plus.svg', 'selected': false, 'hide': false, 'incDec': true },
        { 'label': 'Child', 'imgSrc': '../../../../assets/icons/Single Child.svg', minusImgSrc: '../../../../assets/icons/minus.svg',plusImgSrc:'../../../../assets/icons/plus.svg', 'selected': false, 'hide': false, 'incDec': true }
    ]

    // relationship = [
    //     { 'relationship': 'SELF', 'RelationshipID': '1014' },
    //     { 'relationship': 'MOTHER', 'RelationshipID': '1043' },
    //     { 'relationship': 'SPOUSE', 'RelationshipID': '1045' },
    //     { 'relationship': 'FATHER', 'RelationshipID': '1020' }
    // ]

    // 3 --> Maternity cover
    // 4 --> New born
    // 5 --> Vaccination for new born
    // 6 --> Domestic air ambulance
    // 8 --> Personal accident
    // 10 --> Tele Consultations
    // 11 --> World wide cover
    // 12 --> Sum Insured Protector
    // 13 --> Claim Protector
    stateId = '62';
    stateDetails = {
        'stateId': '',
        'cityId': '',
        'cityName': '',
        'stateName': '',
        'pincode': '',
        'isBefitApplicable': ''
    }

    // value: number = 20;
    // options_ = {
    //   showSelectionBar: true,
    //   showTicksValues: true,
    //   stepArray: [
    //     { value: 20},
    //     { value: 30},
    //     { value: 40}
    //   ]
    stepArray = [20, 30, 40]
    // }
    groupOrder = {
        "firstGroup": 1,
        "secondGroup": 2,
        "thirdGroup": 3,
        "fourthGroup": 4,
        "fifthGroup": 5
    };

    groupCategory = {
        Maternity: "Maternity Cover",
        SumInsured: "Sum Insured Protector",
        Critical: "Critical Illness",
        Personal: "Personal Accident Cover",
        ClaimProtector: "Claim Protector",
        CompassionateVisit: "Compassionate Visit",
        NursingAtHome: "Nursing at Home",
        NewBornBaby:"New Born Baby Cover",
        Befit :"Befit",
        SelectedBefitData: "selectedBefitData",
        MaternityForAdultValue: 'maternityForAdult',
        criticalIllnessForAdult: 'criticalIllnessForAdult',
        proposerDobCi:'proposerDobCi',
        VaccinationNewBorn: "Vaccination for new born baby"
    }

    croreValues = {
        oneCrore: 10000000,
        twoCrore: 20000000,
        threeCrore: 30000000,
        fifteenLaks: 1500000
    }

    months = [
        {
            'key': 1,
            'value': 'January'
        },
        {
            'key': 2,
            'value': 'February'
        },
        {
            'key': 3,
            'value': 'March'
        },
        {
            'key': 4,
            'value': 'April'
        },
        {
            'key': 5,
            'value': 'May'
        },
        {
            'key': 6,
            'value': 'June'
        },
        {
            'key': 7,
            'value': 'July'
        },
        {
            'key': 8,
            'value': 'August'
        },
        {
            'key': 9,
            'value': 'September'
        },
        {
            'key': 10,
            'value': 'October'
        },
        {
            'key': 11,
            'value': 'November'
        },
        {
            'key': 12,
            'value': 'December'
        },
    ]
}

export interface AddonStructure {
    checkboxValue: CheckBoxData,
    coverCode: string,
    coverId: number,
    isForAdult: boolean,
    isForChild: boolean,
    isForFemale?: boolean,
    label: string,
    position: number,
    type: string,
    visible?: boolean,
    visiblityAsPerService: boolean,
    group?: string
    disable?: boolean
}

export interface AddonStructure extends AddonDetails {
    group?: string
    coverCode: string,
}
export interface AddonDetails {
    checkboxValue: CheckBoxData,
    coverCode?: string,
    coverId: number,
    isForAdult: boolean,
    isForChild: boolean,
    isForFemale?: boolean,
    label: string,
    position: number,
    type: string,
    visible?: boolean,
    visiblityAsPerService: boolean,
    disable?: boolean
}

export interface BaseAddonDetails {
    category: string,
    hide: boolean,
    showDivider: boolean,
    showMaternityFields: boolean,
    showOccupationFields: boolean,
    showCriticalFields: boolean,
    showCriticalDatePicker: boolean
    showBefitFields: boolean,
}
export interface ParentAddonDetails extends BaseAddonDetails {
    groupName: string,
    childAddons: AddonStructure[]
}
export interface CheckBoxData {
    disabledAddon: boolean, checkedAddon: boolean
}