<form [formGroup]="portabilityForm" *ngIf="!multipleServiceError && showContent">

    <mat-card class="innerbox borderinnerbox pb-4">
        <h3>Portability Details</h3>
        <div class="fieldsdiv stickydiv">
            <div class="row">
                <div [ngClass]="data.type == 'radio' ? 'col-sm-6 radioWidth': 'col-sm-4'"
                    *ngFor="let data of portabilityControls; let i = index">
                    <div class="mb-2">
                        <mat-label class="singlelabel" style="width: 100%;"
                            *ngIf="data.type == 'radio'">{{data.label}}</mat-label>
                    </div>
                    <!-- If type is radio -->
                    <div>
                        <mat-button-toggle-group formControlName="{{data.controlName}}" *ngIf="data.type == 'radio'"
                            name="fontStyle" aria-label="Font Style" class="custom-toggle-group">
                            <mat-button-toggle value="{{radioData.value}}" [disabled]="radioData.disabled" tabindex="1"
                                *ngFor="let radioData of data.value; let j = index" class="custom_togglebtn"
                                (change)="changeInRadioValue($event.value,data.controlName,i)">
                                {{ radioData.label }}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>

                    <!-- If type is dropdown-->
                    <div class="" *ngIf="data.type == 'dropdown'">
                        <mat-label class="input-group__label">{{data.label}}</mat-label>
                        <mat-select tabindex="1" class="input-group__input cstm_select "
                            (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                            formControlName="{{data.controlName}}" [disabled]="data.disabled"
                            placeholder="{{data.placeholder}}" id="{{data.controlName}}"
                            (selectionChange)="changeOfDropdown($event.value,data.controlName,i)">
                            <ng-container *ngFor="let dropdownData of data.value; let last = last">
                                <mat-option value="{{dropdownData.label}}">{{utilityService.capitalize(dropdownData.label)}}</mat-option>
                                <mat-divider *ngIf="!last"></mat-divider>
                            </ng-container>
                        </mat-select>
                        <!-- Error for dropdown -->
                        <mat-error
                            *ngIf="portabilityForm.controls[data.controlName]?.errors?.['required'] && isSubmit && data.type == 'dropdown'">
                            {{data.label}} is required
                        </mat-error>
                    </div>
                    <!-- If type is input -->
                    <div class="" *ngIf="data.type == 'input' && !data.hide">
                        <mat-label class="input-group__label">{{data.label}} </mat-label>
                        <input tabindex="1" class="input-group__input" matInput type="{{data.inputType}}"
                            autocomplete="off" [maxlength]="data.maxLength" [max]="data.maxNumber"
                            [min]="data.minNumber" formControlName="{{data.controlName}}"
                            placeholder="{{data.placeholder}}" [disabled]="data.disabled" id="{{data.controlName}}"
                            (input)="validationOnInputField($event, data.onlyAlphabets, data.onlyNumbers)"
                            (paste)="utilityService.onPaste($event,data.onlyAlphabets,data.onlyNumbers)">
                        <!-- Error for input values -->
                        <mat-error
                            *ngIf="portabilityForm.controls[data.controlName]?.errors?.['required'] && isSubmit && data.type == 'input'">
                            {{data.label}} is required
                        </mat-error>
                    </div>

                    <!-- If type is datepicker -->
                    <div class="" *ngIf="data.type == 'datepicker'">
                        <mat-label class="input-group__label">{{data.label}}</mat-label>
                        <input tabindex="1" class="input-group__input dob" matInput [matDatepicker]="picker1"
                            placeholder="DD/MM/YYYY" formControlName="{{data.controlName}}" [min]="data.minDate"
                            [max]="data.maxDate" [disabled]="data.disabled" (click)="picker1.open()"
                            [placeholder]="data.placeholder" id="{{data.controlName}}" readonly>
                        <mat-datepicker-toggle matIconSuffix [for]="picker1">
                        </mat-datepicker-toggle><mat-datepicker #picker1 touchUi></mat-datepicker>
                        <!-- Error for DatePicker -->
                        <mat-error
                            *ngIf="portabilityForm.controls[data.controlName]?.errors?.['required'] && isSubmit &&data.type == 'datepicker' ">
                            {{data.label}} is required
                        </mat-error>
                    </div>
                </div>
            </div>
        </div>

    </mat-card>
</form>
<app-error-screen *ngIf="multipleServiceError" (refreshService)="onRefresh($event)"></app-error-screen>

<app-custom-footer *ngIf="!multipleServiceError && showContent" [normalButton]="true"
    [buttonData]="[{'label': 'Save & Proceed', 'type': 'primary', 'source': 'portability', 'id': 'f2','disabled': false , 'hide': false }]"
    (buttonClicked)="navigateTo($event)">
</app-custom-footer>