import { environment } from "src/environments/environment";

export class RenewalModal {

    FemaleTitleArray = [
        {
            "id": "0",
            "val": "Mrs."
        },
        {
            "id": "2",
            "val": "Ms."
        },
        {
            "id": "21",
            "val": "Mx."
        }
    ]

    MaleTitleArray = [
        {
            "id": "1",
            "val": "Mr."
        },
        {
            "id": "21",
            "val": "Mx."
        }
    ]

    title = [
        {
            "id": "0",
            "val": "Mrs."
        },

        {
            "id": "1",
            "val": "Mr."
        },

        {
            "id": "2",
            "val": "Ms."
        },

        {
            "id": "21",
            "val": "Mx."
        }
    ]

    heightinFeet = [0, 1, 2, 3, 4, 5, 6, 7];
    heightinInch = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    copay = [20, 30, 40];
    totalPremiumLimitForPanNO = 80000;
    ipAddress = environment.ipAddress;
    cibilDiscount = ['Yes', 'No'];
    asp80DSection = ['Lump Sum', 'Annual'];
    LumsumTax = ['Lump Sum', 'Annual'];
    ProductType = [
        { value: "HADV", Key: 42 },
        { value: "HGS", Key: 40 },
        { value: "ASP", Key: 22 },
        { value: "HBooster", Key: 20 },
        { value: "HBooster", Key: 19 },
        { value: "CHI", Key: 33 },
        { value: "CHI", Key: 32 },  // temp code for hep
        { value: "CHI", Key: 31 },
        { value: "CHI", Key: 30 },
        { value: "FS", Key: 23 },
        { value: "CS", Key: 38 },
        { value: "CHI", Key: 34 }
    ];
    ProductCode = [
        { Key: 42, value: 3828 },
        { Key: 40, value: 3826 },
        { Key: 22, value: 3822 },
        { Key: 20, value: 3817 },
        { Key: 19, value: 3817 },
        { Key: 33, value: 3816 },
        { Key: 32, value: 3816 },  // temp code for hep
        { Key: 31, value: 3816 },
        { Key: 30, value: 3816 },
        { Key: 23, value: 6031 },
        { Key: 38, value: 6012 },
        { Key: 34, value: 3816 },
    ];
    maritalStatus = ['SINGLE', 'MARRIED'];
    options = ['Yes', 'No'];
    businessData = [
        { "value": "1", "label": "Non Resident Entity" },
        { "value": "2", "label": "Foreign company registered in India" },
        { "value": "3", "label": "Foreign LLP" },
        { "value": "4", "label": "Government Department" },
        { "value": "5", "label": "Hindu Undivided Family" },
        { "value": "6", "label": "LLP Partnership" },
        { "value": "7", "label": "Local Authorities" },
        { "value": "8", "label": "Partnership" },
        { "value": "9", "label": "Private Limited Company" },
        { "value": "10", "label": "Proprietorship" },
        { "value": "11", "label": "Others" }];

    gstStatusData = [
        { "value": "41", "label": "ARN Generated" },
        { "value": "42", "label": "Provision ID Obtained" },
        { "value": "43", "label": "To be commenced" },
        { "value": "44", "label": "Enrolled" },
        { "value": "45", "label": "Not applicable" }]
    GSTINOrUIN = [
        'GSTIN', 'UIN'
    ];
    customerTypeData = [
        { "value": "21", "label": "General" },
        { "value": "22", "label": "EOU/STP/EHTP" },
        { "value": "23", "label": "Government" },
        { "value": "24", "label": "Overseas" },
        { "value": "25", "label": "Related parties" },
        { "value": "26", "label": "SEZ" },
        { "value": "27", "label": "Others" }
    ];
    subProductType = {
        hap: 42,
        GS: 40,
        asp: 22,
        hb: 20,
        hep: 33,
        he: 32,
        hsp: 31,
        hs: 30,
        max: 34
    }
    maxSumInsured = 2500000;
    voluntaryDeductible = ['300000', '400000', '500000'];
    productType = [{ key: 1132, value: 'Classic' }, { key: 1135, value: 'Premium' }];
}