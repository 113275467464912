import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LibSessionStorageService {

  // Fetch step data from storage
  private notificationData = new BehaviorSubject<Object>({});
  notificationData$ = this.notificationData.asObservable();

  constructor() {
    const initialData = this.getDataFromStorage();
    // this.storageSubject = new BehaviorSubject(initialData);
  }

  emitNotificationData(data) {
    this.notificationData.next(data);
  }

  private getDataFromStorage(): any {
    const data = sessionStorage.getItem('messages_Array');
    return data ? JSON.parse(data) : null;
  }

  // public getData(): Observable<any> {
  //   // return this.storageSubject.asObservable();
  // }

  public setData(data: any): void {
    sessionStorage.setItem('messages_Array', JSON.stringify(data));
    // this.storageSubject.next(data);
  }

}
