<!-- <app-error-screen *ngIf="errorOccured" (refreshService)="onRefresh($event)"></app-error-screen> -->
<!-- *ngIf="loadFormContent" -->
<section class="container-fluid">
    <div class="row">
        <div class="container panaMaincontainer">
            <div class="floatdiv floatingedit" *ngIf="scrolling">
                <img class="imgScss" src="../../../../../../assets/images/BBR/ScrollToTop.svg" (click)="scrollToTop()">
            </div>

            <div class="marginbox">
                <!-- Icon and title start -->
                <div class="myTransactionTitle" *ngIf="!isSearchVisible">
                    <div class="bbrheading">
                        <span><img src="../../../../../../assets/images/BBR/BBR.svg" alt=""></span>
                        <span>
                            <h3>Health Claims</h3>

                        </span>
                    </div>
                    <div class="d-md-none">
                        <span (click)="openSearchBottomSheet()"><img src="../../../../../../assets/images/BBR/Edit.svg"
                                alt="" /></span>
                        <span (click)="searchFilter()"><img src="../../../../../../assets/images/BBR/Search.svg"
                                alt="" /></span>
                    </div>
                </div>
                <div class="mobilesearch__fieldBBr" *ngIf="isSearchVisible">
                    <div class="mobileinputDivbbr">
                        <input matInput type="text" class="input-group__input placeholder"
                            [cdkTrapFocusAutoCapture]="isSearchVisible" [cdkTrapFocus]="isSearchVisible"
                            [placeholder]="searchPlaceHolder" (input)="queryData()" [(ngModel)]="inputValue">
                        <span class="crossclick" (click)="closeClear()">
                            <img src="../../../../../../assets/images/BBR/Close.svg" alt="">
                        </span>
                    </div>
                </div>
                <!-- Icon and title end -->

                <!-- Desktop View (above 767px) start -->
                <div class="d-none d-md-block">
                    <!-- Input fields card start -->
                    <mat-card class="innerbox borderinnerbox">
                        <div class="row fieldsdiv" [formGroup]="bookFormGroup">

                            <div class="col-sm-3 form-group">
                                <mat-label class=" input-group__label" for="myInput">Product Type</mat-label>
                                <input matInput type="text" class="input-group__input" placeholder="Enter Product Type"
                                    formControlName="productType" id="productType" readonly>
                            </div>

                            <div class="col-sm-3 form-group">
                                <mat-label class="input-group__label">Select Date</mat-label>
                                <mat-date-range-input class="input-group__input dob" [rangePicker]="picker"
                                    (click)="picker.open()" [max]="minMax.maxDate">
                                    <input matStartDate formControlName="startDate" placeholder="Start date"
                                        (keydown)="preventInput($event)">
                                    <input matEndDate formControlName="endDate" placeholder="End date"
                                        (keydown)="preventInput($event)">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker touchUi></mat-date-range-picker>
                            </div>

                            <div class="col-sm-3 form-group">
                                <button class=" search-button" (click)="onSubmit()">
                                    <span>Submit</span>
                                </button>
                            </div>

                        </div>
                    </mat-card>
                    <!-- Input fields card end -->

                </div>
                <!-- Desktop View (above 767px) end -->

                <!-- Info card start -->
                <mat-card class="innerbox borderinnerbox align-items-center p-3 infoCardColor d-none d-md-flex">
                    <div class="row fieldsdiv">
                        <table>
                            <tr>
                                <td class="vertAlign">
                                    <img src="../../../../../../assets/images/BBR/Info.svg" class="infoIcon" alt="Info">
                                </td>
                                <td>
                                    We found a total of <span class="font-weight-bold">{{policyCount ? policyCount : "0"
                                        }}</span>
                                    records with your search parameters on your Health Claim report between
                                    <span class="font-weight-bold">{{startDate | date: 'dd MMM, yyyy'}}</span> and
                                    <span class="font-weight-bold">{{endDate | date: 'dd MMM, yyyy'}}.</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </mat-card>
                <!-- Info card end -->

                <mat-card class="innerbox borderinnerbox p-3 infoCardColor d-md-none">
                    <table class="filterDatatable">
                        <tr>
                            <td [width]="30"><img src="../../../../../../assets/images/BBR/calendarRange.svg" alt="">
                            </td>
                            <td class="filterlabel">Date Range</td>
                            <td [width]="30">
                                <img src="../../../../../../assets/images/BBR/record.svg" alt="">
                            </td>
                            <td class="filterlabel">Records</td>
                            <td class="countLabel">{{policyCount}}</td>
                        </tr>
                        <tr>
                            <td colspan="2" class="countLabel">
                                {{startDate |date:'dd MMM,yyyy'}} -
                                {{endDate | date: 'dd MMM,yyyy'}}
                            </td>
                            <!-- <td [width]="30"> <img src="../../../../../../assets/images/BBR/amount.svg" alt=""></td>
                            <td class="filterlabel">Amount</td>
                            <td class="countLabel">₹{{utilityService.convertStringtoCurrency(businessAmount)}}</td> -->
                        </tr>
                    </table>
                </mat-card>

                <!-- Need to fix -->
                <mat-card class="innerbox borderinnerbox">
                    <div class="fieldsdiv">
                        <div class="productmainDiv">
                            <div class="productdiv">
                                <div class="align-items-center">
                                    <span>Product: <span class="font-weight-bold">{{product}}</span></span>
                                </div>
                            </div>
                            <div class="filterdiv">
                                <div class="d-none d-md-block">
                                    <div class="search__fieldBBr">
                                        <div class="inputDivbbr" [ngClass]="{'searchBorder': showCancel}">
                                            <input matInput type="text" class=" placeholder"
                                                [placeholder]="searchPlaceHolder" (input)="queryData()"
                                                [(ngModel)]="inputValue" (focus)="inputFocus()" (blur)="inputBlur()">
                                            <span class="crossclick pr-2" (click)="clearInputField()">
                                                <img src="../../../../../../assets/images/BBR/desktopCross.svg" alt="">
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <!-- ends here -->
                                <div class=" d-flex align-items-center">
                                    <mat-button-toggle-group class="custom-toggle-group" (change)="onToggle($event)"
                                        [value]="toggleValue">
                                        <mat-button-toggle matTooltip="Table View" class="custom_togglebtn"
                                            value="table"><img src="../../../../../../assets/images/BBR/tablelist.svg"
                                                alt=""></mat-button-toggle>
                                        <mat-button-toggle matTooltip="Card View" class="custom_togglebtn"
                                            value="card"><img src="../../../../../../assets/images/BBR/cardList.svg"
                                                alt=""></mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>

                            </div>
                        </div>
                    </div>
                </mat-card>

                <!-- Table format start -->
                <app-custom-table *ngIf="toggleValue == 'table'" (policyNo)="getClaimStatus($event)"
                    [reportList]="filteredData" [buttonData]="tableBtnInfo" [displayedColumns]="displayCol"
                    [tableSize]="'max-content'"></app-custom-table>
                <!-- Table format end -->

                <!-- Card format start -->
                <app-custom-card *ngIf="toggleValue == 'card'" (policyNo)="getClaimStatus($event)"
                    [reportList]="filteredData" [buttonData]="cardBtnInfo"
                    [displayedColumns]="displayCol"></app-custom-card>
                <!-- Card format end -->
            </div>
        </div>
    </div>
</section>