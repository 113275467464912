import { environment } from "src/environments/environment"
import { AddonDetails, BaseAddonDetails } from "../hap/hap-modal"

export class elevateModal {
    productCode = '3837'
    subProductCode = '1171';
    zone = ['Zone A', 'Zone B', 'Zone C', 'Zone D']
    elevateZone = {
        zoneA: 'Zone A',
        zoneB: 'Zone B',
        zoneC: 'Zone C',
        zoneD: 'Zone D'
    };
    options = ['Yes', 'No'];
    genderData = [
        {
            "label": "MALE",
            "value": "MALE"
        },
        {
            "label": "FEMALE",
            "value": "FMALE"
        },
        {
            "label": "THIRD GENDER",
            "value": "MF"
        }
    ]
    emiInstallments = ['Monthly', 'Quarterly', 'Half Yearly'];
    policyRange = ['5L-50L', '1Cr-Unlimited'];
    policyTenure = ['1 Year', '2 Year', '3 Year'];
    voluntaryDeductibleDetails = [0, 10000, 20000, 30000, 40000, 50000];
    memberButtonData = [
        { 'label': 'Adult', 'imgSrc': '../../../../assets/icons/Single Adult.svg', minusImgSrc: '../../../../assets/icons/minus.svg', plusImgSrc: '../../../../assets/icons/plus.svg', 'selected': false, 'hide': false, 'incDec': true },
        { 'label': 'Child', 'imgSrc': '../../../../assets/icons/Single Child.svg', minusImgSrc: '../../../../assets/icons/minus.svg', plusImgSrc: '../../../../assets/icons/plus.svg', 'selected': false, 'hide': false, 'incDec': true }
    ]
    adultCount = [
        { 'label': '1 Adult', 'value': '1', 'type': 'adult', 'selected': true, 'hide': false },
        { 'label': '2 Adult', 'value': '2', 'type': 'adult', 'selected': false, 'hide': false },
    ]

    childCount = [
        { 'label': '1 Child', 'value': '1', 'type': 'child', 'selected': false, 'hide': false },
        { 'label': '2 Child', 'value': '2', 'type': 'child', 'selected': false, 'hide': false },
        { 'label': '3 Child', 'value': '3', 'type': 'child', 'selected': false, 'hide': true },
    ]

    buildInAddons = [
        { CoverText: "Everywhere Cashless", isAddonSelected: true },
        { CoverText: "In-patient Treatment", isAddonSelected: true },
        { CoverText: "Pre & Post Hospitalisation", isAddonSelected: true },
        { CoverText: "Donor expenses", isAddonSelected: true },
        { CoverText: "Day Care Procedures/Treatment", isAddonSelected: true },
        { CoverText: "In Patient AYUSH Hospitalisation", isAddonSelected: true },
        { CoverText: "Reset Benefit", isAddonSelected: true },
        { CoverText: "Guaranteed Bonus", isAddonSelected: true },
        { CoverText: "Domestic Road Ambulance", isAddonSelected: true },
        { CoverText: "Domiciliary Hospitalisation", isAddonSelected: true },
        { CoverText: "Wellness Program", isAddonSelected: true },
        { CoverText: "In Patient Hospitalisation (Surrogate Mother)", isAddonSelected: true },
        { CoverText: "In Patient Hospitalisation (Oocyte Donor)", isAddonSelected: true },
        { CoverText: "Bariatric Surgery", isAddonSelected: true }
    ];



    coverId = {
        DummyPowerBooster: 99997,
        DummyInfiniteCover: 99897,
        PowerBooster: 97,
        InfiniteCover: 98,
        WorldWideCover: 111,
        CriticalIllness: 115,
        DummyCI: 23456789,
        DummyPA: 45678,
        DummyAnnual: 8765,
        DummyMaternity: 234567,
        Maternity: 107,
        NewBorn: 84,
        VaccinationNewBorn: 85,
        PersonalAccident: 116,
        Befit: 0,
        JumpStart: 91,
        TeleConsultation: 113,
        DummyJumpstart: 988888,
        PEDReduction: 92,
        MaternityWaiting: 93,
        SpecificIllnessReduction: 94,
        WorldWideReduction: 95,
        AnnualHealthCheckups: 88,
        RoomModifier: 96,
        CoPayment: 105,
        VoluntaryDeductible: 106,
        NetworkAdvantage: 103,
        GuaranteedSuperBonus: 90,
    }

    coverDesc = {
        Befit: "Befit",
        PowerBooster: "Power Booster",
        ABCDRider: "JumpStart",
        InfiniteCover: "Infinite Care",
        SelectedBefitData: "selectedBefitData",
        AnnualHealthForAdult: 'annualHealthForAdult',
        AnnualHealthCheckups: 'Annual Health CheckUps',
        WorldWideCover: "World Wide Cover",
        WorldWideWaitingPeriod: "Worldwide cover waiting period reduction option",
        MaternityForAdultValue: 'maternityForAdult',
        criticalIllnessForAdult: 'criticalIllnessForAdult',
        proposerDobCi: 'proposerDobCi',
        Maternity: "Maternity Cover",
        MaternityReduction: "Reduce maternity waiting period",
        SumInsured: "Sum Insured Protector",
        Critical: "Critical Illness",
        Personal: "Personal Accident",
        CompassionateVisit: "Compassionate Visit",
        PEDReductionRider: "PED reduction rider other than Jumpstart",
        SprcificIllnessReduction: 'Reduction in specific illness waiting period',
        NursingAtHome: "Nursing at Home",
        NewBornBaby: "New Born Baby Expenses",
        VaccinationExpenses: "Vaccination Expenses",
        RoomModifier: "Room modifier",
        CoPayment: "Co-Payment",
        TeleConsultation: "Tele consultation",
        VoluntaryDeductible: "Voluntary Deductible",
        NetworkAdvantage: "Network Advantedge"
    }

    addonGroupDesc = {
        MostlyBought: 'Mostly Bought',
        EnhancedSumPeriod: 'Enhanced Sum Insured',
        ReducedWP: 'Reduced Waiting Period',
        HospAndOtrCover: 'Helpful during hospitalisation',
        MemberBasedCoverage: 'Member Based Coverage',
        CoverageAtHome: 'Coverage at Home',
        MaternityCoverage: 'Maternity Coverage',
        LumpSum: 'Lumpsum Payments',
        WorldwideCoverage: 'Worldwide coverage',
        CostOptimising: 'Cost Optimising'
    }

    validationOperation = {
        clearValidators: 'clear',
        addValidators: 'add',
        disableField: 'disable',
        enableField: 'enable',
        setValue: 'set'
    }

    stateDetails = {
        'stateId': '',
        'cityId': '',
        'cityName': '',
        'stateName': '',
        'pincode': '',
        'isBefitApplicable': ''
    }
    ipAddress = environment.ipAddress;
    productType = ['New', 'Portability'];


    addonGroupIcons = [
        { 'filled': '../../../../../assets/icons/elevate/mostboughtfill.svg', 'notFilled': '../../../../../assets/icons/elevate/mostbought.svg', 'group': 1 },
        { 'filled': '../../../../../assets/icons/elevate/suminsuredfill.svg', 'notFilled': '../../../../../assets/icons/elevate/suminsuredunfill.svg', 'group': 2 },
        { 'filled': '../../../../../assets/icons/elevate/waitingperiodreducetimefill.svg', 'notFilled': '../../../../../assets/icons/elevate/waitingperiodreducetime.svg', 'group': 3 },
        { 'filled': '../../../../../assets/icons/elevate/hospitalisationfill.svg', 'notFilled': '../../../../../assets/icons/elevate/hospitalisation.svg', 'group': 4 },
        { 'filled': '../../../../../assets/icons/elevate/homecoveragefill.svg', 'notFilled': '../../../../../assets/icons/elevate/homecoverageunfill.svg', 'group': 5 },
        { 'filled': '../../../../../assets/icons/elevate/maternityfill.svg', 'notFilled': '../../../../../assets/icons/elevate/maternityunfill.svg', 'group': 6 },
        { 'filled': '../../../../../assets/icons/elevate/lumsumpaymentfill.svg', 'notFilled': '../../../../../assets/icons/elevate/lumsumpaymentunfill.svg', 'group': 7 },
        { 'filled': '../../../../../assets/icons/elevate/costoptimisingfill.svg', 'notFilled': '../../../../../assets/icons/elevate/costoptimisingunfill.svg', 'group': 8 },
        { 'filled': '../../../../../assets/icons/elevate/worldwidefill.svg', 'notFilled': '../../../../../assets/icons/elevate/worldwideunfill.svg', 'group': 9 }
    ]

}

export interface ElevateAddonStructure extends AddonDetails {
    group?: number;
    groupName?: string;
    fieldType: string,
    dropDownValue: Array<{ id: number, label: string }>,
    dropDownControlName: string,
    defaultDropDownValue: string,
    tooltipPresent: boolean,
    defaultDropDownControlValue: string,
    showDependentField: boolean,
    dropDownPlaceholder: string,
    dropDownLabel: string
}

export interface UniqueGroups {
    groupId: number,
    hide: boolean,
    groupDesc: string
}

export interface ElevateParentAddonDetails extends BaseAddonDetails {
    groupPosition: number,
    childAddons: ElevateAddonStructure[],
    addonsCount: number
}

