import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HomeDataRequestPayload, HomeMasterDataRequestPayload, HomeMasterDataResponsePayload, HomeResponsePayload, } from '../interface/home';
import { GenericService } from '../services/generic.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private generic: GenericService) { }
  readonly baseURI = `${environment.baseURL}Home/`;
  readonly baseURL =`${environment.baseURL}`
  readonly homeMasterData = 'MasterData'
  readonly nysaRedirection = 'Utility/ProductRedirection'

  getHomeMasterData(data: HomeMasterDataRequestPayload): Observable<HomeMasterDataResponsePayload> {
    const endpoint = `${this.baseURI}${this.homeMasterData}`;
    return this.generic.genericService<HomeMasterDataResponsePayload>(endpoint, data);
  }

  getHomeData(data: HomeDataRequestPayload): Observable<HomeResponsePayload> {
    const endpoint = `${this.baseURL}${this.nysaRedirection}`;
    return this.generic.genericService<HomeResponsePayload>(endpoint, data);
  }
}
