import { Component, TemplateRef } from '@angular/core';
import { LoaderService } from './loader.service';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  constructor(public spinnerService: LoaderService) { 
  }
  isScrollDisabled = false;
  public loading = true;
  PrimaryWhite = '#f17e00';
  SecondaryGrey = 'white';
  PrimaryRed = '#dd0031';
  SecondaryBlue = '#1976d2';
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public primaryColour = this.PrimaryWhite;
  public secondaryColour = this.SecondaryGrey;
  public loadingTemplate!: TemplateRef<any>;
  public config = {
    animationType: ngxLoadingAnimationTypes.none,
    primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour,
    tertiaryColour: this.primaryColour,
    backdropBorderRadius: '3px',
  };
}
