
import { Subscription, fromEvent } from 'rxjs';
import { LoaderService } from 'src/app/layout/loader/loader.service';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { Constants } from 'src/app/shared/constants/constants';
import { PolicyType, PendingFilterType, MotorPolicyType, TravelPolicyType, AllRiskPolicyType, HomePolicyType, HealthPolicyType } from 'src/app/shared/enums/pendingPaymentEnums';
import { popupType, popupImgPath, popupHeader, popupButton, popupButtonAction, popupDescriptionMsg } from 'src/app/shared/enums/popupEnums';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { PendingPaymentResponsePayload, PendingPaymentsRequestPayload } from 'src/app/shared/interface/pendingPayments';
import { RecalculateResponsePayload } from 'src/app/shared/interface/health';
import { DownloadpdfRequestPayload } from 'src/app/shared/interface/file';
import { HealthService } from 'src/app/shared/proxy-services/health.service';
import { PaymentService } from 'src/app/shared/proxy-services/payment.service';
import { PendingPaymentService } from 'src/app/shared/proxy-services/pending-payment.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { DownloadService } from 'src/app/shared/services/download.service';
import { AgentV1Service } from 'src/app/shared/proxy-services/agent-v1.service';
import { Component, OnDestroy, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { ChannelData } from 'src/app/shared/interface/swapPrameters';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from 'src/app/layout/popups/popup.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FetchCustomerDetailsV1RequestPayload } from 'src/app/shared/interface/customer';
import { CustomerService } from 'src/app/shared/proxy-services/customer.service';
import { HAPModal } from 'src/app/modules/health/products/hap/hap-modal';

@Component({
  selector: 'app-pending-payment',
  templateUrl: './pending-payment.component.html',
  styleUrls: ['./pending-payment.component.scss']
})
export class PendingPaymentComponent implements OnDestroy {
  pendingPaymentForm: FormGroup;
  minimum_date: Date;
  maximum_date: Date;
  start_date: Date;
  end_date: Date;
  health_start_date: Date;
  home_start_date: Date;
  travel_start_date: Date;
  health_end_date: Date;
  home_end_date: Date;
  travel_end_date: Date;
  selectedProduct = 'Health';
  errorPopupData: PopupModal;
  searchText: string = '';
  policyType: number;
  subscription: Subscription[] = [];
  paymentDetails = [];
  StartDate: string;
  EndtDate: string;
  totalPremium: number;
  checkedBoxArray = [];
  showPayNotify: boolean = false;
  isHealth: boolean;
  isHome: boolean;
  isData: boolean = false;
  productCode: number = 42;
  hapDataModal: HAPModal = new HAPModal();
  productCodeTravel: number = 3;
  travelList: { productName: string; productCode: number; isProductMapped: any; }[];
  hidePaymentLink: boolean = true;
  channelData: ChannelData;
  isTravel: boolean;
  showDownloadButton: boolean = true;
  private interval: any;
  productTypeList: { productName: string; productCode: number; isProductMapped: any; }[];
  productList = [];
  scrolling: boolean = false;
  isSearchVisible: boolean = false;
  searchPlaceHolder: string;
  inputValue: string = '';
  selectList = [];
  policyCount: number;
  minMax: { maxDate: Date; minDate: Date; };
  myPolicyStartDate: Date;
  myPolicyEndDate: Date;
  myPolicyProduct: string;
  i: number = 0;
  showCancel: boolean = false;
  toggleValue: 'table' | 'card' = 'card';
  policyFilter: string[] = [];
  policyFilterList = ['New', 'Rollover', 'Renewal', 'Failed Payment'];
  paymentEmail: string;
  paymentMobileno: number;


  filteredData: any[] = []; //dummy use
  phText: string[] = ['Search for Proposal Number',
    'Search for Customer Name'];
  policyDetails: any = [];
  tableBtnInfo = [
    {
      label: 'Download Proposal',
      imgPath: '../../../../../../../assets/images/BBR/downloadProposal.svg',
      tooltip: 'Download Proposal',
      expression: 'data.PolicyType != 4'
    },
    {
      label: 'Download Policy',
      imgPath: '../../../../../../../assets/images/BBR/downloadProposal.svg',
      tooltip: 'Download Policy',
      expression: "data.ProposalStatus == 'INSTACR' && data.Status == 'Insta Payment'"
    },
  ]
  cardBtnInfo = [{
    label: 'Recalculate',
    //imgPath: '../../../assets/images/MyTransactionIcon/Recalculate.svg',
    expression: "data.isRecalculateRequired && ([2,3,4].includes(data.PolicyType))  && !([25,24,37].includes(data.PolicySubType)) && ([42, 45,30, 31, 32, 33, 37, 1, 20, 22, 23, 34, 38, 40, 3, 4, 5].includes(data.PolicySubType) && !data.isEmiRequired && (new Date() > new Date(data.StartDate)) || (data.PolicySubType == '44' && (new Date() > new Date(data.StartDate))))"
  },
  {
    label: 'Add To Pay',
    expression: "((!data.isRecalculateRequired && data.Status != 'Submitted' && (([2,3,4].includes(data.PolicyType))  || (new Date(data.StartDate) >= new Date()))) || (data.isRecalculateRequired && data.PolicySubType == 24)) && (([42,45, 30, 31, 32, 33, 37, 1, 20, 22, 23, 34, 38, 40, 3, 4, 5].includes(data.PolicySubType) && !data.isEmiRequired || (new Date() > new Date(data.StartDate))) || (data.PolicySubType == '44' && (new Date() > new Date(data.StartDate))))"
  },
  {
    label: 'Download Proposal',
    //imgPath: '../../../assets/images/myPolicy/downloadProposal.svg',
    expression: 'data.PolicyType != 4'
  },
  {
    label: 'Download Policy',
    //imgPath: '../../../assets/images/myPolicy/downloadProposal.svg',,
    expression: "data.ProposalStatus == 'INSTACR' && data.Status == 'Insta Payment'"
  }]

  displayCol = [
    {
      columnDef: 'Select',
      header: 'Select',
      cell: '',
      addToPayExp: "((!data.isRecalculateRequired && data.Status != 'Submitted' && (([2,3,4].includes(data.PolicyType))  || (new Date(data.StartDate) >= new Date()))) || (data.isRecalculateRequired && data.PolicySubType == 24)) && (([42,45, 30, 31, 32, 33, 37, 1, 20, 22, 23, 34, 38, 40, 3, 4, 5].includes(data.PolicySubType) && !data.isEmiRequired || (new Date() > new Date(data.StartDate))) || (data.PolicySubType == '44' && (new Date() > new Date(data.StartDate))))",
      recalExp: "data.isRecalculateRequired && ([2,3,4].includes(data.PolicyType))   && !([25,24,37].includes(data.PolicySubType)) && ([42,45, 30, 31, 32, 33, 37, 1, 20, 22, 23, 34, 38, 40, 3, 4, 5].includes(data.PolicySubType) && !data.isEmiRequired && (new Date() > new Date(data.StartDate)) || (data.PolicySubType == '44' && (new Date() > new Date(data.StartDate))))",
      imgPath: '../../../../../../../assets/images/BBR/Recalculate.svg',
      addToPayTooltip: 'Add To Pay',
      recalTooltip: 'Recalculate',
    },
    {
      columnDef: 'CustomerName',
      header: 'Customer Name',
      cell: (element: any) => `${element.CustomerName}`,
    },
    {
      columnDef: 'ProposalNumber',
      header: 'Proposal Number',
      cell: (element: any) => `${element.ProposalNumber}`,
    },
    {
      columnDef: 'PFProposalNo',
      header: 'PF Proposal Number',
      cell: (element: any) => `${element.PFProposalNo}`,
    },
    {
      columnDef: 'TransOn',
      header: 'Proposal Date',
      cell: (element: any) => `${element.TransOn}`,
    },
    {
      columnDef: 'StartDate',
      header: 'Start Date',
      cell: (element: any) => `${element.StartDate}`,
    },
    {
      columnDef: 'EndDate',
      header: 'End Date',
      cell: (element: any) => `${element.EndDate}`,
    },
    {
      columnDef: 'Status',
      header: 'Status',
      cell: (element: any) => `${element.Status}`,
    },
    {
      columnDef: 'FailedRemark',
      header: 'Remarks',
      cell: (element: any) => `${element.FailedRemark}`,
    },
    {
      columnDef: 'ComboPremium',
      header: 'Payable Amount',
      cell: (element: any) => `${element.ComboPremium}`,
    },
    {
      columnDef: 'Action',
      header: 'Action',
      cell: '',
    }
  ]

  constructor(
    private popupService: PopupService,
    private spinner: LoaderService,
    private pendingPaymentService: PendingPaymentService,
    private downloadService: DownloadService,
    public utilityService: UtilityService,
    private paymentService: PaymentService,
    private storageService: StorageService,
    private healthService: HealthService,
    private formBuilder: FormBuilder,
    private agentV1Service: AgentV1Service,
    private renderer: Renderer2,
    private dialog: MatDialog,
    private customerService: CustomerService) { }

  ngOnInit() {
    // window.scrollTo(0, 0);
    this.subscription.push(fromEvent(window, 'scroll')
      .subscribe(() => {
        this.onWindowScroll();
      }));
    this.initialDate();
    // this.onSearchData();
    this.getProductList();
    // this.getTravelList();
    this.createForm();
    this.setCalendarDates();
    this.setProductType();
    this.searchDetails();
    this.setPlaceHolderText();
    this.interval = setInterval(() => { this.setPlaceHolderText(); }, 2000);
    this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
    this.subscription.push(this.popupService.travelPaymentValue1$.subscribe(data => {
      if (data) {
        // this.paymentEmail = data['emailId'];
        // this.paymentMobileno = data['mobileNo'];
        this.PaymentLink(data)
      }
    }))
  }

  setProductType() {
    if (sessionStorage.getItem(userEnums.AgentProductMap)) {
      const products = this.storageService.getAESDecryptedData(userEnums.AgentProductMap);
      this.pendingPaymentForm.controls['productType'].setValue(products[0].productCode);
      if (products[0].productCode != 4) {
        this.pendingPaymentForm.controls['productName'].setValue(products[0].productList[0].productCode);
      }
      this.setSelectList(products[0].productCode);
    } else {
      this.productListListner('2');
    }
  }

  setSelectList(option) {
    for (let i of this.productList) {
      if (i.productCode == option) {
        this.selectList = i.productList
      }
    }
  }

  setPlaceHolderText() {
    this.searchPlaceHolder = this.phText[this.i];
    this.i++;
    if (this.i == this.phText.length) {
      this.i = 0;
    }
  }

  setCalendarDates() {
    let minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 10));
    let maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    this.minMax = { maxDate: maxDate, minDate: minDate }
  }

  onWindowScroll() {
    this.scrolling = true;

    if (window.scrollY === 0) {
      this.scrolling = false;
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  getProductList() {
    // let agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
    this.productList = this.agentV1Service.getAllProducts('pendingPolicy');
  }

  // getTravelList() {
  //   let agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
  //   this.travelList = this.agentV1Service.travelList(agentDetails, 'travel');
  // }

  onClick(type) {
    this.paymentDetails = [];
    this.selectedProduct = type.tab.textLabel;
    if (this.selectedProduct == 'Home')
      this.showDownloadButton = false;
    this.onSearchData();
  }


  initialDate() {
    this.minimum_date = new Date(new Date().setFullYear(new Date().getFullYear() - 10));
    this.maximum_date = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    this.health_start_date = new Date(new Date().setDate(new Date().getDate() - 15));
    this.home_start_date = new Date(new Date().setDate(new Date().getDate() - 15));
    this.travel_start_date = new Date(new Date().setDate(new Date().getDate() - 15));
    this.health_end_date = new Date();
    this.home_end_date = new Date();
    this.travel_end_date = new Date();
  }

  selectproduct(productCode: number) {
    this.productCode = productCode;
  }

  selectproductTravel(productCode: number) {
    this.productCodeTravel = productCode;
  }

  onSearchData() {
    this.checkedBoxArray = [];
    this.paymentDetails = [];
    this.start_date = this.selectedProduct=='Health'?this.health_start_date:this.selectedProduct=='Travel'?this.travel_start_date:this.home_start_date;
    this.end_date = this.selectedProduct=='Health'?this.health_end_date:this.selectedProduct=='Travel'?this.travel_end_date:this.home_end_date;
    if (Date.parse(this.end_date.toDateString()) < Date.parse(this.start_date.toDateString())) {
      this.errorPopupData = this.popupService.fetchErrorPopupMsg('Please select correct date.');
      this.popupService.openGenericPopup(this.errorPopupData);
    } else {
      this.goToPendingPayment(this.selectedProduct);
    }
  }

  goToPendingPayment(type) {
    this.selectedProduct = type;
    this.EndtDate = this.utilityService.formatDate(this.end_date);
    this.StartDate = this.utilityService.formatDate(this.start_date);
    this.showPayNotify = false;
    this.isData = false;
    this.searchText = '';
    if (type == 'Health') {
      this.isHealth = true;
      this.policyType = PolicyType.Health;
      let body = {
        "FilterType": PendingFilterType.ALL,
        "PolicyType": PolicyType.Health,
        // "MotorPolicyType": MotorPolicyType.None,
        "HealthPolicyType": this.productCode,
        "StartDate": this.StartDate,
        "EndDate": this.EndtDate,
        "FilterText": "",
        "TravelPolicyType": TravelPolicyType.None,
        "AllRiskPolicyType": AllRiskPolicyType.MOBILE,
        "HomePolicyType": HomePolicyType.Home,

      }
      this.subscription.push(this.pendingPaymentService.pendingPayments(body).subscribe({
        next: (response: PendingPaymentResponsePayload) => {
          if (response.StatusCode === Constants.statusCode_success) {
            this.paymentDetails = response.Data.Health;
            this.isData = this.paymentDetails.length > 0 ? true : false;
          }
          else {
            // this.errorPopupData = this.popupService.fetchErrorPopupMsg(response.StatusDesc);
            // this.popupService.openGenericPopup(this.errorPopupData);
          }

        },
        error: error => {
          this.errorPopupData = this.popupService.fetchErrorPopupMsg('Pending Payment Response Failed');
          this.popupService.openGenericPopup(this.errorPopupData);
        }
      })

      )
    }
    else if (type == 'Home') {
      this.isHome = true; this.policyType = PolicyType.Home;

      let body2 = {
        "FilterType": PendingFilterType.ALL,
        "PolicyType": PolicyType.Home,
        // "MotorPolicyType": MotorPolicyType.None,
        "HealthPolicyType": HealthPolicyType.None,
        "StartDate": this.StartDate,
        "EndDate": this.EndtDate,
        "FilterText": "",
        "TravelPolicyType": TravelPolicyType.None,
        "AllRiskPolicyType": AllRiskPolicyType.MOBILE,
        "HomePolicyType": HomePolicyType.Home,

      }
      this.subscription.push(this.pendingPaymentService.pendingPayments(body2).subscribe({
        next: (response: PendingPaymentResponsePayload) => {
          if (response.StatusCode === Constants.statusCode_success) {
            this.paymentDetails = response.Data.Home;
            this.isData = this.paymentDetails.length > 0 ? true : false;
          }
          else {
            // this.errorPopupData = this.popupService.fetchErrorPopupMsg(response.StatusDesc);
            // this.popupService.openGenericPopup(this.errorPopupData);
          }

        },
        error: error => {
          this.errorPopupData = this.popupService.fetchErrorPopupMsg('Pending Payment Response Failed');
          this.popupService.openGenericPopup(this.errorPopupData);
        }
      })

      )
    }
    else {
      this.isTravel = true;
      this.policyType = PolicyType.Travel;

      let body1 = {
        "FilterType": PendingFilterType.ALL,
        "PolicyType": PolicyType.Travel,
        // "MotorPolicyType": MotorPolicyType.None,
        "HealthPolicyType": HealthPolicyType.None,
        "StartDate": this.StartDate,
        "EndDate": this.EndtDate,
        "FilterText": "",
        "TravelPolicyType": this.productCodeTravel,
        "AllRiskPolicyType": AllRiskPolicyType.MOBILE,
        "HomePolicyType": HomePolicyType.Home,

      }
      this.subscription.push(this.pendingPaymentService.pendingPayments(body1).subscribe({
        next: (response: PendingPaymentResponsePayload) => {
          if (response.StatusCode === Constants.statusCode_success) {
            this.paymentDetails = response.Data.Travel;
            this.isData = this.paymentDetails.length > 0 ? true : false;
          }
          else {
            // this.errorPopupData = this.popupService.fetchErrorPopupMsg(response.StatusDesc);
            // this.popupService.openGenericPopup(this.errorPopupData);
          }

        },
        error: error => {
          this.errorPopupData = this.popupService.fetchErrorPopupMsg('Pending Payment Response Failed');
          this.popupService.openGenericPopup(this.errorPopupData);
        }
      })

      )

    }
  }

  totalPremiumAmount(details) {
    if (!this.utilityService.isEmptyOrNull(details.BundleList)) {
      var bundle_total = 0;
      for (let i of details.BundleList) {
        bundle_total = bundle_total + parseInt(i.BundleTotalPremium)
      }
      return bundle_total = (parseInt(details.TotalPremium) + bundle_total)
    }
    else { return details.TotalPremium; }
  }

  // downloadPdfFile(details) {
  //   let policyId = details.EPolicyID;
  //   let data: DownloadpdfRequestPayload = {
  //     'type': 'PROPOSAL',
  //     'value': policyId
  //   }
  //   this.subscription.push(this.downloadService.downloadPDfRequest(data).subscribe({
  //     next: (response) => {
  //       if (response.StatusCode == Constants.statusCode_success) {
  //         let filename = details.ProposalNumber + '.pdf';
  //         const fileReader = new FileReader();

  //         fileReader.onloadend = () => {
  //           const base64Data = fileReader.result as string;

  //           // Trigger the download
  //           this.downloadService.downloadPdf(response.Data, filename);
  //         };
  //         fileReader.readAsDataURL(new Blob([response.Data], { type: 'application/octet-stream' }));
  //       }
  //     },
  //     error: error => {
  //       this.errorPopupData = this.popupService.fetchErrorPopupMsg('Unable to download PDF');
  //       this.popupService.openGenericPopup(this.errorPopupData);

  //     }
  //   }))
  // }

  isViewPolicyVisible(data) {
    return (data.Status == 'Insta Payment') && (data.PFPolicyNo != 0 || data.PFPolicyNo != '');
  }


  downloadPolicy(data) {
    let policyType = (data.CUUWSTATUS != '' && data.CUUWSTATUS > 0 && (data.policySubType == 19 || data.policySubType == 20)) ? 'HBCUWPDF' :
      data.policySubType == 21 ? 'POLICY' : (data.CUUWSTATUS != '' && data.CUUWSTATUS > 0) ? 'CHICUWPDF' : 'POLICY';
    // let policyId = data.policySubType == 38 || data.policySubType == 23 ? data.policyID : data.ePolicyID;
    let policyId = data.EPolicyID;
    let params = { policyType: policyType, policySubType: data.policySubType, policyId: policyId, ePolicyID: data.ePolicyID, bundleId: data.bundleID, policyID: data.policyID }
    let details = this.downloadService.downloadPolicy(params);
    this.generatePdf(details, data.PFPolicyNo);
  }

  generatePdf(params, name) {
    this.subscription.push(this.downloadService.generatePDF(params.endPoint, params.body).subscribe({
      next: (response) => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.downloadService.downloadPdf(response.Data, name)
        }
        // let fileName = params.policyId + '.pdf';
        // const fileReader = new FileReader();

        // fileReader.onloadend = () => {
        //   const base64Data = fileReader.result as string;

        //   // Trigger the download
        //   this.downloadFile(base64Data, fileName);
        // };
        // fileReader.readAsDataURL(new Blob([response], { type: 'application/octet-stream' }));
        // //this.downloadService.saveBlob(response, fileName);
      },
      error: error => {
        let errorPopupData = this.popupService.fetchErrorPopupMsg('');
        this.popupService.openGenericPopup(errorPopupData);
      }
    })

    )
  }

  downloadFile(base64Data, filename: string) {
    const link = document.createElement('a');
    link.href = base64Data;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }




  // downloadfile(details) {
  //   let policyId = details.PFPolicyNo;
  //   let data: DownloadpdfRequestPayload = {
  //     'type': 'POLICY',
  //     'value': policyId
  //   };

  //   this.subscription.push(this.downloadService.downloadPDfRequest(data).subscribe({
  //     next: (response) => {
  //       if (response.StatusCode == Constants.statusCode_success) {
  //         // let filename = details.ProposalNumber + '.pdf';
  //         this.downloadService.downloadPdf(response.Data, details.PFPolicyNo);
  //         // const link = document.createElement('a');
  //         // link.addEventListener('click', () => {
  //         //   // Clean up after a short delay
  //         //   setTimeout(() => {
  //         //     document.body.removeChild(link);
  //         //   }, 100);
  //         // });

  //         // // Use Blob-based approach for all browsers
  //         // const blob = new Blob([this.base64ToUint8Array(response.Data)], { type: 'application/pdf' });
  //         // const url = URL.createObjectURL(blob);

  //         // link.href = url;
  //         // link.download = filename;
  //         // document.body.appendChild(link);
  //         // link.click();
  //       }
  //     },
  //     error: error => {
  //       this.errorPopupData = this.popupService.fetchErrorPopupMsg('Unable to download PDF');
  //       this.popupService.openGenericPopup(this.errorPopupData);
  //     }
  //   }));
  // }


  downloadPdfFile(details) {
    let policyId = details.EPolicyID;
    let data: DownloadpdfRequestPayload = {
      'type': 'PROPOSAL',
      'value': policyId
    };
    this.subscription.push(this.downloadService.downloadPDfRequest(data).subscribe({
      next: (response) => {
        if (response.StatusCode == Constants.statusCode_success) {
          // let filename = details.ProposalNumber + '.pdf';
          this.downloadService.downloadPdf(response.Data, details.ProposalNumber);
          // const link = document.createElement('a');
          // link.addEventListener('click', () => {
          //   // Clean up after a short delay
          //   setTimeout(() => {
          //     document.body.removeChild(link);
          //   }, 100);
          // });

          // // Use Blob-based approach for all browsers
          // const blob = new Blob([this.base64ToUint8Array(response.Data)], { type: 'application/pdf' });
          // const url = URL.createObjectURL(blob);

          // link.href = url;
          // link.download = filename;
          // document.body.appendChild(link);
          // link.click();
        }
      },
      error: error => {
        this.errorPopupData = this.popupService.fetchErrorPopupMsg('Unable to download PDF');
        this.popupService.openGenericPopup(this.errorPopupData);
      }
    }));
  }

  downloadPdfFilePolicy(details) {
    let policyId = details.EPolicyID;
    let data: DownloadpdfRequestPayload = {
      'type': 'POLICY',
      'value': policyId
    };
    this.subscription.push(this.downloadService.downloadPDfRequest(data).subscribe({
      next: (response) => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.downloadService.downloadPdf(response.Data, details.PFPolicyNo);
        }
      },
    }));
  }


  receiveDataAndCall(event) {
    if (event.identifier == 'Download Proposal') {
      this.downloadPdfFile(event.data);
    } else if (event.identifier == 'Recalculate') {
      this.reCalAmount(event.data);
    } else if (event.identifier == 'Add To Pay') {
      this.footerSelectedRows(event.data.selected, event.data.rowData, event.data.id);
    } else if (event.identifier == 'Download Policy') {
      this.downloadPdfFilePolicy(event.data);
    }
  }

  selectedRows: any = [];
  footerSelectedRows(isChecked: boolean, row: any, id: number) {
    if (isChecked) {
      this.selectedRows.push(row);
      this.policyDetails[id].isChecked = true;
      this.sortCheckedData(id);
      this.clearInputField();
    } else {
      if (this.selectedRows.length > 0) {
        for (let i = 0; i < this.selectedRows.length; i++) {
          if (this.selectedRows[i].PolicyNumber == row.PolicyNumber) {
            this.selectedRows.splice(i, 1);
          }
        }
      }
      this.policyDetails[id].isChecked = false;
    }
    this.setFooterPaymentData();
  }

  sortCheckedData(id: number): void {
    for (let i = 0; i < this.policyDetails.length; i++) {
      if (id != i) {
        if (!this.policyDetails[i].isChecked) {
          const x = this.policyDetails[i].id;
          this.policyDetails[i].id = id;
          this.policyDetails[id].id = x;
          const temp = this.policyDetails[i];
          this.policyDetails[i] = this.policyDetails[id];
          this.policyDetails[id] = temp;
          break;
        }
      } else {
        break;
      }
    }
  }

  setFooterPaymentData() {
    //Unsure
    //this.storageService.setAESEncryptedData(userEnums.PendingPaymentData, this.selectedRows);
    //Unsure
    this.totalPremium = 0;
    if (this.selectedRows.length > 0) {
      this.selectedRows.forEach((data) => {
        if (!this.utilityService.isEmptyOrNull(data.BundleList)) {
          let bundle_total = 0;
          for (let i of data.BundleList) {
            bundle_total = bundle_total + parseInt(i.BundleTotalPremium)
          }
          this.totalPremium += (parseInt(data.TotalPremium) + bundle_total)
        } else {
          this.totalPremium += data.TotalPremium;
        }
      })
      this.showPayNotify = true;

      if ((this.selectedRows.length > 1)) {
        this.hidePaymentLink = true;
      }
      if (this.selectedRows.length == 1) {
        const currentDate = new Date();
        // Set the time component of currentDate to midnight
        currentDate.setHours(0, 0, 0, 0);
        // Parse the start date
        const startDate = new Date(this.selectedRows[0].StartDate);
        // Set the time component of startDate to midnight
        startDate.setHours(0, 0, 0, 0);
        // Compare the parsed date with the current date
        const isStartDateBeforeToday = startDate < currentDate;
        this.hidePaymentLink = isStartDateBeforeToday;
      }
    } else {
      this.showPayNotify = false;
      this.hidePaymentLink = true;
    }
  }

  isChecked(ev, data) {
    this.storageService.setAESEncryptedData(userEnums.PendingPaymentData, data);
    this.totalPremium = 0;
    let count = 0;
    //if check box is checked
    if (ev.checked) {
      this.checkedBoxArray.push(data);
      this.checkedBoxArray.forEach((data) => {
        //total premium addition
        if (!this.utilityService.isEmptyOrNull(data.BundleList)) {
          let bundle_total = 0;
          for (let i of data.BundleList) {
            bundle_total = bundle_total + parseInt(i.BundleTotalPremium)
          }
          this.totalPremium += (parseInt(data.TotalPremium) + bundle_total)
        } else {
          this.totalPremium += data.TotalPremium;
        }
      });
      //this.showPayNotify = true;
      //cif more than 1 value is selected hide send payment link 
      if ((this.checkedBoxArray.length > 1)) {
        this.hidePaymentLink = true;
      }
      if (this.checkedBoxArray.length == 1) {
        const currentDate = new Date();
        // Set the time component of currentDate to midnight
        currentDate.setHours(0, 0, 0, 0);
        // Parse the start date
        const startDate = new Date(data.StartDate);
        // Set the time component of startDate to midnight
        startDate.setHours(0, 0, 0, 0);
        // Compare the parsed date with the current date
        const isStartDateBeforeToday = startDate < currentDate;
        this.hidePaymentLink = isStartDateBeforeToday;
      }
      //check back-dated record-hide send payment link 
      // else {
      //   this.checkedBoxArray.filter(data => {
      //     count = (new Date(data.StartDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) ? count += 1 : count;
      //   })
      //   this.hidePaymentLink = count > 0 ? true : false;
      // }
    }
    //if unchecked the check box
    else {
      //find index of the unchecked checkbox form array 
      let dataIndex = -1;
      this.checkedBoxArray.findIndex((checkedData, index) => {
        if (checkedData.PFProposalNo == data.PFProposalNo) {
          dataIndex = index;
        }
      });
      //const index: number = this.checkedBoxArray.indexOf(data);
      //removed the record form array list
      if (dataIndex != -1) {
        this.checkedBoxArray.splice(dataIndex, 1)
      }
      //update total premium
      this.checkedBoxArray.forEach((data) => {
        if (!this.utilityService.isEmptyOrNull(data.BundleList)) {
          let bundle_total = 0;
          for (let i of data.BundleList) {
            bundle_total = bundle_total + parseInt(i.BundleTotalPremium)
          }
          this.totalPremium += (parseInt(data.TotalPremium) + bundle_total)
        } else {
          this.totalPremium += data.TotalPremium;

        }
      });
      //no record is selected hide payment button
      if (this.checkedBoxArray.length === 0) {
        this.showPayNotify = false;
        this.hidePaymentLink = true;
      }
      //if array llist more than 1 record hide payment button
      if (this.checkedBoxArray.length > 1) {
        this.hidePaymentLink = true;
      }
      //again check back-dated data 
      else {
        this.checkedBoxArray.filter(data => {
          count = (new Date(data.StartDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) ? count += 1 : count;
        })
        this.hidePaymentLink = count > 0 ? true : false;
      }
    }
  }

  // downloadPdfFile(details) {
  //   const policyId = details.EPolicyID;
  //   let data: DownloadpdfRequestPayload = {
  //     'type': 'PROPOSAL',
  //     'value': policyId
  //   };

  //   this.subscription.push(this.downloadService.downloadPDfRequest(data).subscribe({
  //     next: (response) => {
  //       if (response.StatusCode == Constants.statusCode_success) {
  //         // let filename = details.ProposalNumber + '.pdf';
  //         this.downloadService.downloadPdf(response.Data, details.ProposalNumber);
  //         // const link = document.createElement('a');
  //         // link.addEventListener('click', () => {
  //         //   // Clean up after a short delay
  //         //   setTimeout(() => {
  //         //     document.body.removeChild(link);
  //         //   }, 100);
  //         // });

  //         // // Use Blob-based approach for all browsers
  //         // const blob = new Blob([this.base64ToUint8Array(response.Data)], { type: 'application/pdf' });
  //         // const url = URL.createObjectURL(blob);

  //         // link.href = url;
  //         // link.download = filename;
  //         // document.body.appendChild(link);
  //         // link.click();
  //       }
  //     },
  //     error: error => {
  //       this.errorPopupData = this.popupService.fetchErrorPopupMsg('Unable to download PDF');
  //       this.popupService.openGenericPopup(this.errorPopupData);
  //     }
  //   }));
  // }

  reCalAmount(details) {
    const policyID = details.PolicyID;
    const TotalPremium = details.TotalPremium
    let body = { "PolicyId": policyID.toString(), "TotalPremium": TotalPremium.toString() };
    this.subscription.push(this.healthService.recalculateRequest(body).subscribe({
      next: (response: RecalculateResponsePayload) => {
        this.spinner.hide();
        if (response.StatusCode === Constants.statusCode_success) {
          let popupData: PopupModal = {
            popupType: popupType.generic,
            imgName: popupImgPath.successGif,
            header: popupHeader.success,
            description: 'Your proposal no ' + response.Data.PFProposalNo,
            buttonLabel: popupButton.ok,
            buttonAction: popupButtonAction.close
          }
          this.popupService.openGenericPopup(popupData);
          this.searchDetails();
        }
      }
    }))
  }

  showAddtoPay(details) {
    if ((!details.isRecalculateRequired && details.Status != 'Submitted') &&
      ((this.isHealth) || this.utilityService.compareDates(details.StartDate, this.EndtDate))) {
      return true;
    }
    else if (details.isRecalculateRequired && (details.PolicySubType == 24)) { return true; }
    else { return false; }
  }

  checkEMI(details) {
    let flag = true;
    let proposalStartDate = new Date(details.StartDate);
    let currentDate = new Date(this.utilityService.formatDate(new Date()));
    if (details.PolicySubType == '42' || (details.PolicySubType >= Number('30') && details.PolicySubType <= Number('33')) || details.PolicySubType == '37' || details.PolicySubType == '1') {
      flag = !(details.isEmiRequired && currentDate > proposalStartDate);
    } else if (details.PolicySubType == '44') {
      flag = !(currentDate > proposalStartDate);
    }
    return flag;
  }

  Pay(payMode: string) {
    //let response = this.storageService.getAESDecryptedData(userEnums.PendingPaymentData)
    if (this.selectedRows.length > 2) {
      this.errorPopupData = this.popupService.fetchErrorPopupMsg('You can only select 2 proposals at a time for payment');
      this.popupService.openGenericPopup(this.errorPopupData);
    }
    else if (this.selectedRows.length > 1) {
      let value = this.selectedRows[0].PFCustomerID;
      this.showPayNotify = true;
      let count = 0;
      this.selectedRows.forEach((data) => {
        if (value != data.PFCustomerID) {
          count++;
        }
      });
      if (count > 0) {
        this.errorPopupData = this.popupService.fetchErrorPopupMsg('Selected proposals can not be paid together since proposal details differ');
        this.popupService.openGenericPopup(this.errorPopupData);
      } else {
        this.payNowService(this.selectedRows[0], this.selectedRows[1]);
      }
    }
    else {
      if (payMode == 'PayNow') {
        this.payNowService(this.selectedRows[0], '');
      }
      else {
        if (this.myPolicyProduct == 'Travel') {
          let request: FetchCustomerDetailsV1RequestPayload = {
            PfCustomerID: this.selectedRows[0].PFCustomerID
          }
          this.subscription.push(this.customerService.fetchCustomerDetailsV1(request).subscribe({
            next: response => {

              let popupData: PopupModal = {
                popupType: popupType.travelPaymentLink,
                imgName: popupImgPath.successGif,
                header: popupHeader.success,
                description: '',
                buttonLabel: popupButton.ok,
                buttonAction: popupButtonAction.emitSendPaymentLink
              }
              //this.dialogData.popupType = popupType.travelPaymentLink;
              this.popupService.openGenericPopup(popupData);
              let data = { emailId: response.Data.EmailID, mobileNo: response.Data.Mobile, name: 'pendingpayment', PolicyID: this.selectedRows[0].PolicyID };
              setTimeout(() => {
                this.popupService.emitTravelPaymentData(data);
              }, 0);
            }
          }))
        }
        else {
          this.PaymentLink(this.selectedRows[0]);
        }
      }
    }
  }

  XPay(payMode: string) {
    let response = this.storageService.getAESDecryptedData(userEnums.PendingPaymentData)
    if (this.checkedBoxArray.length > 2) {
      this.errorPopupData = this.popupService.fetchErrorPopupMsg('You can only select 2 proposals at a time for payment');
      this.popupService.openGenericPopup(this.errorPopupData);
      return;
    }
    else if (this.checkedBoxArray.length > 1) {
      let value = this.checkedBoxArray[0].PFCustomerID;
      //this.showPayNotify = true;
      let count = 0;
      this.checkedBoxArray.forEach((data) => {
        if (value != data.PFCustomerID) {
          count++;
        }
      });
      if (count > 0) {
        this.errorPopupData = this.popupService.fetchErrorPopupMsg('Selected proposals can not be paid together since proposal details differ');
        this.popupService.openGenericPopup(this.errorPopupData);
        return;
      } else {
        this.payNowService(this.checkedBoxArray[0], this.checkedBoxArray[1]);
      }
    }
    else {
      if (payMode == 'PayNow') {
        this.payNowService(response, '');
      }
      else {
        this.PaymentLink(response);
      }
    }
  }

  constructPaymentLinkRequest(details) {
    let request;
    request = {
      EmailID: this.utilityService.isUndefinedORNull(details.emailId) ? '' : details.emailId,
      PolicyID: details.PolicyID.toString(),
      RequestType: "2",
      MobileNo: this.utilityService.isUndefinedORNull(details.mobileNo) ? '' : details.mobileNo,
    }
    return request;
  }

  constructCommonPaymentRequest(details) {
    let request;
    request = {
      "TransType": "BUNDLE_PAYMENT",
      "GatewayReturnURL": "",
      "PolicyIDs": details.PolicyID,
      "PayerType": "Customer",
      "ModeID": 0,
      "UserRole": this.utilityService.isEmptyOrNull(this.channelData.UT) ? "AGENT" : this.channelData.UT,
      "IPAddress": this.utilityService.isEmptyOrNull(this.channelData.CIP) ? this.hapDataModal.ipAddress : this.channelData.CIP,
      "PaymentMode": "None",
      "PaymentAmount": 0
    }
    return request;
  }

  PaymentLink(details) {
    let paymentLinkNowRequest = this.constructPaymentLinkRequest(details);
    this.subscription.push(this.paymentService.paymentLinkRequest(paymentLinkNowRequest).subscribe({
      next: response => {
        if (response.StatusCode == 1 && response.StatusMsg == "Success") {
          let emailPart = response.Data.EmailID ? `Email Id: ${response.Data.EmailID}` : '';
          let mobilePart = response.Data.MobileNo ? `Mobile Number: ${response.Data.MobileNo}` : '';
          let popupData: PopupModal = {
            popupType: popupType.generic,
            imgName: popupImgPath.successGif,
            header: popupHeader.success,
            description: popupDescriptionMsg.paymentLinkSuccess + emailPart + ' ' + mobilePart,
            buttonLabel: popupButton.ok,
            buttonAction: popupButtonAction.dashboard
          }
          this.popupService.openGenericPopup(popupData);
        }
      },
      error: error => {
      }
    }))
  }

  constructPayNowRequest(details1, details2) {
    // let bundle_PolicyID;
    // let policyIDs;
    // if (details.BundleList.length > 0) {
    //   bundle_PolicyID = details.BundleList[0].BundlePolicyID;
    //   policyIDs = details.PolicyID.toString() + ':' + bundle_PolicyID;
    // }
    // else {
    //   policyIDs = details.PolicyID;
    // }
    let policyId = '';
    if (details2 == '') {
      policyId = details1.PolicyID.toString();
    } else {
      policyId = details1.PolicyID.toString() + ':' + details2.PolicyID.toString()
    }
    let request;
    request = {
      PolicyID: policyId,
      UserType: 'Agent',
      CorrelationID: null
    }
    return request;
  }

  payNowService(details1, details2) {
    let payNowRequest = this.constructPayNowRequest(details1, details2);
    this.subscription.push(this.paymentService.payNowRequest(payNowRequest).subscribe({
      next: response => {
        if (response.StatusCode == 1 && response.StatusMsg == "Success") {
          let url = response.Data.URL;
          if (details2 == '') {
            this.storageService.setAESEncryptedDataLocal(userEnums.ProposalNumber, details1.PFProposalNo);
          } else {
            this.storageService.setAESEncryptedDataLocal(userEnums.ProposalNumber, details1.PFProposalNo + ':' + details2.PFProposalNo);
          }
          window.location.href = url;
        }
        else {
        }
      },
      error: error => {
      }
    }))
  }

  applyFilter() {
    this.clearInputField();
    this.filterLogic();
  }

  searchFilter() {
    this.isSearchVisible = true;
  }

  onToggle(event) {
    this.toggleValue = event.value;
  }

  clearInputField() {
    this.inputValue = '';
    this.queryData();
  }

  inputFocus() {
    this.showCancel = true;
  }

  inputBlur() {
    this.showCancel = false;
  }

  productListListner(option) {
    for (let i of this.productList) {
      if (i.productCode == option) {
        this.selectList = i.productList
        if (i.productCode != 4) {
          this.pendingPaymentForm.controls['productName'].setValue(this.selectList[0].productCode);
        }
      }
    }
  }

  createForm() {
    let startDate = this.utilityService.subtractDays(new Date(), 15);
    this.pendingPaymentForm = this.formBuilder.group({
      productType: [2, [Validators.required]],
      productName: [42, [Validators.required]],
      startDate: [startDate, [Validators.required]],
      endDate: [new Date(), [Validators.required]],
    })
    this.patchBanner();
  }

  filterImage: string = 'assets/images/BBR/FilterMobile.svg'; // Initial image

  openFilterBottomSheet() {
    if (this.filterImage === 'assets/images/BBR/FilterMobile.svg') {
      this.filterImage = 'assets/images/BBR/FilterActive.svg';
    } else {
      this.filterImage = 'assets/images/BBR/FilterMobile.svg';
    }
    const filterBottomSheet = this.dialog.open(PopupComponent, {
      panelClass: 'my-custom-payment',
      maxWidth: '100vw',
      // height: '38%',
      disableClose: true,
      data: {
        popupType: popupType.filter,
        data: [
          {
            label: 'Status Type',
            list: this.policyFilterList,
            filter: this.policyFilter
          }]
      },

    }

    );
    filterBottomSheet.afterClosed().subscribe((data) => {
      // Reset the image back to FilterMobile.svg
      this.filterImage = 'assets/images/BBR/FilterMobile.svg';
      this.patchFilterParameters(data);
    });
  }

  patchFilterParameters(data) {
    if (data) {
      this.policyFilter = data[0].filter;
    }
    this.applyFilter();
  }

  applyPolicyFilter(isChecked: boolean, value: string) {
    value = value.toLowerCase();
    if (isChecked) {
      if (!this.policyFilter.includes(value)) {
        this.policyFilter.push(value);
      }
    } else {
      const index = this.policyFilter.indexOf(value);
      if (index >= 0) {
        this.policyFilter.splice(index, 1);
      }
    }
    this.applyFilter();
  }

  editImage: string = 'assets/images/BBR/Edit.svg'; // Initial image

  openSearchBottomSheet() {
    if (this.editImage === 'assets/images/BBR/Edit.svg') {
      this.editImage = 'assets/images/BBR/EditActive.svg';
    } else {
      this.editImage = 'assets/images/BBR/Edit.svg';
    }
    const x = this.productList.filter(item => item.productCode == this.pendingPaymentForm.value.productType);
    const y = x[0].productList;
    const searchBottomSheet = this.dialog.open(PopupComponent, {
      panelClass: 'my-custom-payment',
      maxWidth: '100vw',
      // height: '50%',
      disableClose: true,
      data: {
        popupType: popupType.search,
        data: {
          controls: [{
            type: 'myDropdown',
            label: 'Product Type',
            list: this.productList,
            value: this.pendingPaymentForm.value.productType
          },
          {
            type: 'myDependentDropdown',
            label: 'Product Name',
            list: y,
            value: this.pendingPaymentForm.value.productName
          },
          {
            type: 'rangepicker',
            label: 'Select date range',
            startDate: this.pendingPaymentForm.value.startDate,
            endDate: this.pendingPaymentForm.value.endDate,
          }],
          dates: {
            minDate: this.minMax.minDate,
            maxDate: this.minMax.maxDate,
          },
        }
      }
    }
    );
    searchBottomSheet.afterClosed().subscribe((data) => {
      this.editImage = 'assets/images/BBR/Edit.svg';
      this.patchSearchParameters(data);
    });
  }

  patchSearchParameters(data) {
    if (data) {
      for (let i of data) {
        if (i.type == 'myDropdown') {
          this.pendingPaymentForm.controls['productType'].setValue(i.value);
        }
        else if (i.type == 'myDependentDropdown' && i.value) {
          this.pendingPaymentForm.controls['productName'].setValue(i.value);
        }
        else if (i.type == 'rangepicker') {
          this.pendingPaymentForm.controls['startDate'].setValue(i.startDate);
          this.pendingPaymentForm.controls['endDate'].setValue(i.endDate);
        }
      }
      this.searchDetails();
    }
  }


  closeClear() {
    this.isSearchVisible = false;
    this.clearInputField();
  }

  constructRequest(): PendingPaymentsRequestPayload {
    let request = {
      "FilterType": PendingFilterType.ALL,
      "PolicyType": PolicyType.Health,
      // "MotorPolicyType": MotorPolicyType.None,
      "HealthPolicyType": this.pendingPaymentForm.value.productName,
      "FilterText": "",
      "TravelPolicyType": TravelPolicyType.None,
      "AllRiskPolicyType": AllRiskPolicyType.MOBILE,
      "HomePolicyType": HomePolicyType.Home,
      "StartDate": this.utilityService.formatDate(this.pendingPaymentForm.value.startDate),
      "EndDate": this.utilityService.formatDate(this.pendingPaymentForm.value.endDate),
    }
    return request;
  }

  constructRequest1(): PendingPaymentsRequestPayload {
    let request = {
      "FilterType": PendingFilterType.ALL,
      "StartDate": this.utilityService.formatDate(this.pendingPaymentForm.value.startDate),
      "EndDate": this.utilityService.formatDate(this.pendingPaymentForm.value.endDate),
      "PolicyType": PolicyType.Travel,
      "FilterText": "",
      // "MotorPolicyType": MotorPolicyType.None,
      "HealthPolicyType": HealthPolicyType.None,
      "AllRiskPolicyType": AllRiskPolicyType.MOBILE,
      "TravelPolicyType": this.pendingPaymentForm.value.productName,
      "HomePolicyType": HomePolicyType.Home
    }
    return request;
  }

  constructRequest2(): PendingPaymentsRequestPayload {
    let request = {
      "FilterType": PendingFilterType.ALL,
      "StartDate": this.utilityService.formatDate(this.pendingPaymentForm.value.startDate),
      "EndDate": this.utilityService.formatDate(this.pendingPaymentForm.value.endDate),
      "PolicyType": PolicyType.Home,
      "FilterText": "",
      "AllRiskPolicyType": AllRiskPolicyType.MOBILE,
      // "MotorPolicyType": MotorPolicyType.None,
      "HealthPolicyType": HealthPolicyType.None,
      "TravelPolicyType": TravelPolicyType.None,
      "HomePolicyType": HomePolicyType.Home
    }
    return request;
  }

  searchDetails() {
    this.clearInputField();
    this.selectedRows = [];
    this.setFooterPaymentData();
    let body;
    if (this.pendingPaymentForm.value.productType == 2) {
      body = this.constructRequest();
    } else if (this.pendingPaymentForm.value.productType == 3) {
      body = this.constructRequest1();
    } else if (this.pendingPaymentForm.value.productType == 4) {
      body = this.constructRequest2();
    }

    this.subscription.push(this.pendingPaymentService.pendingPayments(body).subscribe({
      next: (response: PendingPaymentResponsePayload) => {
        if (response.StatusCode === Constants.statusCode_success) {
          if (response.Data.Health) {
            this.policyDetails = response.Data.Health;
            this.policyCount = response.Data.Health.length;
          } else if (response.Data.Travel) {
            this.policyDetails = response.Data.Travel;
            this.policyCount = response.Data.Travel.length;
          }
          else if (response.Data.Home) {
            this.policyDetails = response.Data.Home;
            this.policyCount = response.Data.Home.length;
          }
          this.policyDetails = this.policyDetails.map((data, index) => {
            return {
              ...data,
              isChecked: false,
              id: index,
              ComboPremium: Number(data.TotalPremium) + (!this.utilityService.isEmptyOrNull(data.BundleList) ? this.getTotalBundlePremium(data.BundleList) : 0),
            }
          })
          this.filteredData = this.policyDetails;
          this.patchBanner();
        } else {
          this.policyCount = 0;
          this.policyDetails = [];
          this.filteredData = [];
        }
      }
    }))
  }

  getTotalBundlePremium(list) {
    let totalPremium = 0;
    list.forEach(data => totalPremium = totalPremium + Number(data.BundleTotalPremium))
    return totalPremium
  }

  patchBanner() {
    this.myPolicyStartDate = this.pendingPaymentForm.value.startDate;
    this.myPolicyEndDate = this.pendingPaymentForm.value.endDate;
    this.myPolicyProduct = this.getProductNameFromCode(this.pendingPaymentForm.value.productType);
  }

  getProductNameFromCode(x: number) {
    if (x == 2) {
      return 'Health'
    } else if (x == 3) {
      return 'Travel'
    } else if (x == 4) {
      return 'Home'
    }
    return '';
  }

  filterLogic() {
    let filter = this.policyFilter;
    let failedPayment = filter.includes('failed payment');
    let rollover = filter.includes('rollover');
    let renewal = filter.includes('renewal');
    let newProposal = filter.includes('new');

    let failedQuery = "item['Status'].toLowerCase() == 'failed payment'";
    let rolloverQuery = "item.TransFor == 'R'";
    let renewalQuery = "item.TransFor == 'W'";
    let newQuery = "item.TransFor == 'N'";

    let query1 = failedPayment ? failedQuery : '';
    let query2 = rollover ? `item.Status == 'Proposal' && ${rolloverQuery}` : '';
    let query3 = renewal ? `item.Status == 'Proposal' && ${renewalQuery}` : '';
    let query4 = newProposal ? `item.Status == 'Proposal' && ${newQuery}` : '';

    let finalQuery = '';
    if (filter.length === 0) {
      this.filteredData = this.policyDetails;
    } else if (filter.length){
        if(filter.length == 1){ 
        finalQuery = query1 || query2 || query3 || query4;
        } else {
          let tempQuery = [];
          if (query1) tempQuery.push(query1);
          if (query2) tempQuery.push(query2);
          if (query3) tempQuery.push(query3);
          if (query4) tempQuery.push(query4);
          finalQuery = tempQuery.join(' || ');
      }
      // Applying the query dynamically
      this.filteredData = this.policyDetails.filter(item => eval(finalQuery));
    }

    }
  

  queryData() {
    const query = this.inputValue.trim();
    if (!query && this.utilityService.isEmptyOrNull(this.policyFilter)) {
      this.filteredData = this.policyDetails;
    }
    else if (!query && !(this.utilityService.isEmptyOrNull(this.policyFilter))) {
      this.filterLogic();
    }
    else if (query && this.utilityService.isEmptyOrNull(this.policyFilter)) {
      this.filteredData = this.policyDetails.filter(item => {
        return item['CustomerName'].toString().toLowerCase().includes(query.toLowerCase()) ||
          item['ProposalNumber'].toString().toLowerCase().includes(query.toLowerCase())
      });
    }
    else if (query && !(this.utilityService.isEmptyOrNull(this.policyFilter))) {
      this.filteredData = this.filteredData.filter(item => {
        return item['CustomerName'].toString().toLowerCase().includes(query.toLowerCase()) ||
          item['ProposalNumber'].toString().toLowerCase().includes(query.toLowerCase())
      });
    }
  }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}