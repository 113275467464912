import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetQuestionnaireForOrionRequestPayload, HealthAilmentRequestPayload, HealthAilmentResponsePayload, HealthPlanMasterRequestPayload, HealthPlanMasterResponsePayload, RelationshipRequestPayload, RelationshipResponsePayload } from '../interface/healthMaster';
import { GenericService } from '../services/generic.service';
import { HomeRenewalRequestPayload, RenewalDataStorage, RenewalDataStorageRequestPayload, ZerotatDataStorageRequestPayload } from '../components/sidemenu/renewals/renewal-storage.modal';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class HealthMasterService {

  constructor(private generic: GenericService,
    private storageService: StorageService) {
  }
  readonly baseURI = `${environment.baseURL}`;
  readonly HealthPlanMasterV1 = 'HealthMaster/HealthPlanMasterV1';
  readonly HealthProposalRelationships = '/GetHealthProposalRelationships';
  readonly HealthAilmentList = '/GetHealthAilmentList';
  readonly QuestionnaireForOrion = '/GetQuestionnaire';
  readonly RenewalFetch = 'RenewalHealth/HealthRenewalFetch';
  readonly ZerotatFetch = 'RenewalHealth/RenewalFetch';
  readonly HomeFetch = 'HomeRenewal/HomeFetchRenewal';
  //readonly zerotatped ='ZeroTatPed'

  getParentURI(): string {
    return this.storageService.getAESDecryptedData('IsOrionJourney') == 'true' ? `OrionHealthMaster` : `HealthMaster`;
  }

  getHealthMasterPlannerV1(data: any): Observable<any> {
    const endpoint = `${this.baseURI}${this.HealthPlanMasterV1}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  getHealthProposalRelationships(data: RelationshipRequestPayload): Observable<RelationshipResponsePayload> {
    const endpoint = `${this.baseURI}${this.getParentURI()}${this.HealthProposalRelationships}`;
    return this.generic.genericService<RelationshipResponsePayload>(endpoint, data);
  }

  getHealthAilmentList(data: HealthAilmentRequestPayload): Observable<HealthAilmentResponsePayload> {
    const endpoint = `${this.baseURI}${this.getParentURI()}${this.HealthAilmentList}`;
    return this.generic.genericService<HealthAilmentResponsePayload>(endpoint, data);
  }

  getQuestionnaireForOrion(data: GetQuestionnaireForOrionRequestPayload): Observable<any> {
    const endpoint = `${this.baseURI}${this.getParentURI()}${this.QuestionnaireForOrion}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  getRenewalFetch(data: RenewalDataStorageRequestPayload): Observable<any> {
    const endpoint = `${this.baseURI}${this.RenewalFetch}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  getZerotatFetch(data: ZerotatDataStorageRequestPayload): Observable<any> {
    const endpoint = `${this.baseURI}${this.ZerotatFetch}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  getHomeFetch(data: HomeRenewalRequestPayload): Observable<any> {
    const endpoint = `${this.baseURI}${this.HomeFetch}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  // getRenewalFetch(data: RenewalDataStorageRequestPayload): Observable<any> {
  //   const endpoint = `https://pana.insurancearticlez.com/health/api/renewalhealth/HealthHAPRenewalFetch`;
  //   return this.generic.genericService<RenewalDataStorage>(endpoint, data);
  // }
  // ZeroTatPed(data:any):Observable<>{
  //   const endpoint =`${this.baseURI}${this.zerotatped}`;
  //   return this.generic.genericService<>(endpoint,data);
  // }
}
