import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { env } from '../environments/env';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from 'src/app/shared/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ApiserviceService {
  authtoken: any;
  constructor(private http: HttpClient,private storageService:StorageService) { }
  private handleError(error: any): Observable<never> {
    console.error('An error occurred:', error);
    return throwError('Something went wrong. Please try again later.');
  }

  // setUserHeaders(data?) {
  //   let headers = new HttpHeaders();
  //   headers = headers.append('Access-Control-Allow-Methods', 'GET,POST');
  //   headers = headers.append('AppName', this.encryptDecryptService.finalRsaEncryption('critishield'));
  //   headers = headers.append("UserName", this.encryptDecryptService.finalRsaEncryption(data.username));
  //   headers = headers.append("Password", this.encryptDecryptService.finalRsaEncryption(data.password));
  //   return headers;
  // }
  
  // createApiToken(postObj: any): Observable<any> {
  //   // console.log('CreateAPItoken')
  //   return this.http.post(env.baseUrl + 'Authenticate', postObj).pipe(catchError(this.handleError))

  //   // return this.http.post(env.baseUrlPlutus + 'Notification/CreatePlutusLoginToken', postObj).pipe(catchError(this.handleError))
  // }

  isUndefineORNull(data: any) {
    if (data == undefined || data == '' || data == null) {
      return true;
    } else {
      return false;
    }
  }

  getData(postObj: any): Observable<any> {
    return this.http.post(env.baseUrl + 'Redirection/SaveToken', postObj);
  }
  getnotificationhistory(): Observable<any> {
    let fcmauthtoken = this.storageService.getAESDecryptedDataLocal('FCMAuthtoken');
     const newHeaders = new HttpHeaders()
          .set('Content-Type', 'application/json charset=utf-8')
          .set('Authorization', `'Bearer'+ ${fcmauthtoken}`)
          .set('Cache-Control', 'no-cache');
    return this.http.get(env.baseUrl + 'Redirection/ViewNotificationHistory',{ headers: newHeaders });
  }

}
