//note
//paynow- with or wothout proposal bundle api and payment init
//paylater -proposal creation and commonpayment api 
//paylater with bundle - proposal creation and befit or home proposal creation and common payment 
//payment-link - proposal and common payment and sendpayment link
//payment-link with bundle - proposal , befit or home proposal and common payment and sendpayment link


import { Subscription } from 'rxjs';
import { userEnums } from '../enums/userEnums';
import { PaymentService } from './payment.service';
import { StorageService } from '../services/storage.service';
import { Injectable } from '@angular/core';
import { PopupService } from '../services/popup.service';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { popupType, popupImgPath, popupHeader, popupDescriptionMsg, popupButton, popupButtonAction } from '../enums/popupEnums';
import { Constants } from '../constants/constants';
import { ChannelData } from 'src/app/shared/interface/swapPrameters';
import { PaymentStatusRequestPayload } from '../interface/payment';
import { UtilityService } from '../services/utility.service';
import { HAPModal } from 'src/app/modules/health/products/hap/hap-modal';

@Injectable({
    providedIn: 'root'
})
export class PaymodePaymentService {
    private subscription: Subscription[] = [];
    channelData: ChannelData;
    hapDataModal: HAPModal = new HAPModal();

    constructor(private utilityService: UtilityService, private paymentService: PaymentService, private storageService: StorageService, private popupService: PopupService) {
        this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
    }

    constructPayNowRequest(data: PaymentStatusRequestPayload) {
        let request;
        request = {
            PolicyID: data.proposalPolicyID,
            UserType: 'Agent',
            CorrelationID: null
        }
        return request;
    }

    payNow(data: PaymentStatusRequestPayload) {
        let payNowRequest = this.constructPayNowRequest(data);
        this.subscription.push(this.paymentService.payNowRequest(payNowRequest).subscribe({
            next: response => {
                if (response.StatusCode == 1 && response.StatusMsg == "Success") {
                    this.storageService.setAESEncryptedDataLocal(userEnums.TotalPremium, data.totalPremium);
                    this.storageService.setAESEncryptedDataLocal(userEnums.ProposalNumber, data.proposalNumber);
                    let url = response.Data.URL;
                    window.location.href = url;
                }
            },
            error: error => {
            }
        }))
    }

    constructCommonPaymentRequest(data: PaymentStatusRequestPayload) {
        let request;
        if (data.befitPlan || data.homePlan) {
            request = {
                "TransType": "BUNDLE_PAYMENT",
                "GatewayReturnURL": "",
                "PolicyIDs": data.proposalPolicyID + ':' + data.bundalPolicyID,
                "PayerType": "Customer",
                "ModeID": 0,
                "UserRole": this.utilityService.isEmptyOrNull(this.channelData.UT) ? "AGENT" : this.channelData.UT,
                "IPAddress": this.utilityService.isEmptyOrNull(this.channelData.CIP) ? this.hapDataModal.ipAddress : this.channelData.CIP,
                "PaymentMode": "None",
                "PaymentAmount": 0
            }
        }
        else if (data.zeroTatProduct) {
            request = {
                "TransType": data.proposalPolicyID.includes(':') ? "BUNDLE_PAYMENT" : "POLICY_PAYMENT",
                "GatewayReturnURL": "",
                "PolicyIDs": data.proposalPolicyID,
                "PayerType": "Customer",
                "ModeID": 0,
                "UserRole": this.utilityService.isEmptyOrNull(this.channelData.UT) ? "AGENT" : this.channelData.UT,
                "IPAddress": this.utilityService.isEmptyOrNull(this.channelData.CIP) ? this.hapDataModal.ipAddress : this.channelData.CIP,
                "PaymentMode": "None",
                "PaymentAmount": 0
            }
        }
        else {
            request = {
                "TransType": "POLICY_PAYMENT",
                "GatewayReturnURL": "",
                "PolicyIDs": data.proposalPolicyID,
                "PayerType": "Customer",
                "ModeID": 0,
                "UserRole": this.utilityService.isEmptyOrNull(this.channelData.UT) ? "AGENT" : this.channelData.UT,
                "IPAddress": this.utilityService.isEmptyOrNull(this.channelData.CIP) ? this.hapDataModal.ipAddress : this.channelData.CIP,
                "PaymentMode": "None",
                "PaymentAmount": 0
            }
        }
        return request;
    }

    commonPayLaterRequest(data: PaymentStatusRequestPayload) {
        // if (data.zeroTatProduct) {
        //     let zerotatPolicyId = data.proposalPolicyID.includes(':') ? data.proposalPolicyID.split(':')[0] : data.proposalPolicyID;
        //     data.proposalPolicyID = zerotatPolicyId;
        // }
        let payLaterRequest = this.constructCommonPaymentRequest(data);
        this.subscription.push(this.paymentService.commonPaymentRequest(payLaterRequest).subscribe({
            next: response => {
                if (response.StatusCode == 1 && response.StatusMsg == "Success") {
                    if (data.paymode == "PayLater") {
                        let action = this.channelData.hasOwnProperty('BFLAG') ? this.channelData.BFLAG == true || this.channelData.BFLAG == "true" ? popupButtonAction.quote : popupButtonAction.dashboard : popupButtonAction.dashboard;
                        let res = '';
                        if (data.befitPlan || data.homePlan) {
                            res = data.proposalNumber + ',' + data.bundleProposalNumber;
                        }
                        else {
                            res = data.proposalNumber;
                        }
                        let popupData: PopupModal = {
                            popupType: popupType.generic,
                            imgName: popupImgPath.successGif,
                            header: popupHeader.success,
                            description: popupDescriptionMsg.proposalId + res,
                            buttonLabel: popupButton.ok,
                            buttonAction: action
                        }
                        this.popupService.openGenericPopup(popupData);
                    }
                    else if (data.paymode == "PaymentLink") {
                        this.sendPaymentLink(data);
                    }
                }

            },
            error: error => {
            }
        }))
    }

    constructPaymentLinkRequest(data: PaymentStatusRequestPayload) {
        let request;
        let policyId = '';
        if (data.proposalPolicyID.includes(':')) {
            policyId = data.proposalPolicyID.split(':')[0];
        } else {
            policyId = data.proposalPolicyID;
        }
        request = {
            EmailID: this.utilityService.isUndefinedORNull(data.emailID) ? '' : data.emailID,
            PolicyID: policyId,
            RequestType: "2",
            MobileNo: this.utilityService.isUndefinedORNull(data.mobileNo) ? '' : data.mobileNo,
        }
        return request;
    }

    sendPaymentLink(data) {
        let paymentLinkNowRequest = this.constructPaymentLinkRequest(data);
        this.subscription.push(this.paymentService.paymentLinkRequest(paymentLinkNowRequest).subscribe({
            next: response => {
                if (response.StatusCode == Constants.statusCode_success && response.StatusMsg == "Success") {
                    let action = this.channelData.hasOwnProperty('BFLAG') ? this.channelData.BFLAG == true || this.channelData.BFLAG == "true" ? popupButtonAction.quote : popupButtonAction.dashboard : popupButtonAction.dashboard;
                    let emailPart = response.Data.EmailID ? `Email Id: ${response.Data.EmailID}` : '';
                    let mobilePart = response.Data.MobileNo ? `Mobile Number: ${response.Data.MobileNo}` : '';
                    let popupData: PopupModal = {
                        popupType: popupType.generic,
                        imgName: popupImgPath.successGif,
                        header: popupHeader.success,
                        description: popupDescriptionMsg.paymentLinkSuccess + emailPart.toLowerCase() + ' ' + mobilePart,
                        buttonLabel: popupButton.ok,
                        buttonAction: action
                    }
                    this.popupService.openGenericPopup(popupData);
                }
            },
            error: error => {
            }
        }))
    }

    CreateBundlePayment(data: PaymentStatusRequestPayload) {
        let paymentPayLaterRequest = this.constructCommonPaymentRequest(data);
        this.subscription.push(this.paymentService.commonPayRequest(paymentPayLaterRequest).subscribe({
            next: response => {
                if (response.StatusCode == 1 && response.StatusMsg == "Success") {
                    this.payNow(data);
                }
            },
            error: error => {
            }
        }))
    }

}
