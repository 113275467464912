import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: 'root'
})
export class GoldenShieldService_T{
    constructor(private http: HttpClient) {

    }
    readonly baseURI = `${environment.baseURL}GoldenShield/`;
    readonly SaveEditQuoteV1 = 'SaveEditQuoteV1';
    readonly SaveEditProposalV1 = 'SaveEditProposalV1';

    saveEditQuoteData(data:any): Observable<any> {
        return this.http.post<any>(`${this.baseURI}${this.SaveEditQuoteV1}`, data);
      }
    
    //   saveEditProposalV1(data: SaveEditProposalV1Request): Observable<SaveEditProposalV1Response> {
    //     return this.http.post<SaveEditProposalV1Response>(`${this.baseURI}${this.SaveEditProposalV1}`, data);
    //   }
}