import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyPolicyComponent } from './components/sidemenu/my-policy/my-policy.component';
import { PendingPaymentComponent } from './components/sidemenu/pending-payment/pending-payment.component';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '../layout/layout.module';
import { PaymentConfirmationComponent } from './components/payment-confirmation/payment-confirmation.component';
import { PaymentDetailsComponent } from './components/payment-details/payment-details.component';
import { PaymentLinkDeclarationsComponent } from './components/payment-link/payment-link-declarations/payment-link-declarations.component';
import { PaymentLinkSummaryComponent } from './components/payment-link/payment-link-summary/payment-link-summary.component';
import { A11yModule } from '@angular/cdk/a11y';
import { SavedQuotesComponent } from './components/sidemenu/saved-quotes/saved-quotes.component';
import { SaveEditPortabilityPopupComponent } from './components/sidemenu/save-edit-portability-popup/save-edit-portability-popup.component';
import { PayInSlipComponent } from './components/sidemenu/pay-in-slip/pay-in-slip.component';
import { PidComponent } from './components/sidemenu/pid/pid.component';
import { FilterPipe } from './pipes/filter.pipe';
import { RenewalSummaryComponent } from './components/sidemenu/renewals/renewal-summary/renewal-summary.component';
import { RenewalProposalComponent } from './components/sidemenu/renewals/renewal-proposal/renewal-proposal.component';
import { RenewalsComponent } from './components/sidemenu/renewals/renewals-list/renewals.component';
import { InstantRenewalComponent } from './components/sidemenu/instant-renewal/instant-renewal.component';
import { BrochureComponent } from './components/sidemenu/brochure/brochure.component';
import { RenewalZerotatSummaryComponent } from './components/sidemenu/renewals/renewal-zerotat-summary/renewal-zerotat-summary.component';
import { HeightInFeetInchesDirective } from './directives/height-in-feet-inches.directive';
import { BookedBusinessReportComponent } from './components/sidemenu/BBR/booked-business-report/booked-business-report.component';
import { CustomCardComponent } from '../layout/custom-card/custom-card.component';
import { CustomTableComponent } from '../layout/custom-table/custom-table.component';
import { HealthClaimComponent } from './components/sidemenu/health-claim/health-claim.component';
import { HealthClaimStatusComponent } from './components/sidemenu/health-claim/components/health-claim-status/health-claim-status.component';
import { NewRenewalSummaryComponent } from './components/sidemenu/renewals/new-renewal-summary/new-renewal-summary.component';
import { RenewalInsuredDetailsComponent } from './components/sidemenu/renewals/renewal-insured-details/renewal-insured-details.component';

import { UpdateKycComponent } from './components/sidemenu/update-kyc/update-kyc.component';
import { ElevateOfflineCalculatorComponent } from './components/offline-calculator/elevate-offline-calculator/elevate-offline-calculator.component';
import { RenewalHomeSummaryComponent } from './components/sidemenu/renewals/renewal-home-summary/renewal-home-summary.component';

@NgModule({
  declarations: [
    MyPolicyComponent,
    PendingPaymentComponent,
    PaymentDetailsComponent,
    PaymentConfirmationComponent,
    PaymentLinkSummaryComponent,
    PaymentLinkDeclarationsComponent,
    SavedQuotesComponent,
    SaveEditPortabilityPopupComponent,
    PayInSlipComponent,
    PidComponent,
    RenewalsComponent,
    RenewalSummaryComponent,
    RenewalProposalComponent,
    RenewalInsuredDetailsComponent,
    InstantRenewalComponent,
    RenewalInsuredDetailsComponent,
    BrochureComponent,
    RenewalZerotatSummaryComponent,
    HeightInFeetInchesDirective,
    BookedBusinessReportComponent,
    CustomCardComponent,
    CustomTableComponent,
    HealthClaimComponent,
    HealthClaimStatusComponent,
    RenewalInsuredDetailsComponent,
    NewRenewalSummaryComponent,
    UpdateKycComponent,
    ElevateOfflineCalculatorComponent,
    RenewalHomeSummaryComponent
  ],

  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    A11yModule,
    FilterPipe
  ]
})
export class SharedModule { }
