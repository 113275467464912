
<div class="loading">
    <h3 class="custom-class">Please wait we are processing your request...</h3>
    <ngx-loading
      [show]="loading"
      [config]="{
        animationType: ngxLoadingAnimationTypes.chasingDots,
        primaryColour: primaryColour,
        secondaryColour: secondaryColour,
        backdropBorderRadius: '3px'
      }"
      [template]="loadingTemplate"
    >
  </ngx-loading>
  </div>