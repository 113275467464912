import { Injectable } from '@angular/core';
import { GenericService } from '../services/generic.service';
import { FetchDetailsAsPerTransactionIDRequestPayload, FetchDetailsAsPerTransactionIDResponsePayload } from '../interface/customer-payment';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerPaymentService {

  constructor(private generic: GenericService) { }

  readonly baseURI = `${environment.baseURL}CustomerPayment/`;
  readonly transactionId = 'PayPolicyDetails';

  fetchDetailsAsPerTransactionId(data: FetchDetailsAsPerTransactionIDRequestPayload): Observable<FetchDetailsAsPerTransactionIDResponsePayload> {
    const endpoint = `${this.baseURI}${this.transactionId}`;
    return this.generic.genericService<FetchDetailsAsPerTransactionIDResponsePayload>(endpoint, data);
  }
}
