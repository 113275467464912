import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private auth: AuthService) { }
  canActivate() {
    return this.auth.check();
  }
  canActivateChild(): boolean | UrlTree {
    return this.auth.check();
  }
}