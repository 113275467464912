import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { FetchCustomerDetailsV1RequestPayload, SearchCustomerRequestPayload, SearchIntermediaryResponsePayload } from 'src/app/shared/interface/customer';
import { PIDRequestPayload, TravelPIDRequestPayload } from 'src/app/shared/interface/payment';
import { AgentV1Service } from 'src/app/shared/proxy-services/agent-v1.service';
import { CustomerService } from 'src/app/shared/proxy-services/customer.service';
import { PaymentService } from 'src/app/shared/proxy-services/payment.service';
import { SessionStorageService, StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-pid',
  templateUrl: './pid.component.html',
  styleUrls: ['./pid.component.scss']
})
export class PidComponent implements OnInit {

  productName: string = '';

  searchText = new FormControl('', Validators.required);
  searchEmailMobile = new FormControl('', Validators.required)
  customerList: any[];
  productNameOptions = [];
  isHealth: boolean = true
  isTravel: boolean = false
  IntermediaryDataList: any[];

  constructor(private customerService: CustomerService, private paymentService: PaymentService,
    private sessionStorageService: SessionStorageService, private router: Router,
    private storageService: StorageService) {

  }

  ngOnInit(): void {
    let agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
    console.log(agentDetails)
    this.productNameOptions = [{
      label: 'Health',
      value: 'health',
      isVisible: true
    }, {
      label: 'Travel',
      value: 'travel',
      isVisible: agentDetails?.MappedProduct?.Travel?.isTRAVELSTUDENTMapped || agentDetails?.MappedProduct?.Travel?.isTRAVELINTERNATIONALMapped
    }];
    this.setDefaultProductName();
  }

  setDefaultProductName(): void {
    const visibleOptions = this.productNameOptions.filter(option => option.isVisible);
    if (visibleOptions.length > 0) {
      this.productName = visibleOptions[0].value;
    }
  }

  onSearch() {
    this.searchText.markAsTouched();
    this.searchEmailMobile.markAsTouched();
    if (this.searchText.value && this.searchText.value != '') { // Condition to check if customer name field is empty
      if (/^\d+$/.test(this.searchText.value)) { // Condition to check if field contains PF customer ID OR Customer Name

        // Block if PF customer ID is entered
        this.searchEmailMobile.removeValidators(Validators.required);
        this.searchEmailMobile.updateValueAndValidity();

        if (this.searchEmailMobile.valid && this.searchText.valid) {
          let request: FetchCustomerDetailsV1RequestPayload = {
            PfCustomerID: this.searchText.value
          }

          this.customerService.fetchCustomerDetailsV1(request).subscribe(res => {
            let tempCustomerModelArr = [{
              PfCustomerId: res.Data.PfCustomerID,
              CustomerName: res.Data.UserName,
              CustomerEmail: res.Data.EmailID,
              CustomerMobile: res.Data.Mobile
            }];
            this.customerList = tempCustomerModelArr;
            this.IntermediaryDataList = [];
          })
        }


      } else {

        // Block if customer Name is entered
        this.searchEmailMobile.addValidators(Validators.required);
        this.searchEmailMobile.updateValueAndValidity();

        if (this.searchEmailMobile.valid && this.searchText.valid) {
          let request: SearchCustomerRequestPayload;
          if (/^\d+$/.test(this.searchEmailMobile.value)) { // Condition to check if field contains Customer Mobile No OR Customer Email ID
            request = {
              CustomerName: this.searchText.value,
              CustomerEmail: '',
              CustomerMobile: this.searchEmailMobile.value,
              PfCustomerId: ''
            }
          } else {
            request = {
              CustomerName: this.searchText.value,
              CustomerEmail: this.searchEmailMobile.value,
              CustomerMobile: '',
              PfCustomerId: ''
            }
          }

          this.customerService.searchCustomerData(request).subscribe(res => {
            this.customerList = res.Data;
          })
        }

      }

    }

  }

  selectCustomer(customerDetails) {

    let requestPayload: PIDRequestPayload = {
      CustomerID: customerDetails.PfCustomerId.toString()
    }

    this.paymentService.getCustomerPIDList(requestPayload).subscribe(res => {
      if (res.Data.Status == "success") {
        this.sessionStorageService.clear();
        // this.router.navigate(['/', routeEnums.LOGIN]);
        window.location.href = res.Data.URL;
      }
    })
  }

  onProductChange(ev: any) {
    const selectedProduct = ev.value;
    if (selectedProduct === 'travel') {
      this.loadTravelIntermediaryData();
    } else {
      this.onSearch();
    }
  }

  loadTravelIntermediaryData() {
    this.customerService.SearchIntermediaryData().subscribe({
      next: (res: SearchIntermediaryResponsePayload) => {
        console.log(res)
        let tempIntermediaryDataArr = [{
          Intermediary_cd: res.Data.Intermediary_cd,
          Name: res.Data.Name,
          Email: res.Data.Email,
          Mobile: res.Data.Mobile
        }];
        this.IntermediaryDataList = tempIntermediaryDataArr;
        this.customerList = [];
      }
    })
  }

  selectTravelCustomer(customerDetails) {

    let requestPayload: TravelPIDRequestPayload = {
      CustomerID: '',
      Intermediary_cd:customerDetails.Intermediary_cd.toString()
    }

    this.paymentService.getTravelerPIDList(requestPayload).subscribe(res => {
      if (res.Data.Status == "success") {
        this.sessionStorageService.clear();
        // this.router.navigate(['/', routeEnums.LOGIN]);
        window.location.href = res.Data.URL;
      }
    })
  }

}
