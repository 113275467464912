export enum routeEnums {
  Design = 'design',
  ROOT = '',
  ROOTSlash = '/',
  LOGIN = 'login',
  DASHBOARD = 'dashboard',
  HEALTH = 'health',
  TRAVEL = 'travel',
  INTERNATIONAL_TRAVEL = 'international-travel',
  STUDENT_MEDICAL_TRAVEL = 'student-medical-travel',
  HEALTH_CHI = 'chi',
  HEALTH_HOSPIFUND = 'hospifund',
  HEALTH_BOOSTER = 'health-booster',
  HAP = 'hap',
  OrionHap = 'orion-hap',
  Elevate='elevate',
  ASP = 'asp',
  MaxProtect = 'max-protect',
  InsuredDetails = 'insured-details',
  Portability = 'portability',
  GoldenShield = 'golden-shield',
  HealthElitePlus = 'health-elite-plus',
  HEALTH_CLAIM = 'health-claim',
  CREATE_CUSTOMER = 'create-customer',
  Summary = 'summary',
  BEFIT = 'befit',
  WILDCARD = '**',
  SWAP = 'swap',
  PLUTOS = 'plts',
  PaymentConfirmation = 'payment-confirmation',
  PendingPayment = 'pending-payment',
  MyPolicies = 'my-policies',
  Brochure = 'brochure',
  BookedBusinessReport = 'booked-business-report',
  HealthClaim = 'health-claim',
  SavedQuotes = 'saved-quotes',
  PaymentLinkSummary = 'payment-link-summary',
  SummaryAsync = 'health/summary',
  DocumentUpload = 'document-upload',
  OldDocumentUpload = 'old-document-upload',
  Familyshield = 'family-shield',
  ZerotatInsuredDetails = 'zero-tat-insuredDetails',
  CritiShield = 'criti-shield',
  PAY_IN_SLIP = 'pay-in-slip',
  PID = 'pid',
  InstantRenewal = 'instant-renewal',
  Renewals = 'renewals',
  RenewalProposal = 'renewal-proposal',
  RenewalSummary = 'renewal-summary',
  ZerotatRenewalSummary = 'zerotat-renewal-summary',
  HomeRenewalSummary = 'home-renewal-summary',
  DEVELOPER = 'developer',
  LOGS = 'logs',
  // TRAVEL = 'travel',
  INTERNATIONALTRAVEL = 'international-travel',
  STUDENTMEDIALTRAVEL = 'student-medical-travel',
  HOME ='home',
  EXECUTE_QUERIES = 'execute-queries',
  Transaction = 'transaction',
  Logout = 'logout',
  ENCRYPT_DECRYPT = 'encrypt-decrypt',
  UAT = 'uat',
  URL_SHORT = 'url-shortener',
  SALES_GENIE = 'sales-genie',
  READ_DATABASE = 'read-database',
  CHNGPASS = 'change-password',
  CLEAR_CACHE_STATIC_VALUES = 'clear-cache-static-values',
  AUTHORIZATION = 'authorization',
  Create_Customer = 'create-customer',
  DevLogout = 'developer-logout',
  TravelSummaryDetails = "travel-summary-details",
  HealthClaimComponent = "HealthClaimComponent",
  UpdateKYC ='update-kyc',
  CalculateEevate = 'calculate-elevate',
  QuotePDF = "quote-pdf",
  Recurring_Payment = 'recurring-rayment',
  EMI_Payment_Link_Summary = 'EMI-payment-link-summary',
  Renewal_Pre_Proposal ='renewal-pre-proposal-list',
  QuickQuote ='quick-quote'

}

export enum declarationLinksEnum {
  privacyPolicy = 'https://ipartner.icicilombard.com/mobileagentapi/ipartnermsite/customer_privacy_policy/index.html',
}
