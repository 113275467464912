<section class="container-fluid">
    <div class="row">
        <div class="container panaMaincontainer">
            <div class="floatdiv floatingedit" *ngIf="scrolling">
                <img class="imgScss" src="../../../../../../assets/images/BBR/ScrollToTop.svg" alt=""
                    (click)="scrollToTop()">
            </div>

            <div class="marginbox">
                <div class="myTransactionTitle">
                    <div class="bbrheading">
                        <span><img src="assets/images/Brochure_outlined_icon.svg" alt=""></span>
                        <span class="titleSpanWidth">
                            <h3 class="titleH3Text">Brochure</h3>
                        </span>
                    </div>
                </div>
                <div class="row m-0">
                    <div class="container productContainer">
                        <div class="row pb-4 mt-4">
                            <div class="col-lg-4 col-md-6 col-sm-12 form-group brochureWidth100"
                                *ngFor="let product of productDetails; let i = index">
                                <div class="dropdown">
                                    <button class="dropdown-toggle customDropdown" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <div class="prodIconsDiv">
                                            <img src="{{product.imagePath}}" alt="">
                                            <span class="prodsNameDiv">{{product.productName}}</span>
                                        </div>
                                        <div class="toggIcon">
                                            <img *ngIf="product.arrowDown == true" src="assets/images/DownArrow.svg"
                                                alt="Down Arrow">
                                            <img *ngIf="product.arrowDown == false" src="assets/images/UpArrow.svg"
                                                alt="Up Arrow">
                                        </div>
                                    </button>
                                    <div class="dropdown-menu customDropItems" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item cs_dropdown-item" (click)="onFetchDoc(i,j)"
                                            *ngFor="let item of product.menuItems; let j = index">
                                            <span>{{item}}</span>
                                            <img class="mll" src="assets/images/download.svg" alt="Download">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>