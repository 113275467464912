import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MaxProtectResponsePayload, SaveEditProposalV2HAPRequestPayload, SaveEditProposalV2RequestPayload, SaveEditProposalV2ResponsePayload } from '../interface/max-protect';
import { ZoneRequestPayload, ZoneResponsePayload, SaveEditProposalV1ResponsePayload } from '../interface/health-advantedge';
import { GenericService } from '../services/generic.service';

@Injectable({
  providedIn: 'root'
})
export class MaxProtectService {

  constructor(private generic: GenericService) {}
  readonly baseURI = `${environment.baseURL}Health/`;
  readonly MaxProtectMasterData = 'MaxProtectMasterData';
  readonly ZoneFromCity = 'GetZonefromCity';
  readonly SaveEditQuoteV2 = 'SaveEditQuoteV2';
  readonly SaveEditProposalV2 = 'SaveEditProposalV2';

  getMaxProtectMasterData(request): Observable<any> {
    const endpoint = `${this.baseURI}${this.MaxProtectMasterData}`;
    return this.generic.genericService<MaxProtectResponsePayload>(endpoint, request);
  }

  getZoneFromCityData(data: ZoneRequestPayload): Observable<ZoneResponsePayload> {
    const endpoint = `${this.baseURI}${this.ZoneFromCity}`;
    return this.generic.genericService<ZoneResponsePayload>(endpoint, data);
  }

  saveEditQuoteData(data): Observable<any> {
    const endpoint = `${this.baseURI}${this.SaveEditQuoteV2}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  saveEditProposalV2(data: SaveEditProposalV2RequestPayload | SaveEditProposalV2HAPRequestPayload): Observable<SaveEditProposalV2ResponsePayload> {
    const endpoint = `${this.baseURI}${this.SaveEditProposalV2}`;
    return this.generic.genericService<SaveEditProposalV2ResponsePayload>(endpoint, data);
  }

}