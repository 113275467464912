<header class="container-fluid fixed-top">
    <div class="row">
        <div class="col-6 dash_humberger">
            <h1>
                <a title="ICICI Lombard" class="logo" style="cursor: pointer;">ICICI Lombard</a>
            </h1>
        </div>

    </div>
</header>
<div class="container">
    <div class="mainEleavteDiv">
        <form [formGroup]="elevateForm" *ngIf="!multipleServiceError">
            <ng-container formGroupName="elevateControls">
                <div class="">
                    <mat-card class="innerbox borderinnerbox producttype marginbox">
                        <h3>Product Name & Type</h3>
                        <div class="fieldsdiv">
                            <div class="row">
                                <!-- product name -->
                                <div class="col-sm-3 form-group order-1 order-sm-1 mb0">
                                    <mat-label class="input-group__label" for="myInput"> Product
                                        Name</mat-label>
                                    <input type="text" matInput class="input-group__input health planselect"
                                        placeholder="Enter product" type="text" formControlName="productName"
                                        id="productName" readonly autocomplete="off">
                                </div>
                                <!-- product type -->
                                <div class="col-sm-2 form-group mt-4 order-2 order-sm-2 mb0">
                                    <div class="productTypeDiv">
                                        <!-- class="Custom_group_btn2" -->

                                        <!-- <input type="radio" class="Cstmradio__input2" value="New" name="productType" id="myradio8"
                                        formControlName="productType" checked (change)="changeInProductType()">
                                    <label class="Cstmradio__label2" for="myradio8">
                                        New</label>
                                    <input type="radio" class="Cstmradio__input2" value="Portability" name="productType"
                                        id="myradio9" formControlName="productType" (change)="changeInProductType()">
                                    <label class="Cstmradio__label2" for="myradio9">
                                        Portability</label> -->

                                        <mat-button-toggle-group class="custom-toggle-group"
                                            formControlName="productType" name="productType"
                                            (click)="changeInProductType()">
                                            <mat-button-toggle class="custom_togglebtn"
                                                *ngFor="let option of elevateDataModal.productType" [value]="option">
                                                {{option}}
                                            </mat-button-toggle>
                                        </mat-button-toggle-group>

                                    </div>
                                </div>
                                <!-- pincode -->
                                <div class="col-xl-3 col-md-4 col-sm-3 form-group order-3 order-sm-3">
                                    <mat-label class="input-group__label" for="myInput">PIN
                                        Code</mat-label>
                                    <input matInput class="input-group__input" placeholder="Enter pincode" type="text"
                                        formControlName="pincode" id="pincode" [minlength]="6" [maxlength]="6"
                                        autocomplete="off" (input)="utilityService.dynamicControlsNumberOnly($event, true);
                                    fetchDataAsPerPincode($event,null)">

                                    <mat-error *ngIf="hasError('pincode', 'required') && formSubmitted">
                                        Pincode is required
                                    </mat-error>

                                    <mat-error
                                        *ngIf="(hasError('pincode','maxlength') || hasError('pincode','minlength')) && formSubmitted">
                                        Atleast 6 digit pincode is required
                                    </mat-error>

                                    <mat-error
                                        *ngIf="!hasError('pincode','minlength') && hasError('pincode','pattern')  && formSubmitted">
                                        Please enter only numbers
                                    </mat-error>

                                    <mat-error *ngIf="invalidPinCodeMessage!== ''">
                                        {{invalidPinCodeMessage}}
                                    </mat-error>

                                </div>
                                <!-- state city -->
                                <div class="col-xl-3 col-md-4 col-sm-3 mb0 order-4 form-group order-sm-4 stateCititeswidth"
                                    *ngIf="cityData.length>0">
                                    <mat-label class="input-group__label" for="myInput">State & Cities</mat-label>
                                    <!-- If length is 1 -->
                                    <input type="text" matInput class="input-group__input" placeholder="Enter pincode"
                                        type="text" formControlName="cityState" readonly *ngIf="cityData.length == 1"
                                        autocomplete="off">


                                    <mat-select formControlName="cityState" id="cityState" required
                                        class="input-group__input  cstm_select" placeholder="Select state and city"
                                        (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                                        (selectionChange)="changeCityName($event.value,true)"
                                        *ngIf="cityData.length > 1">
                                        <ng-container *ngFor="let item of cityData; let last = last">
                                            <mat-option value={{item.value}}>
                                                {{item.stateLabel}} - {{item.cityLabel}}
                                            </mat-option>
                                            <mat-divider *ngIf="!last"></mat-divider>
                                        </ng-container>
                                    </mat-select>
                                    <mat-error *ngIf="hasError('cityState', 'required') && formSubmitted">
                                        Please select atleast one option
                                    </mat-error>
                                </div>


                                <div class="col-xl-3 col-md-2 col-sm-3 form-group order-3 order-sm-3"
                                    *ngIf="!this.utilityService.isEmptyOrNull(this.elevateControls.controls['cityState'].value) ">
                                    <mat-label class="input-group__label" for="myInput">Zone</mat-label>
                                    <input matInput class="input-group__input" placeholder="Enter zone" type="text"
                                        formControlName="zone" autocomplete="off" readonly>
                                </div>

                                <div class="col-sm-2 form-group order-5 order-sm-5"
                                    *ngIf="!this.utilityService.isEmptyOrNull(this.elevateControls.controls['cityState'].value) && zoneUpgradeVisible">
                                    <label class="singlelabel emiToggle" style="width: 100%;">Do you want to upgrade
                                        zone?</label>
                                    <div class="group_btn">
                                        <mat-button-toggle-group formControlName="zoneUpgrade" name="fontStyle"
                                            aria-label="Font Style" class="custom-toggle-group"
                                            (click)="onZoneUpgrade()">
                                            <mat-button-toggle class="custom_togglebtn"
                                                *ngFor="let option of elevateDataModal.options" [value]="option">
                                                {{ option }}
                                            </mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </div>
                                </div>

                                <div class="col-sm-3 form-group order-6 order-sm-6"
                                    *ngIf="elevateControls.controls['zoneUpgrade'].value == 'Yes' && zoneUpgradeVisible">
                                    <div class=" ml-1">
                                        <label class="input-group__label">Do you want to upgrade zone?</label>
                                        <mat-select class="input-group__input cstm_select" formControlName="reviseZone"
                                            id="revisedZone" placeholder="Please select zone"
                                            (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                                            required (selectionChange)="zoneChange($event.value)">
                                            <ng-container *ngFor="let option of zoneOption; let last = last">
                                                <mat-option [value]="option">
                                                    {{option}}
                                                </mat-option>
                                                <mat-divider *ngIf="!last"></mat-divider>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error *ngIf="hasError('reviseZone', 'required') && formSubmitted">
                                            Revise Zone is required
                                        </mat-error>
                                    </div>
                                </div>
                            </div>

                            <!-- zone -->

                        </div>
                    </mat-card>
                </div>

                <!-- Member Detail Card Starts -->
                <mat-card class="innerbox reverinnerbox pt-2">
                    <ng-container *ngIf="zoneMappedWithPincode != ''">
                        <h3>Member Details</h3>
                        <div class="fieldsdiv">
                            <div class="row">
                                <div class="col-sm-12 form-group">
                                    <div class="memberbtnrefilling">
                                        <button class="refilingbtn"
                                            *ngFor="let memberData of elevateDataModal.memberButtonData"
                                            (click)="memberTabClicked($event)">
                                            <!-- Member Icons -->
                                            <div class="refilimg">
                                                <span>
                                                    <img src="{{memberData.imgSrc}}" alt="{{memberData.label}}">
                                                </span>
                                                <strong>{{memberData.label}}</strong>
                                            </div>

                                            <!-- Increment Decryment member operations -->
                                            <div class="Numberbtn" *ngIf="memberData.incDec"
                                                (click)="stopPropogation($event)">
                                                <span (click)="decrymentMember(memberData, $event)">
                                                    <img src="{{memberData.minusImgSrc}}" alt="minus">
                                                </span>
                                                <!-- Member Count -->
                                                <strong>{{memberData.label == 'Adult'? adultValue: childValue}}</strong>

                                                <span (click)="incrementMember(memberData, $event)">
                                                    <img src="{{memberData.plusImgSrc}}" alt="plus">
                                                </span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <mat-divider></mat-divider>

                        <h3 *ngIf="adultDetails.length > 0">Adults</h3>
                        <!-- Adult fields Construction -->
                        <div class="fieldsdiv " *ngIf="adultDetails.length > 0" formArrayName="adultDetails">
                            <ng-container *ngFor="let data of adultDetails.controls; let i = index" [formGroupName]="i">

                                <div class="row">
                                    <div class="col-sm-12 form-group mb-0 mt-2">
                                        <span class="subheading">Adult {{i+1}}</span>
                                    </div>

                                    <div class="col-sm-3 form-group">
                                        <mat-label class="input-group__label" for="myInput">Relationship</mat-label>
                                        <mat-select class="input-group__input cstm_select"
                                            formControlName="{{'adultRealtionship' + (i+1)}}" id="adultDetails" required
                                            (click)="handleMatSelectClick()"
                                            (selectionChange)="adultRelationShip($event.value, i);"
                                            (blur)="removeMatSelectScrollListener()" placeholder="Select Relationship">
                                            <ng-container *ngFor="let item of adultRelationShips; let last = last">
                                                <mat-option value={{item.RelationshipName}}>
                                                    {{item.RelationshipName}}
                                                </mat-option>
                                                <mat-divider *ngIf="!last"></mat-divider>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="error-margin"
                                            *ngIf="adultDetails.value[i]['adultRealtionship' + (i+1)] == '' && formSubmitted">
                                            Adult {{i+1}} Relationship is required
                                        </mat-error>
                                    </div>

                                    <div class="col-sm-3 form-group">
                                        <mat-label class="input-group__label">DOB</mat-label>
                                        <input class="input-group__input dob" matInput [matDatepicker]="adultDOB"
                                            [formControlName]="'dobAdult' + (i+1)" id="adultDetails"
                                            [max]="getAdultInsuredMinMax('Adult','max',i,'')"
                                            [min]="getAdultInsuredMinMax('Adult','min',i,'')"
                                            (dateInput)="adultsDateComparison($event.value,i,'dobAdult' + (i+1))"
                                            (click)="adultDOB.open()" placeholder="DD/MM/YYYY" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="adultDOB">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #adultDOB touchUi>
                                        </mat-datepicker>
                                        <mat-error class="error-margin"
                                            *ngIf="adultDetails.value[i]['dobAdult' + (i+1)] == '' && formSubmitted">
                                            Adult {{i+1}} DOB is required
                                        </mat-error>
                                        <mat-error class="error-margin" *ngIf="adult2DateError">
                                            Adult2 age can not be greater than Adult1
                                        </mat-error>
                                    </div>

                                    <!-- Gender to be asked only for self spouse and employee -->
                                    <div class="col-sm-3 form-group"
                                        *ngIf="neutralGender.includes(adultDetails.value[i]['adultRealtionship' + (i+1)])">
                                        <mat-label class="input-group__label" for="myInput">Gender</mat-label>
                                        <mat-select class="input-group__input cstm_select"
                                            formControlName="{{'Gender' + (i+1)}}" id="adultDetails" required
                                            (click)="handleMatSelectClick()" (selectionChange)="adultGenderChange();"
                                            (blur)="removeMatSelectScrollListener()" placeholder="Select Gender">
                                            <ng-container *ngFor="let item of genderData; let last = last">
                                                <mat-option value={{item.value}}>
                                                    {{item.label}}
                                                </mat-option>
                                                <mat-divider *ngIf="!last"></mat-divider>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="error-margin"
                                            *ngIf="adultDetails.value[i]['Gender' + (i+1)] == '' && formSubmitted">
                                            Adult {{i+1}} Gender is required
                                        </mat-error>
                                    </div>

                                </div>
                                <mat-divider *ngIf="!i"></mat-divider>
                            </ng-container>
                        </div>

                        <mat-divider></mat-divider>

                        <!-- Child Fields Construction -->
                        <h3 *ngIf="childDetails.length > 0">Children</h3>
                        <div class="fieldsdiv " *ngIf="childDetails.length > 0" formArrayName="childDetails">
                            <ng-container *ngFor="let data of childDetails.controls; let i = index" [formGroupName]="i">

                                <div class="row">
                                    <div class="col-sm-12  form-group mb-0 mt-2">
                                        <span class="subheading">Child {{i+1}}</span>
                                    </div>


                                    <div class="col-sm-3 form-group">
                                        <mat-label class="input-group__label" style="top: 13px;"
                                            for="myInput">Relationship</mat-label>
                                        <mat-select class="input-group__input cstm_select"
                                            formControlName="{{'childRelationship' + (i+1)}}" id="childDetails"
                                            placeholder="Relationship with Applicant of child"
                                            (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                                            (selectionChange)="depIndChildRelationship($event.value,i)">
                                            <ng-container
                                                *ngFor="let item of childRelationShips; let i = index; let last = last">
                                                <mat-option value={{item.RelationshipName}}>
                                                    {{item.RelationshipName}}
                                                </mat-option>
                                                <mat-divider *ngIf="!last"></mat-divider>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="error-margin"
                                            *ngIf="childDetails.value[i]['childRelationship' + (i+1)] == '' && formSubmitted">
                                            Child {{i+1}} Relationship is required
                                        </mat-error>
                                    </div>

                                    <div class="col-sm-3 form-group">
                                        <mat-label class="input-group__label" style="top: 13px;">DOB</mat-label>
                                        <input class="input-group__input dob" matInput [matDatepicker]="childDOB"
                                            formControlName="{{'dobChild' + (i+1)}}" id="childDetails"
                                            placeholder="DD/MM/YYYY" readonly [max]="childMinMaxDate[i]['maxDate']"
                                            [min]="childMinMaxDate[i]['minDate']" (click)="childDOB.open()">
                                        <mat-datepicker-toggle matSuffix [for]="childDOB">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #childDOB touchUi>
                                        </mat-datepicker>
                                        <mat-error class="error-margin"
                                            *ngIf="childDetails.value[i]['dobChild' + (i+1)] == '' && formSubmitted">
                                            Child {{i+1}} DOB is required
                                        </mat-error>
                                    </div>

                                    <!-- Gender to be asked only for self spouse and employee -->
                                    <div class="col-sm-3 form-group"
                                        *ngIf="neutralGender.includes(childDetails.value[i]['childRelationship' + (i+1)])">
                                        <mat-label class="input-group__label" for="myInput">Gender</mat-label>
                                        <mat-select class="input-group__input cstm_select"
                                            formControlName="{{'Gender' + (i+1)}}" id="childDetails"
                                            (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                                            placeholder="Select Gender">
                                            <ng-container *ngFor="let item of genderData; let last = last">
                                                <mat-option value={{item.value}}>
                                                    {{item.label}}
                                                </mat-option>
                                                <mat-divider *ngIf="!last"></mat-divider>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="error-margin"
                                            *ngIf="childDetails.value[i]['Gender' + (i+1)] == '' && formSubmitted">
                                            Child {{i+1}} Gender is required
                                        </mat-error>
                                    </div>

                                </div>
                                <mat-divider *ngIf="!i"></mat-divider>
                            </ng-container>
                        </div>
                        <mat-divider></mat-divider>

                    </ng-container>


                    <!-- Premium Details Seection Starts -->
                    <h3>Premium Details</h3>
                    <div class="fieldsdiv">
                        <div class="row ">
                            <!-- Policy Tenure -->
                            <div class="col-sm-6 col-md-6 col-lg-3 policyTenureWidth form-group">
                                <!-- Policy Tenure -->
                                <label for="" class="singlelabel mb-2" style="width:100%">Policy Tenure</label>
                                <div class="policyTenureDiv"><br />
                                    <mat-button-toggle-group formControlName="policyTenure" name="fontStyle"
                                        aria-label="Font Style" class="custom-toggle-group" (click)="emiValueReset()">
                                        <mat-button-toggle class="custom_togglebtn"
                                            *ngFor="let tenureData of elevateDataModal.policyTenure; let j = index"
                                            [value]="tenureData">
                                            {{ tenureData }}
                                        </mat-button-toggle>
                                    </mat-button-toggle-group>
                                    <mat-error
                                        *ngIf="elevateControls.controls['policyTenure']?.errors?.['required'] && formSubmitted">
                                        Policy Tenure is required
                                    </mat-error>
                                </div>
                            </div>
                            <!-- Policy Range -->
                            <div class="col-sm-6 col-lg-2 form-group appIncome" *ngIf="zoneMappedWithPincode != ''">
                                <label for="" class="singlelabel width100 mb-1">SI Range</label>
                                <div>
                                    <mat-button-toggle-group formControlName="policyRange" name="fontStyle"
                                        aria-label="Font Style" class="custom-toggle-group">
                                        <mat-button-toggle class="custom_togglebtn"
                                            *ngFor="let range of policyPlan;let i = index" [value]="range.value"
                                            (change)="changePolicyRange(range)">
                                            {{ range.label }}
                                        </mat-button-toggle>
                                    </mat-button-toggle-group>
                                    <mat-error
                                        *ngIf="elevateControls.controls['policyRange']?.errors?.['required'] && formSubmitted">
                                        Policy Plan is required
                                    </mat-error>
                                </div>
                            </div>
                            <!-- Annual Sum Insured -->
                            <div class="col-sm-3 form-group appIncome"
                                *ngIf="elevateControls.controls['policyRange'].value !='' && zoneMappedWithPincode != ''">
                                <label class="input-group__label" style="top: 13px;">Sum Insured</label>
                                <mat-select class="input-group__input cstm_select"
                                    placeholder="Select applicant sum insured" formControlName="applicantAnnualSum"
                                    id="applicantAnnualSum" required>
                                    <ng-container
                                        *ngFor="let item of sumInsuredDetails; let i = index; let last = last">
                                        <mat-option value="{{item.SI_VALUE}}">
                                            {{utilityService.easyAmountReader(item.SI_VALUE)}}
                                        </mat-option>
                                        <mat-divider *ngIf="!last"></mat-divider>
                                    </ng-container>
                                </mat-select>
                                <mat-error class="error-margin"
                                    *ngIf="elevateControls.controls['applicantAnnualSum']?.errors?.['required'] && formSubmitted">
                                    Sum Insured is required
                                </mat-error>
                            </div>
                            <!-- EMI -->
                            <!-- <div class="col-sm-3 form-group"
                            *ngIf="elevateControls.controls['policyTenure'].value=='1 Year' && elevateControls.controls['productType'].value!='Portability'">
                            <label class="singlelabel emiToggle" style="width: 100%;">Do you want to opt
                                EMI facility?</label>
                            <div class="group_btn">
                                <mat-button-toggle-group formControlName="EMI" name="fontStyle" aria-label="Font Style"
                                    class="custom-toggle-group" (click)="emiValueValidationUpdate()">
                                    <mat-button-toggle class="custom_togglebtn" *ngFor="let option of elevateDataModal.options"
                                        [value]="option">
                                        {{ option }}
                                    </mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                        </div> -->

                            <div class="col-sm-3 form-group">
                                <div class="ml-1"
                                    *ngIf="elevateControls.controls['EMI'].value == 'Yes' && elevateControls.controls['productType'].value!='Portability' && elevateControls.controls['policyTenure'].value =='1 Year'">
                                    <label class="input-group__label" style="top: 13px;">EMI Tenure</label>
                                    <mat-select class="input-group__input cstm_select" placeholder="Select EMI Tenure"
                                        (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                                        formControlName="EMIInstallment" id="EMIInstallment" required>
                                        <ng-container
                                            *ngFor="let option of elevateDataModal.emiInstallments; let last = last">
                                            <mat-option [value]="option">
                                                {{option}}
                                            </mat-option>
                                            <mat-divider *ngIf="!last"></mat-divider>
                                        </ng-container>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="elevateControls.controls['EMIInstallment']?.errors?.['required'] && formSubmitted">
                                        EMI Installment is required
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card>
                <!-- Member Detail Card Ends -->

                <!-- Additional Details Start -->
                <mat-card class="innerbox borderinnerbox">
                    <h3>Additional Details</h3>
                    <div class="fieldsdiv">
                        <div class="row">

                            <!-- Applicant annual income -->
                            <div class="col-sm-3 form-group appIncome">
                                <label class="input-group__label" style="top: 13px;">Applicant Annual Income</label>
                                <mat-select class="input-group__input cstm_select"
                                    placeholder="Select applicant annual income" (click)="handleMatSelectClick()"
                                    (blur)="removeMatSelectScrollListener()" formControlName="applicantAnnualIncome"
                                    id="applicantAnnualIncome">
                                    <ng-container *ngFor="let item of annualIncome; let last = last">
                                        <mat-option value={{item.Name}}>
                                            {{utilityService.easyIncomeReader(item.Name)}}
                                        </mat-option>
                                        <mat-divider *ngIf="!last"></mat-divider>
                                    </ng-container>
                                </mat-select>
                                <mat-error
                                    *ngIf="elevateControls.controls['applicantAnnualIncome']?.errors?.['required'] && formSubmitted">
                                    Annual Income is required
                                </mat-error>
                            </div>

                            <!-- PED selection -->
                            <div class="col form-group">
                                <label class="singlelabel emiToggle" style="width: 100%;">Do any of the insured have any
                                    pre-existing medical condition?</label>
                                <div>
                                    <div class="group_btn col-sm-12 col-lg-2 p-0">
                                        <mat-button-toggle-group formControlName="PEDSelection" name="fontStyle"
                                            aria-label="Font Style" class="custom-toggle-group">
                                            <mat-button-toggle class="custom_togglebtn"
                                                *ngFor="let option of elevateDataModal.options" [value]="option">
                                                {{ option }}
                                            </mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </div>
                                </div>
                            </div>

                            <!-- co pay dropdown -->
                            <!-- <div class="col-sm-3 form-group appIncome">
                            <label class="input-group__label" style="top: 13px;">Co-Payment</label>
                            <mat-select class="input-group__input cstm_select" placeholder="Select Co-Payment"
                                formControlName="copaymentPercentTaken" name="selectedOptionCopay" (selectionChange)="copayVDSelectionChange('copay')">
                                <ng-container *ngFor="let option of coPaymentDetails; let i = index; let last = last">
                                    <mat-option [value]="option">
                                        {{ option }}
                                    </mat-option>
                                    <mat-divider *ngIf="!last"></mat-divider>
                                </ng-container>
                            </mat-select>
                        </div> -->

                            <!-- VD dropdown -->
                            <!-- <div class="col-sm-3 form-group appIncome">
                            <label class="input-group__label" style="top: 13px;">VD Value</label>
                            <mat-select class="input-group__input cstm_select" placeholder="Select voluntary Deductible"
                                formControlName="voluntaryDeductible" name="voluntaryDeductible" (selectionChange)="copayVDSelectionChange('VD')">
                                <ng-container *ngFor="let option of elevateDataModal.voluntaryDeductibleDetails; let i = index; let last = last">
                                    <mat-option [value]="option">
                                        {{ option }}
                                    </mat-option>
                                    <mat-divider *ngIf="!last"></mat-divider>
                                </ng-container>
                            </mat-select>
                        </div>-->
                        </div>

                        <mat-divider></mat-divider>

                        <!-- cibil toggle -->
                        <div class="row pt-3">
                            <div class="col form-group">
                                <label class="singlelabel emiToggle" style="width: 100%;">Do you want to opt for a
                                    discount
                                    based on
                                    your bureau score?</label>
                                <div class="cibilDiv">
                                    <div class="group_btn col-sm-12 col-lg-2 col-md-3">
                                        <mat-button-toggle-group formControlName="cibilDiscount" name="fontStyle"
                                            aria-label="Font Style" class="custom-toggle-group" (click)="cibilChange()">
                                            <mat-button-toggle class="custom_togglebtn"
                                                *ngFor="let option of elevateDataModal.options" [value]="option">
                                                {{ option }}
                                            </mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </div>
                                    <div class="customaddons col-sm-12 col-lg-10 col-md-12"
                                        *ngIf="this.elevateControls.controls['cibilDiscount'].value=='Yes'">
                                        <span class="d-flex pl-0">
                                            <img src=".../../../../../../../assets/images/Waiting Period.svg"
                                                class="waiting">
                                            <span class="waitinglabel">To avail the Cibil Discount, ensure accurate
                                                alignment
                                                with your KYC records. Discrepancies will affect eligibility.</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- proposal and mobile feild for cibil -->
                        <div class="row" *ngIf="this.elevateControls.controls['cibilDiscount'].value=='Yes'">

                            <div class="col-sm-12 updateKYC">
                                <div class="verifyDiv bgVerify" *ngIf="cibilScore != 0 && cibilSubmitted"
                                    style="margin-bottom: 3;">
                                    <span><img src="../../../assets/images/complete.gif" alt=""></span>
                                    <span>You are eligible for Cibil Discount</span>
                                </div>
                            </div>

                            <div class="col-sm-3 form-group">
                                <mat-label class="input-group__label" for="myInput">Proposer Name</mat-label>
                                <input matInput class="input-group__input" placeholder="Enter Proposer Name" type="text"
                                    (keydown)="proposerNameInput($event)" formControlName="proposerName"
                                    id="proposerName" autocomplete="off">
                                <mat-error *ngIf="elevateControls.get('proposerName').invalid && cibilSubmitted">
                                    Proposer Name is required
                                </mat-error>
                            </div>
                            <div class="col-sm-3 form-group">
                                <mat-label class="input-group__label" for="myInput">Mobile Number</mat-label>
                                <input matInput class="input-group__input" placeholder="Enter mobile no" type="text"
                                    (keydown)="mobNoInput($event)" formControlName="mobileNo" id="mobileNo"
                                    [minlength]="10" [maxlength]="10" autocomplete="off">
                                <mat-error *ngIf="elevateControls.get('mobileNo').invalid && cibilSubmitted">
                                    Please enter a valid Mobile Number (10 digits)
                                </mat-error>
                            </div>
                        </div>
                        <!-- save button for cibil -->
                        <div class="row" *ngIf="elevateControls.controls['cibilDiscount'].value=='Yes'">
                            <div class="col-sm-3 form-group">
                                <button mat-button type="submit" (click)="onSubmit()"
                                    [ngStyle]="{'background-color': bgColor, 'color': textColor}">
                                    <span>Save</span></button>
                            </div>
                        </div>

                        <mat-divider></mat-divider>
                        <div class="row mt-2">
                            <div class="col-sm-12 customaddons pedAddons">
                                <span>
                                    <img src=".../../../../../../../assets/images/Waiting Period.svg"
                                        alt="Waiting period & pre / post hospital duration" class="waiting">
                                    <label for="" class="waitinglabel">Waiting period & pre / post hospital
                                        duration</label>
                                </span>
                                <!-- <ul col-sm-3>
                                        <li>
                                            <span>
                                                <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                            </span>
                                            <span class="waitingperiodSpan">Initial waiting period is 30 days</span>
                                        </li>
                                        <li>
                                            <span>
                                                <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                            </span>
                                            <span class="waitingperiodSpan">PED waiting period is 3 years</span>
                                        </li>
                                        <li>
                                            <span>
                                                <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                            </span>
                                            <span class="waitingperiodSpan">Specific disease waiting period is 2 years</span>
                                        </li>
                                        <li>
                                            <span>
                                                <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                            </span>
                                            <span class="waitingperiodSpan">Maternity waiting period is 2 years</span>
                                        </li>
                                        <li>
                                            <span>
                                                <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                            </span>
                                            <span class="waitingperiodSpan">Bariatric surgery waiting period is 2 years.</span>
                                        </li>
                                        <li>
                                            <span>
                                                <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                            </span>
                                            <span class="waitingperiodSpan">Worlwide cover waiting period is 2 years</span>
                                        </li>
                                        <li>
                                            <span>
                                                <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                            </span>
                                            <span class="waitingperiodSpan">CI waiting period is 90 days</span>
                                        </li>
                                        <li>
                                            <span>
                                                <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                            </span>
                                            <span class="waitingperiodSpan">Waiting period for Diabetes, HTN & Cardiac conditions unless
                                                PED is 90 days</span>
                                        </li>
                                    </ul> -->

                                <!-- Waiting Period Details-->
                                <div class="waitingPeriod">
                                    <div class="col p-0 waitingPeriodSection">
                                        <div class="col waitingPeriodMessage">
                                            <span>
                                                <img src="../../../../assets/images/miniTick.svg" alt=""
                                                    class="miniTick">
                                            </span>
                                            <span class="waitingperiodSpan">Initial waiting period is 30 days</span>
                                        </div>

                                        <div class="col waitingPeriodMessage">
                                            <span>
                                                <img src="../../../../assets/images/miniTick.svg" alt=""
                                                    class="miniTick">
                                            </span>
                                            <span class="waitingperiodSpan">PED waiting period is 3 years</span>
                                        </div>
                                    </div>
                                    <div class="col p-0 waitingPeriodSection">
                                        <div class="col waitingPeriodMessage">
                                            <span>
                                                <img src="../../../../assets/images/miniTick.svg" alt=""
                                                    class="miniTick">
                                            </span>
                                            <span class="waitingperiodSpan">Specific disease waiting period is 2
                                                years</span>
                                        </div>

                                        <div class="col waitingPeriodMessage">
                                            <span>
                                                <img src="../../../../assets/images/miniTick.svg" alt=""
                                                    class="miniTick">
                                            </span>
                                            <span class="waitingperiodSpan">Maternity waiting period is 2 years</span>
                                        </div>
                                    </div>

                                    <!--Second Waiting Period Message-->
                                    <div class="col p-0 waitingPeriodSection">
                                        <div class="col waitingPeriodMessage">
                                            <span>
                                                <img src="../../../../assets/images/miniTick.svg" alt=""
                                                    class="miniTick">
                                            </span>
                                            <span class="waitingperiodSpan">Bariatric surgery waiting period is 2
                                                years</span>
                                        </div>

                                        <!-- <div class="col waitingPeriodMessage">
                                                    <span>
                                                        <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                                    </span>
                                                    <span class="waitingperiodSpan">Worlwide cover waiting period is 2 years</span>
                                                </div> -->
                                    </div>

                                    <div class="col p-0 waitingPeriodSection">
                                        <div class="col waitingPeriodMessage">
                                            <span>
                                                <img src="../../../../assets/images/miniTick.svg" alt=""
                                                    class="miniTick">
                                            </span>
                                            <span class="waitingperiodSpan">CI waiting period is 90 days</span>
                                        </div>

                                        <div class="col waitingPeriodMessage">
                                            <span>
                                                <img src="../../../../assets/images/miniTick.svg" alt=""
                                                    class="miniTick">
                                            </span>
                                            <span class="waitingperiodSpan">Waiting period for Diabetes, HTN & Cardiac
                                                conditions
                                                unless
                                                PED is 90 days</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <!-- NRI discount -->
                        <!--  (click)="NRIReset()" -->
                        <!-- <div class="row pt-3">
                        <div class="col-sm-3 form-group order-5 order-sm-5">
                            <label class="singlelabel emiToggle" style="width: 100%;">Are you NRI?</label>
                            <div class="group_btn">
                                <mat-button-toggle-group formControlName="NRIToggle" name="fontStyle" aria-label="Font Style"
                                    class="custom-toggle-group">
                                    <mat-button-toggle class="custom_togglebtn" *ngFor="let option of elevateDataModal.options"
                                        [value]="option">
                                        {{ option }}
                                    </mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                        </div> -->
                        <!-- <div class="col-sm-3 form-group order-5 order-sm-5"
                            *ngIf="elevateControls.controls['NRIToggle'].value=='Yes'">
                            <label class="singlelabel emiToggle" style="width: 100%;">Do you want to opt for NRI
                                discount?</label>
                            <div class="group_btn">
                                <mat-button-toggle-group formControlName="NRIDiscount" name="fontStyle" aria-label="Font Style"
                                    class="custom-toggle-group">
                                    <mat-button-toggle class="custom_togglebtn" *ngFor="let option of elevateDataModal.options"
                                        [value]="option">
                                        {{ option }}
                                    </mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                        </div> -->
                        <!-- </div> -->
                        <!-- waiting pre/post hospitalisation durations -->
                    </div>
                </mat-card>
            </ng-container>

            <!-- Additional Addon Details -->
            <!-- Additional Addon Details -->
            <!-- <mat-card class="innerbox borderinnerbox">
        <h3>Additional Addon Details</h3>
        <div class="fieldsdiv">
            <div class="row">
                <ng-container *ngIf="showMoreCriticaldatePicker || showMoreAddonsOccupation">

                    <div formGroupName="moreAddons" class="moreAddonsGroup">
                        <div class="form-group" formArrayName="occupation" *ngIf="showMoreAddonsOccupation">
                            <ng-container *ngFor="let occData of occupation.controls; let i = index"
                                [formGroupName]="i">
                                <label class="input-group__label" style="top: 13px;">Occupation For
                                    {{occupation.value[i]['occLabel'] + ' '
                                    +occupation.value[i]['memberIndex']}}</label>
                                <mat-select class="input-group__input cstm_select" placeholder="Select Occupation"
                                    (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                                    formControlName="{{'occupationFor'+(occupation.value[i]['occLabel'])+(occupation.value[i]['memberIndex'])}}"
                                    (selectionChange)="selectOccupationOption($event.value,i)">
                                    <mat-option *ngFor="let item of occupationMaster" [value]="item.value">
                                        {{item.value}}
                                    </mat-option>
                                </mat-select>

                                <mat-error class="error-margin"
                                    *ngIf="moreAddonsOccupation.value[i]['occupationFor'+(occupation.value[i]['occLabel'])+(occupation.value[i]['memberIndex'])] == '' && formSubmitted">
                                    Occupation is required
                                </mat-error>
                            </ng-container>
                        </div>

                        Critical Illness Datepicker
                        <div class="form-group" *ngIf="showMoreCriticaldatePicker">
                            <mat-label class="input-group__label" style="top: 13px;">Proposer
                                DOB</mat-label>
                            <input class="input-group__input dob" matInput [matDatepicker]="criticalDOB"
                                formControlName="proposerDobCi" id="proposerDobCi"
                                [max]="getAdultInsuredMinMax('Adult','max',0,'')"
                                [min]="getAdultInsuredMinMax('Adult','min',0,'')" (click)="criticalDOB.open()"
                                placeholder="DD/MM/YYYY" readonly>
                            <mat-datepicker-toggle matSuffix [for]="criticalDOB">
                            </mat-datepicker-toggle>
                            <mat-datepicker #criticalDOB touchUi>
                            </mat-datepicker>
                            <mat-error class="error-margin"
                                *ngIf="moreAddons.controls['proposerDobCi'].value == '' && formSubmitted">
                                Proposer DOB is required
                            </mat-error>
                        </div>

                    </div>
                </ng-container>

            </div>
            <mat-divider *ngIf="showMoreCriticaldatePicker || showMoreAddonsOccupation"></mat-divider>

            <div class="row mt-2">
                <div class="col-sm-12 customaddons pedAddons">
                    <span>
                        <img src=".../../../../../../../assets/images/Waiting Period.svg"
                            alt="Waiting period & pre / post hospital duration" class="waiting">
                        <label for="" class="waitinglabel">Waiting period & pre / post hospital duration</label>
                    </span>
                  <ul col-sm-3>
                            <li>
                                <span>
                                    <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                </span>
                                <span class="waitingperiodSpan">Initial waiting period is 30 days</span>
                            </li>
                            <li>
                                <span>
                                    <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                </span>
                                <span class="waitingperiodSpan">PED waiting period is 3 years</span>
                            </li>
                            <li>
                                <span>
                                    <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                </span>
                                <span class="waitingperiodSpan">Specific disease waiting period is 2 years</span>
                            </li>
                            <li>
                                <span>
                                    <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                </span>
                                <span class="waitingperiodSpan">Maternity waiting period is 2 years</span>
                            </li>
                            <li>
                                <span>
                                    <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                </span>
                                <span class="waitingperiodSpan">Bariatric surgery waiting period is 2 years.</span>
                            </li>
                            <li>
                                <span>
                                    <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                </span>
                                <span class="waitingperiodSpan">Worlwide cover waiting period is 2 years</span>
                            </li>
                            <li>
                                <span>
                                    <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                </span>
                                <span class="waitingperiodSpan">CI waiting period is 90 days</span>
                            </li>
                            <li>
                                <span>
                                    <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                </span>
                                <span class="waitingperiodSpan">Waiting period for Diabetes, HTN & Cardiac conditions unless
                                    PED is 90 days</span>
                            </li>
                        </ul>

                    <div class="waitingPeriod">
                        <div class="col p-0 waitingPeriodSection">
                            <div class="col waitingPeriodMessage">
                                <span>
                                    <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                </span>
                                <span class="waitingperiodSpan">Initial waiting period is 30 days</span>
                            </div>

                            <div class="col waitingPeriodMessage">
                                <span>
                                    <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                </span>
                                <span class="waitingperiodSpan">PED waiting period is 3 years</span>
                            </div>
                        </div>
                        <div class="col p-0 waitingPeriodSection">
                            <div class="col waitingPeriodMessage">
                                <span>
                                    <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                </span>
                                <span class="waitingperiodSpan">Specific disease waiting period is 2 years</span>
                            </div>

                            <div class="col waitingPeriodMessage">
                                <span>
                                    <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                </span>
                                <span class="waitingperiodSpan">Maternity waiting period is 2 years</span>
                            </div>
                        </div>

                       Second Waiting Period Message
                        <div class="col p-0 waitingPeriodSection">
                            <div class="col waitingPeriodMessage">
                                <span>
                                    <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                </span>
                                <span class="waitingperiodSpan">Bariatric surgery waiting period is 2 years</span>
                            </div>

                        <div class="col waitingPeriodMessage">
                                        <span>
                                            <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                        </span>
                                        <span class="waitingperiodSpan">Worlwide cover waiting period is 2 years</span>
                                    </div>
                        </div>

                        <div class="col p-0 waitingPeriodSection">
                            <div class="col waitingPeriodMessage">
                                <span>
                                    <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                </span>
                                <span class="waitingperiodSpan">CI waiting period is 90 days</span>
                            </div>

                            <div class="col waitingPeriodMessage">
                                <span>
                                    <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                </span>
                                <span class="waitingperiodSpan">Waiting period for Diabetes, HTN & Cardiac
                                    conditions
                                    unless
                                    PED is 90 days</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    </mat-card> -->

            <!-- Additional Details Ends -->

            <!-- Additional Covers Start -->
            <!-- <mat-card class="innerbox borderinnerbox"
                *ngIf="!utilityService.isEmptyOrNull(elevateControls.controls['applicantAnnualSum'].value) && this.elevateAddons.length > 0">
                <h3>Additional Cover</h3>
                <ng-container *ngIf="(adultDetails.length > 0)">
                    <div class="customaddons pl-3 pr-3" [ngStyle]="{'border-radius': hasPedAddons ? '8px' : '0px'}">
                        <span class="d-flex pl-0">
                            <img src=".../../../../../../../assets/images/Waiting Period.svg" class="waiting">
                            <label class="waitinglabel">You can only choose either critical illness coverage or pre-existing
                                disease coverage, but not both. Pre-existing conditions are listed on the insured page.</label>
                        </span>
                    </div>
                </ng-container>
        
                <ng-container *ngFor="let addonsData of elevateAddons; let i = index" formGroupName="addons">
        
                    <div class="fieldsdiv" [ngClass]="getDynamicClasses(i)" *ngIf="!addonsData.hide">
                         <div class="row"> 
                        <div class=" customCheckslist">
                            <ul>
        
                                <li *ngFor="let childData of addonsData['childAddons']; let i = index">
        
                                    <mat-checkbox *ngIf="childData.visiblityAsPerService" class="cstmCheckbox"
                                        [checked]="childData['checkboxValue']['checkedAddon']"
                                        [disabled]="childData['checkboxValue']['disbaledAddon']"
                                        formControlName="{{childData.label}}"
                                        (change)="changeInAddonCover(addonsData,childData,$event)">{{childData.label}}</mat-checkbox>
        
                                    (change)="changeInAddonCover(addonsData,childData,true,$event)" 
                                </li>
        
        
        
        
                             </ng-container>
                            </ul>
                        </div>
                       </div>
        
                    </div>
                    <mat-divider *ngIf="addonsData['showDivider']"></mat-divider>
                </ng-container>
        
            </mat-card> -->
            <!-- Additional Covers Ends -->
            <!-- BUI Addons html -->
            <mat-card #addonsMatCard class="innerbox borderinnerbox" id="addonsCard" *ngIf="BUIresponse">
                <h4 class="elevated-heading text-center" *ngIf="this.elevateBUIAddon.length>1 && !this.hideBUIService">
                    Based on
                    the profile of <span>your customer</span>, we
                    would recommend the following plans</h4>
                <div class="fieldsdiv">
                    <div class="row">
                        <!-- BUI addons -->
                        <ng-container *ngIf="this.elevateBUIAddon.length>1 && !this.hideBUIService">
                            <div class="elevate-main-card">
                                <!-- plan card starts -->
                                <div class="col-sm-12 form-group">
                                    <div class="plan-scroll-area">
                                        <div class="plans">
                                            <div class="plan-card" *ngFor="let data of elevateBUIAddon"
                                                [class.selected]="isSelectedPlan(data)">
                                                <input type="radio" id="{{data.CartType}}" name="plans"
                                                    [checked]="isSelectedPlan(data)" (change)="onSelectPlan(data)" />
                                                <label for="{{data.CartType}}">
                                                    <div class="card-space">
                                                        <h2 *ngIf="data.CartType !== 'Recommended'">{{ data.CartType }}
                                                        </h2>
                                                        <h2 *ngIf="data.CartType === 'Recommended'"
                                                            class="recommended-heading">
                                                            Recommended
                                                            <span class="most-popular">
                                                                <img src="../../../../../assets/icons/elevate/elevated-cover/mostpopular.svg"
                                                                    alt="" />
                                                                Most Popular
                                                            </span>
                                                        </h2>
                                                        <p>
                                                            <strong>₹ {{
                                                                utilityService.convertStringtoCurrency(data.TotalPremium)
                                                                }}
                                                                /</strong> yearly
                                                        </p>
                                                        <hr />
                                                        <strong class="sub-strong"
                                                            *ngIf="data.CartType === 'Essential'">Base +
                                                            {{ data.CartType }}
                                                            Package Includes:</strong>
                                                        <strong class="sub-strong"
                                                            *ngIf="data.CartType === 'Recommended'">Essential + {{
                                                            data.CartType
                                                            }}
                                                            Package Includes:</strong>
                                                        <strong class="sub-strong"
                                                            *ngIf="data.CartType === 'Basic'">In-Built
                                                            :</strong>
                                                        <div class="scroll-area-addons">
                                                            <ul>
                                                                <li
                                                                    *ngFor="let addonData of getCombinedAddOns(data).slice(0, 5)">
                                                                    <span>
                                                                        <img src="../../../../../assets/icons/elevate/elevated-cover/greenTick.svg"
                                                                            alt="" />
                                                                    </span>
                                                                    {{ addonData.CoverText }}
                                                                    <ng-container *ngIf="addonData.CoverId === 96">
                                                                        <ng-container
                                                                            *ngIf="elevateControls.controls['applicantAnnualSum'].value >= 1000000">
                                                                            - Upgrade
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="elevateControls.controls['applicantAnnualSum'].value == 500000 || elevateControls.controls['applicantAnnualSum'].value == 750000">
                                                                            - 1% of SI
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </li>
                                                            </ul>
                                                            <ul class="p-0" *ngIf="isMoreBenefitsVisible(data)">
                                                                <li
                                                                    *ngFor="let addonData of getCombinedAddOns(data).slice(5)">
                                                                    <span>
                                                                        <img src="../../../../../assets/icons/elevate/elevated-cover/greenTick.svg"
                                                                            alt="" />
                                                                    </span>{{ addonData.CoverText }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="more-benefit-list"
                                                            (click)="toggleMoreBenefits(data)"
                                                            *ngIf="getCombinedAddOns(data).length > 5">
                                                            <button>{{ isMoreBenefitsVisible(data) ? 'Hide more
                                                                benefits' :
                                                                '+ ' +
                                                                (getCombinedAddOns(data).length - 5) + ' more benefits'
                                                                }}</button>
                                                        </div>

                                                    </div>

                                                    <div class="select-btn" (click)="onSelectPlan(data)">
                                                        {{ isSelectedPlan(data) ? 'Selected' : 'Select Plan' }}
                                                    </div>
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <!-- plan card end -->

                                <!-- plan card tab starts -->
                                <div class="col-sm-12 form-group">
                                    <mat-tab-group class="elevated-tabs-group">
                                        <!-- comparision tab starts -->
                                        <mat-tab class="elevated-tab">
                                            <ng-template mat-tab-label>
                                                Compare Plans
                                            </ng-template>
                                            <div class="tab-content">
                                                <div class=" form-group">
                                                    <div class="selected-cover-details"
                                                        *ngIf="!this.utilityService.isEmptyOrNull(this.selectedPlan)">
                                                        <div class="selected-plan">
                                                            Selected Plan: <span>{{selectedPlan?.CartType}}</span>
                                                        </div>
                                                        <div class="selected-plan">
                                                            Total Covers:
                                                            <span>
                                                                {{totalAddonCount}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="elevated-cover-table"
                                                        *ngIf="!this.utilityService.isEmptyOrNull(elevateBUIAddon)">
                                                        <table class="elevated-add_ons-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Add ons</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let addon of elevateBUICovertextAddons; let i = index">
                                                                    <td>
                                                                        {{ addon.CoverText }}
                                                                        <ng-container *ngIf="addon.CoverId === 96">
                                                                            <ng-container
                                                                                *ngIf="elevateControls.controls['applicantAnnualSum'].value >= 1000000">
                                                                                - Upgrade
                                                                            </ng-container>
                                                                            <ng-container
                                                                                *ngIf="elevateControls.controls['applicantAnnualSum'].value == 500000 || elevateControls.controls['applicantAnnualSum'].value == 750000">
                                                                                - 1% of SI
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <table class="plans-selected">
                                                            <thead>
                                                                <tr>
                                                                    <th *ngFor="let plan of elevateBUIAddon">
                                                                        {{plan.CartType}}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let addon of elevateBUICovertextAddons; let i = index">
                                                                    <td *ngFor="let plan of elevateBUIAddon">
                                                                        <img src="{{ doesCoverTextExistForCartType(addon.CoverText, plan.CartType) ? 'assets/icons/elevate/elevated-cover/greenTick.svg' : 'assets/icons/elevate/elevated-cover/cancel.svg' }}"
                                                                            alt="">
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-tab>
                                        <!-- comparision tab ends -->

                                        <!-- more addon tab ends -->
                                        <mat-tab class="elevated-tab" formGroupName="moreAddons">
                                            <ng-template mat-tab-label>
                                                More Add-ons
                                            </ng-template>
                                            <div class="tab-content">
                                                <div class="col-sm-12 form-group">
                                                    <div class="selected-cover-details">
                                                        <div class="selected-plan">
                                                            Add ons
                                                        </div>
                                                        <div class="selected-plan">
                                                            Available Covers: <span>{{moreAddonActivatedCount}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="fieldsdiv mobifields">
                                                    <div class="row">
                                                        <div class="col-sm-12 form-group customCheckslist">
                                                            <ul class="moreColumns">
                                                                <ng-container
                                                                    *ngFor="let addons of this.elevateAllAddons">
                                                                    <li *ngIf="addons.IsVisible">
                                                                        <mat-checkbox
                                                                            [ngClass]="{'checkboxDisabled': addons.disabled, 'cstmCheckbox': !addons.disabled}"
                                                                            formControlName="{{addons.CoverText}}"
                                                                            (change)="changeInMoreAddons(addons,$event,true)">
                                                                            {{addons.CoverText}}
                                                                        </mat-checkbox>
                                                                    </li>
                                                                </ng-container>

                                                            </ul>
                                                        </div>

                                                        <div class="col-sm-12 form-group"
                                                            *ngIf="showMoreCriticaldatePicker || showMoreAddonsOccupation">
                                                            <div class="additionAddon">
                                                                Additional Addon Details
                                                            </div>
                                                            <div class="moreAddonsGroup">
                                                                <div class="form-group" formArrayName="occupation"
                                                                    *ngIf="showMoreAddonsOccupation">
                                                                    <ng-container
                                                                        *ngFor="let occData of occupation.controls; let i = index"
                                                                        [formGroupName]="i">
                                                                        <label class="input-group__label"
                                                                            style="top: 13px;">Occupation For
                                                                            {{occupation.value[i]['occLabel'] + ' '
                                                                            +occupation.value[i]['memberIndex']}}</label>
                                                                        <mat-select
                                                                            class="input-group__input cstm_select"
                                                                            placeholder="Select Occupation"
                                                                            (click)="handleMatSelectClick()"
                                                                            (blur)="removeMatSelectScrollListener()"
                                                                            formControlName="{{'occupationFor'+(occupation.value[i]['occLabel'])+(occupation.value[i]['memberIndex'])}}"
                                                                            (selectionChange)="selectOccupationOption($event.value,i,true)">
                                                                            <mat-option
                                                                                *ngFor="let item of occupationMaster"
                                                                                [value]="item.value">
                                                                                {{item.value}}
                                                                            </mat-option>
                                                                        </mat-select>

                                                                        <mat-error class="error-margin"
                                                                            *ngIf="moreAddonsOccupation.value[i]['occupationFor'+(occupation.value[i]['occLabel'])+(occupation.value[i]['memberIndex'])] == '' && formSubmitted">
                                                                            Occupation is required
                                                                        </mat-error>
                                                                    </ng-container>
                                                                </div>

                                                                <!-- Critical Illness Datepicker -->
                                                                <div class="form-group"
                                                                    *ngIf="showMoreCriticaldatePicker">
                                                                    <mat-label class="input-group__label"
                                                                        style="top: 13px;">Proposer
                                                                        DOB</mat-label>
                                                                    <input class="input-group__input dob" matInput
                                                                        [matDatepicker]="criticalDOB"
                                                                        formControlName="proposerDobCi"
                                                                        id="proposerDobCi"
                                                                        [max]="getAdultInsuredMinMax('Adult','max',0,'')"
                                                                        [min]="getAdultInsuredMinMax('Adult','min',0,'')"
                                                                        (click)="criticalDOB.open()"
                                                                        placeholder="DD/MM/YYYY" readonly>
                                                                    <mat-datepicker-toggle matSuffix
                                                                        [for]="criticalDOB">
                                                                    </mat-datepicker-toggle>
                                                                    <mat-datepicker #criticalDOB touchUi>
                                                                    </mat-datepicker>
                                                                    <mat-error class="error-margin"
                                                                        *ngIf="moreAddons.controls['proposerDobCi'].value == '' && formSubmitted">
                                                                        Proposer DOB is required
                                                                    </mat-error>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div class="col-sm-12 form-group">
                                                            <div class="disclaimer-div">
                                                                <img src="../../../../../assets/images/Waiting Period.svg"
                                                                    alt="">
                                                                <p>You can only choose either Critical Illness coverage
                                                                    or
                                                                    Pre-Existing Disease coverage, but not both. If you
                                                                    choose
                                                                    Pre-existing Disease coverage, pre-existing disease
                                                                    conditions can be selected on the applicant page</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-tab>
                                        <!-- more addon tab ends -->
                                    </mat-tab-group>
                                </div>
                                <!--plan card tab ends -->

                                <!-- toggle for changing cart to build your our plan -->

                                <div class="col-sm-12 text-center mt-2 mb-2"
                                    *ngIf=" !hideDIYAddon && elevateAddons.length > 0">
                                    <button class="custom-plan-btn" (click)="resetForm(true)">Build your own
                                        plan</button>
                                </div>
                            </div>
                        </ng-container>
                        <!-- DIY addons -->

                        <div class="elevate-main-card" *ngIf="hideBUIService && elevateAddons.length > 0">
                            <div class="col-sm-12 form-group">
                                <div class="customize-heading-area">
                                    <h4 class="customize-heading"><span>Customize</span> your plan</h4>
                                    <p>We have a complete menu of covers that you can pick and choose from to build a
                                        tailor
                                        made plan for your customers</p>
                                </div>
                            </div>
                            <!-- Prachi customise addons -->


                            <div class="customize-addons-section form-group">
                                <div class="" *ngIf="showCustomAddons">
                                    <div class="customize-plan">

                                        <div class="customize-covers-table">
                                            <div class="maindivfilter">
                                                <ul class="nav nav-tabs customTabs" id="myTab" role="tablist">
                                                    <ng-container
                                                        *ngFor="let parentAddons of elevateAddons; let i = index;">
                                                        <li class="nav-item" (click)="tabChanged(parentAddons)"
                                                            *ngIf="!parentAddons.hide">
                                                            <a class="nav-link" [ngClass]="{'active': i == 0}"
                                                                id="{{parentAddons + 'tab'}}" data-toggle="tab"
                                                                role="tab" aria-selected="true">
                                                                <span><img src="{{selectedTabImg[i]}}" alt=""></span>
                                                                <span>{{parentAddons.category}}</span>
                                                                <span>{{parentAddons.addonsCount}}</span>
                                                            </a>
                                                        </li>
                                                    </ng-container>
                                                </ul>
                                                <!-- Tab panes -->
                                                <div class="tab-content radioTabContent" id="" formGroupName="addons">
                                                    <div class="tab-pane active" id="most" role="tabpanel"
                                                        aria-labelledby="most-tab">
                                                        <div class="tab-heading-data">
                                                            <h4 class="cover-main-heading">
                                                                <img src="{{elevateActiveGroupImg}}" alt="">
                                                                <span>{{selectedElevateGroup.category}}</span>
                                                            </h4>
                                                        </div>
                                                        <div class="tab-data">
                                                            <div class="fieldsdiv addon-fields">
                                                                <div class="row">
                                                                    <div class="col-sm-12 form-group customCheckslist">
                                                                        <ul class="twoColumns">
                                                                            <ng-container
                                                                                *ngFor="let childData of selectedElevateGroup.childAddons; let i = index;">

                                                                                <!-- Normal addon check box -->
                                                                                <li
                                                                                    *ngIf="childData.visiblityAsPerService">
                                                                                    <mat-checkbox class="cstmCheckbox"
                                                                                        [checked]="childData['checkboxValue']['checkedAddon']"
                                                                                        [disabled]="childData['checkboxValue']['disbaledAddon']"
                                                                                        formControlName="{{childData.label}}"
                                                                                        (change)="changeInAddonCover(selectedElevateGroup,childData,$event)">
                                                                                        {{childData.label}}</mat-checkbox>
                                                                                    <span
                                                                                        *ngIf="childData.tooltipPresent">
                                                                                        <img src="../../../../../assets/images/Waiting Period.svg"
                                                                                            alt="" class="tooltipImg"
                                                                                            matTooltip="{{'Default Waiting Period is'+ ' ' + childData.defaultDropDownValue + ' years'}}">

                                                                                    </span>
                                                                                </li>

                                                                                <!-- dependent fields like dropdown/ input field -->
                                                                                <li
                                                                                    *ngIf="childData.showDependentField">
                                                                                    <mat-label
                                                                                        class="input-group__label"
                                                                                        for="myInput">{{childData.dropDownLabel}}</mat-label>
                                                                                    <ng-container
                                                                                        *ngIf="childData.dropDownValue.length > 1">
                                                                                        <mat-select
                                                                                            class="input-group__input cstm_select"
                                                                                            placeholder="{{childData.dropDownPlaceholder}}"
                                                                                            (click)="handleMatSelectClick()"
                                                                                            (blur)="removeMatSelectScrollListener()"
                                                                                            formControlName="{{childData.dropDownControlName}}"
                                                                                            id="{{childData.dropDownControlName}}"
                                                                                            [multiple]="multipleMemberDropDown.includes(childData.coverId)? true: false">
                                                                                            <mat-option
                                                                                                *ngFor="let dropDowndata of childData.dropDownValue"
                                                                                                [value]=dropDowndata.label>
                                                                                                {{dropDowndata.label}}
                                                                                            </mat-option>
                                                                                        </mat-select>
                                                                                    </ng-container>

                                                                                    <input type="text" matInput
                                                                                        class="input-group__input"
                                                                                        placeholder="Adult" type="text"
                                                                                        formControlName="{{childData.dropDownControlName}}"
                                                                                        *ngIf="childData.dropDownValue.length == 1"
                                                                                        autocomplete="off" readonly>

                                                                                    <mat-error class="custom-error"
                                                                                        *ngIf="hasAddonsError(childData.dropDownControlName, 'required') && childData.dropDownValue.length > 1 && formSubmitted">
                                                                                        {{childData.dropDownLabel + ' is
                                                                                        required'}}
                                                                                    </mat-error>
                                                                                </li>

                                                                                <!-- Critical Illness Datepicker -->
                                                                                <li class="col"
                                                                                    *ngIf="selectedElevateGroup.showCriticalDatePicker && childData.coverId == 115">
                                                                                    <mat-label
                                                                                        class="input-group__label">Proposer
                                                                                        DOB</mat-label>
                                                                                    <input
                                                                                        class="input-group__input dob"
                                                                                        matInput
                                                                                        [matDatepicker]="criticalDOB"
                                                                                        formControlName="proposerDobCi"
                                                                                        id="proposerDobCi"
                                                                                        [max]="getAdultInsuredMinMax('Adult','max',i,'')"
                                                                                        [min]="getAdultInsuredMinMax('Adult','min',i,'')"
                                                                                        (click)="criticalDOB.open()"
                                                                                        placeholder="DD/MM/YYYY"
                                                                                        readonly>
                                                                                    <mat-datepicker-toggle matSuffix
                                                                                        [for]="criticalDOB">
                                                                                    </mat-datepicker-toggle>
                                                                                    <mat-datepicker #criticalDOB
                                                                                        touchUi>
                                                                                    </mat-datepicker>
                                                                                    <mat-error class="error-margin"
                                                                                        *ngIf="hasAddonsError('proposerDobCi', 'required') && formSubmitted">
                                                                                        Proposer DOB is required
                                                                                    </mat-error>
                                                                                </li>

                                                                                <!-- Personal accident addons dropdown-->
                                                                                <li class="col"
                                                                                    formArrayName="occupation"
                                                                                    *ngIf="childData.coverId == 116 && selectedElevateGroup.showOccupationFields">
                                                                                    <ng-container
                                                                                        *ngFor="let occData of occupation.controls; let i = index"
                                                                                        [formGroupName]="i">
                                                                                        <mat-label
                                                                                            class="input-group__label"
                                                                                            for="myInput">Occupation
                                                                                            For
                                                                                            {{occupation.value[i]['occLabel']
                                                                                            +
                                                                                            ' '
                                                                                            +occupation.value[i]['memberIndex']}}
                                                                                        </mat-label>
                                                                                        <mat-select
                                                                                            class="input-group__input cstm_select"
                                                                                            placeholder="Select Occupation"
                                                                                            (click)="handleMatSelectClick()"
                                                                                            (blur)="removeMatSelectScrollListener()"
                                                                                            formControlName="{{'occupationFor'+(occupation.value[i]['occLabel'])+(occupation.value[i]['memberIndex'])}}"
                                                                                            (selectionChange)="selectOccupationOption($event.value,i,false)">
                                                                                            <mat-option
                                                                                                *ngFor="let item of occupationMaster"
                                                                                                [value]="item.value">
                                                                                                {{item.value}}
                                                                                            </mat-option>
                                                                                        </mat-select>

                                                                                        <mat-error class="error-margin"
                                                                                            *ngIf="occupation.value[i]['occupationFor'+(occupation.value[i]['occLabel'])+(occupation.value[i]['memberIndex'])] == '' && formSubmitted">
                                                                                            Occupation is required
                                                                                        </mat-error>
                                                                                    </ng-container>
                                                                                </li>
                                                                            </ng-container>

                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="" *ngIf="selectedAddonList.length > 0 && showSelectedAddons">
                                    <div class="selected-addons-card"
                                        [ngClass]=" {'mobileViewSelectedAddons': showSelectedAddons && backButtonNeeded}">
                                        <div class="selected-addons-heading">
                                            <h4 class="cover-selected-heading">
                                                <button *ngIf="backButtonNeeded" class="back-imgbtn"
                                                    (click)="backButtonClicked()">
                                                    <img src="../../../../../assets/images/BBR/back-btn.svg" alt="">
                                                </button>

                                                <span> Covers Selected: </span>
                                                <span style="color:#ec6608">{{selectedAddonList.length }}</span>
                                            </h4>
                                        </div>
                                        <div class="cover-list fieldsdiv">
                                            <table class="cover-table">
                                                <tr *ngFor="let data of selectedAddonList; let j = index;">
                                                    <td>{{data.label}}
                                                        <img src="../../../../../assets/images/Waiting Period.svg"
                                                            alt="" class="tooltipImg" matTooltip="{{data.groupName}}">
                                                    </td>
                                                    <td>
                                                        <img src="../../../../../assets/icons/elevate/elevated-cover/delete.svg"
                                                            alt="" (click)="deleteAddons(data,j)">
                                                    </td>
                                                </tr>
                                                <tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- toggle for changing build your our plan to BUI cart-->
                            <div *ngIf="hideBUIService && elevateBUIAddon.length > 1"
                                class="col-sm-12 text-center mt-2 mb-2">
                                <button class="custom-plan-btn" (click)="resetForm(false)">Switch back to recommended
                                    plan</button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>

            <button
                *ngIf="BUIresponse && hideBUIService && elevateAddons.length > 0 && selectedAddonList.length > 0 && mobileViewActivated && !backButtonNeeded"
                id="scrollToTopButton" (click)="addonCountSelcted()">
                <span [matBadge]="selectedAddonList.length" matBadgePosition="before" class="badge"></span>
                Add-Ons
            </button>
            <!-- Additional Cover Tab Format -->
            <!-- <mat-card class="innerbox borderinnerbox"
                *ngIf="!utilityService.isEmptyOrNull(elevateControls.controls['applicantAnnualSum'].value) && this.elevateAddons.length > 0">
                <h3>Additional Cover</h3>
                <ng-container *ngIf="(adultDetails.length > 0)">
                    <div class="customaddons pl-3 pr-3" [ngStyle]="{'border-radius': hasPedAddons ? '8px' : '0px'}">
                        <span class="d-flex pl-0">
                            <img src=".../../../../../../../assets/images/Waiting Period.svg" class="waiting">
                            <span class="waitinglabel">You can only choose either critical illness coverage or pre-existing
                                disease coverage, but not both. Pre-existing conditions are listed on the insured
                                page.</span>
                        </span>
                    </div>
                </ng-container>
                <section class="mainProductContainer mt-3" formGroupName="addons">
                     <h4 class="mobile_heading">My Products</h4> -->
            <!--  [selectedIndex]="selectedTabIndex" 
                    <div class="product_header_wrapper">
                        <div class="product_header">
                           (selectedTabChange)="tabChanged($event)"
                            <mat-tab-group class="dashboard_tabgroups" (selectedTabChange)="tabChanged($event)">
                                <ng-container *ngFor="let addonsData of elevateAddons; let i = index;">
                                    <mat-tab *ngIf="!addonsData.hide">
                                        <ng-template mat-tab-label class="tablock">
                                            <span class="mr-2">
                                                <img class="heading_img_health" src="{{selectedTabImg[i]}}" alt="Health">
                                            </span>
                                            <span class="headingName"> {{addonsData.category}}</span>
        
                                        </ng-template>
                                        <div class="fieldsdiv mt-4" [ngClass]="getDynamicClasses(i)" *ngIf="!addonsData.hide">
                                            <div class=" customCheckslist">
                                                <ul>
                                                    <ng-container *ngFor="let childData of addonsData['childAddons']">
                                                        <li class="col-4" *ngIf="childData.visiblityAsPerService">
                                                            <mat-checkbox class="cstmCheckbox"
                                                                [checked]="childData['checkboxValue']['checkedAddon']"
                                                                [disabled]="childData['checkboxValue']['disbaledAddon']"
                                                                formControlName="{{childData.label}}"
                                                                (change)="changeInAddonCover(addonsData,childData,$event)">{{childData.label}}</mat-checkbox>
                                                            <span *ngIf="childData.tooltipPresent">
                                                                <img src="../../../../../assets/images/Waiting Period.svg"
                                                                    alt="" class="tooltipImg"
                                                                    matTooltip="{{'Default Waiting Period is'+ ' ' + childData.defaultDropDownValue + ' years'}}">
        
                                                            </span>
                                                        </li>
        
                                                        <li class="col-4 ml-3" *ngIf="childData.showDependentField">
                                                            <mat-label class="input-group__label"
                                                                for="myInput">{{childData.dropDownLabel}}</mat-label>
                                                            <ng-container *ngIf="childData.dropDownValue.length > 1">
                                                                <mat-select class="input-group__input cstm_select"
                                                                    placeholder="{{childData.dropDownPlaceholder}}"
                                                                    (click)="handleMatSelectClick()"
                                                                    (blur)="removeMatSelectScrollListener()"
                                                                    formControlName="{{childData.dropDownControlName}}"
                                                                    id="{{childData.dropDownControlName}}"
                                                                    [multiple]="multipleMemberDropDown.includes(childData.coverId)? true: false">
                                                                    <mat-option
                                                                        *ngFor="let dropDowndata of childData.dropDownValue"
                                                                        [value]=dropDowndata.label>
                                                                        {{dropDowndata.label}}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </ng-container>
        
                                                            <input type="text" matInput class="input-group__input"
                                                                placeholder="Adult" type="text"
                                                                formControlName="{{childData.dropDownControlName}}"
                                                                *ngIf="childData.dropDownValue.length == 1" autocomplete="off"
                                                                readonly>
        
                                                            <mat-error
                                                                *ngIf="hasAddonsError(childData.dropDownControlName, 'required') && childData.dropDownValue.length > 1 && formSubmitted">
                                                                {{childData.dropDownLabel + ' is required'}}
                                                            </mat-error>
                                                        </li>
        
                                                         Critical Illness Datepicker 
                                                        <li class="col-sm-3 form-group"
                                                            *ngIf="addonsData.showCriticalDatePicker && childData.coverId == 59">
                                                            <mat-label class="input-group__label">Proposer DOB</mat-label>
                                                            <input class="input-group__input dob" matInput
                                                                [matDatepicker]="criticalDOB" formControlName="proposerDobCi"
                                                                id="proposerDobCi"
                                                                [max]="getAdultInsuredMinMax('Adult','max',i,'')"
                                                                [min]="getAdultInsuredMinMax('Adult','min',i,'')"
                                                                (click)="criticalDOB.open()" placeholder="DD/MM/YYYY" readonly>
                                                            <mat-datepicker-toggle matSuffix [for]="criticalDOB">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #criticalDOB touchUi>
                                                            </mat-datepicker>
                                                            <mat-error class="error-margin"
                                                                *ngIf="hasAddonsError('proposerDobCi', 'required') && formSubmitted">
                                                                Proposer DOB is required
                                                            </mat-error>
                                                        </li>
        
                                                       Personal accident addons dropdown
                                                        <div class="row mb-3" formArrayName="occupation"
                                                            *ngIf="childData.coverId == 60 && addonsData.showOccupationFields">
                                                            <div class="col-sm-3"
                                                                *ngFor="let occData of occupation.controls; let i = index"
                                                                [formGroupName]="i">
                                                                <mat-label class="input-group__label" for="myInput">Occupation
                                                                    For
                                                                    {{occupation.value[i]['occLabel'] + ' '
                                                                    +occupation.value[i]['memberIndex']}}
                                                                </mat-label>
                                                                <mat-select class="input-group__input cstm_select"
                                                                    placeholder="Select Occupation"
                                                                    (click)="handleMatSelectClick()"
                                                                    (blur)="removeMatSelectScrollListener()"
                                                                    formControlName="{{'occupationFor'+(occupation.value[i]['occLabel'])+(occupation.value[i]['memberIndex'])}}"
                                                                    (selectionChange)="selectOccupationOption($event.value,i)">
                                                                    <mat-option *ngFor="let item of occupationMaster"
                                                                        [value]="item.value">
                                                                        {{item.value}}
                                                                    </mat-option>
                                                                </mat-select>
        
                                                                <mat-error class="error-margin"
                                                                    *ngIf="occupation.value[i]['occupationFor'+(occupation.value[i]['occLabel'])+(occupation.value[i]['memberIndex'])] == '' && formSubmitted">
                                                                    Occupation is required
                                                                </mat-error>
                                                            </div>
                                                        </div>
        
                                                    </ng-container>
        
                                                </ul>
                                            </div>
                                        </div>
                                    </mat-tab>
                                </ng-container>
                            </mat-tab-group>
                        </div>
                    </div>
                </section>
            </mat-card> -->
        </form>

        <!-- error screen -->
        <app-error-screen *ngIf="multipleServiceError" (refreshService)="onRefresh($event)">
        </app-error-screen>

        <!-- footer  -->
        <app-custom-footer *ngIf="!multipleServiceError" [normalButton]="true"
            [buttonData]="[{'label': 'Calculate Premium', 'type': 'primary', 'source': 'quote', 'id': 'f1','disabled': false , 'hide': false}]"
            (buttonClicked)="getQuote($event)">
        </app-custom-footer>
    </div>
</div>