import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { routeEnums } from 'src/app/shared/enums/routeEnums';

const ROUTE_PATHS = routeEnums;

const routes: Routes = [
  {
    path: ROUTE_PATHS.ROOT,
    redirectTo: ROUTE_PATHS.PendingPayment,
    pathMatch: 'full'
  },
  {
    path: ROUTE_PATHS.PendingPayment,
    // component:PaymentConfirmationComponent
  },
  {
    path: ROUTE_PATHS.MyPolicies,
    // component:PaymentConfirmationComponent
  }, {
    path: ROUTE_PATHS.PAY_IN_SLIP
  }, {
    path: ROUTE_PATHS.PID
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class SidenavRoutingModule { }
