// This service should only be responsible for transferring products data between the components / shared components

import { Injectable } from '@angular/core';
import { BefitHomeStorage, HealthDataModal, HealthDataStorage, KycFormData, PremiumDetailsData } from './health.modal';
import { SessionStorageService, StorageService } from 'src/app/shared/services/storage.service';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { Constants } from 'src/app/shared/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class HealthDataStorageService {
  healthDataModal: HealthDataModal = new HealthDataModal();
  befitHomeData = {
    befitData: '',
    befitSelection: false,
    homeInsuranceData: {},
    homeInsuranceSelection: false,
    homeInsuranceSelectedCover: [],
    homeCoverDetails: []
  };
  productData = [{
    product: 'hap',
    subProductCode: Constants.subProductHap
  },{
    product: 'gs',
    subProductCode: Constants.subProductGS
  },{
    product: 'asp',
    subProductCode: Constants.subProductASP
  },{
    product: 'hep',
    subProductCode: Constants.subProductHEP
  },{
    product: 'max',
    subProductCode: Constants.subProductMAX
  },{
    product: 'hb',
    subProductCode: Constants.subProductHB
  },{
    product: 'cs',
    subProductCode: Constants.subProductCS
  },{
    product: 'fs',
    subProductCode: Constants.subProductFS
  }
  ]

  constructor(private storageService: StorageService, private sessionService: SessionStorageService) { }


  setHealthDataStorage(form: HealthDataStorage) {
    this.healthDataModal.setHealthDataStorage(form);
    this.storageService.setAESEncryptedData(userEnums.HealthFormData, form);
  }

  setBefithomeStorage(data: BefitHomeStorage) {
    this.storageService.setAESEncryptedData(userEnums.BefitHome, data);
  }

  getBefithomeStorage(): BefitHomeStorage {
    let BefitHomeData = this.storageService.getAESDecryptedData(userEnums.BefitHome);
    if(BefitHomeData) {
      return BefitHomeData;
    } else {
      return this.befitHomeData;
    }
  }

  getHealthDataStorage(): HealthDataStorage {
    let healthStorageForm;
    // Below logic is written so that we should fetch initial data when user lands on component.
    // But if the form structure is already present and user clicks on refresh then we don't want our data to reset
    // So due to this reason we have written below logic
    if (!this.sessionService.has(userEnums.HealthFormData)) {
      healthStorageForm = this.healthDataModal.getHealthDataStorage();
    }
    else {
      healthStorageForm = this.storageService.getAESDecryptedData(userEnums.HealthFormData);
    }

    this.storageService.setAESEncryptedData(userEnums.HealthFormData, healthStorageForm);
    return this.storageService.getAESDecryptedData(userEnums.HealthFormData);
  }

  resetHealthDataStorage() {
    let form: HealthDataStorage = this.healthDataModal.resetHealthDataStorage();
    this.setHealthDataStorage(form);
  }

  resetKYCdata(): KycFormData {
    return this.healthDataModal.resetKycFormData();
  }

  initializePremiumData(): PremiumDetailsData {
    let premiumDetails: PremiumDetailsData = {
      tenure: 0,
      adult: 0,
      child: 0,
      productName: '',
      totalPremium: '',
      basicPremium: '',
      totalTax: '',
      coverRange: '',
      installmentApplicable: false,
      installmentFrequency: '',
      installmentTotalPremium: 0,
      zone: '',
    };
    return premiumDetails
  }

  getProductCode(product) {
    let data = this.productData.find(prodData=> prodData.product === product);
    let productCode = '';
    if(data) {
      productCode = data.product
    }
    return productCode;
  }
}