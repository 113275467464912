import { Component, EventEmitter, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/authentication/auth.service';
import { routeEnums } from 'src/app/shared/enums/routeEnums';
import { AgentService_A } from 'src/app/shared/proxy-services/AuthService/agent.service';


@Component({
  selector: 'app-error-screen',
  templateUrl: './error-screen.component.html',
  styleUrls: ['./error-screen.component.scss']
})
export class ErrorScreenComponent {
  @Output() refreshService = new EventEmitter<boolean>();
  isAuthenticate: boolean = false;
  loadErrorPage: boolean = false;
  currentUrl: string;

  constructor(private agentService_A: AgentService_A,
    private auth: AuthService,
    private router: Router) { 
      this.router.events.subscribe(
        (event: any) => {
          if (event instanceof NavigationEnd) {
            this.currentUrl = event.url;
          }
      });
    this.isAuthenticate = this.auth.check();
    if (this.isAuthenticate) {
      this.loadErrorPage = true;
    }
    setTimeout(() => {
      if(this.currentUrl != undefined && this.currentUrl.includes(`${routeEnums.SWAP}`)) {
        this.loadErrorPage = false;
      } 
    }, 0);
  }

  goToDashboard() {
    this.agentService_A.logout();
  }

  refreshEmitted() {
    this.refreshService.emit(true);
  }
}
