import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { routeEnums } from './../../shared/enums/routeEnums';
import { SwapComponent } from './swap.component';


const ROUTE_PATHS = routeEnums;


const routes: Routes = [
  { 
    path: ROUTE_PATHS.ROOT,
    component: SwapComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SwapRoutingModule { }