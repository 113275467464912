<div class="container">
    <div class="overlay" id="overlay" (click)="overlayItems($event)" style="cursor: pointer;">
        <div class="sidebar" id="sidebar">
            <div class="mainDashboard">
                <div class="dashboardlinks">
                    <ul class="nav nav-nav flex-column" *ngFor="let items of sideMenuItems; let i = index">
                        <li class="{{items.class}}" [ngClass]="{'active': items.active}"
                            *ngIf="items.type == 'list' && !items.hide">
                            <a class="nav-link" (click)="navigate(items.route)">{{items.label}}</a>
                        </li>
                        <mat-expansion-panel class="dashboard_expand" *ngIf="items.type == 'expansion' && !items.hide"
                            [expanded]="i === currentlyOpenPanelIndex" (opened)="onPanelOpened(i)">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="{{items.class}}">
                                    <span class="exp_panel">{{items.label}}</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="dash_submenu" *ngFor="let subItems of items.subMenuItems">
                                <li *ngIf="!subItems.hide">
                                    <a (click)="navigate(subItems.route)">{{subItems.label}}</a>
                                </li>
                            </ul>
                        </mat-expansion-panel>
                        <mat-divider *ngIf="items.showDivider"></mat-divider>
                    </ul>
                </div>
                <div class="pendingpaymentboard" *ngIf="!isDeveloperDashboard">
                    <table class="dash_pendingTable">
                        <thead>
                            <tr>
                                <th (click)="navigate('/renewals')">Renewal Due</th>
                                <th (click)="navigate('/pending-payment')">Pending Payment</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td (click)="navigate('/renewals')">{{renewalValue}}</td>
                                <td (click)="navigate('/pending-payment')">{{paymentPending}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="Dash_profile">
                    <div class="profileIMG">
                        <img src="../../../assets/icons/dash_profile.svg" alt="">
                    </div>
                    <div class="profile_name">
                        <p>  {{agentDetails?.AgentName ? agentDetails?.AgentName: ''}}</p>
                        <span>{{userName ? userName: ''}}</span>
                    </div>
                    <div class="Profilecalender mt-2">
                        <div class="calender">
                            <span><img src="../../../assets/images/calendar.png" alt=""></span>
                            <span>{{currentDate}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>