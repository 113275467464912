<form [formGroup]="internationTravelForm" *ngIf="!multipleServiceError && loadFormContent">
   <div class="matstickycard">
      <mat-card class="innerbox borderinnerbox producttype">
         <div class="fieldsdiv">
            <div class="row ">
               <div class="col-sm-3 form-group mb0">
                  <mat-label class="input-group__label" for="productName"> Product name</mat-label>
                  <!-- <mat-select class="input-group__input cstm_select" placeholder="Select Product name" id="productName"
                     formControlName="productName" (selectionChange)="createInternationTravelForm($event.value)">
                     <mat-option value="internationalTravel"> International Travel </mat-option>
                  </mat-select> -->
                  <input type="text" id="myInput" class="input-group__input" value="International Travel" readonly autocomplete="off"/>
                  <mat-error class="error-margin"
                     *ngIf="internationTravelForm.controls['productName'].errors?.['required'] && isSubmitted">
                     Product name is required.
                  </mat-error>
               </div>
               <div class="col-sm-3 form-group mwidth ">
                  <mat-label class="input-group__label" for="productSubtype">Product Subtype</mat-label>
                  <mat-select class="input-group__input cstm_select" placeholder="Select Product Subtype"
                     id="productSubtype" formControlName="subProduct"
                     (selectionChange)="changeProductSubType($event.value)">
                     <ng-container *ngFor="let item of travelModal.travelSubProductType; let last = last">
                        <mat-option [value]="item.value">
                           {{item.label}}
                        </mat-option>
                        <mat-divider *ngIf="!last"></mat-divider>
                     </ng-container>
                  </mat-select>
                  <mat-error class="error-margin"
                     *ngIf="internationTravelForm.controls['subProduct'].errors?.['required'] && isSubmitted">
                     Product sub type is required.
                  </mat-error>
               </div>
               <div class="col-sm-3 form-group mwidth">
                  <mat-label class="input-group__label" for="state">State</mat-label>
                  <mat-select class="input-group__input cstm_select" placeholder="Select State" id="state"
                     formControlName="state" (selectionChange)="changeState($event.value)">
                     <ng-container *ngFor="let item of stateMaster; let last = last">
                        <mat-option value={{item.StateName}}>
                           {{item.StateName}}
                        </mat-option>
                        <mat-divider *ngIf="!last"></mat-divider>
                     </ng-container>
                  </mat-select>
                  <mat-error class="error-margin"
                     *ngIf="internationTravelForm.controls['state'].errors?.['required'] && isSubmitted">
                     State is required.
                  </mat-error>
               </div>
               <div class="col-sm-3 form-group mt-4" *ngIf="internationTravelForm.value.subProduct == 'Individual'">
                  <div class="Custom_group_btn2 custom_travelgroupbtn">
                     <input type="radio" class="Cstmradio__input2" value="roundTrip" name="tripType" id="roundTrip"
                        checked formControlName="tripType" (change)="changeTripType($any($event.target).value)" checked>
                     <label class="Cstmradio__label2" for="roundTrip">
                        Round Trip</label>
                     <input type="radio" class="Cstmradio__input2" value="multiTrip" name="tripType" id="multiTrip"
                        formControlName="tripType" (change)="changeTripType($any($event.target).value)">
                     <label class="Cstmradio__label2" for="multiTrip">
                        Multi-trip</label>
                  </div>
               </div>
               <div class="col-sm-12 customaddons pedAddons"     *ngIf=" this.internationTravelForm.value.tripType == 'multiTrip'">
                  <table class="travel_countryNotify">
                     <tr>
                        <td><img src=".../../../../../../../assets/images/Waiting Period.svg"
                           alt="Waiting period & pre / post hospital duration" class="waiting"></td>
                        <td>
                           <label for="" class="travel_label">Gold Multi Trip policy is not valid for Schengen Countries, Separate Schengen compliant single trip policy is required for Schengen travellers.</label>
                  
                        </td>
                     </tr>
                  </table>
               </div>
                 

            </div>
         </div>
      </mat-card>
      <mat-card class="innerbox borderinnerbox pt-2">
         <h3>Travel Details</h3>
         <div class="fieldsdiv">
            <div class="row ">
               <ng-container *ngIf="internationTravelForm.value.tripType != 'multiTrip'">
                  <div class="col-sm-12 form-group">
                        <span >
                              <img src=".../../../../../../../assets/images/Waiting Period.svg"
                              style="width: 18px; height: 15px;"    alt="Waiting period & pre / post hospital duration" class="waiting" (click)="openPopup('travel')">
                              <label for="" class="waitinglabel ml-2">Select Your Travel Location</label>
                        </span> 
                     <div class="floating-mainContainer">
                        <div class="radioul-container">
                           <ul class="radioul">
                              <li *ngFor="let radioData of travelModal.travelLocation; let j = index">
                                 <table class="radio1">
                                    <tbody>
                                       <tr>
                                          <td>
                                             <input type="radio" id="{{radioData.value}}" name="travelLoc"
                                                [value]="radioData.value" checked="" formControlName="travelLoc" 
                                                (change)="changeTravelLocation($any($event.target).value)">
                                             <label for="{{radioData.value}}">{{ radioData.label }} </label>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </li>
                           </ul>
                        </div>
                     </div>
                     <mat-error class="error-margin"
                        *ngIf="internationTravelForm.controls['travelLoc'].errors?.['required'] && isSubmitted">
                        Travel location is required.
                     </mat-error>
                  </div>
                  <div class="col-sm-12 form-group">
                     <label for="isVisitOnImmigrantVisa" class="singlelabel mb-2" style="width:100%">Are the traveller
                        visiting on an Immigrant Visa? *</label>
                     <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="policyToggle_group"
                        id="isVisitOnImmigrantVisa" formControlName="isVisitOnImmigrantVisa">
                        <mat-button-toggle class="PolicyBtn" [value]="true">Yes</mat-button-toggle>
                        <mat-button-toggle class="PolicyBtn" [value]="false">No</mat-button-toggle>
                     </mat-button-toggle-group>
                     <mat-error class="error-margin"
                        *ngIf="internationTravelForm.controls['isVisitOnImmigrantVisa'].errors?.['required'] && isSubmitted">
                        Please select
                     </mat-error>
                  </div>
                  <div class="col-sm-3 form-group">
                     <mat-label class="input-group__label">Leaving India On *
                     </mat-label>
                     <input class="input-group__input dob" matInput [matDatepicker]="leavingDate" id="leavingDate"
                        (click)="leavingDate.open()" placeholder="DD/MM/YYYY" formControlName="leavingDate"
                        [min]="leavingMinMaxDate?.minDate" [max]="leavingMinMaxDate?.maxDate"
                        (dateChange)="getDuration('leavingDate')" readonly>
                     <mat-datepicker-toggle matSuffix [for]="leavingDate">
                     </mat-datepicker-toggle>
                     <mat-datepicker #leavingDate touchUi>
                     </mat-datepicker>
                     <mat-error class="error-margin"
                        *ngIf="internationTravelForm.controls['leavingDate'].errors?.['required'] && isSubmitted">
                        Leaving date is required.
                     </mat-error>
                  </div>
                  <div class="col-sm-3 form-group">
                     <mat-label class="input-group__label">Returning India On *
                     </mat-label>
                     <input class="input-group__input dob" matInput [matDatepicker]="returningDate" id="returningDate"
                        (click)="returningDate.open()" placeholder="DD/MM/YYYY" formControlName="returningDate"
                        [min]="returningMinMaxDate?.minDate" [max]="returningMinMaxDate?.maxDate"
                        (dateChange)="getDuration('returningDate')" readonly>
                     <mat-datepicker-toggle matSuffix [for]="returningDate">
                     </mat-datepicker-toggle>
                     <mat-datepicker #returningDate touchUi
                        [disabled]="utilityService.isEmptyOrNull(internationTravelForm.value.leavingDate)">
                     </mat-datepicker>
                     <mat-error class="error-margin"
                        *ngIf="internationTravelForm.controls['returningDate'].errors?.['required'] && isSubmitted">
                        Returning date is required.
                     </mat-error>
                  </div>
                  <div class="col-sm-3 form-group">
                     <mat-label class="input-group__label" for="myInput">Trip Duration (Days) *</mat-label>
                     <input type="text" id="myInput" class="input-group__input" value="" formControlName="noOfTripDays" autocomplete="off" (change)="calculateEndDate($event)" (keydown)="utilityService.checkForNumberInput($event)"/>
                     <mat-error class="error-margin"
                        *ngIf="internationTravelForm.controls['noOfTripDays'].errors?.['required'] && isSubmitted">
                        Trip duration is required.
                     </mat-error>
                  </div>
               </ng-container>
               <ng-container *ngIf="internationTravelForm.value.tripType == 'multiTrip'">
                  <div class="col-sm-12 form-group">
                     <label for="trip-duration" class="singlelabel mb-2" style="width:100%">Trip Duration</label>
                     <div class="floating-mainContainer">
                        <div class="radioul-container">
                           <ul class="radioul">
                              <li *ngFor="let radioData of travelModal.tripDuration; let j = index">
                                 <table class="radio1">
                                    <tbody>
                                       <tr>
                                          <td>
                                             <input type="radio" id="{{radioData.value}}" name="noOfTripDays"
                                                [value]="radioData.value" checked="" formControlName="noOfTripDays">
                                             <label for="{{radioData.value}}">{{ radioData.label }}</label>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </li>
                           </ul>
                        </div>
                     </div>
                     <mat-error class="error-margin"
                        *ngIf="internationTravelForm.controls['noOfTripDays'].errors?.['required'] && isSubmitted">
                        Trip Duration is required
                     </mat-error>
                  </div>
                  <div class="col-sm-3 form-group">
                     <mat-label class="input-group__label">Policy start date *
                     </mat-label>
                     <input class="input-group__input dob" matInput [matDatepicker]="startDate" id="startDate"
                        (click)="startDate.open()" placeholder="DD/MM/YYYY" formControlName="startDate"
                        [min]="startMinMaxDate?.minDate" [max]="startMinMaxDate?.maxDate"
                        (dateChange)="getDuration('startDate')" readonly>
                     <mat-datepicker-toggle matSuffix [for]="startDate">
                     </mat-datepicker-toggle>
                     <mat-datepicker #startDate touchUi>
                     </mat-datepicker>
                     <mat-error class="error-margin"
                        *ngIf="internationTravelForm.controls['startDate'].errors?.['required'] && isSubmitted">
                        Policy start date is required.
                     </mat-error>
                  </div>
               </ng-container>
               <ng-container *ngIf="internationTravelForm.value.subProduct == 'Senior Citizen'">
                  <div class="col-sm-12 form-group">
                     <label for="coverageType" class="singlelabel mb-2" style="width:100%">Type of Coverage *</label>
                     <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="policyToggle_group"
                        id="coverageType" formControlName="coverageType" (change)="changeCoverageType($event.value)">
                        <mat-button-toggle class="PolicyBtn" value="individualPlan">Individual Plan</mat-button-toggle>
                        <mat-button-toggle class="PolicyBtn" value="familyPlan">Family Plan</mat-button-toggle>
                     </mat-button-toggle-group>
                     <mat-error class="error-margin"
                        *ngIf="internationTravelForm.controls['coverageType'].errors?.['required'] && isSubmitted">
                        Please select
                     </mat-error>
                  </div>
               </ng-container>
               <ng-container
                  *ngIf="internationTravelForm.value.subProduct != 'Individual' && internationTravelForm.value.coverageType != 'individualPlan'">
                  <div class="col-sm-3 form-group mwidth ">
                     <mat-label class="input-group__label" for="noOfTraveller">Number Of Travellers</mat-label>
                     <mat-select class="input-group__input cstm_select" placeholder="Select Number Of Travellers"
                        id="noOfTraveller" formControlName="noOfTraveller"
                        (selectionChange)="changeNoOfTraveller($event.value)">
                        <ng-container
                           *ngFor="let item of internationTravelForm?.value?.noOfTravellerDetails; let last = last">
                           <mat-option [value]="item">
                              {{item}}
                           </mat-option>
                           <mat-divider *ngIf="!last"></mat-divider>
                        </ng-container>
                     </mat-select>
                     <mat-error class="error-margin"
                        *ngIf="internationTravelForm.controls['noOfTraveller'].errors?.['required'] && isSubmitted">
                        Number of travellers is required.
                     </mat-error>
                  </div>
               </ng-container>
            </div>
            <div class="row">
               <ng-container formArrayName="members">
                  <ng-container *ngFor="let item of internationTravelForm.get('members')['controls'];let i = index"
                     [formGroupName]="i">
                     <div class="col-sm-3 form-group">
                        <mat-label class="input-group__label">Traveller {{i + 1}} Date of Birth *
                        </mat-label>
                        <input class="input-group__input dob" matInput [matDatepicker]="travellerDOB" id="members"
                           [min]="memberMinMaxDate?.minDate" [max]="memberMinMaxDate?.maxDate"
                           (click)="travellerDOB.open()" placeholder="DD/MM/YYYY" formControlName="DOB"
                           (dateChange)="changeDateOfBirth(i)" readonly>
                        <mat-datepicker-toggle matSuffix [for]="travellerDOB">
                        </mat-datepicker-toggle>
                        <mat-datepicker #travellerDOB touchUi>
                        </mat-datepicker>
                        <mat-error class="error-margin" *ngIf="this.internationTravelForm.controls['members']['controls'][i]['controls']['DOB'].hasError('required')
&& isSubmitted">
                           DOB is required.
                        </mat-error>
                     </div>
                     <div class="col-sm-3 form-group mwidth ">
                        <mat-label class="input-group__label" for="relationship">Traveller {{i + 1}} Relationship</mat-label>
                        <mat-select class="input-group__input cstm_select" placeholder="Select Relationship"
                           id="members" formControlName="RelationshipwithApplicant" (selectionChange)="changeRelationship($event.value,i)">
                           <ng-container *ngFor="let item of relationshipMaster; let last = last">
                              <mat-option [value]="item.RelationshipName">
                                 {{item.RelationshipName}}
                              </mat-option>
                              <mat-divider *ngIf="!last"></mat-divider>
                           </ng-container>
                        </mat-select>
                        <mat-error class="error-margin" *ngIf="this.internationTravelForm.controls['members']['controls'][i]['controls']['RelationshipwithApplicant'].hasError('required')
&& isSubmitted">
                           Relationship is required.
                        </mat-error>
                     </div>
                  </ng-container>
               </ng-container>
            </div>
            <div class="row">
               <div class="col-sm-3 form-group mwidth">
                  <mat-label class="input-group__label" for="plan">Plan</mat-label>
                  <mat-select class="input-group__input cstm_select" placeholder="Select Plan" id="plan"
                     formControlName="plan">
                     <ng-container *ngFor="let item of internationTravelForm.value.planDetails; let last = last">
                        <mat-option [value]="item.PlanName">
                           {{item.PlanName}}
                        </mat-option>
                        <mat-divider *ngIf="!last"></mat-divider>
                     </ng-container>
                  </mat-select>
                  <mat-error class="error-margin"
                     *ngIf="this.internationTravelForm.controls['plan'].errors?.['required'] && isSubmitted">
                     Plan is required
                  </mat-error>
               </div>
            </div>
         </div>
      </mat-card>
      <!-- <mat-card class="innerbox borderinnerbox" *ngIf="this.internationTravelForm.value.subProduct != 'Senior Citizen'">
         <h3>Additional Cover</h3> -->

         <!-- Non member based addons -->
         <!-- <div class="fieldsdiv mt-1 mb-2">
            <div class="row">
               <div class="col-sm-12 customCheckslist">
                  <ul>
                     <li *ngFor="let addon of travelModal.addonCovers">
                        <mat-checkbox class="cstmCheckbox" id="{{addon.coverId}}"
                           formControlName="{{addon.coverCode}}">{{addon.coverText}}</mat-checkbox>
                     </li>
                  </ul>
               </div>
            </div>
         </div> -->
         <!-- <mat-divider></mat-divider>
      </mat-card> -->
   </div>
</form>

<app-error-screen (refreshService)="onRefresh($event)" *ngIf="multipleServiceError">
</app-error-screen>

<app-custom-footer [normalButton]="true"
   [buttonData]="[{'label': 'Get quote', 'type': 'primary', 'source': 'quote', 'id': 'f1','disabled': false , 'hide': false}]"
   (buttonClicked)="getQuote($event)">
</app-custom-footer>