import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CSMaster, ZeroTatRelationships } from '../interface/cs-master';
import { GenericService } from '../services/generic.service';

@Injectable({
  providedIn: 'root'
})
export class FamilyShieldService {

  constructor(private http: HttpClient,private generic:GenericService) { }
  readonly baseURL = `${environment.baseURL}FamilyShield/`
  readonly masterURL = 'MasterData'
  readonly SaveEditQuoteV1 = 'SaveEditQuote'
  readonly zeroTatRelationship = 'ZeroTatRelationship'
 
  getZeroTatRelationships() {
      return this.http.post<ZeroTatRelationships>(`${this.baseURL}${this.zeroTatRelationship}`, '');
  }
  getMasterData(): Observable<any> {
    return this.http.post<any>(`${this.baseURL}${this.masterURL}`, '');
  }
  saveEditQuoteData(data): Observable<any> {
    const endpoint = `${this.baseURL}${this.SaveEditQuoteV1}`;
    return this.generic.genericService<any>(endpoint, data);
  }
  getMasterDataforKyc(): Observable<CSMaster> {
    return this.http.post<CSMaster>(`${this.baseURL}${this.masterURL}`, '');
  }
}
