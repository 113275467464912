<div *ngIf="isHeaderhide">
<ng-container *ngIf="isAuthenticate && loadContent">
  <app-header [dashBoardStatus]="hideDashboard">
    <lib-notification-ui></lib-notification-ui>
  </app-header>
</ng-container>
</div>
<ng-container>
  <router-outlet></router-outlet>
</ng-container>
<app-loader *ngIf="isLoaderShow" class="loading_loader"></app-loader>