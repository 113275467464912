import { Component } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HealthDataStorage } from 'src/app/modules/health/health.modal';
import { HealthDataStorageService } from 'src/app/modules/health/health.service';
import { Constants } from '../../constants/constants';
import { routeEnums } from '../../enums/routeEnums';
import { userEnums } from '../../enums/userEnums';
import { DownloadService } from '../../services/download.service';
import { StorageService } from '../../services/storage.service';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.scss']
})

export class PaymentConfirmationComponent {
  private subscription: Subscription[] = [];
  healthFormData: HealthDataStorage;
  loadContent: boolean = false;
  bflag: string;
  hideDashboard: boolean = false;

  constructor(
    private healthDataStorageService: HealthDataStorageService,
    public utilityService: UtilityService,
    private router: Router,
    private storageService: StorageService, 
    private downloadService: DownloadService) {
  }

  ngOnInit() {
    // this.testData()

    // this.bflag = this.storageService.getAESDecryptedDataLocal('ctrbflagth')
    // let userType = !this.utilityService.isEmptyOrNull(this.storageService.getAESDecryptedDataLocal(userEnums.UnxkdjT)) ? this.storageService.getAESDecryptedDataLocal(userEnums.UnxkdjT) : this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);;
    // this.hideDashboard = userType.toLowerCase() == UserType.Agent.toLowerCase() ? false : true;

    this.healthFormData = this.healthDataStorageService.getHealthDataStorage();
    this.healthFormData.paymentConfirmationDetails.PfTransactionId = this.storageService.getAESDecryptedDataLocal(userEnums.TransactionId);
    this.healthFormData.paymentConfirmationDetails.PolicyNumber = this.storageService.getAESDecryptedDataLocal(userEnums.PolicyNumber);
    this.healthFormData.paymentConfirmationDetails.PolicyId = this.storageService.getAESDecryptedDataLocal(userEnums.PolicyId);
    this.healthFormData.paymentConfirmationDetails.EPolicyId = this.storageService.getAESDecryptedDataLocal(userEnums.EPolicyId);
    this.healthFormData.paymentConfirmationDetails.TransFor = this.storageService.getAESDecryptedDataLocal(userEnums.TransFor);
    this.healthFormData.paymentConfirmationDetails.PfProposalNumber = this.storageService.getAESDecryptedDataLocal(userEnums.ProposalNumber);
    this.healthFormData.paymentConfirmationDetails.TotalPremium = Math.trunc(Number(this.storageService.getAESDecryptedDataLocal(userEnums.TotalPremium)));
    this.healthFormData.paymentConfirmationDetails.StartDate = this.storageService.getAESDecryptedDataLocal(userEnums.StartDate);
    this.healthFormData.paymentConfirmationDetails.EndDate = this.storageService.getAESDecryptedDataLocal(userEnums.EndDate);

    this.healthFormData.myPolicyDetails.policyId = Number(this.healthFormData.paymentConfirmationDetails.PolicyId);
    this.storageService.setAESEncryptedDataLocal(userEnums.MyPolicyDetails, this.healthFormData.myPolicyDetails.policyId);

    this.healthDataStorageService.setHealthDataStorage(this.healthFormData);

    this.loadContent = true;
  }

  testData() {
    this.storageService.setAESEncryptedDataLocal('ctrbflagth', 'false');
    this.storageService.setAESEncryptedDataLocal(userEnums.UnxkdjT, 'Agent');
    this.storageService.setAESEncryptedDataLocal(userEnums.TransactionId, "2171607376",);
    this.storageService.setAESEncryptedDataLocal(userEnums.ProposalNumber, "2171607376",);
    this.storageService.setAESEncryptedDataLocal(userEnums.PolicyNumber, '4171i/ASP/52836615/00/000');
    // this.storageService.setAESEncryptedDataLocal(userEnums.PolicyNumber, '');
    this.storageService.setAESEncryptedDataLocal(userEnums.PolicyId, "15210044",);
    this.storageService.setAESEncryptedDataLocal(userEnums.EPolicyId, 'FSLqZJ%2bSKUGb23wt4cQZTw%3d%3d');
    this.storageService.setAESEncryptedDataLocal(userEnums.TransFor, 'N');
    this.storageService.setAESEncryptedDataLocal(userEnums.TotalPremium, '18432.0');
    this.storageService.setAESEncryptedDataLocal(userEnums.StartDate, '08-Aug-2024');
    this.storageService.setAESEncryptedDataLocal(userEnums.EndDate, '07-Aug-2025');
  }

  goToDashboard() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        dash: 'dash',
      },
    };
    this.router.navigate(['/', routeEnums.SWAP], navigationExtras)
    // this.router.navigate([routeEnums.HEALTH + '/' + routeEnums.DASHBOARD]);
  }

  goToUploadDocument() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        dash: 'upload',
      },
    };
    this.router.navigate(['/', routeEnums.SWAP], navigationExtras)
    // this.router.navigate([routeEnums.DocumentUpload]);
  }

  downloadPolicy(): void {
    const ePolicyId = this.healthFormData.paymentConfirmationDetails.EPolicyId;
    const policyNo = this.healthFormData.paymentConfirmationDetails.PolicyNumber
    const params = {
      endPoint: 'File/DownloadPDF',
      body:
      {
        type: 'POLICY',
        value: ePolicyId.trim()
      }
    }
    this.subscription.push(this.downloadService.generatePDF(params.endPoint, params.body).subscribe({
      next: (response) => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.downloadService.downloadPdf(response.Data, policyNo)
        }
      },
    }))
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    })
  }
  
}