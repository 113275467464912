import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, concatMap, forkJoin, of } from "rxjs";
import { HealthMasterService } from 'src/app/shared/proxy-services/health-master.service';
import { GetQuestionnaireForOrionRequestPayload, HealthAilmentRequestPayload, HealthAilmentResponsePayload, HealthAilmentResponseStructure, InsuredRelationship, RelationshipRequestPayload } from 'src/app/shared/interface/healthMaster';
import { Constants } from 'src/app/shared/constants/constants';
import { HealthDataStorageService } from '../../health.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ProductCode, fieldTypeDetails, healthMembers, healthProductType, healthProposalProductCode, healthToggle, insuredControls, insuredDiseaseID, patterns, policyType, remarkList } from 'src/app/shared/enums/healthEnums';
import { HealthDataStorage } from '../../health.modal';
import insuredDetailsControls from "src/assets/json/insured-details.json";
import healthBoosterAdditionalDetailsControls from "src/assets/json/health-booster-additional-details.json"
import { FormFields } from 'src/app/shared/interface/formFieldsModal';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { PopupService } from 'src/app/shared/services/popup.service';
import { popupDescriptionMsg, popupHeader, popupType } from 'src/app/shared/enums/popupEnums';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomStepperService } from 'src/app/shared/services/custom-stepper.service';
import { EmitButtonData } from 'src/app/shared/interface/custom-footer';
import { CompanyDetails, InsuredCompanyResponsePayload, PortabilityReasonsResponsePayload } from "src/app/shared/interface/health";
import { HealthService } from "src/app/shared/proxy-services/health.service";
import { Component, OnInit, Output, EventEmitter, ViewChild, Input, ElementRef, Renderer2, HostListener, ViewChildren, QueryList } from '@angular/core';
import { CustomFooterService } from 'src/app/shared/services/custom-footer.service';
import { ConvertSavedQuotesResponsePayload } from 'src/app/shared/interface/savedQuotes';
import { StorageService } from 'src/app/shared/services/storage.service';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { Router } from '@angular/router';
import { NewAddOnCoverDetails } from 'src/app/shared/interface/renewal';
import { RenewalDataStorage } from 'src/app/shared/components/sidemenu/renewals/renewal-storage.modal';

@Component({
  selector: 'app-insured-details',
  templateUrl: './insured-details.component.html',
  styleUrls: ['./insured-details.component.scss'],
})
export class InsuredDetailsComponent implements OnInit {
  @Output() InsuredvalueEmitter = new EventEmitter<{ FormValid: boolean, stepChange: boolean }>();
  @Input() memberDetailsData: HealthDataStorage;
  insuredControls = [];
  hbAdditionalControls = [];
  healthFormData: HealthDataStorage;
  buttonData = {};
  existingDisease: HealthAilmentResponseStructure[] = [];
  insuredDetailsForm: FormGroup;
  private subscription: Subscription[] = [];
  totalData = [];
  isSubmit: boolean = false;
  loadPanel: boolean = false;
  preExistingError: boolean = false;
  showAdultTabs: boolean = false;
  showChildTabs: boolean = false;
  showKYCBlock: boolean = false;
  serviceError: boolean = false;
  singleMemberCategory: boolean = false;
  memberDataIndex: number = 0;
  dataSetSuccessfully: boolean = false;
  patchingDone: boolean = false;
  multipleServiceError: boolean = false;
  companyNames: CompanyDetails[] = [];
  companyListHB = [];
  apiKitProducts = [ProductCode.HAP,ProductCode.Elevate];
  keyDownListener: () => void;
  tabGroup = [];
  @ViewChild('insuredPanel') insuredPanel: MatExpansionPanel;
  errorPopupData: PopupModal;
  hidePED: boolean = true;
  datepickerMobile: boolean = false;
  selfTitleExists: boolean = false;
  hbAdditionalFieldArray = ["typeOfPolicyHB", "policyPeriodHB", "sumInsuredHB", "insuranceCompanyNameHB"]
  relationshipDetails: InsuredRelationship[];
  quoteDetails: ConvertSavedQuotesResponsePayload;
  maxDate: Date;
  minDate: Date;
  vdValue: number;
  addonSelected: NewAddOnCoverDetails[];
  vdHide: boolean = true;
  befitAddonData: any;
  selectedOption: any;
  selectedItem: any;
  renewalFormData: RenewalDataStorage;
  coPayment: string = '';
  adultDetails = [];
  childDetails = [];
  originalTitles = [
    {
      "value": "0",
      "label": "Mrs.",
      "gender": "FMALE"
    },
    {
      "value": "1",
      "label": "Mr.",
      "gender": "MALE"
    },
    {
      "value": "2",
      "label": "Ms.",
      "gender": "FMALE"
    },
    {
      "value": "21",
      "label": "Mx.",
      "gender": "MF"
    }
  ];

  constructor(private fb: FormBuilder,
    private healthDataStorageService: HealthDataStorageService,
    public utilityService: UtilityService,
    private popupService: PopupService,
    private el: ElementRef,
    private snackBar: MatSnackBar,
    private healthMasterService: HealthMasterService,
    private customStepperService: CustomStepperService,
    private renderer: Renderer2,
    private healthService: HealthService,
    private customFooterService: CustomFooterService,
    private route: Router,
    private storageService: StorageService) {
  }

  ngOnInit() {
    this.healthFormData = this.healthDataStorageService.getHealthDataStorage();
    this.quoteDetails = this.storageService.getAESDecryptedData(userEnums.ConverToProposal);
    this.popupService.closeAllDialog(true);
    setTimeout(() => {
      this.popupService.closeAllDialog(false);
      this.onLoadSteps();
    }, 100);
    if (this.quoteDetails && this.quoteDetails.Data && this.quoteDetails.Data.Members) {
      this.updateSavedQuotesInsuredData();
      this.customFooterService.showPremiumDetails(true);
      // this.customStepperService.emitStepData({ status: true, buttonId: 'f3' });
    }
    if (window.innerWidth < 768) {
      this.datepickerMobile = true;
    } else {
      this.datepickerMobile = false;
    }
  }

  onLoadSteps() {
    this.constructMemberDetails();
    this.storageService.getAESDecryptedData('IsOrionJourney') == 'true' ? this.fetchQuestionnaire() : this.fetchHealthAilmentList();
  }

  // IPAA-126: Update healthFormData from of saved quotes page
  updateSavedQuotesInsuredData() {
    this.healthFormData.insuredDataSet = true;
    this.isSubmit = false;
    this.dataSetSuccessfully = true;
    this.quoteDetails.Data.Members.filter(member => {
      if (member.MemberType === "Adult") {
        this.adultDetails.push(member);
      } else if (member.MemberType === "Child") {
        this.childDetails.push(member);
      }
    });
    // this.healthFormData.insuredFormData.preExistingDisease = this.quoteDetails.Data.GenericData.preExistingDisease;
    // this.healthFormData.insuredFormData.existingDisease = this.quoteDetails.Data.GenericData.existingDisease;
    this.adultDetails.forEach((members, index) => {
      this.healthFormData.insuredFormData.insuredMemberDetails[index] = {};
      this.healthFormData.insuredFormData.insuredMemberDetails[index][`title${members.MemberType}${index + 1}`] = this.titleIDConvert(this.quoteDetails.Data.Members[index].TitleID);
      this.healthFormData.insuredFormData.insuredMemberDetails[index][`dob${members.MemberType}${index + 1}`] = this.utilityService.formatDate(this.quoteDetails.Data.Members[index].DOB);
      this.healthFormData.insuredFormData.insuredMemberDetails[index][`fullName${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[index].Name;
      this.healthFormData.insuredFormData.insuredMemberDetails[index][`feetInchesHeight${members.MemberType}${index + 1}`] = (`${this.quoteDetails.Data.Members[index].Height}`);
      this.healthFormData.insuredFormData.insuredMemberDetails[index][`weight${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[index].Weight.toString();
      if (this.quoteDetails.Data.Members[index].VaccinationDate != null) {
        // this.healthFormData.quoteFormData.adultDetails[index]['adultVaccinationStatus${(i + 1)}`] = this.quoteDetails.Data.AdultDetails[index].vaccineStatus;
        this.healthFormData.insuredFormData.insuredMemberDetails[index][`IsPneumococcalVaccination`]= true;
        this.healthFormData.insuredFormData.insuredMemberDetails[index][`pneumoccalDOB${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[index].VaccinationDate;
      }
      this.healthFormData.insuredFormData.insuredMemberDetails[index][`insuredSince${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[index].PortabilityDOJ ? new Date(this.quoteDetails.Data.Members[index].PortabilityDOJ) : "";
      this.healthFormData.insuredFormData.insuredMemberDetails[index][`waiverPeriod${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[index].PortabilityWaiver;
      this.healthFormData.insuredFormData.insuredMemberDetails[index][`preExistinDisease${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[index].Ailments.length > 0 ? "Yes" : "No";
      this.healthFormData.insuredFormData.insuredMemberDetails[index][`applicantRelationship${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[index].RelationshipName;
      this.healthFormData.insuredFormData.insuredMemberDetails[index][`previouslyInsured${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[index].isExisting == "true" ? "Yes" : "No";
      this.healthFormData.insuredFormData.insuredMemberDetails[index][`sumInsured${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[index].PortabilitySI;
      if (this.quoteDetails.Data.SubProductType == 20) {
        this.healthFormData.insuredFormData.insuredMemberDetails[index][`existingHIP${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[index].ExistingHealthPolicy.IsExisting == "true" ? "Yes" : "No";
        this.healthFormData.insuredFormData.insuredMemberDetails[index][`insuranceCompanyNameHB${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[index].ExistingHealthPolicy.InsuranceCompany;
        this.healthFormData.insuredFormData.insuredMemberDetails[index][`policyPeriodHB${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[index].ExistingHealthPolicy.PolicyDuration;
        this.healthFormData.insuredFormData.insuredMemberDetails[index][`sumInsuredHB${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[index].ExistingHealthPolicy.SumInsured;
        this.healthFormData.insuredFormData.insuredMemberDetails[index][`typeOfPolicyHB${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[index].ExistingHealthPolicy.TypeOfPolicy;
      }
      // this.healthFormData.insuredFormData.preExistingDisease[index] = this.quoteDetails.Data.Members[index].Ailments;
    });
    this.childDetails.forEach((members, index) => {
      this.healthFormData.insuredFormData.insuredMemberDetails[this.adultDetails.length + index] = {};
      this.healthFormData.insuredFormData.insuredMemberDetails[this.adultDetails.length + index][`title${members.MemberType}${index + 1}`] = this.titleIDConvert(this.quoteDetails.Data.Members[this.adultDetails.length + index].TitleID);
      this.healthFormData.insuredFormData.insuredMemberDetails[this.adultDetails.length + index][`dob${members.MemberType}${index + 1}`] = this.utilityService.formatDate(this.quoteDetails.Data.Members[this.adultDetails.length + index].DOB);
      this.healthFormData.insuredFormData.insuredMemberDetails[this.adultDetails.length + index][`fullName${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[this.adultDetails.length + index].Name;
      this.healthFormData.insuredFormData.insuredMemberDetails[this.adultDetails.length + index][`feetInchesHeight${members.MemberType}${index + 1}`] = (`${this.quoteDetails.Data.Members[this.adultDetails.length + index].Height}`);
      this.healthFormData.insuredFormData.insuredMemberDetails[this.adultDetails.length + index][`weight${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[this.adultDetails.length + index].Weight.toString();
      if (this.quoteDetails.Data.Members[this.adultDetails.length + index].VaccinationDate != null) {
        this.healthFormData.insuredFormData.insuredMemberDetails[this.adultDetails.length + index][`pneumoccalDOB${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[this.adultDetails.length + index].VaccinationDate;
      }
      this.healthFormData.insuredFormData.insuredMemberDetails[this.adultDetails.length + index][`insuredSince${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[this.adultDetails.length + index].PortabilityDOJ ? new Date(this.quoteDetails.Data.Members[this.adultDetails.length + index].PortabilityDOJ) : "";
      this.healthFormData.insuredFormData.insuredMemberDetails[this.adultDetails.length + index][`waiverPeriod${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[this.adultDetails.length + index].PortabilityWaiver;
      this.healthFormData.insuredFormData.insuredMemberDetails[this.adultDetails.length + index][`preExistinDisease${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[this.adultDetails.length + index].Ailments.length > 0 ? "Yes" : "No";
      this.healthFormData.insuredFormData.insuredMemberDetails[this.adultDetails.length + index][`previouslyInsured${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[this.adultDetails.length + index].isExisting == "true" ? "Yes" : "No";
      this.healthFormData.insuredFormData.insuredMemberDetails[this.adultDetails.length + index][`applicantRelationship${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[this.adultDetails.length + index].RelationshipName;
      // this.healthFormData.insuredFormData.insuredMemberDetails[this.adultDetails.length + index][`sumInsured${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[this.adultDetails.length + index].SumInsured;
      // this.healthFormData.insuredFormData.insuredMemberDetails[this.adultDetails.length + index][`sumInsured${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[index].SumInsured;
      if (this.quoteDetails.Data.SubProductType == 20) {
        this.healthFormData.insuredFormData.insuredMemberDetails[this.adultDetails.length + index][`existingHIP${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[this.adultDetails.length + index].ExistingHealthPolicy.IsExisting;
        this.healthFormData.insuredFormData.insuredMemberDetails[this.adultDetails.length + index][`insuranceCompanyNameHB${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[this.adultDetails.length + index].ExistingHealthPolicy.InsuranceCompany;
        this.healthFormData.insuredFormData.insuredMemberDetails[this.adultDetails.length + index][`policyPeriodHB${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[this.adultDetails.length + index].ExistingHealthPolicy.PolicyDuration;
        this.healthFormData.insuredFormData.insuredMemberDetails[this.adultDetails.length + index][`sumInsuredHB${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[this.adultDetails.length + index].ExistingHealthPolicy.SumInsured;
        this.healthFormData.insuredFormData.insuredMemberDetails[this.adultDetails.length + index][`typeOfPolicyHB${members.MemberType}${index + 1}`] = this.quoteDetails.Data.Members[this.adultDetails.length + index].ExistingHealthPolicy.TypeOfPolicy;
      }
      // this.healthFormData.insuredFormData.preExistingDisease[this.adultDetails.length + index] = this.quoteDetails.Data.Members[this.adultDetails.length + index].Ailments;
      //
    });
    this.healthFormData.insuredFormData.befitCovers = this.quoteDetails.Data.GenericData.befitCovers;
    this.healthDataStorageService.setHealthDataStorage(this.healthFormData);
  }

  // IPAA-126: Covert user title name as per id
  titleIDConvert(id: string) {
    let titleName: string;
    if (id == '0') {
      titleName = "Mrs.";
    } else if (id == '1') {
      titleName = "Mr.";
    } else if (id == '2') {
      titleName = "Ms.";
    } else {
      titleName = "Mx.";
    }
    return titleName;
  }

  changeFieldStructure(fieldType: string, controlsData: FormFields) {
    let controls = { ...controlsData };
    switch (fieldType) {
      case fieldTypeDetails.input: {
        controls.type = fieldTypeDetails.input;
        controls.disabled = true;
        controls.inputType = "text";
        controls.maxLength = "";
        controls.maxNumber = "";
        controls.minNumber = "";
        controls.onlyAlphabets = false;
        controls.onlyNumbers = false;
        delete controls.value
        break;
      }
      case fieldTypeDetails.dropdown: {
        controls.value = [];
        controls.type = fieldTypeDetails.dropdown;
        controls.disabled = false;
        delete controls.maxLength;
        delete controls.maxNumber;
        delete controls.minNumber;
        delete controls.onlyAlphabets;
        delete controls.onlyNumbers;
        delete controls.inputType
        break;
      }
      case fieldTypeDetails.datepicker: {
        let applicantMaxYear = new Date().getFullYear() - 18;
        let tempDate = this.utilityService.getMinMaxDate(applicantMaxYear);
        controls['maxDate'] = tempDate.maxDate;
        controls['type'] = 'datepicker';
        delete controls['inputType'];
        delete controls['maxLength'];
        delete controls['maxNumber'];
        delete controls['minNumber'];
        delete controls['onlyAlphabets'];
        delete controls['onlyNumbers'];
        break;
      }
      default: {
        break
      }
    }
    return controls;
  }

  constructMemberDetails() {
    this.tabGroup = [];
    if (this.healthFormData.quoteFormData.adultDetails.length > 0) {
      this.tabGroup.push({
        'tabLabel': 'Adult',
        'disabled': false,
        'hide': false,
        'selected': 0,
        'imgPath': "../../../../assets/icons/adults.png",
        'memberDetails': []
      })
      this.healthFormData.quoteFormData.adultDetails.forEach((members, index) => {
        this.tabGroup[0]['memberDetails'].push({
          'label': 'Adult ' + (index + 1),
          'selected': index == 0 ? true : false,
          'hide': false
        })
      })
      this.memberChanged('Adult', 0);
      this.showAdultTabs = true;
      this.showChildTabs = false;
    }
    if (this.healthFormData.quoteFormData.childDetails.length > 0) {
      let childTabIndex = this.healthFormData.quoteFormData.adultDetails.length > 0 ? 1 : 0
      this.tabGroup.push({
        'tabLabel': 'Child',
        'disabled': false,
        'hide': false,
        'selected': 0,
        'imgPath': "../../../../assets/images/child1.png",
        'memberDetails': []
      });
      this.healthFormData.quoteFormData.childDetails.forEach((members, index) => {
        this.tabGroup[childTabIndex]['memberDetails'].push({
          'label': 'Child ' + (index + 1),
          'selected': index == 0 ? true : false,
          'hide': false
        })
      })
    }

    this.totalData = this.healthFormData.quoteFormData['totalMemberData'];
    if (this.healthFormData.quoteFormData.childDetails.length > 0 && this.healthFormData.quoteFormData.adultDetails.length == 0) {
      this.memberChanged('Child', 0);
      this.showChildTabs = true;
      this.showAdultTabs = false;
    }
    // Use below flag to hide label if there is only one category present either adult or child
    this.singleMemberCategory = this.healthFormData.quoteFormData.adultDetails.length == 0 || this.healthFormData.quoteFormData.childDetails.length == 0 ? true : false;
  }

  // Below api fetches all the disease list
  fetchHealthAilmentList() {
    this.serviceError = false;
    let requestParams: HealthAilmentRequestPayload = {
      'isAgent': healthToggle.Yes,
      'productType': this.healthFormData.quoteFormData.healthProposalProductType
    };
    this.subscription.push(this.healthMasterService.getHealthAilmentList(requestParams).subscribe({
      next: (response: HealthAilmentResponsePayload) => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.serviceError = false;
          if (response.Data.length > 0) {
            this.existingDisease = response.Data;
            this.fetchInsuredControls();
          }
          else {
            this.customStepperService.emitStepData({ status: false, buttonId: 'f3' });
            this.serviceError = true;
          }
        }
        else {
          this.customStepperService.emitStepData({ status: false, buttonId: 'f3' });
          this.serviceError = true;
        }
      },
      error: error => {
        this.serviceError = true;
      }
    }))
  }

  /**
   * bind orion questionnaire
  */

  fetchQuestionnaire() {
    this.serviceError = false;

    let requestParams: GetQuestionnaireForOrionRequestPayload = {
      productCode: this.healthFormData.quoteFormData.subProductType.toString(),
      planCode: this.healthFormData.quoteFormData.PlanCode.toString()
    };

    this.subscription.push(this.healthMasterService.getQuestionnaireForOrion(requestParams).subscribe({
      next: (response: HealthAilmentResponsePayload) => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.serviceError = false;
          if (response.Data.length > 0) {
            this.existingDisease = response.Data;
            this.fetchInsuredControls();
          }
          else {
            this.customStepperService.emitStepData({ status: false, buttonId: 'f3' });
            this.serviceError = true;
          }
        }
        else {
          this.customStepperService.emitStepData({ status: false, buttonId: 'f3' });
          this.serviceError = true;
        }
      },
      error: error => {
        this.serviceError = true;
      }
    }))
  }

  // below api responsible to fetch all controls
  fetchInsuredControls() {
    this.insuredControls = insuredDetailsControls.insuredControlsdata;

    if (this.healthFormData.quoteFormData.subProductType == 20) {
      this.hbAdditionalControls = healthBoosterAdditionalDetailsControls.healthBoosterAdditionalDetails;
      this.hbAdditionalControls.map((ele, index) => {
        this.insuredControls.push(ele);
      })
      this.fetchCompanyDetails()
    } else {
      const indexesToRemove: number[] = [];
      this.insuredControls.forEach((control, index) => {
        if ([insuredControls.existingHIP, insuredControls.typeOfPolicyHB, insuredControls.policyPeriodHB, insuredControls.sumInsuredHB, insuredControls.insuranceCompanyNameHB].includes(control.controlName)) {
          indexesToRemove.push(index);
        }
      });
      indexesToRemove.reverse().forEach(index => {
        this.insuredControls.splice(index, 1);
      });
    }

    const indexesToRemove: number[] = [];
    this.insuredControls.forEach((control, index) => {
      if (control.controlName === 'doj' || control.controlName === 'isExisting') {
        indexesToRemove.push(index);
      }
    });

    // Remove the objects with controlName equal to 'doj' or 'isExisting'
    indexesToRemove.reverse().forEach(index => {
      this.insuredControls.splice(index, 1);
    });
    this.buttonData = insuredDetailsControls.buttonData;
    if (this.totalData.length > 0) {
      this.totalData.forEach((data, index) => {
        this.totalData[index].controls = this.insuredControls;
      })
    }
    this.constructInsuredForm();
    this.createMemberDetails(insuredDetailsControls.insuredControlsdata);
  }

  constructInsuredForm() {
    this.insuredDetailsForm = this.fb.group({
      memberDetails: this.fb.array([]),
      preExistingDisease: this.fb.array([]),
      addonDetail: this.fb.array([])
    })
  }

  get memberDetails(): FormArray {
    return this.insuredDetailsForm.get('memberDetails') as FormArray;
  }

  get preExistingDisease(): FormArray {
    return this.insuredDetailsForm.get('preExistingDisease') as FormArray;
  }

  createMemberDetails(controls) {
    let adultLength = this.healthFormData.quoteFormData.adultDetails.length;
    let formControls = {};
    // Segregation between adult Data and Child Data
    // Adult Data segregation
    if (this.healthFormData.quoteFormData.adultDetails.length > 0) {
      // If adults are present
      this.healthFormData.quoteFormData.adultDetails.forEach((adultData, adultIndex) => {
        formControls = {};
        // Adding label for adult 1 and adult 2 in total Data . So it will be easier to construct adult data in html
        // Now in start by default adult will be first .So simply we can assign label positions in total data as per adult data
        this.totalData[adultIndex].memberTypeCount = healthMembers.Adult + (adultIndex + 1);

        controls.forEach((formFields, controlsIndex) => {

          // If there are below controls patch the data
          if (formFields.controlName == insuredControls.applicantRelationship || formFields.controlName == insuredControls.dob || formFields.controlName == insuredControls.pneumoccalDOB) {
            // If adult relationship is present patch that value
            if (formFields.controlName == insuredControls.applicantRelationship) {
              formControls[formFields.controlName + healthMembers.Adult + `${adultIndex + 1}`] = new FormControl(
                adultData['adultRealtionship' + (adultIndex + 1)] !== '' ? adultData['adultRealtionship' + (adultIndex + 1)] : "",
                [this.utilityService.requiredFieldValidation()]);
              formFields.disabled = true;
            }
            // If adult dob is present patch that value
            if (formFields.controlName == insuredControls.dob) {
              formControls[formFields.controlName + healthMembers.Adult + `${adultIndex + 1}`] = new FormControl(
                adultData['dobAdult' + (adultIndex + 1)] !== '' ? this.utilityService.formatDate(adultData['dobAdult' + (adultIndex + 1)]) : "",
                [this.utilityService.requiredFieldValidation()]);
              formFields.disabled = true;
            }
            // Now if vaccination date is not present we need to hide vaccination information field in html
            // Hide that control in total data( which is main data)
            if (formFields.controlName == insuredControls.pneumoccalDOB) {
              !(this.utilityService.isEmptyOrNull(adultData['adultVaccinationDate' + (adultIndex + 1)])) ? formControls[formFields.controlName + healthMembers.Adult + `${adultIndex + 1}`] = new FormControl(
                !(this.utilityService.isEmptyOrNull(adultData['adultVaccinationDate' + (adultIndex + 1)])) ? this.utilityService.formatDate(adultData['adultVaccinationDate' + (adultIndex + 1)]) : "",
                [this.utilityService.requiredFieldValidation()]) :
                formControls[formFields.controlName + healthMembers.Adult + `${adultIndex + 1}`] = new FormControl('', null);
              let tempControls = { ...this.totalData[adultIndex].controls[controlsIndex] };
              if (!this.utilityService.isEmptyOrNull(adultData['adultVaccinationDate' + (adultIndex + 1)])) {
                tempControls = { ...tempControls, hide: false };
              }
              else {
                tempControls = { ...tempControls, hide: true };
              }
              controls.splice(controlsIndex, 1, tempControls);
              this.totalData[adultIndex].controls = [...controls];
            }
          }

          else if (formFields.controlName == insuredControls.title) {
            let neutralRelations = ['SELF', 'SPOUSE'];
            let titles = this.originalTitles.slice();
            let tempTitle = { ...this.totalData[adultIndex].controls[controlsIndex] };
            if (!this.healthFormData.renewalDataSet) {
              tempTitle.value = titles.filter(titleItem => {
                let adultGender;
                if (!this.quoteDetails) {
                  // FRESH FLOW
                  adultGender = adultData['Gender' + `${adultIndex + 1}`];
                } else {
                  // CONVERT SAVED QUOTES
                  if (this.healthFormData.quoteFormData.subProductType === 33) {
                    adultGender = adultData['Gender' + `${adultIndex + 1}`];
                    adultGender = adultGender ?? adultData['Gender'];
                  } else {
                    adultGender = adultData['Gender'];
                  }
                }
                const member = healthMembers.Adult + ' ' + (adultIndex + 1);
                const isMF = titleItem.gender === 'MF' || adultGender === 'MF';
                let isMaternity: boolean = false;
                let isNeutralRelation: boolean = false;

                // Check for maternity only for hap and elevate
                if (this.apiKitProducts.includes(this.healthFormData.quoteFormData.subProductType) ) {
                  isMaternity = this.healthFormData.quoteFormData.maternityForAdult.length > 0 && this.healthFormData.quoteFormData.maternityForAdult.includes(member);
                  const relationship = adultData['adultRealtionship' + `${adultIndex + 1}`];
                  isNeutralRelation = neutralRelations.includes(relationship);
                }

                if (isMF || (isMaternity && isNeutralRelation)) {
                  if ((isMaternity && isNeutralRelation) && titleItem.gender === 'MALE') {
                    return false;
                  }
                  else if (isMF) {
                    return true;
                  }
                }
                return titleItem.gender === adultGender;
              });
            } else {
              tempTitle.value = titles;
            }
            controls.splice(controlsIndex, 1, tempTitle);
            this.totalData[adultIndex].controls = [...controls];
            formControls[formFields.controlName + healthMembers.Adult + `${adultIndex + 1}`] = new FormControl(formFields.defaultValue);
          }

          else if (formFields.controlName == insuredControls.previouslyInsured || formFields.controlName == insuredControls.sumInsured || formFields.controlName == insuredControls.insuredSince || formFields.controlName == insuredControls.waiverPeriod) {
            // Now in insured since we need to assign min and max date criteria
            if (formFields.controlName == insuredControls.insuredSince) {
              // As of now we are allowing users to go lot back date.
              this.totalData[adultIndex].controls[controlsIndex].minDate = '';
              this.totalData[adultIndex].controls[controlsIndex].maxDate = this.healthFormData.portabilityFormData.policyStartDate;
            }

            // If type portability is not selected we need to hide below field
            if (this.healthFormData.quoteFormData.productType !== healthProductType.Portability) {
              this.totalData[adultIndex].controls[controlsIndex].hide = true;
              formControls[formFields.controlName + healthMembers.Adult + `${adultIndex + 1}`] = new FormControl('', null);
            }
            // If type portability is selected we need to enable previously insured field
            else if (this.healthFormData.quoteFormData.productType == healthProductType.Portability) {
              if (formFields.controlName == insuredControls.previouslyInsured) {
                this.totalData[adultIndex].controls[controlsIndex].hide = false;
              }
              else if (formFields.controlName == insuredControls.sumInsured || formFields.controlName == insuredControls.insuredSince || formFields.controlName == insuredControls.waiverPeriod) {
                this.totalData[adultIndex].controls[controlsIndex].hide = true;
              }
              formControls[formFields.controlName + healthMembers.Adult + `${adultIndex + 1}`] = new FormControl(formFields.defaultValue, null);
            }
          }

          else if (formFields.controlName == insuredControls.weight || formFields.controlName == insuredControls.heightFeetInches) {
            let validationsData = [];
            if (formFields.controlName == insuredControls.weight) {
              validationsData.push(this.utilityService.requiredFieldValidation(), this.utilityService.zeroValidation(), Validators.pattern(Constants.numberPatternRegExp));
            }
            else {
              validationsData.push(this.utilityService.requiredFieldValidation(), Validators.pattern(patterns.heightFeetInches));
            }
            formControls[formFields.controlName + healthMembers.Adult + `${adultIndex + 1}`] = new FormControl(formFields.defaultValue, validationsData)
          }

          // else if (formFields.controlName == insuredControls.preExistinDisease) {
          //   console.log("Controls value", this.totalData[adultIndex]);
          //   if (!this.utilityService.isEmptyOrNull(this.healthFormData.quoteFormData.criticalIllnessForAdult)) {
          //     console.log("Critical iLlness value",this.healthFormData.quoteFormData.criticalIllnessForAdult);
          //     formFields.hide = true;
          //   }
          // }

          else {
            if (!this.hbAdditionalFieldArray.includes(formFields.controlName)) {
              if (formFields.controlName == insuredControls.preExistinDisease) {
                let pedIndex = adultIndex + 1;
                let memberName = healthMembers.Adult + ' ' + pedIndex;
                let tempFormFields = { ...formFields };
                if (this.apiKitProducts.includes(this.healthFormData.quoteFormData.subProductType) && this.healthFormData.quoteFormData.criticalIllnessForAdult.length > 0) {
                  //  && !this.utilityService.isEmptyOrNull(this.healthFormData.quoteFormData.criticalIllnessForAdult) && this.healthFormData.quoteFormData.criticalIllnessForAdult.includes(pedIndex.toString())
                  if (this.healthFormData.quoteFormData.criticalIllnessForAdult.includes(memberName)) {

                    tempFormFields = { ...tempFormFields, hide: true };
                    let tempControls = [...controls];
                    tempControls.splice(controlsIndex, 1, tempFormFields);
                    this.totalData[adultIndex].controls = [...tempControls];
                  }
                  // this.totalData[adultIndex].controls[controlsIndex].hide = true;
                }
                else {
                  tempFormFields = { ...tempFormFields, hide: false };
                  let tempControls = [...controls];
                  tempControls.splice(controlsIndex, 1, tempFormFields);
                  this.totalData[adultIndex].controls = [...tempControls];
                  // this.totalData[adultIndex].controls[controlsIndex].hide = false;
                }
              }
              formControls[formFields.controlName + healthMembers.Adult + `${adultIndex + 1}`] = new FormControl(formFields.defaultValue, [this.utilityService.requiredFieldValidation()]);
            } else {
              // if (formFields.controlName == insuredControls.preExistinDisease) {
              //   console.log("Controls value", this.totalData[adultIndex]);
              //   if (!this.utilityService.isEmptyOrNull(this.healthFormData.quoteFormData.criticalIllnessForAdult)) {
              //     console.log("Critical iLlness value", this.healthFormData.quoteFormData.criticalIllnessForAdult);
              //     formFields.hide = true;
              //   }
              //   else{
              //     formFields.hide = false;
              //   }
              // }
              formControls[formFields.controlName + healthMembers.Adult + `${adultIndex + 1}`] = new FormControl(formFields.defaultValue);
            }
          }
        })
        // patching prexisting disease for every member
        let preExistingFormControls = {};
        let tempExistingDisease = this.patchExistingDisease();
        if (tempExistingDisease.length > 0) {
          tempExistingDisease.forEach(data => {
            preExistingFormControls[(data['Value']).toString() + healthMembers.Adult + `${adultIndex + 1}`] = new FormControl(false, [this.utilityService.requiredFieldValidation()]);
          })
          this.preExistingDisease.push(new FormGroup(preExistingFormControls));
        }
        this.totalData[adultIndex].existingDisease = tempExistingDisease;
        this.totalData[adultIndex].existingDiseaseHide = true;
        this.memberDetails.push(new FormGroup(formControls));
      })
    }
    // Child Data segregation
    if (this.healthFormData.quoteFormData.childDetails.length > 0) {
      // If childs are present
      this.healthFormData.quoteFormData.childDetails.forEach((childData, childIndex) => {
        formControls = {};
        // Adding label for child 1 and child 2 in total Data . So it will be easier to construct child data in html
        // Now once we complete adult data block we need to modify child data .
        // Child data will always be after adult data/ if adult data is not present still it will take length as 0
        let childLength = childIndex == 0 ? adultLength - childIndex : adultLength + childIndex;
        this.totalData[childLength].memberTypeCount = healthMembers.Child + (childIndex + 1);
        this.totalData[childLength].existingDiseaseHide = true;

        controls.forEach((formFields, controlsIndex) => {

          // If there are below control fields patch the data
          if (formFields.controlName == insuredControls.applicantRelationship || formFields.controlName == insuredControls.dob || formFields.controlName == insuredControls.pneumoccalDOB) {
            // If child relationship is present patch that value
            if (formFields.controlName == insuredControls.applicantRelationship) {
              formControls[formFields.controlName + healthMembers.Child + `${childIndex + 1}`] = new FormControl(
                childData['childRelationship' + (childIndex + 1)] !== '' ? childData['childRelationship' + (childIndex + 1)] : "",
                [this.utilityService.requiredFieldValidation()]);
            }
            // If child dob is present patch that value
            if (formFields.controlName == insuredControls.dob) {
              formControls[formFields.controlName + healthMembers.Child + `${childIndex + 1}`] = new FormControl(
                childData['dobChild' + (childIndex + 1)] !== '' ? this.utilityService.formatDate(childData['dobChild' + (childIndex + 1)]) : "",
                [this.utilityService.requiredFieldValidation()]);
            }
            // Now if vaccination date is not present we need to hide vaccination information field in html
            // Now for child there should not be any vaccination date present
            if (formFields.controlName == insuredControls.pneumoccalDOB) {
              formControls[formFields.controlName + healthMembers.Child + `${childIndex + 1}`] = new FormControl('', null);
              let tempControls = { ...this.totalData[childLength].controls[controlsIndex] };
              tempControls = { ...tempControls, hide: true };
              controls.splice(controlsIndex, 1, tempControls);
              this.totalData[childLength].controls = [...controls];
            }

          }

          else if (formFields.controlName == insuredControls.title) {
            let titles = this.originalTitles.slice();
            let tempTitle = { ...this.totalData[childLength].controls[controlsIndex] };
            if (this.quoteDetails) {
              tempTitle.value = titles.filter(titleItem => {
                if (titleItem.gender == 'MF' || childData['Gender'] == 'MF') return true;
                if (this.healthFormData.quoteFormData.subProductType === 33) {
                  let childGender = childData['Gender' + `${childIndex + 1}`];
                  childGender = childGender ?? childData['Gender'];
                  return titleItem.gender === childGender;
                } else {
                  return titleItem.gender === childData['Gender']
                }
              });
            } else {
              tempTitle.value = titles.filter(titleItem => {
                if (titleItem.gender == 'MF' || childData['Gender' + `${childIndex + 1}`] == 'MF') return true;
                return titleItem.gender === childData['Gender' + `${childIndex + 1}`]
              });
            }
            controls.splice(controlsIndex, 1, tempTitle);
            this.totalData[childLength].controls = [...controls];

            formControls[formFields.controlName + healthMembers.Child + `${childIndex + 1}`] = new FormControl(formFields.defaultValue);
          }


          else if (formFields.controlName == insuredControls.previouslyInsured || formFields.controlName == insuredControls.sumInsured || formFields.controlName == insuredControls.insuredSince || formFields.controlName == insuredControls.waiverPeriod) {
            // Now in insured since we need to assign min and max date criteria
            if (formFields.controlName == insuredControls.insuredSince) {
              this.totalData[childLength].controls[controlsIndex].minDate = '';
              this.totalData[childLength].controls[controlsIndex].maxDate = this.healthFormData.portabilityFormData.policyStartDate;
            }

            // If type portability is not selected we need to hide below field
            if (this.healthFormData.quoteFormData.productType !== healthProductType.Portability) {
              this.totalData[childLength].controls[controlsIndex].hide = true;
              formControls[formFields.controlName + healthMembers.Child + `${childIndex + 1}`] = new FormControl('', null);
            }
            // If type portability is selected we need to enable previously insured field
            else if (this.healthFormData.quoteFormData.productType == healthProductType.Portability) {
              if (formFields.controlName == insuredControls.previouslyInsured) {
                this.totalData[childLength].controls[controlsIndex].hide = false;
              }
              else if (formFields.controlName == insuredControls.sumInsured || formFields.controlName == insuredControls.insuredSince || formFields.controlName == insuredControls.waiverPeriod) {
                this.totalData[childLength].controls[controlsIndex].hide = true;
              }
              formControls[formFields.controlName + healthMembers.Child + `${childIndex + 1}`] = new FormControl(formFields.defaultValue, null);
            }
          }
          else if (formFields.controlName == insuredControls.weight || formFields.controlName == insuredControls.heightFeetInches) {
            let validationsData = [];
            if (formFields.controlName == insuredControls.weight) {
              validationsData.push(this.utilityService.requiredFieldValidation(), this.utilityService.zeroValidation(), Validators.pattern(Constants.numberPatternRegExp));
            }
            else {
              validationsData.push(this.utilityService.requiredFieldValidation(), Validators.pattern(patterns.heightFeetInches));
            }
            formControls[formFields.controlName + healthMembers.Child + `${childIndex + 1}`] = new FormControl(formFields.defaultValue, validationsData)
          }
          else {
            // additional field for hb for child
            if (!this.hbAdditionalFieldArray.includes(formFields.controlName)) {
              if (formFields.controlName == insuredControls.preExistinDisease) {
                let pedIndex = childIndex + 1;
                let memberName = healthMembers.Child + ' ' + pedIndex;
                let tempFormFields = { ...formFields };
                if (this.apiKitProducts.includes(this.healthFormData.quoteFormData.subProductType) && this.healthFormData.quoteFormData.criticalIllnessForAdult.length > 0) {
                  //  && !this.utilityService.isEmptyOrNull(this.healthFormData.quoteFormData.criticalIllnessForAdult) && this.healthFormData.quoteFormData.criticalIllnessForAdult.includes(pedIndex.toString())
                  if (this.healthFormData.quoteFormData.criticalIllnessForAdult.includes(memberName)) {
                    tempFormFields = { ...tempFormFields, hide: true };
                    let tempControls = [...controls];
                    tempControls.splice(controlsIndex, 1, tempFormFields);
                    this.totalData[childLength].controls = [...tempControls];
                  }
                  // this.totalData[adultIndex].controls[controlsIndex].hide = true;
                }
                else {
                  tempFormFields = { ...tempFormFields, hide: false };
                  let tempControls = [...controls];
                  tempControls.splice(controlsIndex, 1, tempFormFields);
                  this.totalData[childLength].controls = [...tempControls];
                  // this.totalData[adultIndex].controls[controlsIndex].hide = false;
                }
              }
              formControls[formFields.controlName + healthMembers.Child + `${childIndex + 1}`] = new FormControl(formFields.defaultValue, [this.utilityService.requiredFieldValidation()]);
            }
            else {
              formControls[formFields.controlName + healthMembers.Child + `${childIndex + 1}`] = new FormControl(formFields.defaultValue);
            }
          }
        })
        let tempExistingDisease = this.patchExistingDisease();
        // Prexisting Disease
        let preExistingFormControls = {};
        if (tempExistingDisease.length > 0) {
          tempExistingDisease.forEach(data => {
            preExistingFormControls[(data['Value']).toString() + healthMembers.Child + `${childIndex + 1}`] = new FormControl(false, [this.utilityService.requiredFieldValidation()]);
          })
          this.preExistingDisease.push(new FormGroup(preExistingFormControls));
        }
        this.totalData[childLength].existingDisease = tempExistingDisease;
        this.memberDetails.push(new FormGroup(formControls));
      })
    }
    if (this.healthFormData.insuredDataSet && this.preExistingDisease.controls.length > 0) {
      this.patchExistingValue();
    }
    else if (!this.healthFormData.insuredDataSet) {
      this.loadPanel = true;
    }

    // if(this.quoteDetails) {
    //   this.onSubmit(null);
    // }

    if (this.quoteDetails && this.quoteDetails.Data) {
      this.preExistingDisease.setValue(this.quoteDetails.Data.GenericData.insuredPED);
    }
  }


  checkExistingMember(data, index): number {
    for (const key in data) {
      if (key.includes('isExisting') && data[key] === 'true') {
        return index;
      }
    }
    return -1;
  }
  getInsuredMinMaxDate(control, tag, member) {
    if (control.controlName == 'insuredSince' && tag == 'max') {
      control.maxDate = this.healthFormData.portabilityFormData.policyStartDate
      return control.maxDate;
    }
  }
  patchExistingValue() {
    if (this.healthFormData.insuredFormData.insuredMemberDetails.length > 0) {
      this.healthFormData.insuredFormData.insuredMemberDetails.forEach((data, index) => {
        let memberDetails = this.memberDetails.controls[index] as FormGroup;
        let diseaseControls = this.preExistingDisease.controls[index] as FormGroup;

        // keys present in insuredMemberDetails is very much similar to insured member controls
        // Relationship, dob and pneumoccal dob should not be patched from insured member details
        // It should be patched from createMember Details
        let dataindex = this.checkExistingMember(data, index);
        for (let keys in data) {
          if (!(keys.includes(insuredControls.applicantRelationship) || keys.includes(insuredControls.dob) || keys.includes(insuredControls.pneumoccalDOB))) {
            //  Check For title in case of maternity opted for adult/child in hap
            if (this.apiKitProducts.includes(this.healthFormData.quoteFormData.subProductType) && this.healthFormData.quoteFormData.maternityForAdult.length > 0 && keys.includes(insuredControls.title)) {
              const neutralRelations = ['SELF', 'SPOUSE'];
              // To find out which member it is. Splitting of keys for maternity member
              let tempPedKey = keys.split(insuredControls.title)[1];
              let memberType = tempPedKey.includes(healthMembers.Adult) ? healthMembers.Adult : healthMembers.Child;
              let memberKey = tempPedKey.split(memberType);
              let newKey = memberType + ' ' + memberKey[1];
              const isNeutralRelation = neutralRelations.includes(this.healthFormData.insuredFormData.insuredMemberDetails[index][insuredControls.applicantRelationship + `${memberType + (memberKey[1])}`]);
              let titleValue = data[keys];
              //  If above three relation exists and maternity holds the value
              if (isNeutralRelation && this.healthFormData.quoteFormData.maternityForAdult.includes(newKey) && (titleValue == 'Mr.' || this.utilityService.isEmptyOrNull(titleValue))) {
                // Find whether this title value exists in title dropdown value
                this.selfTitleExists = true;
                memberDetails.controls[keys].setValue('');
                memberDetails.controls[keys].setValidators(Validators.required);
                memberDetails.controls[keys].updateValueAndValidity();

              }
              else {
                memberDetails.controls[keys].setValue(data[keys]);
              }
            }
            else {
              memberDetails.controls[keys].setValue(data[keys]);
            }
          }
          else if (this.quoteDetails) {
            memberDetails.controls[keys].setValue(data[keys]);
          }

          // There could be possiblity user has initially selected New as product type and later he selectes portability
          // So you need to set value explicitly
          if (keys.includes(insuredControls.previouslyInsured)) {
            if (this.healthFormData.quoteFormData.productType == 'Portability' && this.utilityService.isEmptyOrNull(data[keys])) {
              memberDetails.controls[keys].setValue(healthToggle.No);
              data[keys] = healthToggle.No;
            }
            this.changeRadioValue(insuredControls.previouslyInsured, data[keys], index, this.totalData[index].memberTypeCount);
          }
          // let membertype = this.quoteDetails ? this.quoteDetails.Data.Members[index].MemberType : null;

          // if(this.quoteDetails) {
          //   memberDetails.controls[`dob${membertype}${(index+1)}`].setValue(this.utilityService.formatDate((this.quoteDetails.Data.Members[index].DOB)));
          //   memberDetails.controls[`applicantRelationship${membertype}${(index+1)}`].setValue(this.quoteDetails.Data.Members[index].RelationshipName);
          // }

          // Here also preexisting disease is similar to preexisting disease controls.
          // So If value exists controls will get bind automatically
          if (keys.includes(insuredControls.preExistinDisease)) {
            if (data[keys] == healthToggle.Yes) {

              let tempPedKey = keys.split(insuredControls.preExistinDisease)[1];
              let memberType = tempPedKey.includes(healthMembers.Adult) ? healthMembers.Adult : healthMembers.Child;
              let memberKey = tempPedKey.split(memberType);
              let newKey = memberKey[0] + ' ' + memberKey[1];

              if (!this.apiKitProducts.includes(this.healthFormData.quoteFormData.subProductType) || !this.healthFormData.quoteFormData.criticalIllnessForAdult.includes((newKey))) {

                // Below method is just used to hide show the existing disease block
                this.changeRadioValue(insuredControls.preExistinDisease, data[keys], index, this.totalData[index].memberTypeCount);

                // Below method will just patch all the disease data in respective controls
                if (this.healthFormData.insuredFormData.preExistingDisease.length > 0) {
                  let preExistingData = this.healthFormData.insuredFormData.preExistingDisease[index];
                  for (let diseaseKeys in preExistingData) {
                    // If addon cover is selected as personal accident then remarks would be asked
                    // Remarks block initially are not added in the control so if user has laready selected 40 / 41 existing disease 
                    // Then remark block should be shown
                    if (diseaseKeys.includes((insuredDiseaseID.Fourty).toString()) || diseaseKeys.includes((insuredDiseaseID.FourtyOne).toString())) {
                      if (preExistingData[diseaseKeys]) {
                        let existingDiseaseId: number = diseaseKeys.includes((insuredDiseaseID.Fourty).toString()) ? insuredDiseaseID.Fourty : insuredDiseaseID.FourtyOne;
                        // Find the index of disease[] where 40 or 41 value disease is present
                        let existingDiseaseIndex: number = this.healthFormData.insuredFormData.existingDisease.findIndex(data => data.Value == existingDiseaseId);
                        // Below method will add remark section
                        this.preExistingDiseaseControls(diseaseKeys, index, existingDiseaseIndex, existingDiseaseId, preExistingData[diseaseKeys])
                      }
                    }
                    diseaseControls.controls[diseaseKeys].setValue(preExistingData[diseaseKeys]);
                  }
                }
              }
            }
          }

          if (this.healthFormData.quoteFormData.subProductType == 20) {
            if (keys.includes(insuredControls.existingHIP)) {
              if (data[keys] == healthToggle.Yes) {
                this.changeRadioValue(insuredControls.existingHIP, data[keys], index, this.totalData[index].memberTypeCount);
                this.healthFormData.insuredFormData.insuredMemberDetails.map((ele, ind) => {
                  memberDetails.controls[`typeOfPolicyHB${this.totalData[index].memberTypeCount}`].setValue(ele[`typeOfPolicyHB${this.totalData[index].memberTypeCount}`]);
                  memberDetails.controls[`policyPeriodHB${this.totalData[index].memberTypeCount}`].setValue(ele[`policyPeriodHB${this.totalData[index].memberTypeCount}`]);
                  memberDetails.controls[`sumInsuredHB${this.totalData[index].memberTypeCount}`].setValue(ele[`sumInsuredHB${this.totalData[index].memberTypeCount}`]);
                  memberDetails.controls[`policyPeriodHB${this.totalData[index].memberTypeCount}`].setValue(ele[`policyPeriodHB${this.totalData[index].memberTypeCount}`]);
                })
              }
            }
          }

        }

        if (index == this.healthFormData.insuredFormData.insuredMemberDetails.length - 1) {
          this.patchingDone = true;
        }
      })
    }
    // check whether self exists then freeze title and fullname
    if (this.healthFormData.kycStatus.isKYCDoneSuccessfully) {
      if (this.healthFormData.kycStatus.normalSelf || this.healthFormData.kycStatus.ovdSelf) {
        // true means it needs to patch data from insured members
        this.checkWhetherSelfExists(true);
      }
      else {
        // If user changes relation as self and then he clicks on insured.
        // But he did kyc from normaal relation then also we need to check
        // false means that it needs to patch data from kyc applicant block
        this.checkWhetherSelfExists(false)
      }
    }
    if (this.patchingDone) {
      this.insuredDetailsForm.valueChanges.subscribe(data => {
        this.customStepperService.emitStepData({ status: false, buttonId: 'f3' });
      })
    }
    this.loadPanel = true;
  }


  setResetChildDOB(tag, memberType, relationShipName) {
    if (!this.utilityService.isEmptyOrNull(relationShipName)) {
      // Max Date
      if (this.healthFormData.quoteFormData.adultDetails.length > 0) {
        // Child max date will be 3 months
        this.maxDate = this.utilityService.subtractMonths(new Date(), 3);
      }
      // If no adult is present then child max date will be 6 years
      else if (this.healthFormData.quoteFormData.adultDetails.length == 0) {
        this.maxDate = this.utilityService.subtractYears(new Date(), 6);
      }
      // Min Date
      // Dependent Kid
      if (relationShipName == 'SON' || relationShipName == 'DAUGHTER') {
        let yearDate = this.utilityService.subtractYears(new Date(), 25);
        let monthDate = this.utilityService.subtractMonths(yearDate, 11);
        this.minDate = this.utilityService.subtractDays(monthDate, 25);
      }
      else {
        this.minDate = this.utilityService.subtractYears(new Date(), 18);
      }
    }
    return tag == 'min' ? this.minDate : this.maxDate
  }

  // changeFeildRelationshipAndDOB(dataIndex) {
  //   let product: RelationshipRequestPayload = {
  //     product: this.healthFormData.quoteFormData.subProductType == 40 ? healthProposalProductCode.gs : "HADV",
  //   }
  //   if (this.utilityService.isEmptyOrNull(this.relationshipDetails)) {
  //     this.subscription.push(this.healthMasterService.getHealthProposalRelationships(product).subscribe(response => {
  //       if (response.StatusCode == 1 && response.StatusMsg == "Success") {
  //         this.relationshipDetails = response.Data.InsuredRelationship;
  //         this.patchDOBRelation(dataIndex);
  //       }
  //     }))
  //   } else {
  //     this.patchDOBRelation(dataIndex);
  //   }
  // }

  // patchDOBRelation(dataIndex) {
  //   if (this.healthFormData.quoteFormData.totalMemberData.length > 0) {
  //     this.healthFormData.quoteFormData.totalMemberData.forEach((data, index) => {
  //       if (dataIndex == index) {
  //         let controlsData: Array<FormFields> = [...this.totalData[index].controls];
  //         this.totalData[index].controls = [];
  //         controlsData.forEach(x => {
  //           if (x.controlName == insuredControls.applicantRelationship) {
  //             x = this.changeFieldStructure("dropdown", x);
  //             if (data['MemberType'] == "Adult") {
  //               const transformedData = this.relationshipDetails.filter(detail => detail.KidAdultType === "Adult")
  //                 .map(detail => ({
  //                   value: detail.RelationshipID,
  //                   label: detail.RelationshipName
  //                 }));
  //               x.value = transformedData;
  //             } else {
  //               const transformedData = this.relationshipDetails.filter(detail => detail.KidAdultType === "Child")
  //                 .map(detail => ({
  //                   value: detail.RelationshipID,
  //                   label: detail.RelationshipName
  //                 }));
  //               x.value = transformedData;
  //             }
  //           }
  //           if (x.controlName == insuredControls.dob) {
  //             x = this.changeFieldStructure("datepicker", x);
  //           }
  //           this.totalData[index].controls.push(x);
  //         })
  //       }
  //       else if (data['RelationshipwithApplicant'] == "") {
  //         let controlsData: Array<FormFields> = [...this.totalData[index].controls];
  //         this.totalData[index].controls = [];
  //         controlsData.forEach(x => {
  //           if (x.controlName == insuredControls.applicantRelationship) {
  //             x = this.changeFieldStructure("dropdown", x);
  //             if (data['MemberType'] == "Adult") {
  //               const transformedData = this.relationshipDetails.filter(detail => detail.KidAdultType === "Adult")
  //                 .map(detail => ({
  //                   value: detail.RelationshipID,
  //                   label: detail.RelationshipName
  //                 }));
  //               x.value = transformedData;
  //             } else {
  //               const transformedData = this.relationshipDetails.filter(detail => detail.KidAdultType === "Child")
  //                 .map(detail => ({
  //                   value: detail.RelationshipID,
  //                   label: detail.RelationshipName
  //                 }));
  //               x.value = transformedData;
  //             }
  //           }
  //           if (x.controlName == insuredControls.dob) {
  //             x = this.changeFieldStructure("datepicker", x);
  //           }
  //           this.totalData[index].controls.push(x);
  //         })
  //       }
  //     })
  //   }
  // }

  checkWhetherSelfExists(patchInsuredBlock: boolean) {
    if (this.healthFormData.quoteFormData.adultDetails.length > 0) {
      let selfIndex = this.healthFormData.quoteFormData.adultDetails.findIndex((data, index) => data['adultRealtionship' + (index + 1)] == 'SELF');
      if (selfIndex != -1) {
        let memberDetails = this.memberDetails.controls[selfIndex] as FormGroup;
        let adultControlName = 'Adult' + (selfIndex + 1);
        let controlsData: Array<FormFields> = [...this.totalData[selfIndex].controls];
        this.totalData[selfIndex].controls = [];
        controlsData.forEach((formFields) => {
          if (formFields.controlName == insuredControls.fullName || formFields.controlName == insuredControls.title) {
            let controlName = formFields.controlName == insuredControls.fullName ? (insuredControls.fullName + adultControlName) : (insuredControls.title + adultControlName);

            // Below block will get executed only for hap
            // we need to check if the title is empty and if maternity cover was opted then only self member exists will be true
            // then in that case we need to enable it
            if (formFields.controlName == insuredControls.title && this.selfTitleExists && this.apiKitProducts.includes(this.healthFormData.quoteFormData.subProductType)) {
              // Check if that title value is empty
              if (this.utilityService.isEmptyOrNull(memberDetails.controls[insuredControls.title + adultControlName].value)) {
                memberDetails.controls[controlName].enable();
              }
            }
            else {
              memberDetails.controls[controlName].disable();
            }
            // If in quote it was set as self then patch applicant block data
            if (!patchInsuredBlock) {
              let applicantValue = formFields.controlName == insuredControls.fullName ? this.healthFormData.kycFormData.applicantDetails.applicantFullName : this.healthFormData.kycFormData.applicantDetails.applicantTitle
              memberDetails.controls[controlName].setValue(applicantValue);
            }
          }
          else if (formFields.controlName == insuredControls.dob) {
            let insuredDob = !patchInsuredBlock ? this.healthFormData.kycFormData.applicantDetails.applicantDOB : this.healthFormData.insuredFormData.insuredMemberDetails[selfIndex][insuredControls.dob + adultControlName];
            insuredDob = insuredDob.includes('T') ? this.utilityService.formatDate(insuredDob) : insuredDob
            let insuredDOBValue = insuredDob;
            memberDetails.controls[insuredControls.dob + adultControlName].setValue(insuredDOBValue);
          }
        })
        this.totalData[selfIndex].controls = controlsData;
      }
    }
  }

  // patching preexisting disease for each member
  patchExistingDisease(): Array<{}> {
    let tempExistingDisease: Array<{}> = this.existingDisease;
    tempExistingDisease = tempExistingDisease.map(data => {
      let tempObject = {
        ...data,
        type: 'radio',
        hide: 'false',
        value: [healthToggle.Yes, healthToggle.No],
        showRemarks: false,
        showRemarksType: 'text',
        remarksLabel: 'Remarks',
        remarksApplicable: false,
        remarkControlName: '',
      }
      return tempObject;
    })
    return tempExistingDisease
  }

  // To detect which member is selected
  memberTypeSelected(type) {
    if (type.index == 0) {
      this.memberChanged('Adult', 0);
      this.showChildTabs = false;
      this.showAdultTabs = true;
    }
    else {
      this.memberChanged('Child', 0);
      this.showAdultTabs = false;
      this.showChildTabs = true;
    }

  }

  memberChanged(type: string, index) {
    if (type == healthMembers.Adult && this.healthFormData.quoteFormData.adultDetails.length > 0) {
      this.memberDataIndex = index;
      this.tabGroup.forEach((ele, ind) => {
        if (ele['tabLabel'] == type) {
          ele['memberDetails'].forEach((ele2, ind2) => {
            if (ind2 == index) {
              ele2['selected'] = true;
            } else {
              ele2['selected'] = false;
            }
          })
        }
      })
    }

    else {
      let adultLength = this.healthFormData.quoteFormData.adultDetails.length;
      if (this.healthFormData.quoteFormData.adultDetails.length == 0 && this.healthFormData.quoteFormData.childDetails.length > 0) {
        this.memberDataIndex = index;
      }

      else if (this.healthFormData.quoteFormData.adultDetails.length > 0 && this.healthFormData.quoteFormData.childDetails.length > 0) {
        this.memberDataIndex = adultLength + index;
      }

      // for child
      this.tabGroup.forEach((ele, ind) => {
        if (ele['tabLabel'] == type) {
          ele['memberDetails'].forEach((ele2, ind2) => {
            if (ind2 == index) {
              ele2['selected'] = true;
            } else {
              ele2['selected'] = false;
            }
          })
        }
      })

    }
  }


  // Feet height modification methods
  feetHeightFormatting(eventData, data, index, alphaCheck: boolean, numberCheck: boolean) {
    let memberDetails = this.memberDetails.controls[index] as FormGroup;
    let insuredHeightData = memberDetails.get(`feetInchesHeight${data.memberTypeCount}`);
    if (eventData.target.id.includes('feetInchesHeight')) {
      this.utilityService.dynamicControlsNumberDecimalOnly(eventData, numberCheck);
      if (!isNaN(eventData.target.value)) {
        let heightInput = insuredHeightData.value;
        let heightInputArr = heightInput.split('');
        if (heightInputArr[1] != '.' && heightInput.length == 2) {
          insuredHeightData.setValue(heightInputArr[0] + '.' + heightInputArr[1]);
        }
      }
    }
    else {
      this.utilityService.dynamicControlsAlphaOnly(eventData, alphaCheck);
      this.utilityService.dynamicControlsNumberOnly(eventData, numberCheck);
      if (!(data.controlName = insuredControls.fullName)) {
        this.utilityService.restrictSpace(eventData);
      }
    }
  }

  feetHeightChange(eventData, data, ind) {
    let value = this.insuredDetailsForm['controls']['memberDetails']['controls'][ind].get(`feetInchesHeight${data.memberTypeCount}`).value;
    if (value.length == 1) {
      this.insuredDetailsForm['controls']['memberDetails']['controls'][ind].get(`feetInchesHeight${data.memberTypeCount}`).setValue(value + '.' + 0)
    }
  }

  // All logic w.r.t dropdown controls handle in below method
  changeOfDropdown(value) {
  }

  // All logic w.r.t radio controls handle in below method
  changeRadioValue(controlName, value, totalDataIndex, type) {
    let memberDetails = this.memberDetails.controls[totalDataIndex] as FormGroup;
    if (this.healthFormData.quoteFormData.subProductType == 20) {

      if (controlName == 'existingHIP' && value == "Yes") {
        let existingHIPYES = this.totalData[totalDataIndex]['controls'].map((ele, ind) => {
          if (this.hbAdditionalFieldArray.includes(ele.controlName)) {
            return { ...ele, hide: false }
          } else {
            return ele
          }

        })
        this.totalData[totalDataIndex].controls = existingHIPYES;

        this.hbAdditionalFieldArray.map((ele, ind) => {
          let hbControlName = ele + '' + type;
          memberDetails['controls'][hbControlName].setValidators([Validators.required]);
          memberDetails['controls'][hbControlName].updateValueAndValidity();
        })

      } else if (controlName == 'existingHIP' && value == "No") {

        let existingHIPNO = this.totalData[totalDataIndex]['controls'].map((ele, ind) => {
          if (this.hbAdditionalFieldArray.includes(ele.controlName)) {
            return { ...ele, hide: true }
          } else {
            return ele
          }
        })
        this.totalData[totalDataIndex].controls = existingHIPNO;

        this.hbAdditionalFieldArray.map((ele, ind) => {
          let hbControlName = ele + '' + type;
          memberDetails['controls'][hbControlName].setValue("");
          memberDetails['controls'][hbControlName].clearValidators();
          memberDetails['controls'][hbControlName].updateValueAndValidity();
        })
      }
    }
    // If previous insured is selected as yes show below fields or hide below fields
    if (controlName == insuredControls.previouslyInsured) {
      const newControlsArray = this.totalData[totalDataIndex].controls.map(obj => {
        if (obj.controlName == insuredControls.insuredSince || obj.controlName == insuredControls.waiverPeriod) {
          if (value == healthToggle.No) {
            this.hidePED = true;
            memberDetails.controls[obj.controlName + type].setValue('');
          }
          if (value == healthToggle.Yes) {
            this.hidePED = false;
          }
          (value == healthToggle.Yes) ? memberDetails.controls[obj.controlName + type].setValidators([this.utilityService.requiredFieldValidation()]) : memberDetails.controls[obj.controlName + type].clearValidators();
          memberDetails.controls[obj.controlName + type].updateValueAndValidity();
          return (value == healthToggle.Yes) ? { ...obj, hide: false } : { ...obj, hide: true };
        }
        else if (obj.controlName == insuredControls.sumInsured && this.healthFormData.portabilityFormData.typeOfPolicy == policyType.Individual) {
          if (value == healthToggle.No) {
            this.hidePED = true;
            memberDetails.controls[obj.controlName + type].setValue('');
          }
          if (value == healthToggle.Yes) {
            this.hidePED = false;
          }
          (value == healthToggle.Yes) ? memberDetails.controls[obj.controlName + type].setValidators([this.utilityService.requiredFieldValidation()]) : memberDetails.controls[obj.controlName + type].clearValidators();
          memberDetails.controls[obj.controlName + type].updateValueAndValidity();
          return (value == healthToggle.Yes) ? { ...obj, hide: false } : { ...obj, hide: true };
        }
        return obj;
      });
      this.totalData[totalDataIndex].controls = newControlsArray;
    }

    if (controlName == insuredControls.preExistinDisease) {
      if (value == healthToggle.Yes) {
        this.hidePED = false;
        this.totalData[totalDataIndex].existingDiseaseHide = false;
        //Below is the method for CHecking EMI condition
        this.checkEMI();
      }

      else if (value == healthToggle.No) {
        this.hidePED = true;
        this.totalData[totalDataIndex].existingDiseaseHide = true;
        let memberDisease = this.totalData[totalDataIndex].existingDisease;
        let diseaseControls = this.preExistingDisease.controls[totalDataIndex] as FormGroup;
        let diseaseIndexArray = [];
        // Now when user selected NO we need to remove the remark section also if applicable
        memberDisease.forEach((data, diseaseIndex) => {
          // This block removes control if we have included extra remark objects in totatl data array
          if ((data.Value == insuredDiseaseID.Fourty || data.Value == insuredDiseaseID.FourtyOne) && data.Name.includes(remarkList.Remark)) {
            diseaseIndexArray.push(diseaseIndex)
            diseaseControls.removeControl(data.Value == insuredDiseaseID.Fourty ? remarkList.Remark1 + this.totalData[totalDataIndex].memberTypeCount : remarkList.Remark2 + this.totalData[totalDataIndex].memberTypeCount)

          }
          // Below block is used to hide remark section
          if (data.Value == insuredDiseaseID.Fourty || data.Value == insuredDiseaseID.FourtyOne) {
            memberDisease[diseaseIndex].showRemarks = false
          }
        })
        //  Now we need to remove the data backwards. IF u try to remove data from front side it will only delete one element
        if (diseaseIndexArray.length > 0) {
          for (let i = diseaseIndexArray.length - 1; i >= 0; i--) {
            memberDisease.splice(diseaseIndexArray[i], 1);
          }
        }

        this.totalData[totalDataIndex].existingDisease = memberDisease;

        // If user selects no then we also need to reset all fields 
        for (let diseaseKeys in this.preExistingDisease.controls[totalDataIndex]['controls']) {
          diseaseKeys.includes(remarkList.Remark) ? this.preExistingDisease.controls[totalDataIndex]['controls'][diseaseKeys].setValue('') : this.preExistingDisease.controls[totalDataIndex]['controls'][diseaseKeys].setValue(false);
        }
      }
    }
  }

  checkEMI() {
    if (this.healthFormData.quoteFormData.InstallmentApplicable == healthToggle.Yes) {
      this.errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.emiNotApplicable);
      this.popupService.openGenericPopup(this.errorPopupData);
      //re-direct to quote page left
      this.subscription.push(this.popupService.emiValue$.subscribe(data => {
        if (data) {
          this.setEmiValues();
          this.customFooterService.showPremiumDetails(false);
          this.popupService.emitEmiData(false);
          this.customStepperService.redirectToStep('f1', false);
        }
      }))
    }
  }

  setEmiValues() {
    if (this.healthFormData.quoteFormData.InstallmentApplicable == healthToggle.Yes) {
      // Reset emi values and store it
      this.healthFormData.quoteFormData.InstallmentApplicable = healthToggle.No;
      this.healthFormData.quoteFormData.InstallmentFrequency = '';
      this.healthDataStorageService.setHealthDataStorage(this.healthFormData);
    }
  }

  preExistingDiseaseControls(controlName, totalDataIndex, existingDiseaseIndex, existingDiseaseID, controlValue) {
    let diseaseControls = this.preExistingDisease.controls[totalDataIndex] as FormGroup;
    // If disease id is 40 or 41 and personal accident is selected then we need to show remarks section
    if (existingDiseaseID == insuredDiseaseID.Fourty || existingDiseaseID == insuredDiseaseID.FourtyOne) {
      let memberDisease = [];
      // Extracting based on member index disease controls
      memberDisease = this.totalData[totalDataIndex].existingDisease;
      if (this.healthFormData.quoteFormData.occupation.length > 0) {
        // If those two disease control is selected as yes
        if (controlValue) { // true ---> control  value
          this.healthFormData.quoteFormData.occupation.forEach((data, index) => {
            // This will display remark section only for those adults who have opted for personal accident cover in quote page
            let paKey = '';
            let occMmberSelected = this.totalData[totalDataIndex].memberTypeCount;
            // To retrieve occupation key
            let keys = Object.keys(data);
            // Here we are extracting the occupation key for member and checking it's value
            // ANd if that occupation has vaalue then we are assigning the key
            if (!this.utilityService.isEmptyOrNull(data[keys[0]])) {
              paKey = keys[0];
            }
            // Here we will have all object keys 

            if (!this.utilityService.isEmptyOrNull(paKey) && paKey.includes(occMmberSelected)) {

              memberDisease[existingDiseaseIndex].showRemarks = true;
              memberDisease[existingDiseaseIndex].remarkControlName = existingDiseaseID == insuredDiseaseID.Fourty ? remarkList.Remark1 + occMmberSelected : remarkList.Remark2 + occMmberSelected;
              memberDisease[existingDiseaseIndex].remarkIndex = existingDiseaseID == insuredDiseaseID.Fourty ? remarkList.Remark1 : remarkList.Remark2;

              diseaseControls.addControl(existingDiseaseID == insuredDiseaseID.Fourty ? remarkList.Remark1 + occMmberSelected : remarkList.Remark2 + occMmberSelected, this.fb.control('', this.utilityService.requiredFieldValidation()));
              //  Note: Here I'm adding extra two objects because in html my I'm looping my form array to print controls.And if there
              // is no blocks for remark controls html will start giving errors
              memberDisease.push({
                Name: existingDiseaseID == insuredDiseaseID.Fourty ? remarkList.Remark1 : remarkList.Remark2,
                type: 'text',
                Value: existingDiseaseID,
                showRemarks: false,
                hide: 'false',
                showRemarksType: 'text',
                Member: occMmberSelected,
                remarksApplicable: true,
                remarksLabel: 'Remarks',
              })
              this.totalData[totalDataIndex].existingDisease = memberDisease;
            }
          })
        }
        // If the disease control is selected as no . It will delete the remarks object only for those adults
        else if (!controlValue) {
          if (this.healthFormData.quoteFormData.occupation.length > 0) {
            this.healthFormData.quoteFormData.occupation.forEach((data, index) => {
              let memberCount = this.totalData[totalDataIndex].memberTypeCount;
              let paKey = '';
              // To retrieve occupation key
              // Here we are extracting the occupation key for member and checking it's value
              // ANd if that occupation has vaalue then we are assigning the key
              let keys = Object.keys(data);
              if (!this.utilityService.isEmptyOrNull(data[keys[0]])) {
                paKey = keys[0];
              }
              // Here we will have all object keys 

              if (!this.utilityService.isEmptyOrNull(paKey) && paKey.includes(memberCount)) {
                let index: number = memberDisease.findIndex(data => data.Value == existingDiseaseID && data.Name == (existingDiseaseID == insuredDiseaseID.Fourty ? remarkList.Remark1 : remarkList.Remark2));
                memberDisease.splice(index, 1);
                diseaseControls.removeControl(existingDiseaseID == insuredDiseaseID.Fourty ? remarkList.Remark1 + memberCount : remarkList.Remark2 + memberCount)
              }
              memberDisease[existingDiseaseIndex].showRemarks = false;
              this.totalData[totalDataIndex].existingDisease = memberDisease;
            })
          }
        }
      }
      else {
        this.totalData[totalDataIndex].existingDisease[existingDiseaseIndex].showRemarks = false;
      }
    }
  }

  // This method is created to find controls and pass a comparison value so this method can return the index of that control
  findControlsByIndex(value: string): number {
    let index: number = this.insuredControls.findIndex(data => data.controlName == value);
    return index;
  }

  onSubmit(event: EmitButtonData) {
    this.insuredDetailsForm.markAllAsTouched();
    this.isSubmit = true;
    let adultCount = 0;
    // this.checkPreExistingValidation();

    if (!this.insuredDetailsForm.valid) {
      this.showKYCBlock = false;
      let memberDetails = this.insuredDetailsForm.controls['memberDetails']['controls']
      let values = []; let messageArray = [];
      for (let i = 0; i < memberDetails.length; i++) {
        let memberDetailsStatus = this.insuredDetailsForm.controls['memberDetails']['controls'][i].valid;
        let internalKeys = Object.keys(this.insuredDetailsForm.controls['memberDetails']['controls'][i].value);
        let type = internalKeys[0].includes('Adult') ? 'Adult' : 'Child';
        if (type == 'Adult') {
          adultCount = adultCount + 1;
        }
        let typeLen = memberDetails.length > 2 ? ((type == 'Adult') ? i + 1 : (adultCount == 1) ? i : i - 1) : ((type == 'Adult') ? i + 1 : (adultCount == 0) ? i + 1 : i);
        if (memberDetailsStatus) {
          if (!this.totalData[i].existingDiseaseHide) {
            for (let prop in this.preExistingDisease.value[i]) {
              if (this.preExistingDisease.value[i][prop]) {
                values.push(this.preExistingDisease.value[i][prop])
              }
            }
            if (values.length == 0) {
              messageArray.push(this.totalData[i].memberTypeCount)
            }
          }
          if (messageArray.length > 0) {
            let popupData: PopupModal = {
              popupType: popupType.confirmation,
              imgName: '',
              header: popupHeader.pedNotSelected + ` ${messageArray.join(', ')}`,
              description: popupDescriptionMsg.pedDescription,
              buttonLabel: '',
              buttonAction: ''
            }
            this.popupService.openGenericPopup(popupData);
            this.preExistingError = true;
            this.pedPopupValidation();
            break;
          }
          else {
            this.preExistingError = false;
          }
        } else {
          let msg = 'Please fill details for ' + type + ' ' + typeLen;
          this.snackBar.open(
            msg, 'Ok', {
            duration: 2000,
            verticalPosition: 'bottom',
            horizontalPosition: 'right'
          });
          return;
        }
      }
    }

    else if (this.insuredDetailsForm.valid) {
      this.checkPreExistingValidation();
      if (!this.preExistingError) {
        this.isSubmit = false;

        // There could be a possiblity user has entered space in the text field.
        // Always trim input fields
        this.memberDetails.getRawValue().forEach((data) => {
          for (let objectKey in data) {
            if (objectKey.includes(insuredControls.fullName)) {
              data[objectKey] = (data[objectKey]).trim();
            }
          }
        })
        this.preExistingDisease.value.forEach((data) => {
          for (let objectKey in data) {
            if (objectKey.includes(remarkList.Remark)) {
              data[objectKey] = (data[objectKey]).trim();
            }
          }
        })
        this.healthFormData = this.healthDataStorageService.getHealthDataStorage();
        this.healthFormData.insuredFormData.insuredMemberDetails = this.memberDetails.getRawValue();
        this.healthFormData.insuredFormData.totalMemberDetails = this.memberDetails.getRawValue();
        this.memberDetails.controls.forEach((data, index) => {
          //  Below logic is written for title if title is disabled then value is not getting patched
          for (let key in data['controls']) {
            let memberDetails = this.memberDetails.controls[index] as FormGroup;
            if (key.includes(insuredControls.title) || key.includes(insuredControls.fullName)) {
              this.healthFormData.insuredFormData.insuredMemberDetails[index][key] = memberDetails.controls[key].getRawValue();
            }
          }
        })
        this.healthFormData.insuredFormData.preExistingDisease = this.preExistingDisease.value;
        this.healthFormData.insuredFormData.existingDisease = this.existingDisease;
        this.healthFormData.insuredDataSet = true;
        this.patchingDone = true;
        this.healthDataStorageService.setHealthDataStorage(this.healthFormData);
        // Uncomment below line if you need entire insured details controls
        //  this.healthFormData['totalMemberDetails'] =  this.totalData;
        this.showKYCBlock = true;
        this.isSubmit = false;

        this.dataSetSuccessfully = true;
        this.customStepperService.emitStepData({ status: true, buttonId: 'f3' });
      }
    }
  }

  checkMemberValidation() {
    for (const key of Object.keys(this.insuredDetailsForm.controls)) {
      let a = this.insuredDetailsForm.controls[key]['controls'];
      for (let i = 0; i < a.length; i++) {
        let status = this.insuredDetailsForm.controls[key]['controls'][i].valid;
        let internalKeys = Object.keys(this.insuredDetailsForm.controls[key]['controls'][i].value);
        let type = internalKeys[0].includes('Adult') ? 'Adult' : 'Child';
        let typeRes = (type == 'Adult') ? i + 1 : i - 1;
        let typeLen = a.length > 2 ? typeRes : i + 1;
        if (!status) {
          let msg = 'Please fill details for ' + type + ' ' + typeLen;
          this.snackBar.open(
            msg, 'Ok', {
            duration: 3000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center'
          });
          return;
        }
      }
    }
  }

  checkPreExistingValidation() {
    let messageArray = [];
    this.totalData.forEach((totaldata, index) => {
      let values = [];
      if (!totaldata.existingDiseaseHide) {
        for (let prop in this.preExistingDisease.value[index]) {
          if (this.preExistingDisease.value[index][prop]) {
            values.push(this.preExistingDisease.value[index][prop])
          }
        }
        if (values.length == 0) {
          messageArray.push(totaldata.memberTypeCount)
        }
      }
    })

    // This is temproary we need to add alert box to show atleast one pre existing disease is needed
    if (messageArray.length > 0) {
      let popupData: PopupModal = {
        popupType: popupType.confirmation,
        imgName: '',
        header: popupHeader.pedNotSelected + ` ${messageArray.join(', ')}`,
        description: popupDescriptionMsg.pedDescription,
        buttonLabel: '',
        buttonAction: ''
      }
      this.popupService.openGenericPopup(popupData);
      this.preExistingError = true;
      this.pedPopupValidation();
    }
    else {
      this.preExistingError = false;
    }
  }

  pedPopupValidation() {
    // Checking for PED popup
    this.popupService.pedNotRequired$.subscribe(status => {
      if (status) {
        // If user does not want any ped make all ped as no
        this.totalData.forEach((totaldata, index) => {
          let pedPresentForMember: boolean = false;
          if (!totaldata.existingDiseaseHide) {
            for (let prop in this.preExistingDisease.value[index]) {
              if (this.preExistingDisease.value[index][prop]) {
                pedPresentForMember = true;
              }
            }
            // No ped is selected for that member
            if (!pedPresentForMember) {
              // We need to hide existing disease and mark the value as no
              totaldata.existingDiseaseHide = true;
              this.memberDetails.at(index).get(insuredControls.preExistinDisease + totaldata.memberTypeCount).setValue(healthToggle.No);
            }
          }
        })
        // In any case it will be false
        this.preExistingError = false;
      }
      // If he clicks cancel that means he wants to select
      else if (!status) {
        this.preExistingError = true;
      }
    })
  }
  // Uncomment below code if logic needs to be altered
  // @HostListener('window:keydown', ['$event'])
  // onKeyPress(event: KeyboardEvent) {
  //   // Below line gets the active element which is focused
  //   if (event.key === 'Tab') {
  //     const focusedElement = this.el.nativeElement.ownerDocument.activeElement as HTMLElement;
  //     let id = focusedElement.id;
  //     if (id.includes('i') || id.includes('s') || id.includes('a') || id.includes('c') || id.includes('p') || id.includes('n') || id.includes('k')) {
  //       let customId = this.utilityService.nextElementFocus(focusedElement.id);
  //       const nextElementToFocus = document.getElementById(customId);
  //       if (nextElementToFocus) {
  //         nextElementToFocus.focus();
  //       }
  //     }
  //   }
  // }

  @HostListener('window:keydown', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    const focusedElement = this.el.nativeElement.ownerDocument.activeElement as HTMLElement;
    const focusedElementId = focusedElement.id;
    const remainingString = focusedElementId.replace('fullName', "");
    const newString = "dob" + remainingString;
    if (focusedElementId.includes('fullName') && event.key === 'Tab') {
      const nextElement = this.el.nativeElement.ownerDocument.querySelector(`#${newString}`) as HTMLInputElement;
      if (nextElement) {
        nextElement.focus();
      }
    }
  }

  removeKeyListener() {
    if (this.keyDownListener) {
      window.removeEventListener('keydown', this.keyDownListener);
      this.keyDownListener = null;
    }
  }

  onRefresh(status) {
    if (status) {
      this.storageService.getAESDecryptedData('IsOrionJourney') == 'true' ? this.fetchQuestionnaire() : this.fetchHealthAilmentList();
    }
  }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  fetchCompanyDetails() {
    this.multipleServiceError = false;
    this.subscription.push(this.requestDataFromMultipleSources().subscribe({
      next: (responseList: [InsuredCompanyResponsePayload, PortabilityReasonsResponsePayload]) => {
        this.handleMultipleResponse(responseList);
        this.multipleServiceError = false;
      },
      error: error => {
        this.customStepperService.emitStepData({ status: false, buttonId: 'f2' });
        // this.showContent = false;
        this.multipleServiceError = true;
      }
    })
    )
  }

  requestDataFromMultipleSources(): Observable<[InsuredCompanyResponsePayload, PortabilityReasonsResponsePayload]> {
    return this.healthService.getCompanyName().pipe(
      concatMap(companyResponse => {
        return forkJoin([
          of(companyResponse),
          this.healthService.getReasonsOfPortabilityDetails()
        ]);
      })
    );
  }

  handleMultipleResponse(responseList: [InsuredCompanyResponsePayload, PortabilityReasonsResponsePayload]) {
    this.multipleServiceError = false;

    if (responseList[0].Data.length > 0 && responseList[0].StatusCode == Constants.statusCode_success) {
      this.companyNames = responseList[0].Data;
      this.companyNames.map((ele, ind) => {
        let obj = {};
        obj['label'] = ele.CompanyName;
        obj['value'] = ele.CompanyCode;
        this.companyListHB.push(obj);
      })

      this.insuredControls.map((element, ind) => {
        if (element['controlName'] == "insuranceCompanyNameHB") {
          element['value'] = this.companyListHB;
        }
      })
    }
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.removeKeyListener();
  }
}