<div [ngClass]="{'container panaMainContainer': isCreateCustomer}">
    <div class="myTransactionTitle" *ngIf="isCreateCustomer">
        <div class="bbrheading">
            <span><img src='../../../assets/images/Header Icon/Insured.svg' alt=""></span>
            <span>
                <h3>Create Customer</h3>
            </span>
        </div>
    </div>

    <mat-accordion class="example-headers-align" multi
        *ngIf="showFormContent && initialServiceLoaded && !multipleServiceError">
        <mat-expansion-panel class="customExpansion kycExpansion mb-2 marginBox" [expanded]="true" disabled="true"
            hideToggle>
            <mat-expansion-panel-header *ngIf="!isCreateCustomer">
                <mat-panel-title>
                    <h3>Applicant Details</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <!-- Customer Type -->
            <div class="fieldsdiv" *ngIf="!isCreateCustomer">
                <div class="row customerType">
                    <div class="col-sm-3">
                        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="custom-toggle-group"
                            style="min-width: 148px;">
                            <mat-button-toggle class="custom_togglebtn" value="bold"
                                [checked]="customerType == 'New' ? true : false"
                                (change)="changeInCustomerType('New')">New</mat-button-toggle>
                            <mat-button-toggle class="custom_togglebtn" value="italic"
                                [checked]="customerType != 'New' ? true : false"
                                (change)="changeInCustomerType('Existing')">Existing</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
            </div>

            <div class="fieldsdiv" [ngStyle]="{'margin-top': isCreateCustomer ? '25px' : '14px'}">
                <div class="row" *ngIf="showEKYC && !showSearchPopup">
                    <div class="col-sm-12 updateKYC">
                        <!-- If kyc is not completed -->
                        <div class="verifyDiv verifyNew" *ngIf="!isKYCDoneSuccessfully">
                            <span><img src="../../../assets/images/verifyIndicator.gif" alt=""></span>
                            <span> Please complete your KYC verification </span>
                            <button class="secondarybtn_2 secondarybtn_update ml-2" (click)="callKYC()"
                                [innerText]="isCreateCustomer ? 'Onboard Customer' : 'Update'"
                                [ngStyle]="isCreateCustomer ? {'padding': '10px'} : {'padding': '10px'}">
                            </button>
                        </div>

                        <!-- If kyc is not completed for pilot user-->
                        <div class="verifyDiv bgVerificationError" *ngIf="!isKYCDoneSuccessfullyForPilotuser && !isKYCDoneSuccessfully" style="margin-bottom: 3;">
                            <span><img src="../../../assets/images/verifyIndicator.gif" alt=""></span>
                            <span>Kindly complete your KYC verification using a govt. approved ID proof.</span>
                        </div>

                        <!-- If kyc is completed -->
                        <div class="verifyDiv bgVerify" *ngIf="isKYCDoneSuccessfully" style="margin-bottom: 3;">
                            <span><img src="../../../assets/images/complete.gif" alt=""></span>
                            <span>KYC Verification successfully completed</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Search Customer Popup -->
            <form [formGroup]="searchCustomerForm" *ngIf="showSearchPopup">
                <div class="fieldsdiv">
                    <div class="row pt-3">
                        <div [ngClass]="controlsData['controlName'] == 'or' ? 'col-sm-12 orDiv' : 'col-sm-4 form-group'"
                            *ngFor=" let controlsData of searchForCustomerControls">
                            <h2 class="orHeading" *ngIf="controlsData['controlName'] == 'or'">OR</h2>

                            <!-- If type is input field -->
                            <div class="form-group" *ngIf="controlsData['type'] == 'input' && !controlsData['hide']">
                                <mat-label class="input-group__label"
                                    for="myInput">{{controlsData['label']}}</mat-label>
                                <input type="{{controlsData['inputType']}}" [maxlength]="controlsData['maxLength']"
                                    [readonly]="controlsData['disabled']" [max]="controlsData['maxNumber']"
                                    [id]="controlsData['controlName']" [min]="controlsData['minNumber']"
                                    formControlName="{{controlsData['controlName']}}"
                                    placeholder="{{controlsData['placeholder']}}"
                                    (input)="validationOfInputField($event, controlsData.controlName, controlsData['onlyAlphabets'], controlsData['onlyNumbers'])"
                                    (paste)="utilityService.onPaste($event,controlsData['onlyAlphabets'],controlsData['onlyNumbers'])"
                                    class="input-group__input"  autocomplete="off"/>
                                <mat-error *ngIf="hasError(controlsData['controlName'], 'required','searchCustomer')">
                                    {{controlsData['label']}} is required
                                </mat-error>

                                <mat-error *ngIf="hasError(controlsData['controlName'], 'minlength','searchCustomer')">
                                    {{controlsData['label']}} is invalid minlength
                                </mat-error>

                                <mat-error *ngIf="hasError(controlsData['controlName'], 'maxlength','searchCustomer')">
                                    {{controlsData['label']}} is invalid maxlength
                                </mat-error>

                                <mat-error *ngIf="hasError(controlsData['controlName'], 'pattern','searchCustomer')">
                                    {{controlsData['label']}} is invalid pattern
                                </mat-error>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Search Button Click -->
                <div class="fieldsdiv">
                    <div class="row">
                        <div class="col-md-12 form-group">
                            <button mat-raised-button class="primary-button" (click)="searchButtonClick('proceed')"
                                style="min-width:140px; background: #EC6625 !important;">Search
                                customer</button>
                        </div>
                    </div>
                </div>

                <mat-divider *ngIf="displayCustomerList"></mat-divider>

                <!-- List of customers table -->
                <div *ngIf="displayCustomerList" class="mt-3" style="max-width: 1150px; margin-left: 12px;">
                    <p style="font-size: 13px;">
                        {{numberOfRecords}} record(s) matching your search criteria were found.
                    </p>
                    <div id="tableDiv" class="kyccustomerTable"
                        *ngIf="customerListData.length >= 1;  else noDataFound ">
                        <table class="kycTable">
                            <caption class="hidden-caption">Details</caption>
                            <td class="hidden-caption">KYC Details</td>
                            <thead>
                                <tr>
                                    <th>Serial No.</th>
                                    <th>Customer Name</th>
                                    <th>Email Id</th>
                                    <th>Mobile No.</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of customerListData" (click)="getCustomerRecord(item)">
                                    <td>{{ item.id }}</td>
                                    <td>{{ item.label }}</td>
                                    <td>{{ item.email }}</td>
                                    <td>{{ item.mobileNumber }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <ng-template #noDataFound>
                        <h1>No Data Found!</h1>
                    </ng-template>

                    <!-- Error msg if no customer gets selected -->
                    <p *ngIf="customerSelected.length == 0" style="font-size: 11px; color: #B02A30" class="mt-1">
                        Note: Atleast one customer needs to be selected for KYC verification.
                    </p>
                </div>

            </form>
        </mat-expansion-panel>

        <!-- Dynamic construction of kyc form group -->
        <form [formGroup]="kycForm" *ngIf="showFormContent">
            <ng-template [ngIf]="ekycMandatory">
                <div *ngFor=" let kycData of kycControls; let tabIndex = index;">
                    <!-- Dynamic panel construction starts here -->
                    <mat-expansion-panel class="customExpansion mb-2" [expanded]="true" disabled="true"
                        *ngIf="!kycData['hide']">
                        <div *ngIf="!kycData['hide']" [id]="kycData['formGroupLabel']"
                            [formGroupName]="kycData['formGroupLabel']">
                            <mat-expansion-panel-header class="px-0">
                                <mat-panel-title>
                                    <h3>
                                        {{kycData['tabLabel']}}
                                    </h3>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <!-- Panel Controls -->
                            <div *ngIf="kycData['showControls']">
                                <div class="fieldsdiv">
                                    <div class="row">
                                        <div class="form-group" [ngClass]="{
                                            'col-sm-3': !controlsData.hide && controlsData.controlName != 'correspondenceSameAddress' && controlsData.controlName != 'insuredHIP' && controlsData.controlName != 'taxExemptionHB',
                                            'col-sm-12 width100': controlsData.controlName == 'correspondenceSameAddress',
                                            'col-sm-4 width100': controlsData.controlName == 'insuredHIP',
                                            'col-sm-5 width100': controlsData.controlName == 'taxExemptionHB',
                                            'd-none': controlsData.hide
                                        }"
                                            *ngFor="let controlsData of kycData['controls']; let controlsIndex = index;">

                                            <div *ngIf="controlsData.type == 'checkbox' && !controlsData.hide"
                                                class="col-sm-12" style="top:17px;padding-left: 0px;">
                                                <mat-checkbox class="cstmCheckbox" style="color: #282828;"
                                                    (change)="changeInRadioButton(controlsData.controlName,tabIndex,controlsIndex,kycData['formGroupLabel'])"
                                                    formControlName="{{controlsData.controlName}}">
                                                    {{controlsData.label}}
                                                </mat-checkbox>
                                            </div>
                                            <!-- If type is radio -->
                                            <label *ngIf="controlsData.type == 'radio' && !controlsData.hide"
                                                class="singlelabel width100 mb-2">
                                                {{controlsData.label}}</label>
                                            <div class=" group_btn">
                                                <mat-button-toggle-group formControlName="{{controlsData.controlName}}"
                                                    *ngIf="controlsData.type == 'radio' && !controlsData.hide"
                                                    name="fontStyle" aria-label="Font Style"
                                                    class="custom-toggle-group">
                                                    <mat-button-toggle [value]="radioData.value"
                                                        [disabled]="radioData.disabled"
                                                        *ngFor="let radioData of controlsData.value; let j = index"
                                                        (change)="changeInRadioButton(controlsData.controlName,tabIndex,controlsIndex,kycData['formGroupLabel'])"
                                                        class="custom_togglebtn">
                                                        {{ radioData.label }}
                                                    </mat-button-toggle>
                                                </mat-button-toggle-group>
                                            </div>

                                            <mat-error
                                                *ngIf="hasError(controlsData.controlName, 'required',kycData['formGroupLabel']) && (controlsData.type == 'radio' && !controlsData.hide) && isSubmit">
                                                {{controlsData.label}} is required
                                            </mat-error>
                                            <!-- If type is input field -->
                                            <mat-label class="input-group__label"
                                                *ngIf="controlsData.type == 'input' && !controlsData.hide">{{controlsData.label}}</mat-label>
                                            <input *ngIf="controlsData.type == 'input' && !controlsData.hide"
                                                type="{{controlsData.inputType}}" matInput class="input-group__input"  [maxlength]="controlsData['maxLength']" [minlength]="controlsData['maxLength']"
                                                [readonly]="controlsData.disabled" [max]="controlsData.maxNumber" [id]="controlsData.controlName" 
                                                [min]="controlsData.minNumber"
                                                formControlName="{{controlsData.controlName}}"
                                                placeholder="{{controlsData.placeholder}}"
                                                (input)="changeInInputField($event,controlsData.controlName,kycData, controlsData.onlyAlphabets, controlsData.onlyNumbers)"
                                                (paste)="utilityService.onPaste($event,controlsData['onlyAlphabets'],controlsData['onlyNumbers'])" autocomplete="off">

                                            <mat-error
                                                *ngIf="hasError(controlsData.controlName, 'required',kycData['formGroupLabel']) && (controlsData.type == 'input' && !controlsData.hide) && isSubmit">
                                                {{controlsData.label}} is required
                                            </mat-error>

                                            <mat-error
                                                *ngIf="(hasError(controlsData.controlName, 'minlength',kycData['formGroupLabel']) || hasError(controlsData.controlName, 'maxlength',kycData['formGroupLabel'])) && (controlsData.type == 'input' && !controlsData.hide  && isSubmit)">
                                                <!-- {{controlsData.label}} is invalid minlength -->
                                                Atleast {{controlsData.maxLength}} digit {{controlsData.label}} is required
                                            </mat-error>
<!-- 
                                            <mat-error
                                                *ngIf="hasError(controlsData.controlName, 'maxlength',kycData['formGroupLabel']) && (controlsData.type == 'input' && !controlsData.hide) && isSubmit">
                                                {{controlsData.label}} is invalid maxlength
                                            </mat-error> -->

                                            <mat-error
                                                *ngIf="((!hasError(controlsData.controlName, 'maxlength',kycData['formGroupLabel'])  && !hasError(controlsData.controlName, 'minlength',kycData['formGroupLabel'])) && hasError(controlsData.controlName, 'pattern',kycData['formGroupLabel'])) && (controlsData.type == 'input' && !controlsData.hide) && isSubmit">
                                                {{controlsData.label}} is invalid pattern
                                            </mat-error>

                                            <!-- Pincode error -->
                                            <mat-error class="error-margin"
                                                *ngIf="invalidPinCodeCorrespondence!== '' && controlsData.controlName == 'correspondencePincode' && (controlsData.type == 'input' && !controlsData.hide) && isSubmit">
                                                {{invalidPinCodeCorrespondence}}
                                            </mat-error>

                                            <mat-error class="error-margin"
                                                *ngIf="invalidPinCodePermanent!== '' && controlsData.controlName == 'permanentPincode' &&(controlsData.type == 'input' && !controlsData.hide) && isSubmit">
                                                {{invalidPinCodePermanent}}
                                            </mat-error>

                                            <!-- If type is dropdown -->
                                            <mat-label class="input-group__label" for="myInput"
                                                *ngIf="controlsData.type == 'dropdown' && !controlsData.hide">{{controlsData.label}}</mat-label>
                                            <mat-select class="input-group__input cstm_select" required
                                                (click)="handleMatSelectClick()"
                                                (blur)="removeMatSelectScrollListener()"
                                                formControlName="{{controlsData.controlName}}"
                                                [disabled]="controlsData.disabled"
                                                [placeholder]="controlsData.placeholder" [id]="controlsData.controlName"
                                                (selectionChange)="changeOfDropdown($event.value,controlsData.controlName)"
                                                *ngIf="controlsData.type == 'dropdown' && !controlsData.hide">
                                                <ng-container
                                                    *ngFor="let dropDownData of controlsData.value; let last = last">
                                                    <mat-option
                                                        value="{{dropDownData.label}}">{{utilityService.capitalize(dropDownData.label)}}</mat-option>
                                                    <mat-divider *ngIf="!last"></mat-divider>
                                                </ng-container>
                                            </mat-select>

                                            <!-- Error for dropdown -->
                                            <mat-error
                                                *ngIf="hasError(controlsData.controlName, 'required',kycData['formGroupLabel']) && (controlsData.type == 'dropdown' && !controlsData.hide) && isSubmit">
                                                {{controlsData.label}} is required
                                            </mat-error>

                                            <!-- If type is datepicker -->
                                            <mat-label *ngIf="controlsData.type == 'datepicker' && !controlsData.hide"
                                                class="input-group__label">{{controlsData.label}}</mat-label>
                                            <input class="input-group__input dob" placeholder="DD/MM/YYYY"
                                                *ngIf="controlsData.type == 'datepicker' && !controlsData.hide" matInput
                                                [id]="controlsData.controlName" [matDatepicker]="picker1"
                                                [placeholder]="controlsData.placeholder"
                                                formControlName="{{controlsData.controlName}}"
                                                [min]="controlsData.minDate" [max]="controlsData.maxDate"
                                                [disabled]="controlsData.disabled" (click)="picker1.open()" readonly
                                                (dateInput)="changeInDatePicker($event.value,kycData['formGroupLabel'],controlsData.controlName)">

                                            <mat-datepicker-toggle matIconSuffix [for]="picker1"
                                                *ngIf="controlsData.type == 'datepicker' && !controlsData.hide">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker1 touchUi></mat-datepicker>

                                            <mat-error
                                                *ngIf="hasError(controlsData.controlName, 'required',kycData['formGroupLabel']) && (controlsData.type == 'datepicker' && !controlsData.hide) && isSubmit">
                                                {{controlsData.label}} is required
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </mat-expansion-panel>
                </div>
            </ng-template>
        </form>

    </mat-accordion>
</div>

<!-- Error State -->
<app-error-screen *ngIf="multipleServiceError" (refreshService)="onRefresh($event)">
</app-error-screen>
<!-- Below is save button condition -->
<!-- ekycMandatory && healthFormData.quoteFormData.subProductType != 34 ? false: true -->
<!-- {'label': 'Save', 'type': 'secondary', 'source': 'kyc', 'id': 'f4','disabled': false , 'hide': true }, -->
<app-custom-footer *ngIf="showFormContent && initialServiceLoaded && !multipleServiceError" [normalButton]="true"
    [buttonData]="[
        {'label': 'Submit', 'type': 'primary', 'source': 'kyc', 'id': 'f4','disabled': false , 'hide': ekycMandatory && !isCreateCustomer ? false: true },
        {'label': 'Save', 'type': 'primary', 'source': 'kyc', 'id': 'f4','disabled': false , 'hide': ekycMandatory && isCreateCustomer ? false: true }
    ]" (buttonClicked)="kycButtonClicked($event)">
</app-custom-footer>

<!-- Kyc Modal -->
<!-- uncomment below line only when need to switch flutter -->
<div class="modal fade kycPopupScreen" id="externalUrlModal" tabindex="-1" role="dialog"
    aria-labelledby="externalUrlModalLabel" aria-hidden="true">
    <div class="modal-dialog my-0" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <iframe id="externalUrlFrame" width="100%" height="600px" title=""></iframe>
            </div>
        </div>
    </div>
</div>