import { Component, Input, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Subscription } from 'rxjs';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { UpdatePremiumData } from 'src/app/shared/interface/custom-footer';
import { StepData, subHeadingDetails } from 'src/app/shared/interface/custom-stepper';
import { ConvertSavedQuotesResponsePayload } from 'src/app/shared/interface/savedQuotes';
import { CustomFooterService } from 'src/app/shared/services/custom-footer.service';
import { CustomStepperService } from 'src/app/shared/services/custom-stepper.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { WindowResizeService } from 'src/app/shared/services/window-resize.service';

@Component({
  selector: 'app-custom-stepper',
  templateUrl: './custom-stepper.component.html',
  styleUrls: ['./custom-stepper.component.scss']
})
export class CustomStepperComponent {
  mobileStepper: boolean = false;
  selectedIndex = 0;
  subscription: Subscription[] = [];
  activeStepIndex = 0;
  @ViewChild('stepper') stepper: MatStepper;

  @Input() steps: StepData[] = [];
  @Input() subheadingDetails: subHeadingDetails[] = [];
  @Input() linear: boolean;
  @Input() activeStep: boolean;
  quoteDetails: ConvertSavedQuotesResponsePayload;

  constructor(private customStepper: CustomStepperService,
    private windowResizeService: WindowResizeService, private customFooterService: CustomFooterService,
    private storageService: StorageService) { }

  ngOnInit() {
    this.eventsEmitted();
    this.checkScreenWidth();
  }

  eventsEmitted() {
    // For next step
    this.customStepper.nextStepRequired$.subscribe(nextStep => {
      if (nextStep) {
        this.nextStep();
      }
    })

    // For previous step
    this.customStepper.previousStepRequired$.subscribe(previousStep => {
      if (previousStep) {
        this.previousStep();
      }
    })

    // To go to particular step
    this.customStepper.goToStep$.subscribe(stepIndex => {
      if (stepIndex != -1) {
        this.activeStepIndex = stepIndex;
      }
    })
  }

  private checkScreenWidth() {
    this.windowResizeService.getResizeObservable().subscribe(data => {
      this.mobileStepper = data.width < 768;
    })
  }

  summaryFlagReset(currentIndex, activeStep) {
    if (currentIndex != activeStep) {
      let premiumData: UpdatePremiumData = {
        update: false,
        data: '',
        value: 'quote'
      };
      premiumData.update = false;
      premiumData.data = '';
      this.customFooterService.updatePremiumDetails(premiumData);
    }
  }

  selectionChange(event) {
    this.quoteDetails = this.storageService.getAESDecryptedData(userEnums.ConverToProposal);
    this.customStepper.currentIndex(event.selectedIndex);
    if(!this.quoteDetails) {
      this.summaryFlagReset(event.selectedIndex, this.activeStepIndex);
    }
    let selectedIndex = event.selectedIndex;
    // In case of insured when user comes backward we need to initialize the component again
    // In case of portability the length will be 5
    if (selectedIndex == 0 || ((selectedIndex == 1 && this.steps.length == 4) || (selectedIndex == 2 && this.steps.length == 5))) {
      this.reinitializingComponent(selectedIndex);
    } 
    else if(this.quoteDetails && (selectedIndex == 2 && this.steps.length == 4) || (selectedIndex == 3 && this.steps.length == 4) || (selectedIndex == 3 && this.steps.length == 5) || (selectedIndex == 4 && this.steps.length == 5)) {
      // reinitializing kyc and summary component
      this.reinitializingComponent(selectedIndex);
    }
    else {
      this.activeStepIndex = selectedIndex;
    }
  }

  reinitializingComponent(index) {
    // Just reinitializing the component by toggling
    if (this.steps[index].completed) {
      this.steps[index].isActive = false;
      setTimeout(() => {
        this.steps[index].isActive = true;
        this.activeStepIndex = index;
      }, 0);
    }
    // If the insured is not yet completed. Then just simply let it pass
    else if (!this.steps[index].completed) {
      this.steps[index].isActive = true;
      this.activeStepIndex = index;
    }
  }

  nextStep() {
    if (this.stepper) {
      this.activeStepIndex = this.activeStepIndex + 1;
    }
  }

  previousStep() {
    if (this.stepper) {
      this.activeStepIndex = this.activeStepIndex - 1;
    }
  }

  fetchLastActiveElement(): number {
    let lastActiveElement: number = -1;
    for (let i = this.steps.length - 1; i >= 0; i--) {
      if (this.steps[i].isActive) {
        lastActiveElement = i;
        break; // Stop the loop when the last active element is found
      }
    }
    return lastActiveElement;
  }

  resetStep() {
    if (this.stepper) {
      this.stepper.reset();
    }
  }

  getSelectedIndex(): number {
    if (this.stepper) {
      return this.stepper.selectedIndex;
    }
    return -1;
  }

  ngOnDestroy() {
    this.subscription.forEach(subs => {
      subs.unsubscribe();
    })
  }
}




