import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { EmitStepData, StepData } from '../interface/custom-stepper';
import { CustomFooterService } from './custom-footer.service';
import { userEnums } from '../enums/userEnums';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class CustomStepperService {

  // Hap data emission
  private stepData = new Subject<Object>();
  stepData$ = this.stepData.asObservable();

  // Fetch step data from storage
  private storageStepData = new BehaviorSubject<boolean>(false);
  storageStepData$ = this.storageStepData.asObservable();

  // Porability data present
  private portabilityDataPresent = new BehaviorSubject<boolean>(false);
  portabilityDataPresent$ = this.portabilityDataPresent.asObservable();

  // Next Step
  private nextStepRequired = new Subject<boolean>();
  nextStepRequired$ = this.nextStepRequired.asObservable();

  // Previous Step
  private previousStepRequired = new Subject<boolean>();
  previousStepRequired$ = this.previousStepRequired.asObservable();

  // Reset Step
  private resetStepperRequired = new Subject<boolean>();
  resetStepperRequired$ = this.resetStepperRequired.asObservable();

  // go to particular step
  private goToStep = new BehaviorSubject<number>(-1);
  goToStep$ = this.goToStep.asObservable();

  // go to particular step for sub heading
  private stepIndex = new BehaviorSubject<number>(-1);
  stepIndex$ = this.stepIndex.asObservable();

  // Save quote changes
  private saveQuoteChanges = new Subject<boolean>();
  saveQuoteChanges$ = this.resetStepperRequired.asObservable();

  constructor(private customFooterService: CustomFooterService,
    private storageService: StorageService) { }

  emitStepData(data: EmitStepData) {
    this.stepData.next(data);
  }

  portabilityPresent(data: boolean) {
    this.portabilityDataPresent.next(data);
  }

  nextStep(data: boolean) {
    this.nextStepRequired.next(data);
  }

  previousStep(data: boolean) {
    this.previousStepRequired.next(data);
  }

  resetStepper(data: boolean) {
    this.resetStepperRequired.next(data);
  }

  saveQuoteDataChanges(data: boolean) {
    this.saveQuoteChanges.next(data);
  }

  goToParticularStep(data: number) {
    this.goToStep.next(data);
  }

  //sub heading according to step
  currentIndex(index) {
    this.stepIndex.next(index);
  }

  // Enable disable steps
  activatedComponentCheck(componentStatusData: Object, steps: StepData[]): StepData[] {
    let selectedComponentId = steps.findIndex((data) => data.id == componentStatusData['buttonId']);
    steps[selectedComponentId].completed = componentStatusData['status'];
    // Only if we get id of the component and there is succesful data set. Then only move to next step
    if (selectedComponentId >= 0) {
      if (componentStatusData['status']) {
        setTimeout(() => {
          this.nextStep(true);
          steps[selectedComponentId + 1].isActive = componentStatusData['status'] ? true : false;
        }, 0);
      }

      // If false is emitted on any step that means service error occured or any validation happened wrong
      // All other steps will be disabled
      // Hide premium 
      else if (!componentStatusData['status']) {
        steps.forEach((data, index) => {
          if (index > selectedComponentId) {
            data.isActive = false;
            data.completed = false;
          }
        })
        // Premium details gets hidden only on first step
        // Because first step is quote generation step
        if (selectedComponentId == 0) {
          this.customFooterService.showPremiumDetails(false);
        }
      }
    }
    // Set step details in session storage
    // As it will be useful when we are returieving the values on refresh
    this.storageService.setAESEncryptedData(userEnums.StepperDetails, steps);
    return steps;
  }

  resetStep(steps: StepData[]): StepData[] {
    // SO when this is called. Only first step should be active 
    // And all step should be complete
    steps.forEach((data, index) => {
      data.isActive = index == 0 ? true : false;
      data.completed = false;
    })
    return steps;
  }

  redirectToStep(id: string, portabilityDataPresent: boolean) {
    let redirectToStep: number = 0;
    switch (id) {
      case 'f1': {
        redirectToStep = 0;
        break
      }
      case 'f2': {
        redirectToStep = 1;
        break
      }
      case 'f3': {
        redirectToStep = portabilityDataPresent ? 2 : 1;
        break
      }
      case 'f4': {
        redirectToStep = portabilityDataPresent ? 3 : 2;
        break
      }
      case 'f5': {
        redirectToStep = portabilityDataPresent ? 4 : 3;
        break
      }
      default: {
        redirectToStep = 0;
        break;
      }
    }
    this.goToParticularStep(redirectToStep);
  }
}
