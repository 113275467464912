import { Injectable } from '@angular/core';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { popupType } from '../enums/popupEnums';
import { PopupService } from './popup.service';
import { declarationLinksEnum } from '../enums/routeEnums';
import { PaymentLinkDeclarationsComponent } from '../components/payment-link/payment-link-declarations/payment-link-declarations.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class DeclarationsService {

    constructor(private popupService: PopupService,
        private dialog: MatDialog) { }

    openDeclarationPopup(declarationType: string, Data?: any) {
        let popupData: PopupModal;
        switch (declarationType) {
            case 'Agent Declaration':
                popupData = {
                    popupType: popupType.declarations,
                    imgName: '',
                    header: `Agent Declaration`,
                    description: `Agent Declaration`,
                    data: {
                        agentName: Data.rnAgent
                    },
                    buttonLabel: '',
                    buttonAction: ''
                }
                this.popupService.openTravelCountryPopup(popupData);
                break;

            case 'agentConfidentialityReport':
                popupData = {
                    popupType: popupType.declarations,
                    imgName: '',
                    header: `Agent's Confidentiality Report`,
                    description: `Agent's Confidentiality Report`,
                    data: {
                        agentName: Data.rnAgent,
                        productType: Data.productType
                    },
                    buttonLabel: '',
                    buttonAction: ''
                }
                this.popupService.openTravelCountryPopup(popupData);
                break;

            case 'generalDeclaration':
                popupData = {
                    popupType: popupType.declarations,
                    imgName: '',
                    header: `Declaration`,
                    description: `Declaration`,
                    buttonLabel: '',
                    buttonAction: ''
                }
                this.popupService.openTravelCountryPopup(popupData);
                break;

            case 'travel':
                popupData = {
                    popupType: popupType.travel,
                    imgName: '',
                    header: ``,
                    description: ``,
                    buttonLabel: '',
                    buttonAction: ''
                }
                this.popupService.openTravelCountryPopup(popupData);
                break;

                case 'tncDeclaration':
                    popupData = {
                        popupType: popupType.declarations,
                        imgName: '',
                        header: `Terms & Conditions`,
                        description: `Terms & Conditions`,
                        buttonLabel: '',
                        buttonAction: ''
                    }
                    this.popupService.openTravelCountryPopup(popupData);
                    break;

            case 'privacyPolicy':
                this.dialog.open(PaymentLinkDeclarationsComponent, { panelClass: 'my-custom-popup', data: 'privacy', height: '450px', width: '800px', autoFocus: false });
                // window.open(declarationLinksEnum.privacyPolicy, '', 'width=600,height=400,left=200,top=200');
                break;

            default:
                break;
        }
    }
}
