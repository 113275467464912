import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-health-claim-status',
  templateUrl: './health-claim-status.component.html',
  styleUrls: ['./health-claim-status.component.scss']
})
export class HealthClaimStatusComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any, private dialog: MatDialog) { }

  close() {
    this.dialog.closeAll();
  }
}
