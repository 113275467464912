import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { userEnums } from '../enums/userEnums';
import { Subject } from 'rxjs';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root',
})
export class SideNavService {
  private hideSideNav = new Subject<boolean>();
  hideSideNav$ = this.hideSideNav.asObservable();

  constructor(private storageService: StorageService,private utilityService: UtilityService) {
  }

  setUserAuthenticatedStatus(userAuthenticated: string) {
    this.storageService.setAESEncryptedData(userEnums.SidenavStatus, userAuthenticated)
  }

  getUserAuthenticatedStatus(): string {
    return !this.utilityService.isEmptyOrNull(this.storageService.getAESDecryptedData(userEnums.SidenavStatus))? this.storageService.getAESDecryptedData(userEnums.SidenavStatus) : 'false';
  }

  hideSidenav(data: boolean) {
    this.hideSideNav.next(data);
  }

}
