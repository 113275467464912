import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwapComponent } from './swap.component';
import { SwapRoutingModule } from './swap-routing.module';
import { LayoutModule } from 'src/app/layout/layout.module';



@NgModule({
  declarations: [SwapComponent],
  imports: [
    CommonModule,
    SwapRoutingModule,
    LayoutModule
  ]
})
export class SwapModule { }
