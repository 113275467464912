
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GoldenShieldResponsePayload } from '../../interface/goldenShield';

@Injectable({
  providedIn: 'root'
})
export class GoldenShieldService_M {

  constructor(private http: HttpClient) {

  }
  readonly baseURI = `${environment.baseURL}`;
  readonly GSAdvantage = 'GoldenShield/HealthPlanGSMasterV1';
  readonly SaveEditQuoteV1 = 'transaction/GoldenShield/SaveEditQuoteV1';

  getHealthGSMasterData(data): Observable<GoldenShieldResponsePayload> {
    return this.http.post<GoldenShieldResponsePayload>(`${this.baseURI}${this.GSAdvantage}`, data);
  }

  saveEditQuoteData(data): Observable<any> {
    return this.http.post(`${this.baseURI}${this.SaveEditQuoteV1}`, data);
  }
}