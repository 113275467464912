let blue = {
  environment: 'blue',
  baseURL: 'https://panaapi.ilgicdash.com/apigateway/health/',
  existingUrl: 'http://ipartnerblue.prodicicilombard.com/mobileagentapi/ipartnermsite/webpage/#/',
  // kycUrl: 'https://premium.insurancearticlez.com/KYC/index.html',
  // kycUrl: 'https://iltakecare.icicilombard.com/ILTakeCare/KYC/index.html',
  kycUrl: 'https://panaapi.icicilombard.com/kyc/index.html',
  logoutRedirectionUrl: 'https://ipartner.icicilombard.com/WebPages/Login.aspx',
  nysaMotorLoginRedirection: 'https://nysa.icicilombard.com/#/login',
  nysaBancaLoginRedirection: '',
  ipartnerProIOS: 'https://apps.apple.com/in/app/ipartner-pro/id1589043830',
  ipartnerProAndroid: 'https://play.google.com/store/apps/details?id=com.icicilombard.ipartnerpro',
  ipartnerProWeb: 'https://ipartnerpro.icicilombard.com/Web/#/',
  encryptdecryptkey :'MAKV2SPBNI99212I',
  amlBaseURL : 'https://pana.icicilombard.com/'
};

// Existing prod:- https://ipartner.icicilombard.com/mobileagentapi/ipartnermsite/webpage/#/swap?
// Existing Blue:-http://ipartnerblue.prodicicilombard.com/mobileagentapi/ipartnermsite/webpage/#/swap?
export const environment = {
  production: false,
  uat: false,
  sanity: false,
  ipAddress: 'NYSAHEALTH',
  ...blue
};
