import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { UtilityService } from 'src/app/shared/services/utility.service';
@Component({
  selector: 'app-custom-card',
  templateUrl: './custom-card.component.html',
  styleUrls: ['./custom-card.component.scss']
})
export class CustomCardComponent {
  @Input() reportList: any = [];
  @Input() buttonData = [];
  @Input() filterString: string;
  @Input() displayedColumns = [];
  @Output() policyNo = new EventEmitter<any>();
  pageSizeOptions: number[] = [10, 25, 100];
  pageSize: number = this.pageSizeOptions[0];
  pageIndex: number = 0;
  pagedList: any = this.reportList;
  length: number = this.pagedList.length;
  selectedRows: any = [];

  constructor(
    public utilityService: UtilityService
  ) { }

  ngOnChanges() {
    this.pagedList = this.reportList;
    this.length = this.pagedList.length;
    this.pageIndex = 0;
  }

  onPageChange(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
  }

  emitData(data, identifier) {
    data = { data, identifier };
    this.policyNo.emit(data);
    if (identifier == 'Add To Pay') {
      this.pageSize = this.pageSizeOptions[2];
    }
  }

  banColumns(item: string): boolean {
    const colName = ['Action', 'Select']
    if (colName.includes(item)) {
      return false
    }
    return true;
  }

  evalConditionForMyPolicy(data, condition) {
    return eval(condition);
  }

  addSpace(inputString: string) {
    if (inputString == 'PendingForUnderWriter') {
      return 'Pending for Approval with UW1';
    }
    return inputString;
  }

  evalConditionForPreProposal(data) {
    const d1 = new Date(data.startDate);
    d1.setHours(0, 0, 0, 0);

    const d2 = new Date();
    d2.setHours(0, 0, 0, 0);

    if (data.StatusMessage == 'SUCCESS' && d1 >= d2) {
      return true;
    }
    else {
      return false;
    }
  }
}
