<div class="container custom-dialog">
    <div class="sticky-top custom-header pt-2 pb-2 row">
        <h5>Claim Status</h5>
        <div class="custom-close" (click)="close()">
            <img class="custom-cross" src="../../../../../../../assets/images/cross.png" alt="X">
        </div>
    </div>

    <div class="dialog-content">
        <div class="fieldsdiv">
            <p class="para-style">
                <b>Customer Details</b>
            </p>
            <div class="row customDiv planrowData" style="margin-left: 0px;">
                <div class="col-lg-4 col-sm-3 form-group">
                    <mat-label class="mtlabel" for="myInput">Insured Name</mat-label>
                    <p>{{ dialogData.Insured_Name }}</p>
                </div>

                <div class=" col-lg-3 col-sm-3 form-group">
                    <mat-label class="mtlabel" for="myInput">Status</mat-label>
                    <p>{{ dialogData.Status }}</p>
                </div>

                <div class="col-lg-3 col-sm-3 form-group">
                    <mat-label class="mtlabel" for="myInput">UHID</mat-label>
                    <p>{{ dialogData.UHID }}</p>
                </div>
            </div>
        </div>

        <div class="fieldsdiv">
            <p class="para-style">
                <b>Claim Details</b>
            </p>
            <div class="row customDiv planrowData" style="margin-left: 0px;">
                <div class="col-lg-3 col-sm-3 form-group">
                    <mat-label class="mtlabel" for="myInput">Claim Category</mat-label>
                    <p>{{ dialogData.Category_Of_Claim }}</p>
                </div>

                <div class=" col-lg-3 col-sm-3 form-group">
                    <mat-label class="mtlabel" for="myInput">Amount Claimed</mat-label>
                    <p>₹ {{ dialogData.Claimed_Amount }}</p>
                </div>

                <div class="col-lg-3 col-sm-3 form-group">
                    <mat-label class="mtlabel" for="myInput">Disallowed Amount</mat-label>
                    <p>₹ {{ dialogData.Disallowed_Amount }}</p>
                </div>

                <div class="col-lg-3 col-sm-3 form-group">
                    <mat-label class="mtlabel" for="myInput">Net Amount</mat-label>
                    <p>₹ {{ dialogData.Net_Amount_Paid }}</p>
                </div>
            </div>

            <div class="row customDiv planrowData" style="margin-left: 0px;">
                <div class="col-lg-3 col-sm-3 form-group">
                    <mat-label class="mtlabel" for="myInput">Inward Number</mat-label>
                    <p>{{ dialogData.Inward_Number }}</p>
                </div>

                <div class=" col-lg-3 col-sm-3 form-group">
                    <mat-label class="mtlabel" for="myInput">Inward Date</mat-label>
                    <p>{{ dialogData.Inward_Date }}</p>
                </div>

                <div class="col-lg-3 col-sm-3 form-group">
                    <mat-label class="mtlabel" for="myInput">Admission Date</mat-label>
                    <p>{{ dialogData.Date_of_Admission }}</p>
                </div>

                <div class="col-lg-3 col-sm-3 form-group">
                    <mat-label class="mtlabel" for="myInput">Discharge Date</mat-label>
                    <p>{{ dialogData.Date_of_Discharge }}</p>
                </div>
            </div>

            <div class="row customDiv planrowData" style="margin-left: 0px;">
                <div class="col-lg-12 col-sm-12 form-group">
                    <mat-label class="mtlabel" for="myInput">Reason</mat-label>
                    <p>{{ dialogData.Reason }}</p>
                </div>
            </div>

            <div class="row customDiv planrowData" style="margin-left: 0px;">
                <div class="col-lg-12 col-sm-12 form-group">
                    <mat-label class="mtlabel" for="myInput">Remarks</mat-label>
                    <p>{{ dialogData.Remarks }}</p>
                </div>
            </div>
        </div>

    </div>
</div>