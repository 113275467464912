import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { InsuredCompanyResponsePayload, PortabilityReasonsResponsePayload, BefitRequestPayload, BefitResponsePayload, SavehealthPolicyRequestPayload, SaveHealthPolicyResponsePayload, GenerateHtNumberRequestPayload, GenerateHtNumberResponsePayload, UpdateFolderIndexRequestPayload, UpdateFolderIndexResponsePayload, UpdateBasicPaymentDetailsRequestPayload, RecalculateRequestPayload, RecalculateResponsePayload, QuickquotePayload, QuickquoteRequestPayload, SumInsuredPayload, SubmitCaseRequestPayload } from '../interface/health';
import { GenericService } from '../services/generic.service';
import { SaveEditProposalV1RequestPayload, SaveEditProposalV1ResponsePayload } from '../interface/health-advantedge';
import { ZeroTatProposalRequestPayload, ZeroTatProposalResponsePayload } from '../interface/zerotat';
import { SavedQuote, SavedQuoteResponsePayload } from '../interface/savedQuotes';


@Injectable({
  providedIn: 'root'
})

export class HealthService {

  constructor(private generic: GenericService) {

  }

  readonly baseURI = `${environment.baseURL}Health/`;
  readonly baseURIQC = `${environment.baseURL}Qc/`;
  readonly getCompanyDetails = 'insurercompanydetails';
  readonly getReasonsOfPortability = 'GetReasonsOfPortability';
  readonly getBefitPlanPremium = 'BefitPlanPremium';
  readonly savehealthPolicy = 'SaveHealthPolicyDetails';
  readonly updateFolderIndexForHtNumber = 'UpdateFolderIndexForHtNumber';
  readonly generateHtNumberDetails = 'GenerateHtNumber';
  readonly fetchDocuments ='DocumentMaster';
  readonly submitQcCase = 'SubmitCase'; 
  readonly updateBasicDetails = 'UpdateBasicDetailsInPaymentPage';
  readonly recalculate = 'Recalculatepremium';
  readonly SaveEditQuoteV1 = 'SaveEditQuoteV1';
  readonly SaveEditProposalV1 = 'SaveEditProposalV1';
  readonly SaveQuote = 'SaveQuote';
  readonly ZeroTatproposal = 'ZeroTatProposal';
  readonly baseURIZeroTat = `${environment.baseURL}Health/`;
  readonly baseHealthAdv = `${environment.baseURL}HealthAdvantedge/`;
  readonly GetSumInsuredQuickQuote = 'GetSumInsuredQuickQuote';
  readonly QuickQuote = 'QuickQuote';


  getCompanyName(): Observable<InsuredCompanyResponsePayload> {
    const endpoint = `${this.baseURI}${this.getCompanyDetails}`;
    return this.generic.genericService<InsuredCompanyResponsePayload>(endpoint, {});
  }

  getReasonsOfPortabilityDetails(): Observable<PortabilityReasonsResponsePayload> {
    const endpoint = `${this.baseURI}${this.getReasonsOfPortability}`;
    return this.generic.genericService<PortabilityReasonsResponsePayload>(endpoint, {});
  }

  getBefitPlanPremiumDetails(data: BefitRequestPayload): Observable<BefitResponsePayload> {
    const endpoint = `${this.baseURI}${this.getBefitPlanPremium}`;
    return this.generic.genericService<BefitResponsePayload>(endpoint, data);
  }

  savehealthPolicyDetails(data: SavehealthPolicyRequestPayload): Observable<SaveHealthPolicyResponsePayload> {
    const endpoint = `${this.baseURI}${this.savehealthPolicy}`;
    return this.generic.genericService<SaveHealthPolicyResponsePayload>(endpoint, data);
  }

  updateFolderIndex(data: UpdateFolderIndexRequestPayload): Observable<UpdateFolderIndexResponsePayload> {
    const endpoint = `${this.baseURI}${this.updateFolderIndexForHtNumber}`;
    return this.generic.genericService<UpdateFolderIndexResponsePayload>(endpoint, data);
  }

  updateBasicPaymentDetails(data: UpdateBasicPaymentDetailsRequestPayload): Observable<any> {
    const endpoint = `${this.baseURI}${this.updateBasicDetails}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  recalculateRequest(data: RecalculateRequestPayload): Observable<RecalculateResponsePayload> {
    const endpoint = `${this.baseURI}${this.recalculate}`;
    return this.generic.genericService<RecalculateResponsePayload>(endpoint, data);
  }


  saveEditProposalV1(data: SaveEditProposalV1RequestPayload): Observable<SaveEditProposalV1ResponsePayload> {
    const endpoint = `${this.baseURI}${this.SaveEditProposalV1}`;
    return this.generic.genericService<SaveEditProposalV1ResponsePayload>(endpoint, data);
  }

  savedQuote(data: SavedQuote): Observable<any> {
    const endpoint = `${this.baseHealthAdv}${this.SaveQuote}`;
    return this.generic.genericService<SavedQuoteResponsePayload>(endpoint, data);
  }

  saveEditQuoteData(data): Observable<any> {
    const endpoint = `${this.baseURI}${this.SaveEditQuoteV1}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  ZeroTatProposal(data: ZeroTatProposalRequestPayload): Observable<ZeroTatProposalResponsePayload> {
    const endpoint = `${this.baseURIZeroTat}${this.ZeroTatproposal}`;
    return this.generic.genericService<ZeroTatProposalResponsePayload>(endpoint, data);
  }

  getSumInsuredQuickQuote(): Observable<SumInsuredPayload> {
    const endpoint = `${this.baseURI}${this.GetSumInsuredQuickQuote}`;
    return this.generic.genericService<SumInsuredPayload>(endpoint);
  }

  getQuickQuote(data: QuickquoteRequestPayload): Observable<QuickquotePayload> {
    const endpoint = `${this.baseURI}${this.QuickQuote}`;
    return this.generic.genericService<QuickquotePayload>(endpoint, data);
  }

  fetchDocumentMaster(): Observable<any> {
    const endpoint = `${this.baseURIQC}${this.fetchDocuments}`;
    return this.generic.genericService<any>(endpoint, {});
  }

  generateHtNumber(data: GenerateHtNumberRequestPayload): Observable<GenerateHtNumberResponsePayload> {
    const endpoint = `${this.baseURIQC}${this.generateHtNumberDetails}`;
    return this.generic.genericService<GenerateHtNumberResponsePayload>(endpoint, data);
  }

  generateOldHtNumber(data: GenerateHtNumberRequestPayload): Observable<GenerateHtNumberResponsePayload> {
    const endpoint = `${this.baseURI}${this.generateHtNumberDetails}`;
    return this.generic.genericService<GenerateHtNumberResponsePayload>(endpoint, data);
  }

  submitCase(data: SubmitCaseRequestPayload): Observable<any> {
    const endpoint = `${this.baseURIQC}${this.submitQcCase}`;
    return this.generic.genericService<any>(endpoint, data);
  }

}
