import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/authentication/auth.service';
import { Constants } from 'src/app/shared/constants/constants';
import { routeEnums } from 'src/app/shared/enums/routeEnums';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.scss']
})
export class PagenotfoundComponent {
  isAuthenticate: boolean = false;
  loadPageNotFoundPage: boolean = false;
  currentUrl: string;
  buttonText: string;
  constructor(private router: Router,
    private auth: AuthService) {
    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          this.currentUrl = event.url;
        }
      });
    this.isAuthenticate = this.auth.check();
    if (this.isAuthenticate) {
      this.loadPageNotFoundPage = true;
    }
    setTimeout(() => {
      if (this.currentUrl != undefined && this.currentUrl.includes(`${routeEnums.SWAP}`)) {
        this.loadPageNotFoundPage = false;
      }
    }, 0);

    // 404 page - button text for developer or pana login
    if (this.router.url.includes(`${routeEnums.DEVELOPER}`)) {
      this.buttonText = Constants.utilityDashboard;
    } else {
      this.buttonText = Constants.panaDashboard;
    }
  }

  goToHome() {
    // 404 page - button navigation for developer or pana login
    if (this.router.url.includes(`${routeEnums.DEVELOPER}`)) {
      this.router.navigate([`/${routeEnums.DEVELOPER}/${routeEnums.LOGS}`]);
    } else {
      this.router.navigate([`/${routeEnums.HEALTH}/${routeEnums.DASHBOARD}`]);
    }
  }
}
