import { ApplicationRef, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { env } from '../../environments/env';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { ApiserviceService } from '../../libservices/apiservice.service';
import { LibSessionStorageService } from '../../libservices/libsession-storage.service';
import { EmitButtonData } from 'src/app/shared/interface/custom-footer';
import { SessionStorageService, StorageService } from 'src/app/shared/services/storage.service';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { AgentService } from 'src/app/shared/proxy-services/agent.service';
import { HttpClient } from '@angular/common/http';
import { CreateLoginTokenRequest } from 'src/app/shared/interface/token';
import { Observable, interval } from 'rxjs';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'lib-notification-ui',
  templateUrl: './notification-ui.component.html',
  styleUrls: ['./notification-ui.component.css']
})
export class NotificationUiComponent implements OnChanges, OnInit {

  @Input() creds: { Uowkjsdjssrme: string, Powkjsdjssrd: string };
  @Output() buttonClicked = new EventEmitter<object>();
  showShakeAnimation: boolean = false;
  showdot: boolean = false;
  title = 'af-notification';
  noti_token: any;
  message: any = null;
  messages: any[] = [];
  messagesArray: { title: string, message: string }[] = [];
  messages_Array: Array<{ title: string, message: string }> = [];
  Array_messages: { title: string; message: string; }[] = [];
  showDiv: boolean = false;
  viewHistory: boolean = false;
  notification_token: any;
  cIPartner_UserID: string;
  cUser_Name: string;
  cUser_Password: string;
  mergedArraySpread: any[] = [];
  libraryComponent: NotificationUiComponent;
  libraryservice: ApiserviceService;
  notificationCounter = 0;
  private isHostListenerActive: boolean = false;


  readonly baseUrlPlutus = 'https://pana.insurancearticlez.com/apigateway/health'
  // public arraysubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  // array$ = this.arraysubject.asObservable();
  @Input() notificationCount: number = 0;
  @Input() isProfileOpen: boolean = false;
  @Input() updatedData: Array<{}>;
  @Output() getNotification = new EventEmitter<any>();
  @Input() dynamicName: string;
  constructor(public as: ApiserviceService, public appRef: ApplicationRef, public session_store: LibSessionStorageService,
    private agentService: AgentService, private storageService: StorageService,
    private utilityService: UtilityService, private cdr: ChangeDetectorRef)
  // private agentService: AgentService, private storageService: StorageService, private http: HttpClient,
  // public appref: ApplicationRef,
  // private apiService: ApiserviceService,
  // public libsession: LibSessionStorageService) 
  {
    // this.arraysubject.next([]);
  }
  ngOnInit(): void {
    const messaging = getMessaging();
    // this.session_store.getData().subscribe(data => { this.messages_Array = data; });
    this.buttonClicked.emit({ status: true });
    this.session_store.notificationData$.subscribe(notifyData => {
      if (notifyData['status']) {
        this.messages_Array = notifyData['notificationData'];
        this.showShakeAnimation = true;
        this.showdot = true;
        this.cdr.detectChanges();
      }
    })
    // setInterval(this.listen.bind(this),5000);
    // interval(5 * 1000).subscribe(() => this.listen());
    this.tokenFunc();
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['updatedData']) {
      const currentValue = changes['updatedData'].currentValue;
      if (currentValue.length > 0) {
        this.messages_Array = currentValue;
      }
    }

    if (this.isProfileOpen) {
      this.showDiv = false;
    }
  }


  tokenFunc() {
    // this.libraryservice = new ApiserviceService(this.http);
    // this.libraryComponent = new NotificationUiComponent(this.libraryservice, this.appref, this.libsession, this.agentService, this.storageService);
    if (!this.utilityService.isEmptyOrNull(this.creds)) {
      this.agentService.createApiToken(this.creds).subscribe({
        next: response => {
          if (response.StatusCode == 1 && response.StatusMsg == "Success") {
            this.createToken(response.Data);
            // this.libraryComponent.listen();
            // this.libraryComponent.auth(response.Data);

          }
        }
      })
    }
  }

  // createApiToken(data: CreateLoginTokenRequest): Observable<any> {
  //   return this.http.post(this.baseUrlPlutus + 'Notification/CreatePlutusLoginToken', '', 'json', headers)
  // }

  auth(response) {
    this.createToken(response);
  }


  // AuthenticateCreds(response): Promise<any> {

  //   return new Promise((resolve: any) => {
  //     // this.cIPartner_UserID = _IPartner_UserID;
  //     // this.cUser_Name = _User_Name;
  //     // this.cUser_Password = _User_Password;
  //     console.log(response, 'after return');

  //     this.createToken(response);
  //     resolve();
  //   });
  // }

  //   createApiToken(data: CreateLoginTokenRequest): Observable<any> {
  //     const headers = this.setUserHeaders(data);
  //     return this.http.post(environment.baseURL + 'Notification/CreatePlutusLoginToken', '', 'json', headers)
  // }

  createToken(response): Promise<any> {
    return new Promise((resolve: any) => {
      let authToken = response;
      if (!this.as.isUndefineORNull(authToken)) {
        this.storageService.setAESEncryptedDataLocal('FCMAuthtoken', authToken);
        // localStorage.setItem('FCMAuthtoken', JSON.stringify(authToken));
        sessionStorage.setItem('count', '0');
        this.gethistory().then(() => {
          this.requestPermission().then(token => {
            this.NotificationToken(token);
            // this.listen();
            // sessionStorage.setItem('messages_Array', JSON.stringify(this.messages_Array))
            // this.toggleDiv();
            this.listen();
          });
        });

        // this.requestPermission();
        // this.NotificationToken();
        // this.listen();
      }
      resolve();
      // },
      //   (err) => {
      //     // error
      // })
    })
  }


  requestPermission(): Promise<any> {
    return new Promise((resolve: any) => {
      const messaging = getMessaging();

      let key = "BN8u0jV5aTODz3eHk-qcGTzta6J70KZvVFOHs0cRILjtcCOKK5vaFRZABTdOohsDRGoI3ifQ4o8XuGqLdprnxkY";

      getToken(messaging,
        { vapidKey: key }).then(
          (currentToken) => {
            if (currentToken) {
              resolve(currentToken);
            } else {
            }
          }).catch((err) => {
          });
    });

  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      this.message = payload;
      this.messages.push(payload);
      const title = payload.notification?.title || 'No Title';
      const message = payload.notification?.body || 'No Message';
      // Push the title and message into the messagesArray
      this.messages_Array.unshift({ title, message });
      this.session_store.emitNotificationData({ status: true, notificationData: this.messages_Array });
      // sessionStorage.setItem('messages_Array',JSON.stringify(this.messages_Array));
      this.showShakeAnimation = true;
      this.showdot = true;

      // Reset shake animation after a delay (0.8s in this case)
      setTimeout(() => {
        this.showShakeAnimation = false;
        this.showdot = true;
      }, 800);
      this.getNotification.emit(this.messages_Array);
      this.session_store.setData(this.messages_Array);
      this.appRef.tick()

      //this.updateLocalStorage();
    });

  }
  NotificationToken(token?: string) {
    let postObj = {
      "UserToken": token
    }
    this.as.getData(postObj).subscribe(
      (res: any) => {
      }
    );

  }


  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!(event.target instanceof Element)) {
      return;
    }
    const closestBellBtn = (event.target as Element).closest('.bell-btn');
    if (!closestBellBtn && this.showDiv) {
      this.closeNotification();
    }
  }

  toggleDiv(): void {
    this.showDiv = !this.showDiv;
    this.showdot = false;
    this.notificationCounter = 0;

    if (this.showDiv && !this.isHostListenerActive) {
      document.addEventListener('click', this.onClick);
      this.isHostListenerActive = true;
    } else if (!this.showDiv && this.isHostListenerActive) {
      document.removeEventListener('click', this.onClick);
      this.isHostListenerActive = false;
    }
  }

  closeNotification(): void {
    this.showDiv = false;
    this.showdot = false;
    this.notificationCounter = 0;
    if (this.isHostListenerActive) {
      document.removeEventListener('click', this.onClick);
      this.isHostListenerActive = false;
    }
  }

  gethistory(): Promise<any> {
    return new Promise((resolve: any) => {
      this.as.getnotificationhistory().subscribe(
        (res: any) => {
          if (res.statusMsg == "Success") {
            this.viewHistory = true;
            // this.messages_Array = [];
            let tempNotification = [];
            res.response.messageslist.forEach(item => {
              tempNotification.push({
                title: item.title,
                message: item.body
              });
            });
            this.messages_Array = tempNotification;
            this.getNotification.emit(this.messages_Array);
            this.dynamicName = 'fail';
            resolve();
          }
        },
        (err) => {
          //show service is down inside notification history section
        }

      );
    })
  }

  checkNotification(event) {
    this.getNotification.emit(this.messages_Array);
  }
}
export class BadgeOverviewExample {
  hidden = true;

  toggleBadgeVisibility() {
    this.hidden = !this.hidden;
  }
}

//Add ngondestroy()

