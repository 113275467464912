import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { routeEnums } from 'src/app/shared/enums/routeEnums';

import { Constants } from 'src/app/shared/constants/constants';
import { PopupService } from 'src/app/shared/services/popup.service';
import { PopupModal } from '../popups/popup.modal';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loadingCounter = 0;
  errorPopupData: PopupModal;
  loaderShow: EventEmitter<void> = new EventEmitter<void>();
  loaderHide: EventEmitter<void> = new EventEmitter<void>();
  constructor(private popupService: PopupService, private router: Router) { }

  show() {
    this.loadingCounter++;
    this.loaderShow.emit();
    return true;
  }

  hide() {
    this.loadingCounter--;
    if (this.loadingCounter <= 0) {
      this.loadingCounter = 0;
      this.loaderHide.emit();
    }
    return false;
  }

  handleError(response) {
    if (this.loadingCounter === 1) {
      if (response?.type == 'application/pdf' && this.router.routerState.snapshot.url == '/brochure') {
        return;
      } else if (response.StatusCode != Constants.statusCode_success && response != undefined) {
        if (response.StatusDesc != 'Login Resticted') {
          this.errorPopupData = this.popupService.fetchErrorPopupMsg(response.StatusDesc, response?.LogReference, response?.LogReference ? true : false);
          this.popupService.openGenericPopup(this.errorPopupData);
          if (response.StatusDesc === 'Deal ID Not Found') {
            this.router.navigate([routeEnums.HEALTH + '/' + routeEnums.DASHBOARD]);
            return;
          }
        } else {
          this.errorPopupData = this.popupService.fetchErrorPopupMsg(response.StatusDesc);
          this.popupService.openGenericPopup(this.errorPopupData);
        }
      }
    }
  }
}
