import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GetPolicyDetailsRequestPayload, GetPolicyDetailsResponsePayload } from '../interface/policy';
import { Observable } from 'rxjs';
import { GenericService } from '../services/generic.service';

@Injectable({
  providedIn: 'root'
})
export class PolicyService {

  constructor(private generic: GenericService) { }

  readonly baseURI = `${environment.baseURL}Policy/`;
  readonly getPolicyDetailsID = 'GetPolicyDetails'

  getPolicyDetails(data: GetPolicyDetailsRequestPayload): Observable<GetPolicyDetailsResponsePayload> {
    const endpoint = `${this.baseURI}${this.getPolicyDetailsID}`;
    return this.generic.genericService<GetPolicyDetailsResponsePayload>(endpoint, data);
  }
}
