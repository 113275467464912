// This service should only be responsible for transferring products data between the components / shared components

import { Injectable } from '@angular/core';
import { SessionStorageService, StorageService } from 'src/app/shared/services/storage.service';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { HealthAilmentResponseStructure, InsuredRelationship, Relationship, RelationshipsStructure } from 'src/app/shared/interface/healthMaster';
import { NewAddOnCoverDetails } from 'src/app/shared/interface/renewal';
import { HomeRenewalRequestPayload, RenewalDataModal, RenewalDataStorage, RenewalDataStorageRequestPayload, ZerotatDataStorageRequestPayload } from './renewal-storage.modal';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { Subscription } from 'rxjs';
import { HealthMasterService } from 'src/app/shared/proxy-services/health-master.service';
import { Router } from '@angular/router';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { PopupService } from 'src/app/shared/services/popup.service';
import { Constants } from 'src/app/shared/constants/constants';
import { routeEnums } from 'src/app/shared/enums/routeEnums';

@Injectable({
  providedIn: 'root'
})
export class RenewalDataStorageService {
  RenewalDataModal: RenewalDataModal = new RenewalDataModal();
  returnFromProposal: boolean = false;
  correspondenceCheckbox: boolean = true;
  diseaseList: HealthAilmentResponseStructure[] = [];
  RelationList: RelationshipsStructure;
  InsuredRelationList: InsuredRelationship[] = [];
  NomineeAppointeeRelationList: Relationship[] = [];
  addonList: NewAddOnCoverDetails[] = [];
  occupationList: [{ [key: string]: string; }];
  errorPopupData: PopupModal;
  private subscription: Subscription[] = [];
  rnFetchVoluntaryCopay: number = 0;
  rnFetchVoluntaryDeductible: number = 0;
  homePolicies = ['4013', '4118', '4119', '4120', '4121', '4040', '1001', '1015', '1024'];

  constructor(private storageService: StorageService,
    private sessionService: SessionStorageService,
    private utilityService: UtilityService,
    private healthMasterService: HealthMasterService,
    private route: Router,
    private popupService: PopupService,) {
  }


  setRenewalDataStorage(form: RenewalDataStorage) {
    this.RenewalDataModal.setRenewalDataStorage(form);
    this.storageService.setAESEncryptedData(userEnums.RenewalFormData, form);
  }

  // setZerotatDataStorage(form: any) {
  //   this.RenewalDataModal.setZerotatDataStorage(form);
  // }

  getRenewalDataStorage(): RenewalDataStorage {
    let RenewalStorageForm;
    // Below logic is written so that we should fetch initial data when user lands on component.
    // But if the form structure is already present and user clicks on refresh then we don't want our data to reset
    // So due to this reason we have written below logic
    if (!this.sessionService.has(userEnums.RenewalFormData)) {
      RenewalStorageForm = this.RenewalDataModal.getRenewalDataStorage();
    }
    else {
      RenewalStorageForm = this.storageService.getAESDecryptedData(userEnums.RenewalFormData);
    }

    this.storageService.setAESEncryptedData(userEnums.RenewalFormData, RenewalStorageForm);
    return this.storageService.getAESDecryptedData(userEnums.RenewalFormData);
  }

  resetRenewalDataStorage() {
    let form: RenewalDataStorage = this.RenewalDataModal.resetRenewalDataStorage();
    this.setRenewalDataStorage(form);
  }
  getreturnFromProposalFlag() {
    return this.returnFromProposal;
  }
  setreturnFromProposalFlag(flag: boolean) {
    this.returnFromProposal = flag;
  }
  indenmitySearch(policyNumber, SubProductType, customerDOB) {
    let req: RenewalDataStorageRequestPayload = {
      "UserType": "Agent",
      "PolicyNo": policyNumber,
      "Addon1": false,
      "Addon3": false,
      "IsHigherSiRequired": false,
      "SubProductType": parseInt(SubProductType),
      "InsuredDOB": this.utilityService.formatDateWithDash_dmy(customerDOB)
    }

    this.subscription.push(this.healthMasterService.getRenewalFetch(req).subscribe(response => {
      if (response.StatusCode == 1) {
        let renewalDataStorage = response.Data;
        renewalDataStorage.instantrenewal = true;
        renewalDataStorage.IsHaapRefiling = renewalDataStorage.ZoneRefilingflag == 'Yes' && parseInt(renewalDataStorage.SubProductType) == 42 ? true : false;
        // Check for pep flag
        if (!this.utilityService.isEmptyOrNull(renewalDataStorage.customerDetails.PEPFLAG)) {
          renewalDataStorage.customerDetails.PEPFLAG = (renewalDataStorage.customerDetails.PEPFLAG).toLowerCase() == 'No' || (renewalDataStorage.customerDetails.PEPFLAG).toLowerCase() == 'false'  ? false : true;
        }
        else if (this.utilityService.isEmptyOrNull(renewalDataStorage.customerDetails.PEPFLAG)) {
          renewalDataStorage.customerDetails.PEPFLAG = false;
        }
        this.setRenewalDataStorage(renewalDataStorage);
        this.route.navigate(['/renewal-summary'])
      }
    }))
  }

  zeroTatSearch(policyNumber, customerDOB) {
    let request: ZerotatDataStorageRequestPayload = {
      "PolicyNo": policyNumber,
      "InsuredDOB": this.utilityService.formatDateWithDash(customerDOB)
    }
    this.subscription.push(this.healthMasterService.getZerotatFetch(request).subscribe(response => {
      if (response.StatusCode == 1) {
        let zeroTatDataStorage = response.Data;
        zeroTatDataStorage.instantrenewal = true;
        // Check for pep flag
        if (!this.utilityService.isEmptyOrNull(zeroTatDataStorage.CustomerDetails.PEPFLAG)) {
          zeroTatDataStorage.CustomerDetails.PEPFLAG = (zeroTatDataStorage.CustomerDetails.PEPFLAG).toLowerCase() == 'No' || (zeroTatDataStorage.CustomerDetails.PEPFLAG).toLowerCase() == 'false'  ? false : true;
        }
        else if (this.utilityService.isEmptyOrNull(zeroTatDataStorage.CustomerDetails.PEPFLAG)) {
          zeroTatDataStorage.CustomerDetails.PEPFLAG = false;
        }
        this.setRenewalDataStorage(zeroTatDataStorage);
        this.route.navigate(['/zerotat-renewal-summary'])
      }
    }))
  }

  homeSearch(policyNumber, customerDOB) {
    let request: HomeRenewalRequestPayload = {
      "policy_number": policyNumber,
      "policy_end_date": this.utilityService.formatDateHomeRenewal(customerDOB)
    }
    this.subscription.push(this.healthMasterService.getHomeFetch(request).subscribe(response => {
      if (response.StatusCode == Constants.statusCode_success) {
        this.storageService.setAESEncryptedData(userEnums.RenewalFormData, response.Data);
        this.route.navigate([routeEnums.HomeRenewalSummary])
      }
    }))
  }

  getSubProductType(policyNumber) {
    let SubProductInfo = {
      'SubProductType': '',
      'ProductType': ''
    };
    if (policyNumber) {
      if (policyNumber.startsWith('4193')) {
        SubProductInfo['SubProductType'] = "42";//haap
        SubProductInfo['ProductType'] = "indimnity";
      }
      else if (policyNumber.startsWith('4128')) {
        SubProductInfo['SubProductType'] = "33";//hep
        SubProductInfo['ProductType'] = "indimnity";
      }
      else if (policyNumber.startsWith('4140')) {
        SubProductInfo['SubProductType'] = "20";//hb
        SubProductInfo['ProductType'] = "indimnity";
      }
      else if (policyNumber.startsWith('4171')) {
        SubProductInfo['SubProductType'] = "22";//asp
        SubProductInfo['ProductType'] = "indimnity";
      }
      else if (policyNumber.startsWith('4192')) {
        SubProductInfo['SubProductType'] = "40";//gs
        SubProductInfo['ProductType'] = "indimnity";
      }
      else if (policyNumber.startsWith('4191')) {
        SubProductInfo['SubProductType'] = "38";//cs
        SubProductInfo['ProductType'] = "zerotat";
      }
      else if (policyNumber.startsWith('4172')) {
        SubProductInfo['SubProductType'] = "23";//fs
        SubProductInfo['ProductType'] = "zerotat";
      }
      else if (policyNumber.startsWith('4223')) {
        SubProductInfo['SubProductType'] = "34";//max
        SubProductInfo['ProductType'] = "indimnity";
      }
      else if (this.homePolicies.some(x => policyNumber.startsWith(x))) {
        SubProductInfo['SubProductType'] = "4";
        SubProductInfo['ProductType'] = "home";
      }
      else {
        this.errorPopupData = this.popupService.fetchErrorPopupMsg('Kindly enter valid policy number');
        this.popupService.openGenericPopup(this.errorPopupData);
      }
    }
    return SubProductInfo;
  }
}