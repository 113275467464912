<!-- <div class="container-fluid" *ngIf="false">
    <div class="row">
        <div class="container panaMaincontainer">
            <div class="pb-5">
                <div class="steppermainDiv">
                    <div class="marginbox">
                        <div class="myTransactionTitle">
                            <span class="cstmstep"><img
                                    src="../../../../../assets/images/MyTransactionIcon/pendingpayment.svg"
                                    alt=""></span>
                            <span class="cstmstep">
                                <h3>Pending Payment</h3>
                            </span>
                        </div>
                        <mat-tab-group class="pendingTabs" (selectedTabChange)="onClick($event)">
                            <mat-tab class=""  label="Health">
                                <ng-template mat-tab-label class="tablock "> -->
                                    <!-- <mat-icon class="cstm_icon" matPrefix>
                                    <img src="../../../../assets/images/health.png" alt="" class="icon">
                                </mat-icon> -->
                                    <!-- Health
                                </ng-template> -->
                                <!-- tab content start here -->
                                <!-- <div class="tabInnerdiv mb-0 fieldsdiv">
                                    <div class="row">
                                        <div class="col-sm-3 pendingPayCol3 form-group mb0" *ngIf='isData'>
                                            <mat-label class="input-group__label">Proposal Number/Customer Name
                                            </mat-label>
                                            <input class="input-group__input" [(ngModel)]="searchText"
                                                placeholder="Search Proposal Number/Customer Name">
                                        </div>
                                        
                                       <div class="col-sm-3 pendingPayCol3 form-group mb0">
                                            <mat-label class="input-group__label" for="myInput">Product
                                                Name</mat-label>
                                            <mat-select class="input-group__input cstm_select" id="childDetails"
                                                placeholder="Product Name" [(ngModel)]="productCode"
                                                (click)="handleMatSelectClick()"
                                                (blur)="removeMatSelectScrollListener()">
                                                <ng-container
                                                    *ngFor="let item of productList; let i = index; let last = last">
                                                    <mat-option [value]="item.productCode"
                                                        (onSelectionChange)="selectproduct(item.productCode)">
                                                        {{item.productName}}
                                                    </mat-option>
                                                    <mat-divider *ngIf="!last"></mat-divider>
                                                </ng-container>
                                            </mat-select> -->
                                            <!-- <mat-error class="error-margin"
                                          *ngIf="mypolicyForm.controls['productName']?.errors?.['required'] && formSubmitted">
                                          Product Name is required
                                        </mat-error> -->
                                        <!-- </div>

                                        <div class="col-sm-2 pendingPayCol2 form-group">
                                            <mat-label class="input-group__label" for="myInput"> Start Date<sup
                                                    class="mandtory-field">*</sup></mat-label>
                                            <input class="input-group__input dob" matInput
                                                [matDatepicker]="myPolicyStartDate" (click)="myPolicyStartDate.open()"
                                                [min]='minimum_date' [max]='maximum_date' [(ngModel)]="health_start_date"
                                                placeholder="DD/MM/YYYY" readonly>
                                            <mat-datepicker-toggle matSuffix
                                                [for]="myPolicyStartDate"></mat-datepicker-toggle>
                                            <mat-datepicker #myPolicyStartDate touchUi>
                                            </mat-datepicker>
                                        </div>

                                        <div class="col-sm-2 pendingPayCol2 form-group">
                                            <mat-label class="input-group__label" for="myInput"> End Date<sup
                                                    class="mandtory-field">*</sup></mat-label>
                                            <input class="input-group__input dob" matInput
                                                [matDatepicker]="myPolicyEndDate" (click)="myPolicyEndDate.open()"
                                                [min]='minimum_date' [max]='maximum_date' [(ngModel)]="health_end_date"
                                                placeholder="DD/MM/YYYY" readonly>
                                            <mat-datepicker-toggle matSuffix
                                                [for]="myPolicyEndDate"></mat-datepicker-toggle>
                                            <mat-datepicker #myPolicyEndDate touchUi>
                                            </mat-datepicker>
                                        </div>

                                        <div class="col-sm-2 pendingPayCol2 form-group  d-md-block d-none">
                                            <button class="search-button" (click)="onSearchData()">
                                                <img src="../../../assets/images/Page-1.svg" alt=""
                                                    style="width: 18px"><span class="pl-1">Search</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="scrollcontainer" [ngStyle]="{'height': isData ? '100vh' : 'fit-content'}">
                                    <div class="scrollRow">
                                        <div class="tabInnerdiv innerTabs mt-3 no-record" *ngIf='!isData'>
                                            <img src="../../../assets/images/NoRecordFound.svg" alt="No Record Found"
                                                class="img-fluid">
                                            <h3 class="text-center mb-0"> No data to show</h3>
                                            <h4 class="text-center mb-0"> Try adjusting your search or filter to find
                                                your report</h4>
                                        </div>
                                        <div class="tabInnerdiv innerTabs mt-3"
                                            *ngFor="let details of paymentDetails | filter:searchText; let i=index">
                                            <h3>{{details['CustomerName']}}</h3>
                                            <div class="fieldsdiv">
                                                <div class="row customDiv">
                                                    <div class="col-sm-3 form-group pwidth50">
                                                        <mat-label class="mtlabel" for="myInput">Proposal
                                                            Number</mat-label>
                                                        <p>{{details['ProposalNumber'] ? details['ProposalNumber'] :
                                                            details['PFProposalNo']}}</p>
                                                    </div>
                                                    <div class="col-sm-3 form-group pwidth50">
                                                        <mat-label class="mtlabel" for="myInput">PF Proposal
                                                            Number</mat-label>
                                                        <p>{{details['PFProposalNo'] ? details['PFProposalNo'] :
                                                            details['ProposalNumber']}}</p>
                                                    </div>
                                                    <div class="col-sm-3 form-group pwidth50">
                                                        <mat-label class="mtlabel" for="myInput">Proposal
                                                            Date</mat-label>
                                                        <p>{{details['TransOn']}}</p>
                                                    </div>
                                                    <div class="col-sm-3 form-group pwidth50">
                                                        <mat-label class="mtlabel" for="myInput">Start Date</mat-label>
                                                        <p>{{details['StartDate']}}</p>
                                                    </div>
                                                    <div class="col-sm-3 form-group pwidth50">
                                                        <mat-label class="mtlabel" for="myInput">End Date</mat-label>
                                                        <p>{{details['EndDate']}}</p>
                                                    </div>
                                                    <div class="col-sm-3 form-group pwidth50">
                                                        <mat-label class="mtlabel" for="myInput">Status</mat-label>
                                                        <p
                                                            [ngStyle]="{'color': details['Status'] === 'Failed Payment' ? '#E71A1A' : 'inherit'}">
                                                            {{details['Status']}}</p>
                                                    </div> -->
                                                    <!-- If status is failed payment then set the value -->
                                                    <!-- <div class="col-sm-3 form-group pwidth50"
                                                        *ngIf="details['Status'] == 'Failed Payment'">
                                                        <mat-label class="mtlabel" for="myInput">Remark</mat-label>
                                                        <p>{{details['FailedRemark']}}</p>
                                                    </div>
                                                    <div class="col-sm-3 form-group pwidth50">
                                                        <mat-label class="mtlabel" for="myInput">
                                                            {{ details['isEmiRequired'] ? 'EMI Payable Amount' :
                                                            'Payable Amount' }}
                                                        </mat-label>
                                                        <p>{{'₹' +
                                                            utilityService.convertStringtoCurrency(totalPremiumAmount(details))}}
                                                        </p>
                                                    </div>

                                                </div>

                                            </div>
                                            <mat-divider></mat-divider>

                                            <div class="fieldsdiv">
                                                <div class="row">
                                                    <div class="col-sm-12 mt-3 form-group customCheckslist">
                                                        <ul>
                                                            <li>
                                                                <mat-checkbox class="cstmCheckbox checkboxPending"
                                                                    *ngIf="showAddtoPay(details) && checkEMI(details)"
                                                                    [id]="details['PolicyID']" value="on"
                                                                    (change)="isChecked($event,details)">Add to
                                                                    pay</mat-checkbox>
                                                                <button
                                                                    class=" checkboxPending downloadPending recalculate ml-0"
                                                                    (click)="reCalAmount($event)"
                                                                    *ngIf="details['isRecalculateRequired'] && checkEMI(details) && isHealth && details['PolicySubType'] != 25 && details['PolicySubType'] != 24 && details['PolicySubType'] !=37 && checkEMI(details)">Recalculate</button>
                                                                <button class=" checkboxPending downloadPending"
                                                                    (click)="downloadPdfFile(details)"><img
                                                                        src="../../../assets/images/download.svg"
                                                                        alt="">
                                                                    <span class="ml-2"
                                                                        style="color: #000000DE">Download</span></button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- scroll container ends here -->
                                <!-- tab content ends here -->
                            <!-- </mat-tab>

                            <mat-tab class="" label="Travel">
                                <ng-template mat-tab-label class="tablock ">
                                    Travel
                                </ng-template>
                                <div class="tabInnerdiv mb-0 fieldsdiv">
                                    <div class="row">
                                        <div class="col-sm-3 pendingPayCol3 form-group mb0" *ngIf='isData'>
                                            <mat-label class="input-group__label">Proposal Number / Customer Name
                                            </mat-label>
                                            <input class="input-group__input" [(ngModel)]="searchText"
                                                placeholder="Search">
                                        </div>

                                        <div class="col-sm-3 pendingPayCol3 form-group mb0">
                                            <mat-label class="input-group__label" for="myInput">Product
                                                Name</mat-label>
                                            <mat-select class="input-group__input cstm_select" id="childDetails"
                                                placeholder="Product Name" [(ngModel)]="productCodeTravel"
                                                (click)="handleMatSelectClick()"
                                                (blur)="removeMatSelectScrollListener()">
                                                <ng-container
                                                    *ngFor="let item of travelList; let i = index; let last = last">
                                                    <mat-option [value]="item.productCode"
                                                        (onSelectionChange)="selectproductTravel(item.productCode)">
                                                        {{item.productName}}
                                                    </mat-option>
                                                    <mat-divider *ngIf="!last"></mat-divider>
                                                </ng-container>
                                            </mat-select>
                                        </div>

                                        <div class="col-sm-2 pendingPayCol2 form-group">
                                            <mat-label class="input-group__label" for="myInput"> Start Date<sup
                                                    class="mandtory-field">*</sup></mat-label>
                                            <input class="input-group__input dob" matInput
                                                [matDatepicker]="myPolicyStartDate" (click)="myPolicyStartDate.open()"
                                                [min]='minimum_date' [max]='maximum_date' [(ngModel)]="travel_start_date"
                                                placeholder="DD/MM/YYYY" readonly>
                                            <mat-datepicker-toggle matSuffix
                                                [for]="myPolicyStartDate"></mat-datepicker-toggle>
                                            <mat-datepicker #myPolicyStartDate touchUi>
                                            </mat-datepicker>
                                        </div>

                                        <div class="col-sm-2 pendingPayCol2 form-group">
                                            <mat-label class="input-group__label" for="myInput"> End Date<sup
                                                    class="mandtory-field">*</sup></mat-label>
                                            <input class="input-group__input dob" matInput
                                                [matDatepicker]="myPolicyEndDate" (click)="myPolicyEndDate.open()"
                                                [min]='minimum_date' [max]='maximum_date' [(ngModel)]="travel_end_date"
                                                placeholder="DD/MM/YYYY" readonly>
                                            <mat-datepicker-toggle matSuffix
                                                [for]="myPolicyEndDate"></mat-datepicker-toggle>
                                            <mat-datepicker #myPolicyEndDate touchUi>
                                            </mat-datepicker>
                                        </div>

                                        <div class="col-sm-2 pendingPayCol2 form-group  d-md-block d-none">
                                            <button class="search-button" (click)="onSearchData()">
                                                <img src="../../../assets/images/Page-1.svg" alt=""
                                                    style="width: 18px"><span class="pl-1">Search</span>
                                            </button>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="scrollcontainer" [ngStyle]="{'height': isData ? '100vh' : 'fit-content'}">
                                    <div class="scrollRow">
                                        <div class="tabInnerdiv innerTabs mt-3 no-record" *ngIf='!isData'>
                                            <img src="../../../assets/images/NoRecordFound.svg" alt="No Record Found"
                                                class="img-fluid">
                                            <h3 class="text-center mb-0"> No data to show</h3>
                                            <h4 class="text-center mb-0"> Try adjusting your search or filter to find
                                                your report</h4>
                                        </div>
                                        <div class="tabInnerdiv innerTabs mt-3"
                                            *ngFor="let details of paymentDetails | filter:searchText; let i=index">
                                            <h3>{{details['CustomerName']}}</h3>
                                            <div class="fieldsdiv">
                                                <div class="row customDiv">
                                                    <div class="col-sm-3 form-group pwidth50">
                                                        <mat-label class="mtlabel" for="myInput">Proposal
                                                            Number</mat-label>
                                                        <p>{{details['ProposalNumber'] ? details['ProposalNumber'] :
                                                            details['PFProposalNo']}}</p>
                                                    </div>
                                                    <div class="col-sm-3 form-group pwidth50">
                                                        <mat-label class="mtlabel" for="myInput">PF Proposal
                                                            Number</mat-label>
                                                        <p>{{details['PFProposalNo'] ? details['PFProposalNo'] :
                                                            details['ProposalNumber']}}</p>
                                                    </div>
                                                    <div class="col-sm-3 form-group pwidth50">
                                                        <mat-label class="mtlabel" for="myInput">Proposal
                                                            Date</mat-label>
                                                        <p>{{details['TransOn']}}</p>
                                                    </div>
                                                    <div class="col-sm-3 form-group pwidth50">
                                                        <mat-label class="mtlabel" for="myInput">Start Date</mat-label>
                                                        <p>{{details['StartDate']}}</p>
                                                    </div>
                                                    <div class="col-sm-3 form-group pwidth50">
                                                        <mat-label class="mtlabel" for="myInput">End Date</mat-label>
                                                        <p>{{details['EndDate']}}</p>
                                                    </div>
                                                    <div class="col-sm-3 form-group pwidth50">
                                                        <mat-label class="mtlabel" for="myInput">Status</mat-label>
                                                        <p
                                                            [ngStyle]="{'color': details['Status'] === 'Failed Payment' ? '#E71A1A' : 'inherit'}">
                                                            {{details['Status']}}</p>
                                                    </div>
                                                    <div class="col-sm-3 form-group pwidth50"
                                                        *ngIf="details['Status'] == 'Failed Payment'">
                                                        <mat-label class="mtlabel" for="myInput">Remark</mat-label>
                                                        <p>{{details['FailedRemark']}}</p>
                                                    </div>
                                                    <div class="col-sm-3 form-group pwidth50">
                                                        <mat-label class="mtlabel" for="myInput">
                                                            {{ details['isEmiRequired'] ? 'EMI Payable Amount' :
                                                            'Payable Amount' }}
                                                        </mat-label>
                                                        <p>{{'₹' +
                                                            utilityService.convertStringtoCurrency(totalPremiumAmount(details))}}
                                                        </p>
                                                    </div>

                                                </div>

                                            </div>
                                            <mat-divider></mat-divider>

                                            <div class="fieldsdiv">
                                                <div class="row">
                                                    <div class="col-sm-12 mt-3 form-group customCheckslist">
                                                        <ul>
                                                            <li>
                                                                <mat-checkbox class="cstmCheckbox checkboxPending"
                                                                    *ngIf="showAddtoPay(details) && checkEMI(details)"
                                                                    [id]="details['PolicyID']" value="on"
                                                                    (change)="isChecked($event,details)">Add to
                                                                    pay</mat-checkbox>


                                                                    <button
                                                                    class=" checkboxPending downloadPending recalculate ml-0"
                                                                    (click)="reCalAmount(details['PolicyID'], totalPremiumAmount(details))"
                                                                    *ngIf="details['isRecalculateRequired'] && checkEMI(details) && isHealth && details['PolicySubType'] != 25 && details['PolicySubType'] != 24 && details['PolicySubType'] !=37 && checkEMI(details)">Recalculate</button>
                                                            
                                                         
                                                                <button class=" checkboxPending downloadPending"
                                                                    (click)="downloadPdfFile(details)">
                                                                    <div class="myDownload">
                                                                        <span class="webdownload">Download
                                                                            Proposal</span>
                                                                    </div>
                                                                    <span class="mobiledownload"><img
                                                                            src="../../../assets/images/myPolicy/proposal-download.svg"
                                                                            alt="Download Proposal"></span>
                                                                </button>
                                                           
                                                                <button class=" checkboxPending downloadPending"
                                                                    *ngIf="isViewPolicyVisible(details)"
                                                                    (click)="downloadPolicy(details)">
                                                                    <div class="myDownload">
                                                                        <span class="webdownload">Download Policy</span>
                                                                    </div>
                                                                    <span class="mobiledownload"><img
                                                                            src="../../../assets/images/myPolicy/policy-download.svg"
                                                                            alt="Download Policy"></span>
                                                                </button>
                                                            </li>
                                                            
                                                                
                                                            
                                                        </ul>
                                                        
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab class="" label="Home">
                                <ng-template mat-tab-label class="tablock ">
                                    Home
                                </ng-template>
                                <div class="tabInnerdiv mb-0 fieldsdiv">
                                    <div class="row">
                                        <div class="col-sm-3 pendingPayCol3 form-group mb0">
                                            <mat-label class="input-group__label">Proposal Number / Customer Name
                                            </mat-label>
                                            <input class="input-group__input" [(ngModel)]="searchText"
                                                placeholder="Search">
                                        </div>

                                        <div class="col-sm-2 pendingPayCol2 form-group">
                                            <mat-label class="input-group__label" for="myInput"> Start Date<sup
                                                    class="mandtory-field">*</sup></mat-label>
                                            <input class="input-group__input dob" matInput
                                                [matDatepicker]="myPolicyStartDate" (click)="myPolicyStartDate.open()"
                                                [min]='minimum_date' [max]='maximum_date' [(ngModel)]="home_start_date"
                                                placeholder="DD/MM/YYYY" readonly>
                                            <mat-datepicker-toggle matSuffix
                                                [for]="myPolicyStartDate"></mat-datepicker-toggle>
                                            <mat-datepicker #myPolicyStartDate touchUi>
                                            </mat-datepicker>
                                        </div>

                                        <div class="col-sm-2 pendingPayCol2 form-group">
                                            <mat-label class="input-group__label" for="myInput"> End Date<sup
                                                    class="mandtory-field">*</sup></mat-label>
                                            <input class="input-group__input dob" matInput
                                                [matDatepicker]="myPolicyEndDate" (click)="myPolicyEndDate.open()"
                                                [min]='minimum_date' [max]='maximum_date' [(ngModel)]="home_end_date"
                                                placeholder="DD/MM/YYYY" readonly>
                                            <mat-datepicker-toggle matSuffix
                                                [for]="myPolicyEndDate"></mat-datepicker-toggle>
                                            <mat-datepicker #myPolicyEndDate touchUi>
                                            </mat-datepicker>
                                        </div>

                                        <div class="col-sm-2 pendingPayCol2 form-group  d-md-block d-none">
                                            <button class="search-button" (click)="onSearchData()">
                                                <img src="../../../assets/images/Page-1.svg" alt=""
                                                    style="width: 18px"><span class="pl-1">Search</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="scrollcontainer" [ngStyle]="{'height': isData ? '100vh' : 'fit-content'}">
                                    <div class="scrollRow">
                                        <div class="tabInnerdiv innerTabs mt-3 no-record" *ngIf='!isData'>
                                            <img src="../../../assets/images/NoRecordFound.svg" alt="No Record Found"
                                                class="img-fluid">
                                            <h3 class="text-center mb-0"> No data to show</h3>
                                            <h4 class="text-center mb-0"> Try adjusting your search or filter to find
                                                your report</h4>
                                        </div>
                                        <div class="tabInnerdiv innerTabs mt-3"
                                            *ngFor="let details of paymentDetails | filter:searchText; let i=index">
                                            <h3>{{details['CustomerName']}}</h3>
                                            <div class="fieldsdiv">
                                                <div class="row customDiv">
                                                    <div class="col-sm-3 form-group pwidth50">
                                                        <mat-label class="mtlabel" for="myInput">Proposal
                                                            Number</mat-label>
                                                        <p>{{details['ProposalNumber'] ? details['ProposalNumber'] :
                                                            details['PFProposalNo']}}</p>
                                                    </div>
                                                    <div class="col-sm-3 form-group pwidth50">
                                                        <mat-label class="mtlabel" for="myInput">PF Proposal
                                                            Number</mat-label>
                                                        <p>{{details['PFProposalNo'] ? details['PFProposalNo'] :
                                                            details['ProposalNumber']}}</p>
                                                    </div>
                                                    <div class="col-sm-3 form-group pwidth50">
                                                        <mat-label class="mtlabel" for="myInput">Proposal
                                                            Date</mat-label>
                                                        <p>{{details['TransOn']}}</p>
                                                    </div>
                                                    <div class="col-sm-3 form-group pwidth50">
                                                        <mat-label class="mtlabel" for="myInput">Start Date</mat-label>
                                                        <p>{{details['StartDate']}}</p>
                                                    </div>
                                                    <div class="col-sm-3 form-group pwidth50">
                                                        <mat-label class="mtlabel" for="myInput">End Date</mat-label>
                                                        <p>{{details['EndDate']}}</p>
                                                    </div>
                                                    <div class="col-sm-3 form-group pwidth50">
                                                        <mat-label class="mtlabel" for="myInput">Status</mat-label>
                                                        <p
                                                            [ngStyle]="{'color': details['Status'] === 'Failed Payment' ? '#E71A1A' : 'inherit'}">
                                                            {{details['Status']}}</p>
                                                    </div>
                                                    <div class="col-sm-3 form-group pwidth50"
                                                        *ngIf="details['Status'] == 'Failed Payment'">
                                                        <mat-label class="mtlabel" for="myInput">Remark</mat-label>
                                                        <p>{{details['FailedRemark']}}</p>
                                                    </div>
                                                    <div class="col-sm-3 form-group pwidth50">
                                                        <mat-label class="mtlabel" for="myInput">
                                                            {{ details['isEmiRequired'] ? 'EMI Payable Amount' :
                                                            'Payable Amount' }}
                                                        </mat-label>
                                                        <p>{{'₹' +
                                                            utilityService.convertStringtoCurrency(totalPremiumAmount(details))}}
                                                        </p>
                                                    </div>

                                                </div>

                                            </div>
                                            <mat-divider></mat-divider> -->

                                            <!-- <div class="fieldsdiv">
                                                <div class="row">
                                                    <div class="col-sm-12 mt-3 form-group customCheckslist">
                                                        <ul>
                                                            <li>
                                                                <mat-checkbox class="cstmCheckbox checkboxPending"
                                                                    *ngIf="showAddtoPay(details) && checkEMI(details)"
                                                                    [id]="details['PolicyID']" value="on"
                                                                    (change)="isChecked($event,details)">Add to
                                                                    pay</mat-checkbox>
                                                                <button
                                                                    class=" checkboxPending downloadPending recalculate ml-0"
                                                                    (click)="reCalAmount(details['PolicyID'], totalPremiumAmount(details))"
                                                                    *ngIf="details['isRecalculateRequired'] && checkEMI(details) && isHealth && details['PolicySubType'] != 25 && details['PolicySubType'] != 24 && details['PolicySubType'] !=37 && checkEMI(details)">Recalculate</button>
                                                                <button class=" checkboxPending downloadPending"
                                                                    (click)="downloadPdfFile(details)"><img
                                                                        src="../../../assets/images/download.svg"
                                                                        alt="">
                                                                    <span class="ml-2"
                                                                        style="color: #000000DE">Download</span></button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-tab>

                        </mat-tab-group>
                    </div>
                </div>
            </div>

            <div class="footer d-md-none borderStyling">
                <div class="col-sm-12 text-right">
                    <button mat-raised-button class="primary-button btn-lg btn-block" style="min-width: 140px;"
                        (click)="onSearchData()">Search</button>
                </div>
            </div>

            <div class="footer" id="notify" *ngIf="showPayNotify">
                <div class="col-sm-12 text-right pendingBtns">
                    <button mat-raised-button class="secondarybtn_2 mr-1" (click)="Pay('PayNow')"
                        style="min-width: 140px;">Pay Now <span>(
                            &#8377;
                            {{totalPremium | number:'1.0-0'}} )</span></button>
                    <button mat-raised-button class="primary-button" (click)="Pay('PaymentLink')"
                        style="min-width: 140px;" *ngIf="!hidePaymentLink">Payment Link</button>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- BBR FORMAT -->
<section class="container-fluid">
    <div class="row">
        <div class="container panaMaincontainer">
            <div class="floatdiv floatingedit" *ngIf="scrolling">
                <div class="mainCounterDiv">
                    <div class="counter" *ngIf="this.selectedRows.length > 0">
                        <span class="count">{{this.selectedRows.length}}</span>
                    </div>
                </div>
                <img class="imgScss" src="../../../../../../assets/images/BBR/ScrollToTop.svg" (click)="scrollToTop()">
            </div>

            <div class="marginbox">
                <!-- Icon and title start -->
                <div class="myTransactionTitle" *ngIf="!isSearchVisible">
                    <div class="bbrheading">
                        <span><img src="../../../../../assets/images/MyTransactionIcon/pendingpayment.svg"
                                alt=""></span>
                        <span>
                            <h3>Pending Payment</h3>
                        </span>
                    </div>
                    <div class="d-md-none headerImgSection">
                        <span (click)="openFilterBottomSheet()">
                            <img class="headerImg FilterMobile" src="{{filterImage}}" alt="">
                        </span>
                        <span (click)="openSearchBottomSheet()">
                            <img class="headerImg" src="{{editImage}}" alt="" /></span>
                        <span (click)="searchFilter()">
                            <img class="headerImg" src="../../../../../../assets/images/BBR/Search.svg" alt="" /></span>
                    </div>
                </div>
                <div class="mobilesearch__fieldBBr" *ngIf="isSearchVisible">
                    <div class="mobileinputDivbbr">
                        <input matInput type="text" class="input-group__input placeholder"
                            [cdkTrapFocusAutoCapture]="isSearchVisible" [cdkTrapFocus]="isSearchVisible"
                            [placeholder]="searchPlaceHolder" (input)="queryData()" [(ngModel)]="inputValue">
                        <span class="crossclick" (click)="closeClear()">
                            <img src="../../../../../../assets/images/BBR/Close.svg" alt="">
                        </span>
                    </div>
                </div>
                <!-- Icon and title end -->

                <!-- Desktop View (above 767px) start -->
                <div class="d-none d-md-block">
                    <!-- Input fields card start -->
                    <mat-card class="innerbox borderinnerbox">
                        <div class="row fieldsdiv" [formGroup]="pendingPaymentForm">

                            <div class="col-sm-3 form-group">
                                <mat-label class="input-group__label" for="myInput">Product Type</mat-label>
                                <mat-select class="input-group__input cstm_select" id="productType"
                                    placeholder="Product Type" formControlName="productType"
                                    (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                                    (selectionChange)="productListListner($event.value)">
                                    <ng-container *ngFor="let item of productList; let i = index; let last = last">
                                        <mat-option [value]="item.productCode">
                                            {{item.productName}}
                                        </mat-option>
                                        <mat-divider *ngIf="!last"></mat-divider>
                                    </ng-container>
                                </mat-select>
                            </div>

                            <div class="col-sm-3 form-group" *ngIf="selectList.length > 0">
                                <mat-label class="input-group__label" for="myInput">Product Name</mat-label>
                                <mat-select class="input-group__input cstm_select" id="productName"
                                    placeholder="Product Name" formControlName="productName"
                                    (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()">
                                    <ng-container *ngFor="let p of selectList;let i = index; let last = last ">
                                        <mat-option [value]="p.productCode">
                                            {{p.productName}}
                                        </mat-option>
                                        <mat-divider *ngIf="!last"></mat-divider>
                                    </ng-container>
                                </mat-select>
                            </div>

                            <div class="col-sm-3 form-group">
                                <mat-label class="input-group__label">Select Date</mat-label>
                                <mat-date-range-input class="input-group__input dob" [rangePicker]="picker"
                                    (click)="picker.open()" [min]="minMax.minDate" [max]="minMax.maxDate">
                                    <input matStartDate formControlName="startDate" placeholder="Start date" readonly>
                                    <input matEndDate formControlName="endDate" placeholder="End date" readonly>
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker touchUi></mat-date-range-picker>
                            </div>

                            <div class="col-sm-3 form-group">
                                <button class=" search-button" (click)="searchDetails()">
                                    <span>Submit</span>
                                </button>
                            </div>

                        </div>
                    </mat-card>
                    <!-- Input fields card end -->

                </div>
                <!-- Desktop View (above 767px) end -->

                <!-- Info card start -->
                <mat-card class="innerbox borderinnerbox align-items-center p-3 infoCardColor d-none d-md-flex">
                    <div class="row fieldsdiv">
                        <table>
                            <tr>
                                <td class="vertAlign">
                                    <img src="../../../../../../assets/images/BBR/Info.svg" class="infoIcon" alt="Info">
                                </td>
                                <td>
                                    We found a total of <span class="font-weight-bold">{{policyCount ? policyCount : "0"
                                        }}</span>
                                    records with your search parameters on your Pending Payment report between
                                    <span class="font-weight-bold">{{myPolicyStartDate | date: 'dd MMM, yyyy'}}</span>
                                    and
                                    <span class="font-weight-bold">{{myPolicyEndDate | date: 'dd MMM, yyyy'}}.</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </mat-card>
                <!-- Info card end -->

                <mat-card class="innerbox borderinnerbox p-2 infoCardColor d-md-none">
                    <table class="filterDatatable">
                        <tr>
                            <td [width]="30"><img src="../../../../../../assets/images/BBR/calendarRange.svg" alt="">
                            </td>
                            <td class="filterlabel">Date Range</td>
                            <td [width]="30">
                                <img src="../../../../../../assets/images/BBR/record.svg" alt="">
                            </td>
                            <td class="filterlabel">Records</td>
                            <td class="countLabel">{{policyCount ? policyCount : "0" }}</td>
                        </tr>
                        <tr>
                            <td colspan="2" class="countLabel">
                                {{myPolicyStartDate|date:'dd MMM,yyyy'}} -
                                {{myPolicyEndDate| date: 'dd MMM,yyyy'}}
                            </td>
                        </tr>
                    </table>
                </mat-card>

                <!-- Need to fix -->
                <mat-card class="innerbox borderinnerbox">
                    <div class="fieldsdiv">
                        <div class="productmainDiv">
                            <div class="productdiv">
                                <div class="align-items-center">
                                    <span>Product: <span class="font-weight-bold">{{myPolicyProduct}}</span></span>
                                </div>
                            </div>
                            <div class="filterdiv">
                                <div class="d-none d-md-block">
                                    <div class="search__fieldBBr">
                                        <div class="inputDivbbr" [ngClass]="{'searchBorder': showCancel}">
                                            <input matInput type="text" class=" placeholder"
                                                [placeholder]="searchPlaceHolder" (input)="queryData()"
                                                [(ngModel)]="inputValue" (focus)="inputFocus()" (blur)="inputBlur()">
                                            <span class="crossclick pr-2" (click)="clearInputField()">
                                                <img src="../../../../../../assets/images/BBR/desktopCross.svg" alt="">
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- filter functionality -->
                                <div>
                                    <div class="dropdown d-none d-md-flex ">
                                        <button matTooltip="Filter" class="dropdown-toggle customDropdown" type="button"
                                            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                            <div class="toggIcon">
                                                <img src="../../../../../../assets/images/BBR/Filter.svg" alt="">
                                            </div>
                                        </button>
                                        <ul class="dropdown-menu customDropItems " aria-labelledby="dropdownMenuButton">
                                            <li class="dropdownHeading">
                                                <h4>Status Type</h4>
                                            </li>
                                            <li class="dropdown-item cs_dropdown-item"
                                                *ngFor="let value of policyFilterList">
                                                <mat-checkbox [checked]="policyFilter.includes(value.toLowerCase())"
                                                    (change)="applyPolicyFilter($event.checked, value)">
                                                    {{ value }}
                                                </mat-checkbox>
                                            </li>
                                        </ul>

                                    </div>
                                </div>

                                <!-- ends here -->
                                <div class=" d-flex align-items-center">
                                    <mat-button-toggle-group class="custom-toggle-group" (change)="onToggle($event)"
                                        [value]="toggleValue">
                                        <mat-button-toggle matTooltip="Table View" class="custom_togglebtn"
                                            value="table"><img src="../../../../../../assets/images/BBR/tablelist.svg"
                                                alt=""></mat-button-toggle>
                                        <mat-button-toggle matTooltip="Card View" class="custom_togglebtn"
                                            value="card"><img src="../../../../../../assets/images/BBR/cardList.svg"
                                                alt=""></mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>

                            </div>
                        </div>
                    </div>
                </mat-card>

                <div class="footer" id="notify" *ngIf="showPayNotify">
                    <div class="col p-0 text-right pendingBtns">
                        <button mat-raised-button class="secondarybtn_2 mr-1" (click)="Pay('PayNow')"
                            style="min-width: 140px;">Pay Now <span>(
                                &#8377;
                                {{totalPremium | number:'1.0-0'}} )</span></button>
                        <button mat-raised-button class="primary-button" (click)="Pay('PaymentLink')"
                            style="min-width: 140px;" *ngIf="!hidePaymentLink">Payment Link</button>
                    </div>
                </div>

                <!-- <mat-card class="innerbox borderinnerbox">
                    No record found card
                </mat-card> -->

                <!-- Table format start -->
                <app-custom-table *ngIf="toggleValue == 'table'" [reportList]="filteredData" [buttonData]="tableBtnInfo"
                    [displayedColumns]="displayCol" (policyNo)="receiveDataAndCall($event)"
                    [tableSize]="'1440px'"></app-custom-table>
                <!-- Table format end -->

                <!-- Card format start -->
                <app-custom-card *ngIf="toggleValue == 'card'" [reportList]="filteredData" [buttonData]="cardBtnInfo"
                    [displayedColumns]="displayCol" (policyNo)="receiveDataAndCall($event)"></app-custom-card>
                <!-- Card format end -->

            </div>
        </div>
    </div>
</section>