import { Component, ElementRef, EventEmitter, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { concatMap, forkJoin, Observable, of, Subscription } from 'rxjs';
import { Constants } from 'src/app/shared/constants/constants';
import { healthMembers, healthProducts, healthProposalProductCode, patterns } from 'src/app/shared/enums/healthEnums';
import { HealthDetails, SaveEditQuoteV1Request } from 'src/app/shared/interface/transactionHealthAdvantege';
import { MasterStateResponsePayload, StateDetails } from 'src/app/shared/interface/moterMaster';
import { HealthAdvantedgeService } from 'src/app/shared/proxy-services/health-advantedge.service';
import { HealthMasterService } from 'src/app/shared/proxy-services/health-master.service';
import { RTOListService } from 'src/app/shared/proxy-services/rto-list.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HAPModal } from '../hap/hap-modal';
import { RTOListRequestPayload, RTOListResponsePayload } from 'src/app/shared/interface/rto-list';
import { HealthPlanMasterRequestPayload, HealthPlanMasterResponsePayload, RelationshipRequestPayload, RelationshipResponsePayload } from 'src/app/shared/interface/healthMaster';
import { HealthDataStorageService } from '../../health.service';
import { HealthDataStorage, PremiumDetailsData } from '../../health.modal';
import { PopupService } from 'src/app/shared/services/popup.service';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { popupType, popupHeader, popupDescriptionMsg, popupImgPath } from 'src/app/shared/enums/popupEnums';
import { MatSelect } from '@angular/material/select';
import { SessionStorageService, StorageService } from 'src/app/shared/services/storage.service';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { HealthService } from 'src/app/shared/proxy-services/health.service';
import { CustomStepperService } from 'src/app/shared/services/custom-stepper.service';
import { CustomFooterService } from 'src/app/shared/services/custom-footer.service';
import { WindowResizeService } from 'src/app/shared/services/window-resize.service';
import { ChannelData } from 'src/app/shared/interface/swapPrameters';
import { ConvertSavedQuotesResponsePayload, SavedQuote } from 'src/app/shared/interface/savedQuotes';
import { StepData } from 'src/app/shared/interface/custom-stepper';


@Component({
  selector: 'app-asp',
  templateUrl: './asp.component.html',
  styleUrls: ['./asp.component.scss']
})
export class AspComponent implements OnInit {
  @ViewChild('textCreate', { static: false }) textCreateComponent: ElementRef;
  // @Output() QuoteValueEmitter = new EventEmitter<{ Quote: boolean, Portability: boolean, StatusCode: boolean }>();
  arogyaSanjeevaniForm: FormGroup;
  formSubmitted: boolean = false;
  formReset: boolean = false;
  healthProducts = healthProducts; // health enums
  aspDataModal: HAPModal = new HAPModal(); //hap data modal 
  aspFormData: HealthDataStorage;
  aspMasterData: HealthPlanMasterResponsePayload;
  adultRelationShips = [];
  childRelationShips = [];
  adultCountData = this.aspDataModal.adultCount;
  childCountData = this.aspDataModal.childCount;
  adultData = [];
  childData = [];
  selectedAdult = [];
  selectedChild = [];
  totalAdultChildData = [];
  subscription: Subscription[] = [];
  states: StateDetails[] = [];
  sumInsuredDetails: string[] = [];
  cityData = [];
  premiumDetails: PremiumDetailsData;
  stateDetails = this.aspDataModal.stateDetails;
  invalidPinCodeMessage: string;
  transparentSpinner: boolean = false;
  stateFetched: boolean = false;
  adult2DateError: boolean = false;
  success: boolean = false;
  oneMemberRequired: boolean = false;
  multipleServiceError: boolean = false;
  refreshPopupActivated: boolean = false;
  loadFormContent: boolean = false;
  policyTenureData: string[] = [];
  tenureDuration: number = 0;
  previousAdultValue: string = '';
  previousChildValue: string = '';
  errorPopupData: PopupModal;
  reload = false;
  insuredMinMaxDate: { maxDate: Date; minDate: Date; };
  selectedOption = 'No';
  statuscode: boolean;
  // stepperCheckData: Array<StepperCheckDataQuote> = [];
  disableButton: boolean = false;
  minValue: number = 0;
  maxValue: number;
  stepValue: number;
  selectedValue: number;
  formValid: boolean = false;
  isInvalid: boolean = false;
  patchingDone: boolean = false;
  initialFormState = {};
  dealId: string = "";
  channelData: ChannelData;
  quoteDetails: ConvertSavedQuotesResponsePayload;
  quoteConvertData: SavedQuote;
  saveQuoteCounter = 0;
  steps: StepData[] = [];

  constructor(private fb: FormBuilder,
    public utilityService: UtilityService,
    private healthAdvantedgeService: HealthAdvantedgeService,
    private healthMasterService: HealthMasterService,
    private rtoListService: RTOListService,
    private snackBar: MatSnackBar,
    private popupService: PopupService,
    private healthDataStorageService: HealthDataStorageService,
    private renderer: Renderer2,
    private customStepperService: CustomStepperService,
    private customFooterService: CustomFooterService,
    private windowResizeService: WindowResizeService,
    private sessionStorageService: SessionStorageService,
    private el: ElementRef,
    private storageService: StorageService,
    private healthService: HealthService) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    // get hap form data structure
    this.onLoadSteps();
    //Stepper step validation

    this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
  }

  onLoadSteps() {
    this.premiumDetails = this.healthDataStorageService.initializePremiumData()
    this.aspFormData = this.healthDataStorageService.getHealthDataStorage();
    this.quoteDetails = this.storageService.getAESDecryptedData(userEnums.ConverToProposal);
    if (this.quoteDetails) {
      this.quoteConvertData = this.quoteDetails.Data;
      this.updateSavedQuotesConvertData();
      this.customFooterService.showPremiumDetails(true);
    }
    if (this.aspFormData.quoteDataSet) {
      this.patchingDone = false;
    }
    this.createASPForm();
    this.fetchASPDetails();
    if (!this.aspFormData.quoteDataSet && this.aspFormData.quickquoteDataSet) {
      this.patchQuickQuoteData();
    }
  }

  isSelected(event: any, fromvalue: string): boolean {
    event.preventDefault();
    event.stopPropagation();
    return fromvalue === event.value;
  }

  setAdult(adult) {
    const dob = 'dobAdult' + `${adult.memberCount}`;
    const relationship = 'adultRealtionship' + `${adult.memberCount}`;
    const vaccineStatus = 'adultVaccinationStatus' + `${adult.memberCount}`;
    const vaccineDate = 'adultVaccinationDate' + `${adult.memberCount}`;
    let newAdult = {
      [dob]: adult.dob,
      [relationship]: adult.relationship,
      [vaccineStatus]: adult.vaccineStatus,
      [vaccineDate]: adult.vaccineDate
    };
    return newAdult;
  }

  setChild(child) {
    const dob = 'dobChild' + `${child.memberCount}`;
    const relationship = 'childRelationship' + `${child.memberCount}`;

    let newChild = {
      [dob]: child.dob,
      [relationship]: child.relationship
    };

    return newChild;
  }

  updateSavedQuotesConvertData() {
    this.aspFormData.quoteDataSet = true;
    let quoteFormData = this.aspFormData.quoteFormData;
    //quoteFormData.productType = this.quoteConvertData.ProductType;
    //quoteFormData.subProductCode = this.quoteConvertData.SubProductCode;
    //quoteFormData.corelationId = this.quoteConvertData.CustomerDetails.CorelationId;
    quoteFormData.healthProposalProductType = healthProposalProductCode.asp;
    quoteFormData.productName = this.quoteConvertData.ProductName;
    quoteFormData.subProductType = this.aspDataModal.subProductType.asp;
    // quoteFormData.subProductCode = this.quoteConvertData.SubProductCode;
    quoteFormData.pincode = this.quoteConvertData.Pincode;
    quoteFormData.cityName = this.quoteConvertData.City;
    quoteFormData.cityState = this.quoteConvertData.CityState;
    quoteFormData.zone = this.quoteConvertData.Zone;
    quoteFormData.policyTenure = this.quoteConvertData.Tenure.toString();
    quoteFormData.adultDetails = this.quoteConvertData.AdultDetails.map(x => { x = this.setAdult(x); return x; });
    quoteFormData.childDetails = this.quoteConvertData.ChildDetails.map(x => { x = this.setChild(x); return x; });
    quoteFormData.applicantAnnualSum = this.quoteConvertData.Members[0].SumInsured;
    quoteFormData.adultRelationShips = this.quoteConvertData.AdultRelationShips;
    quoteFormData.childRelationShips = this.quoteConvertData.ChildRelationShips;
    this.aspFormData.premiumFormData.PfQuoteId = this.quoteConvertData.GenericData.pfQuoteId;
    quoteFormData.copaymentPercentTaken = this.quoteConvertData.VoluntaryCopaymentPercentage;
    quoteFormData.corelationId = this.quoteConvertData.CustomerDetails.CorelationId;
    this.healthDataStorageService.setHealthDataStorage(this.aspFormData);
  }

  //form construction
  createASPForm() {
    // patch annual sum insured if quote data is already present
    this.arogyaSanjeevaniForm = this.fb.group({
      productName: [healthProducts.asp],
      pincode: [this.aspFormData.quoteDataSet ? this.aspFormData.quoteFormData.pincode : '', [Validators.required, Validators.pattern(patterns.pincode), Validators.minLength(6)]],
      cityState: ['', Validators.required],
      adultDetails: this.fb.array([]),
      childDetails: this.fb.array([]),
      policyTenure: [this.aspDataModal.tenurePeriodASP[0], Validators.required],
      applicantAnnualSum: [this.aspFormData.quoteDataSet ? this.aspFormData.quoteFormData.applicantAnnualSum : '', [Validators.required]],
    })
    // Creating adult and child controls and patching existing data if present
    this.patchExistingMemberData();
    this.arogyaSanjeevaniForm.valueChanges.subscribe((data) => {
      // Below logic works when data is already patched .OR. User comes back and changes value. Only when premium is set
      // Now when user tries to change the value the stepper should reset to quote page
      // And premium should hide
      if (this.patchingDone) {
        // premium data set flag is used beacsuse user can come back and try to change the values.
        // In that case also we need to reset the stepper

        // And if user refreshes that means existing value is getting patched. In that case we are using the patchingDone flag
        // and resetting the premium footer
        if (JSON.stringify(this.arogyaSanjeevaniForm.value) != JSON.stringify(this.initialFormState)) {
          this.showPremiumDetails(false);
          // Here we are resetting the stepper. Just to be sure if the quote is changed.
          // User needs to traverse through each steps
          if (this.quoteDetails) {
            // Below block is written to identify value changes due to save quote
            if (this.saveQuoteCounter == 0) {
              this.customStepperService.saveQuoteDataChanges(true);
              this.sessionStorageService.remove(userEnums.ConverToProposal);
              this.saveQuoteCounter++;
              this.quoteDetails = null;
            }
          }
          this.customStepperService.resetStepper(true);
        }
      }
    })

    // this.arogyaSanjeevaniForm.valueChanges.subscribe((formValue) => {
    // });
  }

  showPremiumDetails(data: boolean) {
    this.customFooterService.showPremiumDetails(data);
  }

  patchExistingMemberData() {
    if (this.aspFormData.quoteDataSet) {
      if (this.aspFormData.quoteFormData.adultDetails.length > 0) {
        this.aspFormData.quoteFormData.adultDetails.forEach((data, index) => {
          this.adultDetails.push(this.createAdultDetails(index));
          // Patch values of adults
          // Below block is written because when there is self data that get's patched from kyc is not in date format
          // So to handle it we need to convert in into date type
          for (let adultKeys in data) {
            if (adultKeys.includes('dob')) {
              let dobValue = !data[adultKeys].includes('T') ? new Date(data[adultKeys]) : data[adultKeys]
              this.adultDetails.at(index).get(adultKeys).setValue(dobValue);
            }
            else {
              this.adultDetails.at(index).get(adultKeys).setValue(data[adultKeys]);
            }
          }
        })

        // We need to highlight the selected adult also
        let selectedAdultIndex = this.aspFormData.quoteFormData.adultDetails.length - 1;
        this.adultCountData.forEach((adultCount, index) => {
          if (index == selectedAdultIndex) {
            adultCount.selected = true;
          }
          else {
            adultCount.selected = false;
          }
        })
      }
      if (this.aspFormData.quoteFormData.childDetails.length > 0) {
        this.aspFormData.quoteFormData.childDetails.forEach((data, index) => {
          this.childDetails.push(this.createChildDetails(index));
          // Patch values of adults
          for (let childKeys in data) {
            if (childKeys.includes('dob')) {
              let dobValue = !data[childKeys].includes('T') ? new Date(data[childKeys]) : data[childKeys]
              this.childDetails.at(index).get(childKeys).setValue(dobValue);
            }
            else {
              this.childDetails.at(index).get(childKeys).setValue(data[childKeys]);
            }
          }
        })

        // We need to highlight the selected child also
        // If user has selected only one child then other child needs to hide that would be in case of 0 adults
        let selectedChildIndex = this.aspFormData.quoteFormData.childDetails.length - 1;
        this.childCountData.forEach((childCount, index) => {
          if (this.aspFormData.quoteFormData.adultDetails.length == 0) {
            // Disabling adult selected
            this.adultCountData[0].selected = false;
            this.adultCountData[1].selected = false;
            if (index == 0) {
              childCount.selected = true;
              childCount.hide = false;
            }
            else {
              childCount.selected = false;
              childCount.hide = true;
            }
          }
          else {
            if (index == selectedChildIndex) {
              childCount.selected = true;
              childCount.hide = false;
            }
            else {
              childCount.selected = false;
            }
          }
        })
      }

      // IPAA-176: Patch totalMemeber object for insured page Convert API
      if (this.quoteConvertData && (this.adultDetails.length > 0 || this.childDetails.length > 0)) {
        this.aspFormData.quoteFormData.totalMemberData = this.getMemberRequest();
        this.healthDataStorageService.setHealthDataStorage(this.aspFormData);
      }
    }
    else if (this.aspFormData.quickquoteDataSet) {
      this.getadultchilddataforquickquote();
    }
    else {
      this.adultDetails.push(this.createAdultDetails(0));
    }
  }

  get adultDetails(): FormArray {
    return this.arogyaSanjeevaniForm.get('adultDetails') as FormArray;
  }

  get childDetails(): FormArray {
    return this.arogyaSanjeevaniForm.get('childDetails') as FormArray;
  }

  createAdultDetails(index?): FormGroup {
    return new FormGroup({
      ['dobAdult' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['adultRealtionship' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['adultVaccinationStatus' + `${index + 1}`]: new FormControl('No', [Validators.required]),
      ['adultVaccinationDate' + `${index + 1}`]: new FormControl(''),
      ['Gender' + `${index + 1}`]: new FormControl('')
    })
  }

  createChildDetails(index?): FormGroup {
    return new FormGroup({
      ['dobChild' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['childRelationship' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['Gender' + `${index + 1}`]: new FormControl('')
    })
  }

  // on load service calls
  fetchASPDetails() {
    this.transparentSpinner = false;
    // If below solution does not work try calling api individually
    this.subscription.push(this.requestDataFromMultipleSources().subscribe({
      next: (responseList: [any, RelationshipResponsePayload]) => {
        this.multipleServiceError = false;
        this.handleMultipleResponse(responseList)

      },
      error: error => {
        this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
        this.loadFormContent = false;
        this.multipleServiceError = true;
      }
    })
    )
  }

  handleMultipleResponse(responseList: [any, RelationshipResponsePayload]) {
    if (responseList[0].StatusCode != Constants.statusCode_success || responseList[1].StatusCode != Constants.statusCode_success) {
      this.multipleServiceError = true;
    }
    else {
      this.multipleServiceError = false;
      this.manageASPMasterData(responseList[0]);
      this.manageHealthRelationShipsData(responseList[1]);
    }
  }

  requestDataFromMultipleSources(): Observable<[any, RelationshipResponsePayload]> {
    let proposalData: RelationshipRequestPayload = { product: Constants.healthProposalProductCodeAsp };
    const agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
    this.dealId = agentDetails.MappedProduct.Health.HealthASPDealId;
    const UserID: string = String(agentDetails.AgentID);
    const request: HealthPlanMasterRequestPayload = {
      UserID: UserID,
      SubType: this.aspDataModal.subProductType.asp.toString(),
      isCSC: true,
      isCHIRefileFlag: true
    }
    return this.healthMasterService.getHealthMasterPlannerV1(request).pipe(
      // concatMap operator to sequentially call getHealthAdvantagePlusMasterData
      concatMap(healthResponse => {
        // forkJoin to concurrently call the other two methods
        // The responses from these three requests are combined into an array and emitted as a single observable
        return forkJoin([
          // of(healthResponse) within the forkJoin to include the result of the first API call in the final array of responses. 
          of(healthResponse),
          this.healthMasterService.getHealthProposalRelationships(proposalData)
        ]);
      })
    );
  }

  // handles hap master data
  manageASPMasterData(aspResponse: any) {
    if (aspResponse.StatusCode == Constants.statusCode_success) {
      this.multipleServiceError = false;
      this.aspMasterData = aspResponse;
      if (this.aspMasterData.Data.Tenure_1Yr_Visible) {
        this.policyTenureData.push('1 Year')
      } else if (this.aspMasterData.Data.Tenure_2Yr_Visible) {
        this.policyTenureData.push('2 Years')
      } else if (this.aspMasterData.Data.Tenure_3Yr_Visible) {
        this.policyTenureData.push('3 Years')
      }
      for (let i = 0; i < this.aspMasterData.Data.SumInsuredDetails.length; i++) {
        const sumAmount = Math.trunc(this.aspMasterData.Data.SumInsuredDetails[i].SumAmount);
        this.sumInsuredDetails.push(String(sumAmount));
      }
      if (this.aspFormData.quoteDataSet) {
        this.arogyaSanjeevaniForm.controls['applicantAnnualSum'].setValue(this.aspFormData.quoteFormData.applicantAnnualSum);
        this.fetchDataAsPerPincode(null, this.aspFormData.quoteFormData.pincode)
      }
      this.aspFormData.insuredFormData.befitCovers = aspResponse.Data.befitCovers;
      this.aspFormData.insuredFormData.BefitApplicableCities = aspResponse.Data.BefitApplicableCities;
      // this.healthDataStorageService.setHealthDataStorage(this.aspFormData);
    }
  }


  // handles member relationships 
  manageHealthRelationShipsData(response: RelationshipResponsePayload) {
    let adultRelations = [];
    let childRelations = [];
    if (response.StatusCode == Constants.statusCode_success) {
      this.multipleServiceError = false;
      if (response.Data.InsuredRelationship.length > 0) {
        adultRelations = response.Data.InsuredRelationship.filter(x => x.KidAdultType == healthMembers.Adult);
        childRelations = response.Data.InsuredRelationship.filter(x => x.KidAdultType == healthMembers.Child);

        this.adultRelationShips = adultRelations.length > 0 ? adultRelations.sort(function (a, b) {
          return a.RelationshipName.localeCompare(b.RelationshipName)
        }) : [];
        this.childRelationShips = childRelations.length > 0 ? childRelations.sort(function (a, b) {
          return a.RelationshipName.localeCompare(b.RelationshipName)
        }) : [];
        this.loadFormContent = true;
      }
      else {
        this.adultRelationShips = [];
        this.childRelationShips = [];
        this.loadFormContent = false;
        this.multipleServiceError = true;
      }
    }
  }


  hasError(controlName: string, errorCode: string): boolean {
    return !this.arogyaSanjeevaniForm.valid && this.arogyaSanjeevaniForm.hasError(errorCode, [controlName]);
  }

  // Fetch statename according to pincode
  fetchDataAsPerPincode(event, existingPincode) {
    if (existingPincode == null) {
      this.aspFormData.quoteDataSet = false;
    }

    this.formReset = false;
    this.stateFetched = false;
    this.invalidPinCodeMessage = '';
    this.cityData = [];
    if ((existingPincode != null ? existingPincode : event.target.value.length == 6) && !this.arogyaSanjeevaniForm.controls['pincode'].hasError('pattern')) {
      let data: RTOListRequestPayload = {
        "Pincode": existingPincode != null ? existingPincode.toString() : event.target.value,
        "CityID": ""
      }
      this.transparentSpinner = true;
      ;
      this.invalidPinCodeMessage = '';
      this.arogyaSanjeevaniForm.controls['cityState'].reset();
      this.subscription.push(this.rtoListService.getStatesCityByPin(data).subscribe({
        next: (response: RTOListResponsePayload) => {
          if (response.StatusCode == Constants.statusCode_success) {
            if (response.Data.length > 0) {
              this.stateDetails.stateId = response.Data[0].StateId ? response.Data[0].StateId : '';
              this.stateDetails.stateName = response.Data[0].StateName ? response.Data[0].StateName : '';
              this.stateDetails.pincode = existingPincode != null ? existingPincode : event.target.value;
              this.stateDetails.isBefitApplicable = response.Data[0].IsBefitApplicable ? "true" : "false";
              response.Data.forEach(cityList => {
                this.cityData.push({ 'cityLabel': cityList['CityName'], 'stateLabel': this.stateDetails.stateName, 'value': cityList['CityID'] });
              });
            }
            if (this.cityData.length == 1) {
              this.stateDetails.cityName = this.cityData[0].cityLabel;
              this.stateDetails.cityId = this.cityData[0].value;
              this.arogyaSanjeevaniForm.controls['cityState'].setValue(this.stateDetails.stateName + ' - ' + this.stateDetails.cityName);
            }

            // IPAA-176: Patch City and State for Convert API
            if (this.quoteDetails && this.cityData.length > 0) {
              this.stateDetails.cityName = this.cityData[0].cityLabel;
              this.stateDetails.cityId = this.cityData[0].value;
              this.arogyaSanjeevaniForm.controls['cityState'].setValue(this.stateDetails.stateName + ' - ' + this.stateDetails.cityName);
              this.aspFormData.quoteFormData.cityId = this.stateDetails.cityId;
            }

            // Need to check below in backward patching
            if (this.aspFormData.quoteDataSet) {
              this.arogyaSanjeevaniForm.controls['cityState'].setValue(this.aspFormData.quoteFormData.cityState);
              this.changeCityName(this.aspFormData.quoteFormData.cityId, false);
            }
            this.stateFetched = true;

            if (this.aspFormData.quoteDataSet && !this.patchingDone) {
              this.patchingDone = true;
            }
          }

          else if (response.StatusCode == Constants.statusCode_failure) {
            this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
            this.stateFetched = false;
            this.invalidPinCodeMessage = response.StatusDesc;
          }

          else {
            this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
            // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
            // this.popupService.openGenericPopup(this.errorPopupData);
          }

        },
        error: error => {
          this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          this.invalidPinCodeMessage = '';
          this.stateFetched = false;
          this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
          this.popupService.openGenericPopup(this.errorPopupData);
        }
      })
      )
    }
    this.utilityService.numberOnly(event);
    this.utilityService.restrictSpace(event);
  }

  changeCityName(cityValue: string, showSpinner: boolean) {
    this.stateDetails.cityId = cityValue;
    this.stateDetails.cityName = this.cityData.length > 0 ? (this.cityData.find(data => data.value == cityValue).cityLabel) : '';
  }

  // Adult count selected
  adultCountSelected(adultValue: string, event) {
    // adult count entire logic is set on adult data present in hap modal
    // toggle is handled by selected sttribute for each object
    let index = parseInt(adultValue) - 1;
    let selectedChild = this.fetchSelectedChild();
    switch (adultValue) {

      // If adult 1 is selected then we need to set selected attribute true
      case '1': {
        if (!this.adultCountData[index].selected) {
          this.adultCountData[index].selected = true;
          this.adultCountData[index + 1].selected = false;

          // Also enable second child because we have a condition for 0A 1C only should be there
          // SO if it's hidden unhide it
          if (this.childCountData[1].hide) {
            this.childCountData[1].hide = false;
            this.childCountData[1].selected = false;
          }

          // If two adults present in form array remove second adult as only 1A needed
          if (this.adultDetails.length > 1) {
            this.adult2DateError = false;
            this.adultDetails.removeAt(this.adultDetails.length - 1);
          }

          // If no adults presents in form array as again 1A is selected then we need to push in array
          // Push one adult in form array
          else if (this.adultDetails.length == 0) {
            this.adult2DateError = false;
            this.adultDetails.push(this.createAdultDetails(0));
          }

          // If one adult is selected then make third child hidden. SO for 1A 2C can be selected and also unselect them
          this.childCountData[2].hide = true;
          this.childCountData[2].selected = false;

          // if 3C is presnt in form array then remove third child from form array
          if (this.childDetails.length == 3) {
            // If third child was selected then we need to remove all child details from form array
            if (selectedChild[0].value == '3') {
              this.childDetails.clear();
            }
            else {
              this.childDetails.removeAt(this.childDetails.length - 1);
            }
          }
        }
        // But if user tries to click again on adult 1 and if it is already selected that means if selected is true
        // then stop checkbox behaviour
        else {
          // Now if he tries to uncheck adult. We need to allow uncheck only when any of the child is selcted otherwise we won't allow
          // Uncheck to be allowed only when child is present
          if (selectedChild.length == 0) {
            event.preventDefault();
            event.stopPropagation();
            this.snackBar.open('Atleast one member is required', 'Ok', {
              duration: 2000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
            });
          }
          // If uncheck can happen set the value of that adult as false.
          else {
            // Remove all adult details
            this.adultDetails.clear();
            this.adultCountData[index].selected = false;
            // If no adult is selected then only 1 child should be visible
            this.childCountData.forEach((childData, index) => {
              childData.hide = index == 0 ? false : true;
              childData.selected = index == 0 ? true : false;
            })

            // Remove child elements if present
            if (this.childDetails.length == 2) {
              this.childDetails.removeAt(this.childDetails.length - 1);
            }

          }
        }
        break;
      }

      // If adult 2 is selected then we need to set selected attribute true
      case '2': {
        if (!this.adultCountData[index].selected) {
          this.adultCountData[index].selected = true;
          this.adultCountData[index - 1].selected = false;

          // Push one adult in form array if one adult is already present
          if (this.adultDetails.length == 1) {
            this.adult2DateError = false;
            this.adultDetails.push(this.createAdultDetails(1));
          }

          // If no adult is present then push two adults
          else if (this.adultDetails.length == 0) {
            this.adult2DateError = false;
            this.adultDetails.push(this.createAdultDetails(0));
            this.adultDetails.push(this.createAdultDetails(1));
          }

          // If two adult is selected then make one child visible. SO for 2A 3C can be selected and also unselect them
          this.childCountData[2].selected = false;
          this.childCountData[2].hide = false;

          // If first child is hidden unhide it
          if (this.childCountData[1].hide) {
            this.childCountData[1].hide = false;
            this.childCountData[1].selected = false;
          }
        }
        // But if user tries to click again on adult 2 and if it is already selected that means if selected is true
        // then stop checkbox behaviour
        else {
          // Now if he tries to uncheck adult. We need to allow uncheck only when any of the child is selcted otherwise we won't allow
          // Uncheck to be allowed only when child is present
          if (selectedChild.length == 0) {
            event.preventDefault();
            event.stopPropagation();
            this.snackBar.open('Atleast one member is required', 'Ok', {
              duration: 2000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
            });
          }
          // If user tries to uncheck 2A but he has selected 3C. Then we can't allow uncheck
          else if (selectedChild.length > 0 && selectedChild[0].value == '3') {
            event.preventDefault();
            event.stopPropagation();
            this.snackBar.open('3C can be selected on 2A only. To uncheck Please select 1A', 'Ok', {
              duration: 2000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
            });
          }
          else if (selectedChild.length > 0) {
            // Remove all adult details
            this.adultDetails.clear();
            this.adultCountData[index].selected = false;
            // If no adult is selected then only 1 child should be visible
            this.childCountData.forEach((childData, index) => {
              childData.selected = index == 0 ? true : false;
              childData.hide = index == 0 ? false : true;
            })
            // Remove child elements if present
            if (this.childDetails.length == 2) {
              this.childDetails.removeAt(this.childDetails.length - 1);
            }
          }
        }
        break;
      }
      default : {
        break;
      }
    }
  }
  // Child count selected
  childCountSelected(childValue: string, event) {
    // child count entire logic is set on child data present in hap modal
    // toggle is handled by selected attribute for each object
    let index = parseInt(childValue) - 1;
    let selectedAdult = this.fetchSelectedAdult();
    switch (childValue) {
      // If child 1 is selected then we need to set selected attribute true
      case '1': {
        if (!this.childCountData[index].selected) {
          this.childCountData[index].selected = true;

          // As it is check box and 1C is selected we need to ensure other 2C & 3C is unchecked
          this.childCountData[index + 1].selected = false;
          this.childCountData[index + 2].selected = false;

          // If 0C is present push one data in child array
          if (this.childDetails.length == 0) {
            this.childDetails.push(this.createChildDetails(0));
          }

          // If 2C is present in child details form array remove second child
          else if (this.childDetails.length == 2) {
            this.childDetails.removeAt(this.childDetails.length - 1);
          }

          // If 3C is present then remove last two child
          else if (this.childDetails.length == 3) {
            this.childDetails.removeAt(this.childDetails.length - 1);
            this.childDetails.removeAt(this.childDetails.length - 1);
          }

        }

        // And if no adult is selected then child can't be unchecked otherwise uncheck can happen
        else if (selectedAdult.length == 0) {
          event.preventDefault();
          event.stopPropagation();
          this.snackBar.open('Atleast one member is required', 'Ok', {
            duration: 2000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
          });
        }
        // if it can be unchecked set value as false of that unchecked child
        else if (selectedAdult.length > 0) {
          this.childCountData[index].selected = false;
          // clear child details
          this.childDetails.clear();
        }
        break;
      }

      // If child 2 is selected then we need to set selected attribute true
      case '2': {
        if (!this.childCountData[index].selected) {
          this.childCountData[index].selected = true;
          // As it is check box and 2C is selected we need to ensure other 1C & 3C is unchecked
          this.childCountData[index - 1].selected = false;
          this.childCountData[index + 1].selected = false;

          // If 0C is present push two data in child array
          if (this.childDetails.length == 0) {
            this.childDetails.push(this.createChildDetails(0));
            this.childDetails.push(this.createChildDetails(1));
          }

          // If 1C is present push one data in child array
          else if (this.childDetails.length == 1) {
            this.childDetails.push(this.createChildDetails(1));
          }

          // If 3C is present in child details form array remove third child
          else if (this.childDetails.length == 3) {
            this.childDetails.removeAt(this.childDetails.length - 1);
          }
        }

        // And if no adult is selected then child can't be unchecked otherwise uncheck can happen
        else if (selectedAdult.length == 0) {
          event.preventDefault();
          event.stopPropagation();
          this.snackBar.open('Atleast one member is required', 'Ok', {
            duration: 2000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
          });
        }
        // if it can be unchecked set value as false of that unchecked child
        else if (selectedAdult.length > 0) {
          this.childCountData[index].selected = false;
          // clear child details
          this.childDetails.clear();
        }
        break;
      }

      // If child 3 is selected then we need to set selected attribute true
      case '3': {
        if (!this.childCountData[index].selected) {
          this.childCountData[index].selected = true;
          // As it is check box and 3C is selected we need to ensure other 1C & 2C is unchecked
          this.childCountData[index - 2].selected = false;
          this.childCountData[index - 1].selected = false;

          // If 0C is present then push 3 child data in form array
          if (this.childDetails.length == 0) {
            this.childDetails.push(this.createChildDetails(0));
            this.childDetails.push(this.createChildDetails(1));
            this.childDetails.push(this.createChildDetails(2));
          }
          // If 1C is present push two data in child array
          else if (this.childDetails.length == 1) {
            this.childDetails.push(this.createChildDetails(1));
            this.childDetails.push(this.createChildDetails(2));
          }
          // If 2C is present push one data in child array
          else if (this.childDetails.length == 2) {
            this.childDetails.push(this.createChildDetails(2));
          }
        }
        // But if user tries to click again on child 3 and if it is already selected that means if selected is true
        // then stop checkbox behaviour
        //In this case 2A is required and if it is checked it can't be unchecked
        else {
          event.preventDefault();
          event.stopPropagation();
          this.snackBar.open('3C can be selected on 2A only. To uncheck Please select 1A', 'Ok', {
            duration: 2000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
          });
        }
        break;
      }
      default : {
        break;
      }
    }
  }

  fetchSelectedAdult() {
    return this.adultCountData.filter(element => element.selected);
  }

  fetchSelectedChild() {
    return this.childCountData.filter(element => element.selected);
  }

  adultsDateComparison() {
    if (this.adultDetails.length == 2) {
      if (this.adultDetails.value[1]['dobAdult2'] != '' && this.utilityService.dateDiff(this.adultDetails.value[1]['dobAdult2'], this.adultDetails.value[0]['dobAdult1'])) {
        this.adult2DateError = true;
      }
      else {
        this.adult2DateError = false;
      }
    }
    else {
      this.adult2DateError = false;
    }
  }

  getMinMaxDate(type: string, minMaxType: string) {
    let noOfAdult = this.adultDetails.length;
    let minYear = type == healthMembers.Child ? 26 : 66;
    let maxYear = type != healthMembers.Child ? 18 : type == healthMembers.Child && noOfAdult == 0 ? 6 : 0;
    let maxMonth = type == healthMembers.Child && noOfAdult != 0 ? 3 : '';
    let minMonth = type == healthMembers.Child && noOfAdult != 0 ? 9 : '';
    let insuredMaxYear = (type != healthMembers.Child) || (type == healthMembers.Child && noOfAdult == 0) ? new Date().getFullYear() - maxYear : new Date().getFullYear();
    let insuredMinYear = new Date().getFullYear() - minYear;
    this.insuredMinMaxDate = this.utilityService.getMinMaxDate(insuredMaxYear, insuredMinYear, maxMonth, minMonth);
    return minMaxType == 'min' ? this.insuredMinMaxDate.minDate : this.insuredMinMaxDate.maxDate;
  }

  getInsuredMinMaxDate(memberType: string, minMaxType: string) {
    if (memberType == 'Adult') {
      let adultMaxDate = this.utilityService.subtractYears(new Date(), 21);

      let yearsdate = this.utilityService.subtractYears(new Date(), 65);
      let monthdate = this.utilityService.subtractMonths(yearsdate, 11);
      let adultMinDate = this.utilityService.subtractDays(monthdate, 25);

      return minMaxType == 'min' ? adultMinDate : adultMaxDate;
    }
    else {
      let noOfAdults = this.adultDetails.length;
      if (noOfAdults > 0) {
        //depedent child
        let depChildMaxDate = this.utilityService.subtractMonths(new Date(), 3);

        let yearDate = this.utilityService.subtractYears(new Date(), 20);
        let monthDate = this.utilityService.subtractMonths(yearDate, 11);
        let depChildMinDate = this.utilityService.subtractDays(monthDate, 25);

        return minMaxType == 'min' ? depChildMinDate : depChildMaxDate;
      }
      else {
        //independent child
        let indChildMaxDate = this.utilityService.subtractYears(new Date(), 6);

        let yearDate = this.utilityService.subtractYears(new Date(), 20);
        let monthDate = this.utilityService.subtractMonths(yearDate, 11);
        let indChildMinDate = this.utilityService.subtractDays(monthDate, 25);

        return minMaxType == 'min' ? indChildMinDate : indChildMaxDate;
      }
    }
  }

  getQuote() {
    // this.stepperCheckData = [];
    // let objstepperCheckData: StepperCheckDataQuote = new StepperCheckDataQuote();
    this.formReset = false;
    this.transparentSpinner = false;
    this.success = false;
    this.formSubmitted = true;
    this.arogyaSanjeevaniForm.markAllAsTouched();

    //self case validation for more than 1 adult/child
    if (this.adultDetails.length > 0 || this.childDetails.length > 0) {
      let adultRelationshipArrCount = 0;
      let childRelationshipArrCount = 0;
      if (this.adultDetails.value.length > 0) {
        this.adultDetails.value.map((ele, index) => {
          if (ele[`adultRealtionship${index + 1}`] == 'SELF') {

            adultRelationshipArrCount++;
          }
        })
      }

      if (this.childDetails.value.length > 0) {
        this.childDetails.value.map((ele, index) => {
          if (ele[`childRelationship${index + 1}`] == 'SELF') {
            childRelationshipArrCount++;
          }
        })
      }

      if ((adultRelationshipArrCount > 1 || childRelationshipArrCount > 1) || (adultRelationshipArrCount > 0 && childRelationshipArrCount > 0)) {
        this.errorPopupData = this.popupService.fetchErrorPopupMsg('SELF can be selected only for one Insured');
        this.popupService.openGenericPopup(this.errorPopupData);
        return;
      }
    }

    // If child details length is only 1 . we need to check dob present in child is valid or not
    if (this.childDetails.length == 1 && this.adultDetails.length == 0) {
      // Read Dob value if it's invalid then reset
      if (this.childDetails.at(0).get('dobChild1').status == 'INVALID') {
        this.childDetails.at(0).get('dobChild1').reset();
        this.childDetails.at(0).get('dobChild1').setValue('');
        this.childDetails.at(0).get('dobChild1').setValidators(Validators.required);
        this.childDetails.at(0).get('dobChild1').updateValueAndValidity();
      }
    }
    if (!this.arogyaSanjeevaniForm.valid || this.adult2DateError || this.invalidPinCodeMessage !== '') {
      for (const key of Object.keys(this.arogyaSanjeevaniForm.controls)) {
        if (this.arogyaSanjeevaniForm.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('#' + key + '.ng-invalid');
          invalidControl.scrollIntoView({ behaviour: "instant", block: "center", inline: "center" });
          invalidControl.focus();
          break;
        }
      }
      this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
    }

    else if (this.invalidPinCodeMessage == '') {

      this.formSubmitted = false;
      let request: SaveEditQuoteV1Request = this.constructRequest();
      this.subscription.push(this.healthService.saveEditQuoteData(request).subscribe({
        next: response => {
          if (response.StatusCode == Constants.statusCode_success) {
            if (response.Data.HealthDetails.CorrelationId) {
              this.aspFormData.quoteFormData.corelationId = response.Data.HealthDetails.CorrelationId;
              this.healthDataStorageService.setHealthDataStorage(this.aspFormData);
            }
            // objstepperCheckData.isSuccess = true;
            this.statuscode = true
            //this.QuoteValueEmitter.emit({ Quote: false, Portability: false, StatusCode: true });
            this.setaspFormData(response.Data.HealthDetails);
            this.disableButton = true;
            this.success = true;
            this.patchingDone = true;
            this.initialFormState = this.arogyaSanjeevaniForm.value;

            // If premium exists then only allow user to proceed forward
            if (!this.utilityService.isEmptyOrNull(this.aspFormData.premiumFormData.totalPremium) && this.aspFormData.premiumFormData.totalPremium != '0') {
              this.customStepperService.emitStepData({ status: true, buttonId: 'f1' });
            }
            else {
              this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
            }
          }
          this.showPremiumDetails(true);
          // else if (response.StatusCode != Constants.statusCode_success) {
          //   // objstepperCheckData.isSuccess = false;

          //   this.success = false;
          //   this.disableButton = false;
          //   // this.errorPopupData = this.popupService.fetchErrorPopupMsg(response.StatusDesc);
          //   // this.popupService.openGenericPopup(this.errorPopupData);
          //   this.statuscode = false;
          //   this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          // }
          // this.stepperCheckData.push(objstepperCheckData);
        },
        error: (err) => {
          this.success = false;
          this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
          this.popupService.openGenericPopup(this.errorPopupData);
        }
      }))
    }
  }

  constructRequest(): SaveEditQuoteV1Request {
    let tenure = (this.arogyaSanjeevaniForm.value.policyTenure).split(' ')[0];
    this.tenureDuration = parseInt(tenure);
    let adult1DOB = this.adultDetails.value.length > 0 ? this.utilityService.formatDate(this.adultDetails.value[0]['dobAdult1']) : '';
    let child1DOB = this.childDetails.value.length > 0 ? this.utilityService.formatDate(this.childDetails.value[0]['dobChild1']) : '';
    let requestData: SaveEditQuoteV1Request = {
      "ProductType": 'ASP',
      "UserType": this.utilityService.isEmptyOrNull(this.channelData) ? this.aspDataModal.agent : this.channelData.UT,
      "NoOfAdults": (this.adultDetails.length).toString(),
      "NoOfKids": (this.childDetails.length).toString(),
      "NoOfInLaws": null,
      "AgeGroup": null,
      "SubProductType": this.aspDataModal.subProductType.asp,
      "SubLimit": null,
      "Tenure": this.tenureDuration,
      "IsJammuKashmir": this.checkIsGSTRegistered(),
      "VolDedutible": this.aspDataModal.volDedutible,
      "GSTStateCode": this.stateDetails.stateId,
      "SumInsured": this.arogyaSanjeevaniForm.value.applicantAnnualSum,
      "GSTStateName": this.stateDetails.stateName ? this.stateDetails.stateName : '',
      "isGSTRegistered": this.checkIsGSTRegistered(),
      "CalculateHigherSI": false,
      "SaveQuote": false,//let's send it true in premium we are setting true
      "Members": this.getMemberRequest(),
      "IpAddress": this.utilityService.isEmptyOrNull(this.channelData.CIP) ? this.aspDataModal.ipAddress : this.channelData.CIP,
      "DobOfEldestMember": this.adultDetails.value.length == 0 ? child1DOB : adult1DOB
    }
    Object.assign(requestData);
    return requestData;

  }

  // Quote request formation 
  checkIsGSTRegistered() {
    return this.stateDetails.stateId != this.aspDataModal.stateId ? false : true;
  }

  getMemberRequest() {
    let members = [];
    if (this.adultDetails.length > 0) {
      this.adultDetails.value.forEach((element, i) => {
        this.adultDetails.value[i]['Gender' + `${i + 1}`] = this.utilityService.setGender(element['adultRealtionship' + (i + 1)], this.adultRelationShips);
        members.push(
          {
            DOB: this.utilityService.formatDate(element['dobAdult' + (i + 1)]),
            MemberType: healthMembers.Adult,
            RelationshipwithApplicant: element['adultRealtionship' + (i + 1)],
            Gender: this.utilityService.setGender(element['adultRealtionship' + (i + 1)], this.adultRelationShips)
          })
      });
    }
    if (this.childDetails.length > 0) {
      this.childDetails.value.forEach((element, j) => {
        this.childDetails.value[j]['Gender' + `${j + 1}`] = this.utilityService.setGender(element['childRelationship' + (j + 1)], this.childRelationShips);
        members.push(
          {
            DOB: this.utilityService.formatDate(element['dobChild' + (j + 1)]),
            MemberType: healthMembers.Child,
            RelationshipwithApplicant: element['childRelationship' + (j + 1)],
            Gender: this.utilityService.setGender(element['childRelationship' + (j + 1)], this.childRelationShips)
          })
      });
    }

    return members;
  }

  setaspFormData(premiumData: HealthDetails) {
    let resetFlag: boolean = this.checkMemberDetails();
    this.aspFormData.quoteFormData.productName = this.arogyaSanjeevaniForm.controls['productName'].value;
    this.aspFormData.quoteFormData.productType = '';
    this.aspFormData.quoteFormData.pincode = this.arogyaSanjeevaniForm.controls['pincode'].value;
    this.aspFormData.quoteFormData.adultDetails = this.adultDetails.value;
    this.aspFormData.quoteFormData.childDetails = this.childDetails.value;
    this.aspFormData.quoteFormData.cityId = this.stateDetails.cityId;
    this.aspFormData.quoteFormData.cityName = this.stateDetails.cityName;
    this.aspFormData.quoteFormData.stateId = this.stateDetails.stateId;
    this.aspFormData.quoteFormData.isBefitApplicable = this.stateDetails.isBefitApplicable;
    this.aspFormData.quoteFormData.totalMemberData = this.getMemberRequest();
    this.aspFormData.quoteFormData.policyTenure = this.arogyaSanjeevaniForm.controls['policyTenure'].value;
    this.aspFormData.quoteFormData.applicantAnnualSum = this.arogyaSanjeevaniForm.controls['applicantAnnualSum'].value;
    this.aspFormData.quoteFormData.pedWaitingPeriod = '';
    this.aspFormData.quoteFormData.conditionWaitingPeriod = '';
    this.aspFormData.quoteFormData.preHospitalDuration = '';
    this.aspFormData.quoteFormData.postHospitalDuration = '';
    this.aspFormData.quoteFormData.cityState = this.arogyaSanjeevaniForm.controls['cityState'].value;
    this.aspFormData.quoteFormData.applicantAnnualIncome = '';
    this.aspFormData.quoteFormData.softCopyDiscount = 'true';
    this.aspFormData.quoteFormData.pedWaitingPeriod = '';
    this.aspFormData.quoteFormData.copaymentPercentTaken = '';
    this.aspFormData.quoteFormData.underWritterApproved = 'false';
    this.aspFormData.quoteFormData.InstallmentApplicable = 'No';
    this.aspFormData.quoteFormData.adultRelationShips = this.adultRelationShips;
    this.aspFormData.quoteFormData.childRelationShips = this.childRelationShips;
    this.aspFormData.quoteFormData.subProductType = this.aspDataModal.subProductType.asp;
    this.aspFormData.quoteFormData.healthProposalProductType = healthProposalProductCode.asp;
    this.aspFormData.policyDetails.DealId = this.dealId;

    //reset data for quickquote
    this.aspFormData.quickquoteData.pincode = '';
    this.aspFormData.quickquoteData.cityState = '';
    this.aspFormData.quickquoteData.adultDetails = [];
    this.aspFormData.quickquoteData.childDetails = [];
    this.aspFormData.quickquoteData.policyTenure = '';
    this.aspFormData.quickquoteData.applicantAnnualSum = '';
    this.aspFormData.quoteDataSet = true;
    if (resetFlag) {
      this.setOtherFormFlags();
    }
    this.constructPremiumData(premiumData);
    this.healthDataStorageService.setHealthDataStorage(this.aspFormData);
    //this.QuoteValueEmitter.emit({ Quote: this.formValid, Portability: false, StatusCode: false })
  }

  checkMemberDetails(): boolean {
    let nonEqualMembers = [];
    let resetFlag: boolean = false;
    // Adult Details
    // If new adult details and child details length are same then we need to check the info
    if (this.adultDetails.length == this.aspFormData.quoteFormData.adultDetails.length) {
      let existingAdultDetails = this.aspFormData.quoteFormData.adultDetails;
      existingAdultDetails.forEach((adultData, index) => {
        let tempAdultDetails = adultData;
        // When there is self detected there is change in format of dob.
        if (this.aspFormData.kycStatus.ovdSelf || this.aspFormData.kycStatus.normalSelf) {
          // convert dob in respected format
          for (let keys in tempAdultDetails) {
            // There are chances date is patched along with orignal time stamp
            if (keys.includes('dob') && !tempAdultDetails[keys].includes('T')) {
              tempAdultDetails[keys] = (new Date(tempAdultDetails[keys]));
            }
          }
        }
        // Looping through keys
        if (JSON.stringify(tempAdultDetails) != JSON.stringify(this.adultDetails.value[index])) {
          nonEqualMembers.push(index);
        }
      });
    }
    // If the length is only not equal that means adult data is been modified
    else if (this.adultDetails.length != this.aspFormData.quoteFormData.adultDetails.length) {
      nonEqualMembers.push(0);
    }

    // Child Details

    if (this.childDetails.length == this.aspFormData.quoteFormData.childDetails.length) {
      let existingChildDetails = this.aspFormData.quoteFormData.childDetails;
      existingChildDetails.forEach((childData, index) => {
        // Looping through keys
        if (JSON.stringify(childData) != JSON.stringify(this.childDetails.value[index])) {
          nonEqualMembers.push(index);
        }
      });
    }

    // If the length is only not equal that means adult data is been modified
    else if (this.childDetails.length != this.aspFormData.quoteFormData.childDetails.length) {
      nonEqualMembers.push(0);
    }

    else {
      // Adult members found to be equal
      nonEqualMembers = [];
    }

    // If there is mismatch of data in terms of length or details
    if (nonEqualMembers.length > 0) {
      resetFlag = true;
    }
    else {
      resetFlag = false;
    }
    return resetFlag;
  }

  setOtherFormFlags() {
    this.aspFormData.insuredDataSet = false;
    this.aspFormData.kycDataSet = false;
    this.aspFormData.kycStatus.ekycMandatory = false;
    this.aspFormData.kycStatus.normal = false;
    this.aspFormData.kycStatus.normalSelf = false;
    this.aspFormData.kycStatus.ovd = false;
    this.aspFormData.kycStatus.ovdSelf = false;
    this.aspFormData.kycStatus.isKYCDoneSuccessfully = false;
    this.aspFormData.kycStatus.idType = false;
    this.aspFormData.kycStatus.certNumber = '';
    this.aspFormData.kycStatus.isPilotUser = false;
    this.aspFormData.kycFormData = this.healthDataStorageService.resetKYCdata();
  }

  constructPremiumData(response: HealthDetails) {
    this.aspFormData.premiumFormData.totalPremium = response.TotalPremium;
    this.aspFormData.premiumFormData.basicPremium = response.BasicPremium;
    this.aspFormData.premiumFormData.totalTax = response.TotalTax;
    this.aspFormData.premiumFormData.tenure = this.tenureDuration;
    this.aspFormData.premiumFormData.QuoteId = response.QuoteId;
    this.aspFormData.premiumFormData.SubProductCode = response.SubProductCode;
    this.aspFormData.premiumFormData.PfQuoteId = response.PFQuoteID;
    this.aspFormData.premiumFormData.InstallmentNetPremium = response.InstallmentNetPremium;
    this.aspFormData.premiumFormData.InstallmentTaxAmount = response.InstallmentTaxAmount;
    this.aspFormData.premiumFormData.InstallmentTotalPremium = response.InstallmentTotalPremium;
    this.aspFormData.premiumDataSet = true;
    this.formValid = true;
  }

  getadultchilddataforquickquote() {
    if (this.aspFormData.quickquoteData.adultDetails.length > 0) {
      this.aspFormData.quickquoteData.adultDetails.forEach((data, index) => {
        this.adultDetails.push(this.createAdultDetails(index));
        // Patch values of adults
        // Below block is written because when there is self data that get's patched from kyc is not in date format
        // So to handle it we need to convert in into date type
        for (let adultKeys in data) {
          if (adultKeys.includes('dob')) {
            let dobValue = !data[adultKeys].includes('T') ? new Date(data[adultKeys]) : data[adultKeys]
            this.adultDetails.at(index).get(adultKeys).setValue(dobValue);
          }
          else {
            this.adultDetails.at(index).get(adultKeys).setValue(data[adultKeys]);
          }
        }
      })

      // We need to highlight the selected adult also
      let selectedAdultIndex = this.aspFormData.quickquoteData.adultDetails.length - 1;
      this.adultCountData.forEach((adultCount, index) => {
        if (index == selectedAdultIndex) {
          adultCount.selected = true;
        }
        else {
          adultCount.selected = false;
        }
      })
    }
    if (this.aspFormData.quickquoteData.childDetails.length > 0) {
      this.aspFormData.quickquoteData.childDetails.forEach((data, index) => {
        this.childDetails.push(this.createChildDetails(index));
        // Patch values of adults
        for (let childKeys in data) {
          if (childKeys.includes('dob')) {
            let dobValue = !data[childKeys].includes('T') ? new Date(data[childKeys]) : data[childKeys]
            this.childDetails.at(index).get(childKeys).setValue(dobValue);
          }
          else {
            this.childDetails.at(index).get(childKeys).setValue(data[childKeys]);
          }
        }
      })

      // We need to highlight the selected child also
      // If user has selected only one child then other child needs to hide that would be in case of 0 adults
      let selectedChildIndex = this.aspFormData.quickquoteData.childDetails.length - 1;
      this.childCountData.forEach((childCount, index) => {
        if (this.aspFormData.quickquoteData.adultDetails.length == 0) {
          // Disabling adult selected
          this.adultCountData[0].selected = false;
          this.adultCountData[1].selected = false;
          if (index == 0) {
            childCount.selected = true;
            childCount.hide = false;
          }
          else {
            childCount.selected = false;
            childCount.hide = true;
          }
        }
        else {
          if (index == selectedChildIndex) {
            childCount.selected = true;
            childCount.hide = false;
          }
          else {
            childCount.selected = false;
          }
        }
      })
    }
  }

  patchQuickQuoteData() {
    this.healthDataStorageService.getHealthDataStorage();
    this.arogyaSanjeevaniForm.patchValue({
      pincode: this.aspFormData.quickquoteData.pincode,
      cityState: this.aspFormData.quickquoteData.cityState,
      // adultDetails: this.aspFormData.quickquoteData.adultDetails,
      childDetails: this.aspFormData.quickquoteData.childDetails,
      policyTenure: this.aspFormData.quickquoteData.policyTenure ? this.aspFormData.quickquoteData.policyTenure : this.aspDataModal.tenurePeriod[0],
      applicantAnnualSum: this.aspFormData.quickquoteData.applicantAnnualSum
    })
    this.fetchDataAsPerPincode('', this.aspFormData.quickquoteData.pincode);
    // this.stateFetched = true;
  }

  resetForm() {
    this.formReset = true;
    this.success = false;
    this.formSubmitted = false;
    this.resetFormData();
  }

  resetFormData() {
    this.arogyaSanjeevaniForm.controls['pincode'].reset();
    this.arogyaSanjeevaniForm.controls['applicantAnnualSum'].reset();
    this.arogyaSanjeevaniForm.controls['state'].reset();
    this.arogyaSanjeevaniForm.controls['city'].reset();
    this.adultData = [];
    this.childData = [];
  }

  unsubscribeSubscription() {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

  onRefresh(status) {
    if (status) {
      this.fetchASPDetails();
    }
  }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  ngOnDestroy() {
    this.unsubscribeSubscription();
  }

}