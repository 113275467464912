import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class RenewalDataEmitService {

    //max product type emit for addon changes 
    private maxProductType = new Subject<any>();
    maxProductType$ = this.maxProductType.asObservable();

    //data emit
    private renewalData = new Subject<Object>();
    renewalData$ = this.renewalData.asObservable();
    //addon data emit
    private addonData = new Subject<Object>();
    addonData$ = this.addonData.asObservable();

    //vd Addon data emit
    private vdAddOnData = new Subject<any>();
    vdAddOnData$ = this.vdAddOnData.asObservable();

    //insured form value change data emit
    private insuredFormValueChange = new Subject<any>();
    insuredFormValueChange$ = this.insuredFormValueChange.asObservable();

    //insured form valid
    private insuredFormValid = new Subject<boolean>();
    insuredFormValid$ = this.insuredFormValid.asObservable();

    // Addon data emission for Vd
    private hapAddon = new Subject<boolean>();
    hapAddon$ = this.hapAddon.asObservable();

    // Addon data emission for hep addon
    private hepAddon = new Subject<any>();
    hepAddon$ = this.hepAddon.asObservable();

    // occupation emission for hep addon
    private occupation = new Subject<any>();
    occupation$ = this.occupation.asObservable();

    //on submit click on proposal page event
    private onSubmitClickEvent = new Subject<boolean>();
    onSubmitClickEvent$ = this.onSubmitClickEvent.asObservable();

    private isBefitApplicableEvent = new Subject<boolean>();
    isBefitApplicableEvent$ = this.isBefitApplicableEvent.asObservable();

    // Voluntary value data emission
    // private gsVoluntaryCoPay = new Subject<{'status': boolean, 'data': string}>();
    // gsVoluntaryCoPay$ = this.gsVoluntaryCoPay.asObservable();

    private gsCopayVisiblity = new Subject<boolean>();
    gsCopayVisiblity$ = this.gsCopayVisiblity.asObservable();

    befitApplicableData(data) {
        this.isBefitApplicableEvent.next(data);
    }

    emitRenewalData(data: boolean) {
        this.renewalData.next(data);
    }

    emitInsuredAddonData(data) {
        this.addonData.next(data);
    }

    emitVDValueInsuredAddonData(data) {
        this.vdAddOnData.next(data);
    }

    emitInsuredFormValueChange(data) {
        this.insuredFormValueChange.next(data);
    }

    emitHapData(data) {
        this.hapAddon.next(data);
    }
    emitHepAddonData(data) {
        this.hepAddon.next(data);
    }

    emitGSCopayVisiblity(data: boolean) {
        this.gsCopayVisiblity.next(data);
    }
    emitOnSubmitClickEvent(data: boolean) {
        this.onSubmitClickEvent.next(data);
    }
    emitMaxProductType(data: any) {
        this.maxProductType.next(data);
    }

    emitOccupationData(data) {
        this.occupation.next(data);
    }
    
    emitinsuredFormValid(boolean) {
        this.insuredFormValid.next(boolean);
    }
}  
