import { Component, ElementRef, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RenewalDataStorage } from '../renewal-storage.modal';
import { Subscription } from 'rxjs';
import { RTOListService } from 'src/app/shared/proxy-services/rto-list.service';
import { RTOListRequestPayload, RTOListResponsePayload } from 'src/app/shared/interface/rto-list';
import { Constants } from 'src/app/shared/constants/constants';
import { HealthAilmentRequestPayload, InsuredRelationship, Relationship, RelationshipRequestPayload } from 'src/app/shared/interface/healthMaster';
import { HealthMasterService } from 'src/app/shared/proxy-services/health-master.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { GSZone, HAPRefilingZone, HAPZone, HEPZone, healthMembers, healthToggle, patterns } from 'src/app/shared/enums/healthEnums';
import { RenewalService } from 'src/app/shared/proxy-services/renewal-api.service';
import { GetAddOnListRequestPayload, GetAddOnListResponsePayload, GetHEPAddOnListRequestPayload, GetHEPAddOnListResponsePayload, GetRenewalRecalculateRequestPayload, NewAddOnCoverDetails } from 'src/app/shared/interface/renewal';
import { Router } from '@angular/router';
import { RenewalDataStorageService } from '../renewal.service';
import { RenewalDataEmitService } from 'src/app/shared/services/renewal-emit-service';
import { MatSelect } from '@angular/material/select';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { PopupService } from 'src/app/shared/services/popup.service';
import { HealthAdvantedgeService } from 'src/app/shared/proxy-services/health-advantedge.service';
import { ZoneRequestPayload, ZoneResponsePayload } from 'src/app/shared/interface/health-advantedge';
import { StorageService } from 'src/app/shared/services/storage.service';
import { RenewalInsuredDetailsComponent } from '../renewal-insured-details/renewal-insured-details.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RenewalModal } from '../renewal-modal';

@Component({
  selector: 'app-renewal-proposal',
  templateUrl: './renewal-proposal.component.html',
  styleUrls: ['./renewal-proposal.component.scss']
})
export class RenewalProposalComponent {
  @ViewChild("PermanentDetails") PermanentDetails1: ElementRef;
  BasicDetailsForm: FormGroup;
  memberForm: FormGroup;
  PermanentDetailsForm: FormGroup;
  correspondenceDetailsForm: FormGroup;
  OtherDetailsForm: FormGroup;
  NomineeDetailsForm: FormGroup;
  AppointeeDetailsForm: FormGroup;
  insuredDetailsForm: FormGroup;
  befitForm: FormGroup;
  renewalDataModal: RenewalModal = new RenewalModal();
  errorPopupData: PopupModal;
  subscription: Subscription[] = [];
  correspondenceCityData = [];
  permanentCityData = [];
  nomineeMaxDate: Date;
  nomineeMinDate: Date;
  showAppointee: boolean = false;
  childPresent: boolean = true;
  loadInsured: boolean = false;
  appointeeMinDate: Date;
  appointeeMaxDate: Date;
  childValue: number = 0;
  adultValue: number = 0;
  totalMemberInfo = [];
  adultTotalMemberInfo = [];
  childTotalMemberInfo = [];
  addonList: NewAddOnCoverDetails[];
  occupation: [{ [key: string]: string; }];
  occupationList: any[] = [];
  isSubmitted: boolean = false;
  hideGsCoPay: boolean = false;
  nomineeRelationshipDetails: Relationship[];
  apointeeRelationShipDetails: Relationship[];
  insuredRelationshipDetails: InsuredRelationship[];
  renewalData: RenewalDataStorage;
  multiPlanDetails = [];
  copay = []
  @ViewChild(RenewalInsuredDetailsComponent) renewalInsuredComponent: RenewalInsuredDetailsComponent;
  showBefit: boolean = false;
  customerType: string = '';
  annualIncome: string = '';
  maritalStatus: string = '';
  isResponse: boolean = false;

  constructor(private fb: FormBuilder, private rtoListService: RTOListService, private healthMasterService: HealthMasterService,
    public utilityService: UtilityService, private renewal_emit_service: RenewalDataEmitService,
    private renewal_service: RenewalService, private renewalStorageService: RenewalDataStorageService,
    private router: Router, private el: ElementRef,
    private renderer: Renderer2, private hapService: HealthAdvantedgeService, private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.renewalData = this.renewalStorageService.getRenewalDataStorage();
    this.renewalStorageService.setRenewalDataStorage(this.renewalData);
    this.patchInitialData();
    this.getHealthAilment();
    if (this.renewalData.SubProductType != 0 && !this.renewalData.proposalRenewal) {
      this.getAddonList(this.renewalData.SumInsured, this.renewalData.customerDetails.MAILINGPINCODE);
    } else {
      this.addonList = this.renewalData.addonList;
      this.loadInsured = true;
    }
    // this.manageRelationShip();
    this.getNomineeMinMaxDate();
    this.getAppointeeMinMaxDate();
    // If modification co pay is selected then we need to patch co pay data into it
    this.renewal_emit_service.gsCopayVisiblity$.subscribe(data => {
      // Applicable only for gd product
      if (this.renewalData.SubProductType == 40) {
        this.hideGsCoPay = data ? true : false;
      }
    })

    if (this.renewalData.SubProductType == 20) {
      this.renewalData.VoluntaryDeductible = this.renewalData.SumInsuredDetails.find(detail => detail.SumAmount === this.renewalData.SumInsured).VDValues[0].value
    }
  }

  patchInitialData() {
    if (this.renewalData.SubProductType == 33 || this.renewalData.SubProductType == 32 || this.renewalData.SubProductType == 31 || this.renewalData.SubProductType == 30) {
      //HEP
      this.renewalData.CoPay = [0, 5, 10, 15, 20];
    }
    if (this.renewalData.SubProductType == 42) {
      //HAAP
      this.renewalData.CoPay = [0, 10, 20];
    }
    let si = this.renewalData.SumInsured.toString();
    let tempCoPay: number = 0;
    tempCoPay = typeof (this.renewalData.VoluntaryCopay) == 'string' ? parseInt(this.renewalData.VoluntaryCopay) : this.renewalData.VoluntaryCopay;

    let isPanNumberVisible = this.renewalData.TotalPremium > this.renewalDataModal.totalPremiumLimitForPanNO;
    // let fields = {
    //   productName: [this.utilityService.isEmptyOrNull(this.renewalData.ProductName) ? '' : this.renewalData.ProductName],
    //   hospitalizationCoverSI: [this.utilityService.isEmptyOrNull(si) ? '0' : si],
    //   cumulativeBonus: [this.renewalData.NewASI],
    //   copaymentPercentTaken: [this.utilityService.isEmptyOrNull(this.renewalData?.CoPay) ? '0' : this.renewalData?.CoPay[this.renewalData.CoPay.indexOf((tempCoPay))].toString()],
    //   voluntaryDeductible: [this.renewalData.VoluntaryDeductible],
    //   applicantFullName: [this.renewalData.customerDetails.CustomerName],
    //   applicantDOB: [this.renewalData.customerDetails.DOB],
    //   applicantAnnualIncome: [this.utilityService.isEmptyOrNull(this.renewalData.customerDetails.ANNUALINCOME) || this.renewalData.customerDetails.ANNUALINCOME == '0' ? '' : this.renewalData.customerDetails.ANNUALINCOME != 'SELECT' ? this.renewalData.customerDetails.ANNUALINCOME : "", Validators.required],
    //   additionalSum: [this.renewalData.NewASI],
    //   isPanNumberVisible: [isPanNumberVisible],
    //   panNumber: [this.renewalData.customerDetails.PAN]
    // }
    // this.annualIncome = this.utilityService.isEmptyOrNull(this.renewalData.customerDetails.ANNUALINCOME) || this.renewalData.customerDetails.ANNUALINCOME == '0' ? '' : this.renewalData.customerDetails.ANNUALINCOME != 'SELECT' ? this.renewalData.customerDetails.ANNUALINCOME : "";

    // if (this.renewalData.customerDetails.CustomerType == 'I') {
    //   let dobData = {
    //     applicantDOB: [this.renewalData.customerDetails.DOB]
    //   }
    //   Object.assign(fields, dobData);
    // }
    this.nomineeRelationshipDetails = this.renewalData.NomineeAppointeeRelationList;
    this.insuredRelationshipDetails = this.renewalData.InsuredRelationList;
    this.apointeeRelationShipDetails = this.renewalData.NomineeAppointeeRelationList;
    this.BasicDetailsForm = this.fb.group({
      productName: [this.utilityService.isEmptyOrNull(this.renewalData.ProductName) ? '' : this.renewalData.ProductName],
      hospitalizationCoverSI: [this.utilityService.isEmptyOrNull(si) ? '0' : si],
      cumulativeBonus: [this.renewalData.NewASI],
      copaymentPercentTaken: [this.utilityService.isEmptyOrNull(this.renewalData?.CoPay) ? '0' : this.renewalData?.CoPay[this.renewalData.CoPay.indexOf((tempCoPay))].toString()],
      voluntaryDeductible: [this.renewalData.VoluntaryDeductible],
      applicantFullName: [this.renewalData.customerDetails.CustomerName],
      applicantDOB: [this.renewalData.customerDetails.DOB],
      applicantAnnualIncome: ["", Validators.required],
      additionalSum: [this.renewalData.NewASI],
      isPanNumberVisible: [isPanNumberVisible],
      panNumber: [this.renewalData.customerDetails.PAN]
    });
    if (!this.renewalStorageService.correspondenceCheckbox) {
      this.correspondenceDetailsForm = this.fb.group({
        correspondenceAddress1: [this.renewalData.customerDetails.MAILINGADDRESSLINE1],
        correspondenceAddress2: [this.renewalData.customerDetails.MAILINGADDRESSLINE2],
        correspondencePincode: [this.renewalData.customerDetails.MAILINGPINCODE],
        correspondenceCity: [this.renewalData.customerDetails.MAILING_CITYDISTRICT_NAME],
        correspondenceState: [this.renewalData.customerDetails.MAILING_STATE_NAME],
        correspondenceStateCode: [this.renewalData.customerDetails.MAILINGSTATE_CODE],
        correspondenceCityCode: [this.renewalData.customerDetails.MAILINGCITYDISTRICT],
        correspondenceStateCity: [this.renewalData.customerDetails.MAILING_CITYDISTRICT_NAME + '-' + this.renewalData.customerDetails.MAILING_STATE_NAME],
        correspondenceZone: [this.renewalData.Zone],
      })
    }
    else {
      this.correspondenceDetailsForm = this.fb.group({
        correspondenceAddress1: [""],
        correspondenceAddress2: [""],
        correspondencePincode: [""],
        correspondenceCity: [""],
        correspondenceState: [""],
        correspondenceStateCode: [""],
        correspondenceCityCode: [""],
        correspondenceStateCity: [""],
        correspondenceZone: [this.renewalData.Zone],
      })
    }

    this.PermanentDetailsForm = this.fb.group({
      permanentAddress1: [this.renewalData.customerDetails.PERMANENTADDRESSLINE1],
      permanentAddress2: [this.renewalData.customerDetails.PERMANENTADDRESSLINE2],
      permanentAddressLandmark: [this.renewalData.customerDetails.PERMANENTLANDMARK],
      permanentPincode: [this.renewalData.customerDetails.PERMANENTPINCODE],
      permanentCity: [this.renewalData.customerDetails.PERMANENTCITYDISTRICT],
      permanentState: [this.renewalData.customerDetails.PERMANENTSTATE],
      maritalStatus: [this.utilityService.isEmptyOrNull(this.renewalData.customerDetails.MARITALSTATUS) ? '' : this.renewalData.customerDetails.MARITALSTATUS, Validators.required],
      applicantEmailAddress: [this.renewalData.customerDetails.EmailId, [Validators.required, Validators.pattern(Constants.emailIdPatternRegExp)]],
      applicantMoblieNo: [this.renewalData.customerDetails.MOBILENUMBER, [Validators.required, Validators.pattern(Constants.mobileNumberPatternRegExp)]],
      correspondenceCheckbox: [this.renewalStorageService.correspondenceCheckbox],
    })
    this.maritalStatus = this.utilityService.isEmptyOrNull(this.renewalData.customerDetails.MARITALSTATUS) ? '' : this.renewalData.customerDetails.MARITALSTATUS;
    this.OtherDetailsForm = this.fb.group({
      registeredGST: [this.renewalData.customerDetails.GSTInApplicable == true ? 'Yes' : 'No'],
      panNo: [this.renewalData.customerDetails.GSTInApplicable == true ? this.renewalData.customerDetails.GSTInData[0].PanDetails : '', Validators.pattern(Constants.panNumberPatternRegExp)],
      constitutionBusiness: [this.renewalData.customerDetails.GSTInApplicable == true ? this.renewalData.customerDetails.GSTInData[0].ConstitutionOfBusiness : ''],
      customerType: [this.renewalData.customerDetails.GSTInApplicable == true ? this.renewalData.customerDetails.GSTInData[0].CustomerType : ''],
      gstRegistrationStatus: [this.renewalData.customerDetails.GSTInApplicable == true ? this.renewalData.customerDetails.GSTInData[0].GSTRegistrationStatus : ''],
      GSTINOrUIN: [this.renewalData.customerDetails.GSTInApplicable == true ? this.renewalData.customerDetails.GSTInData[0].GSTINNo ? 'GSTIN' : this.renewalData.customerDetails.GSTInData[0].UINNo ? 'UIN' : 'GSTIN' : 'GSTIN'],
      GSTINNo: [this.renewalData.customerDetails.GSTInApplicable == true ? this.renewalData.customerDetails.GSTInData[0].GSTINNo : '', Validators.pattern(patterns.GSTIN)],
      UINNo: [this.renewalData.customerDetails.GSTInApplicable == true ? this.renewalData.customerDetails.GSTInData[0].UINNo : '', Validators.pattern(patterns.UIN)]
    })
    this.customerType = this.renewalData.customerDetails.GSTInApplicable == true ? this.renewalData.customerDetails.GSTInData[0].CustomerType : '';

    let title = '';
    this.renewalDataModal.title.forEach(xy => {
      if (xy.id == this.renewalData.NomineeTitle) {
        title = xy.val;
      }
    })
    this.NomineeDetailsForm = this.fb.group({
      nomineeTitle: [this.renewalData.NomineeTitle, Validators.required],
      nomineeFullName: [this.renewalData.NomineeName, Validators.required],
      nomineeRelationship: [this.renewalData.NomineeRelationShipId == '0' ? this.nomineeRelationshipDetails : this.renewalData.NomineeRelationShipId, Validators.required],
      nomineeDOB: [this.utilityService.formatDate(this.renewalData.NomineeDOB) ? new Date(this.renewalData.NomineeDOB) : '', Validators.required]
    })
    this.AppointeeDetailsForm = this.fb.group({
      appointeeTitle: [this.renewalData.AppointeeTitle],
      appointeeFullName: [this.renewalData.AppointeeName],
      appointeeRelationship: [this.renewalData.AppointeeRelationShipId == '' ? this.insuredRelationshipDetails : this.renewalData.AppointeeRelationShipId],
      appointeeDOB: [this.utilityService.formatDate(this.renewalData.AppointeeDOB) ? new Date(this.renewalData.AppointeeDOB) : '']
    })
    if (isPanNumberVisible) {
      this.BasicDetailsForm.get('panNumber').setValidators([Validators.required, Validators.pattern(Constants.panNumberPatternRegExp)]);
      this.BasicDetailsForm.get('panNumber').updateValueAndValidity();
    } else {
      this.BasicDetailsForm.get('panNumber').clearValidators();
      this.BasicDetailsForm.get('panNumber').updateValueAndValidity();
    }
    if (this.renewalData.SubProductType != 42) {
      this.BasicDetailsForm.get('applicantAnnualIncome').clearValidators(); //clearValidators();
      this.BasicDetailsForm.get('applicantAnnualIncome').patchValue("");
      this.BasicDetailsForm.get('applicantAnnualIncome').updateValueAndValidity();
    } else if (this.renewalData.SubProductType == 42) {
      this.BasicDetailsForm.get('applicantAnnualIncome').setValidators([Validators.required]);
      this.BasicDetailsForm.get('applicantAnnualIncome').updateValueAndValidity();
    }
    this.calculateNomineeAge();
    // Co payment disable in case of gs
    if (this.renewalData.SubProductType == 40) {
      if (!this.utilityService.isEmptyOrNull(this.renewalData.VoluntaryCopay)) {
        let volCopay = (this.renewalData.VoluntaryCopay.toString());
        this.BasicDetailsForm.controls['copaymentPercentTaken'].setValue(volCopay);
      }
      this.BasicDetailsForm.controls['copaymentPercentTaken'].disable();

    }
    else {
      this.BasicDetailsForm.controls['copaymentPercentTaken'].enable();
    }
  }

  getStateCity(event) {
    if (event.target.value.length == 6) {
      let data: RTOListRequestPayload = {
        "Pincode": event.target.value,
        "CityID": ""
      }
      this.subscription.push(this.rtoListService.getStatesCityByPin(data).subscribe({
        next: (response: RTOListResponsePayload) => {
          if (response.StatusCode == Constants.statusCode_success) {
            if (this.renewalData.SubProductType == 42) {
              this.constructBefit(event.target.value);
            }
            if (this.renewalData.SubProductType == 30 || this.renewalData.SubProductType == 31 || this.renewalData.SubProductType == 32 || this.renewalData.SubProductType == 33) {
              this.getAddonList(this.renewalData.SumInsured, event.target.value);
            }
            if (response.Data.length > 1) {
              this.correspondenceCityData = [];
              this.correspondenceCityData = response.Data;
            }
            if (response.Data.length == 1) {
              let data = response.Data[0];
              this.correspondenceCityData = [];
              this.correspondenceDetailsForm.controls['correspondenceCity'].patchValue(data.CityName);
              this.correspondenceDetailsForm.controls['correspondenceCityCode'].patchValue(data.CityID);
              this.correspondenceDetailsForm.controls['correspondenceState'].patchValue(data.StateName);
              this.correspondenceDetailsForm.controls['correspondenceStateCode'].patchValue(data.StateId);
              this.correspondenceDetailsForm.controls['correspondenceStateCity'].patchValue(data.CityName + '-' + data.StateName);
              this.zoneRequest(response.Data[0]);
            }
          }
        },
        error: error => {
        }
      })
      )
    }
  }

  zoneRequest(data) {
    let citydata = data;
    let prodCode = "";
    switch (this.renewalData.SubProductType) {
      case 42:
        prodCode = '3828';
        break;
      case 33:
      case 32:
      case 31:
      case 30:
        prodCode = '3816';
        break;
      case 40:
        prodCode = '3826';
        break;
      default:
        break;
    }
    let zonePayload: ZoneRequestPayload = {
      productcode: prodCode,
      State: citydata.StateName,
      City: citydata.CityName
    }
    this.getZoneData(zonePayload);
  }

  constructBefit(pincode) {
    let request1 = this.constructBefitPlanPremium(pincode);
    this.subscription.push(this.renewal_service.getBefitApplicableDetails(request1).subscribe(response => {
      if (response.StatusCode == 1 && response.StatusMsg == "Success") {
        this.showBefit = response.Data.IsBefitApplicable;
        this.renewalData.IsBefitApplicable = response.Data.IsBefitApplicable;
      } else {
        this.showBefit = false;
        this.renewalData.IsBefitApplicable = false;
      }
      this.renewal_emit_service.befitApplicableData(this.showBefit)
    }))
  }

  constructBefitPlanPremium(pincode) {
    let request1 = {
      pincode: pincode,
      SubProductCode: this.renewalData.SubProductCode,
      SumInsured : String(this.renewalData.SumInsured),
      ProductCode : this.renewalData.ProductCode
    }
    return request1
  }

  getZoneData(data: ZoneRequestPayload) {
    this.subscription.push(this.hapService.getZoneFromCityData(data).subscribe({
      next: (response: ZoneResponsePayload) => {
        if (response.StatusCode == Constants.statusCode_success) {
          let zoneResponse = response.Data.zone;
          this.correspondenceDetailsForm.controls['correspondenceZone'].patchValue(zoneResponse);
          switch (this.renewalData.SubProductType) {
            case 40:
              this.renewalData.Zone = (zoneResponse == GSZone.getZoneZone1) ? GSZone.rnFetchZone1 : (zoneResponse == GSZone.getZoneZone2) ? GSZone.rnFetchZone2 : (zoneResponse == GSZone.getZoneZone3) ? GSZone.rnFetchZone3 : '';
              this.renewalData.RevisedZone = (zoneResponse == GSZone.getZoneZone1) ? GSZone.rnFetchZone1 : (zoneResponse == GSZone.getZoneZone2) ? GSZone.rnFetchZone2 : (zoneResponse == GSZone.getZoneZone3) ? GSZone.rnFetchZone3 : '';
              break;
            case 42:
              if (this.renewalData.IsHaapRefiling) {
                let zone = (zoneResponse == HAPRefilingZone.getZoneZone1) ? HAPRefilingZone.rnFetchZone1 : (zoneResponse == HAPRefilingZone.getZoneZone2) ? HAPRefilingZone.rnFetchZone2 : (zoneResponse == HAPRefilingZone.getZoneZone3) ? HAPRefilingZone.rnFetchZone3 : '';
                this.correspondenceDetailsForm.controls['correspondenceZone'].patchValue(zone);
                break;
              }
              else {
                let zone = (zoneResponse == HAPZone.getZoneZone1) ? HAPZone.rnFetchZone1 : (zoneResponse == HAPZone.getZoneZone2) ? HAPZone.rnFetchZone2 : (zoneResponse == HAPZone.getZoneZone3) ? HAPZone.rnFetchZone3 : '';
                this.correspondenceDetailsForm.controls['correspondenceZone'].patchValue(zone);
              }
              break;
            case 33:
            case 32:
            case 31:
            case 30:
              this.correspondenceDetailsForm.controls['correspondenceZone'].patchValue(zoneResponse);
              break;
            default:
              break;
          }
        }
      }
    }))
  }

  correspondenceCityStateData(data) {
    this.correspondenceDetailsForm.controls['correspondenceCity'].patchValue(data.CityName);
    this.correspondenceDetailsForm.controls['correspondenceCityCode'].patchValue(data.CityID);
    this.correspondenceDetailsForm.controls['correspondenceState'].patchValue(data.StateName);
    this.correspondenceDetailsForm.controls['correspondenceStateCode'].patchValue(data.StateId);
    this.correspondenceDetailsForm.controls['correspondenceStateCity'].patchValue(data.CityName + '-' + data.StateName);
    this.zoneRequest(data);
    this.getAddonList(this.renewalData.SumInsured, data.Pincode);
  }

  correspondenceAddresscheckbox(event) {
    if (event.checked) {
      if (this.renewalData.SubProductType == 30 || this.renewalData.SubProductType == 31 || this.renewalData.SubProductType == 32 || this.renewalData.SubProductType == 33) {
        this.getAddonList(this.renewalData.SumInsured, this.PermanentDetailsForm.controls['permanentPincode'].value);
      }
      if (this.renewalData.SubProductType == 42) {
        this.constructBefit(this.PermanentDetailsForm.controls['permanentPincode'].value);
      }
      this.correspondenceDetailsForm.reset();
      this.correspondenceDetailsForm.get('correspondenceAddress1').clearValidators();
      this.correspondenceDetailsForm.get('correspondenceAddress1').updateValueAndValidity();
      this.correspondenceDetailsForm.get('correspondencePincode').clearValidators();
      this.correspondenceDetailsForm.get('correspondencePincode').updateValueAndValidity();
      this.correspondenceDetailsForm.get('correspondenceStateCity').clearValidators();
      this.correspondenceDetailsForm.get('correspondenceStateCity').updateValueAndValidity();
      let data = { CityName: this.renewalData.customerDetails.PERMANENTCITYDISTRICT, StateName: this.renewalData.customerDetails.PERMANENTSTATE };
      this.zoneRequest(data);
    } else {
      this.correspondenceDetailsForm.get('correspondenceAddress1').setValidators([Validators.required]);
      this.correspondenceDetailsForm.get('correspondenceAddress1').updateValueAndValidity();
      this.correspondenceDetailsForm.get('correspondencePincode').setValidators([Validators.required]);
      this.correspondenceDetailsForm.get('correspondencePincode').updateValueAndValidity();
      this.correspondenceDetailsForm.get('correspondenceStateCity').setValidators([Validators.required]);
      this.correspondenceDetailsForm.get('correspondenceStateCity').updateValueAndValidity();
    }
    this.correspondenceDetailsForm.updateValueAndValidity();
    this.renewalStorageService.correspondenceCheckbox = this.PermanentDetailsForm.controls['correspondenceCheckbox'].value;
  }

  changeGST(value) {
    if (value == 'No') {
      this.renewalData.customerDetails.GSTInData = [];
      this.OtherDetailsForm.get('constitutionBusiness').clearValidators(); //clearValidators();
      this.OtherDetailsForm.get('constitutionBusiness').patchValue("");
      this.OtherDetailsForm.get('constitutionBusiness').updateValueAndValidity();
      this.OtherDetailsForm.get('panNo').clearValidators();
      this.OtherDetailsForm.get('panNo').patchValue("");
      this.OtherDetailsForm.get('panNo').updateValueAndValidity();
      this.OtherDetailsForm.get('gstRegistrationStatus').clearValidators();
      this.OtherDetailsForm.get('gstRegistrationStatus').patchValue("");
      this.OtherDetailsForm.get('gstRegistrationStatus').updateValueAndValidity();
      this.OtherDetailsForm.get('customerType').clearValidators();
      this.OtherDetailsForm.get('customerType').patchValue("");
      this.OtherDetailsForm.get('customerType').updateValueAndValidity();
      this.OtherDetailsForm.get('GSTINNo').clearValidators();
      this.OtherDetailsForm.get('GSTINNo').patchValue("");
      this.OtherDetailsForm.get('GSTINNo').updateValueAndValidity();
      this.OtherDetailsForm.get('UINNo').clearValidators();
      this.OtherDetailsForm.get('UINNo').patchValue("");
      this.OtherDetailsForm.get('UINNo').updateValueAndValidity();
      this.OtherDetailsForm.updateValueAndValidity();
    } else {
      this.OtherDetailsForm.get('constitutionBusiness').setValidators([Validators.required]);
      this.OtherDetailsForm.get('constitutionBusiness').updateValueAndValidity();
      this.OtherDetailsForm.get('panNo').setValidators([Validators.required, Validators.pattern(patterns.panNo)]);
      this.OtherDetailsForm.get('panNo').updateValueAndValidity();
      this.OtherDetailsForm.get('gstRegistrationStatus').setValidators([Validators.required]);
      this.OtherDetailsForm.get('gstRegistrationStatus').updateValueAndValidity();
      this.OtherDetailsForm.get('customerType').setValidators([Validators.required]);
      this.OtherDetailsForm.get('customerType').updateValueAndValidity();
      value = this.OtherDetailsForm.value.GSTINOrUIN;
      if (value == 'GSTIN') {
        this.OtherDetailsForm.get('GSTINNo').setValidators([Validators.required, Validators.pattern(patterns.GSTIN)]);
        this.OtherDetailsForm.get('GSTINNo').updateValueAndValidity();
        this.OtherDetailsForm.get('UINNo').clearValidators();
        this.OtherDetailsForm.get('GSTINNo').patchValue(this.renewalData.customerDetails.GSTInData[0].GSTINNo);
        this.OtherDetailsForm.get('UINNo').patchValue('');
        this.OtherDetailsForm.get('UINNo').updateValueAndValidity();
      }
      if (value == 'UIN') {
        this.OtherDetailsForm.get('UINNo').setValidators([Validators.required, Validators.pattern(patterns.UIN)]);
        this.OtherDetailsForm.get('UINNo').updateValueAndValidity();
        this.OtherDetailsForm.get('GSTINNo').clearValidators();
        this.OtherDetailsForm.get('GSTINNo').patchValue('');
        this.OtherDetailsForm.get('UINNo').patchValue(this.renewalData.customerDetails.GSTInData[0].UINNo);
        this.OtherDetailsForm.get('GSTINNo').updateValueAndValidity();
      }
    }
  }

  constructAddonRequest() {
    let data: GetAddOnListRequestPayload = {
      "SubType": this.renewalData.SubProductType.toString(),
      "ZoneRefilingflag": this.renewalData.IsHaapRefiling == true ? "yes" : "no",
      "subproductcode":this.renewalData.SubProductCode
    }
    return data;
  }

  constructHEPAddonRequest(sumInsured, pincode) {
    let data: GetHEPAddOnListRequestPayload = {
      "Pincode": pincode,
      "ProductCode": "3816", // api chnages abhijit---now from renewal data 
      "SubProductCode": this.renewalData.SubProductCode,
      "SumInsured": sumInsured.toString()
    }
    return data;
  }

  getAddonList(sumInsured, pincode) {
    sumInsured = this.utilityService.isEmptyOrNull(sumInsured) ? this.renewalData.SumInsured : sumInsured;
    pincode = this.utilityService.isEmptyOrNull(pincode) ? this.renewalData.customerDetails.PERMANENTPINCODE : pincode;
    //hep
    if (this.renewalData.SubProductType == 33 || this.renewalData.SubProductType == 32 || this.renewalData.SubProductType == 31 || this.renewalData.SubProductType == 30) {
      let data = this.constructHEPAddonRequest(sumInsured, pincode);
      this.subscription.push(this.renewal_service.getHEPAddonList(data).subscribe({
        next: (response: GetHEPAddOnListResponsePayload) => {
          if (response.StatusCode === Constants.statusCode_success) {
            this.addonList = response.Data.NewAddOnCoverDetails;
            this.renewalData.SumInsured = sumInsured;
            this.renewalData.addonList = this.addonList;
            this.renewalStorageService.setRenewalDataStorage(this.renewalData);
            let data={
              addon:this.addonList,
              si:sumInsured
            }
            this.renewal_emit_service.emitHepAddonData(data);
            this.constructBefit(pincode);
            this.loadInsured = true;
          }
        },
        error: error => {
        }
      }))
    } else {
      let data = this.constructAddonRequest();
      this.subscription.push(this.renewal_service.getAddonList(data).subscribe({
        next: (response: GetAddOnListResponsePayload) => {
          if (response.StatusCode === Constants.statusCode_success) {
            this.isResponse = false;
            this.addonList = response.Data.NewAddOnCoverDetails;
            this.occupation = response.Data.Occupation;
            let occupationObj = response.Data.Occupation;
            for (let i in occupationObj) {
              this.occupationList.push({ key: occupationObj[i], value: i })
            }
            this.occupationList = this.utilityService.sortDetails(this.occupationList, "value");
            if (this.renewalData.SubProductType == 20) {
              this.addonList.forEach(addon => {
                addon.CoverText = addon.Description.replaceAll('_', ' ');
              })
            }
            this.renewalData.addonList = this.addonList;
            this.renewalData.occupationList = this.occupationList;
            this.renewalStorageService.setRenewalDataStorage(this.renewalData);
            this.loadInsured = true;
          } else {
            this.isResponse = true;
          }
        },
        error: error => {
        }
      }))
    }
  }

  getHealthAilment() {
    let diseaseRequestParams: HealthAilmentRequestPayload = {
      'isAgent': healthToggle.Yes,
      'productType': this.renewalDataModal.ProductType.find(x => x.Key === Number(this.renewalData.SubProductType)).value,
    };
    this.subscription.push(this.healthMasterService.getHealthAilmentList(diseaseRequestParams).subscribe({
      next: response => {
        if (response.StatusCode === Constants.statusCode_success) {
          this.isResponse = false;
          this.renewalData.diseaseList = response.Data;
          this.renewalStorageService.setRenewalDataStorage(this.renewalData);
        } else {
          this.isResponse = true;
        }
      },
      error: error => {
      }
    }))
  }

  getNomineeMinMaxDate() {
    let nomineeMinMaxDate = this.utilityService.getMinMaxDate(new Date().getFullYear());
    this.nomineeMinDate = nomineeMinMaxDate.minDate;
    this.nomineeMaxDate = nomineeMinMaxDate.maxDate;
  }

  calculateNomineeAge() {
    let nomineeAge = this.utilityService.calculateAge(this.NomineeDetailsForm.controls['nomineeDOB'].value);
    if (nomineeAge < 18) {
      this.showAppointee = true;
      this.AppointeeDetailsForm.controls['appointeeTitle'].setValidators(Validators.required);
      this.AppointeeDetailsForm.controls['appointeeTitle'].updateValueAndValidity();
      this.AppointeeDetailsForm.controls['appointeeFullName'].setValidators(Validators.required);
      this.AppointeeDetailsForm.controls['appointeeFullName'].updateValueAndValidity();
      this.AppointeeDetailsForm.controls['appointeeRelationship'].setValidators(Validators.required);
      this.AppointeeDetailsForm.controls['appointeeRelationship'].updateValueAndValidity();
      this.AppointeeDetailsForm.controls['appointeeDOB'].setValidators(Validators.required);
      this.AppointeeDetailsForm.controls['appointeeDOB'].updateValueAndValidity();
    } else {
      this.showAppointee = false;
      this.AppointeeDetailsForm.controls['appointeeTitle'].clearValidators();
      this.AppointeeDetailsForm.controls['appointeeTitle'].updateValueAndValidity();
      this.AppointeeDetailsForm.controls['appointeeFullName'].clearValidators();
      this.AppointeeDetailsForm.controls['appointeeFullName'].updateValueAndValidity();
      this.AppointeeDetailsForm.controls['appointeeRelationship'].clearValidators();
      this.AppointeeDetailsForm.controls['appointeeRelationship'].updateValueAndValidity();
      this.AppointeeDetailsForm.controls['appointeeDOB'].clearValidators();
      this.AppointeeDetailsForm.controls['appointeeDOB'].updateValueAndValidity();
      this.AppointeeDetailsForm.updateValueAndValidity();
    }

  }

  getAppointeeMinMaxDate() {
    let appointeeMinMaxDate = this.utilityService.getMinMaxDate(new Date().getFullYear());
    this.appointeeMinDate = appointeeMinMaxDate.minDate;
    this.appointeeMaxDate = this.utilityService.subtractYears(new Date(), 18);

  }

  updateArray(data, index) {
    const updatedData = data.map((item, index) => {
      const updatedItem: any = {};
      Object.entries(item).forEach(([key, value]) => {
        const dynamicKey = key.replace(/(.*?)(\d+)$/, `$1${index + 1}`);
        updatedItem[dynamicKey] = value;
      });
      return updatedItem;
    });

    return updatedData;

  }

  stopPropogation(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  // checkPedValidation(insuredForm) {
  //   let count = 0;
  //   insuredForm.controls.forEach((control) => {
  //     if (control.controls['isPreExistingDisease'].value) {
  //       control.controls['insuredPED'].value.forEach((insuredPED, index) => {
  //         if (!insuredPED['isPEDSelected' + index]) {
  //           count++;
  //         }
  //       });
  //     }
  //   });
  //   return count;
  // }

  getSubmitProposal(event) {
    this.renewal_emit_service.emitOnSubmitClickEvent(true);
    this.renewalData = this.renewalStorageService.getRenewalDataStorage();
    if (this.AppointeeDetailsForm.valid && this.NomineeDetailsForm.valid && this.PermanentDetailsForm.valid && this.correspondenceDetailsForm.valid && this.OtherDetailsForm.valid && this.BasicDetailsForm.valid && this.renewalInsuredComponent.insuredForm.valid) {
      this.setRenewalResponseData(event);
      this.recalculateRenewal(event);
    } else {
      if (this.renewalData.SubProductType == 42 && !this.BasicDetailsForm.valid) {
        for (const key of Object.keys(this.BasicDetailsForm.controls)) {
          if (this.BasicDetailsForm.controls[key].invalid) {
            const invalidControl = this.el.nativeElement.querySelector('#' + key + '.ng-invalid');
            invalidControl.scrollIntoView({ behaviour: "instant", block: "center", inline: "center" });
            invalidControl.focus();
            break;
          }
        }
      }
      else if (!this.PermanentDetailsForm.valid) {
        document.getElementById('PermanentDetails').scrollIntoView();
      }
      else if (!this.NomineeDetailsForm.valid) {
        document.getElementById('NomineeDetails').scrollIntoView();
      }
      else if (this.showAppointee && !this.AppointeeDetailsForm.valid) {
        document.getElementById('AppointeeDetails').scrollIntoView();
      }
      else if (!this.renewalInsuredComponent.insuredForm.valid) {
        let feild = this.getInvalidControlInsured();
        document.getElementById('insuredDetails').scrollIntoView();
        this.showSnackbar(`${feild} is required`);
      }
      else if (this.OtherDetailsForm.controls['registeredGST'].value == 'Yes') {
        if (!this.OtherDetailsForm.valid) {
          document.getElementById('OtherDetails').scrollIntoView();
        }
      }
      this.isSubmitted = true;
    }
  }

  getInvalidControlInsured() {
    const insuredForm = this.renewalInsuredComponent.insuredForm;
    const formControls = insuredForm.controls;
    const invalidFields: string[] = [];
    // Iterate over each form control and check validity
    for (const controlName in formControls) {
      if (formControls.hasOwnProperty(controlName)) {
        const control = formControls[controlName];
        // Assuming control is a FormGroup or FormArray
        if (control instanceof FormArray) {
          // Iterate over nested controls
          for (const nestedControlName in control.controls) {
            if (control.controls.hasOwnProperty(nestedControlName)) {
              const nestedControl = control.controls[nestedControlName];
              // Check if the nested control is invalid
              if (nestedControl.invalid) {
                let memberType = nestedControl.value.memberType
                // Add the nested control name to the list of invalid fields
                invalidFields.push(`${controlName}.${nestedControlName}`);
                if (nestedControl instanceof FormArray) {
                  for (const nestedNestedControlName in nestedControl.controls) {
                    if (nestedControl.controls.hasOwnProperty(nestedNestedControlName)) {
                      const nestedNestedControl = nestedControl.controls[nestedNestedControlName];
                      // Check if the nested nested control is invalid
                      if (nestedNestedControl.invalid) {
                        // Add the nested nested control name to the list of invalid fields
                        let status = `Insured Details ${memberType}  ${nestedControlName} ${nestedNestedControlName}`;
                        return status;
                      }
                    }
                  }
                }
              }
            }
          }
        } else if (control.invalid) {
          invalidFields.push(controlName);
        }
      }
    }
    return invalidFields;
  }

  showSnackbar = (message: string) => {
    this.snackBar.open(message, 'Ok', {
      duration: 4000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
    });
  };
  // recalculate request payload
  recalculateProposalRequest(addon): any {
    this.renewalData = this.renewalStorageService.getRenewalDataStorage();
    let Zone, RevisedZone;
    switch (this.renewalData.SubProductType) {
      case 40:
        Zone = this.renewalData.Zone;
        RevisedZone = this.renewalData.RevisedZone;
        break;
      case 42:
        if (this.renewalData.IsHaapRefiling) {
          Zone = this.renewalData.Zone;
          RevisedZone = this.renewalData.RevisedZone;
        }
        else {
          Zone = (this.renewalData.Zone == HAPZone.rnFetchZone1) ? HAPZone.recalculateZone1 : (this.renewalData.Zone == HAPZone.rnFetchZone2) ? HAPZone.recalculateZone2 : (this.renewalData.Zone == HAPZone.rnFetchZone3) ? HAPZone.recalculateZone3 : '';
          RevisedZone = (this.renewalData.RevisedZone == HAPZone.rnFetchZone1) ? HAPZone.recalculateZone1 : (this.renewalData.RevisedZone == HAPZone.rnFetchZone2) ? HAPZone.recalculateZone2 : (this.renewalData.RevisedZone == HAPZone.rnFetchZone3) ? HAPZone.recalculateZone3 : '';
        }
        break;
      case 33:
      case 32:
      case 31:
      case 30:
        Zone = this.correspondenceDetailsForm.controls['correspondenceZone'].value;
        RevisedZone = this.correspondenceDetailsForm.controls['correspondenceZone'].value;
        break;
      default:
        break;
    }

    let request = {
      UserType: "Agent",
      PolicyNo: this.renewalData.PolicyNo,
      ProductName: this.renewalData.ProductName,
      isJammuAndKashmir: this.renewalData.isJammuAndKashmir,
      IsHigherSiRequired: this.renewalData.IsHigherSiRequired,
      HigherSuminsured: 0,//int left
      IsProductSiCrossedSiLimit: this.renewalData.IsProductSiCrossedSiLimit,
      IsCentralizedDeal: this.renewalData.isCentralizedDeal,
      PrevASI: this.renewalData.PrevASI,
      NewASI: this.renewalData.NewASI,
      PrevInsuredAmt: this.renewalData.PrevPolicySI,
      VoluntaryCopay: this.renewalData.VoluntaryCopay,
      VoluntaryDeductible: this.renewalData.VoluntaryDeductible, //parseInt(this.BasicDetailsForm.controls['voluntaryDeductible'].value) ? parseInt(this.BasicDetailsForm.controls['voluntaryDeductible'].value) : 0,
      SumInsured: this.BasicDetailsForm.controls['hospitalizationCoverSI'].value,
      CustomerId: this.renewalData.customerDetails.CustomerId,
      LoadingPercent: this.renewalData.loadingPercentage,
      LoadingAmount: this.renewalData.loadingDiscount.toString(),
      PrevPolicyStartDate: this.renewalData.PrevPolicyStartDate,
      PrevPolicyEndDate: this.renewalData.PrevPolicyEndDate,
      ClaimStatus: this.renewalData.ClaimStatus,
      IsJammuKashmir: this.renewalData.isJammuAndKashmir,
      Tenure: this.renewalData.Tenure,
      RevisedZone: this.correspondenceDetailsForm.controls['correspondenceZone'].value,
      Zone: this.correspondenceDetailsForm.controls['correspondenceZone'].value,
      ZoneRefilingflag: this.renewalData.ZoneRefilingflag,
      SubProductCode: this.renewalData.SubProductCode,
      CustomerStateCode: this.renewalData.customerDetails.PERMANENTSTATECODE,
      CustomerStateName: this.renewalData.customerDetails.PERMANENTSTATE,
      SubProductType: this.renewalData.IsHigherSiRequired == true ? this.renewalData.higherPremiumDetails.SubProductType : this.renewalData.SubProductType,
      DealId: this.renewalData.DealId,
      PEDWaitingPeriod: "2 Years",
      PostHospitalization: "180 Days",
      PreHospitalization: "60 Days",
      SpecificConditionWaiting: "2 Years",
      InstallmentApplicable: this.renewalData.InstallmentApplicable,
      InstallmentFrequency: this.renewalData.InstallmentFrequencyName,
      ProposalAnnualIncome: this.BasicDetailsForm.controls['applicantAnnualIncome'].value,
      ProposerDOB: this.renewalData.IsHaapRefiling ? this.renewalData.customerDetails.DOB : '',
      multiPlanDtls: this.renewalData.multiPlanDtls,
    }
    return request;
  }

  // Change sum insured function
  changeSumInsured(event) {
    this.renewalData.SumInsured = event;
    // If it is hap check for world wide cover
    // If world wide cover is greater or equal to 25L then emit world wide cover or else emit false
    // To make worldwide cover false 
    // this.renewalData.SumInsured = event;
    if (this.renewalData.SubProductType == this.renewalDataModal.subProductType.hap) {
      this.renewalStorageService.setRenewalDataStorage(this.renewalData);
      if (this.renewalData.SubProductType == this.renewalDataModal.subProductType.hap) {
        let addonRequired = event >= this.renewalDataModal.maxSumInsured ? true : false;
        this.renewal_emit_service.emitHapData(addonRequired);
      }
    }
    else if(this.renewalData.SubProductType == this.renewalDataModal.subProductType.hep){
      this.renewal_emit_service.emitHepAddonData(this.addonList);
    }
  }

  coPaymentPercentTaken(value) {
    this.BasicDetailsForm.controls['copaymentPercentTaken'].setValue(value);
  }

  calculateAge(dob: string) {
    const today = new Date();
    const birthDate = new Date(dob);
    const timeDiff = today.getTime() - birthDate.getTime();
    const ageInMilliseconds = timeDiff;

    // Calculate the age in years
    const ageInYears = Math.floor(ageInMilliseconds / (1000 * 3600 * 24 * 365.25));

    return ageInYears;
  }

  recalculateRenewal(addon) {
    let data;
    let request: GetRenewalRecalculateRequestPayload = this.recalculateProposalRequest(addon);
    this.subscription.push(this.renewal_service.renewalRecalculate(request).subscribe({
      next: response => {
        data = response.Data;
        if (response.StatusCode == Constants.statusCode_success) {
          this.renewalStorageService.setRenewalDataStorage(response.Data);
          if (this.AppointeeDetailsForm.valid && this.NomineeDetailsForm.valid && this.PermanentDetailsForm.valid) {
            this.setRenewalResponseData(addon, response.Data);

            this.router.navigate(['renewal-summary']);
          }
          else {
            this.isSubmitted = true;
          }
        }
      }
    }))
  }
  getAddonCoverDetails(addonData: any) {
    let coverDetails: any = [];
    addonData.controls.forEach((addon, i) => {
      if (addon.get("IsAddonSelected" + i).value) {
        const fields = {
          CoverCode: addon.get('CoverCode').value,//addon.CoverCode,
          CoverId: addon.get('CoverId').value,//addon.CoverId,
          CoverText: addon.get('CoverText').value,//addon.CoverText,
          CoverVariantType: addon.get('CoverVariantType').value,//addon.CoverVariantType,
          Description: addon.get('Description').value,//addon.Description,
          IsForAdult: addon.get('IsForAdult').value,//addon.IsForAdult,
          IsForChild: addon.get('IsForChild').value,//addon.IsForChild,
          IsForFemaleAdult: addon.get('IsForFemaleAdult').value,//addon.IsForFemaleAdult,
          IsVisible: addon.get('IsVisible').value,//addon.IsVisible,
          SiLimit: addon.get('SiLimit').value,//addon.SiLimit,
          maxAgeAllowed: addon.get('maxAgeAllowed').value,//addon.maxAgeAllowed,
          IsAddonSelected: addon.get("IsAddonSelected" + i).value,//addon[`IsAddonSelected${i}`],
        };
        coverDetails.push(fields);
      }
    })
    return coverDetails;
  }
  getRelationId(relationshipName) {
    let relationshipId = 0;
    this.insuredRelationshipDetails.find(x => {
      if (x.RelationshipName == relationshipName) {
        relationshipId = parseInt(x.RelationshipID);
      }
    });
    return relationshipId
  }
  getOccupationdata(addonData: any) {
    let occupationData = "";
    addonData.controls.forEach((addon, i) => {
      let addonId = addon.get('CoverId').value;
      if (addonId == 39 || addonId == 71) {
        occupationData = addon.get('insuredOccupation').value;
      }
    })
    return occupationData;
  }
  getInsuredDataFromForm() {
    let insuredData: any = [];
    this.renewalInsuredComponent.getInsuredArray.controls.forEach((insured: any) => {
      let data = {
        AddOn1: false,
        AddOn10: false,
        AddOn11: false,
        AddOn12: false,
        AddOn13: false,
        AddOn2: false,
        AddOn3: false,
        AddOn4: false,
        AddOn5: false,
        AddOn6: false,
        AddOn7: false,
        AddOn8: false,
        AddOn9: false,
        TitleId: parseInt(insured.get('insuredTitle').value),
        InsuredName: insured.get('insuredName').value,
        DateofBirth: this.utilityService.formatDate1(insured.get('insuredDOB').value),
        RelationShipID: this.getRelationId(insured.get('insuredRelationWithAppl').value),
        RelationShipName: insured.get('insuredRelationWithAppl').value,
        PedIllness: this.getPedDataForRNFetchObject(insured.get('insuredPED')),
        Height: 0,//`${insured.get('insuredHeightInFeet').value}.${insured.get('insuredHeightInInches').value}`,
        HeightInInches: insured.get('insuredHeightInInches').value,
        HeightInFeet: insured.get('insuredHeightInFeet').value,
        Weight: parseInt(insured.get('insuredWeight').value),
        KidAdultType: insured.get('memberType').value == 'Adult' ? 2 : 1,
        DateOfJoining: this.utilityService.formatDate1(insured.get('insuredDateOfJoining').value),
        IsExisting: insured.get('isExistingInsured').value,
        // Gender: insured.get('insuredGender').value,
        MemberType: insured.get('memberType').value,
        VaccinationDate: insured.get('insuredVaccineDate').value,
        VaccineDate: insured.get('insuredVaccineDate').value,
        IsPneumococcalVaccination: insured.get('isVaccineTaken').value,
        UWPedIlness: insured.get('insuredUWPedIllness').value,
        Occupation: this.getOccupationdata(insured.get('insuredAddOn')) ? this.getOccupationdata(insured.get('insuredAddOn')) : null,
        AddOnCoverDetails: [],//bydefault empty
        NewAddOnCoverDetails: this.getAddonCoverDetails(insured.get('insuredAddOn')),//NewAddOnCoverDetails
      }
      insuredData.push(data);
    })

    return insuredData;
  }
  getPedDataForRNFetchObject(pedData: any) {
    let pedDetails = []
    pedData.controls.forEach((d, i) => {
      if (d.get(`PEDSelected${i}`).value) {
        pedDetails.push({
          'OtherPedName': d.get('PEDName').value,
          'PedCode': d.get('PEDCode').value,
          'PedName': d.get('PEDName').value,
        })
      }
    });
    return pedDetails || [];
  }
  setRenewalResponseData(addonData, data?) {
    //INSURED CETAILS
    let adultCount = this.renewalInsuredComponent.getMembersLength(healthMembers.Adult);
    let childCount = this.renewalInsuredComponent.getMembersLength(healthMembers.Child);
    let totalmember = adultCount + childCount;
    this.renewalData.multiPlanDtls =
      [
        {
          SumInsured: this.BasicDetailsForm.controls['hospitalizationCoverSI'].value,
          DeductibleSI: this.renewalData.multiPlanDtls[0].DeductibleSI,
          AgeOfEldest: this.renewalData.multiPlanDtls[0].AgeOfEldest,
          AdditionalSI: this.renewalData.multiPlanDtls[0].AdditionalSI,
          NoOfMember: totalmember,
          NoOfAdults: adultCount,
          NoOfKids: childCount,
          isFloater: true,//by default
          insuredDetails: this.getInsuredDataFromForm(),
        }
      ];
    this.renewalData.SumInsured = this.BasicDetailsForm.controls['hospitalizationCoverSI'].value
    this.renewalData.HospitalizationCoverSI = this.BasicDetailsForm.controls['hospitalizationCoverSI'].value;
    this.renewalData.VoluntaryCopay = this.BasicDetailsForm.controls['copaymentPercentTaken'].value;
    this.renewalData.customerDetails.PAN = this.BasicDetailsForm.controls['panNumber'].value;
    this.renewalData.customerDetails.ANNUALINCOME = this.BasicDetailsForm.controls['applicantAnnualIncome'].value;
    // this.renewalData.VoluntaryDeductible = this.BasicDetailsForm.controls['voluntaryDeductible'].value;
    // this.renewalData.VoluntaryDeductible = this.BasicDetailsForm.controls['VoluntaryDeductibleTaken'].value;
    //nominee details
    this.renewalData.NomineeName = this.NomineeDetailsForm.controls['nomineeFullName'].value;
    this.renewalData.NomineeTitle = this.NomineeDetailsForm.controls['nomineeTitle'].value;
    this.renewalData.NomineeRelationShipId = this.NomineeDetailsForm.controls['nomineeRelationship'].value;
    this.renewalData.NomineeDOB = this.NomineeDetailsForm.controls['nomineeDOB'].value;
    let relationshipName = this.nomineeRelationshipDetails.find(x => x.RelationshipID == this.NomineeDetailsForm.controls['nomineeRelationship'].value);
    this.renewalData.NomineeRelationShip = relationshipName?.RelationshipName;
    //appointee details
    if (this.showAppointee) {
      this.renewalData.AppointeeDOB = this.AppointeeDetailsForm.controls['appointeeDOB'].value;
      this.renewalData.AppointeeName = this.AppointeeDetailsForm.controls['appointeeFullName'].value;
      this.renewalData.AppointeeTitle = this.AppointeeDetailsForm.controls['appointeeTitle'].value;
      this.renewalData.AppointeeRelationShipId = this.AppointeeDetailsForm.controls['appointeeRelationship'].value;
      let appointeerelationship = this.apointeeRelationShipDetails.find(x => x.RelationshipID == this.AppointeeDetailsForm.controls['appointeeRelationship'].value);
      this.renewalData.AppointeeRelationShip = appointeerelationship?.RelationshipName;
    }
    //permanent
    this.renewalData.customerDetails.MARITALSTATUS = this.PermanentDetailsForm.controls['maritalStatus'].value;
    this.renewalData.customerDetails.EmailId = this.PermanentDetailsForm.controls['applicantEmailAddress'].value;
    this.renewalData.customerDetails.MOBILENUMBER = this.PermanentDetailsForm.controls['applicantMoblieNo'].value;
    this.renewalData.customerDetails.EmailId = this.PermanentDetailsForm.controls['applicantEmailAddress'].value;
    this.renewalData.customerDetails.MAILINGLANDMARK = this.PermanentDetailsForm.controls['permanentAddressLandmark'].value;
    //diff correspondence address set value
    this.renewalStorageService.correspondenceCheckbox = this.PermanentDetailsForm.controls['correspondenceCheckbox'].value;

    if (this.PermanentDetailsForm.controls['correspondenceCheckbox'].value == false) {
      this.renewalData.customerDetails.MAILINGCITYDISTRICT = this.correspondenceDetailsForm.controls['correspondenceCityCode'].value;
      this.renewalData.customerDetails.MAILING_CITYDISTRICT_NAME = this.correspondenceDetailsForm.controls['correspondenceCity'].value;
      this.renewalData.customerDetails.MAILING_STATE_NAME = this.correspondenceDetailsForm.controls['correspondenceState'].value;
      this.renewalData.customerDetails.MAILINGSTATE_CODE = this.correspondenceDetailsForm.controls['correspondenceStateCode'].value;
      this.renewalData.customerDetails.MAILINGPINCODE = this.correspondenceDetailsForm.controls['correspondencePincode'].value;
      this.renewalData.customerDetails.MAILINGADDRESSLINE1 = this.correspondenceDetailsForm.controls['correspondenceAddress1'].value;
      this.renewalData.customerDetails.MAILINGADDRESSLINE2 = this.correspondenceDetailsForm.controls['correspondenceAddress2'].value;
    }

    if (this.OtherDetailsForm.controls['registeredGST'].value == "Yes") {
      if (!this.utilityService.isEmptyOrNull(this.renewalData.customerDetails.GSTInData)) {
        this.renewalData.customerDetails.GSTInData[0].ConstitutionOfBusiness = this.OtherDetailsForm.controls['constitutionBusiness'].value;
        this.renewalData.customerDetails.GSTInData[0].PanDetails = this.OtherDetailsForm.controls['panNo'].value;
        this.renewalData.customerDetails.GSTInData[0].CustomerType = this.OtherDetailsForm.controls['customerType'].value;
        this.renewalData.customerDetails.GSTInData[0].GSTRegistrationStatus = this.OtherDetailsForm.controls['gstRegistrationStatus'].value;
        this.renewalData.customerDetails.GSTInData[0].GSTINNo = this.OtherDetailsForm.controls['GSTINNo'].value;
        this.renewalData.customerDetails.GSTInData[0].UINNo = this.OtherDetailsForm.controls['UINNo'].value;

      } else {
        this.renewalData.customerDetails.GSTInData = [{
          "GSTINNo": this.OtherDetailsForm.controls['GSTINNo'].value,
          "GSTStateName": "",
          "ConstitutionOfBusiness": this.OtherDetailsForm.controls['constitutionBusiness'].value,
          "CustomerType": this.OtherDetailsForm.controls['customerType'].value,
          "PanDetails": this.OtherDetailsForm.controls['panNo'].value,
          "GSTExemptionApplicable": "",
          "GSTRegistrationStatus": this.OtherDetailsForm.controls['gstRegistrationStatus'].value
        }]
      }
    }
    else {
      this.renewalData.customerDetails.GSTInData = [];
    }
    this.renewalData.customerDetails.GSTInApplicable = this.OtherDetailsForm.controls['registeredGST'].value === 'Yes' ? true : false;

    if (!this.utilityService.isEmptyOrNull(data)) {
      this.renewalData.SubSequentSIPremiums = data.SubSequentSIPremiums;
      this.renewalData.Premium1Year = data.Premium1Year;
      this.renewalData.Premium2Year = data.Premium2Year;
      this.renewalData.Premium3Year = data.Premium3Year;
    }
    this.renewalData.proposalRenewal = true;
    this.renewalData.Zone = this.correspondenceDetailsForm.controls['correspondenceZone'].value;
    this.renewalData.RevisedZone = this.correspondenceDetailsForm.controls['correspondenceZone'].value;
    this.renewalStorageService.setRenewalDataStorage(this.renewalData);
  }

  setData() {
    let request = {
      "customerDetails": {
        "GSTInData": [],
        "Status": "SUCCESS",
        "Error_Id": "",
        "ErrorText": ""
      },
    }
    return request;
  }

  VDvalue(value) {
    this.renewalData.SumInsured = Number(value);
    this.renewalData?.SumInsuredDetails.forEach(x => {
      if (x.SumAmount === this.renewalData.SumInsured) {
        let data = {
          'vdValue': x.VDValues,
          'SI': x.SumAmount
        }
        if (this.renewalData.SubProductType == 20) {
          this.BasicDetailsForm.controls['voluntaryDeductible'].patchValue(x.VDValues[0].value);
        }
        this.renewal_emit_service.emitVDValueInsuredAddonData(data);
        return
      }
    })
    if (this.renewalData.SubProductType == this.renewalDataModal.subProductType.hep || this.renewalData.SubProductType == this.renewalDataModal.subProductType.he || this.renewalData.SubProductType == this.renewalDataModal.subProductType.hsp || this.renewalData.SubProductType == this.renewalDataModal.subProductType.hs) {
      this.getAddonList(value, this.renewalData.customerDetails.MAILINGPINCODE);
    }
  }

  onSelectDropdown(event, tag) {
    switch (tag) {
      case 'customerType':
        this.customerType = event;
        this.OtherDetailsForm.controls['customerType'].patchValue(this.customerType);
        break;
      case 'annualIncome':
        this.annualIncome = event;
        this.BasicDetailsForm.controls['applicantAnnualIncome'].patchValue(this.annualIncome);
        break;
      case 'maritalStatus':
        this.maritalStatus = event;
        this.PermanentDetailsForm.controls['maritalStatus'].patchValue(this.maritalStatus);
        break;
    }
  }

  addonCopayChangeEvent(copay) {
    this.BasicDetailsForm.controls['copaymentPercentTaken'].patchValue(String(copay));
    this.renewalData.VoluntaryCopay = copay.toString();
  }
  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  ngOnDestroy() {
    this.unsubscribeSubscription();
  }

  unsubscribeSubscription() {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

  onRefresh(status) {
    if (status) {
      this.ngOnInit();
    }
  }

}