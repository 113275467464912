<!-- BBR UI Implementation -->
<section class="container-fluid">
  <div class="row">
    <div class="container panaMaincontainer">
      <div class="floatdiv floatingedit" *ngIf="scrolling">
        <img class="imgScss" src="../../../../../../assets/images/BBR/ScrollToTop.svg" (click)="scrollToTop()">
      </div>

      <div class="marginbox">
        <!-- Icon and title start -->
        <div class="myTransactionTitle" *ngIf="!isSearchVisible">
          <div class="bbrheading">
            <span><img src="../../../../../assets/images/MyTransactionIcon/SavedQuotes.svg" alt=""></span>
            <span>
              <h3>Saved Quotes</h3>
            </span>
          </div>
          <div class="d-md-none headerImgSection">
            <span (click)="openSearchBottomSheet()">
              <img class="headerImg" src="{{editImage}}" alt="" /></span>
            <span (click)="searchFilter()">
              <img class="headerImg" src="../../../../../../assets/images/BBR/Search.svg" alt="" /></span>
          </div>
        </div>
        <div class="mobilesearch__fieldBBr" *ngIf="isSearchVisible">
          <div class="mobileinputDivbbr">
            <input matInput type="text" class="input-group__input placeholder"
              [cdkTrapFocusAutoCapture]="isSearchVisible" [cdkTrapFocus]="isSearchVisible"
              [placeholder]="searchPlaceHolder" (input)="queryData()" [(ngModel)]="searchTerm">
            <span class="crossclick" (click)="closeClear()">
              <img src="../../../../../../assets/images/BBR/Close.svg" alt="">
            </span>
          </div>
        </div>
        <!-- Icon and title end -->

        <div class="d-none d-md-block">
          <!-- Input fields card start -->
          <mat-card class="innerbox borderinnerbox">
            <div class="row fieldsdiv" [formGroup]="saveQuoteForm">

              <div class="col-sm-3 form-group" *ngIf="selectList.length > 0">
                <mat-label class="input-group__label" for="myInput">Product Name</mat-label>
                <mat-select class="input-group__input cstm_select" id="productName" placeholder="Product Name"
                  formControlName="productName" (click)="handleMatSelectClick()"
                  (blur)="removeMatSelectScrollListener()">
                  <ng-container *ngFor="let p of selectList;let i = index; let last = last ">
                    <mat-option [value]="p.productCode">
                      {{p.productName}}
                    </mat-option>
                    <mat-divider *ngIf="!last"></mat-divider>
                  </ng-container>
                </mat-select>
              </div>

              <div class="col-sm-3 form-group">
                <mat-label class="input-group__label">Select Date</mat-label>
                <mat-date-range-input class="input-group__input dob" [rangePicker]="picker" (click)="picker.open()"
                  [min]="minMax.minDate" [max]="minMax.maxDate">
                  <input matStartDate formControlName="startDate" placeholder="Start date" readonly>
                  <input matEndDate formControlName="endDate" placeholder="End date" readonly>
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker touchUi></mat-date-range-picker>
              </div>

              <div class="col-sm-3 form-group">
                <button class=" search-button" (click)="getSavedQuoteList()">
                  <span>Submit</span>
                </button>
              </div>

            </div>
          </mat-card>
          <!-- Input fields card end -->

        </div>

        <mat-card class="innerbox borderinnerbox">
          <div class="fieldsdiv">
            <div class="productmainDiv">
              <div class="productdiv">
                <div class="align-items-center">
                  <span>Records: <span class="font-weight-bold" [ngStyle]="{ 'color': '#ec6608' }">{{filteredData ?
                      filteredData.length : 0
                      }}</span></span>
                </div>
              </div>
              <div class="filterdiv">
                <div class="d-none d-md-block">
                  <div class="search__fieldBBr">
                    <div class="inputDivbbr" [ngClass]="{'searchBorder': showCancel}">
                      <input matInput type="text" class=" placeholder" [placeholder]="searchPlaceHolder"
                        (input)="queryData()" [(ngModel)]="searchTerm" (focus)="inputFocus()" (blur)="inputBlur()">
                      <span class="crossclick pr-2" (click)="clearInputField()">
                        <img src="../../../../../../assets/images/BBR/desktopCross.svg" alt="">
                      </span>
                    </div>
                  </div>
                </div>

                <!-- ends here -->
                <div class=" d-flex align-items-center">
                  <mat-button-toggle-group class="custom-toggle-group" (change)="onToggle($event)"
                    [value]="toggleValue">
                    <mat-button-toggle matTooltip="Table View" class="custom_togglebtn" value="table"><img
                        src="../../../../../../assets/images/BBR/tablelist.svg" alt=""></mat-button-toggle>
                    <mat-button-toggle matTooltip="Card View" class="custom_togglebtn" value="card"><img
                        src="../../../../../../assets/images/BBR/cardList.svg" alt=""></mat-button-toggle>
                  </mat-button-toggle-group>
                </div>

              </div>
            </div>
          </div>
        </mat-card>

        <!-- Table format start -->
        <app-custom-table *ngIf="toggleValue == 'table'" [reportList]="filteredData" [buttonData]="tableBtnInfo"
          [displayedColumns]="displayCol" (policyNo)="convertProposalToQuote($event)"
          [tableSize]="'1440px'"></app-custom-table>
        <!-- Table format end -->

        <!-- Card format start -->
        <app-custom-card *ngIf="toggleValue == 'card'" [reportList]="filteredData" [buttonData]="cardBtnInfo"
          [displayedColumns]="displayCol" (policyNo)="convertProposalToQuote($event)"></app-custom-card>
        <!-- Card format end -->

      </div>
    </div>
  </div>
</section>