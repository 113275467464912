<div class="container-fluid" *ngIf="renewalDataStorage.SubProductType!=0">
    <div class="row">
        <div class="container panaMaincontainer">
            <div class="mainDiv">
                <form>
                    <mat-card class="innerbox borderinnerbox mt-2">
                        <p class="hideshowUW" *ngIf="renewalDataStorage.IsMultiPlanPolicy">* It's a non-editable
                            multi-plan policy.</p>
                        <p class="hideshowUW" *ngIf="renewalDataStorage.UWStatusCode == 'Yes'">* It's a non-editable
                            Under-Writer policy.</p>
                        <h3>Renewal Premium Payable</h3>
                        <div class="fieldsdiv">
                            <div class="renewalDiv">
                                <div class="radiobtn"
                                    *ngIf="this.utilityService.isEmptyOrNull(renewalDataStorage.SubSequentSIPremiums)">
                                    <h2 class="renewalHeading">Sum insured</h2>
                                    <ul>
                                        <li>
                                            <input type="radio" class="" name="plan" checked>
                                            <label>
                                                <span>{{utilityService.convertStringtoCurrency(renewalDataStorage.SumInsured)}}</span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                                <div class="radiobtn"
                                    *ngIf="!this.utilityService.isEmptyOrNull(renewalDataStorage?.SubSequentSIPremiums) && this.renewalDataStorage.SubProductType!=40&& this.renewalDataStorage.SubProductType!=22">
                                    <h2 class="renewalHeading">Sum insured</h2>
                                    <ul>
                                        <li
                                            *ngFor="let PremiumData of renewalDataStorage.SubSequentSIPremiums; let i=index">
                                            <ng-container *ngIf=" PremiumData.PremiumDetails">
                                                <input type="radio" class="" name="plan" id="{{'id'+i}}"
                                                    [checked]="i==selectedSumInsuredIndex">
                                                <label for="{{'id'+i}}" (click)="selectSumInsured(PremiumData, i)">
                                                    <span>{{utilityService.convertStringtoCurrency(PremiumData.SumInsured)}}</span>
                                                </label>
                                                <span [ngClass]="
                                                {'line':i<renewalDataStorage.SubSequentSIPremiums.length-1}"
                                                    *ngIf=" renewalDataStorage.SubSequentSIPremiums[i+1]?.PremiumDetails"></span>
                                            </ng-container>
                                        </li>
                                    </ul>
                                </div>
                                <h2 class="renewalHeading">Choose Coverage Period</h2>
                                <div class="plan-radioDiv ">
                                    <div class="scrollable">
                                        <ul class="sumInsRadio">
                                            <li *ngFor="let coverageData of premiumData ; let j=index">
                                                <input type="radio" id="{{'pickSumIns_'+j+'lacs'}}" name="pickSumIns"
                                                    [checked]="selectedCoverageIndex == j" />
                                                <label for="{{'pickSumIns_'+j+'lacs'}}" (click)="selectCoverage(j)">
                                                    <div class="container plansAmountDiv">
                                                        <div class="row">
                                                            <div class="col-sm-12 form-group">
                                                                <span class="planLabel">Premium for {{j+1}}
                                                                    year</span>
                                                            </div>
                                                            <div class="col-sm-12 form-group">
                                                                <span
                                                                    class="Planamount">₹{{utilityService.convertStringtoCurrency(coverageData.TotalPremium)}}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <button class="secondarybutton mt-2" (click)="applyPremium()"
                                    *ngIf="!isApplySuccessfully">Apply</button>
                            </div>

                        </div>
                        <div class="verifyDiv bgVerify"
                            *ngIf="isApplySuccessfully && this.renewalDataStorage.SubProductType!=20 && this.renewalDataStorage.SubProductType!=40 && this.renewalDataStorage.SubProductType!=22">
                            <span><img src="../../../assets/images/complete.gif" alt=""></span>
                            <span>Sum insured applied successfully</span>
                        </div>

                    </mat-card>
                    <mat-expansion-panel class="customExpansion kycExpansion mb-2 marginBox" [expanded]="true"
                        disabled="true" hideToggle>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h3>KYC Details</h3>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="fieldsdiv">
                            <div class="row">
                                <div class="col-sm-12 updateKYC">
                                    <!-- If kyc is not completed -->
                                    <div class="verifyDiv verifyNew" *ngIf="!isKYCDoneSuccessfully">
                                        <span><img src="../../../assets/images/verifyIndicator.gif" alt=""></span>
                                        <span> Please complete your KYC verification </span>
                                        <button class="secondarybtn_2 ml-2" (click)="callKYC()">Update</button>
                                    </div>

                                    <!-- If kyc is completed -->
                                    <div class="verifyDiv bgVerify" *ngIf="isKYCDoneSuccessfully"
                                        style="margin-bottom: 3;">
                                        <span><img src="../../../assets/images/complete.gif" alt=""></span>
                                        <span>KYC Verification successfully completed</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>

                    <!-- Multiplan and Insured Details card -->
                    <div *ngIf="renewalDataStorage.IsMultiPlanPolicy">
                        <mat-card class="innerbox borderinnerbox"
                            *ngFor="let multiplanData of renewalDataStorage.multiPlanDtls; let i=index">
                            <div class="row">
                                <div class="col-sm-12 renewalnewheading">
                                    <h3>Plan - {{i + 1}} Details</h3>
                                    <span
                                        *ngIf="renewalDataStorage.IsMultiPlanPolicy && renewalDataStorage.multiPlanDtls.length >1"
                                        (click)="deletePlan(i)">
                                        <img src="../../../../../../assets/images/cross.png" alt="">
                                    </span>
                                </div>

                            </div>
                            <div class="fieldsdiv">
                                <div class="row customDiv planrowData">
                                    <div class="col-lg-2 col-md-2 col-sm-2 width50 form-group">
                                        <mat-label class="mtlabel planselect" for="myInput">Product Name</mat-label>
                                        <p>{{this.renewalDataStorage.ProductName}}</p>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-sm-2 width50 form-group">
                                        <mat-label class="mtlabel" for="myInput">Sum Insured</mat-label>
                                        <p>₹{{utilityService.convertStringtoCurrency(multiplanData?.SumInsured)}}</p>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-sm-2 form-group">
                                        <mat-label class="mtlabel" for="myInput">Number of insured </mat-label>
                                        <p>{{multiplanData?.NoOfMember}}</p>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-sm-2 form-group"
                                        *ngIf="this.renewalDataStorage.SubProductType == 42">
                                        <mat-label class="mtlabel" for="myInput">Additional SumInsured</mat-label>
                                        <p>₹{{utilityService.convertStringtoCurrency(multiplanData?.AdditionalSI)}}</p>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-sm-2 form-group"
                                        *ngIf="this.renewalDataStorage.SuperNCBCoverValue">
                                        <mat-label class="mtlabel" for="myInput">Super NCB Cover Value</mat-label>
                                        <p>{{multiplanData?.SuperNCBCoverValue}}</p>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-sm-2 form-group"
                                        *ngIf="this.renewalDataStorage.SipProtectCoverValue">
                                        <mat-label class="mtlabel" for="myInput">Sip Protect Cover Value</mat-label>
                                        <p>{{multiplanData?.SipProtectCoverValue}}</p>
                                    </div>

                                    <div class="innerbox plandetailPanel col-12 pl-0 pr-0"
                                        *ngFor="let insuredDetails of multiplanData.insuredDetails;let j=index">
                                        <h3>Insured - {{j + 1}} Details</h3>
                                        <div class="fieldsdiv">
                                            <div class="row customDiv planrowData">
                                                <div class="col-lg-2 col-md-2 col-sm-2 form-group">
                                                    <mat-label class="mtlabel planselect" for="myInput">Insured
                                                        Category</mat-label>
                                                    <p>{{insuredDetails?.MemberType}}
                                                    </p>
                                                </div>
                                                <div class=" col-lg-2 col-sm-2 form-group ">
                                                    <mat-label class="mtlabel" for="myInput">Relationship with
                                                        Applicant</mat-label>
                                                    <p>{{insuredDetails?.RelationShipName}}
                                                    </p>
                                                </div>
                                                <div class=" col-lg-2 col-sm-2 form-group ">
                                                    <mat-label class="mtlabel" for="myInput">Full Name</mat-label>
                                                    <p>{{insuredDetails?.InsuredName}}
                                                    </p>
                                                </div>
                                                <div class="col-lg-2 col-sm-2 form-group ">
                                                    <mat-label class="mtlabel" for="myInput">Date of Birth</mat-label>
                                                    <p>{{insuredDetails?.DateofBirth}}
                                                    </p>
                                                </div>
                                                <div class="col-lg-2 col-sm-2 form-group ">
                                                    <mat-label class="mtlabel" for="myInput">Pre Existing
                                                        Disease</mat-label>
                                                    <p>{{insuredDetails?.PedIllness
                                                        == null ||insuredDetails?.PedIllness== undefined||
                                                        insuredDetails?.PedIllness.length==0?'No':'Yes'}}
                                                    </p>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-2  form-group ">
                                                    <mat-label class="mtlabel" for="myInput">Height</mat-label>
                                                    <p>{{insuredDetails?.HeightInFeet}}
                                                        feet.{{insuredDetails?.HeightInInches}} Inches
                                                    </p>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-2  form-group ">
                                                    <mat-label class="mtlabel" for="myInput">Weight</mat-label>
                                                    <p>{{insuredDetails?.Weight}} kg
                                                    </p>
                                                </div>

                                                <div class="col-sm-12 customaddons pedAddons order-8 order-sm-8"
                                                    *ngIf="!this.utilityService.isEmptyOrNull(insuredDetails.NewAddOnCoverDetails)">
                                                    <span class="subheading pb-5">Addon Covers</span>

                                                    <ul>
                                                        <li *ngFor=" let addonData of insuredDetails.NewAddOnCoverDetails"
                                                            [ngClass]="{'d-none':!addonData.IsAddonSelected}">
                                                            <span>
                                                                <img src="../../../../assets/images/miniTick.svg" alt=""
                                                                    class="miniTick">
                                                            </span>
                                                            <span class="waitingperiodSpan"
                                                                *ngIf="addonData.IsAddonSelected">{{addonData.CoverText}}</span>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </mat-card>
                    </div>


                    <!-- plan Details Card -->
                    <mat-card class="innerbox borderinnerbox producttype marginbox-renewal borderinnerbox"
                        *ngIf="!this.renewalDataStorage.IsMultiPlanPolicy">
                        <form [formGroup]="PlanDetailsForm">
                            <mat-expansion-panel [expanded]="panelOpenState" disabled="false" style="padding:0px">
                                <mat-expansion-panel-header class="pl-0 pr-15">
                                    <mat-panel-title>
                                        <h3>Plan Details</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="fieldsdiv">
                                    <div class="row customDiv planrowData">
                                        <div class="col-12 col-sm-6 form-group order-1 order-sm-1 mb0">
                                            <mat-label class="input-group__label" for="myInput"> Product
                                                Name</mat-label>
                                            <input type="text" matInput class="input-group__input health planselect"
                                                placeholder="Enter product" type="text" formControlName="productName"
                                                autocomplete="off" id="productName" readonly>
                                        </div>

                                        <!-- max renewal product type -->
                                        <div class="col-sm-4 form-group order-3 order-sm-3 "
                                            *ngIf="this.renewalDataStorage.SubProductType==34">
                                            <label class="input-group__label" style="top: 13px;">Product
                                                Type</label>
                                            <mat-select class="input-group__input cstm_select"
                                                formControlName="productType" placeholder="Select product type"
                                                id="productType" required (click)="handleMatSelectClick()"
                                                (blur)="removeMatSelectScrollListener()" *ngIf="!hideEdit">
                                                <ng-container
                                                    *ngFor="let item of this.renewalDataModal.productType; let last = last">
                                                    <mat-option value={{item.key}} selected>
                                                        {{item.value}}
                                                    </mat-option>
                                                    <mat-divider *ngIf="!last"></mat-divider>
                                                </ng-container>
                                            </mat-select>
                                            <input type="text" matInput class="input-group__input health planselect"
                                                placeholder="Enter product type" type="text"
                                                formControlName="productTypeName" autocomplete="off" id="productType"
                                                *ngIf="hideEdit" readonly>
                                        </div>
                                        <div class="col-12 col-sm-6 form-group order-3 order-sm-3"
                                            *ngIf="this.renewalDataStorage.SubProductType==42 && !hideEdit">
                                            <label class="input-group__label">Sum Insured for
                                                Hospitalization
                                                cover(₹)</label>
                                            <mat-select class="input-group__input cstm_select"
                                                formControlName="hospitalizationCoverSI"
                                                placeholder="Select sum insured for hospitalization cover"
                                                id="HospitalizationCoverSI" required (click)="handleMatSelectClick()"
                                                (selectionChange)="changeSumInsured($event.value)"
                                                (blur)="removeMatSelectScrollListener()">
                                                <ng-container
                                                    *ngFor="let item of this.renewalDataStorage?.PolicyPlan; let last = last">
                                                    <mat-option value={{item.SI_VALUE}} selected>
                                                        {{item.SI_VALUE}}
                                                    </mat-option>
                                                    <mat-divider *ngIf="!last"></mat-divider>
                                                </ng-container>
                                            </mat-select>
                                        </div>
                                        <div class="col-12 col-sm-6 form-group order-3 order-sm-3"
                                            *ngIf="this.renewalDataStorage.SubProductType==34 && !hideEdit">
                                            <label class="input-group__label">Sum Insured for
                                                Hospitalization
                                                cover(₹)</label>
                                            <mat-select *ngIf="this.renewalDataStorage.SubProductType==34"
                                                class="input-group__input cstm_select"
                                                formControlName="hospitalizationCoverSI"
                                                placeholder="Select sum insured for hospitalization cover"
                                                id="HospitalizationCoverSI" required (click)="handleMatSelectClick()"
                                                (blur)="removeMatSelectScrollListener()"
                                                (selectionChange)="VDvalue($event.value)">
                                                <ng-container
                                                    *ngFor="let item of this.renewalDataStorage?.SumInsuredDetails; let last = last">
                                                    <mat-option value={{item.SumAmount}} selected>
                                                        {{item.SumAmountDisplay}}
                                                    </mat-option>
                                                    <mat-divider *ngIf="!last"></mat-divider>
                                                </ng-container>
                                            </mat-select>
                                            <mat-select *ngIf="this.renewalDataStorage.SubProductType!==34"
                                                class="input-group__input cstm_select"
                                                formControlName="hospitalizationCoverSI"
                                                placeholder="Select sum insured for hospitalization cover"
                                                id="HospitalizationCoverSI" required (click)="handleMatSelectClick()"
                                                (blur)="removeMatSelectScrollListener()"
                                                (selectionChange)="VDvalue($event.value)">
                                                <ng-container
                                                    *ngFor="let item of this.renewalDataStorage?.SumInsuredDetails; let last = last">
                                                    <mat-option value={{item.SumAmount}} selected>
                                                        {{item.SumAmount}}
                                                    </mat-option>
                                                    <mat-divider *ngIf="!last"></mat-divider>
                                                </ng-container>
                                            </mat-select>
                                        </div>
                                        <div class="col-12 col-sm-6 form-group order-3 order-sm-3"
                                            *ngIf="this.renewalDataStorage.SubProductType!=42 && !hideEdit">
                                            <label class="input-group__label">Sum Insured for
                                                Hospitalization
                                                cover(₹)</label>
                                            <mat-select class="input-group__input cstm_select"
                                                formControlName="hospitalizationCoverSI"
                                                placeholder="Select sum insured for hospitalization cover"
                                                id="HospitalizationCoverSI" required (click)="handleMatSelectClick()"
                                                (blur)="removeMatSelectScrollListener()"
                                                (selectionChange)="VDvalue($event.value)">
                                                <ng-container
                                                    *ngFor="let item of this.renewalDataStorage?.SumInsuredDetails; let last = last">
                                                    <mat-option value={{item.SumAmount}} selected>
                                                        {{item.SumAmount}}
                                                    </mat-option>
                                                    <mat-divider *ngIf="!last"></mat-divider>
                                                </ng-container>
                                            </mat-select>
                                        </div>
                                        <div class="col-12 col-sm-6 form-group order-3 order-sm-3"
                                            *ngIf="(renewalDataStorage?.SubProductType==20 || this.renewalDataStorage.SubProductType==19) && (!hideEdit)">
                                            <label class="input-group__label">Voluntary
                                                Deductible</label>
                                            <input matInput class="input-group__input" type="text" autocomplete="off"
                                                formControlName="voluntaryDeductible" readonly>

                                        </div>
                                        <div class="col-12 col-sm-6 form-group order-3 order-sm-3">
                                            <label class="input-group__label">Additional
                                                Sum Insured </label>
                                            <input matInput class="input-group__input" type="text" autocomplete="off"
                                                formControlName="cumulativeBonus" readonly>
                                        </div>
                                        <div class="col-12 col-sm-6 form-group order-3 order-sm-3"
                                            *ngIf="(!hideEdit) && (this.renewalDataStorage.SubProductType==40 || this.renewalDataStorage.SubProductType==42
                                            || this.renewalDataStorage.SubProductType==33 || this.renewalDataStorage.SubProductType==32 || this.renewalDataStorage.SubProductType==31 || this.renewalDataStorage.SubProductType==30)">
                                            <label class="input-group__label">Co-Payment</label>
                                            <mat-select class="input-group__input cstm_select"
                                                [ngClass]="{'disabled-div': this.renewalDataStorage.SubProductType == 40}"
                                                placeholder="Select co-payment" id="coPayment"
                                                formControlName="copaymentPercentTaken" required
                                                (selectionChange)="coPaymentPercentTaken($event.value)"
                                                (click)="handleMatSelectClick()"
                                                (blur)="removeMatSelectScrollListener()">
                                                <ng-container
                                                    *ngFor="let item of this.renewalDataStorage.CoPay; let last = last">
                                                    <mat-option value={{item}} selected>
                                                        {{item}}
                                                    </mat-option>
                                                    <mat-divider *ngIf="!last"></mat-divider>
                                                </ng-container>
                                            </mat-select>
                                        </div>
                                        <div class="col-12 col-sm-6 form-group order-3 order-sm-3"
                                            *ngIf="this.renewalDataStorage.SubProductType!=22 && this.renewalDataStorage?.SubProductType != 20 && this.renewalDataStorage?.SubProductType != 19 ">
                                            <label class="input-group__label">Zone </label>
                                            <input matInput class="input-group__input" type="text" autocomplete="off"
                                                formControlName="zone" readonly>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </form>
                    </mat-card>

                    <!-- Insured Details card -->
                    <mat-card class="innerbox reverinnerbox borderinnerbox border-0 shadow-none mt-1"
                        *ngIf="this.loadInsured && !hideEdit">
                        <h3>Insured Details</h3>
                        <app-renewal-insured-details id="insuredDetails"
                            (copayChangeEvent)="addonCopayChangeEvent($event)"
                            *ngIf="loadInsured"></app-renewal-insured-details>
                    </mat-card>

                    <!-- Insured Details card -->
                    <mat-card class="innerbox borderinnerbox plandetailPanel "
                        *ngIf="!this.renewalDataStorage.IsMultiPlanPolicy && this.renewalDataStorage.UWStatusCode =='Yes'">
                        <div>
                            <h3>Insured Details</h3>
                        </div>
                        <div
                            *ngIf=" this.renewalDataStorage && this.renewalDataStorage.multiPlanDtls[0].insuredDetails">
                            <div class="fieldsdiv">
                                <div *ngFor="let insuredData of this.adultInsured; let i=index">
                                    <span class="subheading pb-5"> {{insuredData.MemberType}} {{i + 1}}</span>
                                    <div class="row customDiv planrowData">
                                        <!-- <div class="col-sm-2 col-lg-2 form-group width100 order-1 order-sm-1">
                                         <mat-label class="mtlabel " for="myInput">Insured Category</mat-label>
                                         <p>{{insuredData.MemberType}}</p>
                                     </div> -->
                                        <div class=" col-lg-2 col-sm-2 form-group width50 order-3 order-sm-3 ">
                                            <mat-label class="mtlabel" for="myInput">Relationship with
                                                Applicant</mat-label>
                                            <p>{{insuredData.RelationShipName}}</p>
                                        </div>
                                        <div class=" col-lg-2 col-sm-2 form-group width50 order-2 order-sm-2">
                                            <mat-label class="mtlabel" for="myInput">Full Name</mat-label>
                                            <p>{{insuredData.InsuredName}}</p>
                                        </div>
                                        <div class="col-lg-2 col-sm-2 form-group width50 order-4 order-sm-4">
                                            <mat-label class="mtlabel" for="myInput">Date of Birth</mat-label>
                                            <p>{{insuredData.DateofBirth}}</p>
                                        </div>
                                        <div class="col-lg-2 col-sm-2 form-group width50 order-5 order-sm-5 ">
                                            <mat-label class="mtlabel" for="myInput">Pre Existing Disease</mat-label>
                                            <p>{{insuredData.PedIllness == null || insuredData.PedIllness == undefined
                                                ||
                                                insuredData.PedIllness.length==0?
                                                'No':'Yes'}}
                                            </p>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2 form-group width50 order-6 order-sm-6">
                                            <mat-label class="mtlabel" for="myInput">Height</mat-label>
                                            <p>{{insuredData.HeightInFeet}} feet.{{insuredData.HeightInInches}} Inches
                                            </p>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2 form-group width50 order-7 order-sm-7">
                                            <mat-label class="mtlabel" for="myInput">Weight</mat-label>
                                            <p>{{insuredData.Weight}} kg</p>
                                        </div>
                                        <div class="col-sm-12 customaddons pedAddons order-8 order-sm-8"
                                            *ngIf="!this.utilityService.isEmptyOrNull(insuredData.NewAddOnCoverDetails)">
                                            <span class="subheading pb-5"> {{insuredData.MemberType}} {{i + 1}} Addon
                                                Covers</span>

                                            <ul>
                                                <li *ngFor=" let addonData of insuredData.NewAddOnCoverDetails"
                                                    [ngClass]="{'d-none':!addonData.IsAddonSelected}">
                                                    <span>
                                                        <img src="../../../../assets/images/miniTick.svg" alt=""
                                                            class="miniTick">
                                                    </span>
                                                    <span class="waitingperiodSpan"
                                                        *ngIf="addonData.IsAddonSelected">{{!utilityService.isEmptyOrNull(addonData.CoverText)?
                                                        addonData.CoverText: addonData.Description}}</span>
                                                </li>


                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div *ngFor="let insuredData of this.childInsured; let i=index">
                                    <span class="subheading pb-5"> {{insuredData.MemberType}} {{i + 1}}</span>
                                    <div class="row customDiv planrowData">
                                        <div class=" col-lg-2 col-sm-2 form-group width50 order-3 order-sm-3 ">
                                            <mat-label class="mtlabel" for="myInput">Relationship with
                                                Applicant</mat-label>
                                            <p>{{insuredData.RelationShipName}}</p>
                                        </div>
                                        <div class=" col-lg-2 col-sm-2 form-group width50 order-2 order-sm-2">
                                            <mat-label class="mtlabel" for="myInput">Full Name</mat-label>
                                            <p>{{insuredData.InsuredName}}</p>
                                        </div>
                                        <div class="col-lg-2 col-sm-2 form-group width50 order-4 order-sm-4">
                                            <mat-label class="mtlabel" for="myInput">Date of Birth</mat-label>
                                            <p>{{insuredData.DateofBirth}}</p>
                                        </div>
                                        <div class="col-lg-2 col-sm-2 form-group width50 order-5 order-sm-5 ">
                                            <mat-label class="mtlabel" for="myInput">Pre Existing Disease</mat-label>
                                            <p>{{insuredData.PedIllness == null || insuredData.PedIllness == undefined
                                                ||
                                                insuredData.PedIllness.length==0?
                                                'No':'Yes'}}
                                            </p>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2  form-group width50 order-6 order-sm-6">
                                            <mat-label class="mtlabel" for="myInput">Height</mat-label>
                                            <p>{{insuredData.HeightInFeet}} feet.{{insuredData.HeightInInches}} Inches
                                            </p>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2  form-group width50 order-7 order-sm-7">
                                            <mat-label class="mtlabel" for="myInput">Weight</mat-label>
                                            <p>{{insuredData.Weight}} kg</p>
                                        </div>

                                        <div class="col-sm-12 customaddons pedAddons order-8 order-sm-8"
                                            *ngIf="!this.utilityService.isEmptyOrNull(insuredData.NewAddOnCoverDetails)">
                                            <span class="subheading pb-5"> Child {{i + 1}} Addon Covers</span>
                                            <ul>
                                                <li *ngFor=" let addonData of insuredData.NewAddOnCoverDetails"
                                                    [ngClass]="{'d-none':!addonData.IsAddonSelected}">
                                                    <span>
                                                        <img src="../../../../assets/images/miniTick.svg" alt=""
                                                            class="miniTick">
                                                    </span>
                                                    <span class="waitingperiodSpan"
                                                        *ngIf="addonData.IsAddonSelected">{{addonData.CoverText}}</span>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </mat-card>

                    <!-- Applicant Details card -->
                    <mat-card class="innerbox reverinnerbox borderinnerbox">

                        <h3>Applicant Details</h3>
                        <div class="fieldsdiv" id="ApplicantDetails">
                            <form [formGroup]="ApplicantDetailsForm">
                                <div class="row">

                                    <div class="col-sm-4 form-group order-3 order-sm-3 ">
                                        <mat-label class="input-group__label" for="myInput">Applicant Full
                                            Name</mat-label>
                                        <input matInput class="input-group__input" type="text"
                                            formControlName="applicantFullName" autocomplete="off"
                                            [readonly]="!(applicantChange && isOVDCase)">
                                    </div>
                                    <div class="col-sm-4 form-group order-3 order-sm-3 "
                                        *ngIf="renewalDataStorage.customerDetails.CustomerType == 'I'">
                                        <mat-label class="input-group__label" for="myInput">Date of
                                            Birth</mat-label>
                                        <input matInput class="input-group__input" type="text" autocomplete="off"
                                            formControlName="applicantDOB" [readonly]="!(applicantChange && isOVDCase)">
                                    </div>
                                    <div class="col-sm-4 form-group order-3 order-sm-3 mwidth"
                                        *ngIf="renewalDataStorage.SubProductType==42 && !hideEdit">
                                        <label class="input-group__label" style="top: 13px;">Applicant Annual
                                            Income</label>
                                        <mat-select class="input-group__input cstm_select"
                                            placeholder="Select applicant annual income"
                                            formControlName="applicantAnnualIncome" id="applicantAnnualIncome" required
                                            (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()">
                                            <ng-container
                                                *ngFor="let item of renewalDataStorage.IncomeRange; let last = last">
                                                <mat-option value={{item.Name}}>
                                                    {{item.Name}}
                                                </mat-option>
                                                <mat-divider *ngIf="!last"></mat-divider>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="this.renewalDataStorage.SubProductType==42 && ApplicantDetailsForm.controls['applicantAnnualIncome']?.errors?.['required'] && (isSubmitted || isRecalculated)">
                                            Annual income is required.
                                        </mat-error>
                                    </div>
                                    <div class="col-sm-4 form-group order-3 order-sm-3 mwidth"
                                        *ngIf="ApplicantDetailsForm.value.isPanNumberVisible && !hideEdit">
                                        <mat-label class="input-group__label" for="myInput">Pan No.</mat-label>
                                        <input matInput class="input-group__input" type="text" autocomplete="off"
                                            formControlName="panNumber" placeholder="Enter Pan No">
                                        <mat-error
                                            *ngIf="ApplicantDetailsForm.controls['panNumber']?.errors?.['required'] && isSubmitted">
                                            PAN number is required.
                                        </mat-error>
                                        <mat-error
                                            *ngIf="ApplicantDetailsForm.controls['panNumber']?.errors?.['pattern'] && isSubmitted">
                                            Please enter valid pan number.
                                        </mat-error>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </mat-card>

                    <!-- Permanant address Details card -->
                    <mat-card class="innerbox reverinnerbox borderinnerbox" id="PermanentDetails">
                        <mat-expansion-panel [expanded]="panelPermanentOpenState" disabled="false" style="padding:0px">
                            <mat-expansion-panel-header class="pl-0 pr-15">
                                <mat-panel-title>
                                    <h3>Permanent Address Details</h3>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <mat-panel-description>
                                <div class="fieldsdiv">
                                    <form [formGroup]="PermanentDetailsForm">
                                        <div class="row">
                                            <div class="col-sm-3 form-group order-3 order-sm-3 ">
                                                <mat-label class="input-group__label" for="myInput">Address</mat-label>
                                                <input matInput class="input-group__input" type="text"
                                                    autocomplete="off" placeholder="Enter Permanent Address1"
                                                    formControlName="permanentAddress1"
                                                    [readonly]="!(applicantChange && isOVDCase)">
                                                <mat-error
                                                    *ngIf="PermanentDetailsForm.controls['permanentAddress1'].errors?.['required'] && isSubmitted">
                                                    Permanent address is required.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-3 form-group order-3 order-sm-3 ">
                                                <mat-label class="input-group__label" for="myInput">Address
                                                    02</mat-label>
                                                <input matInput class="input-group__input" type="text"
                                                    autocomplete="off" placeholder="Enter Permanent Address2"
                                                    formControlName="permanentAddress2"
                                                    [readonly]="!(applicantChange && isOVDCase)">
                                            </div>
                                            <div class="col-sm-3 form-group order-3 order-sm-3 ">
                                                <mat-label class="input-group__label" for="myInput">Landmark</mat-label>
                                                <input matInput class="input-group__input" type="text"
                                                    autocomplete="off" placeholder="Enter Landmark"
                                                    formControlName="permanentAddressLandmark"
                                                    [readonly]="!(applicantChange && isOVDCase)">
                                            </div>
                                            <div class="col-sm-3 form-group order-3 order-sm-3 ">
                                                <mat-label class="input-group__label" for="myInput">PIN Code</mat-label>
                                                <input matInput class="input-group__input" placeholder="Enter pincode"
                                                    type="text" formControlName="permanentPincode" id="permanentPincode"
                                                    autocomplete="off" [minlength]="6" [maxlength]="6"
                                                    placeholder="Enter Permanent Pinocde"
                                                    (input)="getStateCity(PermanentDetailsForm.controls['permanentPincode'].value ,'permanentPincode')"
                                                    [readonly]="!(applicantChange && isOVDCase)">
                                                <mat-error
                                                    *ngIf="PermanentDetailsForm.controls['permanentPincode'].errors?.['required'] && isSubmitted">
                                                    Permanent pincode is required.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-3 form-group order-3 order-sm-3"
                                                *ngIf="PermanentDetailsForm.controls['permanentState'].value">
                                                <mat-label class="input-group__label" for="myInput">State</mat-label>
                                                <input matInput class="input-group__input" placeholder="Enter state"
                                                    type="text" autocomplete="off" formControlName="permanentState"
                                                    id="permanentState" placeholder="Enter Permanent state" readonly>
                                            </div>
                                            <div class="col-sm-3 form-group order-3 order-sm-3"
                                                *ngIf="PermanentDetailsForm.controls['permanentCity'].value">
                                                <mat-label class="input-group__label" for="myInput">City</mat-label>
                                                <input matInput class="input-group__input" placeholder="Enter city"
                                                    type="text" formControlName="permanentCity" autocomplete="off"
                                                    id="permanentCity" placeholder="Enter Permanent city" readonly>


                                                <!-- <mat-select *ngIf="permamnentCityData.length>1" id="permanentCity"
                                                    required class="input-group__input  cstm_select"
                                                    formControlName="permanentCityDropdown" placeholder="Select City"
                                                    (selectionChange)="permanentCityChange()"
                                                    (click)="handleMatSelectClick()"
                                                    (blur)="removeMatSelectScrollListener()">
                                                    <mat-option [value]='item.CityName'
                                                        *ngFor="let item of permamnentCityData" selected>
                                                        {{item.CityName}}
                                                    </mat-option>
                                                </mat-select> -->

                                            </div>
                                            <div class="col-sm-3 form-group order-3 order-sm-3 ">
                                                <label class="input-group__label" style="top: 13px;">Marital
                                                    Status</label>
                                                <mat-select class="input-group__input cstm_select"
                                                    placeholder="Select marital status" id="maritalStatus"
                                                    formControlName="maritalStatus" required
                                                    (click)="handleMatSelectClick()"
                                                    (blur)="removeMatSelectScrollListener()">
                                                    <ng-container
                                                        *ngFor="let item of this.renewalDataModal.maritalStatus; let last = last">
                                                        <mat-option value={{item}}>
                                                            {{item}}
                                                        </mat-option>
                                                        <mat-divider *ngIf="!last"></mat-divider>
                                                    </ng-container>
                                                </mat-select>
                                                <mat-error
                                                    *ngIf="PermanentDetailsForm.controls['maritalStatus'].errors?.['required'] && isSubmitted">
                                                    Marital status is required.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-3 form-group order-3 order-sm-3 ">
                                                <mat-label class="input-group__label" for="myInput">Email
                                                    Address</mat-label>
                                                <input matInput class="input-group__input" type="text"
                                                    autocomplete="off" formControlName="applicantEmailAddress">
                                                <mat-error
                                                    *ngIf="PermanentDetailsForm.controls['applicantEmailAddress']?.errors?.['required'] && isSubmitted">
                                                    Applicant email address is required.
                                                </mat-error>
                                                <mat-error
                                                    *ngIf="PermanentDetailsForm.controls['applicantEmailAddress']?.errors?.['pattern'] && isSubmitted">
                                                    Please enter a valid email.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-3 form-group order-3 order-sm-3 ">
                                                <mat-label class="input-group__label" for="myInput">Mobile
                                                    Number</mat-label>
                                                <input matInput class="input-group__input" type="number"
                                                    autocomplete="off" formControlName="applicantMoblieNo"
                                                    maxlength="10">

                                                <mat-error
                                                    *ngIf="PermanentDetailsForm.controls['applicantMoblieNo']?.errors?.['required'] && isSubmitted">
                                                    Applicant mobile number is required.
                                                </mat-error>
                                                <mat-error
                                                    *ngIf="PermanentDetailsForm.controls['applicantMoblieNo']?.errors?.['pattern'] && isSubmitted">
                                                    Please enter a valid mobile number.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-12 form-group order-3 order-sm-3 "
                                                style="top:17px;padding-left: 0px;">
                                                <mat-checkbox class="cstmCheckbox" style="color: #282828;"
                                                    formControlName="correspondenceCheckbox"
                                                    (click)="correspondenceAddresscheckbox()">
                                                    Is correspondence Address same as Permanent Address
                                                </mat-checkbox>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </mat-panel-description>
                        </mat-expansion-panel>
                    </mat-card>

                    <!-- Cooreespondance address Details card -->
                    <mat-card class="innerbox reverinnerbox borderinnerbox"
                        *ngIf="PermanentDetailsForm.controls['correspondenceCheckbox'].value==false ">
                        <mat-expansion-panel [expanded]="panelOpenState" disabled="false" style="padding:0px">
                            <mat-expansion-panel-header class="pl-0 pr-15">
                                <mat-panel-title>
                                    <h3>Correspondence Address Details</h3>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <mat-panel-description>
                                <div class="fieldsdiv">
                                    <form [formGroup]="correspondenceDetailsForm">
                                        <div class="row">
                                            <div class="col-sm-3 form-group order-3 order-sm-3 ">
                                                <mat-label class="input-group__label" for="myInput">Address
                                                    01</mat-label>
                                                <input matInput class="input-group__input" type="text"
                                                    autocomplete="off" placeholder="Enter Correspondence Address1"
                                                    formControlName="correspondenceAddress1">
                                                <mat-error
                                                    *ngIf="correspondenceDetailsForm.controls['correspondenceAddress1'].errors?.['required'] && isSubmitted">
                                                    Correspondence address is required.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-3 form-group order-3 order-sm-3 ">
                                                <mat-label class="input-group__label" for="myInput">Address
                                                    02</mat-label>
                                                <input matInput class="input-group__input" type="text"
                                                    autocomplete="off" placeholder="Enter Correspondence Address2"
                                                    formControlName="correspondenceAddress2">
                                                <mat-error
                                                    *ngIf="correspondenceDetailsForm.controls['correspondenceAddress2'].errors?.['required'] && isSubmitted">
                                                    Correspondence address is required.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-3 form-group order-3 order-sm-3 ">
                                                <mat-label class="input-group__label" for="myInput">PIN
                                                    code</mat-label>
                                                <input matInput class="input-group__input" placeholder="Enter pincode"
                                                    autocomplete="off" type="text"
                                                    formControlName="correspondencePincode" id="correspondencePincode"
                                                    [minlength]="6" placeholder="Enter Pinocde" [maxlength]="6"
                                                    [minlength]="6"
                                                    (input)="getStateCity(correspondenceDetailsForm.controls['correspondencePincode'].value,'correspondencePincode')">
                                                <mat-error
                                                    *ngIf="correspondenceDetailsForm.controls['correspondencePincode'].errors?.['required'] && isSubmitted">
                                                    Correspondence pincode is required.
                                                </mat-error>
                                            </div>

                                            <div class="col-sm-3 form-group order-4 order-sm-4 "
                                                *ngIf="!this.utilityService.isEmptyOrNull(correspondenceDetailsForm.controls['correspondencePincode'].value)">
                                                <!-- *ngIf="!this.utilityService.isEmptyOrNull(correspondenceDetailsForm.controls['correspondenceStateCity'].value) || !this.utilityService.isEmptyOrNull(correspondenceDetailsForm.controls['correspondencePincode'].value)" -->
                                                <!-- <mat-label class="input-group__label" for="myInput">State &
                                                    Cities</mat-label> -->
                                                <!-- If length is 1 -->
                                                <mat-label class="input-group__label" for="myInput"
                                                    *ngIf="correspondenceCityData.length>=1">State &
                                                    Cities</mat-label>
                                                <input type="text" matInput class="input-group__input"
                                                    formControlName="correspondenceStateCity"
                                                    placeholder="Enter pincode" autocomplete="off" type="text"
                                                    *ngIf="correspondenceCityData.length==1" readonly>
                                                <mat-select *ngIf="correspondenceCityData.length>1"
                                                    id="correspondenceCityState" required
                                                    class="input-group__input  cstm_select"
                                                    formControlName="correspondenceStateCityDropdown"
                                                    placeholder="Select state and city"
                                                    (selectionChange)="correspondenceCityStateData()"
                                                    (click)="handleMatSelectClick()"
                                                    (blur)="removeMatSelectScrollListener()">
                                                    <mat-option [value]='item.CityName'
                                                        *ngFor="let item of correspondenceCityData" selected>
                                                        {{item.CityName}} - {{item.StateName}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error
                                                    *ngIf="correspondenceDetailsForm.controls['correspondenceStateCity'].errors?.['required'] && isSubmitted">
                                                    Correspondence state and city is required.
                                                </mat-error>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </mat-panel-description>
                        </mat-expansion-panel>
                    </mat-card>

                    <!-- Other Details card -->
                    <mat-card class="innerbox reverinnerbox borderinnerbox" *ngIf="!hideEdit">
                        <mat-expansion-panel [expanded]="panelOpenState" disabled="false" style="padding:0px">
                            <mat-expansion-panel-header class="pl-0 pr-15">
                                <mat-panel-title>
                                    <h3>Other Details</h3>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <form [formGroup]="OtherDetailsForm" id="OtherDetailsForm">
                                <div class="col-sm-6 col-lg-4 form-group">
                                    <label for="" class="singlelabel mb-2" style="width:100%">Are you registered
                                        with
                                        GST?</label>
                                    <div class="group_btn"><br />
                                        <mat-button-toggle-group formControlName="registeredGST" name="fontStyle"
                                            aria-label="Font Style" class="policyToggle_group">
                                            <mat-button-toggle class="PolicyBtn"
                                                *ngFor="let option of renewalDataModal.options; let j = index"
                                                [value]="option" (change)="changeGST(option)">
                                                {{ option }}
                                            </mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </div>
                                </div>
                                <div class="fieldsdiv"
                                    *ngIf="this.OtherDetailsForm?.controls['registeredGST']?.value=='Yes'">
                                    <div class="row">
                                        <div class="col-sm-3 form-group order-3 order-sm-3 ">
                                            <div class="ml-1">
                                                <label class="input-group__label" style="top: 13px;">Constitution of
                                                    Business</label>
                                                <mat-select class="input-group__input cstm_select"
                                                    formControlName="constitutionBusiness" id="registerGST"
                                                    placeholder="Select Business" (click)="handleMatSelectClick()"
                                                    (blur)="removeMatSelectScrollListener()">
                                                    <mat-option *ngFor="let option of renewalDataModal.businessData"
                                                        [value]="option.label.toUpperCase()">
                                                        {{option.label}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error
                                                    *ngIf="OtherDetailsForm.controls['registeredGST'].value == 'Yes' && OtherDetailsForm.controls['constitutionBusiness']?.errors?.['required'] && isRecalculated">
                                                    Constitution of Business is required.
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col-sm-3 form-group order-3 order-sm-3"
                                            *ngIf="!renewalDataStorage?.panRequired">
                                            <mat-label class="input-group__label" for="myInput">Enter Pan
                                                No.</mat-label>
                                            <input matInput class="input-group__input" type="text" autocomplete="off"
                                                placeholder="Enter Pan number" formControlName="panNo">
                                            <mat-error
                                                *ngIf="OtherDetailsForm.controls['registeredGST'].value == 'Yes' &&  OtherDetailsForm.controls['panNo']?.errors?.['required']  && isRecalculated">
                                                Pan Number is required.
                                            </mat-error>
                                            <mat-error
                                                *ngIf="OtherDetailsForm.controls['registeredGST'].value == 'Yes' &&  OtherDetailsForm.controls['panNo']?.errors?.['pattern'] && isRecalculated">
                                                Please enter a valid pan number.
                                            </mat-error>
                                        </div>
                                        <div class="col-sm-3 form-group order-3 order-sm-3 ">
                                            <div class="ml-1">
                                                <label class="input-group__label" style="top: 13px;">GST
                                                    Registration
                                                    Status
                                                </label>
                                                <mat-select class="input-group__input cstm_select"
                                                    formControlName="gstRegistrationStatus" id="registerGST"
                                                    placeholder="Select Status" (click)="handleMatSelectClick()"
                                                    (blur)="removeMatSelectScrollListener()">
                                                    <mat-option *ngFor="let option of renewalDataModal.gstStatusData"
                                                        [value]="option.label.toUpperCase()">
                                                        {{option.label}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error
                                                    *ngIf="OtherDetailsForm.controls['registeredGST'].value == 'Yes' &&  OtherDetailsForm.controls['gstRegistrationStatus']?.errors?.['required'] && isRecalculated">
                                                    GST Registration Status is required.
                                                </mat-error>
                                            </div>
                                        </div>

                                        <div class="col-sm-3 form-group order-3 order-sm-3">
                                            <div class="ml-1">
                                                <label class="input-group__label" style="top: 13px;">Customer
                                                    Type</label>
                                                <mat-select class="input-group__input cstm_select"
                                                    formControlName="customerType" id="customerType"
                                                    placeholder="Select Business" (click)="handleMatSelectClick()"
                                                    (blur)="removeMatSelectScrollListener()">
                                                    <mat-option *ngFor="let option of renewalDataModal.customerTypeData"
                                                        [value]="option.label.toUpperCase()">
                                                        {{option.label}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error
                                                    *ngIf="OtherDetailsForm.controls['registeredGST'].value == 'Yes' && OtherDetailsForm.controls['customerType']?.errors?.['required'] && isRecalculated">
                                                    Customer type is required.
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col-sm-3 form-group order-3 order-sm-3">
                                            <label for="" class="singlelabel mb-2" style="width:100%">Is GSTIN or
                                                UIN
                                                applicable
                                                ?</label>
                                            <div class="group_btn"><br />
                                                <mat-button-toggle-group formControlName="GSTINOrUIN" name="fontStyle"
                                                    aria-label="Font Style" class="policyToggle_group">
                                                    <mat-button-toggle class="PolicyBtn"
                                                        *ngFor="let option of renewalDataModal.GSTINOrUIN; let j = index"
                                                        [value]="option" (change)="changeGST(option)">
                                                        {{ option }}
                                                    </mat-button-toggle>
                                                </mat-button-toggle-group>
                                            </div>
                                        </div>
                                        <div class="col-sm-3 form-group order-3 order-sm-3 "
                                            *ngIf="this.OtherDetailsForm.controls['GSTINOrUIN'].value =='GSTIN'">
                                            <mat-label class="input-group__label" for="myInput">GSTIN</mat-label>
                                            <input matInput class="input-group__input" placeholder="Enter GSTIN"
                                                type="text" autocomplete="off" formControlName="GSTINNo">
                                            <mat-error
                                                *ngIf="OtherDetailsForm.controls['registeredGST'].value == 'Yes' && OtherDetailsForm.controls['GSTINOrUIN'].value == 'GSTIN' && OtherDetailsForm.controls['GSTINNo']?.errors?.['required'] && isRecalculated">
                                                GSTIN is required.
                                            </mat-error>
                                            <mat-error
                                                *ngIf="OtherDetailsForm.controls['registeredGST'].value == 'Yes' && OtherDetailsForm.controls['GSTINNo']?.errors?.['pattern'] && isRecalculated">
                                                Please enter a valid GSTIN number.
                                            </mat-error>
                                        </div>
                                        <div class="col-sm-2 form-group order-3 order-sm-3 "
                                            *ngIf="this.OtherDetailsForm.controls['GSTINOrUIN'].value =='UIN'">
                                            <mat-label class="input-group__label" for="myInput">UIN</mat-label>
                                            <input matInput class="input-group__input" placeholder="Enter UIN"
                                                type="text" autocomplete="off" formControlName="UINNo">
                                            <mat-error
                                                *ngIf="OtherDetailsForm.controls['registeredGST'].value == 'Yes' && OtherDetailsForm.controls['GSTINOrUIN'].value == 'UIN' && OtherDetailsForm.controls['UINNo'].value == '' && isRecalculated">
                                                UIN is required.
                                            </mat-error>
                                            <mat-error
                                                *ngIf="OtherDetailsForm.controls['registeredGST'].value == 'Yes' && OtherDetailsForm.controls['UINNo']?.errors?.['pattern'] && isRecalculated">
                                                Please enter a valid UIN number.
                                            </mat-error>
                                        </div>
                                    </div>

                                </div>

                            </form>
                        </mat-expansion-panel>
                    </mat-card>

                    <!-- zone Upgrade card -->
                    <mat-card class="innerbox plandetailPanel borderinnerbox" *ngIf="showZoneCard && !hideEdit">

                        <form [formGroup]="zoneForm" id="zoneForm">
                            <div>
                                <h3>Zone</h3>
                            </div>

                            <div class="fieldsdiv">

                                <div class="row">
                                    <div class="col-sm-4 form-group">
                                        <mat-label class="mtlabel" for="myInput"
                                            style="position: relative; top: 10px;">Do
                                            you want to Change zone?</mat-label>
                                    </div>

                                    <div class="col-sm-3 form-group">
                                        <mat-button-toggle-group name="fontStyle" aria-label="Font Style"
                                            class="custom-toggle-group" formControlName="changeZone">
                                            <mat-button-toggle class="custom_togglebtn" value="Yes"
                                                (click)="upgradeZoneValue(true)">Yes</mat-button-toggle>
                                            <mat-button-toggle class="custom_togglebtn" value="No"
                                                (click)="upgradeZoneValue(false)">No</mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </div>
                                    <div class="col-sm-3 form-group" style="position: relative;
                                    top: -24px;" *ngIf="this.renewalDataStorage.SubProductType!=40 && showZoneList">
                                        <label class="input-group__label">Zone list</label>
                                        <mat-select class="input-group__input cstm_select" placeholder="Select Zone"
                                            (selectionChange)="onSelectZone($event)" (click)="handleMatSelectClick()"
                                            (blur)="removeMatSelectScrollListener()" formControlName="revisedZone">
                                            <ng-container *ngFor="let zone of zoneList; let last = last">
                                                <mat-option value='{{zone}}'>
                                                    {{zone}}
                                                </mat-option>
                                                <mat-divider *ngIf="!last"></mat-divider>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="zoneForm.controls['changeZone']?.value == 'Yes' && zoneForm.controls['revisedZone']?.errors?.['required'] && isRecalculated">
                                            Zone is required.
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-card>

                    <!-- befit card -->
                    <mat-card class="innerbox borderinnerbox"
                        *ngIf="!hideEdit && ( this.renewalDataStorage?.ZoneRefilingflag != 'Yes') && (this.renewalDataStorage?.SubProductType==22 || this.renewalDataStorage?.SubProductType== 20 || this.renewalDataStorage?.SubProductType == 19 ||  (this.renewalDataStorage?.SubProductType==42 && showBefit && !this.renewalDataStorage.InstallmentApplicable)) ">

                        <form [formGroup]="befitForm" id="befitForm">
                            <h3>Befit</h3>
                            <div class="fieldsdiv">
                                <div class="row">
                                    <div class="col-sm-4 form-group">
                                        <mat-label class="mtlabel" for="myInput"
                                            style="position: relative; top: 10px;">Want
                                            to opt for Befit Rider to your proposal?</mat-label>
                                    </div>
                                    <div class="col-sm-3 form-group">
                                        <mat-button-toggle-group formControlName="befit" name="fontStyle"
                                            aria-label="Font Style" class="policyToggle_group">
                                            <mat-button-toggle class="PolicyBtn"
                                                *ngFor="let option of renewalDataModal.options; let j = index"
                                                [value]="option" (change)="onCheckBefit(option)">
                                                {{ option }}
                                            </mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </div>
                                    <div class="col-sm-12 form-group customCheckslist"
                                        *ngIf="this.befitForm.controls['befit'].value=='Yes'">
                                        <mat-checkbox class="cstmCheckbox" (change)="checkHMPCondition($event)">I
                                            want
                                            to opt for HMP
                                            program</mat-checkbox>
                                    </div>

                                    <div class="col-sm-6 form-group"
                                        *ngIf="this.befitForm.controls['befit'].value=='Yes'">
                                        <div class="summaryplanTable">
                                            <table class="summaryPlan">
                                                <caption class="hidden-caption">Details</caption>
                                                <td class="hidden-caption">Befit Covers</td>
                                                <thead>
                                                    <tr>
                                                        <th>Choose plan</th>
                                                        <th class="pl-2">Premium</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <mat-select placeholder="Select Befit Plan"
                                                                (click)="handleMatSelectClick()"
                                                                (blur)="removeMatSelectScrollListener()"
                                                                (selectionChange)="onbefitPanSelection($event.value)">
                                                                <ng-container
                                                                    *ngFor="let item of bifitCovers; let last = last">
                                                                    <mat-option value={{item.CoverName}}>
                                                                        {{item.CoverName}}
                                                                    </mat-option>
                                                                    <mat-divider *ngIf="!last"></mat-divider>
                                                                </ng-container>
                                                            </mat-select>
                                                        </td>
                                                        <td>{{BefitSelectedPremium ? '₹ ' +
                                                            utilityService.convertStringtoCurrency(BefitSelectedPremium)
                                                            : ''}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 form-group"
                                        *ngIf="this.befitForm.controls['befit'].value=='Yes'">
                                        <mat-label for="" class="singlelabel mediTest">Top benefits
                                            include:</mat-label>
                                        <div class="benefitsTable mb-2">
                                            <table class="benefit" *ngFor="let data of BenefitDiscription">
                                                <caption class="hidden-caption">Details</caption>
                                                <th class="hidden-caption">Befit Plan</th>
                                                <td class="hidden-caption">Befit Description</td>
                                                <tr>
                                                    <td>
                                                        <img class="tickIcon"
                                                            src="../../../../assets/images/miniTick.svg" alt="">
                                                    </td>
                                                    <td>{{data.BenefitName}} -
                                                        <span>{{data.BenefitValue}} {{data.BenefitValueType}}</span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-card>

                    <!-- Emi change card -->
                    <!-- && this.PlanDetailsForm.controls['hospitalizationCoverSI'].value <= 2000000 -->
                    <mat-card class="innerbox plandetailPanel borderinnerbox"
                        *ngIf="!hideEdit && ((((this.renewalDataStorage?.SubProductType == 42  ) || this.renewalDataStorage?.SubProductType == 33 || this.renewalDataStorage?.SubProductType == 32 || this.renewalDataStorage?.SubProductType == 31 || this.renewalDataStorage?.SubProductType == 30) ) && this.befitForm.controls['befit'].value=='No' && this.renewalDataStorage?.EMIOptionVisibleFlag)">
                        <form [formGroup]="emiForm">
                            <h3>EMI</h3>
                            <div class="fieldsdiv">
                                <div class="row">
                                    <div class="col-sm-4 form-group">
                                        <mat-label class="mtlabel" for="myInput"
                                            style="position: relative; top: 10px;">Do
                                            you want to opt for EMI
                                            facility?</mat-label>
                                    </div>
                                    <div class="col-sm-3 form-group">
                                        <mat-button-toggle-group name="fontStyle" aria-label="Font Style"
                                            class="custom-toggle-group" formControlName="EMIApplicable">
                                            <mat-button-toggle class="custom_togglebtn" value="Yes"
                                                (click)="onclickRequireEMI(true)">Yes</mat-button-toggle>
                                            <mat-button-toggle class="custom_togglebtn" value="No"
                                                (click)="onclickRequireEMI(false)">No</mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </div>
                                    <div class="col-sm-3 form-group" *ngIf="showEMIOptionList">
                                        <mat-button-toggle-group name="fontStyle" aria-label="Font Style"
                                            class="custom-toggle-group" (click)="onSelectEMIOption($event)"
                                            formControlName="EMIOption">
                                            <mat-button-toggle class="custom_togglebtn"
                                                *ngFor="let emiOption of emiOptionList"
                                                value='{{emiOption}}'>{{emiOption}}</mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-card>

                    <!-- CIBIL Form Card -->
                    <mat-card class="innerbox plandetailPanel"
                        *ngIf="!hideEdit && this.renewalDataStorage.ZoneRefilingflag=='Yes' && this.renewalDataStorage.SubProductType==42 && !hideEdit">
                        <div class="fieldsdiv">
                            <form [formGroup]="CibilForm">
                                <div class="row">
                                    <div class="fieldsdiv" *ngIf="this.onLoadCibilFlag">
                                        <div class="row">
                                            <div class="col-sm-12 updateKYC">
                                                <!-- If cibil is not completed -->
                                                <div class="verifyDiv verifyNew" *ngIf="!this.cibilApplicable">
                                                    <span><img src="../../../assets/images/verifyIndicator.gif"
                                                            alt=""></span>
                                                    <span> You are not eligible for Cibil Discount</span>
                                                </div>

                                                <!-- If cibil is completed -->
                                                <div class="verifyDiv bgVerify" *ngIf="this.cibilApplicable"
                                                    style="margin-bottom: 3;">
                                                    <span><img src="../../../assets/images/complete.gif" alt=""></span>
                                                    <span>You are eligible for Cibil Discount</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 form-group">
                                        <mat-label class="mtlabel" for="myInput"
                                            style="font-size:12px; font-weight: 600; position: relative; top: 10px;">
                                            CIBIL
                                            Discount</mat-label>
                                    </div>
                                    <div class="col-sm-3 form-group">
                                        <mat-button-toggle-group name="fontStyle" aria-label="Font Style"
                                            class="custom-toggle-group" (click)="checkForCibil($event)"
                                            formControlName="changeCibil">

                                            <mat-button-toggle class="custom_togglebtn"
                                                value="Yes">Yes</mat-button-toggle>
                                            <mat-button-toggle class="custom_togglebtn"
                                                value="No">No</mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <!-- <div class="col-sm-3 form-group order-3 order-sm-3 mwidth"
                            *ngIf="!cibilApplicable && cibilDiscount">
                            <mat-label class="input-group__label" for="myInput">Mobile Number</mat-label>
                            <input matInput class="input-group__input" type="number" maxlength="10"
                                formControlName="CibilMobile">

                            <mat-error *ngIf="CibilForm.controls['CibilMobile']?.errors?.['required'] && isSubmitted">
                                Mobile number is required.
                            </mat-error>
                            <mat-error *ngIf="CibilForm.controls['CibilMobile']?.errors?.['pattern'] && isSubmitted">
                                Please enter a valid Mobile number.
                            </mat-error>
                        </div> -->

                        <!-- </div>
                    <div class="col-sm-3 form-group order-3 order-sm-3 mwidth"
                        *ngIf="!cibilApplicable && cibilDiscount">
                        <mat-label class="input-group__label" for="myInput">Mobile Number</mat-label>
                        <input matInput class="input-group__input" type="text" placeholder="Enter Mobile Number">
                    </div>
                </mat-card> -->

                    </mat-card>
                </form>

                <!-- 80D section -->
                <mat-card class="innerbox plandetailPanel "
                    *ngIf="(!hideEdit) && this.renewalDataStorage?.SubProductType != 34 && (this.renewalDataStorage?.SubProductType == 42 || this.renewalDataStorage?.SubProductType == 33 || this.renewalDataStorage?.SubProductType == 32 || this.renewalDataStorage?.SubProductType == 31 || this.renewalDataStorage?.SubProductType == 30 || this.renewalDataStorage?.SubProductType == 20 || this.renewalDataStorage?.SubProductType == 19 || this.renewalDataStorage?.SubProductType!=22)">
                    <div class="fieldsdiv">
                        <div class="row">
                            <div class="col-sm-12 form-group">
                                <mat-label class="mtlabel" for="myInput" style="position: relative; top: 10px;">Do
                                    you want tax exemption certificate(under section 80D) to be availed as a
                                    lump
                                    sum or on an annual basis(bifurcated as per the policy tenure) ?</mat-label>
                            </div>
                            <div class="col-sm-3 form-group">
                                <mat-button-toggle-group name="fontStyle" aria-label="Font Style"
                                    class="custom-toggle-group" (change)="checkFor80DTax($event)">
                                    <mat-button-toggle class="custom_togglebtn"
                                        *ngFor="let option of renewalDataModal.LumsumTax" [value]="option"
                                        [checked]="'NO'">
                                        {{ option }}
                                    </mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                        </div>
                    </div>
                </mat-card>

                <!-- section 80D for asp  -->
                <mat-card class="innerbox plandetailPanel "
                    *ngIf="!hideEdit && this.renewalDataStorage?.SubProductType==22">
                    <div class="fieldsdiv">
                        <div class="row">
                            <div class="col-sm-12 form-group">
                                <mat-label class="mtlabel" for="myInput"
                                    style="font-size:12px; font-weight: 600; position: relative; top: 10px;">Do you
                                    want tax exemption certificate(under section 80D) to be availed as a lump
                                    sum or on
                                    an annual basis(bifurcated as per the policy tenure) ?</mat-label>
                            </div>
                            <div class="col-sm-3 form-group">
                                <mat-button-toggle-group name="fontStyle" aria-label="Font Style"
                                    class="custom-toggle-group">
                                    <mat-button-toggle class="custom_togglebtn"
                                        *ngFor="let option of renewalDataModal.asp80DSection" [value]="option"
                                        [checked]="'NO'">
                                        {{ option }}
                                    </mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <table class="mediTestTable">
                                        <caption class="hidden-caption">Details</caption>
                                        <th class="hidden-caption">Declarations</th>
                                        <td class="hidden-caption">ASP Declaration</td>
                                        <tr>
                                            <td class="customCheckslist">
                                                <ul>
                                                    <li>
                                                        <mat-checkbox class="cstmCheckbox"
                                                            [checked]="true"></mat-checkbox>
                                                    </li>
                                                </ul>
                                            </td>
                                            <!-- <td>
                                                <p>
                                                    Owing to ongoing pandemic the courier services are impacted.
                                                    To
                                                    ensure on time delivery, policy copy will be shared
                                                    digitally on
                                                    your mobile number/email ID. If you wish to receive policy
                                                    document
                                                    in hardcopy, please tick the option. Please note this
                                                    facility of
                                                    providing policy copy using digital mode is applicable only
                                                    up to
                                                    March 31, 2022*
                                                </p>
                                            </td> -->
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card>

                <!-- Nominee details form -->
                <mat-card class="innerbox reverinnerbox borderinnerbox" id="NomineeDetails">
                    <form [formGroup]="NomineeDetailsForm">
                        <h3>Nominee Details</h3>
                        <div class="fieldsdiv">
                            <div class="row">
                                <div class="col-sm-3 form-group order-3 order-sm-3 mwidth">
                                    <div class="ml-1">
                                        <label class="input-group__label" style="top: 13px;">Title</label>
                                        <mat-select class="input-group__input cstm_select"
                                            (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                                            placeholder="Select nominee title" formControlName="nomineeTitle" required>
                                            <ng-container
                                                *ngFor="let option of nomineeTitle; let last = last">
                                                <mat-option [value]="option.id">
                                                    {{option.val}}
                                                </mat-option>
                                                <mat-divider *ngIf="!last"></mat-divider>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="NomineeDetailsForm.controls['nomineeTitle']?.errors?.['required'] && isSubmitted">
                                            Nominee title is required.
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="col-sm-3 form-group order-3 order-sm-3 mwidth">
                                    <mat-label class="input-group__label" for="myInput">Full Name</mat-label>
                                    <input matInput class="input-group__input" type="text" autocomplete="off"
                                        formControlName="nomineeFullName" placeholder="Nominee full name" 
                                        (input)="this.utilityService.dynamicControlsAlphaOnly($event,true)">
                                    <mat-error
                                        *ngIf="NomineeDetailsForm.controls['nomineeFullName']?.errors?.['required'] && isSubmitted">
                                        Nominee full name is required.
                                    </mat-error>
                                </div>
                                <div class="col-sm-3 form-group order-3 order-sm-3 mwidth">
                                    <div class="ml-1">
                                        <label class="input-group__label" style="top: 13px;">Relationship with
                                            Applicant</label>
                                        <mat-select class="input-group__input cstm_select"
                                            placeholder="Select nominee relationship"
                                            formControlName="nomineeRelationship" (selectionChange)="patchTitle($event.value,'nominee')" required
                                            (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()">
                                            <ng-container
                                                *ngFor="let option of nomineeRelationshipDetails; let last = last">
                                                <mat-option [value]="option.RelationshipID">
                                                    {{option.RelationshipName}}
                                                </mat-option>
                                                <mat-divider *ngIf="!last"></mat-divider>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="NomineeDetailsForm.controls['nomineeRelationship']?.errors?.['required'] && isSubmitted">
                                            Nominee relationship is required.
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="col-sm-3 form-group order-3 order-sm-3 mwidth">
                                    <mat-label class="input-group__label">Date of Birth </mat-label>
                                    <input class="input-group__input dob" matInput [matDatepicker]="nomineeDOB"
                                        formControlName="nomineeDOB" [min]="nomineeMinDate" [max]="nomineeMaxDate"
                                        (click)="nomineeDOB.open()" (dateInput)="calculateNomineeAge()"
                                        placeholder="DD/MM/YYYY">
                                    <mat-datepicker-toggle matSuffix [for]="nomineeDOB">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #nomineeDOB touchUi>
                                    </mat-datepicker>
                                    <mat-error
                                        *ngIf="NomineeDetailsForm.controls['nomineeDOB']?.errors?.['required'] && isSubmitted">
                                        Nominee dob is required.
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-card>

                <!-- Appointee Details Form -->
                <mat-card class="innerbox reverinnerbox borderinnerbox" *ngIf="showAppointee" id="AppointeeDetails">
                    <form [formGroup]="AppointeeDetailsForm">
                        <h3>Appointee Details</h3>
                        <div class="fieldsdiv">
                            <div class="row">
                                <div class="col-sm-3 form-group order-3 order-sm-3 mwidth">
                                    <div class="ml-1">
                                        <label class="input-group__label" style="top: 13px;">Title</label>
                                        <mat-select class="input-group__input cstm_select"
                                            (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                                            placeholder="Select appointee title" formControlName="appointeeTitle"
                                            required>
                                            <ng-container
                                                *ngFor="let option of appointeeTitle; let last = last">
                                                <mat-option [value]="option.id">
                                                    {{option.val}}
                                                </mat-option>
                                                <mat-divider *ngIf="!last"></mat-divider>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="showAppointee && AppointeeDetailsForm.controls['appointeeTitle']?.errors?.['required'] && isSubmitted">
                                            Appointee title is required.
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="col-sm-3 form-group order-3 order-sm-3 mwidth">
                                    <mat-label class="input-group__label" for="myInput">Full Name</mat-label>
                                    <input matInput class="input-group__input" type="text" autocomplete="off"
                                        placeholder="Appointee full name" formControlName="appointeeFullName">
                                    <mat-error
                                        *ngIf="showAppointee && AppointeeDetailsForm.controls['appointeeFullName']?.errors?.['required'] && isSubmitted">
                                        Appointee full name is required.
                                    </mat-error>
                                </div>
                                <div class="col-sm-3 form-group order-3 order-sm-3 mwidth">
                                    <div class="ml-1">
                                        <label class="input-group__label" style="top: 13px;">Relationship with
                                            Applicant</label>
                                        <mat-select class="input-group__input cstm_select"
                                            placeholder="Select appointee relationship" (click)="handleMatSelectClick()"
                                            (blur)="removeMatSelectScrollListener()" (selectionChange)="patchTitle($event.value,'appointee')"
                                            formControlName="appointeeRelationship" id="appointeeRelationship" required>
                                            <ng-container
                                                *ngFor="let option of apointeeRelationShipDetails; let last = last">
                                                <mat-option [value]="option.RelationshipID">
                                                    {{option.RelationshipName}}
                                                </mat-option>
                                                <mat-divider *ngIf="!last"></mat-divider>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="showAppointee && AppointeeDetailsForm.controls['appointeeRelationship']?.errors?.['required'] && isSubmitted">
                                            Appointee relationship is required.
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="col-sm-3 form-group order-3 order-sm-3 mwidth">
                                    <mat-label class="input-group__label">Date of Birth </mat-label>
                                    <input class="input-group__input dob" matInput [matDatepicker]="appointeeDOB"
                                        formControlName="appointeeDOB" [min]="appointeeMinDate" [max]="appointeeMaxDate"
                                        (click)="appointeeDOB.open()" (dateInput)="calculateNomineeAge()"
                                        placeholder="DD/MM/YYYY" readonly>
                                    <mat-datepicker-toggle matSuffix [for]="appointeeDOB">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #appointeeDOB touchUi>
                                    </mat-datepicker>
                                    <mat-error
                                        *ngIf="showAppointee && AppointeeDetailsForm.controls['appointeeDOB']?.errors?.['required'] && isSubmitted">
                                        Appointee dob is required.
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-card>

                <!-- Declarations -->
                <mat-card class="innerbox borderinnerbox">
                    <form [formGroup]="declarationsForm">
                        <div class="fieldsdiv mb-4">
                            <div class="row">
                                <div class="col-sm-12">
                                    <table class="mediTestTable">
                                        <caption class="hidden-caption">Details</caption>
                                        <th class="hidden-caption">Declarations</th>
                                        <td class="hidden-caption">Agent Declaration</td>
                                        <tr>
                                            <td class="customCheckslist">
                                                <ul>
                                                    <li>
                                                        <mat-checkbox class="cstmCheckbox"
                                                            [checked]="declarationsForm.controls['agentDeclarationChecked'].value"
                                                            formControlName="agentDeclarationChecked"></mat-checkbox>
                                                    </li>
                                                </ul>
                                            </td>
                                            <td>
                                                <p>
                                                    I {{rnAgent}} accept <a
                                                        (click)="openDeclarationPopup('Agent Declaration')">
                                                        <span class="custom-anchor"> Agent Declaration
                                                        </span> </a>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                    <mat-error class="ml-4 error-margin"
                                        *ngIf="this.declarationsForm.controls['agentDeclarationChecked'].value!=true">
                                        Agent Declaration is required.
                                    </mat-error>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <table class="mediTestTable">
                                        <caption class="hidden-caption">Details</caption>
                                        <th class="hidden-caption">Declarations</th>
                                        <td class="hidden-caption">Agent Declaration</td>
                                        <tr>
                                            <td class="customCheckslist">
                                                <ul>
                                                    <li>
                                                        <mat-checkbox class="cstmCheckbox"
                                                            [checked]="declarationsForm.controls['privacyPolicyChecked'].value"
                                                            formControlName="privacyPolicyChecked"></mat-checkbox>
                                                    </li>
                                                </ul>
                                            </td>
                                            <td>
                                                <p>
                                                    I accept the <a
                                                        (click)="openDeclarationPopup('generalDeclaration')">
                                                        <span class="custom-anchor">Declaration</span></a> and agree to
                                                    the <a (click)="openDeclarationPopup('tncDeclaration')"><span
                                                            class="custom-anchor">Terms & Conditions</span></a> and
                                                    <a (click)="openDeclarationPopup('privacyPolicy')"><span
                                                            class="custom-anchor">Privacy
                                                            Policy </span></a>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                    <mat-error class="ml-4 error-margin"
                                        *ngIf="this.declarationsForm.controls['privacyPolicyChecked'].value!=true">
                                        Please accept the declaration and agree to the Terms & Conditions and Privacy
                                        Policy
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-card>
            </div>
        </div>
    </div>
</div>


<app-custom-footer [normalButton]="false" [isKYCDoneSuccessfully]="isKYCDoneSuccessfully ? true: false" [buttonData]="[
    {'label': 'Pay Now', 'type': 'secondary', 'source': 'summary', 'id': 'f5','disabled': false, 'hide': isKYCDoneSuccessfully && !isRecalculationNeeded ?false:true },
    {'label': 'Pay Later', 'type': 'secondary', 'source': 'summary', 'id': 'f5','disabled': false, 'hide': isKYCDoneSuccessfully && !isRecalculationNeeded?false:true },
    {'label': 'Payment Link', 'type': 'primary', 'source': 'summary', 'id': 'f5','disabled': false, 'hide': isKYCDoneSuccessfully && !isRecalculationNeeded?false:true },
    {'label': 'Recalculate', 'type': 'primary', 'source': 'summary', 'id': 'f5','disabled': false, 'hide':  isRecalculationNeeded?false:true },
    {'label': 'Choose Payment', 'type': 'primary', 'source': 'summary', 'id': 'f5','disabled': false, 'hide': isKYCDoneSuccessfully && !isRecalculationNeeded?false:true },
]" (buttonClicked)="buttonSelection($event)" *ngIf="renewalDataStorage.SubProductType!=0">
</app-custom-footer>

<!-- Kyc Modal -->
<div class="modal fade kycPopupScreen" id="externalUrlModal" tabindex="-1" role="dialog"
    aria-labelledby="externalUrlModalLabel" aria-hidden="true">
    <div class="modal-dialog my-0" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <h5 class="modal-title">Modal title</h5> -->
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <iframe id="externalUrlFrame" width="100%" height="600px"></iframe>
            </div>
        </div>
    </div>
</div>

<app-error-screen *ngIf="renewalDataStorage.SubProductType==0" (refreshService)="onRefresh($event)">
</app-error-screen>