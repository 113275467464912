<div class="container panaMaincontainer" *ngIf="renewalData.SubProductType!=0">
    <div class="mainDiv">
        <form [formGroup]="BasicDetailsForm">
            <mat-card class="innerbox borderinnerbox producttype marginbox-renewal borderinnerbox">
                <h3>Basic Details</h3>
                <div class="fieldsdiv">
                    <div class="row">
                        <div class="col-sm-3 form-group order-1 order-sm-1 mb0">
                            <mat-label class="input-group__label" for="myInput"> Product Name</mat-label>
                            <input type="text" matInput class="input-group__input health planselect" autocomplete="off"
                                placeholder="Enter product" type="text" formControlName="productName" id="productName"
                                readonly>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4 form-group order-3 order-sm-3 "
                            *ngIf="this.renewalData.SubProductType==42">
                            <label class="input-group__label" style="top: 13px;">Sum Insured for Hospitalization
                                cover(₹)</label>
                            <mat-select class="input-group__input cstm_select" formControlName="hospitalizationCoverSI"
                                placeholder="Select sum insured for hospitalization cover" id="HospitalizationCoverSI"
                                required (selectionChange)="changeSumInsured($event.value)"
                                (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()">
                                <ng-container *ngFor="let item of this.renewalData?.PolicyPlan; let last = last">
                                    <mat-option value={{item.SI_VALUE}} selected>
                                        {{item.SI_VALUE}}
                                    </mat-option>
                                    <mat-divider *ngIf="!last"></mat-divider>
                                </ng-container>
                            </mat-select>
                        </div>
                        <div class="col-sm-4 form-group order-3 order-sm-3 "
                            *ngIf="this.renewalData.SubProductType!=42">
                            <label class="input-group__label" style="top: 13px;">Sum Insured for Hospitalization
                                cover(₹)</label>
                            <mat-select class="input-group__input cstm_select" formControlName="hospitalizationCoverSI"
                                placeholder="Select sum insured for hospitalization cover" id="HospitalizationCoverSI"
                                required (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                                (selectionChange)="VDvalue($event.value)">
                                <ng-container *ngFor="let item of this.renewalData?.SumInsuredDetails; let last = last">
                                    <mat-option value={{item.SumAmount}} selected>
                                        {{item.SumAmount}}
                                    </mat-option>
                                    <mat-divider *ngIf="!last"></mat-divider>
                                </ng-container>
                            </mat-select>
                        </div>
                        <div class="col-sm-4 form-group order-3 order-sm-3 " *ngIf="renewalData?.SubProductType==20">
                            <label class="input-group__label" style="top: 13px;">Voluntary Deductible</label>
                            <input matInput class="input-group__input" type="text" autocomplete="off" formControlName="voluntaryDeductible"
                                readonly>
                            <!-- <mat-select class="input-group__input cstm_select" placeholder="Select Voluntary Deductible"
                                id="voluntaryDeductible" formControlName="voluntaryDeductible" required
                                (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()">
                                <ng-container
                                    *ngFor="let item of this.healthDataModal.voluntaryDeductible; let last = last">
                                    <mat-option value={{item}} selected>
                                        {{item}}
                                    </mat-option>
                                    <mat-divider *ngIf="!last"></mat-divider>
                                </ng-container>
                            </mat-select> -->
                        </div>
                        <div class="col-sm-4 form-group order-3 order-sm-3" *ngIf="!this.renewalData.IsHaapRefiling">
                            <label class="input-group__label" style="top: 13px;">Additional
                                Sum Insured </label>
                            <input matInput class="input-group__input" type="text" formControlName="cumulativeBonus" autocomplete="off"
                                readonly>
                        </div>
                        <div class="col-sm-4 form-group order-3 order-sm-3" *ngIf="this.renewalData.IsHaapRefiling">
                            <label class="input-group__label" style="top: 13px;">Guaranteed
                                Cumulative Bonus </label>
                            <input matInput class="input-group__input" type="text" formControlName="cumulativeBonus" autocomplete="off"
                                readonly>
                        </div>
                        <div class="col-sm-4 form-group order-3 order-sm-3 "
                            *ngIf="!this.renewalData.IsHaapRefiling && (renewalData?.SubProductType==42 || (this.renewalData.SubProductType==40 && !this.hideGsCoPay) || this.renewalData.SubProductType==33 || this.renewalData.SubProductType==32 || this.renewalData.SubProductType==31 || this.renewalData.SubProductType==30) ">
                            <label class="input-group__label" style="top: 13px;">Co-Payment</label>
                            <mat-select class="input-group__input cstm_select" placeholder="Select co-payment"
                                id="coPayment" formControlName="copaymentPercentTaken" required
                                (selectionChange)="coPaymentPercentTaken($event.value)" (click)="handleMatSelectClick()"
                                (blur)="removeMatSelectScrollListener()">
                                <ng-container *ngFor="let item of this.renewalData.CoPay; let last = last">
                                    <mat-option value={{item}} selected>
                                        {{item}}
                                    </mat-option>
                                    <mat-divider *ngIf="!last"></mat-divider>
                                </ng-container>
                            </mat-select>
                        </div>
                    </div>
                </div>
                <!-- haap refiling condition for copay and voluntary -->
                <div class="fieldsdiv" *ngIf="this.renewalData.IsHaapRefiling">
                    <div class="row">
                        <!-- refiling copay -->
                        <div class="col-sm-4 form-group order-3 order-sm-3 ">
                            <label class="input-group__label" style="top: 13px;">Co-Payment </label>
                            <mat-select class="input-group__input cstm_select" placeholder="Select co-payment"
                                id="coPayment" formControlName="copaymentPercentTaken"
                                (selectionChange)="coPaymentPercentTaken($event.value)">
                                <ng-container *ngFor="let item of this.renewalData.CoPay; let last = last">
                                    <mat-option value={{item}} selected>
                                        {{item}}
                                    </mat-option>
                                    <mat-divider></mat-divider>
                                </ng-container>
                            </mat-select>
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="innerbox reverinnerbox borderinnerbox border-0 shadow-none mt-1">
                <h3>Insured Details</h3>
                <app-renewal-insured-details id="insuredDetails" (copayChangeEvent)="addonCopayChangeEvent($event)"
                    *ngIf="loadInsured"></app-renewal-insured-details>
            </mat-card>
            <mat-card class="innerbox reverinnerbox borderinnerbox">
                <h3>Applicant Details</h3>
                <div class="fieldsdiv">
                    <div class="row">
                        <div class="col-sm-4 form-group order-3 order-sm-3 ">
                            <mat-label class="input-group__label" for="myInput">Applicant Full Name</mat-label>
                            <input matInput class="input-group__input" type="text" formControlName="applicantFullName" autocomplete="off"
                                readonly>
                        </div>
                        <div class="col-sm-4 form-group order-3 order-sm-3 "
                            *ngIf="renewalData.customerDetails.CustomerType == 'I'">
                            <mat-label class="input-group__label" for="myInput">Date of Birth</mat-label>
                            <input matInput class="input-group__input" type="text" formControlName="applicantDOB" autocomplete="off"
                                readonly>
                        </div>
                        <div class="col-sm-4 form-group order-3 order-sm-3 mwidth"
                            *ngIf="this.renewalData.SubProductType==42">
                            <label class="input-group__label" style="top: 13px;">Applicant Annual Income</label>
                            <mat-select class="input-group__input cstm_select" formControlName="applicantAnnualIncome"
                                id="applicantAnnualIncome" placeholder="Select applicant annual income" required
                                (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                                [(value)]="annualIncome"
                                (selectionChange)="onSelectDropdown($event.value,'annualIncome')">
                                <ng-container *ngFor="let item of this.renewalData.IncomeRange; let last = last">
                                    <mat-option value={{item.Name}}>
                                        {{item.Name}}
                                    </mat-option>
                                    <mat-divider *ngIf="!last"></mat-divider>
                                </ng-container>
                            </mat-select>
                            <mat-error
                                *ngIf="this.renewalData.SubProductType==42 && this.utilityService.isEmptyOrNull(annualIncome) && isSubmitted">
                                Annual income is required.
                            </mat-error>
                        </div>
                        <div class="col-sm-4 form-group order-3 order-sm-3 mwidth"
                            *ngIf="BasicDetailsForm.value.isPanNumberVisible">
                            <mat-label class="input-group__label" for="myInput">Pan No.</mat-label>
                            <input matInput class="input-group__input" type="text" formControlName="panNumber" autocomplete="off"
                                placeholder="Enter Pan No">
                            <mat-error
                                *ngIf="BasicDetailsForm.controls['panNumber']?.errors?.['required'] && isSubmitted">
                                PAN number is required.
                            </mat-error>
                            <mat-error
                                *ngIf="BasicDetailsForm.controls['panNumber']?.errors?.['pattern'] && isSubmitted">
                                Please enter valid pan number.
                            </mat-error>
                        </div>
                    </div>
                </div>
            </mat-card>
        </form>
        <form [formGroup]="PermanentDetailsForm">
            <mat-card class="innerbox reverinnerbox borderinnerbox" id="PermanentDetails">
                <h3>Permanent Address Details</h3>
                <div class="fieldsdiv">
                    <div class="row">
                        <div class="col-sm-3 form-group order-3 order-sm-3 ">
                            <mat-label class="input-group__label" for="myInput">Address</mat-label>
                            <input matInput class="input-group__input" type="text" autocomplete="off"
                                placeholder="Enter Permanent Address1" formControlName="permanentAddress1" readonly>
                        </div>
                        <div class="col-sm-3 form-group order-3 order-sm-3 ">
                            <mat-label class="input-group__label" for="myInput">Address 02</mat-label>
                            <input matInput class="input-group__input" type="text" autocomplete="off"
                                placeholder="Enter Permanent Address2" formControlName="permanentAddress2" readonly>
                        </div>
                        <div class="col-sm-3 form-group order-3 order-sm-3 ">
                            <mat-label class="input-group__label" for="myInput">Landmark</mat-label>
                            <input matInput class="input-group__input" type="text" placeholder="Enter Landmark" autocomplete="off"
                                formControlName="permanentAddressLandmark">
                        </div>
                        <div class="col-sm-3 form-group order-3 order-sm-3 ">
                            <mat-label class="input-group__label" for="myInput">PIN Code</mat-label>
                            <input matInput class="input-group__input" placeholder="Enter pincode" type="text" autocomplete="off"
                                formControlName="permanentPincode" id="permanentPincode" [minlength]="6" [maxlength]="6"
                                placeholder="Enter Permanent Pinocde" readonly>
                        </div>
                        <div class="col-sm-3 form-group order-3 order-sm-3 ">
                            <mat-label class="input-group__label" for="myInput">State</mat-label>
                            <input matInput class="input-group__input" placeholder="Enter state" type="text" autocomplete="off"
                                formControlName="permanentState" id="permanentState" placeholder="Enter Permanent state"
                                readonly>
                        </div>
                        <div class="col-sm-3 form-group order-3 order-sm-3 ">
                            <mat-label class="input-group__label" for="myInput">City</mat-label>
                            <input matInput class="input-group__input" placeholder="Enter city" type="text" autocomplete="off"
                                formControlName="permanentCity" id="permanentCity" placeholder="Enter Permanent city"
                                readonly>
                        </div>
                        <div class="col-sm-3 form-group order-3 order-sm-3 ">
                            <label class="input-group__label" style="top: 13px;">Marital Status</label>
                            <mat-select class="input-group__input cstm_select" placeholder="Select marital status"
                                id="maritalStatus" formControlName="maritalStatus" required
                                [(value)]="maritalStatus" (selectionChange)="onSelectDropdown($event.value,'maritalStatus')"
                                (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()">
                                <ng-container *ngFor="let item of this.renewalDataModal.maritalStatus; let last = last">
                                    <mat-option value={{item}} selected>
                                        {{item}}
                                    </mat-option>
                                    <mat-divider *ngIf="!last"></mat-divider>
                                </ng-container>
                            </mat-select>
                            <mat-error
                                *ngIf="PermanentDetailsForm.controls['maritalStatus'].errors?.['required'] && isSubmitted">
                                Marital status is required.
                            </mat-error>
                        </div>
                        <div class="col-sm-3 form-group order-3 order-sm-3 ">
                            <mat-label class="input-group__label" for="myInput">Email Address</mat-label>
                            <input matInput class="input-group__input" type="text" autocomplete="off"
                                formControlName="applicantEmailAddress">
                            <mat-error
                                *ngIf="PermanentDetailsForm.controls['applicantEmailAddress']?.errors?.['required'] && isSubmitted">
                                Applicant email address is required.
                            </mat-error>
                            <mat-error
                                *ngIf="PermanentDetailsForm.controls['applicantEmailAddress']?.errors?.['pattern'] && isSubmitted">
                                Please enter a valid email.
                            </mat-error>
                        </div>
                        <div class="col-sm-3 form-group order-3 order-sm-3 ">
                            <mat-label class="input-group__label" for="myInput">Mobile Number</mat-label>
                            <input matInput class="input-group__input" type="number"  autocomplete="off" formControlName="applicantMoblieNo"
                                maxlength="10">

                            <mat-error
                                *ngIf="PermanentDetailsForm.controls['applicantMoblieNo']?.errors?.['required'] && isSubmitted">
                                Applicant mobile number is required.
                            </mat-error>
                            <mat-error
                                *ngIf="PermanentDetailsForm.controls['applicantMoblieNo']?.errors?.['pattern'] && isSubmitted">
                                Please enter a valid mobile number.
                            </mat-error>
                        </div>
                        <div class="col-sm-12 form-group order-3 order-sm-3 " style="top:17px;padding-left: 0px;">
                            <mat-checkbox class="cstmCheckbox" style="color: #282828;"
                                formControlName="correspondenceCheckbox"
                                (change)="correspondenceAddresscheckbox($event)">
                                Is correspondence Address same as Permanent Address
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </mat-card>
        </form>
        <form [formGroup]="correspondenceDetailsForm">
            <mat-card class="innerbox reverinnerbox borderinnerbox"
                *ngIf="PermanentDetailsForm.controls['correspondenceCheckbox'].value==false">
                <h3>Correspondence Address Details</h3>
                <div class="fieldsdiv">
                    <div class="row">
                        <div class="col-sm-3 form-group order-3 order-sm-3 ">
                            <mat-label class="input-group__label" for="myInput">Address 01</mat-label>
                            <input matInput class="input-group__input" type="text" autocomplete="off"
                                placeholder="Enter Correspondence Address1" formControlName="correspondenceAddress1">
                            <mat-error
                                *ngIf="correspondenceDetailsForm.controls['correspondenceAddress1'].errors?.['required'] && isSubmitted">
                                Correspondence address is required.
                            </mat-error>
                        </div>
                        <div class="col-sm-3 form-group order-3 order-sm-3 ">
                            <mat-label class="input-group__label" for="myInput">Address 02</mat-label>
                            <input matInput class="input-group__input" type="text" autocomplete="off"
                                placeholder="Enter Correspondence Address2" formControlName="correspondenceAddress2">
                            <mat-error
                                *ngIf="correspondenceDetailsForm.controls['correspondenceAddress2'].errors?.['required'] && isSubmitted">
                                Correspondence address is required.
                            </mat-error>
                        </div>
                        <div class="col-sm-3 form-group order-3 order-sm-3 ">
                            <mat-label class="input-group__label" for="myInput">PIN
                                code</mat-label>
                            <input matInput class="input-group__input" placeholder="Enter pincode" type="text" autocomplete="off"
                                formControlName="correspondencePincode" id="correspondencePincode" [minlength]="6"
                                placeholder="Enter Pinocde" [maxlength]="6" [minlength]="6"
                                (input)="getStateCity($event)">
                            <mat-error
                                *ngIf="correspondenceDetailsForm.controls['correspondencePincode'].errors?.['required'] && isSubmitted">
                                Correspondence pincode is required.
                            </mat-error>
                        </div>
                        <div class="col-sm-3 form-group order-4 order-sm-4 ">
                            <mat-label class="input-group__label" for="myInput">State & Cities</mat-label>
                            <!-- If length is 1 -->
                            <input type="text" matInput class="input-group__input"
                                formControlName="correspondenceStateCity" placeholder="Enter pincode" type="text" autocomplete="off"
                                *ngIf="correspondenceCityData.length==0" readonly>
                            <mat-select *ngIf="correspondenceCityData.length>0" id="correspondenceCityState" required
                                class="input-group__input  cstm_select" placeholder="Select state and city"
                                (selectionChange)="correspondenceCityStateData($event.value)"
                                (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()">
                                <mat-option [value]="item" *ngFor="let item of correspondenceCityData" selected>
                                    {{item.CityName}} - {{item.StateName}}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="correspondenceDetailsForm.controls['correspondenceStateCity'].errors?.['required'] && isSubmitted">
                                Correspondence state and city is required.
                            </mat-error>
                        </div>
                    </div>
                </div>
            </mat-card>
        </form>
        <form [formGroup]="OtherDetailsForm">
            <mat-card class="innerbox reverinnerbox borderinnerbox">
                <h3>Other Details</h3>
                <div class="col-sm-6 col-lg-4 form-group">
                    <label for="" class="singlelabel mb-2" style="width:100%">Are you registered with GST?</label>
                    <div class="group_btn"><br />
                        <mat-button-toggle-group formControlName="registeredGST" name="fontStyle"
                            aria-label="Font Style" class="policyToggle_group">
                            <mat-button-toggle class="PolicyBtn"
                                *ngFor="let option of renewalDataModal.options; let j = index" [value]="option"
                                (change)="changeGST(option)">
                                {{ option }}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
                <div class="fieldsdiv" *ngIf="this.OtherDetailsForm?.controls['registeredGST']?.value=='Yes'">
                    <div class="row">
                        <div class="col-sm-3 form-group order-3 order-sm-3 ">
                            <div class="ml-1">
                                <label class="input-group__label" style="top: 13px;">Constitution of Business</label>
                                <mat-select class="input-group__input cstm_select"
                                    formControlName="constitutionBusiness" id="registerGST"
                                    placeholder="Select Business" (click)="handleMatSelectClick()"
                                    (blur)="removeMatSelectScrollListener()">
                                    <mat-option *ngFor="let option of renewalDataModal.businessData"
                                        [value]="option.label.toUpperCase()">
                                        {{option.label}}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="OtherDetailsForm.controls['registeredGST'].value == 'Yes' && OtherDetailsForm.controls['constitutionBusiness']?.errors?.['required'] && isSubmitted">
                                    Constitution of Business is required.
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-sm-3 form-group order-3 order-sm-3" *ngIf="!this.renewalData?.panRequired">
                            <mat-label class="input-group__label" for="myInput">Enter Pan No.</mat-label>
                            <input matInput class="input-group__input" type="text" placeholder="Enter Pan number" autocomplete="off"
                                formControlName="panNo">
                            <mat-error
                                *ngIf="OtherDetailsForm.controls['registeredGST'].value == 'Yes' &&  OtherDetailsForm.controls['panNo']?.errors?.['required']  && isSubmitted">
                                Pan Number is required.
                            </mat-error>
                            <mat-error
                                *ngIf="OtherDetailsForm.controls['registeredGST'].value == 'Yes' &&  OtherDetailsForm.controls['panNo']?.errors?.['pattern'] && isSubmitted">
                                Please enter a valid pan number.
                            </mat-error>
                        </div>
                        <div class="col-sm-3 form-group order-3 order-sm-3 ">
                            <div class="ml-1">
                                <label class="input-group__label" style="top: 13px;">GST Registration Status </label>
                                <mat-select class="input-group__input cstm_select"
                                    formControlName="gstRegistrationStatus" id="registerGST" placeholder="Select Status"
                                    (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()">
                                    <mat-option *ngFor="let option of renewalDataModal.gstStatusData"
                                        [value]="option.label.toUpperCase()">
                                        {{option.label}}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="OtherDetailsForm.controls['registeredGST'].value == 'Yes' &&  OtherDetailsForm.controls['gstRegistrationStatus']?.errors?.['required'] && isSubmitted">
                                    GST Registration Status is required.
                                </mat-error>
                            </div>
                        </div>

                        <div class="col-sm-3 form-group order-3 order-sm-3">
                            <div class="ml-1">
                                <label class="input-group__label" style="top: 13px;">Customer Type</label>
                                <mat-select class="input-group__input cstm_select" placeholder="Select Business"
                                    (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                                    (selectionChange)="onSelectDropdown($event.value,'customerType')"
                                    [(value)]="customerType">
                                    <mat-option *ngFor="let option of renewalDataModal.customerTypeData"
                                        [value]="option.label">
                                        {{option.label}}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="OtherDetailsForm.controls['registeredGST'].value == 'Yes' && this.utilityService.isEmptyOrNull(customerType) && isSubmitted">
                                    Customer type is required.
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-sm-3 form-group order-3 order-sm-3">
                            <label for="" class="singlelabel mb-2" style="width:100%">Is GSTIN or UIN applicable
                                ?</label>
                            <div class="group_btn"><br />
                                <mat-button-toggle-group formControlName="GSTINOrUIN" name="fontStyle"
                                    aria-label="Font Style" class="policyToggle_group">
                                    <mat-button-toggle class="PolicyBtn"
                                        *ngFor="let option of renewalDataModal.GSTINOrUIN; let j = index"
                                        [value]="option" (change)="changeGST(option)">
                                        {{ option }}
                                    </mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                        </div>
                        <div class="col-sm-3 form-group order-3 order-sm-3 "
                            *ngIf="this.OtherDetailsForm.controls['GSTINOrUIN'].value =='GSTIN'">
                            <mat-label class="input-group__label" for="myInput">GSTIN</mat-label>
                            <input matInput class="input-group__input" placeholder="Enter GSTIN" type="text" autocomplete="off"
                                formControlName="GSTINNo">
                            <mat-error
                                *ngIf="OtherDetailsForm.controls['registeredGST'].value == 'Yes' && OtherDetailsForm.controls['GSTINOrUIN'].value == 'GSTIN' && OtherDetailsForm.controls['GSTINNo']?.errors?.['required'] && isSubmitted">
                                GSTIN is required.
                            </mat-error>
                            <mat-error
                                *ngIf="OtherDetailsForm.controls['registeredGST'].value == 'Yes' && OtherDetailsForm.controls['GSTINNo']?.errors?.['pattern'] && isSubmitted">
                                Please enter a valid GSTIN number.
                            </mat-error>
                        </div>
                        <div class="col-sm-2 form-group order-3 order-sm-3 "
                            *ngIf="this.OtherDetailsForm.controls['GSTINOrUIN'].value =='UIN'">
                            <mat-label class="input-group__label" for="myInput">UIN</mat-label>
                            <input matInput class="input-group__input" placeholder="Enter UIN" type="text" autocomplete="off"
                                formControlName="UINNo">
                            <mat-error
                                *ngIf="OtherDetailsForm.controls['registeredGST'].value == 'Yes' && OtherDetailsForm.controls['GSTINOrUIN'].value == 'UIN' && OtherDetailsForm.controls['UINNo'].value == '' && isSubmitted">
                                UIN is required.
                            </mat-error>
                            <mat-error
                                *ngIf="OtherDetailsForm.controls['registeredGST'].value == 'Yes' && OtherDetailsForm.controls['UINNo']?.errors?.['pattern'] && isSubmitted">
                                Please enter a valid UIN number.
                            </mat-error>
                        </div>
                    </div>
                </div>
            </mat-card>

        </form>

        <form [formGroup]="NomineeDetailsForm">
            <mat-card class="innerbox reverinnerbox mb-4 borderinnerbox" id="NomineeDetails">
                <h3>Nominee Details</h3>
                <div class="fieldsdiv">
                    <div class="row">
                        <div class="col-sm-3 form-group order-3 order-sm-3 ">
                            <div class="ml-1">
                                <label class="input-group__label" style="top: 13px;">Title</label>
                                <mat-select class="input-group__input cstm_select" placeholder="Select nominee title"
                                    formControlName="nomineeTitle" required (click)="handleMatSelectClick()"
                                    (blur)="removeMatSelectScrollListener()">
                                    <mat-option *ngFor="let option of renewalDataModal.title" [value]="option.id">
                                        {{option.val}}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="NomineeDetailsForm.controls['nomineeTitle']?.errors?.['required'] && isSubmitted">
                                    Nominee title is required.
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-sm-3 form-group order-3 order-sm-3 ">
                            <mat-label class="input-group__label" for="myInput">Full Name</mat-label>
                            <input matInput class="input-group__input" type="text" formControlName="nomineeFullName" autocomplete="off"
                                placeholder="Nominee full name">
                            <mat-error
                                *ngIf="NomineeDetailsForm.controls['nomineeFullName']?.errors?.['required'] && isSubmitted">
                                Nominee full name is required.
                            </mat-error>
                        </div>
                        <div class="col-sm-3 form-group order-3 order-sm-3 ">
                            <div class="ml-1">
                                <label class="input-group__label" style="top: 13px;">Relationship with Applicant</label>
                                <mat-select class="input-group__input cstm_select"
                                    placeholder="Select nominee relationship" formControlName="nomineeRelationship"
                                    required (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()">
                                    <mat-option *ngFor="let option of nomineeRelationshipDetails"
                                        [value]="option.RelationshipID">
                                        {{option.RelationshipName}}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="NomineeDetailsForm.controls['nomineeRelationship']?.errors?.['required'] && isSubmitted">
                                    Nominee relationship is required.
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-sm-3 form-group order-3 order-sm-3 ">
                            <mat-label class="input-group__label">DOB </mat-label>
                            <input class="input-group__input dob" matInput [matDatepicker]="nomineeDOB"
                                placeholder="Enter nominee DOB" formControlName="nomineeDOB" [min]="nomineeMinDate"
                                [max]="nomineeMaxDate" (click)="nomineeDOB.open()" (dateInput)="calculateNomineeAge()"
                                placeholder="DD/MM/YYYY" readonly>
                            <mat-datepicker-toggle matSuffix [for]="nomineeDOB">
                            </mat-datepicker-toggle>
                            <mat-datepicker #nomineeDOB touchUi>
                            </mat-datepicker>
                            <mat-error
                                *ngIf="NomineeDetailsForm.controls['nomineeDOB'].errors?.['required'] && isSubmitted">
                                Nominee dob is required.
                            </mat-error>
                        </div>
                    </div>
                </div>
            </mat-card>
        </form>

        <form [formGroup]="AppointeeDetailsForm" *ngIf="showAppointee" class="mb-4">
            <mat-card class="innerbox reverinnerbox borderinnerbox" id="AppointeeDetails">
                <h3>Appointee Details</h3>
                <div class="fieldsdiv">
                    <div class="row">
                        <div class="col-sm-3 form-group order-3 order-sm-3 ">
                            <div class="ml-1">
                                <label class="input-group__label" style="top: 13px;">Title</label>
                                <mat-select class="input-group__input cstm_select" placeholder="Select appointee title"
                                    formControlName="appointeeTitle" required (click)="handleMatSelectClick()"
                                    (blur)="removeMatSelectScrollListener()">
                                    <mat-option *ngFor="let option of renewalDataModal.title" [value]="option.id">
                                        {{option.val}}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="showAppointee && AppointeeDetailsForm.controls['appointeeTitle'].errors?.['required'] && isSubmitted">
                                    Appointee title is required.
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-sm-3 form-group order-3 order-sm-3 ">
                            <mat-label class="input-group__label" for="myInput">Full Name</mat-label>
                            <input matInput class="input-group__input" type="text" placeholder="Appointee full name" autocomplete="off"
                                formControlName="appointeeFullName">
                            <mat-error
                                *ngIf="showAppointee && AppointeeDetailsForm.controls['appointeeFullName'].errors?.['required'] && isSubmitted">
                                Appointee full name is required.
                            </mat-error>
                        </div>
                        <div class="col-sm-3 form-group order-3 order-sm-3 ">
                            <div class="ml-1">
                                <label class="input-group__label" style="top: 13px;">Relationship with Applicant</label>
                                <mat-select class="input-group__input cstm_select"
                                    placeholder="Select appointee relationship" formControlName="appointeeRelationship"
                                    id="appointeeRelationship" required (click)="handleMatSelectClick()"
                                    (blur)="removeMatSelectScrollListener()">
                                    <mat-option *ngFor="let option of apointeeRelationShipDetails"
                                        [value]="option.RelationshipID">
                                        {{option.RelationshipName}}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="showAppointee && AppointeeDetailsForm.controls['appointeeRelationship']?.errors?.['required'] && isSubmitted">
                                    Appointee relationship is required.
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-sm-3 form-group order-3 order-sm-3 ">
                            <mat-label class="input-group__label">DOB </mat-label>
                            <input class="input-group__input dob" matInput [matDatepicker]="appointeeDOB"
                                formControlName="appointeeDOB" [min]="appointeeMinDate" [max]="appointeeMaxDate"
                                (click)="appointeeDOB.open()" placeholder="DD/MM/YYYY" readonly>
                            <mat-datepicker-toggle matSuffix [for]="appointeeDOB">
                            </mat-datepicker-toggle>
                            <mat-datepicker #appointeeDOB touchUi>
                            </mat-datepicker>
                            <mat-error
                                *ngIf="showAppointee && AppointeeDetailsForm.controls['appointeeDOB'].errors?.['required'] && isSubmitted">
                                Appointee dob is required.
                            </mat-error>
                        </div>
                    </div>
                </div>
            </mat-card>
        </form>
    </div>
</div>

<app-custom-footer [normalButton]="true"
    [buttonData]="[{'label': 'Submit', 'type': 'primary', 'source': '', 'id': '','disabled': false , 'hide': isResponse}]"
    (buttonClicked)="getSubmitProposal($event)">
</app-custom-footer>

<app-error-screen *ngIf="renewalData.SubProductType==0 || isResponse" (refreshService)="onRefresh($event)">
</app-error-screen>