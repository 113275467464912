<div class="container custom-dialog custom-bar" *ngIf="this.dialogData === 'declaration'">
  <div class="sticky-top custom-header pt-2 pb-2 row">
    <h5>Declarations</h5>
    <div class="custom-close">
      <img class="custom-cross" (click)="close()" src="../../../../../assets/images/cross.png" alt="X">
    </div>
  </div>

  <div class="dialog-content">
    <p>
      I/ We have read and understood the terms and conditions of the Policy and
      confirm to abide by the same.
    </p>

    <p>
      I/We hereby agree that the insurance coverage under the Policy will
      commence only on realization of full premium, receipt of complete medical
      reports (wherever applicable) and subject to medical underwriting approval
      by the Company. Receipt of proposal form by the Company shall not be
      construed as acceptance of proposal. Company in its sole discretion
      reserves the right to accept or reject any proposal without assigning any
      reasons thereof.
    </p>

    <p>
      I/We hereby declare that I/We will submit to medical examinations by the
      nominated doctors of the Company or undergo diagnostic or other medical
      tests, as suggested by the Company for its medical underwriting.
    </p>

    <p>
      I/We hereby agree that the Company reserves the right to enquire from any
      physicians, nurse, hospital official or employee or any person,
      institution for all or any information regarding the medical history of
      the proposed and that the Company shall have the right to ask the proposed
      for the medical check-up.
    </p>

    <p>
      I/We, the undersigned hereby declare that the above statements and
      particulars are true, accurate and complete and I/We declare and agree
      that this declaration and the answers given above shall be held to be
      promissory and shall be the basis of the contract between me/us and the
      Insurer.
    </p>

    <p>
      I/We authorize the Company and their agents to exchange, share or part
      with all the information relating to my/ our personal and financial
      details with Government bodies / Regulatory Authorities/ Statutory bodies,
      or under court orders as may be required and I/ we will not hold the
      Company and its agents liable for use of this information.
    </p>

    <p>
      I/we agree that the Policy shall become voidable at the option of the
      Insurer, in the event of any untrue or incorrect statement,
      misrepresentation, non-description or nondisclosure in any material
      particular in the Proposal form/personal statement, declaration and
      connected documents, or any material information has been withheld by
      me/us or anyone acting on my/our behalf to obtain any benefit under this
      policy.
    </p>

    <p>
      The information furnished by you in the proposal form has been recorded
      for future reference. Henceforth, if it comes to our notice that you have
      purchased another insurance policy(s) from us with the similar details as
      mentioned in the previous policy, we shall initiate cancellation of the
      later issued policy(s). We will process the refund of whole premium amount
      received for such policy(s). However, we may deduct transaction fee from
      the premium amount to be refunded.
    </p>
  </div>
</div>

<div class="container custom-dialog custom-bar" *ngIf="this.dialogData === 'declarationT'">
  <div class="sticky-top custom-header pt-2 pb-2 row">
    <h5>Declarations</h5>
    <div class="custom-close">
      <img class="custom-cross" (click)="close()" src="../../../../../assets/images/cross.png" alt="X">
    </div>
  </div>

  <div class="dialog-content">
    <p>
      1. The insured(s) covered under this policy is/are not travelling as a pilot or as an operator or as a crew
      member. Furthermore, the insured(s) is/are not visiting abroad for the purpose of Aviation Studies or
      Mountaineering or any form of Adventure Sports.
    </p>

    <p>
      2. I do hereby declare that I have read and understood the entire text, features, disclosures, benefits, terms and
      conditions of the policy and I further declare that the information furnished above are true to the best of my
      knowledge and no material information, which may be relevant, has been withheld or not disclosed. In case any of
      the information above is found false during verification at a later date, the company would have the right to
      cancel the policy and premium amount paid will be forfeited.
    </p>
  </div>
</div>

<div class="container custom-dialog custom-bar" *ngIf="this.dialogData === 'declarationH'">
  <div class="sticky-top custom-header pt-2 pb-2 row">
    <h5>Declarations</h5>
    <div class="custom-close">
      <img class="custom-cross" (click)="close()" src="../../../../../assets/images/cross.png" alt="X">
    </div>
  </div>

  <div class="dialog-content">
    <p>
      I/ We have read and understood the terms and conditions of the Policy and confirm to abide by the same.
    </p>
  </div>
</div>

<div class="container custom-dialog" *ngIf="this.dialogData === 'tnc'">
  <div class="sticky-top custom-header pt-2 pb-2 row">
    <h5>Terms and Conditions</h5>
    <div class="custom-close">
      <img class="custom-cross" (click)="close()" src="../../../../../assets/images/cross.png" alt="X">
    </div>
  </div>

  <div class="dialog-content">
    <p>
      I agree that the insurance benefit available to me shall become voidable
      in the event of any untrue or incorrect statement, misrepresentation,
      non-description or non-disclosure in any particulars in the application
      form / personal statement, declaration and connected documents or any
      material information has been withheld by me or anyone acting on my behalf
      to obtain insurance benefit. I hereby declare that I am not aware of any
      neglect and omission or error or existence of any circumstances likely to
      give rise to a claim thereof. I hereby declare, incase of false
      declaration the Insurance Company shall have the right to cancel the
      policy. The insurance company shall have the right to change the premium
      and conditions agreed to subject to such terms and conditions been
      communicated to me. I hereby agree that my enrollment for insurance would
      be at the sole discretion of the insurance company. I declare and warrant
      that the information I have given in this proposal form in relation to
      myself (or my other person or categories of person to be insured) and any
      documentation of information accompanying it or arising out of the answers
      I have given is complete and accurate in all respects. I understand and
      agree that this proposal and other information and documentation I have
      given or will give, relating to myself or any other person to be insured,
      will be the basis of any insurance that you may issue, and I also
      understood the consequences of any default. I also consent and authorize
      you to seek information from any doctor who has ever attended me or any
      other person to be insured, and also from any life or non-life insurance
      office to which a proposal for a critical illness or any other health
      cover has been made. Coverage subject to realization of payment.
    </p>

    <p><b>Online Transaction Facility - Terms and Conditions:</b></p>

    <p>
      I have read and understood the terms and conditions governing the Online
      Transaction Facility of ICICI Lombard General Insurance Company Limited
      through the internet. I agree to abide by the same.
    </p>

    <p>Third Party Payment Disclaimer</p>

    <p>
      Payment of premium by way of cheque/ demand draft/ credit card/ debit card
      should be made through the account or card pertaining to the proposer or
      the policy holder only. Any payment made through the account or card of a
      third party would render the contract of insurance invalid.
    </p>

    <p><b>IMPORTANT:</b></p>

    <p>
      The information that you give to us on this proposal or in any
      supplemental information form or documentation supplied by you or on your
      behalf will influence our decision to offer insurance and the terms upon
      which to offer it. Further, any policy we issue will be based on what you
      have told us. It is therefore important that your answers are complete and
      accurate in all respect.
    </p>

    <p>
      The questions in this proposal are indicative rather than exhaustive. You
      must provide us with all information relevant to the risk to be insured,
      even if it is not the subject of a question in this proposal. If you are
      in any doubt as to what information should be given, please email us at
      <a class="custom-anchor" href="customersupport@icicilombard.com">customersupport{{url}}</a>. Any failure
      to provide us with full and
      accurate information may mean that your claim under any insurance issued
      is rejected and your policy is declared void.
    </p>

    <p>
      No insurance cover will be in force until we have approved it and the
      premium and has been paid.
    </p>

    <p>
      Statutory Warning - PROHIBITION OF REBATES (Under Section 41 of Insurance
      Act 1938)
    </p>

    <p>
      No person shall allow or offer to allow, either directly or indirectly as
      an inducement to any person to take out or renew or continue an insurance
      in respect of any kind of risk relating to lives or property, in India,
      any rebate of the whole or part of the commission payable or any rebate of
      the premium shown on the policy, nor shall any person taking out or
      renewing or continuing a policy accept any rebate, except such rebate as
      may be allowed in accordance with the published prospectuses or tables of
      the Insurer.
    </p>

    <p>
      Any person making default in complying with the provisions of this section
      shall be punishable with fine, which may extend to five hundred rupees.
    </p>
  </div>
</div>

<div class="container custom-dialog" *ngIf="this.dialogData === 'tncT'">
  <div class="sticky-top custom-header pt-2 pb-2 row">
    <h5>Terms and Conditions</h5>
    <div class="custom-close">
      <img class="custom-cross" (click)="close()" src="../../../../../assets/images/cross.png" alt="X">
    </div>
  </div>

  <div class="dialog-content">
    <p>
      1. Any claim due to or arising out of pre-existing medical condition/ailment whether declared or undeclared is not
      covered under the Policy.
    </p>

    <p>
      2. Minimum age of the insured shall be 3 months and maximum age shall be 85 years.
    </p>

    <p>
      3. Policy start date should be on or before the trip start date.
    </p>

    <p>
      4. A policy may not be extended if a claim is already filed by the insured. If the insured does not declare the
      claims filed or the claims that will be filed on the policy for the original policy duration, the extension is
      deemed to be invalid. No refund of premium will be given. The company will also not be liable to pay any claim
      filed on these policies.
    </p>

    <p>
      5. The maximum number of travel days that may be insured, under the policy, shall be 180 days. Provided that the
      policy may be extended only once beyond the initial period of 180 days during the trip duration by a maximum of
      additional 180 days at the sole discretion of the Company. Provided further that for an Insured being up to the
      age of 60 years, the maximum trip duration (including the extension as provided earlier) shall not exceed 360 days
      in total, and for an Insured being more than 60 years of age, the maximum trip duration (including the extension
      as provided earlier) shall not exceed 180 days in total.
    </p>

    <p>
      6. No refunds will be given on policies with claims.
    </p>

    <p>
      7. Termination of the policy at a date earlier than the end date can be done only if the insured returns back to
      the Republic of India earlier than the end date of the policy. Refund of premium for the days between the arrival
      date and the end date of the policy will only be given if the same are minimum 30. A cancellation charge will be
      deducted from the refund premium. Premium refunded will be equal to the amount of premium to be paid for the
      original policy duration, minus the premium to be paid by taking the arrival date as the new end date.
    </p>

    <p>
      8. Any claim relating to events occurring before the commencement of the cover or otherwise outside of the Period
      of Insurance is not cover under this policy.
    </p>

    <p>
      9. Treatment will not be covered under the policy, if the insured
      a. Is traveling against the advice of a physician.
      b. Is receiving, or is on a waiting list to receive, specified medical treatment declared in a physician's report
      or certificate.
    </p>

    <p>
      10. Payment of premium by way of cheque/ demand draft/ credit card/ debit card should be made through the account
      or card pertaining to the proposer or the policy holder only. Any payment made through the account or card of a
      third party would render the contract of insurance invalid.
    </p>
  </div>
</div>

<div class="container custom-dialog" *ngIf="this.dialogData === 'tncH'">
  <div class="sticky-top custom-header pt-2 pb-2 row">
    <h5>Terms and Conditions</h5>
    <div class="custom-close">
      <img class="custom-cross" (click)="close()" src="../../../../../assets/images/cross.png" alt="X">
    </div>
  </div>

  <div class="dialog-content">
    <p>
      Warranted that Claim Experience for last 3 years is less than 5%
    </p>

    <p>
      Warranted that building's construction is of RCC
    </p>

    <p>
      Earthquake and Act of Terrorism are covered under Standard Fire and Special Perils
    </p>

    <p>
      Policy is subject to Local Authorities Clause attached herewith
    </p>

    <p>
      Policy shall stand canceled ab intio in the event of non-realization of the premium
    </p>

    <p>
      Policy subject to no storage/ manufacturing activities are being carried out on the premises
    </p>

    <p>
      Basement and contents therein are not covered under the Policy
    </p>

    <p>
      Policy is subject to Reinstatement Value Policies for Structure only and Market Value for Contents
    </p>

    <p>
      All Risk cover is inclusive of accidental damage to the structure and contents along with loss of contents due to
      burglary
    </p>

    <p>
      Kutcha construction is excluded under the policy
    </p>

    <p>
      FIR is mandatory in case of burglary related claims
    </p>

    <p>
      Jewellery will be covered up to max 25% of the overall content value or 5 lacs whichever is lower
    </p>

    <p>
      Jewellery, gold ornaments, silver articles and precious stones cover will be applicable to insured and insured's
      immediate family members (Spouse, kids and parents)
    </p>

    <p>
      Portable electronic equipment's is not covered under the policy
    </p>

    <p>
      Policy is applicable to the Contents not older than 10 years
    </p>
  </div>
</div>

<div class="container custom-dialog" *ngIf="this.dialogData === 'privacy'">
  <div class="sticky-top custom-header pt-2 pb-2 row">
    <h5>Privacy Policy</h5>
    <div class="custom-close">
      <img class="custom-cross" (click)="close()" src="../../../../../assets/images/cross.png" alt="X">
    </div>
  </div>

  <div class="dialog-content">
    <p>
      <b>
        ICICI LOMBARD PRIVACY POLICY
      </b>
    </p>

    <p>
      We value you as a customer and respect your right to privacy. In the process of operating the website, we may
      become aware of information relating to you, including information that is of a confidential nature. We are
      strongly committed to protecting your privacy online and have taken steps to protect such information. To aid us
      in protecting your privacy, you should maintain secrecy of your login ID and password, if any, provided to you in
      connection with your account with <a class=" custom-anchor"
        href="https://www.icicilombard.com/">www.icicilombard.com</a>
    </p>

    <p>
      <b>COLLECTING YOUR PERSONAL INFORMATION</b>
    </p>

    <p>
      ICICI Lombard may collect the information you provide when you:
    </p>

    <ul>
      <li>Request information from ICICI Lombard's website</li>
      <li>Use online tools and calculators (including voice enabled applications/assistance)</li>
      <li>Apply online for products</li>
      <li>Subscribe to online services</li>
      <li>Complete an online form</li>
      <li>Conduct transactions online</li>
      <li>Apply for a job online</li>
    </ul>

    <p>
      We collect contact information such as your name, address, email address and other details as given in the
      registration page of our website. If you purchase a policy from us, we will collect financial information such
      as
      credit card number and expiration date through the payment gateway of the credit card company or the internet
      banking service of your bank. We store this information for our records and to verify authenticity of the same.
      We
      also collect information such as beneficiary name, address and your relationship to the beneficiary when you buy
      a
      policy from us. This is required to maintain a record of your beneficiary details for your insurance with us.
    </p>
    <p>
      If you choose to use our referral service to tell a friend's about our site, we will ask you for your friends
      name
      and email address. Your friend will automatically be sent a one-time email inviting him or her to visit the
      website.
    </p>
    <p>
      <b>
        USE OF YOUR PERSONAL INFORMATION
      </b>
    </p>
    <p>
      We will use the information primarily for the following purposes:
    </p>
    <ul>
      <li>By using this website and on procuring quotations therein, you hereby authorize us to contact you via calls,
        mails
        and/or text messages on the contact details so provided, to furnish you with information with regards to the
        services and products of ICICI Lombard. This authorization shall be licit for the mentioned purposes
        irrespective
        of whether you are registered with the NDNC registry.</li>
      <li>Allow you to access specific account information.</li>
      <li>Providing customisation: We may use the information provided by you to customise your visit to the website
        by
        displaying appropriate content at our judgment and discretion.</li>
      <li>To send you information about products and services offered by ICICI Lombard and its affiliates, to contact
        you
        for policy reminder notices, claims processing and to keep you updated on the insurance sector and ICICI
        Lombard
        through our newsletters. In case you do not wish to receive such information, you may unsubscribe through the
        facility in the email message you receive.</li>
      <li>To contact you for policy reminder notices, claims processing and to keep you updated on the insurance
        sector
        and
        ICICI Lombard through our newsletters.</li>
    </ul>
    <p>
    <p>
      <b>
        LOG FILES
      </b>
    </p>
    <p>
      As is true of most websites, we gather certain information automatically and store it in log files. This
      information includes internet protocol (IP) addresses, browser type, internet service provider (ISP),
      referring/exit pages, operating system, date/time stamp, and clickstream data.
    </p>
    <p>
      We use this information, which does not identify individual users, to analyse trends, to administer the site, to
      track user movements around the site and to gather demographic information about our user base as a whole.
    </p>
    <p>
      IP addresses are tied to personally identifiable information to help us customise your visit to our website so
      that you like the experience. However, we would like to reiterate that this information is not shared with third
      parties, for their promotional purposes, except as provided under Disclosure of Information clause.
    </p>
    <p>
      We store a cookie on your computer when you visit our website. A cookie is a small text file that is stored on a
      user's computer for record-keeping purposes. The primary purpose of these cookies is to analyse how users move
      within our website. Our cookies let you view customised pages while transacting with us. Our cookies do not have
      confidential or personally identifiable information. Since we use session ID cookies, they do not track a user
      after leaving our Website. A session ID cookie expires when you close the browser. It is required if you want to
      buy a policy online from us. We also use analytic tools to track visitor traffic on website.
    </p>
    <p>
      Some of our business partners such as chat support use cookies on our site. We have no access to or control over
      these cookies. This privacy statement covers the use of cookies by <a class="custom-anchor"
        href="https://www.icicilombard.com/">www.icicilombard.com</a> only and does not cover
      the use of cookies by any third parties
    </p>
    <p>
      <b>
        DISCLOSURE OF INFORMATION
      </b>
    </p>
    <p>
      Subject to the other terms contained herein, we will not disclose your information to any third parties or
      affiliates for their promotional purposes, unless acting under a good faith belief that such action is necessary
      to:
    </p>
    <ul>
      <li>Conform to any statutory or legal requirements or comply with legal process or judicial orders</li>
      <li>Protect, enforce and defend our rights or property</li>
      <li>Protect our interests or interests of ICICI Bank Group Companies</li>
    </ul>
    <p>
      We may also use your personal information for the purposes of providing you with any services and
      service-related
      activities. In this regard, it may be necessary to disclose your personal information to one or more service
      providers (For example, Third Party administrators for cashless hospitalisation, National Do Not Call Registry
      for
      filtering before call related activities) of ICICI Lombard providing services linked to Insurance sector to
      fulfill your requests.
    </p>
    <p>
      We reserve the right to exchange, share or provide the information relating to your personal and financial
      details
      and information with other ICICI Bank Group Companies or statutory bodies as required and you will not hold us
      and/or any other group companies of ICICI Bank Group and their agents liable for use or sharing of the
      information.
    </p>
    <p>
      We obtain your permission to post testimonials which may include your personal information prior to posting on
      this website. If you would like your testimonial removed, please contact us at: <a class="custom-anchor"
        href="https://www.icicilombard.com/">insuranceonline{{url}}</a>
    </p>
    <p>
      We use service providers to fulfil some of the service requests on this site including live chat, third party
      administrators for cashless claims and health check-up and motor surveyors for vehicle inspection. We do not
      share
      or sell your personal information to third parties. These service providers are not allowed to use your
      information for their promotional purposes.
    </p>
    <p>
      <b>
        UPDATING YOUR PERSONAL INFORMATION
      </b>
    </p>
    <p>
      If you need to update your personal information or there is any change in the personal information, or if you no
      longer desire our service, you may update or delete it by making the change on our registration page or by
      emailing our website team at <a class="custom-anchor"
        href="https://www.icicilombard.com/">insuranceonline{{url}}</a> or by contacting us by telephone
      or
      postal mail at
      the contact information listed below.
    </p>
    <p>
      <b>
        SECURITY
      </b>
    </p>
    <p>
      The security of your personal information is important to us. When you enter sensitive information such as
      credit
      card number on our registration or order forms, we encrypt that information using secure socket layer technology
      (SSL). To learn more about SSL, follow this link <a class="custom-anchor"
        href="http://wp.netscape.com/eng/ssl3/">http://wp.netscape.com/eng/ssl3/</a>
    </p>
    <p>
      We follow generally accepted industry standards to protect the personal information submitted to us, both during
      transmission and once we receive it. No method of transmission over the internet, or method of electronic
      storage,
      is 100% secure, however. Therefore, while we strive to use commercially acceptable means to protect your
      personal
      information, we do not guarantee its absolute security.
    </p>
    <p>
      <b>
        LINKS TO OTHER SITES
      </b>
    </p>
    <p>
      The website may contain links to other sites and/or portals on the internet. While we intend to link only to
      such
      sites that share our high standards and respect for privacy, we are not responsible for the content or the
      privacy
      practices employed by such other sites.
    </p>
    <p>
      Please be aware that websites that have links from our site may collect personally identifiable information
      about
      you. This privacy statement does not cover the information and disclosure practices of those websites. When you
      leave our site, please read the privacy statements of each and every Website that collects personally
      identifiable
      information.
    </p>
    <p>
      <b>
        CHANGES IN THIS PRIVACY STATEMENT
      </b>
    </p>
    <p>
      We reserve the right to modify this privacy statement at any time by posting the same on the website, so please
      review it frequently on the website. If we materially change our privacy practices we will notify you by sending
      an email or by posting a notice on our website.
    </p>
    <p>
      <b>
        BUSINESS TRANSITIONS
      </b>
    </p>
    <p>
      In the event ICICI Lombard General Insurance Company Ltd. goes through a business transition, such as a merger,
      acquisition by another company, or sale of all or a portion of its assets, your personally identifiable
      information will likely be among the assets transferred. You will be notified [via email] [prominent notice on
      our
      website for 30 days] of any such change in ownership or control of your personal information.
    </p>
  </div>
</div>