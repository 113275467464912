<div class="container panaMaincontainer">
  <div class="mainDiv">
    <div class="steppermainDiv">
      <div class="breadcrum" *ngFor="let data of subheadingDetails">
        <!-- //make it dynamic as per the page name and discription -->
        <span class="cstmstep"><img src={{data.img}} alt=""></span>
        <span class="cstmstep">
          <h3>{{data.h3}}</h3>
          <p>{{data.p}}</p>
        </span>
      </div>
      <div class="backhidebox"></div>
      <mat-horizontal-stepper #stepper [linear]="linear" (selectionChange)="selectionChange($event)"
        [selectedIndex]="activeStepIndex">
        <!-- Dynamic construction of steps start here -->
        <mat-step *ngFor="let step of steps; let i = index" [completed]="step.completed">
          <ng-template matStepperIcon="edit">
            <mat-icon>
              <img class="pos" src="../../../assets/images/Header Icon/Edit-Icon.svg" alt="edit">
            </mat-icon>
          </ng-template>
          <ng-template matStepLabel>{{step.label}}</ng-template>
          <ng-container *ngIf="step.isActive">
            <ng-container *ngComponentOutlet="step.content" matStepContent></ng-container>
          </ng-container>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</div>