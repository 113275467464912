import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { UpdatePremiumData } from '../interface/custom-footer';

@Injectable({
  providedIn: 'root'
})
export class CustomFooterService {
  // Hide and show premium
  private showPremium = new BehaviorSubject<boolean>(false);
  showPremium$ = this.showPremium.asObservable();

  // Hide and show renewal footer
  private showRenewalFooterData = new BehaviorSubject<boolean>(false);
  showRenewalFooterData$ = this.showRenewalFooterData.asObservable();

  // Update Premium info
  private premiumInfoUpdated = new BehaviorSubject<UpdatePremiumData>({ update: false, data: '', value: '' });
  premiumInfoUpdated$ = this.premiumInfoUpdated.asObservable();

  constructor() { }

  showPremiumDetails(data: boolean) {
    this.showPremium.next(data);
  }

  updatePremiumDetails(data: UpdatePremiumData) {
    this.premiumInfoUpdated.next(data);
  }

  showRenewalFooter(data){
    this.showRenewalFooterData.next(data);
  }
}
