<div class="container-fluid">
    <div class="row">
      <div class="container panaMaincontainer">
        <div class="mainDiv uploadMainDiv">
          <mat-card class="innerbox reverinnerbox">
            <h3 class="pt-3">Upload Policy Document</h3>
            <div class="fieldsdiv">
              <div class="row ">
                <div class="col-sm-12">
                  <div class="befitplanmenu">
                    <h6>
                      <span class="uploadFile">
                        <img src=".../../../../../../../assets/images/Waiting Period.svg" alt="" class="uploadicon">
                      </span> Supported file JPG and PDF
                    </h6>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 form-group fileuploadDiv">
                  <mat-radio-group [(ngModel)]="selectedDoc">
                    <ul class="radio-list">
                      <li class="fileuploadradio" *ngFor="let item of uploadImgData;let i = index"
                        [ngStyle]="{'border-left':item.isManditory ? '5px solid red' : ''}">
                        <ng-container *ngIf="!item?.fileName">
                          <mat-radio-button [value]="item.id" (change)="changeUploadType(i)">
                            {{item.label}}
                          </mat-radio-button>
                          <div class="input_upload">
                            <label for="file-upload-{{i}}" class="custom-file-upload"
                              *ngIf="item.uploadText && selectedDoc == item.id">
                              <input id="file-upload-{{i}}" type="file" (change)="selectDocument(item, $event,i,0)">Upload
                            </label>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="item?.fileName">
                          <div class="filetype ">
                            <img src="../../../assets/images/pdf_image.png" alt="" class="filetypeimg"
                              *ngIf="item.extention == 'pdf'">
                            <img src="../../../assets/images/jpg_image.png" alt="" class="filetypeimg"
                              *ngIf="item.extention == 'jpg'">
                          </div>
                          <div class="fileudetails">
                            <h4>{{setFileName(item?.fileName)}}</h4>
                            <div class="filedescrib">
                              <span *ngIf="item.progress">{{item.progress}}% uploaded</span>
                            </div>
  
                            <mat-progress-bar class="" mode="determinate" [value]="item.progress"
                              *ngIf="item.progress"></mat-progress-bar>
  
                          </div>
                          <div class="previewSection ">
                            <span>
                              <img src="../../../assets/images/View.svg" alt="" class="previewimg"
                                (click)="previewImage(i)" *ngIf="item.previewIcon">
                            </span>
                            <span>
                              <img src="../../../assets/images/Cancel.svg" alt="" class="previewimg"
                                (click)="cancelUpload(i)">
                            </span>
                          </div>
                        </ng-container>
                      </li>
                    </ul>
                  </mat-radio-group>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
      <div class="footer" *ngIf="uploadImgData">
        <div class="col-sm-12 text-right">
          <button mat-raised-button class="primary-button" (click)="finalUpload()">Submit</button>
        </div>
      </div>
    </div>
  </div>
  
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" *ngIf="previewImageUrl !=''">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">{{modalTitle}}</div>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          <img [src]="previewImageUrl" style="margin-top: 0px; width: 100%; height: 100%; background-size: auto;" id="imgPreview" alt="" />
        </div>
      </div>
    </div>
  </div>