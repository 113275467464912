import { Component, OnInit } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { Constants } from 'src/app/shared/constants/constants';
import { popupType } from 'src/app/shared/enums/popupEnums';
import { payInSlipsResponseDataStructure } from 'src/app/shared/interface/pay-in-slips';
import { DownloadService } from 'src/app/shared/services/download.service';
import { PayInSlipService } from 'src/app/shared/services/pay-in-slips.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-pay-in-slip',
  templateUrl: './pay-in-slip.component.html',
  styleUrls: ['./pay-in-slip.component.scss']
})
export class PayInSlipComponent implements OnInit {

  payInSlipsMasterData = [];
  payInSlipsVisibleData = [];
  searchTerm: string = '';
  private interval: any;
  toggleValue: 'table' | 'card' = 'card';
  phText: string[] = ['Search for Payment ID',
    'Search for Policy PF Payment ID',
    'Search for PF Proposal No.',
    'Search for Cheque No.',
    'Search for Amount'];
  searchPlaceHolder: string;
  i: number = 0;
  scrolling: boolean = false;
  showCancel: boolean = false;
  isSelected: boolean = false;
  isSearchVisible: boolean = false;
  cardBtnInfo = [{
    label: 'Download',
    imgPath: '../../../assets/images/myPolicy/PayInSlip.svg',
  },
  {
    label: 'Cheque Status',
    imgPath: '../../../assets/images/chequestatus.svg',
  }]
  tableBtnInfo = [{
    label: 'Download',
    imgPath: '../../../../../../../assets/images/BBR/download.svg',
    tooltip: 'Download'
  },
  {
    label: 'Cheque Status',
    imgPath: '../../../../../../../assets/images/BBR/chequeStatus.svg',
    tooltip: 'Cheque Status'
  }]

  displayCol = [
    {
      columnDef: 'PaymentID',
      header: 'Payment ID',
      cell: (element: any) => `${element.PaymentID}`,
    },
    {
      columnDef: 'PFPaymentID',
      header: 'PF Payment ID',
      cell: (element: any) => `${element.PFPaymentID}`,
    },
    {
      columnDef: 'PFProposalNo',
      header: 'PF Proposal No',
      cell: (element: any) => `${element.PFProposalNo}`,
    },
    {
      columnDef: 'ChequeNumber',
      header: 'Cheque No.',
      cell: (element: any) => `${element.ChequeNumber}`,
    },
    {
      columnDef: 'ChequeDate',
      header: 'Cheque Date',
      cell: (element: any) => `${element.ChequeDate}`,
    },
    {
      columnDef: 'PaymentDate',
      header: 'Transaction Date',
      cell: (element: any) => `${element.PaymentDate}`,
    },
    {
      columnDef: 'Amount',
      header: 'Amount',
      cell: (element: any) => `${element.Amount}`,
    },
    {
      columnDef: 'Action',
      header: 'Action',
      cell: '',
    }
  ]
  subscription: Subscription[] = [];

  constructor(
    private payInSlipsService: PayInSlipService,
    private popupService: PopupService,
    public utilityService: UtilityService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.subscription.push(fromEvent(window, 'scroll')
      .subscribe(() => {
        this.onWindowScroll();
      }));
    let requestPayload = {
      policyType: '2'
    }
    this.payInSlipsService.fetchPayInSlipsData(requestPayload).subscribe(success => {
      if (success.StatusCode == Constants.statusCode_success) {
        this.payInSlipsMasterData = success.Data;
        // Whole master data is visible to user without filter
        this.payInSlipsVisibleData = this.payInSlipsMasterData;
      }
    })
    this.setPlaceHolderText();
    this.interval = setInterval(() => { this.setPlaceHolderText(); }, 2000);
  }

  onWindowScroll() {
    this.scrolling = true;

    if (window.scrollY === 0) {
      this.scrolling = false;
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  setPlaceHolderText() {
    this.searchPlaceHolder = this.phText[this.i];
    this.i++;
    if (this.i == this.phText.length) {
      this.i = 0;
    }
  }

  inputFocus() {
    this.showCancel = true;
  }

  inputBlur() {
    this.showCancel = false;
  }

  searchFilter() {
    this.isSearchVisible = true;
  }

  closeClear() {
    this.isSearchVisible = false;
    this.clearInputField();
  }

  clearInputField() {
    this.searchTerm = '';
    this.onSearch();
  }

  onToggle(event) {
    this.toggleValue = event.value;
  }

  onSearch() {
    if (!this.searchTerm) {
      this.payInSlipsVisibleData = this.payInSlipsMasterData;
    } else {
      this.payInSlipsVisibleData = this.payInSlipsMasterData.filter(item => {
        return Object.values(item).some(val =>
          val.toString().toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      });
    }
  }

  receiveDataAndCall(event) {
    if (event.identifier == 'Download') {
      this.downloadSlip(event.data);
    } else if (event.identifier == 'Cheque Status') {
      this.getChequeStatus(event.data);
    }
  }

  downloadSlip(slipDetails) {
    this.payInSlipsService.downloadPayInSlip({ PFPaymentID: slipDetails.PFPaymentID });
  }

  getChequeStatus(slipDetails) {
    this.payInSlipsService.getChequeStatus({ Payment_id: slipDetails.PaymentID.toString() }).subscribe(response => {
      if (response.StatusCode === Constants.statusCode_success) {
        let popupData: PopupModal = {
          popupType: popupType.chequeStatus,
          imgName: '',
          header: 'Cheque Status',
          description: response.Data.PaymentStatus,
          data: slipDetails,
          buttonLabel: ''
        }
        this.popupService.openChequeStatusPopup(popupData);
      }

    })
  }

  onRefresh(status) {
    if (status) {
      // this.getPayInSlipList();
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

}
