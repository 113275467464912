import { environment } from "src/environments/environment";
import { PendingPaymentResponsePayload, PendingPaymentsRequestPayload } from "../interface/pendingPayments";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { GenericService } from "./generic.service";
import { ConvertSavedQuotesRequestPayload, ConvertSavedQuotesResponsePayload, SavedQuotesRequestPayload, SavedQuotesResponsePayload } from "../interface/savedQuotes";

@Injectable({
    providedIn: 'root'
})

export class SavedQuotesService {

    constructor(private generic: GenericService) { }

    //'https://pana.insurancearticlez.com/apigateway/master/Policy/FetchPendingPayments' \
    readonly baseURI = `${environment.baseURL}`;
    readonly savedQuotesUrl = 'policy/MySavedQuotesV1';
    readonly convertSavedQuotesUrl = 'HealthAdvantedge/ConvertQuote';

    // Saved Quote API call
    fetchSavedQuotesData(data: SavedQuotesRequestPayload): Observable<SavedQuotesResponsePayload> {
        const endpoint = `${this.baseURI}${this.savedQuotesUrl}`;
        return this.generic.genericService<SavedQuotesResponsePayload>(endpoint, data);
    }

    // Convert API call
    convertSavedQuotes(data: ConvertSavedQuotesRequestPayload): Observable<ConvertSavedQuotesResponsePayload> {
        const endpoint = `${this.baseURI}${this.convertSavedQuotesUrl}`;
        return this.generic.genericService<any>(endpoint, data);
    }

}