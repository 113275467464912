import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GenericService } from '../services/generic.service';
import { FetchCustomerDetailsV1RequestPayload, FetchCustomerDetailsV1ResponsePayload, SearchCustomerRequestPayload, SearchCustomerResponsePayload, SaveEditCustomerRequestPayload, SaveEditCustomerResponsePayload, SearchIntermediaryResponsePayload } from '../interface/customer';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private generic: GenericService) {}
  
  readonly baseURI = `${environment.baseURL}Customer/`;
  readonly editCustomer = 'SaveEditCustomer';
  readonly searchCustomer = 'SearchCustomer';
  readonly fetchCustomerDetails = 'FetchCustomerDetailsV1';
  readonly SearchIntermediary = 'SearchIntermediary';

  saveEditCustomer(data: SaveEditCustomerRequestPayload): Observable<SaveEditCustomerResponsePayload> {
    const endpoint = `${this.baseURI}${this.editCustomer}`;
    return this.generic.genericService<SaveEditCustomerResponsePayload>(endpoint, data);
  }

  searchCustomerData(data: SearchCustomerRequestPayload): Observable<SearchCustomerResponsePayload> {
    const endpoint = `${this.baseURI}${this.searchCustomer}`;
    return this.generic.genericService<SearchCustomerResponsePayload>(endpoint, data);
  }

  fetchCustomerDetailsV1(data: FetchCustomerDetailsV1RequestPayload): Observable<FetchCustomerDetailsV1ResponsePayload> {
    const endpoint = `${this.baseURI}${this.fetchCustomerDetails}`;
    return this.generic.genericService<FetchCustomerDetailsV1ResponsePayload>(endpoint, data);
  }

  SearchIntermediaryData():Observable<SearchIntermediaryResponsePayload>{
    const endpoint = `${this.baseURI}${this.SearchIntermediary}`;
    return this.generic.genericService<SearchIntermediaryResponsePayload>(endpoint);
  }
}