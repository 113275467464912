import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { HapComponent } from '../hap/hap.component';
import { InsuredDetailsComponent } from '../../health-shared-components/insured-details/insured-details.component';
import { PortabilityComponent } from '../../health-shared-components/portability-details/portability-details.component';
import { KycDetailsComponent } from 'src/app/shared/components/kyc-details/kyc-details.component';
import { SummaryPageComponent } from '../../health-shared-components/summary-page/summary-page.component';
import { CustomFooterService } from 'src/app/shared/services/custom-footer.service';
import { CustomStepperService } from 'src/app/shared/services/custom-stepper.service';
import { SessionStorageService, StorageService } from 'src/app/shared/services/storage.service';
import { fromEvent, Subject, Subscription, takeUntil } from 'rxjs';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { StepData } from 'src/app/shared/interface/custom-stepper';
import { PopupService } from 'src/app/shared/services/popup.service';
import { HealthDataStorage } from '../../health.modal';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { popupType, popupImgPath, popupHeader, popupDescriptionMsg } from 'src/app/shared/enums/popupEnums';
import { HAPModal } from '../hap/hap-modal';
import { HealthDataStorageService } from '../../health.service';
import { routeEnums } from 'src/app/shared/enums/routeEnums';
import { GoldenShieldComponent } from '../golden-shield/golden-shield.component';
import { AspComponent } from '../asp/asp.component';
import { MaxProtectComponent } from '../max-protect/max-protect.component';
import { HealthBoosterComponent } from '../health-booster/health-booster.component';
import { FamilyshieldComponent } from '../family-shield/familyshield.component';
import { HealthElitePlusComponent } from '../health-elite-plus/health-elite-plus.component';
import { ZeroTatInsuredDetailsComponent } from '../../zero-tat-health-shared-component/zero-tat-insured-details/zero-tat-insured-details.component';
import { ZeroTatSummaryDetailsComponent } from '../../zero-tat-health-shared-component/zero-tat-summary-details/zero-tat-summary-details.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { ProductType } from 'src/app/shared/enums/healthEnums';
import { NavigationEnd, Router } from '@angular/router';
import { CritiShieldComponent } from '../criti-shield/criti-shield.component';
import { ConvertSavedQuotesResponsePayload } from 'src/app/shared/interface/savedQuotes';
import { ChannelData } from 'src/app/shared/interface/swapPrameters';
import { OrionHapComponent } from '../hap/orion-hap/orion-hap/orion-hap.component';
import { TravelDataStorageService } from '../travel/travel.service';
import { AgentMasterStructure } from 'src/app/shared/interface/agentV1';
import { InternationTravelComponent } from '../travel/internation-travel/internation-travel.component';
import { StudentMedicalTravelComponent } from '../travel/student-medical-travel/student-medical-travel.component';
import { TravelApplicantDetailsComponent } from '../travel/travel-applicant-details/travel-applicant-details.component';
import { TravelInsuredDetailsComponent } from '../travel/travel-insured-details/travel-insured-details.component';
import { TravelSummaryDetailsComponent } from '../travel/travel-summary-details/travel-summary-details.component';
import { ElevateComponent } from '../elevate/elevate.component';

@Component({
  selector: 'app-product-stepmanager',
  template: `
  <app-custom-stepper [steps]="steps" [subheadingDetails]="subheadingDetails" [linear]="true" *ngIf = "loadStepper">
  </app-custom-stepper>
  `,
  styleUrls: [],
  providers: [CustomFooterService, CustomStepperService, HealthDataStorageService]
})
export class ProductStepmanagerComponent implements OnInit, OnDestroy {
  steps: Array<StepData> = [];
  loadStepper: boolean = false
  activateStepOne: boolean = false;
  footerButtons = [];
  hapDataModal: HAPModal = new HAPModal(); //hap data modal 
  quoteDetails: ConvertSavedQuotesResponsePayload;

  private subscription: Subscription[] = [];
  hapFormData: HealthDataStorage;
  subheadingDetails = [];
  agentDetails: AgentMasterStructure;

  subheadingwithPortability = [
    { index: 0, img: '../../../assets/images/Header Icon/Basic Detail.svg', h3: 'Basic Details', p: 'Provide basic details to generate quote' },
    { index: 1, img: '../../../assets/images/Header Icon/Portability.svg', h3: 'Portability Details', p: 'Provide portability details to generate quote' },
    { index: 2, img: '../../../assets/images/Header Icon/Insured.svg', h3: 'Insured Details', p: 'Provide insured details to generate quote' },
    { index: 3, img: '../../../assets/images/Header Icon/KYC.svg', h3: 'Kyc & Applicant Details', p: 'Provide basic details to facilitate your KYC process' },
    { index: 4, img: '../../../assets/images/Header Icon/Summary and PAyment.svg', h3: 'Summary & Payment Details', p: 'Confirm details and proceed' }]

  subheading = [
    { index: 0, img: '../../../assets/images/Header Icon/Basic Detail.svg', h3: 'Basic Details', p: 'Provide basic details to generate quote' },
    { index: 1, img: '../../../assets/images/Header Icon/Insured.svg', h3: 'Insured Details', p: 'Provide insured details to generate quote' },
    { index: 2, img: '../../../assets/images/Header Icon/KYC.svg', h3: 'Kyc & Applicant Details', p: 'Provide basic details to facilitate your KYC process' },
    { index: 3, img: '../../../assets/images/Header Icon/Summary and PAyment.svg', h3: 'Summary & Payment Details', p: 'Confirm details and proceed' }]
  portability: boolean = false;
  isAuthenticate: boolean = false;

  deactivateRoute: boolean;
  previousURL: string;
  private unsubscriber: Subject<void> = new Subject<void>();
  productType: string = '';
  travelProducts = [];
  channelData: ChannelData;

  constructor(private customFooterService: CustomFooterService,
    private storageService: StorageService,
    private popupService: PopupService,
    private healthDataStorageService: HealthDataStorageService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private customStepperService: CustomStepperService,
    private utilityService: UtilityService,
    private travelDataStorageService: TravelDataStorageService) {
    history.pushState(null, '');
    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          this.previousURL = event.url;
          // Update the previous URL 
        }
      }
    );
  }

  ngOnInit(): void {
    this.agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
    let channelData: ChannelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
    if (!this.sessionStorageService.has(userEnums.SubProductCode)) {
      this.utilityService.mapSubProductCode(channelData.ST);

    }

    this.constructTravelDetails();

    this.productType = this.utilityService.productTypeStatus(this.storageService.getAESDecryptedData(userEnums.SubProductCode));

    if (!channelData || channelData && channelData.VT !== 'Mobile') {
      this.browserBackButtonDisabled();
    }
    this.storageService.setAESEncryptedData(userEnums.PreviousUrl, this.previousURL);
    // Refresh popup needs to be called in stepper
    // So for that we are fetching the data already stored
    this.hapFormData = this.healthDataStorageService.getHealthDataStorage();
    this.quoteDetails = this.storageService.getAESDecryptedData(userEnums.ConverToProposal);
    // Reset hap page when user back from plutus page
    // let plutusStatus = this.storageService.getAESDecryptedData(userEnums.Plutus);
    // if(plutusStatus) {
    //   this.healthDataStorageService.resetHealthDataStorage();
    //     this.initializeSteps();
    //     this.customFooterService.showPremiumDetails(false);
    //     this.loadStepper = true;
    // }
    // Call Refresh popup if the data is already set. 
    // This component will be only initialized when user hits refresh or first time quote is about to generate
    if (this.hapFormData?.quoteDataSet) {
      this.openRefreshPopup();
    }

    else if (!this.hapFormData?.quoteDataSet) {
      this.customStepperService.portabilityPresent(false);
      //  Based on zerotat
      if (this.productType == ProductType.ZeroTat) {
        this.initializeZeroTatSteps();
      }
      // For indemnity
      else if (this.productType == ProductType.Indemnity) {
        this.initializeSteps();
      }
      //For travel
      else if (this.productType == ProductType.Travel) {
        this.initializeTravelSteps();
      }

      this.customFooterService.showPremiumDetails(false);
      this.loadStepper = true;
    }

    // Check For portability. 
    // So when user changes step from new to portability call this method
    this.subscription.push(this.customStepperService.portabilityDataPresent$.subscribe(data => {
      if (this.steps.length > 0) {
        this.addPortabilityStep(data);
      }
    })
    )

    // Check for save quote value changes
    this.subscription.push(this.customStepperService.saveQuoteChanges$.subscribe(data => {
      if (data) {
        this.quoteDetails = this.storageService.getAESDecryptedData(userEnums.ConverToProposal);
      }
    })
    )

    // Activate steps based on button data emitted of each page

      this.subscription.push(this.customStepperService.stepData$.subscribe(data => {
        if (!this.quoteDetails) {
        this.activatedComponentCheck(data);
        }
      }))
    

    // Reset steps and make all the component active and  completed false
    // To reset the step to initial state
    this.customStepperService.resetStepperRequired$.subscribe(resetStep => {
      if (resetStep) {
        this.resetStep();
      }
    })

    //sub heading
    this.customStepperService.stepIndex$.subscribe(index => {
      let stepIndex = index == -1 ? 0 : index;
      this.subHeadingChange(stepIndex, this.portability);
    });

    // IPAA-176: Saved Quotes - Redirect saved quotes convert to summary page
    this.redirectToSummaryPage();
  }

  //remove this function after travel is live
  constructTravelDetails() {
    let route = (this.router.url).split('/');
    if (route[1] == routeEnums.TRAVEL) {
      this.storageService.setAESEncryptedData(userEnums.ProductType, routeEnums.TRAVEL);
      let productCode = route[2] == `${routeEnums.STUDENTMEDIALTRAVEL}` ? 3 : route[2] == `${routeEnums.INTERNATIONALTRAVEL}` ? 4 : '';
      this.travelDataStorageService.resetTravelDataStorage();
      let channelData: ChannelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
      // channelData.CIP = channelData.CN = 'NYSATRAVEL';
      channelData.PRDT = 'Travel';
      this.storageService.setAESEncryptedData(userEnums.SubProductCode, productCode);
      this.storageService.setAESEncryptedDataLocal(userEnums.ChannelData, channelData);
    }
    // Temproary changes. Changes should be done from channel data
    else {
      let channelData: ChannelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
      this.storageService.setAESEncryptedData(userEnums.ProductType, routeEnums.HEALTH);
      this.storageService.setAESEncryptedDataLocal(userEnums.ChannelData, channelData);
    }
  }

  // Browser Back button
  browserBackButtonDisabled() {
    if (!!window.location.pathname && window.location.pathname !== '/') {
      history.replaceState(null, '');
      history.pushState(null, '');
    }
    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {
      history.pushState(null, '');
    });
  }

  redirectToSummaryPage() {
    // Redirect to summary page
    if (this.quoteDetails) {
      this.storageService.setAESEncryptedData(userEnums.ProductType, this.quoteDetails.Data.GenericData.productType);
      let stepCount = 0;
      this.loadStepper = true;
      // this.customFooterService.showPremiumDetails(true);
      if (this.productType == ProductType.ZeroTat) {
        this.initializeZeroTatSteps();
      } else if (this.productType == ProductType.Indemnity) {
        this.initializeSteps();
      }
      this.portability = this.quoteDetails.Data.ProductType === "Portability" ? true : false;
      if (this.portability) {
        stepCount = 4;
        this.customStepperService.portabilityPresent(true);
      } else {
        stepCount = 3;
      }

      for (let i = 0; i <= stepCount; i++) {
        this.steps[i].isActive = true;
        this.steps[i].completed = true;
        if (stepCount == 3 || stepCount == 4) {
          this.steps[stepCount].completed = false;
        }
      }
      this.storageService.setAESEncryptedData(userEnums.StepperDetails, this.steps);
      this.customStepperService.redirectToStep('f5', this.portability);
    }
  }

  getProductType() {
    // Based on route differentiate the products
    let route = (this.router.url).split('/')[2];
    switch (route) {
      case routeEnums.HAP: {
        this.steps[0].content = HapComponent
        break;
      }
      case routeEnums.OrionHap: {
        this.steps[0].content = OrionHapComponent
        break;
      }
      case routeEnums.Elevate: {
        this.steps[0].content = ElevateComponent
        break;
      }
      case routeEnums.GoldenShield: {
        this.steps[0].content = GoldenShieldComponent
        break;
      }
      case routeEnums.ASP: {
        this.steps[0].content = AspComponent
        break;
      }
      case routeEnums.MaxProtect: {
        this.steps[0].content = MaxProtectComponent
        break;
      }
      case routeEnums.HEALTH_BOOSTER: {
        this.steps[0].content = HealthBoosterComponent
        break;
      }
      case routeEnums.HealthElitePlus: {
        this.steps[0].content = HealthElitePlusComponent
        break;
      }
      case routeEnums.INTERNATIONALTRAVEL: {
        this.steps[0].content = InternationTravelComponent
        break;
      }
      case routeEnums.STUDENTMEDIALTRAVEL: {
        this.steps[0].content = StudentMedicalTravelComponent
        break;
      }
      case routeEnums.Familyshield: {
        this.steps[0].content = FamilyshieldComponent
        break
      }
      case routeEnums.CritiShield: {
        this.steps[0].content = CritiShieldComponent
        break
      }
      default : {
        break;
      }
    }
  }

  initializeSteps() {
    this.steps = [
      { label: 'Quote', isActive: true, completed: false, id: 'f1', content: null },
      { label: 'Insured', isActive: false, completed: false, id: 'f3', content: InsuredDetailsComponent },
      { label: 'KYC', isActive: false, completed: false, id: 'f4', content: KycDetailsComponent },
      { label: 'Summary', isActive: false, completed: false, id: 'f5', content: SummaryPageComponent }
    ]
    this.getProductType();
  }

  initializeZeroTatSteps() {
    this.steps = [
      { label: 'Quote', isActive: true, completed: false, id: 'f1', content: null },
      { label: 'Insured', isActive: false, completed: false, id: 'f3', content: ZeroTatInsuredDetailsComponent },
      { label: 'KYC', isActive: false, completed: false, id: 'f4', content: KycDetailsComponent },
      { label: 'Summary', isActive: false, completed: false, id: 'f5', content: ZeroTatSummaryDetailsComponent }
    ]
    this.getProductType();
  }

  initializeTravelSteps() {
    this.steps = [
      { label: 'Quote', isActive: true, completed: false, id: 'f1', content: null },
      { label: 'Insured', isActive: false, completed: false, id: 'f3', content: TravelInsuredDetailsComponent },
      { label: 'KYC', isActive: false, completed: false, id: 'f4', content: TravelApplicantDetailsComponent },
      { label: 'Summary', isActive: false, completed: false, id: 'f5', content: TravelSummaryDetailsComponent }
    ]
    this.getProductType();
  }

  addPortabilityStep(portabilityDataPresent: boolean) {
    // If portability data is present then we need to push it after quote page at index 1
    if (this.steps.length > 0) {
      if (portabilityDataPresent) {
        let portabilityObject = { label: 'Portability', isActive: false, completed: false, id: 'f2', content: PortabilityComponent };
        this.steps.splice(1, 0, portabilityObject);
        this.portability = true;
      }
      // If portability data not present then we need to simply remove portability object if present
      else if (!portabilityDataPresent) {
        if (this.steps[1].id == 'f2') {
          this.steps.splice(1, 1);
          this.portability = false;
        }
      }
    }
  }

  subHeadingChange(index, portability) {
    // this.subheading.filter(x=>x.index == index);
    let data = [];
    let details = [];
    details = portability ? this.subheadingwithPortability : this.subheading;
    details.forEach(x => {
      if (x.index == index) {
        data.push(x);
      }
    })
    this.subheadingDetails = data;
  }

  activatedComponentCheck(componentStatusData: Object) {
    this.steps = this.customStepperService.activatedComponentCheck(componentStatusData, this.steps);
  }

  openRefreshPopup() {
    let popupData: PopupModal = {
      popupType: popupType.confirmation,
      imgName: popupImgPath.warningGif,
      header: popupHeader.refreshData,
      description: popupDescriptionMsg.refreshDescription,
      buttonLabel: '',
      buttonAction: ''
    }
    this.popupService.openGenericPopup(popupData);
    this.popupService.refreshForm$.subscribe(status => {
      if (status) {
        // So if user wants to refresh the data this block will be executed
        // So on refresh it sets to initial stage where no portabilty is present
        // and footer should also be disabled
        this.healthDataStorageService.resetHealthDataStorage();
        // Based on zerotat and indemnity initialize steps
        if (this.productType == ProductType.ZeroTat) {
          this.initializeZeroTatSteps();
        }
        else if (this.productType == ProductType.Indemnity) {
          this.initializeSteps();
        }
        else if (this.productType == ProductType.Travel) {
          this.initializeTravelSteps();
        }
        this.customFooterService.showPremiumDetails(false);
        this.loadStepper = true;
      }

      else if (!status) {
        // While refreshed if user chooses to load previous data then we need to get the stepper state also back to 
        // last stored state
        //  if (this.sessionStorageService.has(userEnums.StepperDetails)) {
        // Initialize your stepper
        // Based on zerotat and indemnity initialize steps
        if (this.productType == ProductType.ZeroTat) {
          this.initializeZeroTatSteps();
        }
        else if (this.productType == ProductType.Indemnity) {
          this.initializeSteps();
        }
        else if (this.productType == ProductType.Travel) {
          this.initializeTravelSteps();
        }
        let tempSteps: StepData[] = this.storageService.getAESDecryptedData(userEnums.StepperDetails)
        //  Once initialization is done. Replace it with the values already stored  (stepper details)

        // If portability data is present then add portability step
        if (this.steps.length > 0) {
          if (this.hapFormData?.quoteFormData.productType == this.hapDataModal.productType[1]) {
            this.addPortabilityStep(true);
            this.portability = true;
          }
          // extra check if it's not portability then set to false
          else {
            this.addPortabilityStep(false);
            this.portability = false;
          }
        }
        // And now whatever value is stored just map it with the existing step
        // Below logic is used to load all the steps that was stored. 
        // And all the steps will be editable.

        // But since there is dynamic rendering of components . All api is called at once
        // this.steps.forEach((data, index) => {
        //   data.isActive = tempSteps[index].isActive;
        //   data.completed = tempSteps[index].completed;
        // })
        // Premium details should be visible if we are loading previous data 
        this.customFooterService.showPremiumDetails(true);
        this.loadStepper = true;
        // }
      }
    })
  }

  resetStep() {
    // SO when this is called. Only first step should be active 
    // And all step should be complete
    this.steps = this.customStepperService.resetStep(this.steps);
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
    this.subscription.forEach(subs => {
      subs.unsubscribe();
    })
  }
}
