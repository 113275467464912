import { environment } from "src/environments/environment";
import { PendingPaymentResponsePayload, PendingPaymentsRequestPayload } from "../interface/pendingPayments";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { GenericService } from "../services/generic.service";


@Injectable({
    providedIn: 'root'
})

export class PendingPaymentService {

    constructor(private generic: GenericService) { }

    readonly baseURI = `${environment.baseURL}`;
    readonly fetchPendingPayments = 'Policy/FetchPendingPaymentsV1';

    pendingPayments(data: PendingPaymentsRequestPayload): Observable<PendingPaymentResponsePayload> {
        const endpoint = `${this.baseURI}${this.fetchPendingPayments}`;
        return this.generic.genericService<PendingPaymentResponsePayload>(endpoint, data);
    }
}