<form [formGroup]="TravelSummary">

    <mat-card class="innerbox plandetailPanel marginbox">
        <h3>Journey Details
            <span class="float-right">
                <img src="../../../../../assets/images/edit.png" alt=""  (click)="navigateToPage('f1')" alt="" class="edit" >
            </span>
        </h3>
        <div class="fieldsdiv">
            <div class="row customDiv planrowData">
                <div class="col-lg-3 col-md-3 col-sm-3 form-group">
                    <mat-label class="mtlabel" for="myInput">Plan Name</mat-label>
                    <p>
                        <i class="health"></i>
                        {{this.travelFormData.quoteFormData.productName}} 
                    </p>
                </div>
                <div class="col-md-2 col-sm-2 form-group" *ngIf="travelFormData.quoteFormData.productName !== 'Student Travel'">
                    <mat-label class="mtlabel" for="myInput">Sub-Plan Name</mat-label>
                    <p>{{this.travelFormData.quoteFormData.subProduct}}</p>
                </div>

                <div class="col-md-2 col-sm-2 form-group" *ngIf="travelFormData.quoteFormData.productName !== 'Student Travel'">
                    <mat-label class="mtlabel" for="myInput">Duration Of Trip</mat-label>
                    <p>{{this.travelFormData.quoteFormData.noOfTripDays}}</p>
                </div>

                <div class="col-md-2 col-sm-2 form-group" *ngIf="travelFormData.quoteFormData.productName == 'Student Travel'">
                    <mat-label class="mtlabel" for="myInput">Duration Of Trip</mat-label>
                    <p>{{this.travelFormData.quoteFormData.policyDuration}}</p>
                </div>

                <div class="col-md-2 col-sm-2 form-group"*ngIf="travelFormData.quoteFormData.productName !== 'Student Travel'" >
                    <mat-label class="mtlabel" for="myInput">Number Of Traveller</mat-label>
                    <p>{{this.travelFormData.quoteFormData.noOfTraveller}} </p>
                </div>

                
                <div class="col-md-2 col-sm-2 form-group" *ngIf="travelFormData.quoteFormData.productName == 'Student Travel'">
                    <mat-label class="mtlabel" for="myInput">Number Of Traveller</mat-label>
                    <p>1 </p>
                </div>
                <div class="col-md-2 col-sm-2 form-group" >
                    <mat-label class="mtlabel" for="myInput">Plan Type</mat-label>
                    <p>{{this.travelFormData.quoteFormData.plan}} </p>
                </div>
               
            </div>

        </div>
        <mat-divider></mat-divider>
    </mat-card>

<ng-container  *ngIf="travelFormData.quoteFormData.productName !== 'Student Travel'">
    <mat-card class="innerbox reverinnerbox" *ngFor="let memberData of MemberDetailsData;let i=index;">
        <h3 class="pt-3">Insured {{i+1}} Details
            <span class="float-right">
                <img src="../../../../assets/images/edit.png" alt="" (click)="navigateToPage('f3')" alt="" class="edit" >
            </span>
        </h3>
        <div class="fieldsdiv" >
            <div class="row customDiv mb-0">
               
                <div class="col-sm-3 form-group width100 order-1 order-sm-1">
                    <mat-label class="mtlabel" for="myInput">Full Name</mat-label>
                    <p class="titlecase">{{memberData.fullName.toLowerCase()}}</p>
                </div>
                <div class="col-lg-2 col-sm-2 form-group width50 order-3 order-sm-3 ">
                    <mat-label class="mtlabel" for="myInput">Relationship With Applicant</mat-label>
                    <p>{{memberData.applicantRelationship }}</p>
                </div>
                <div class="col-lg-2 col-sm-2 form-group width50 order-2 order-sm-2">
                    <mat-label class="mtlabel" for="myInput">DOB</mat-label>
                    <!-- <p>{{utilitySerice.formatDateWithDash(memberData.dob)}}</p> -->
                    <p> {{ memberData.dob | date:'dd/MM/yyyy' }} </p>
                </div>
                <div class="col-lg-3 col-sm-3 form-group width50 order-4 order-sm-4"
                    >
                    <mat-label class="mtlabel" for="myInput">Nominee Name</mat-label>
                    <p>{{memberData.nomineeName}}</p>
                </div>

                <div class="col-lg-3 col-sm-3 form-group width50 order-4 order-sm-4"
                    >
                    <mat-label class="mtlabel" for="myInput"> Relation with Nominee </mat-label>
                    <p>{{memberData.relationshipWithNominee}}</p>
                </div>
                <div class="col-lg-2 col-sm-2 form-group width50 order-4 order-sm-4"
                    >
                    <mat-label class="mtlabel" for="myInput">Passport Number</mat-label>
                    <p>{{memberData.passportNumber}}</p>
                </div>
                

                <mat-divider></mat-divider>
            </div>
        
            
        </div>
    </mat-card>
</ng-container>


    <mat-card class="innerbox borderinnerbox">
        <h3 class="pt-3">Applicant Details
            <span class="float-right">
                <img src="../../../../../assets/images/edit.png" alt="" (click)="navigateToPage('f4')" alt="" class="edit"   >
            </span>
        </h3>
        <div class="fieldsdiv">
            <div class="row customDiv">
                <div class="col-sm-3 form-group width100">
                    <mat-label class="mtlabel" for="myInput">Full Name</mat-label>
                    <p>{{this.travelFormData.kycFormData.applicantDetails.applicantFullName}}</p>
                </div>
                <div class="col-lg-3 col-sm-3 form-group width50">
                    <mat-label class="mtlabel" for="myInput">Mobile No.</mat-label>
                    <p>{{this.travelFormData.kycFormData.permanentAddress ['applicantMobileNumber']}}</p>
                </div>
                <div class="col-lg-3 col-sm-3 form-group">
                    <mat-label class="mtlabel" for="myInput">Email ID</mat-label>
                    <p>{{this.travelFormData.kycFormData.permanentAddress ['applicantEmailAddress']}}</p>
                </div>
                <div class="col-sm-6 form-group width100">
                    <mat-label class="mtlabel" for="myInput">Address</mat-label>
                    <p class="titlecase">{{this.travelFormData?.kycFormData?.permanentAddress?.permanentAddress1.toLowerCase() }}
                        {{this.travelFormData?.kycFormData?.permanentAddress?.permanentAddress2.toLowerCase() }}
                        {{this.travelFormData?.kycFormData?.permanentAddress?.permanentLandmark.toLowerCase() }}
                        {{this.travelFormData?.kycFormData?.permanentAddress?.permanentPincode.toLowerCase() }}
                        {{this.travelFormData?.kycFormData?.permanentAddress?.permanentState.toLowerCase() }}
                        {{this.travelFormData?.kycFormData?.permanentAddress?.permanentCity.toLowerCase() }}
                    </p>
                </div>
            </div>
        </div>
       
       
        <mat-divider></mat-divider>
    </mat-card>


    <mat-card class="innerbox borderinnerbox" *ngIf="travelFormData.quoteFormData.productName == 'Student Travel'">
        <h3 class="pt-3">University Details
            <span class="float-right">
                <img src="../../../../../assets/images/edit.png" alt=""(click)="navigateToPage('f4')" alt="" class="edit"  >
            </span>
        </h3>
        <div class="fieldsdiv">
            <div class="row customDiv">
                <div class="col-lg-3 col-sm-3 form-group">
                    <mat-label class="mtlabel" for="myInput">Adressline1</mat-label>
                    <p>{{this.travelFormData.kycFormData.universityDetails.instituteAddress1}}</p>
                </div>

                <div class="col-lg-3 col-sm-3 form-group" *ngIf="travelFormData.kycFormData.universityDetails.instituteAddress2">
                    <mat-label class="mtlabel" for="myInput">Adressline2</mat-label>
                    <p>{{this.travelFormData.kycFormData.universityDetails.instituteAddress2}}</p>
                </div>
                <div class="col-lg-3 col-sm-3 form-group width50">
                    <mat-label class="mtlabel" for="myInput">City</mat-label>
                    <p>{{this.travelFormData.kycFormData.universityDetails.instituteCity}}</p>
                </div>

                <div class="col-sm-3 form-group width100">
                    <mat-label class="mtlabel" for="myInput">University Name</mat-label>
                    <p>{{this.travelFormData.kycFormData.universityDetails.instituteName}}</p>
                </div>

                <div class="col-sm-3 form-group width100">
                    <mat-label class="mtlabel" for="myInput">Pincode</mat-label>
                    <p>{{this.travelFormData.kycFormData.universityDetails.institutePincode}}</p>
                </div>
               
                <div class="col-sm-3 form-group width100">
                    <mat-label class="mtlabel" for="myInput">State</mat-label>
                    <p>{{this.travelFormData.kycFormData.universityDetails.instituteState}}</p>
                </div>
               
            </div>
        </div>
       
        <mat-divider></mat-divider>
    </mat-card>
    
    <mat-card class="innerbox borderinnerbox">
        <h3>Declarations
        </h3>
        <div class="fieldsdiv mb-4">
            <div class="row">
                <div class="col-12">
                    <table class="mediTestTable">
                        <tr>
                            <td style="vertical-align: top;">1.</td>
                            <td> The insured(s) covered under this
                                policy is/are not travelling as a pilot or as an
                                operator or as a crew member. Furthermore, the insured(s) is/are not visiting abroad for the
                                purpose of Aviation Studies or Mountaineering or any form of Adventure Sports.</td>
                        </tr>
                    </table>
                  </div>
                  <div class="col-12">
                    <table class="mediTestTable">
                        <tr>
                            <td style="vertical-align: top;">2.</td>
                            <td>
                                I do hereby declare that I have read
                      and understood the entire text, features,
                      disclosures, benefits, terms and conditions of the policy and I further declare that the
                      information furnished above are true to the best of my knowledge and no material information,
                      which may be relevant, has been withheld or not disclosed. In case any of the information above is
                      found false during verification at a later date, the company would have the right to cancel the
                      policy and premium amount paid will be forfeited.
                            </td>
                        </tr>
                    </table>
                  </div>
                <div class="col-sm-12">
                    <table class="mediTestTable">

                        

                        <!-- <caption class="hidden-caption">Details</caption>
                        <th class="hidden-caption">Declarations</th>
                        <td class="hidden-caption">Agent Declaration</td> -->
                        <tr>
                            <td class="customCheckslist" style="vertical-align: top;">
                                <ul>
                                    <li>
                                        <mat-checkbox class="cstmCheckbox" [checked]="true"
                                            formControlName="medicalDeclaration1"></mat-checkbox>
                                    </li>
                                </ul>
                            </td>
                            <td>



                              <!-- <ng-container >
                                  <p>
                                        I {{rnAgent}} accept <a
                                            (click)="openDeclarationPopup('agentConfidentialityReport')">
                                            <span class="custom-anchor">Agent Declaration
                                            </span> </a>
                                 </p>
                             </ng-container> -->
                             <ng-container >
                                <p>
                                       I accept the Declaration and agree to the Terms & Condition.
                                          
                               </p>
                           </ng-container>
                              

                            </td>
                        </tr>
                    </table>
                    <mat-error class="ml-4 error-margin"
                        *ngIf="this.TravelSummary.controls['medicalDeclaration1'].value==false">
                        Please accept Declaration.
                    </mat-error>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <table class="mediTestTable">
                        <!-- <caption class="hidden-caption">Details</caption> -->
                        <!-- <th class="hidden-caption">Declarations</th> -->
                        <td class="hidden-caption">Agent Declaration</td>
                        <tr>
                            <td class="customCheckslist" style="vertical-align: top;">
                                <ul>
                                    <li>
                                        <mat-checkbox class="cstmCheckbox" [checked]="true"
                                            formControlName="medicalDeclaration2"></mat-checkbox>
                                    </li>
                                </ul>
                            </td>
                            <td>
                               <span>
                                    <!-- I accept the <a (click)="openDeclarationPopup('generalDeclaration')"> <span
                                            class="custom-anchor">Agent Declaration</span></a> and agree to
                                    the <a (click)="openDeclarationPopup('tncDeclaration')"><span
                                            class="custom-anchor">Terms & Conditions</span></a> and
                                    <a (click)="openDeclarationPopup('privacyPolicy')"><span
                                            class="custom-anchor">Privacy
                                            Policy </span></a> -->
                                            I  accept <a
                                            (click)="openDeclarationPopup('agentConfidentialityReport')">
                                            <span class="custom-anchor">Agent's Confidentiality Report
                                            </span> </a> Declaration

                                        </span> 
                            </td>
                        </tr>
                    </table>
                    <mat-error class="ml-4 error-margin"
                        *ngIf="this.TravelSummary.controls['medicalDeclaration2'].value==false">
                        Please accept the declaration and agree to the Terms & Conditions and Privacy Policy
                    </mat-error>
                </div>
            </div>
        </div>
    </mat-card>

</form>

<app-custom-footer [normalButton]="false" [buttonData]="[
{'label': 'Pay Now', 'type': 'secondary', 'source': 'summary', 'id': 'f5','disabled': false , 'hide': false },
{'label': 'Pay Later', 'type': 'secondary', 'source': 'summary', 'id': 'f5','disabled': false , 'hide': false },
{'label': 'Payment Link', 'type': 'primary', 'source': 'summary', 'id': 'f5','disabled': false , 'hide': false },
{'label': 'Choose Payment', 'type': 'primary', 'source': 'summary', 'id': 'f5','disabled': false , 'hide': false },
]" (buttonClicked)="paymentModeSelected($event)">
</app-custom-footer>

