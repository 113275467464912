import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetRenewalCountRequestPayload, GetRenewalCountResponsePayload, getCibilScoreRequestPayload } from '../interface/agent';
import { GenericService } from '../services/generic.service';
import { CreateLoginTokenRequest, CreateNotificationLoginTokenRequest } from '../interface/token';
import { HttpHeaders } from '@angular/common/http';
import { EncryptDecryptService } from '../services/encrypt-decrypt.service';

@Injectable({
    providedIn: 'root'
})
export class AgentService {

    constructor(private generic: GenericService,
        private encryptDecryptService: EncryptDecryptService) {
    }

    readonly baseURI = environment.baseURL + 'Agent/';
    readonly serviceName = 'GetRenewalCount';
    readonly cibilScore = 'GetCibilScore'

    renewalCountData(data: GetRenewalCountRequestPayload): Observable<GetRenewalCountResponsePayload> {
        const endpoint = `${this.baseURI}${this.serviceName}`;
        return this.generic.genericService<GetRenewalCountResponsePayload>(endpoint, data);
      }

      getCibilScore(data: getCibilScoreRequestPayload): Observable<any> {
        const endpoint = `${this.baseURI}${this.cibilScore}`;
        return this.generic.genericService<any>(endpoint, data);
      }
 

    setUserHeaders(data?) {
        let headers = new HttpHeaders();
        headers = headers.append('Access-Control-Allow-Methods', 'GET,POST');
        headers = headers.append("Uowkjsdjssrme", this.encryptDecryptService.finalRsaEncryption(data.Uowkjsdjssrme));
        headers = headers.append("Powkjsdjssrd", this.encryptDecryptService.finalRsaEncryption(data.Powkjsdjssrd));
        return headers;
    }

    createApiToken(data: CreateNotificationLoginTokenRequest): Observable<any> {
        const headers = this.setUserHeaders(data);
        return this.generic.genericService(environment.baseURL + 'Notification/CreatePlutusLoginToken', '', 'json', headers)
    }
}
