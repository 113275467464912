<form [formGroup]="healthAdvantageForm" *ngIf="!multipleServiceError && loadFormContent">

    <!-- Product Details Card Starts -->

    <div class="">
        <mat-card class="innerbox borderinnerbox">
            <h3>Product Name & Type</h3>
            <div class="fieldsdiv">
                <div class="row">

                    <div class="col-sm-3 form-group order-1 order-sm-1 mb0">
                        <mat-label class="input-group__label" for="myInput"> Product
                            name</mat-label>

                        <input type="text" matInput class="input-group__input health planselect"
                            placeholder="Enter product" type="text" formControlName="productName" id="productName" autocomplete="off"
                            readonly>

                    </div>

                    <div class="col-sm-3 form-group order-2 mb0">
                        <label class="input-group__label" style="top: 13px;">Product type</label>
                        <input class="input-group__input cstm_select" placeholder="Super Top Up Plan" readonly
                            formControlName="productType" id="productType" [(ngModel)]="HBPtype" required>
                        <mat-error *ngIf="hasError('productType', 'required') && formSubmitted">
                            Please select product type
                        </mat-error>
                    </div>

                    <div class="col-sm-3 form-group order-3 order-sm-3">
                        <mat-label class="input-group__label" for="myInput">PIN
                            code</mat-label>
                        <input matInput class="input-group__input" placeholder="Enter pincode" type="text"
                            formControlName="pincode" id="pincode" [minlength]="6" [maxlength]="6"
                            (input)="fetchDataAsPerPincode($event,null)" autocomplete="off">

                        <mat-error *ngIf="hasError('pincode', 'required') && formSubmitted">
                            Pincode is required
                        </mat-error>

                        <mat-error *ngIf="(hasError('pincode','maxlength') || hasError('pincode','minlength')) && formSubmitted">
                            Atleast 6 digit pincode is required
                        </mat-error>

                        <mat-error *ngIf="!hasError('pincode','minlength') && hasError('pincode','pattern')  && formSubmitted">
                            Please enter only numbers
                        </mat-error>

                        <mat-error *ngIf="invalidPinCodeMessage!== ''">
                            {{invalidPinCodeMessage}}
                        </mat-error>

                    </div>

                    <div class="col-xl-3 col-md-4 col-sm-3 form-group order-4 order-sm-4 stateCititeswidth"
                        *ngIf="this.stateFetched">
                        <mat-label class="input-group__label" for="myInput">State & Cities</mat-label>
                        <!-- If length is 1 -->
                        <input type="text" matInput class="input-group__input" placeholder="Enter pincode" type="text"
                            formControlName="cityState" readonly *ngIf="cityData.length == 1" autocomplete="off">


                        <mat-select formControlName="cityState" id="cityState" required
                            class="input-group__input  cstm_select" placeholder="Select state and city"
                            (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                            (selectionChange)="changeCityName($event.value,true)" *ngIf="cityData.length > 1">
                            <ng-container *ngFor="let item of cityData; let last = last">
                                <mat-option value={{item.value}}>
                                    {{item.stateLabel}} - {{item.cityLabel}}
                                </mat-option>
                                <mat-divider *ngIf="!last"></mat-divider>
                            </ng-container>
                        </mat-select>
                        <mat-error *ngIf="hasError('cityState', 'required') && formSubmitted">
                            Please select atleast one option
                        </mat-error>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
    <!-- Product Details Card Ends -->

    <!-- Member Detail Card Starts -->
    <mat-card class="innerbox borderinnerbox">
        <h3>Member Details</h3>
        <div class="fieldsdiv">
            <div class="row">

                <!-- Adult Panel -->
                <div class="col-sm-3 form-group">
                    <label for="" class="singlelabel" style="width: 100%;">Select Adult</label>
                    <div class="memberbtn2" style="min-width:50%">
                        <!-- Adult 1 -->
                        <input type="checkbox" class="member__input2" value="1" name="myradio18" id="myradio18"
                            [checked]="adultCountData[0].selected" (click)="adultCountSelected('1',$event)">
                        <label class="member__label2 one-member" for="myradio18">
                            <img src="../../../../../assets/images/Single Adult.svg" alt="" class="icon">
                        </label>
                        <!-- Adult 2 -->
                        <input type="checkbox" class="member__input2" value="2" name="myradio18" id="myradio19"
                            [checked]="adultCountData[1].selected" (click)="adultCountSelected('2',$event)">
                        <label class="member__label2 two-member " for="myradio19">
                            <img src="../../../../../assets/images/Single Adult.svg" alt="" class="icon">
                            <img src="../../../../../assets/images/Single Adult.svg" alt="" class="icon">
                        </label>
                    </div>
                </div>

                <!-- Child Panel -->
                <div class="col-sm-3 form-group">
                    <label for="" class="singlelabel" style="width: 100%;">Select Child</label>
                    <div class="memberbtn2"
                        [ngClass]="{'border-0': childCountData[1].hide,'childSizeFix':childCountData[2].hide || childCountData[1].hide }">
                        <!-- Child 1 -->
                        <input type="checkbox" class="member__input2" value="new" name="memberchild" id="onechild"
                            [checked]="childCountData[0].selected" (click)="childCountSelected('1',$event)"
                            *ngIf="!childCountData[0].hide">
                        <label class="member__label2 one-child" for="onechild" *ngIf="!childCountData[0].hide">
                            <img src="../../../../../assets/images/Single Child.svg" alt="" class="icon">
                        </label>

                        <!-- Child 2 -->
                        <input type="checkbox" class="member__input2" value="new" name="memberchild" id="twochild"
                            (click)="childCountSelected('2',$event)" *ngIf="!childCountData[1].hide"
                            [checked]="childCountData[1].selected">
                        <label class="member__label2 two-child " for="twochild" *ngIf="!childCountData[1].hide">
                            <img src="../../../../../assets/images/Single Child.svg" alt="" class="icon">
                            <img src="../../../../../assets/images/Single Child.svg" alt="" class="icon">

                        </label>
                        <!-- Child 3 only when adult 2 is selected-->
                        <input type="checkbox" class="member__input2" value="new" name="memberchild" id="threechild"
                            (click)="childCountSelected('3',$event)" *ngIf="!childCountData[2].hide"
                            [checked]="childCountData[2].selected">
                        <label class="member__label2 thre-child " for="threechild" *ngIf="!childCountData[2].hide">
                            <img src="../../../../../assets/images/Single Child.svg" alt="" class="icon">
                            <img src="../../../../../assets/images/Single Child.svg" alt="" class="icon">
                            <img src="../../../../../assets/images/Single Child.svg" alt="" class="icon">
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <mat-divider></mat-divider>

        <h3 *ngIf="adultDetails.length > 0">Adults</h3>
        <!-- Adult fields Construction -->
        <div class="fieldsdiv " *ngIf="adultDetails.length > 0" formArrayName="adultDetails">
            <div class="row" *ngFor="let data of adultDetails.controls; let i = index" [formGroupName]="i">
                <div class="col-sm-12 form-group mb-0">
                    <span class="subheading">Adult {{i+1}}</span>
                </div>
                <div class="col-sm-3 form-group">
                    <mat-label class="input-group__label">DOB</mat-label>
                    <input class="input-group__input dob" matInput [matDatepicker]="adultDOB"
                        [formControlName]="'dobAdult' + (i+1)" id="adultDetails"
                        [max]="getInsuredMinMaxDate('Adult','max')" [min]="getInsuredMinMaxDate('Adult','min')"
                        (click)="adultDOB.open()" placeholder="DD/MM/YYYY" readonly
                        (dateInput)="adultsDateComparison()">
                    <mat-datepicker-toggle matSuffix [for]="adultDOB">
                    </mat-datepicker-toggle>
                    <mat-datepicker #adultDOB touchUi>
                    </mat-datepicker>
                    <mat-error class="error-margin"
                        *ngIf="adultDetails.value[i]['dobAdult' + (i+1)] == '' && formSubmitted">
                        Adult {{i+1}} DOB is required
                    </mat-error>
                    <mat-error class="error-margin" *ngIf="adult2DateError">
                        Adult2 age can not be greater than Adult1
                    </mat-error>
                </div>

                <div class="col-sm-3 form-group">
                    <mat-label class="input-group__label" for="myInput">Relationship</mat-label>
                    <mat-select class="input-group__input cstm_select" formControlName="{{'adultRealtionship' + (i+1)}}"
                        id="adultDetails" required (click)="handleMatSelectClick()"
                        (blur)="removeMatSelectScrollListener()" placeholder="Select Relationship">
                        <ng-container *ngFor="let item of adultRelationShips; let last = last">
                            <mat-option value={{item.RelationshipName}}>
                                {{item.RelationshipName}}
                            </mat-option>
                            <mat-divider *ngIf="!last"></mat-divider>
                        </ng-container>
                    </mat-select>
                    <mat-error class="error-margin"
                        *ngIf="adultDetails.value[i]['adultRealtionship' + (i+1)] == '' && formSubmitted">
                        Adult {{i+1}} Relationship is required
                    </mat-error>
                </div>

            </div>
        </div>
        <mat-divider></mat-divider>

        <!-- Child Fields Construction -->
        <h3 *ngIf="childDetails.length > 0">Children</h3>
        <div class="fieldsdiv " *ngIf="childDetails.length > 0" formArrayName="childDetails">
            <div class="row " *ngFor="let data of childDetails.controls; let i = index" [formGroupName]="i">
                <div class="col-sm-12  form-group mb-2">
                    <span class="subheading">Child {{i+1}}</span>
                </div>
                <div class="col-sm-3 form-group">
                    <mat-label class="input-group__label" style="top: 13px;">DOB</mat-label>
                    <input class="input-group__input dob" matInput [matDatepicker]="childDOB"
                        formControlName="{{'dobChild' + (i+1)}}" id="childDetails" placeholder="DD/MM/YYYY" readonly
                        [max]="getInsuredMinMaxDate('Child','max')" [min]="getInsuredMinMaxDate('Child','min')"
                        (click)="childDOB.open()">
                    <mat-datepicker-toggle matSuffix [for]="childDOB">
                    </mat-datepicker-toggle>
                    <mat-datepicker #childDOB touchUi>
                    </mat-datepicker>
                    <mat-error class="error-margin"
                        *ngIf="childDetails.value[i]['dobChild' + (i+1)] == '' && formSubmitted">
                        Child {{i+1}} DOB is required
                    </mat-error>
                </div>

                <div class="col-sm-3 form-group">
                    <mat-label class="input-group__label" style="top: 13px;" for="myInput">Relationship</mat-label>
                    <mat-select class="input-group__input cstm_select" formControlName="{{'childRelationship' + (i+1)}}"
                        id="childDetails" placeholder="Relationship with Applicant of child"
                        (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()">
                        <ng-container *ngFor="let item of childRelationShips; let i = index; let last = last">
                            <mat-option value={{item.RelationshipName}}>
                                {{item.RelationshipName}}
                            </mat-option>
                            <mat-divider *ngIf="!last"></mat-divider>
                        </ng-container>
                    </mat-select>
                    <mat-error class="error-margin"
                        *ngIf="childDetails.value[i]['childRelationship' + (i+1)] == '' && formSubmitted">
                        Child {{i+1}} Relationship is required
                    </mat-error>
                </div>
            </div>
        </div>

        <!-- Premium Details Seection Starts -->
        <h3>Premium Details</h3>
        <div class="fieldsdiv ">
            <div class="row ">
                <!-- Policy Tenure -->
                <div class="col-sm-6 col-md-6 col-lg-3 policyTenureWidth form-group">
                    <!-- Policy Tenure -->
                    <label for="" class="singlelabel mb-2" style="width:100%">Policy Tenure</label>
                    <div class="group_btn"><br />
                        <mat-button-toggle-group formControlName="policyTenure" name="fontStyle" aria-label="Font Style"
                            class="policyToggle_group">
                            <mat-button-toggle class="PolicyBtn"
                                *ngFor="let tenureData of hapDataModal.tenurePeriod; let j = index"
                                [value]="tenureData">
                                {{ tenureData }}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                        <mat-error
                            *ngIf="healthAdvantageForm.controls['policyTenure']?.errors?.['required'] && formSubmitted">
                            Policy Tenure is required
                        </mat-error>
                    </div>
                </div>

                <!-- Annual Sum Insured -->
                <div class="col-sm-3 form-group appIncome">
                    <label class="input-group__label" style="top: 13px;">Sum Insured</label>
                    <mat-select class="input-group__input cstm_select"
                        (selectionChange)="annualSumChanged($event.value)" placeholder="Select applicant sum insured"
                        (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                        formControlName="applicantAnnualSum" id="applicantAnnualSum" required>
                        <ng-container
                            *ngFor="let item of this.hapMasterData.Data.SumInsuredDetails; let i = index; let last = last">
                            <mat-option value="{{item.SumAmount}}">
                                {{utilityService.easyAmountReader(item.SumAmount)}}
                            </mat-option>
                            <mat-divider *ngIf="!last"></mat-divider>
                        </ng-container>
                    </mat-select>
                    <mat-error class="error-margin"
                        *ngIf="healthAdvantageForm.controls['applicantAnnualSum']?.errors?.['required'] && formSubmitted">
                        Sum Insured is required
                    </mat-error>
                </div>

                <div class="col-sm-3 form-group appIncome">
                    <label class="input-group__label" style="top: 13px;">Voluntary Deductible Sum</label>
                    <mat-select class="input-group__input cstm_select" placeholder="Select Voluntary Deductible Sum"
                        (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                        formControlName="voluntaryDeductible" id="voluntaryDeductible" required>
                        <ng-container *ngFor="let item of vdValue; let i = index; let last = last">
                            <mat-option value="{{item}}">
                                {{utilityService.easyAmountReader(item)}}
                            </mat-option>
                            <mat-divider *ngIf="!last"></mat-divider>
                        </ng-container>
                    </mat-select>
                    <mat-error class="error-margin"
                        *ngIf="healthAdvantageForm.controls['voluntaryDeductible']?.errors?.['required'] && formSubmitted">
                        Voluntary Deductible is required
                    </mat-error>
                </div>

            </div>
        </div>

    </mat-card>
    <!-- Member Detail Card Ends -->


    <!-- Additional Covers Start -->
    <mat-card class="innerbox borderinnerbox" *ngIf="!childAddon">
        <h3>Additional Cover</h3>

        <!-- Non-member-based addons -->
        <div class="fieldsdiv" formArrayName="addOnsHB">
            <div class="row" *ngFor="let i = index; let add of addonHBlist" [formGroupName]="i"
                style="display: flex; align-items: center;">

                <div class="col-sm-3 customCheckslist">
                    <mat-checkbox class="cstmCheckbox" formControlName="{{add.CoverCode}}" (change)="toggleCheckbox(i)">
                        {{add.CoverCode}}
                    </mat-checkbox>
                </div>

                <div class="col-sm-3 form-group appIncome" *ngIf="viewVariant(i)">
                    <label class="input-group__label" style="top: 13px;">Variants</label>
                    <mat-select class="input-group__input cstm_select" (selectionChange)="variantChanged(i)"
                        placeholder="Select Variant" (click)="handleMatSelectClick()"
                        (blur)="removeMatSelectScrollListener()" formControlName="{{'Variant' + (i+1)}}"
                        id="{{'Variant' + (i+1)}}" required>
                        <ng-container *ngFor="let item of add.Variants; let last = last">
                            <mat-option value="{{item}}">
                                {{item}}
                            </mat-option>
                            <mat-divider *ngIf="!last"></mat-divider>
                        </ng-container>
                    </mat-select>
                    <mat-error class="error-margin"
                        *ngIf="addonArray.value[i]['Variant' + (i+1)] == '' && formSubmitted">
                        Variant is required
                    </mat-error>
                </div>

                <div class="col-sm-3 form-group appIncome" *ngIf="viewMember(i) && adultDetails.length > 0">
                    <label class="input-group__label" style="top: 13px;">Members</label>
                    <mat-select class="input-group__input cstm_select" placeholder="Select Member"
                        (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                        formControlName="{{'Member' + (i+1)}}" id="{{'Member' + (i+1)}}" required>
                        <ng-container>
                            <mat-option value="Adult1">
                                Adult 1
                            </mat-option>
                            <mat-divider *ngIf="adultDetails.length > 1"></mat-divider>
                            <mat-option *ngIf="adultDetails.length > 1" value="Adult2">
                                Adult 2
                            </mat-option>
                            <mat-divider *ngIf="adultDetails.length > 1"></mat-divider>
                            <mat-option *ngIf="adultDetails.length > 1" value='Both'>
                                Adult 1 & Adult 2
                            </mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-error class="error-margin"
                        *ngIf="addonArray.value[i]['Member' + (i+1)] == '' && formSubmitted">
                        Member is required
                    </mat-error>
                </div>
            </div>
        </div>




    </mat-card>

    <mat-card class="innerbox borderinnerbox" *ngIf="childAddon">
        <h3>Additional Cover</h3>

        <!-- Non-member-based addons -->
        <div class="fieldsdiv" formArrayName="addOnsHB">
            <div class="row" [formGroupName]="0" style="display: flex; align-items: center;">

                <div class="col-sm-3 customCheckslist">
                    <mat-checkbox class="cstmCheckbox" formControlName="{{addonHBlist[0].CoverCode}}"
                        (change)="toggleCheckbox(0)">
                        {{addonHBlist[0].CoverCode}}
                    </mat-checkbox>
                </div>

                <div class="col-sm-3 form-group appIncome" *ngIf="viewVariant(0)">
                    <label class="input-group__label" style="top: 13px;">Variants</label>
                    <mat-select class="input-group__input cstm_select" (selectionChange)="variantChanged(0)"
                        placeholder="Select Variant" (click)="handleMatSelectClick()"
                        (blur)="removeMatSelectScrollListener()" formControlName="Variant1" id="Variant1" required>
                        <ng-container *ngFor="let item of addonHBlist[0].Variants; let last = last">
                            <mat-option value="{{item}}">
                                {{item}}
                            </mat-option>
                            <mat-divider *ngIf="!last"></mat-divider>
                        </ng-container>
                    </mat-select>
                    <mat-error class="error-margin" *ngIf="addonArray.value[0]['Variant1'] == '' && formSubmitted">
                        Variant is required
                    </mat-error>
                </div>

            </div>
        </div>


    </mat-card>

</form>


<app-error-screen *ngIf="multipleServiceError" (refreshService)="onRefresh($event)">
</app-error-screen>

<app-custom-footer *ngIf="!multipleServiceError && loadFormContent" [normalButton]="true"
    [buttonData]="[{'label': 'Generate Quote', 'type': 'primary', 'source': 'quote', 'id': 'f1','disabled': false , 'hide': false}]"
    (buttonClicked)="getQuote($event)">
</app-custom-footer>