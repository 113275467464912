import { Component, ElementRef, EventEmitter, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { Form, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { concatMap, forkJoin, Observable, of, Subscription } from 'rxjs';
import { Constants } from 'src/app/shared/constants/constants';
import { HBAddOns, healthDays, healthMembers, healthProducts, healthProposalProductCode, healthToggle, healthYears, patterns, zoneDetails } from 'src/app/shared/enums/healthEnums';
import { HealthDetails, SaveEditQuoteV1Request } from 'src/app/shared/interface/transactionHealthAdvantege';
import { MasterStateResponsePayload, StateDetails } from 'src/app/shared/interface/moterMaster';
import { HealthAdvantedgeService } from 'src/app/shared/proxy-services/health-advantedge.service';
import { HealthMasterService } from 'src/app/shared/proxy-services/health-master.service';
import { MotorMasterService_M } from 'src/app/shared/proxy-services/moter-master.service';
import { RTOListService } from 'src/app/shared/proxy-services/rto-list.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddonStructure, HAPModal } from '../hap/hap-modal';
import { CustomStepperService } from 'src/app/shared/services/custom-stepper.service';
import { CustomFooterService } from 'src/app/shared/services/custom-footer.service';
import { WindowResizeService } from 'src/app/shared/services/window-resize.service';
import { EmitButtonData } from 'src/app/shared/interface/custom-footer';
import { RTOListRequestPayload, RTOListResponsePayload } from 'src/app/shared/interface/rto-list';
import { IncomeRange, PolicyPlan, ZoneRequestPayload, ZoneResponsePayload, StepperCheckDataQuote } from 'src/app/shared/interface/health-advantedge';
import { HealthPlanMasterRequestPayload, RelationshipRequestPayload, RelationshipResponsePayload, AddonDetail } from 'src/app/shared/interface/healthMaster';
import { HealthDataStorageService } from '../../health.service';
import { HealthDataStorage, PremiumDetailsData } from '../../health.modal';
import { MatStepper } from '@angular/material/stepper';
import { PopupService } from 'src/app/shared/services/popup.service';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { popupType, popupHeader, popupDescriptionMsg, popupImgPath } from 'src/app/shared/enums/popupEnums';
import { MatSelect } from '@angular/material/select';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { SessionStorageService, StorageService } from 'src/app/shared/services/storage.service';
import { SaveEditQuoteV1RequestPayload, addonHB } from 'src/app/shared/interface/health-booster';
import { HBoosterService } from 'src/app/shared/proxy-services/hbooster.service';
import { ChannelData } from 'src/app/shared/interface/swapPrameters';
import { ConvertSavedQuotesResponsePayload, SavedQuote } from 'src/app/shared/interface/savedQuotes';
import { StepData } from 'src/app/shared/interface/custom-stepper';

@Component({
  selector: 'app-health-booster',
  templateUrl: './health-booster.component.html',
  styleUrls: ['./health-booster.component.scss']
})
export class HealthBoosterComponent {

  @ViewChild('textCreate', { static: false }) textCreateComponent: ElementRef;
  @Output() QuoteValueEmitter = new EventEmitter<{ Quote: boolean, Portability: boolean, StatusCode: boolean }>();
  healthAdvantageForm: FormGroup;
  formSubmitted: boolean = false;
  formReset: boolean = false;
  healthProducts = healthProducts; // health enums
  hapDataModal: HAPModal = new HAPModal(); //hap data modal 
  hapFormData: HealthDataStorage;
  hapMasterData: any;
  adultRelationShips = [];
  childRelationShips = [];
  adultCountData = this.hapDataModal.adultCount;
  childCountData = this.hapDataModal.childCount;
  adultData = [];
  childData = [];
  selectedAdult = [];
  selectedChild = [];
  totalAdultChildData = [];
  subscription: Subscription[] = [];
  states: StateDetails[] = [];
  sumInsuredDetails: PolicyPlan[] = [];
  cityData = [];
  premiumDetails: PremiumDetailsData;
  stateDetails = this.hapDataModal.stateDetails;
  zoneMappedWithPincode: string = '';
  invalidPinCodeMessage: string;
  transparentSpinner: boolean = false;
  stateFetched: boolean = false;
  adult2DateError: boolean = false;
  //adultRelationShipError: boolean = false;
  success: boolean = false;
  oneMemberRequired: boolean = false;
  multipleServiceError: boolean = false;
  refreshPopupActivated: boolean = false;
  loadFormContent: boolean = false;
  tenureDuration: number = 0;
  previousAdultValue: string = '';
  previousChildValue: string = '';
  errorPopupData: PopupModal;
  reload = false;
  insuredMinMaxDate: { maxDate: Date; minDate: Date; };
  selectedOption = 'No';
  statuscode: boolean;
  stepperCheckData: Array<StepperCheckDataQuote> = [];
  disableButton: boolean = false;
  @ViewChild(MatStepper) stepper: MatStepper;
  portability: boolean = false;
  formValid: boolean = false;
  isInvalid: boolean = false;
  vdValue: any[] = [];
  addonHBlist: addonHB[] = [];
  childAddon: boolean = false;
  adults: string[] = ["Adult1", "Adult2"];
  patchingDone: boolean = false;
  initialFormState = {};
  dealId: string = "";
  HBPtype = 'Super Top Up Plan';
  channelData: ChannelData;
  quoteDetails: ConvertSavedQuotesResponsePayload;
  saveQuoteCounter = 0;
  quoteConvertData: SavedQuote;
  steps: StepData[] = [];

  quoteFormHB: FormGroup;

  constructor(private fb: FormBuilder,
    public utilityService: UtilityService,
    private healthAdvantedgeService: HealthAdvantedgeService,
    private healthMasterService: HealthMasterService,
    private rtoListService: RTOListService,
    private snackBar: MatSnackBar,
    private moterMasterService: MotorMasterService_M,
    private popupService: PopupService,
    private healthDataStorageService: HealthDataStorageService,
    private renderer: Renderer2,
    private el: ElementRef,
    private storageService: StorageService,
    private hbooster: HBoosterService,
    private customStepperService: CustomStepperService,
    private sessionStorageService: SessionStorageService,
    private customFooterService: CustomFooterService,
    private windowResizeService: WindowResizeService,
  ) {
    this.adultDetails1.forEach(adult => {
      this.selectedAdults[adult] = false;
    });
  }

  healthMasterResponseHB: any;
  noOfAdultHB: number;
  selectedAddon: string[] = [];
  selectedVariant: string[] = [];
  selectedMembers: string[][] = [];


  // addons = [
  //   { name: 'Optional Addon-Covers 1 (All Members)', variants: ['SILVER', 'GOLD'], members: ['Adult 1', 'Adult 2'] },
  //   { name: 'Optional Addon-Covers 2 (Member Basis)' , variants: ['SILVER', 'GOLD'], members: ['Adult 1', 'Adult 2'] },
  //   { name: 'Optional Addon-Covers 3 (Member Basis)', variants: ['SILVER', 'GOLD'], members: ['Adult 1', 'Adult 2'] }
  // ];

  toggleAddon(index: number) {
    this.selectedAddon[index] = this.selectedAddon[index] === 'yes' ? 'no' : 'yes';
  }

  isAnyAddonSelected(): boolean {
    return this.healthMasterResponseHB && this.selectedAddon.some(option => option === 'yes');
  }


  ngOnInit() {
    window.scrollTo(0, 0);
    // get hap form data structure
    this.onLoadSteps();
    //Stepper step validation 
    this.quoteFormHB = this.fb.group({
      productName: [''],
    });
    this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
  }

  onLoadSteps() {
    this.premiumDetails = this.healthDataStorageService.initializePremiumData()
    this.hapFormData = this.healthDataStorageService.getHealthDataStorage();
    this.quoteDetails = this.storageService.getAESDecryptedData(userEnums.ConverToProposal);
    if (this.quoteDetails) {
      this.quoteConvertData = this.quoteDetails.Data;
      this.updateSavedQuotesConvertData();
      // this.changeInProductType();
      this.customFooterService.showPremiumDetails(true);
    }
    if (this.hapFormData.quoteDataSet) {
      this.patchingDone = false;
    }
    this.createHAPForm();
    this.fetchHAPDetails();
    // if (!this.hapFormData.quoteDataSet) {
    //   this.patchQuickQuoteData();
    // }
  }

  isSelected(event: any, fromvalue: string): boolean {
    event.preventDefault();
    event.stopPropagation();
    return fromvalue === event.value;
  }

  updateSavedQuotesConvertData() {
    this.success = true;
    this.hapFormData.quoteDataSet = true;
    let quoteFormData = this.hapFormData.quoteFormData;
    // let GSTData = this.quoteConvertData.CustomerDetails.GSTDetails;
    quoteFormData.productType = this.quoteConvertData.ProductType;
    quoteFormData.pincode = this.quoteConvertData.Pincode;
    quoteFormData.policyTenure = this.tenureSelectionAsPerNumber(this.quoteConvertData.Tenure);
    quoteFormData.policyRange = this.quoteConvertData.PolicyRange;
    quoteFormData.applicantAnnualSum = this.quoteConvertData.Members[0].SumInsured.toString();
    quoteFormData.applicantAnnualIncome = this.quoteConvertData.ProposalAnnualIncomeRange;
    quoteFormData.InstallmentApplicable = this.quoteConvertData.InstallmentApplicable;
    quoteFormData.InstallmentFrequency = this.quoteConvertData.InstallmentFrequency;
    quoteFormData.addonsFormdata = this.quoteConvertData.hepAddonsFormData;
    quoteFormData.zone = this.quoteConvertData.Zone;
    quoteFormData.cityState = this.quoteConvertData.CityState;
    quoteFormData.copaymentPercentTaken = this.quoteConvertData.VoluntaryCopaymentPercentage;
    this.zoneMappedWithPincode = this.hapFormData.quoteFormData.zone;
    this.hapFormData.premiumFormData.InstallmentTotalPremium = parseInt(this.quoteConvertData.InstallmentTotalPremium);
    quoteFormData.productType = this.quoteConvertData.ProductType;
    quoteFormData.zoneUpgrade = this.quoteConvertData.ZoneUpgrade;
    quoteFormData.reviseZone = this.quoteConvertData.RevisedZone;
    quoteFormData.productName = healthProducts.hb;
    quoteFormData.voluntaryDeductible = this.quoteConvertData.VoluntaryDeductible;
    quoteFormData.healthProposalProductType = healthProposalProductCode.hb;
    quoteFormData.adultRelationShips = this.quoteConvertData.AdultRelationShips;
    quoteFormData.childRelationShips = this.quoteConvertData.ChildRelationShips;
    quoteFormData.subProductType = this.hapDataModal.subProductType.hb;
    this.hapFormData.premiumFormData.PfQuoteId = this.quoteConvertData.GenericData.pfQuoteId;
    quoteFormData.corelationId = this.quoteConvertData.CustomerDetails.CorelationId;
    quoteFormData.addonListHB = this.quoteConvertData.GenericData.addonListHB;
    this.healthDataStorageService.setHealthDataStorage(this.hapFormData);
  }

  tenureSelectionAsPerNumber(data: number) {
    let tenureSelect;
    if (data == 1) {
      tenureSelect = this.hapDataModal.tenurePeriod[0];
    } else if (data == 2) {
      tenureSelect = this.hapDataModal.tenurePeriod[1];
    } else {
      tenureSelect = this.hapDataModal.tenurePeriod[2];
    }
    return tenureSelect;
  }

  //form construction
  createHAPForm() {
    // patch annual sum insured if quote data is already present
    this.healthAdvantageForm = this.fb.group({
      productName: [healthProducts.hb],
      productType: [this.hapFormData.quoteDataSet ? this.hapFormData.quoteFormData.productTypeHB : null],
      pincode: [this.hapFormData.quoteFormData.pincode, [Validators.required, Validators.pattern(patterns.pincode), Validators.minLength(6)]],
      cityState: ['', Validators.required],
      adultDetails: this.fb.array([]),
      childDetails: this.fb.array([]),
      policyTenure: [this.hapFormData.quoteDataSet ? this.hapFormData.quoteFormData.policyTenure : this.hapDataModal.tenurePeriod[0], Validators.required],
      applicantAnnualSum: [this.hapFormData.quoteDataSet ? this.hapFormData.quoteFormData.applicantAnnualSum : '', [Validators.required]],
      voluntaryDeductible: [this.hapFormData.quoteDataSet ? this.hapFormData.quoteFormData.voluntaryDeductible : '', [Validators.required]],
      conditionWaitingPeriod: [healthYears.TwoYear],
      softCopyDiscount: ['Yes'],
      addOnsHB: this.fb.array([])
    });
    // Creating adult and child controls and patching existing data if present
    this.patchExistingMemberData();

    this.healthAdvantageForm.valueChanges.subscribe((data) => {
      // Below logic works when data is already patched .OR. User comes back and changes value. Only when premium is set
      // Now when user tries to change the value the stepper should reset to quote page
      // And premium should hide
      if (this.patchingDone) {
        // premium data set flag is used beacsuse user can come back and try to change the values.
        // In that case also we need to reset the stepper

        // And if user refreshes that means existing value is getting patched. In that case we are using the patchingDone flag
        // and resetting the premium footer
        if (JSON.stringify(this.healthAdvantageForm.value) != JSON.stringify(this.initialFormState)) {
          this.showPremiumDetails(false);
          // Here we are resetting the stepper. Just to be sure if the quote is changed.
          // User needs to traverse through each steps
          // 
          if (this.quoteDetails) {
            // Below block is written to identify value changes due to save quote
            if (this.saveQuoteCounter == 0) {
              this.customStepperService.saveQuoteDataChanges(true);
              this.sessionStorageService.remove(userEnums.ConverToProposal);
              this.saveQuoteCounter++;
              this.quoteDetails = null;
            }
          }
          this.customStepperService.resetStepper(true);
        
        }
      }
    })
  }

  patchExistingMemberData() {
    if (this.hapFormData.quoteDataSet || this.quoteConvertData) {
      // IPAA-176: Patch AdultDetails and ChildDetails for Convert API
      if(this.quoteConvertData && this.quoteConvertData.AdultDetails) {
        // this.hepFormData.quoteFormData.adultDetails = this.quoteConvertData.AdultDetails;
        this.hapFormData.quoteFormData.adultDetails = this.quoteConvertData.AdultDetails.map((data:any, i) => ({
          [`dobAdult${(i+1)}`]: data.dob,
          [`adultRealtionship${(i+1)}`]: data.relationship,
          [`adultVaccinationStatus${(i+1)}`]: data.vaccineStatus,
          // [`adultVaccinationDate${(i+1)}`]: new Date(data.vaccineDate),
        }));
      }
      if (this.quoteConvertData && this.quoteConvertData.ChildDetails) {
        this.hapFormData.quoteFormData.childDetails = this.quoteConvertData.ChildDetails.map((data: any, i) => ({
          [`dobChild${(i + 1)}`]: new Date(data.dob),
          [`childRelationship${(i + 1)}`]: data.relationship
        }));
      }
      if (this.hapFormData.quoteFormData.adultDetails.length > 0) {
        this.hapFormData.quoteFormData.adultDetails.forEach((data, index) => {
          this.adultDetails.push(this.createAdultDetails(index));
          // Patch values of adults
          // Below block is written because when there is self data that get's patched from kyc is not in date format
          // So to handle it we need to convert in into date type
          for (let adultKeys in data) {
            if (adultKeys.includes('dob')) {
              let dobValue = !data[adultKeys].includes('T') ? new Date(data[adultKeys]) : data[adultKeys]
              this.adultDetails.at(index).get(adultKeys).setValue(dobValue);
            }
            else {
              this.adultDetails.at(index).get(adultKeys).setValue(data[adultKeys]);
            }
          }
        })

        // We need to highlight the selected adult also
        let selectedAdultIndex = this.hapFormData.quoteFormData.adultDetails.length - 1;
        this.adultCountData.forEach((adultCount, index) => {
          if (index == selectedAdultIndex) {
            adultCount.selected = true;
          }
          else {
            adultCount.selected = false;
          }
        })
      }
      if (this.hapFormData.quoteFormData.childDetails.length > 0) {
        this.hapFormData.quoteFormData.childDetails.forEach((data, index) => {
          this.childDetails.push(this.createChildDetails(index));
          // Patch values of adults
          for (let childKeys in data) {
            this.childDetails.at(index).get(childKeys).setValue(data[childKeys]);

          }
        })

        // We need to highlight the selected child also
        // If user has selected only one child then other child needs to hide that would be in case of 0 adults
        let selectedChildIndex = this.hapFormData.quoteFormData.childDetails.length - 1;
        this.childCountData.forEach((childCount, index) => {
          if (this.hapFormData.quoteFormData.adultDetails.length == 0) {
            // Disabling adult selected
            this.adultCountData[0].selected = false;
            this.adultCountData[1].selected = false;
            if (index == 0) {
              childCount.selected = true;
              childCount.hide = false;
            }
            else {
              childCount.selected = false;
              childCount.hide = true;
            }
          }
          else {
            if (index == selectedChildIndex) {
              childCount.selected = true;
              childCount.hide = false;
            }
            else {
              childCount.selected = false;
            }
          }
        })
      }

      // IPAA-176: Patch totalMemeber object for insured page Convert API
      if (this.quoteConvertData && (this.adultDetails.length > 0 || this.childDetails.length > 0)) {
        this.hapFormData.quoteFormData.totalMemberData = this.getMemberRequest();
        this.healthDataStorageService.setHealthDataStorage(this.hapFormData);
      }
    }
    else if (this.hapFormData.quickquoteDataSet) {
      this.getadultchilddataforquickquote();
    }
    else {
      this.adultDetails.push(this.createAdultDetails(0));
    }
  }

  get adultDetails(): FormArray {
    return this.healthAdvantageForm.get('adultDetails') as FormArray;
  }

  get childDetails(): FormArray {
    return this.healthAdvantageForm.get('childDetails') as FormArray;
  }

  createAdultDetails(index?): FormGroup {
    return new FormGroup({
      ['dobAdult' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['adultRealtionship' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['adultVaccinationStatus' + `${index + 1}`]: new FormControl(healthToggle.No, [Validators.required]),
      // ['adultVaccinationDate' + `${index + 1}`]: new FormControl(''),
      ['Gender' + `${index + 1}`]: new FormControl('')
    })
  }

  createChildDetails(index?): FormGroup {
    return new FormGroup({
      ['dobChild' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['childRelationship' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['Gender' + `${index + 1}`]: new FormControl('')
    })
  }

  createAddons(control, defaultValue): FormGroup {
    return new FormGroup({
      [`${control}`]: new FormControl(defaultValue)
    })
  }

  // on load service calls
  fetchHAPDetails() {
    this.transparentSpinner = false;
    ;
    // If below solution does not work try calling api individually
    this.subscription.push(this.requestDataFromMultipleSources().subscribe({
      next: (responseList: [any, RelationshipResponsePayload]) => {
        this.multipleServiceError = false;
        this.handleMultipleResponse(responseList)

      },
      error: error => {
        this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
        this.loadFormContent = false;
        this.multipleServiceError = true;
      }
    })
    )


  }

  handleMultipleResponse(responseList: [any, RelationshipResponsePayload]) {
    if (responseList[0].StatusCode != Constants.statusCode_success || responseList[1].StatusCode != Constants.statusCode_success) {
      this.multipleServiceError = true;
    }
    else {
      this.multipleServiceError = false;
      this.manageHAPMasterData(responseList[0]);
      this.manageHealthRelationShipsData(responseList[1]);
      if (!this.hapFormData.quoteDataSet && this.hapFormData.quickquoteDataSet) {
        this.patchQuickQuoteData();
      }
    }
  }

  requestDataFromMultipleSources(): Observable<[any, RelationshipResponsePayload]> {
    let proposalData: RelationshipRequestPayload = { product: Constants.healthProposalProductCodeHB };
    const agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
    this.dealId = agentDetails.MappedProduct.Health.HealthBoosterDealId;
    const UserID: string = String(agentDetails.AgentID);
    const request: HealthPlanMasterRequestPayload = {
      UserID: UserID,
      SubType: this.hapDataModal.subProductTypeHB,
      isCSC: true,
      isCHIRefileFlag: true
    }
    return this.healthMasterService.getHealthMasterPlannerV1(request).pipe(
      // concatMap operator to sequentially call getHealthAdvantagePlusMasterData
      concatMap(healthResponse => {
        // forkJoin to concurrently call the other two methods
        // The responses from these three requests are combined into an array and emitted as a single observable
        return forkJoin([
          // of(healthResponse) within the forkJoin to include the result of the first API call in the final array of responses. 
          of(healthResponse),
          this.healthMasterService.getHealthProposalRelationships(proposalData)
        ]);
      })
    );
  }

  // handles hap master data
  manageHAPMasterData(hapResponse: any) {
    if (hapResponse.StatusCode == Constants.statusCode_success) {
      let addOns = [];
      this.multipleServiceError = false;
      this.hapMasterData = hapResponse;
      if (this.hapFormData.quoteDataSet) {
        this.healthAdvantageForm.controls['applicantAnnualSum'].setValue(this.hapFormData.quoteFormData.applicantAnnualSum);
        // for vd back data rendering 
        if (this.hapFormData.quoteFormData.applicantAnnualSum.length > 0) {
          this.annualSumChanged(this.hapFormData.quoteFormData.applicantAnnualSum)
        }

        this.fetchDataAsPerPincode(null, this.hapFormData.quoteFormData.pincode)
      }
      this.hapFormData.insuredFormData.befitCovers = hapResponse.Data.befitCovers;
      this.hapFormData.insuredFormData.BefitApplicableCities = hapResponse.Data.BefitApplicableCities;
      // this.healthDataStorageService.setHealthDataStorage(this.hapFormData);

      let coverCode: string = '';
      let coverCodeID: number = 0;
      for (let i = 0; i < this.hapMasterData.Data.AddonDetails.length; i++) {
        if (!this.hapMasterData.Data.AddonDetails[i].CoverCode.includes(String(coverCodeID))) {
          this.addonHBlist[i] = { CoverCode: '', Variants: [] };
          coverCode = this.hapMasterData.Data.AddonDetails[i].CoverCode
          if (coverCode.includes('1')) {
            coverCodeID = 1;
          } else if (coverCode.includes('2')) {
            coverCodeID = 2;
          } else {
            coverCodeID = 3;
          }
          for (let j = 0; j < this.hapMasterData.Data.AddonDetails.length; j++) {
            if (this.hapMasterData.Data.AddonDetails[j].CoverCode.includes(String(coverCodeID))) {
              this.addonHBlist[i].CoverCode = this.addonNameChange(this.hapMasterData.Data.AddonDetails[j].CoverCode);
              this.addonHBlist[i].Variants.push(this.hapMasterData.Data.AddonDetails[j].CoverVariantType);
            }
          }
        }
      }
      this.addonHBlist = this.addonHBlist.filter((item) => item.Variants.length > 0);
      if (this.hapFormData.quoteFormData.addonsHB.length > 0) {
        this.patchAddonDataHB(this.hapFormData.quoteFormData.addonsHB);
      } else if (this.quoteConvertData && this.quoteConvertData.hbAddonsFormData) {
        // IPAA-176: Saved quotes addon patching
        this.patchAddonDataHB(this.quoteConvertData.hbAddonsFormData);
      } else {
        for (let i = 0; i < this.addonHBlist.length; i++) {
          if (this.addonHBlist[i].CoverCode.includes('1')) {
            const addonName = this.addonHBlist[i].CoverCode;
            this.addonArray.push(
              this.fb.group({
                [addonName]: [false],
                iVariant: [false],
                iMember: [false]
              })
            );
          } else {
            const addonName = this.addonHBlist[i].CoverCode;
            this.addonArray.push(
              this.fb.group({
                [addonName]: [false],
                iVariant: [false],
                iMember: [false]
              })
            );
          }
        }
      }
    }
  }

  get addonArray(): FormArray {
    return (<FormArray>this.healthAdvantageForm.get('addOnsHB'));
  }

  removeSpace(name: string): string {
    return name.replace(/\s/g, "");
  }

  addonNameChange(name: string): string {
    if (name.includes('1')) {
      return 'Optional Add-on Covers 1 (All Members)';
    } else if (name.includes('2')) {
      return 'Optional Add-on Covers 2 (Member Basis)';
    } else {
      return 'Optional Add-on Covers 3 (Member Basis)';
    }
  }

  // handle state list
  manageStateResponseData(stateResponse: MasterStateResponsePayload) {
    if (stateResponse.StatusCode == Constants.statusCode_success) {
      this.multipleServiceError = false;
      if ((stateResponse.Data).length > 0) {
        this.states = stateResponse.Data.sort(function (a, b) {
          return a.StateName.localeCompare(b.StateName)
        });
      }
      else {
        this.multipleServiceError = true;
      }
    }
  }

  // handles member relationships 
  manageHealthRelationShipsData(response: RelationshipResponsePayload) {
    let adultRelations = [];
    let childRelations = [];
    if (response.StatusCode == Constants.statusCode_success) {
      this.multipleServiceError = false;
      if (response.Data.InsuredRelationship.length > 0) {
        adultRelations = response.Data.InsuredRelationship.filter(x => x.KidAdultType == healthMembers.Adult);
        childRelations = response.Data.InsuredRelationship.filter(x => x.KidAdultType == healthMembers.Child);

        this.adultRelationShips = adultRelations.length > 0 ? adultRelations.sort(function (a, b) {
          return a.RelationshipName.localeCompare(b.RelationshipName)
        }) : [];
        this.childRelationShips = childRelations.length > 0 ? childRelations.sort(function (a, b) {
          return a.RelationshipName.localeCompare(b.RelationshipName)
        }) : [];
        this.loadFormContent = true;
      }
      else {
        this.adultRelationShips = [];
        this.childRelationShips = [];
        this.loadFormContent = false;
        this.multipleServiceError = true;
      }
    }
  }

  hasError(controlName: string, errorCode: string): boolean {
    return !this.healthAdvantageForm.valid && this.healthAdvantageForm.hasError(errorCode, [controlName]);
  }

  // Fetch statename according to pincode
  fetchDataAsPerPincode(event, existingPincode) {
    if (existingPincode == null) {
      this.hapFormData.quoteDataSet = false;
    }

    this.formReset = false;
    this.stateFetched = false;
    this.invalidPinCodeMessage = '';
    this.cityData = [];
    if ((existingPincode != null ? existingPincode : event.target.value.length == 6) && !this.healthAdvantageForm.controls['pincode'].hasError('pattern')) {
      let data: RTOListRequestPayload = {
        "Pincode": existingPincode != null ? existingPincode.toString() : event.target.value,
        "CityID": ""
      }
      this.transparentSpinner = true;
      ;
      this.invalidPinCodeMessage = '';
      this.healthAdvantageForm.controls['cityState'].reset();
      this.subscription.push(this.rtoListService.getStatesCityByPin(data).subscribe({
        next: (response: RTOListResponsePayload) => {
          if (response.StatusCode == Constants.statusCode_success) {
            if (response.Data.length > 0) {
              this.stateDetails.stateId = response.Data[0].StateId ? response.Data[0].StateId : '';
              this.stateDetails.stateName = response.Data[0].StateName ? response.Data[0].StateName : '';
              this.stateDetails.pincode = existingPincode != null ? existingPincode : event.target.value;
              this.stateDetails.isBefitApplicable = response.Data[0]['IsBefitApplicable'] ? "true" : "false";
              response.Data.forEach(cityList => {
                this.cityData.push({ 'cityLabel': cityList['CityName'], 'stateLabel': this.stateDetails.stateName, 'value': cityList['CityID'] });
              });
            }
            if (this.cityData.length == 1) {
              this.stateDetails.cityName = this.cityData[0].cityLabel;
              this.stateDetails.cityId = this.cityData[0].value;
              this.getZoneData();
              this.healthAdvantageForm.controls['cityState'].setValue(this.stateDetails.stateName + ' - ' + this.stateDetails.cityName);
            }

            // IPAA-176: Patch City and State for Convert API
            if (this.quoteDetails && this.cityData.length > 0) {
              this.stateDetails.cityName = this.cityData[0].cityLabel;
              this.stateDetails.cityId = this.cityData[0].value;
              this.getZoneData();
              this.healthAdvantageForm.controls['cityState'].setValue(this.stateDetails.stateName + ' - ' + this.stateDetails.cityName);
              this.hapFormData.quoteFormData.cityId = this.stateDetails.cityId;
            }

            // Need to check below in backward patching
            if (this.hapFormData.quoteDataSet) {
              this.zoneMappedWithPincode = this.hapFormData.quoteFormData.zone;
              this.healthAdvantageForm.controls['cityState'].setValue(this.hapFormData.quoteFormData.cityState);
              this.changeCityName(this.hapFormData.quoteFormData.cityId, false);
            }
            this.stateFetched = true;
            if (this.hapFormData.quoteDataSet && !this.patchingDone) {
              this.patchingDone = true;
            }
          }

          else if (response.StatusCode == Constants.statusCode_failure) {
            this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
            this.stateFetched = false;
            this.invalidPinCodeMessage = response.StatusDesc;
          }

          else {
            this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
            // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
            // this.popupService.openGenericPopup(this.errorPopupData);
          }
        },
        error: error => {
          this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          this.invalidPinCodeMessage = '';
          this.stateFetched = false;
          // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
          // this.popupService.openGenericPopup(this.errorPopupData);
        }
      })
      )
    }
    this.utilityService.numberOnly(event);
    this.utilityService.restrictSpace(event);
  }

  getZoneData() {
    let params: ZoneRequestPayload = {
      "productcode": this.hapDataModal.zoneCode,
      "State": this.stateDetails.stateName,
      "City": this.stateDetails.cityName
    }
    this.subscription.push(this.healthAdvantedgeService.getZoneFromCityData(params).subscribe({
      next: (response: ZoneResponsePayload) => {
        if (response.StatusCode === Constants.statusCode_success) {
          let zoneData = response.Data.zone;
          this.zoneMappedWithPincode = zoneData ? ((zoneData == this.hapDataModal.zoneData[0]) ? zoneDetails.Zone1 : ((zoneData == this.hapDataModal.zoneData[1]) ? zoneDetails.Zone2 : zoneDetails.Zone3)) : '';

        }
        else {
          this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          this.zoneMappedWithPincode = '';
          // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
          // this.popupService.openGenericPopup(this.errorPopupData);
        }
      },
      error: error => {
        this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
        // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
        // this.popupService.openGenericPopup(this.errorPopupData);
      }
    }))
  }

  changeCityName(cityValue: string, showSpinner: boolean) {
    this.stateDetails.cityId = cityValue;
    this.stateDetails.cityName = this.cityData.length > 0 ? (this.cityData.find(data => data.value == cityValue).cityLabel) : '';
    if (showSpinner) {
      ;
      this.getZoneData();
    }
  }


  changeInProductType() {
    // Check if portability selected reset emi values
    if (this.healthAdvantageForm.value.productType != 'New') {
      this.hapFormData.portabilityDataSet = true;
      this.healthDataStorageService.setHealthDataStorage(this.hapFormData);
      this.portabilityDataPresent(true);
    }
    else {
      this.hapFormData.portabilityDataSet = false;
      this.healthDataStorageService.setHealthDataStorage(this.hapFormData);
      this.portabilityDataPresent(false);
    }
  }

  portabilityDataPresent(data: boolean) {
    this.customStepperService.portabilityPresent(data);
  }

  showPremiumDetails(data: boolean) {
    this.customFooterService.showPremiumDetails(data);
  }

  annualSumChanged(value) {
    this.vdValue = [];
    for (let i = 0; i < this.hapMasterData.Data.SumInsuredDetails.length; i++) {
      if (this.hapMasterData.Data.SumInsuredDetails[i].SumAmount == value) {
        for (let j = 0; j < this.hapMasterData.Data.SumInsuredDetails[i].VDValues.length; j++) {
          this.vdValue.push(this.hapMasterData.Data.SumInsuredDetails[i].VDValues[j].value)
        }
        break;
      }
    }
  }

  // Adult count selected
  adultCountSelected(adultValue: string, event) {
    // adult count entire logic is set on adult data present in hap modal
    // toggle is handled by selected sttribute for each object
    let index = parseInt(adultValue) - 1;
    let selectedChild = this.fetchSelectedChild();
    switch (adultValue) {

      // If adult 1 is selected then we need to set selected attribute true
      case '1': {
        if (!this.adultCountData[index].selected) {
          this.adultCountData[index].selected = true;
          this.adultCountData[index + 1].selected = false;

          // Also enable second child because we have a condition for 0A 1C only should be there
          // SO if it's hidden unhide it
          if (this.childCountData[1].hide) {
            this.childCountData[1].hide = false;
            this.childCountData[1].selected = false;
          }

          // If two adults present in form array remove second adult as only 1A needed
          if (this.adultDetails.length > 1) {
            this.adult2DateError = false;
            this.adultDetails.removeAt(this.adultDetails.length - 1);
          }

          // If no adults presents in form array as again 1A is selected then we need to push in array
          // Push one adult in form array
          else if (this.adultDetails.length == 0) {
            this.adult2DateError = false;
            this.adultDetails.push(this.createAdultDetails(0));
          }

          // If one adult is selected then make third child hidden. SO for 1A 2C can be selected and also unselect them
          this.childCountData[2].hide = true;
          this.childCountData[2].selected = false;

          // if 3C is presnt in form array then remove third child from form array
          if (this.childDetails.length == 3) {
            // If third child was selected then we need to remove all child details from form array
            if (selectedChild[0].value == '3') {
              this.childDetails.clear();
            }
            else {
              this.childDetails.removeAt(this.childDetails.length - 1);
            }
          }
        }
        // But if user tries to click again on adult 1 and if it is already selected that means if selected is true
        // then stop checkbox behaviour
        else {
          // Now if he tries to uncheck adult. We need to allow uncheck only when any of the child is selcted otherwise we won't allow
          // Uncheck to be allowed only when child is present
          if (selectedChild.length == 0) {
            event.preventDefault();
            event.stopPropagation();
            this.snackBar.open('Atleast one member is required', 'Ok', {
              duration: 2000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
            });
          }
          // If uncheck can happen set the value of that adult as false.
          else {
            // Remove all adult details
            this.adultDetails.clear();
            this.adultCountData[index].selected = false;
            // If no adult is selected then only 1 child should be visible
            this.childCountData.forEach((childData, index) => {
              childData.hide = index == 2 ? true : false;
              childData.selected = index == 0 ? true : false;
            })

            // Remove child elements if present
            // if (this.childDetails.length == 2) {
            //   this.childDetails.removeAt(this.childDetails.length - 1);
            // }

          }
        }
        break;
      }

      // If adult 2 is selected then we need to set selected attribute true
      case '2': {
        if (!this.adultCountData[index].selected) {
          this.adultCountData[index].selected = true;
          this.adultCountData[index - 1].selected = false;

          // Push one adult in form array if one adult is already present
          if (this.adultDetails.length == 1) {
            this.adult2DateError = false;
            this.adultDetails.push(this.createAdultDetails(1));
          }

          // If no adult is present then push two adults
          else if (this.adultDetails.length == 0) {
            this.adult2DateError = false;
            this.adultDetails.push(this.createAdultDetails(0));
            this.adultDetails.push(this.createAdultDetails(1));
          }

          // If two adult is selected then make one child visible. SO for 2A 3C can be selected and also unselect them
          this.childCountData[2].selected = false;
          this.childCountData[2].hide = false;

          // If first child is hidden unhide it
          if (this.childCountData[1].hide) {
            this.childCountData[1].hide = false;
            this.childCountData[1].selected = false;
          }
        }
        // But if user tries to click again on adult 2 and if it is already selected that means if selected is true
        // then stop checkbox behaviour
        else {
          // Now if he tries to uncheck adult. We need to allow uncheck only when any of the child is selcted otherwise we won't allow
          // Uncheck to be allowed only when child is present
          if (selectedChild.length == 0) {
            event.preventDefault();
            event.stopPropagation();
            this.snackBar.open('Atleast one member is required', 'Ok', {
              duration: 2000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
            });
          }
          // If user tries to uncheck 2A but he has selected 3C. Then we can't allow uncheck
          else if (selectedChild.length > 0 && selectedChild[0].value == '3') {
            event.preventDefault();
            event.stopPropagation();
            this.snackBar.open('3C can be selected on 2A only. To uncheck Please select 1A', 'Ok', {
              duration: 2000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
            });
          }
          else if (selectedChild.length > 0) {
            // Remove all adult details
            this.adultDetails.clear();
            this.adultCountData[index].selected = false;
            // If no adult is selected then only 1 child should be visible
            this.childCountData.forEach((childData, index) => {
              childData.selected = index == 0 ? true : false;
              childData.hide = index == 0 ? false : true;
            })
            // Remove child elements if present
            if (this.childDetails.length == 2) {
              this.childDetails.removeAt(this.childDetails.length - 1);
            }
          }
        }
        break;
      }
      default : {
        break;
      }
    }
    if (this.adultDetails.length > 0 && this.childDetails.length >= 0) {
      this.childAddon = false;
      const group1 = this.addonArray.at(0) as FormGroup;
      group1.get(HBAddOns.AddOn1)?.setValue(false);
      if (group1.get('Variant1')) {
        group1.removeControl('Variant1')
        group1.get('iVariant')?.setValue(false)
      }
    }
    else {
      this.childAddon = true;
      const group1 = this.addonArray.at(0) as FormGroup;
      group1.get(HBAddOns.AddOn1)?.setValue(false);
      if (group1.get('Variant1')) {
        group1.removeControl('Variant1')
        group1.get('iVariant')?.setValue(false)
      }
      const group2 = this.addonArray.at(1) as FormGroup;
      group2.get(HBAddOns.AddOn2)?.setValue(false);
      if (group2.get('Variant2')) {
        group2.removeControl('Variant2')
        group2.get('iVariant')?.setValue(false)
      }
      if (group2.get('Member2')) {
        group2.removeControl('Member2')
        group2.get('iMember')?.setValue(false)
      }
      const group3 = this.addonArray.at(2) as FormGroup;
      group3.get(HBAddOns.AddOn3)?.setValue(false);
      if (group3.get('Variant3')) {
        group3.removeControl('Variant3')
        group3.get('iVariant')?.setValue(false)
      }
      if (group3.get('Member3')) {
        group3.removeControl('Member3')
        group3.get('iMember')?.setValue(false)
      }
    }
  }
  // Child count selected
  childCountSelected(childValue: string, event) {
    // child count entire logic is set on child data present in hap modal
    // toggle is handled by selected attribute for each object
    let index = parseInt(childValue) - 1;
    let selectedAdult = this.fetchSelectedAdult();
    switch (childValue) {
      // If child 1 is selected then we need to set selected attribute true
      case '1': {
        if (!this.childCountData[index].selected) {
          this.childCountData[index].selected = true;

          // As it is check box and 1C is selected we need to ensure other 2C & 3C is unchecked
          this.childCountData[index + 1].selected = false;
          this.childCountData[index + 2].selected = false;

          // If 0C is present push one data in child array
          if (this.childDetails.length == 0) {
            this.childDetails.push(this.createChildDetails(0));
          }

          // If 2C is present in child details form array remove second child
          else if (this.childDetails.length == 2) {
            this.childDetails.removeAt(this.childDetails.length - 1);
          }

          // If 3C is present then remove last two child
          else if (this.childDetails.length == 3) {
            this.childDetails.removeAt(this.childDetails.length - 1);
            this.childDetails.removeAt(this.childDetails.length - 1);
          }

        }

        // And if no adult is selected then child can't be unchecked otherwise uncheck can happen
        else if (selectedAdult.length == 0) {
          event.preventDefault();
          event.stopPropagation();
          this.snackBar.open('Atleast one member is required', 'Ok', {
            duration: 2000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
          });
        }
        // if it can be unchecked set value as false of that unchecked child
        else if (selectedAdult.length > 0) {
          this.childCountData[index].selected = false;
          // clear child details
          this.childDetails.clear();
        }
        break;
      }

      // If child 2 is selected then we need to set selected attribute true
      case '2': {
        if (!this.childCountData[index].selected) {
          this.childCountData[index].selected = true;
          // As it is check box and 2C is selected we need to ensure other 1C & 3C is unchecked
          this.childCountData[index - 1].selected = false;
          this.childCountData[index + 1].selected = false;

          // If 0C is present push two data in child array
          if (this.childDetails.length == 0) {
            this.childDetails.push(this.createChildDetails(0));
            this.childDetails.push(this.createChildDetails(1));
          }

          // If 1C is present push one data in child array
          else if (this.childDetails.length == 1) {
            this.childDetails.push(this.createChildDetails(1));
          }

          // If 3C is present in child details form array remove third child
          else if (this.childDetails.length == 3) {
            this.childDetails.removeAt(this.childDetails.length - 1);
          }
        }

        // And if no adult is selected then child can't be unchecked otherwise uncheck can happen
        else if (selectedAdult.length == 0) {
          event.preventDefault();
          event.stopPropagation();
          this.snackBar.open('Atleast one member is required', 'Ok', {
            duration: 2000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
          });
        }
        // if it can be unchecked set value as false of that unchecked child
        else if (selectedAdult.length > 0) {
          this.childCountData[index].selected = false;
          // clear child details
          this.childDetails.clear();
        }
        break;
      }

      // If child 3 is selected then we need to set selected attribute true
      case '3': {
        if (!this.childCountData[index].selected) {
          this.childCountData[index].selected = true;
          // As it is check box and 3C is selected we need to ensure other 1C & 2C is unchecked
          this.childCountData[index - 2].selected = false;
          this.childCountData[index - 1].selected = false;

          // If 0C is present then push 3 child data in form array
          if (this.childDetails.length == 0) {
            this.childDetails.push(this.createChildDetails(0));
            this.childDetails.push(this.createChildDetails(1));
            this.childDetails.push(this.createChildDetails(2));
          }
          // If 1C is present push two data in child array
          else if (this.childDetails.length == 1) {
            this.childDetails.push(this.createChildDetails(1));
            this.childDetails.push(this.createChildDetails(2));
          }
          // If 2C is present push one data in child array
          else if (this.childDetails.length == 2) {
            this.childDetails.push(this.createChildDetails(2));
          }
        }
        // But if user tries to click again on child 3 and if it is already selected that means if selected is true
        // then stop checkbox behaviour
        //In this case 2A is required and if it is checked it can't be unchecked
        else {
          event.preventDefault();
          event.stopPropagation();
          this.snackBar.open('3C can be selected on 2A only. To uncheck Please select 1A', 'Ok', {
            duration: 2000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
          });
        }
        break;
      }
      default : {
        break;
      }
    }
    if (this.adultDetails.length == 0 && this.childDetails.length > 0) {
      this.childAddon = true;
      const group1 = this.addonArray.at(0) as FormGroup;
      group1.get(HBAddOns.AddOn1)?.setValue(false);
      if (group1.get('Variant1')) {
        group1.removeControl('Variant1')
        group1.get('iVariant')?.setValue(false)
      }
      const group2 = this.addonArray.at(1) as FormGroup;
      group2.get(HBAddOns.AddOn2)?.setValue(false);
      if (group2.get('Variant2')) {
        group2.removeControl('Variant2')
        group2.get('iVariant')?.setValue(false)
      }
      if (group2.get('Member2')) {
        group2.removeControl('Member2')
        group2.get('iMember')?.setValue(false)
      }
      const group3 = this.addonArray.at(2) as FormGroup;
      group3.get(HBAddOns.AddOn3)?.setValue(false);
      if (group3.get('Variant3')) {
        group3.removeControl('Variant3')
        group3.get('iVariant')?.setValue(false)
      }
      if (group3.get('Member3')) {
        group3.removeControl('Member3')
        group3.get('iMember')?.setValue(false)
      }
    }
  }

  fetchSelectedAdult() {
    return this.adultCountData.filter(element => element.selected);
  }

  fetchSelectedChild() {
    return this.childCountData.filter(element => element.selected);
  }

  adultsDateComparison() {
    if (this.adultDetails.length == 2) {
      if (this.adultDetails.value[1]['dobAdult2'] != '' && this.utilityService.dateDiff(this.adultDetails.value[1]['dobAdult2'], this.adultDetails.value[0]['dobAdult1'])) {
        this.adult2DateError = true;
      }
      else {
        this.adult2DateError = false;
      }
    }
    else {
      this.adult2DateError = false;
    }

  }

  getMinMaxDate(type: string, minMaxType: string) {
    let noOfAdult = this.adultDetails.length;
    let minYear = type == healthMembers.Child ? 26 : 66;
    let maxYear = type != healthMembers.Child ? 18 : type == healthMembers.Child && noOfAdult == 0 ? 6 : 0;
    let maxMonth = type == healthMembers.Child && noOfAdult != 0 ? 3 : '';
    let minMonth = type == healthMembers.Child && noOfAdult != 0 ? 9 : '';
    let insuredMaxYear = (type != healthMembers.Child) || (type == healthMembers.Child && noOfAdult == 0) ? new Date().getFullYear() - maxYear : new Date().getFullYear();
    let insuredMinYear = new Date().getFullYear() - minYear;
    this.insuredMinMaxDate = this.utilityService.getMinMaxDate(insuredMaxYear, insuredMinYear, maxMonth, minMonth);
    return minMaxType == 'min' ? this.insuredMinMaxDate.minDate : this.insuredMinMaxDate.maxDate;
  }

  getInsuredMinMaxDate(memberType: string, minMaxType: string) {
    if (memberType == 'Adult') {
      let adultMaxDate = this.utilityService.subtractYears(new Date(), 21);
      //changes on age
      let yearsdate = this.utilityService.subtractYears(new Date(), 100);
      let monthdate = this.utilityService.subtractMonths(yearsdate, 11);
      let adultMinDate = this.utilityService.subtractDays(monthdate, 25);

      return minMaxType == 'min' ? adultMinDate : adultMaxDate;
    }
    else {
      let noOfAdults = this.adultDetails.length;
      if (noOfAdults > 0) {
        //depedent child
        let depChildMaxDate = this.utilityService.subtractMonths(new Date(), 3);

        let yearDate = this.utilityService.subtractYears(new Date(), 20);
        let monthDate = this.utilityService.subtractMonths(yearDate, 11);
        let depChildMinDate = this.utilityService.subtractDays(monthDate, 25);

        return minMaxType == 'min' ? depChildMinDate : depChildMaxDate;
      }
      else {
        //independent child
        let indChildMaxDate = this.utilityService.subtractYears(new Date(), 6);

        let yearDate = this.utilityService.subtractYears(new Date(), 20);
        let monthDate = this.utilityService.subtractMonths(yearDate, 11);
        let indChildMinDate = this.utilityService.subtractDays(monthDate, 25);

        return minMaxType == 'min' ? indChildMinDate : indChildMaxDate;
      }
    }
  }


  onChangeEmitValue() {
    // this.QuoteValueEmitter.emit({ Quote: false, Portability: true, StatusCode: false });
  }

  getQuote(event: EmitButtonData) {
    this.formReset = false;
    this.transparentSpinner = false;
    this.success = false;
    this.formSubmitted = true;
    this.healthAdvantageForm.markAllAsTouched();

      //self case validation for more than 1 adult/child
      if(this.adultDetails.length > 0 || this.childDetails.length > 0){
        let adultRelationshipArrCount = 0;
        let childRelationshipArrCount = 0;
        if(this.adultDetails.value.length > 0){
            this.adultDetails.value.map((ele,index)=>{
                      if(ele[`adultRealtionship${index + 1}`]== 'SELF'){
                    
                        adultRelationshipArrCount++;
                      }
            })
        }

      if (this.childDetails.value.length > 0) {
        this.childDetails.value.map((ele, index) => {
          if (ele[`childRelationship${index + 1}`] == 'SELF') {
            childRelationshipArrCount++;
          }
        })
      }

      if ((adultRelationshipArrCount > 1 || childRelationshipArrCount > 1) || (adultRelationshipArrCount > 0 && childRelationshipArrCount > 0)) {
        this.errorPopupData = this.popupService.fetchErrorPopupMsg('SELF can be selected only for one Insured');
        this.popupService.openGenericPopup(this.errorPopupData);
        return;
      }
    }


    // If child details length is only 1 . we need to check dob present in child is valid or not
    if (this.childDetails.length == 1 && this.adultDetails.length == 0) {
      // Read Dob value if it's invalid then reset
      if (this.childDetails.at(0).get('dobChild1').status == 'INVALID') {
        this.childDetails.at(0).get('dobChild1').reset();
        this.childDetails.at(0).get('dobChild1').setValue('');
        this.childDetails.at(0).get('dobChild1').setValidators(Validators.required);
        this.childDetails.at(0).get('dobChild1').updateValueAndValidity();
      }
    }
    if (!this.healthAdvantageForm.valid || this.adult2DateError || this.invalidPinCodeMessage !== '') {
      for (const key of Object.keys(this.healthAdvantageForm.controls)) {
        if (this.healthAdvantageForm.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('#' + key + '.ng-invalid');
          invalidControl.scrollIntoView({ behaviour: "instant", block: "center", inline: "center" });
          invalidControl.focus();
          break;
        }
      }
      this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
    }

    else if (this.invalidPinCodeMessage == '') {
      this.formSubmitted = false;
      let request: SaveEditQuoteV1Request = this.constructRequest();
      this.subscription.push(this.hbooster.saveEditQuoteV1(request).subscribe({
        next: response => {

          if (response.StatusCode == Constants.statusCode_success) {
            if (response.Data.HealthDetails.CorrelationId) {
              this.hapFormData.quoteFormData.corelationId = response.Data.HealthDetails.CorrelationId;
              this.healthDataStorageService.setHealthDataStorage(this.hapFormData);
            }
            this.statuscode = true
            this.setHapFormData(response?.Data.HealthDetails);
            this.disableButton = true;
            this.success = true;
            this.patchingDone = true;
            this.initialFormState = this.healthAdvantageForm.value;

            // If premium exists then only allow user to proceed forward
            if (!this.utilityService.isEmptyOrNull(response.Data.HealthDetails.TotalPremium) && response.Data.HealthDetails.TotalPremium != '0') {
              this.customStepperService.emitStepData({ status: true, buttonId: 'f1' });
            }
            else {
              this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
            }
            this.showPremiumDetails(true);

          }
          else if (response.StatusCode != Constants.statusCode_success) {

            this.success = false;
            this.disableButton = false;
            // this.errorPopupData = this.popupService.fetchErrorPopupMsg(response.StatusDesc);
            // this.popupService.openGenericPopup(this.errorPopupData);
            this.statuscode = false;
            this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          }

        },
        error: (err) => {
          this.success = false;
          this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
          // this.popupService.openGenericPopup(this.errorPopupData);
        }
      }))
    }
  }


  constructRequest(): SaveEditQuoteV1Request {
    let tenure = (this.healthAdvantageForm.value.policyTenure).split(' ')[0];
    this.tenureDuration = parseInt(tenure);
    let adult1DOB = this.adultDetails.value.length > 0 ? this.utilityService.formatDate(this.adultDetails.value[0]['dobAdult1']) : '';
    let adult2DOB = this.adultDetails.value.length > 1 ? this.utilityService.formatDate(this.adultDetails.value[1]['dobAdult2']) : '';

    const group1 = this.addonArray.at(0);
    const AddOn1 = group1.get(HBAddOns.AddOn1)?.value;
    const AddOn1Variant = group1.get('Variant1')?.value ? group1.get('Variant1')?.value : '';
    const group2 = this.addonArray.at(1);
    const AddOn2 = group2.get(HBAddOns.AddOn2)?.value;
    const AddOn2Variant = group2.get('Variant2')?.value ? group2.get('Variant2')?.value : '';
    const AddOn2Members = group2.get('Member2')?.value ? group2.get('Member2')?.value : '';
    const group3 = this.addonArray.at(2);
    const AddOn3 = group3.get(HBAddOns.AddOn3)?.value;
    const AddOn3Variant = group3.get('Variant3')?.value ? group3.get('Variant3')?.value : '';
    const AddOn3Members = group3.get('Member3')?.value ? group3.get('Member3')?.value : '';
    let requestData: SaveEditQuoteV1Request = {
      "ProductType": 'N',
      "UserType": this.utilityService.isEmptyOrNull(this.channelData.UT) ? "AGENT" : this.channelData.UT,
      "NoOfAdults": (this.adultDetails.length).toString(),
      "NoOfKids": (this.childDetails.length).toString(),
      "Adult1Age": adult1DOB,
      "Adult2Age": adult2DOB,
      "SubProductType": this.hapDataModal.subProductTypeHB,
      "Tenure": this.tenureDuration,
      "AddOn1": AddOn1,
      "AddOn2": AddOn2,
      "AddOn3": AddOn3,
      "AddOn1Varient": AddOn1Variant,
      "AddOn2Varient": AddOn2Variant,
      "Addon2Members": AddOn2Members == "Both" ? this.adults : [AddOn2Members],
      "AddOn3Varient": AddOn3Variant,
      "Addon3Members": AddOn3Members == "Both" ? this.adults : [AddOn3Members],
      "IsJammuKashmir": this.checkIsGSTRegistered(),
      "VolDedutible": this.healthAdvantageForm.value.voluntaryDeductible,
      "GSTStateCode": this.stateDetails.stateId,
      "SumInsured": this.healthAdvantageForm.value.applicantAnnualSum,
      "GSTStateName": this.stateDetails.stateName ? this.stateDetails.stateName : '',
      "isGSTRegistered": this.checkIsGSTRegistered(),
      "SaveQuote": true,//let's send it true in premium we are setting true
      "IpAddress": this.utilityService.isEmptyOrNull(this.channelData.CIP) ? this.hapDataModal.ipAddress : this.channelData.CIP,
    }
    Object.assign(requestData);
    return requestData;

  }


  // Quote request formation 
  checkIsGSTRegistered() {
    return this.stateDetails.stateId != this.hapDataModal.stateId ? false : true;
  }

  getMemberRequest() {
    let members = [];
    if (this.adultDetails.length > 0) {
      this.adultDetails.value.forEach((element, i) => {
        this.adultDetails.value[i]['Gender' + `${i + 1}`] = this.utilityService.setGender(element['adultRealtionship' + (i + 1)], this.adultRelationShips);
        members.push({
          DOB: this.utilityService.formatDate(element['dobAdult' + (i + 1)]),
          MemberType: healthMembers.Adult,
          RelationshipwithApplicant: element['adultRealtionship' + (i + 1)],
          // VaccinationDate: this.utilityService.formatDate(element['adultVaccinationDate' + (i + 1)]),
          Gender: this.utilityService.setGender(element['adultRealtionship' + (i + 1)], this.adultRelationShips)
        })
      });
    }
    if (this.childDetails.length > 0) {
      this.childDetails.value.forEach((element, j) => {
        this.childDetails.value[j]['Gender' + `${j + 1}`] = this.utilityService.setGender(element['childRelationship' + (j + 1)], this.childRelationShips);
        members.push({
          DOB: this.utilityService.formatDate(element['dobChild' + (j + 1)]),
          MemberType: healthMembers.Child,
          RelationshipwithApplicant: element['childRelationship' + (j + 1)],
          Gender: this.utilityService.setGender(element['childRelationship' + (j + 1)], this.childRelationShips)
        })
      });
    }

    return members;
  }

  // setHapFormData(premiumData: HealthDetails) {
  //   let resetFlags: boolean = false;
  //   let tempStoredFormData = this.healthDataStorageService.getHealthDataStorage();
  //   if (tempStoredFormData.insuredDataSet) {
  //     if (this.adultDetails.length == tempStoredFormData.quoteFormData.adultDetails.length) {
  //       if (this.childDetails.length != tempStoredFormData.quoteFormData.childDetails.length) {
  //         resetFlags = true;
  //       }
  //     }
  //     else {
  //       resetFlags = true;
  //     }
  //   }
  //   this.hapFormData = this.healthDataStorageService.getHealthDataStorage();
  //   if (resetFlags) {
  //     this.setOtherFormFlags();
  //   }
  //   this.hapFormData.quoteFormData.productName = this.healthAdvantageForm.controls['productName'].value;
  //   this.hapFormData.quoteFormData.productType = this.healthAdvantageForm.controls['productType'].value;
  //   this.hapFormData.quoteFormData.pincode = this.healthAdvantageForm.controls['pincode'].value;
  //   this.hapFormData.quoteFormData.adultDetails = this.adultDetails.value;
  //   this.hapFormData.quoteFormData.childDetails = this.childDetails.value;
  //   this.hapFormData.quoteFormData.cityId = this.stateDetails.cityId;
  //   this.hapFormData.quoteFormData.cityName = this.stateDetails.cityName;
  //   this.hapFormData.quoteFormData.stateId = this.stateDetails.stateId;
  //   this.hapFormData.quoteFormData.totalMemberData = this.getMemberRequest();
  //   this.hapFormData.quoteFormData.policyTenure = this.healthAdvantageForm.controls['policyTenure'].value;
  //   this.hapFormData.quoteFormData.applicantAnnualSum = this.healthAdvantageForm.controls['applicantAnnualSum'].value;
  //   this.hapFormData.quoteFormData.conditionWaitingPeriod = this.healthAdvantageForm.controls['conditionWaitingPeriod'].value;
  //   this.hapFormData.quoteFormData.cityState = this.healthAdvantageForm.controls['cityState'].value;
  //   this.hapFormData.quoteFormData.softCopyDiscount = this.healthAdvantageForm.controls['softCopyDiscount'].value;
  //   this.hapFormData.quoteFormData.underWritterApproved = premiumData.Policystatus == "CONDITIONALAPPROVE" ? 'false' : 'true';
  //   this.hapFormData.quoteFormData.zone = this.zoneMappedWithPincode;
  //   this.hapFormData.quoteFormData.adultRelationShips = this.adultRelationShips;
  //   this.hapFormData.quoteFormData.childRelationShips = this.childRelationShips;
  //   this.hapFormData.quoteFormData.subProductType = this.hapDataModal.subProductType.hb;
  //   this.hapFormData.quoteFormData.addonListHB = this.addonHBlist;
  //   this.hapFormData.quoteFormData.addonsHB = this.healthAdvantageForm.controls['addOnsHB'].value;
  //   this.hapFormData.quoteFormData.healthProposalProductType = healthProposalProductCode.hb;
  //   this.hapFormData.quoteFormData.voluntaryDeductible = this.healthAdvantageForm.value.voluntaryDeductible;
  //   this.hapFormData.policyDetails.DealId = this.dealId;
  //   this.hapFormData.quoteDataSet = true;
  //   console.log("+++++++++++++++hapFormData++++++++++",this.hapFormData);

  //   this.constructPremiumData(premiumData);

  //   this.healthDataStorageService.setHealthDataStorage(this.hapFormData);
  //   if (this.healthAdvantageForm.controls['productType'].value == 'Portability') {
  //     this.portability = true;
  //     this.QuoteValueEmitter.emit({ Quote: this.formValid, Portability: this.portability, StatusCode: false })
  //   }
  //   else {
  //     this.portability = false;
  //     this.QuoteValueEmitter.emit({ Quote: this.formValid, Portability: this.portability, StatusCode: false })
  //   }
  // }

  setHapFormData(premiumData: HealthDetails) {
    let resetFlag: boolean = this.checkMemberDetails();
    this.hapFormData.quoteFormData.productName = this.healthAdvantageForm.controls['productName'].value;
    this.hapFormData.quoteFormData.productType = 'N';
    this.hapFormData.quoteFormData.productTypeHB = this.healthAdvantageForm.controls['productType'].value;
    this.hapFormData.quoteFormData.pincode = this.healthAdvantageForm.controls['pincode'].value;
    this.hapFormData.quoteFormData.adultDetails = this.adultDetails.value;
    this.hapFormData.quoteFormData.childDetails = this.childDetails.value;
    this.hapFormData.quoteFormData.cityId = this.stateDetails.cityId;
    this.hapFormData.quoteFormData.cityName = this.stateDetails.cityName;
    this.hapFormData.quoteFormData.stateId = this.stateDetails.stateId;
    this.hapFormData.quoteFormData.isBefitApplicable = this.stateDetails.isBefitApplicable;
    this.hapFormData.quoteFormData.totalMemberData = this.getMemberRequest();
    this.hapFormData.quoteFormData.policyTenure = this.healthAdvantageForm.controls['policyTenure'].value;
    this.hapFormData.quoteFormData.applicantAnnualSum = this.healthAdvantageForm.controls['applicantAnnualSum'].value;
    this.hapFormData.quoteFormData.pedWaitingPeriod = '';
    this.hapFormData.quoteFormData.conditionWaitingPeriod = '';
    this.hapFormData.quoteFormData.preHospitalDuration = '';
    this.hapFormData.quoteFormData.postHospitalDuration = '';
    this.hapFormData.quoteFormData.cityState = this.healthAdvantageForm.controls['cityState'].value;
    this.hapFormData.quoteFormData.applicantAnnualIncome = '';
    this.hapFormData.quoteFormData.softCopyDiscount = 'true';
    this.hapFormData.quoteFormData.pedWaitingPeriod = '';
    this.hapFormData.quoteFormData.copaymentPercentTaken = '';
    this.hapFormData.quoteFormData.underWritterApproved = 'false';
    this.hapFormData.quoteFormData.InstallmentApplicable = 'No';
    this.hapFormData.quoteFormData.adultRelationShips = this.adultRelationShips;
    this.hapFormData.quoteFormData.childRelationShips = this.childRelationShips;
    this.hapFormData.quoteFormData.subProductType = this.hapDataModal.subProductType.hb;
    this.hapFormData.quoteFormData.healthProposalProductType = healthProposalProductCode.hb;
    this.hapFormData.quoteFormData.zone = this.zoneMappedWithPincode;
    this.hapFormData.quoteFormData.addonListHB = this.addonHBlist;
    this.hapFormData.quoteFormData.addonsHB = this.healthAdvantageForm.controls['addOnsHB'].value;
    this.hapFormData.quoteFormData.voluntaryDeductible = this.healthAdvantageForm.value.voluntaryDeductible;
    this.hapFormData.policyDetails.DealId = this.dealId;

    //reset data for quickquote
    this.hapFormData.quickquoteData.pincode = '';
    this.hapFormData.quickquoteData.cityState = '';
    this.hapFormData.quickquoteData.adultDetails = [];
    this.hapFormData.quickquoteData.childDetails = [];
    this.hapFormData.quickquoteData.policyTenure = '';
    this.hapFormData.quickquoteData.applicantAnnualSum = '';
    this.hapFormData.quoteDataSet = true;
    if (resetFlag) {
      this.setOtherFormFlags();
    }
    this.constructPremiumData(premiumData);
    this.healthDataStorageService.setHealthDataStorage(this.hapFormData);
    //this.QuoteValueEmitter.emit({ Quote: this.formValid, Portability: false, StatusCode: false })
  }

  checkMemberDetails(): boolean {
    let nonEqualMembers = [];
    let resetFlag: boolean = false;
    // Adult Details
    // If new adult details and child details length are same then we need to check the info
    if (this.adultDetails.length == this.hapFormData.quoteFormData.adultDetails.length) {
      let existingAdultDetails = this.hapFormData.quoteFormData.adultDetails;
      existingAdultDetails.forEach((adultData, index) => {
        let tempAdultDetails = adultData;
        // When there is self detected there is change in format of dob.
        if (this.hapFormData.kycStatus.ovdSelf || this.hapFormData.kycStatus.normalSelf) {
          // convert dob in respected format
          for (let keys in tempAdultDetails) {
            // There are chances date is patched along with orignal time stamp
            if (keys.includes('dob') && !tempAdultDetails[keys].includes('T')) {
              tempAdultDetails[keys] = (new Date(tempAdultDetails[keys]));
            }
          }
        }
        // Looping through keys
        if (JSON.stringify(tempAdultDetails) != JSON.stringify(this.adultDetails.value[index])) {
          nonEqualMembers.push(index);
        }
      });
    }
    // If the length is only not equal that means adult data is been modified
    else if (this.adultDetails.length != this.hapFormData.quoteFormData.adultDetails.length) {
      nonEqualMembers.push(0);
    }

    // Child Details

    if (this.childDetails.length == this.hapFormData.quoteFormData.childDetails.length) {
      let existingChildDetails = this.hapFormData.quoteFormData.childDetails;
      existingChildDetails.forEach((childData, index) => {
        // Looping through keys
        if (JSON.stringify(childData) != JSON.stringify(this.childDetails.value[index])) {
          nonEqualMembers.push(index);
        }
      });
    }

    // If the length is only not equal that means adult data is been modified
    else if (this.childDetails.length != this.hapFormData.quoteFormData.childDetails.length) {
      nonEqualMembers.push(0);
    }

    else {
      // Adult members found to be equal
      nonEqualMembers = [];
    }

    // If there is mismatch of data in terms of length or details
    if (nonEqualMembers.length > 0) {
      resetFlag = true;
    }
    else {
      resetFlag = false;
    }
    return resetFlag;
  }

  setOtherFormFlags() {
    this.hapFormData.insuredDataSet = false;
    this.hapFormData.kycDataSet = false;
    this.hapFormData.kycStatus.ekycMandatory = false;
    this.hapFormData.kycStatus.normal = false;
    this.hapFormData.kycStatus.normalSelf = false;
    this.hapFormData.kycStatus.ovd = false;
    this.hapFormData.kycStatus.ovdSelf = false;
    this.hapFormData.kycStatus.isKYCDoneSuccessfully = false;
    this.hapFormData.kycStatus.idType = false;
    this.hapFormData.kycStatus.certNumber = '';
    this.hapFormData.kycStatus.isPilotUser = false;
    this.hapFormData.kycFormData = this.healthDataStorageService.resetKYCdata();
  }

  constructPremiumData(response: HealthDetails) {
    this.hapFormData.premiumFormData.totalPremium = response.TotalPremium;
    this.hapFormData.premiumFormData.basicPremium = response.BasicPremium;
    this.hapFormData.premiumFormData.totalTax = response.TotalTax;
    this.hapFormData.premiumFormData.tenure = this.tenureDuration;
    this.hapFormData.premiumFormData.QuoteId = response.QuoteId;
    this.hapFormData.premiumFormData.SubProductCode = response.SubProductCode;
    this.hapFormData.premiumFormData.PfQuoteId = response.PFQuoteID;
    let c = response.Zone == "Zone_III" ? 'C' : '';
    let c1 = response.Zone == "Zone_II" ? 'B' : c;
    this.hapFormData.premiumFormData.zone = response.Zone == "Zone_I" ? 'A' : c1;
    this.hapFormData.premiumDataSet = true;
    this.formValid = true;
  }

  resetForm() {
    this.formReset = true;
    this.success = false;
    this.formSubmitted = false;
    this.resetFormData();
  }

  resetFormData() {
    this.healthAdvantageForm.controls['pincode'].reset();
    this.healthAdvantageForm.controls['applicantAnnualSum'].reset();
    this.healthAdvantageForm.controls['state'].reset();
    this.healthAdvantageForm.controls['city'].reset();
    this.adultData = [];
    this.childData = [];
  }

  goToSecondStep() {
    this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
  }


  getadultchilddataforquickquote() {
    if (this.hapFormData.quickquoteData.adultDetails.length > 0) {
      this.hapFormData.quickquoteData.adultDetails.forEach((data, index) => {
        this.adultDetails.push(this.createAdultDetails(index));
        // Patch values of adults
        // Below block is written because when there is self data that get's patched from kyc is not in date format
        // So to handle it we need to convert in into date type
        for (let adultKeys in data) {
          if (adultKeys.includes('dob')) {
            let dobValue = !data[adultKeys].includes('T') ? new Date(data[adultKeys]) : data[adultKeys]
            this.adultDetails.at(index).get(adultKeys).setValue(dobValue);
          }
          else {
            this.adultDetails.at(index).get(adultKeys).setValue(data[adultKeys]);
          }
        }
      })

      // We need to highlight the selected adult also
      let selectedAdultIndex = this.hapFormData.quickquoteData.adultDetails.length - 1;
      this.adultCountData.forEach((adultCount, index) => {
        if (index == selectedAdultIndex) {
          adultCount.selected = true;
        }
        else {
          adultCount.selected = false;
        }
      })
    }
    if (this.hapFormData.quickquoteData.childDetails.length > 0) {
      this.hapFormData.quickquoteData.childDetails.forEach((data, index) => {
        this.childDetails.push(this.createChildDetails(index));
        // Patch values of adults
        for (let childKeys in data) {
          this.childDetails.at(index).get(childKeys).setValue(data[childKeys]);

        }
      })

      // We need to highlight the selected child also
      // If user has selected only one child then other child needs to hide that would be in case of 0 adults
      let selectedChildIndex = this.hapFormData.quickquoteData.childDetails.length - 1;
      this.childCountData.forEach((childCount, index) => {
        if (this.hapFormData.quickquoteData.adultDetails.length == 0) {
          // Disabling adult selected
          this.adultCountData[0].selected = false;
          this.adultCountData[1].selected = false;
          if (index == 0) {
            childCount.selected = true;
            childCount.hide = false;
          }
          else {
            childCount.selected = false;
            childCount.hide = true;
          }
        }
        else {
          if (index == selectedChildIndex) {
            childCount.selected = true;
            childCount.hide = false;
          }
          else {
            childCount.selected = false;
          }
        }
      })
    }
  }

  patchQuickQuoteData() {
    this.healthDataStorageService.getHealthDataStorage();
    this.healthAdvantageForm.patchValue({
      pincode: this.hapFormData.quickquoteData.pincode,
      cityState: this.hapFormData.quickquoteData.cityState,
      // adultDetails: this.hapFormData.quickquoteData.adultDetails,
      childDetails: this.hapFormData.quickquoteData.childDetails,
      policyTenure: this.hapFormData.quickquoteData.policyTenure ? this.hapFormData.quickquoteData.policyTenure : this.hapDataModal.tenurePeriod[0],
      applicantAnnualSum: this.hapFormData.quickquoteData.applicantAnnualSum
    })
    this.fetchDataAsPerPincode('', this.hapFormData.quickquoteData.pincode)
    if (this.hapFormData.quickquoteData.applicantAnnualSum.length > 0) {
      this.annualSumChanged(this.hapFormData.quickquoteData.applicantAnnualSum)
    }
    // this.stateFetched = true;
  }

  unsubscribeSubscription() {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

  onRefresh(status) {
    if (status) {
      this.fetchHAPDetails();
    }
  }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  ngOnDestroy() {
    this.unsubscribeSubscription();
  }

  onAddonSelected(addon: any) {
    this.selectedAddon = addon;
  }

  showHB: boolean = true;
  showaddon2HB: boolean = true;
  showaddon3HB: boolean = true;
  SnoOfAudultHB: boolean = true;

  viewVariant(index: number) {
    const variantGroup = this.addonArray.at(index);
    const variant = variantGroup.get('iVariant')
    return variant.value
  }

  viewMember(index: number) {
    const memberGroup = this.addonArray.at(index);
    const member = memberGroup.get('iMember')
    return member.value
  }

  toggleCheckbox(index: number) {
    const checkboxGroup = this.addonArray.at(index) as FormGroup | null;

    if (!checkboxGroup) {
      // console.error('Checkbox group is null or undefined.');
      return;
    }

    let addonName;
    if ((index + 1) == 1) {
      addonName = `Optional Add-on Covers ${index + 1} (All Members)`;
    } else {
      addonName = `Optional Add-on Covers ${index + 1} (Member Basis)`;
    }
    const variant = `Variant${index + 1}`;
    const member = `Member${index + 1}`;

    if (checkboxGroup.get(addonName)?.value) {
      if (addonName.includes('1')) {
        this.addOrRemoveControl(checkboxGroup, variant, true, [Validators.required]);
      } else {
        this.addOrRemoveControl(checkboxGroup, variant, true);
        this.addOrRemoveControl(checkboxGroup, member, true, [Validators.required]);
      }
    } else {
      if (addonName.includes('1')) {
        this.addOrRemoveControl(checkboxGroup, variant, false);
      } else {
        this.addOrRemoveControl(checkboxGroup, variant, false);
        this.addOrRemoveControl(checkboxGroup, member, false);
      }
    }

    const iVariantControl = checkboxGroup.get('iVariant');
    if (iVariantControl) {
      if (iVariantControl.value && !addonName.includes('1')) {
        const memberGroup = this.addonArray.at(index) as FormGroup | null;
        if (memberGroup) {
          const iMemberControl = memberGroup.get('iMember');
          if (iMemberControl) {
            if (iMemberControl.value) {
              iMemberControl.setValue(!iMemberControl.value);
            }
          } else {
            // console.error('iMember control not found in member group.');
          }
        } else {
          // console.error('Member group is null or undefined.');
        }
      }
      iVariantControl.setValue(!iVariantControl.value);
      // console.log("+++++++++my cover details++++++++",checkboxGroup);
    } else {
      // console.error('iVariant control not found.');
    }
  }

  private addOrRemoveControl(group: FormGroup, controlName: string, add: boolean, validators: any[] = []) {
    if (add) {
      group.addControl(controlName, this.fb.control('', validators));
    } else {
      group.removeControl(controlName);
    }
  }


  variantChanged(index: number) {
    const memberGroup = this.addonArray.at(index);
    if (index != 0 && !memberGroup.get('iMember')?.value) {
      memberGroup.get('iMember')?.setValue(!memberGroup.get('iMember')?.value);
    }
  }

  patchAddonDataHB(addonData) {
    addonData.map((ele, ind) => {
      let obj = {};
      for (let key in ele) {
        let keyName = key;
        obj[keyName] = ele[key];
      }
      this.addonArray.push(this.fb.group(obj));
    })
  }

  adultDetails1 = ['Adult 1', 'Adult 2']; // Replace with your actual data
  selectedAdults: { [key: string]: boolean } = {};

  // renderCheckbox(data: any) {
  //   if (this.adultDetails.length == 0) {
  //     return data !== "Optional Add-on Covers 2" || data !== "Optional Add-on Covers 3"
  //   }
  // }
}
