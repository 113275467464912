import { Component, ElementRef, Inject, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FamilyShieldResponsePayload } from 'src/app/shared/interface/familyShieldMaster';
import { FamilyshieldModal } from './familyshield-modal';
import { RTOListRequestPayload, RTOListResponsePayload } from 'src/app/shared/interface/rto-list';
import { Constants } from 'src/app/shared/constants/constants';
import { StateDetails } from 'src/app/shared/interface/moterMaster';
import { healthProposalProductCode, patterns, zoneDetails } from 'src/app/shared/enums/healthEnums';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { MatSelect } from '@angular/material/select';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FamilyShieldService } from 'src/app/shared/proxy-services/familyshield.service';
import { RTOListService } from 'src/app/shared/proxy-services/rto-list.service';
import { ZoneRequestPayload, ZoneResponsePayload } from 'src/app/shared/interface/health-advantedge';
import { HealthAdvantedgeService } from 'src/app/shared/proxy-services/health-advantedge.service';
import { HealthDataStorageService } from '../../health.service';
import { CustomStepperService } from 'src/app/shared/services/custom-stepper.service';
import { CustomFooterService } from 'src/app/shared/services/custom-footer.service';
import { HealthDataStorage, PremiumDetailsData } from '../../health.modal';
import { PopupService } from 'src/app/shared/services/popup.service';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { popupButton, popupButtonAction, popupDescriptionMsg, popupHeader, popupImgPath, popupType } from 'src/app/shared/enums/popupEnums';
import { SaveEditQuoteV1Request, memberDetails } from 'src/app/shared/interface/zero-tat-transaction';
import { HealthDetails, ZeroTatHealthDetails, ZeroTatSaveEditQuoteV1Response } from 'src/app/shared/interface/transactionHealthAdvantege';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { SessionStorageService, StorageService } from 'src/app/shared/services/storage.service';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { ConvertSavedQuotesResponsePayload, SavedQuote } from 'src/app/shared/interface/savedQuotes';


@Component({
  selector: 'app-familyshield',
  templateUrl: './familyshield.component.html',
  styleUrls: ['./familyshield.component.scss']
})
export class FamilyshieldComponent implements OnInit {
  subscription: Subscription[] = [];
  MasterData: any;
  familyShieldForm: FormGroup
  states: StateDetails[] = [];
  fsDataModal: FamilyshieldModal = new FamilyshieldModal(); //hap data modal 
  adultCountData = this.fsDataModal.adultCount;
  formReset: boolean;
  stateFetched: boolean;
  invalidPinCodeMessage: string;
  stateDetails = this.fsDataModal.stateDetails;
  cityData: any[];
  zoneMappedWithPincode: any;
  fsFormData: HealthDataStorage;
  formSubmitted: boolean = false;
  OccupancyType: any;
  AnnualIncome: any;
  riskCategory: any;
  bucketDetails: any;
  tabGroup = [];
  adultCount: number = 1;
  premiumDetails: PremiumDetailsData;
  patchingDone: boolean = false;
  statuscode: boolean;
  loadFormContent: boolean = false;
  bucketList: any[] = [];
  bucketdata_original: any[] = [];
  bucketListWeb: any[] = [];
  savedQuoteData;
  saveQuoteCounter = 0;
  quoteDetails: ConvertSavedQuotesResponsePayload;
  quoteConvertData: SavedQuote;
  constructor(
    private fb: FormBuilder,
    public utilityService: UtilityService,
    private renderer: Renderer2,
    private familyShieldService: FamilyShieldService,
    public dialog: MatDialog,
    private rtoListService: RTOListService,
    private healthAdvantedgeService: HealthAdvantedgeService,
    private healthDataStorageService: HealthDataStorageService,
    private customStepperService: CustomStepperService,
    private customFooterService: CustomFooterService,
    private popupService: PopupService,
    private el: ElementRef,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private storageService: StorageService,
    private sessionStorageService: SessionStorageService,

  ) {

  }
  ngOnInit() {
     this.popupService.closeAllDialog(true);
    setTimeout(() => {
      this.popupService.closeAllDialog(false);
    }, 0);
    window.scrollTo(0, 0);
    this.onLoadSteps();
  }
  onLoadSteps() {
    this.premiumDetails = this.healthDataStorageService.initializePremiumData()
    this.fsFormData = this.healthDataStorageService.getHealthDataStorage();
    this.quoteDetails = this.storageService.getAESDecryptedData(userEnums.ConverToProposal);

   

    this.savedQuoteData = this.storageService.getAESDecryptedData(userEnums.ConverToProposal);
    if(this.savedQuoteData != null){
      this.fsFormData.quoteDataSet = true;
      this.fsFormData['quoteFormData'] = this.savedQuoteData['Data']['FamilyShield']['savedQuoteData'];
      this.fsFormData.premiumFormData = this.savedQuoteData.Data.FamilyShield.premiumFormData;
      this.showPremiumDetails(true);
      this.healthDataStorageService.setHealthDataStorage(this.fsFormData);
    }
    if (this.fsFormData.quoteDataSet ) {
      this.patchingDone = false;
    }
    this.createFSForm();
    this.fetchFSDetails();
    this.bucketList = [];
    this.bucketListWeb = [];
    // this.fsFormData = this.healthDataStorageService.getHealthDataStorage();
  }
  fetchFSDetails() {
    this.subscription.push(this.familyShieldService.getMasterData().subscribe({
      next: (masterdata: FamilyShieldResponsePayload) => {
        this.MasterData = masterdata.Data;
        this.OccupancyType = masterdata.Data.OccupancyType;
        this.AnnualIncome = masterdata.Data.AnnualIncome;
        this.riskCategory = masterdata.Data.riskCategory;
        this.bucketDetails = masterdata.Data.bucketDetails;
        this.CoverDetails = this.bucketDetails[0].coverDetails
        this.CreateCoverLabel(this.bucketDetails);
        // console.log(masterdata.Data);
        if (this.fsFormData.quoteDataSet) {

          this.familyShieldForm.valueChanges.subscribe((data) => {
            // Below logic works when data is already patched .OR. User comes back and changes value. Only when premium is set
            // Now when user tries to change the value the stepper should reset to quote page
            // And premium should hide
            if (this.patchingDone) {
              // premium data set flag is used beacsuse user can come back and try to change the values.
              // In that case also we need to reset the stepper
              // And if user refreshes that means existing value is getting patched. In that case we are using the patchingDone flag
              // and resetting the premium footer
              this.showPremiumDetails(false);
              // Here we are resetting the stepper. Just to be sure if the quote is changed.
              if (this.quoteDetails) {
                // Below block is written to identify value changes due to save quote
                if (this.saveQuoteCounter == 0) {
                  this.customStepperService.saveQuoteDataChanges(true);
                  this.sessionStorageService.remove(userEnums.ConverToProposal);
                  this.saveQuoteCounter++;
                  this.quoteDetails = null;
                }
              }
              this.customStepperService.resetStepper(true);
            }
          })

          this.backDataPatch();
          // console.log(this.coverDetails);
        } else {
          this.createTabGroup(this.adultCount);
          this.memberDetails.push(this.createMemberDetails(this.adultCount));
          this.coverDetails.push(this.createCoverDetails());
        }

      }
    }))


  }
  backDataPatch() {
    this.fetchDataAsPerPincode(null, this.fsFormData.quoteFormData.pincode)
    this.familyShieldForm.patchValue(this.fsFormData.quoteFormData.fsFormData);
    this.bucketList = this.fsFormData.quoteFormData.bucketList
    this.memberDetails.clear();
    this.fsFormData.quoteFormData.fsFormData['MemberDetails'].forEach((item, j) => {
      this.createTabGroup(j + 1);
      this.memberDetails.push(this.fb.group({
        InsuredDOB: [new Date(item.InsuredDOB)],
        PreExistingIllness: [item.PreExistingIllness],
        OccupationType: [item.OccupationType],
        IncomeSlab: [item.IncomeSlab],
        MemberType: [item.MemberType],
      }));
    });
    this.coverDetails.clear();
    this.bucketListWeb = [];
    this.bucketListWeb = this.bucketDetails[0].coverDetails;
    this.isEnableCoverPart = [];
    this.isEnableCoverPart = this.fsFormData.quoteFormData.isEnableCoverPart;


    this.fsFormData.quoteFormData.fsFormData['CoverDetails'].forEach((item, i) => {
      this.coverDetails.push(this.patchCoverDetails(item));
    })
    this.bucketdata_original.push(JSON.parse(JSON.stringify(this.bucketDetails)));
    this.adultCount = this.memberDetails.length;
  }

  createFSForm() {
    this.tabGroup = [];
    this.familyShieldForm = this.fb.group({
      Pincode: ['', [Validators.required, Validators.pattern(patterns.pincode), Validators.minLength(6)]],
      CustomerGstState: ['', Validators.required],
      CustomerGstStateCode: [''],
      CityState: ['', Validators.required],
      MemberDetails: this.fb.array([]),
      CoverDetails: this.fb.array([]),
      Product: "Family Shield",
      Tenure: [1],
      UserType: ['Agent'],

    });
    this.loadFormContent = true;

    // this.memberDetails.push(this.createMemberDetails(1));

    // this.healthFormData.quoteFormData.adultDetails.forEach((members, index) => {
    //   this.tabGroup[0]['memberDetails'].push({
    //     'label': 'Adult ' + (index + 1),
    //     'selected': index == 0 ? true : false,
    //     'hide': false
    //   })
    // })
  }
  steps: number[] = [];
  CreateCoverLabel(coverDetails) {
    // this.steps =[];
    // coverDetails.forEach(element => {
    //   let labelarray:any =[];
    //   for (let i = 0; i <= element.MaxSI; i += element.SliderOptionForSI) {
    //     labelarray.push(i);
    //   }  
    //   this.steps.push(labelarray)  ;
    //       console.log(this.steps)
    // });
  }
  removeLastMember(index) {
    this.memberDetails.removeAt(index);
    this.fsFormData.insuredFormData.insuredMemberDetails.splice(index, 1);
    this.coverDetails.removeAt(index)
    this.tabGroup.pop();
  }

  createTabGroup(index) {
    this.tabGroup.push({
      'tabLabel': 'Adult ' + index + '',
      'disabled': false,
      'hide': false,
      'selected': 0,
      'imgPath': "../../../../assets/icons/adults.png",
      'memberDetails': []
    })
  }
  createMemberDetails(index?): FormGroup {

    return new FormGroup({
      ['InsuredDOB']: new FormControl('', [Validators.required]),
      ['PreExistingIllness']: new FormControl(''),
      ['OccupationType']: new FormControl('', [Validators.required]),
      ['IncomeSlab']: new FormControl(''),
      ['MemberType']: new FormControl('Adult'),
    })

  }

  patchCoverDetails(item): FormGroup {
    // Create an object to hold the dynamic form controls
    const dynamicControls = {};
    this.bucketDetails.forEach(element => {
      element.coverDetails.forEach((ele, i) => {
        dynamicControls['CoverNameCheck_' + ele.CvrId] = new FormControl((item['CoverNameCheck_' + ele.CvrId]) ? true : false);
        dynamicControls['CoverSI_' + ele.CvrId] = new FormControl(item['CoverSI_' + ele.CvrId]);
        dynamicControls['CoverName' + ele.CvrId] = new FormControl(item['CoverName' + ele.CvrId]);
        dynamicControls['isValidamtentered' + ele.CvrId] = new FormControl(item['isValidamtentered' + ele.CvrId]);
        dynamicControls['Cover' + ele.CvrId] = new FormControl(element.bucketName);
        dynamicControls['CvrId'] = new FormControl(ele.CvrId);
        dynamicControls['isEnhansement_childern' + ele.CvrId] = new FormControl(item['isEnhansement_childern' + ele.CvrId]);
      })
    });

    dynamicControls['RiskCategory'] = new FormControl(item['RiskCategory'], [Validators.required]);
    dynamicControls['IsChecked'] = new FormControl(item['IsChecked']);

    // Use the object to create the FormGroup
    return new FormGroup(dynamicControls);
  }
  createCoverDetails(): FormGroup {
    // Create an object to hold the dynamic form controls
    const dynamicControls = {};
    this.bucketdata_original.push(JSON.parse(JSON.stringify(this.bucketDetails)));
    this.bucketListWeb = this.bucketDetails[0].coverDetails;
    this.bucketList.push(this.bucketDetails)
    this.bucketDetails.forEach(element => {
      element.coverDetails.forEach((ele, i) => {
        dynamicControls['CoverNameCheck_' + ele.CvrId] = new FormControl('',);
        dynamicControls['CoverSI_' + ele.CvrId] = new FormControl('0');
        dynamicControls['CoverName' + ele.CvrId] = new FormControl(ele.CoverName);
        dynamicControls['isValidamtentered' + ele.CvrId] = new FormControl(false);
        dynamicControls['Cover' + ele.CvrId] = new FormControl(element.bucketName);
        dynamicControls['CvrId'] = new FormControl(ele.CvrId);
        if(ele.CvrId === 13){
          dynamicControls['isEnhansement_childern'+ ele.CvrId] = new FormControl(false);
        }else{
          dynamicControls['isEnhansement_childern'+ ele.CvrId] = new FormControl(true);
        }
      })
    });
    dynamicControls['RiskCategory'] = new FormControl('', [Validators.required]);
    dynamicControls['IsChecked'] = new FormControl('');

    // Use the object to create the FormGroup
    return new FormGroup(dynamicControls);
  }

  get memberDetails(): FormArray {
    return this.familyShieldForm.get('MemberDetails') as FormArray;
  }
  get coverDetails(): FormArray {
    return this.familyShieldForm.get('CoverDetails') as FormArray;
  }
  tabIndex: number = 0;
  memberTypeSelected(event: any) {
    this.tabIndex = event.index;
    // console.log(this.tabIndex);
  }
  // Member count selected

  removeMember() {
    if (this.adultCount > 1) {
      this.adultCount--;
      this.removeLastMember(this.adultCount);
    }
    // console.log(this.memberDetails);
  }
  addMember() {
    if (this.adultCount < 4) {
      this.adultCount++;
      this.createTabGroup(this.adultCount);
      this.memberDetails.push(this.createMemberDetails(this.adultCount));
      this.coverDetails.push(this.createCoverDetails());
    }
    // console.log(this.memberDetails);
  }
  CoverDetails: any[] = [];
  coversControls: any[] = [];
  bucketName: string = "Death Benefit";
  selectedCover(coverdata, index, bucketName, memberNo) {
    this.CoverDetails = coverdata;
    this.bucketName = bucketName;
    this.bucketListWeb = this.bucketList[memberNo][index].coverDetails;

    // this.coversControls = this.familyShieldForm.controls['CoverDetails']['controls'][this.tabIndex].controls[bucketName.replace(/\s/g, "")].controls[0].controls
    // console.log(this.coversControls)
  }


  getSliderLabelArray(maxSI: number, step: number) {
    let labelArray: any;
    for (let i = step; i <= maxSI; i += step) {
      if (i != undefined)
        labelArray.push(i);
    }
    return labelArray
  }
  openRiskPopup() {
    this.popupService.openRiskCategoryPopUp(this.riskCategory)
  }

  openErrorPopup() {
    let popupData: PopupModal = {
      popupType: popupType.generic,
      imgName: popupImgPath.alertGif,
      header: '',
      description: popupDescriptionMsg.selectCover,
      buttonLabel: popupButton.cancel,
      buttonAction: popupButtonAction.close
    }
    this.popupService.openGenericPopup(popupData);
  }
  // Fetch statename according to pincode
  fetchDataAsPerPincode(event, existingPincode) {
    // if (existingPincode == null) {
    //   this.fsFormData.quoteDataSet = false;
    // }

    this.formReset = false;
    this.stateFetched = false;
    this.invalidPinCodeMessage = '';
    this.cityData = [];
    if ((existingPincode != null ? existingPincode : event.target.value.length == 6) && !this.familyShieldForm.controls['Pincode'].hasError('pattern')) {
      let data: RTOListRequestPayload = {
        "Pincode": existingPincode != null ? existingPincode.toString() : event.target.value,
        "CityID": ""
      }
        ;
      this.invalidPinCodeMessage = '';
      this.familyShieldForm.controls['CityState'].reset();
      this.subscription.push(this.rtoListService.getStatesCityByPin(data).subscribe({
        next: (response: RTOListResponsePayload) => {
          if (response.StatusCode == Constants.statusCode_success) {
            if (response.Data.length > 0) {
              this.stateDetails.stateId = response.Data[0].StateId ? response.Data[0].StateId : '';
              this.stateDetails.stateName = response.Data[0].StateName ? response.Data[0].StateName : '';
              this.stateDetails.pincode = existingPincode != null ? existingPincode : event.target.value;
              response.Data.forEach(cityList => {
                this.cityData.push({ 'cityLabel': cityList['CityName'], 'stateLabel': this.stateDetails.stateName, 'value': cityList['CityID'] });
              });
            }
            if (this.cityData.length == 1) {
              this.stateDetails.cityName = this.cityData[0].cityLabel;
              this.stateDetails.cityId = this.cityData[0].value;
              // this.getZoneData();
              this.familyShieldForm.controls['CityState'].setValue(this.stateDetails.stateName + ' - ' + this.stateDetails.cityName);
              this.familyShieldForm.controls['CustomerGstState'].setValue(this.stateDetails.stateName);

            }
            else {
            }

            // Need to check below in backward patching
            if (this.fsFormData.quoteDataSet) {
              this.zoneMappedWithPincode = this.fsFormData.quoteFormData.zone;
              this.familyShieldForm.controls['CityState'].setValue(this.fsFormData.quoteFormData.cityState);
              this.changeCityName(this.fsFormData.quoteFormData.cityId, false);
            }
            if (this.fsFormData.quoteDataSet && !this.patchingDone) {
              this.patchingDone = true;
            }
            this.stateFetched = true;
          }

          else if (response.StatusCode == Constants.statusCode_failure) {
            this.stateFetched = false;
            this.invalidPinCodeMessage = response.StatusDesc;
          }

          else {
            // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
            // this.popupService.openGenericPopup(this.errorPopupData);
          }
        },
        error: error => {
          this.invalidPinCodeMessage = '';
          this.stateFetched = false;
          // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
          // this.popupService.openGenericPopup(this.errorPopupData);
        }
      })
      )
    }
    this.utilityService.numberOnly(event);
    this.utilityService.restrictSpace(event);
  }
  changeCityName(cityValue: string, showSpinner: boolean) {
    this.stateDetails.cityId = cityValue;
    this.stateDetails.cityName = this.cityData.length > 0 ? (this.cityData.find(data => data.value == cityValue).cityLabel) : '';
    if (showSpinner) {
      ;
      this.getZoneData();
    }
    this.familyShieldForm.controls['CustomerGstState'].setValue(this.stateDetails.stateName);

  }
  getZoneData() {
    let params: ZoneRequestPayload = {
      "productcode": this.fsDataModal.zoneCode,
      "State": this.stateDetails.stateName,
      "City": this.stateDetails.cityName
    }
    this.subscription.push(this.healthAdvantedgeService.getZoneFromCityData(params).subscribe({
      next: (response: ZoneResponsePayload) => {
        if (response.StatusCode === Constants.statusCode_success) {
          let zoneData = response.Data.zone;
          this.zoneMappedWithPincode = zoneData ? ((zoneData == this.fsDataModal.zoneData[0]) ? zoneDetails.Zone1 : ((zoneData == this.fsDataModal.zoneData[1]) ? zoneDetails.Zone2 : zoneDetails.Zone3)) : '';

        }
        else {

          this.zoneMappedWithPincode = '';
          // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
          // this.popupService.openGenericPopup(this.errorPopupData);
        }
      },
      error: error => {
        // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
        // this.popupService.openGenericPopup(this.errorPopupData);
      }
    }))
  }
  getInsuredMinMaxDate(memberType: string, minMaxType: string) {
    if (memberType == 'Adult') {
      let adultMaxDate = this.utilityService.subtractYears(new Date(), 18);

      let yearsdate = this.utilityService.subtractYears(new Date(), 65);
      let monthdate = this.utilityService.subtractMonths(yearsdate, 11);
      let adultMinDate = this.utilityService.subtractDays(monthdate, 25);

      return minMaxType == 'min' ? adultMinDate : adultMaxDate;
    }
    else {
      let noOfAdults = this.memberDetails.length;
      if (noOfAdults > 0) {
        //depedent child
        let depChildMaxDate = this.utilityService.subtractMonths(new Date(), 3);

        let yearDate = this.utilityService.subtractYears(new Date(), 26);
        let monthDate = this.utilityService.subtractMonths(yearDate, 11);
        let depChildMinDate = this.utilityService.subtractDays(monthDate, 25);

        return minMaxType == 'min' ? depChildMinDate : depChildMaxDate;
      }
      else {
        //independent child
        let indChildMaxDate = this.utilityService.subtractYears(new Date(), 6);

        let yearDate = this.utilityService.subtractYears(new Date(), 25);
        let monthDate = this.utilityService.subtractMonths(yearDate, 11);
        let indChildMinDate = this.utilityService.subtractDays(monthDate, 25);

        return minMaxType == 'min' ? indChildMinDate : indChildMaxDate;
      }
    }
  }

  getShieldOccupationType($event,i){
   //show hide Cover Details on the basis of OccupationType and IncomeSlab selection start
   if(this.familyShieldForm.controls['MemberDetails'].value[i].OccupationType =="Salaried"){
    this.familyShieldForm.controls['MemberDetails']['controls'][i]['controls']['IncomeSlab'].setValue('');
  }else
  if(this.familyShieldForm.controls['MemberDetails'].value[i].OccupationType =="Self Employed"){
    this.familyShieldForm.controls['MemberDetails']['controls'][i]['controls']['IncomeSlab'].setValue('');
  }
  if(this.familyShieldForm.controls['MemberDetails'].value[i].IncomeSlab == '') {
    this.isEnableCoverPart[i] = false;
  } else {
    this.isEnableCoverPart[i] = true;
  }
  this.bucketdata_original[0].forEach((element,m)=>{
    element.coverDetails.forEach((item,n) => {
      if (this.memberDetails.value[i]['OccupationType'] == 'No Active Source Of Income') {
        let maxSI = item.NonSalariedBaseSI * item.BaseSiPercentage;
        this.bucketList[i][m].coverDetails[n].MaxSI = maxSI < item.NonSalariedMaxSI ? maxSI : item.NonSalariedMaxSI;
    }  })})
  }
  isEnableCoverPart: boolean[] = [false, false, false, false];
  getShieldOccupation(event, i,riskCoverCall) {
      if(event.value){
        event = event.value;
      }
 
    if (this.familyShieldForm.controls['MemberDetails'].value[i].OccupationType == 'No Active Source Of Income') {
      this.familyShieldForm.controls['MemberDetails']['controls'][i]['controls']['IncomeSlab'].setValue('0');
      this.isEnableCoverPart[i] = true;
    } else if (this.familyShieldForm.controls['MemberDetails'].value[i].OccupationType != 'No Active Source Of Income' && this.familyShieldForm.controls['MemberDetails'].value[i].IncomeSlab == '') {
      this.isEnableCoverPart[i] = false;
    } else {
      this.isEnableCoverPart[i] = true;
    }
    //show hide Cover Details on the basis of OccupationType and IncomeSlab selection end
    let median;
   
    if (event.includes('Upto')) {
      median = event.split(' ');
      median = median[1];
    } 
   if (event.includes('and above')) {
      median = event.split('and');
      median = median[0];
    } else if(event.includes('-')){
      median = event.split('-');
      median = (parseInt(median[0]) + parseInt(median[1])) / 2;
    }
    median = Math.round(parseInt(median));
    // console.log(median);
    if(this.memberDetails.value[i]['OccupationType'] == 'No Active Source Of Income'){
       this.updateCoverMaxSI(median, i);
    }
    if(riskCoverCall  == 'riskCoverCall'){
      this.updateCoverMaxSI(median, i);
    }
  }
  updateCoverMaxSI(median, member_no){
    let defaultMaxSILessThan1CR = 10000000;
    this.bucketdata_original[0].forEach((element,m)=>{
      element.coverDetails.forEach((item,n) => {
        if (this.memberDetails.value[member_no]['OccupationType'] == 'No Active Source Of Income') {
          let maxSI = item.NonSalariedBaseSI * item.BaseSiPercentage;
          this.bucketList[member_no][m].coverDetails[n].MaxSI = maxSI < item.NonSalariedMaxSI ? maxSI : item.NonSalariedMaxSI;
      }
      else {
          let maxSILessThan1CR = 10000000;
          if (item.CvrId != 7) {
              maxSILessThan1CR = median * (this.memberDetails.value[member_no]['OccupationType'] == "Salaried" ?  this.MasterData.IncomeMultiplierForSalaried : this.MasterData.IncomeMultiplierForSelfEmployed);
          }
          else {
              maxSILessThan1CR = median / 4;
          }
          let maxSI = (maxSILessThan1CR < defaultMaxSILessThan1CR ? maxSILessThan1CR : defaultMaxSILessThan1CR) * item.BaseSiPercentage;
          let actualSI =item.MaxSI;
          this.bucketList[member_no][m]['coverDetails'][n].MaxSI = maxSI < actualSI ? maxSI : actualSI;
      }
      });
      // console.log(element);
    })
  }
  shouldShowCoverName4(cover: any, i: any, j: any) {
    let isshow:boolean = true;
    if(this.bucketdata_original[i] != undefined)
    this.bucketdata_original[i].forEach((element,m)=>{
      element.coverDetails.forEach((item,n) => {
        if (this.memberDetails.value[i]['OccupationType'] == "Salaried" || this.memberDetails.value[i]['OccupationType'] == "Self Employed") {
          if (cover.Applicability != "Child" && cover.CoverType == "Base") {
            isshow = true;
          } else if ((cover.CvrId == 8 && cover.CoverName == "Lifestyle Support Benefit" && cover.CoverType == "Extension") ||
            (cover.CvrId == 14 && cover.CoverName == "Burns Benefit" && cover.CoverType == "Extension") ||
            (cover.CvrId == 13 && cover.CoverName == "Compassionate Visit Benefit" && cover.CoverType == "Extension") ||
            (cover.CvrId == 12 && cover.CoverName == "Broken Bones Benefit" && cover.CoverType == "Extension")
          ) {
            isshow = true;
          }
          else {
              if ( cover.Applicability != "Child" && cover.CoverType == "Base") {
                isshow =  true;
              }
            
            isshow = false;
          }
        }
        else {
          if (cover.Applicability != "Child" && cover.CoverType == "Base" && parseInt(cover.NonSalariedMaxSI) > 0) {
            isshow = true;
          }
          else {
           
              if (cover.Applicability != "Child" && cover.CoverType == "Base") {
                isshow=  true;
              
            }
            isshow = false;
          }
        }
      })})
  return isshow

  }
  // shouldShowCoverDetails4(cover: any, i: any, j: any) {
  //   if (this.insuredForm.value.Members[3].memberOccuaption == 1 || this.insuredForm.value.Members[3].memberOccuaption == 2) {
  //     if (!cover.IsFixedOption && cover.Applicability != "Child" && cover.CoverType == "Base") {
  //       return true;
  //     }
  //     else {
  //       for (let j = 0; j < this.buckets4[i].covers4.length; j++) {
  //         if (this.buckets4[i].covers4[j].checked1 == true && !cover.IsFixedOption && cover.Applicability != "Child" && cover.CoverType == "Base") {
  //           return true;
  //         }
  //       }
  //       return false;
  //     }
  //   }
  //   else {
  //     if (!cover.IsFixedOption && cover.Applicability != "Child" && cover.CoverType == "Base" && parseInt(cover.NonSalariedMaxSI) > 0) {
  //       return true;
  //     }
  //     else {
  //       for (let j = 0; j < this.buckets4[i].covers4.length; j++) {
  //         if (this.buckets4[i].covers4[j].checked1 == true && !cover.IsFixedOption && cover.Applicability != "Child" && cover.CoverType == "Base") {
  //           return true;
  //         }
  //       }
  //       return false;
  //     }
  //   }

  // }


  changePolicyTenure(tenureData) {

  }
  openDialog() {

    const dialogRef = this.dialog.open(dialog, {
      width: '600px',
      height: 'auto',
      data: this.riskCategory
    }
    );
  }
  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }
  hasError(controlName: string, errorCode: string): boolean {
    return !this.familyShieldForm.valid && this.familyShieldForm.hasError(errorCode, [controlName]);
  }
  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }
  allMemberDetails = [];
  MemberDetails = [];
  finalCoverObject = [];
  constructRequest(): SaveEditQuoteV1Request {
    this.allMemberDetails = [];
    let allAddons = [];
    this.MemberDetails = [];
    this.familyShieldForm.controls['MemberDetails'].value.forEach((ele, k) => {
      let objmemberData = { InsuredDOB: '', OccupationType: '', PreExistingIllness: false, RiskCategory: 0, MemberNo: 0, CoverDetails: [] };
      let objmember = { InsuredDOB: '', OccupationType: '', PreExistingIllness: false, RiskCategory: 0, MemberNo: 0, CoverDetails: [] };
      objmemberData.InsuredDOB = this.utilityService.formatDateWithDash(ele.InsuredDOB);
      objmemberData.OccupationType = ele.OccupationType;
      objmemberData.MemberNo = k + 1;
      objmember.InsuredDOB = this.utilityService.formatDateWithDash(ele.InsuredDOB);
      objmember.OccupationType = ele.OccupationType;
      objmember.MemberNo = k + 1;
      for (let i = 1; i <= 20; i++) {
        const checkKey = `CoverNameCheck_${i}`;
        const siKey = `CoverSI_${i}`;
        const nameKey = `CoverName${i}`;
        const coverKey = `Cover${i}`;
        const isvalidKey = `isValidamtentered${i}`;
        if (this.familyShieldForm.controls['CoverDetails'].value[k][checkKey]) {
          let coverdata: any = { CoverSI: '', CoverName: '', Column1: '' }
          coverdata.CoverSI = parseInt(this.familyShieldForm.controls['CoverDetails'].value[k][siKey]);
          coverdata.CoverName = this.familyShieldForm.controls['CoverDetails'].value[k][nameKey];
          objmemberData.RiskCategory = parseInt(this.familyShieldForm.controls['CoverDetails'].value[k].RiskCategory);
          objmemberData.CoverDetails.push(coverdata)
        }
        //for viewfull summary
        if (this.familyShieldForm.controls['CoverDetails'].value[k][checkKey]) {
          let coverdetail: any = { CoverSI: '', CoverName: '', Column1: '', Cover: '', AdultStatusFlag: false, AllInsuredFlag: false, CvrId: 0,isValidamtentered:'' }
          coverdetail.CoverSI = parseInt(this.familyShieldForm.controls['CoverDetails'].value[k][siKey]);
          coverdetail.CoverName = this.familyShieldForm.controls['CoverDetails'].value[k][nameKey];
          objmember.RiskCategory = parseInt(this.familyShieldForm.controls['CoverDetails'].value[k].RiskCategory);
          coverdetail.Cover = this.familyShieldForm.controls['CoverDetails'].value[k][coverKey];
          coverdetail.CvrId = this.familyShieldForm.controls['CoverDetails'].value[k]['CvrId'];
          coverdetail.isValidamtentered = this.familyShieldForm.controls['CoverDetails'].value[k][isvalidKey];
          coverdetail[`member${k + 1}`] = true;
          objmember.MemberNo = k + 1;
          objmember.CoverDetails.push(coverdetail)
        }
      }
      this.MemberDetails.push(objmemberData)

      this.allMemberDetails.push(objmember);

      this.allMemberDetails.forEach((member) => {
        member.CoverDetails.forEach((addon) => {
          allAddons.push(addon)
        })
      })

    })

    let key = 'CoverName';
    this.finalCoverObject = this.findOcc(allAddons, key)

    // console.log(this.finalCoverObject)
    // console.log(this.allMemberDetails)
    let requestData: SaveEditQuoteV1Request = {
      UserType: this.familyShieldForm.controls['UserType'].value,
      CustomerGstState: this.familyShieldForm.controls['CustomerGstState'].value,
      MemberDetails: this.MemberDetails,
      Tenure: this.familyShieldForm.controls['Tenure'].value
    }
    return requestData;
  }
  findOcc(arr, key) {
    let arr2 = [];
    let count = 0;

    arr.forEach((x) => {

      // Checking if there is any object in arr2 
      // which contains the key value 
      if (arr2.some((val) => { return val[key] == x[key] })) {

        // If yes! then increase the occurrence by 1 
        arr2.forEach((k) => {
          if (k[key] === x[key]) {
            k["occurrence"] = count++;
          }
        })

      } else {
        // If not! Then create a new object initialize  
        // it with the present iteration key's value and  
        // set the occurrence to 1 
        let a = {}
        a[key] = x[key]
        a["occurrence"] = 1
        arr2.push(a);
      }
    })

    return arr2
  }



  // findOcc(arr, key) {
  //   let arr2 = [];
  //   let count = 0;

  //   arr.forEach((x) => {

  //     // Checking if there is any object in arr2 
  //     // which contains the key value 
  //     if (arr2.some((val) => { return val[key] == x[key] })) {

  //       // If yes! then increase the occurrence by 1 
  //       arr2.forEach((k) => {
  //         if (k[key] === x[key]) {
  //           k["occurrence"] = count++;
  //         }
  //       })

  //     } else {
  //       // If not! Then create a new object initialize  
  //       // it with the present iteration key's value and  
  //       // set the occurrence to 1 
  //       let a = {}
  //       a[key] = x[key]
  //       a["occurrence"] = 1
  //       arr2.push(a);
  //     }
  //   })

  //   return arr2
  // }
  getQuote(event: any) {

    let request: SaveEditQuoteV1Request = this.constructRequest();
    this.formReset = false;
    this.formSubmitted = true;
    this.familyShieldForm.markAllAsTouched();
    // const doesNotContainTrue = this.familyShieldForm.controls['CoverDetails'].value.every((value,i) => value['CoverNameCheck_' + i] !== true);
    // if (!doesNotContainTrue) {
    //   this.snackBar.open('Please select at least one cover.' ,'Ok', {
    //     duration: 2000,
    //     verticalPosition: 'bottom',
    //     horizontalPosition: 'center',
    //   });
    //   return
    // } 
    this.allMemberDetails.forEach((item, x) => {
      if(this.familyShieldForm.controls['CoverDetails'].value[x].RiskCategory === '' &&  item.InsuredDOB  && item.OccupationType){
        if(this.familyShieldForm.controls['MemberDetails'].value[x].IncomeSlab !=""){
          this.snackBar.open('Please select RiskCategory for Adult ' + item.MemberNo + '', 'Ok', {
            duration: 2000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
          });
          this.formSubmitted = false;
          return;
        }
            }
            if(this.familyShieldForm.controls['CoverDetails'].value[x].RiskCategory === ''  && this.familyShieldForm.controls['MemberDetails'].value[x].OccupationType =='No Active Source Of Income'&&  item.InsuredDOB){
              this.snackBar.open('Please select RiskCategory for Adult ' + item.MemberNo + '', 'Ok', {
                duration: 2000,
                verticalPosition: 'bottom',
                horizontalPosition: 'center',
              });
              this.formSubmitted = false;
              return;
            }
      if (item.CoverDetails.length===0 && this.familyShieldForm.controls['CoverDetails'].value[x].RiskCategory != '' && item.InsuredDOB != '' && item.OccupationType != '') {
        this.snackBar.open('Please select covers for Adult ' + item.MemberNo + '', 'Ok', {
          duration: 2000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center',
        });
        this.formSubmitted = false;
        return;
      }
      if (item.CoverDetails && this.familyShieldForm.controls['CoverDetails'].value[x].RiskCategory != '' && item.InsuredDOB != '' && item.OccupationType != '') {
        item.CoverDetails.forEach((element,index) => {
          if (element.CoverSI == 0) {
            this.snackBar.open('Please select sum insured of Adult ' + item.MemberNo + ' for Cover Name - ' + element.CoverName + '', 'Ok', {
              duration: 2000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
            });
            this.formSubmitted = false;
            return;
          } if (element['isValidamtentered']) {
            this.snackBar.open('Please entered amount in multipe of required amount for  ' + item.MemberNo + 'for Cover Name - ' + element.CoverName + ' ', 'Ok', {
              duration: 2000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
            });
            this.formSubmitted = false;
            return;
          }
        });
      }
   
    })
    if (this.familyShieldForm.invalid) {
        for (const key of Object.keys(this.familyShieldForm.controls)) {
          if (this.familyShieldForm.controls[key].invalid) {
            const invalidControl = this.el.nativeElement.querySelector('#' + key + '.ng-invalid');
            invalidControl.scrollIntoView({ behaviour: "instant", block: "center", inline: "center" });
            invalidControl.focus();
            break;
          }
        }
        return;
      }
      if (this.formSubmitted) {
        if (this.familyShieldForm.invalid) {
          for (const key of Object.keys(this.familyShieldForm.controls)) {
            if (this.familyShieldForm.controls[key].invalid) {
              const invalidControl = this.el.nativeElement.querySelector('#' + key + '.ng-invalid');
              invalidControl.scrollIntoView({ behaviour: "instant", block: "center", inline: "center" });
              invalidControl.focus();
              break;
            }
          }
          return;
        }
        this.subscription.push(this.familyShieldService.saveEditQuoteData(request).subscribe({
          next: response => {
  
            if (response.StatusCode == Constants.statusCode_success) {
              this.patchingDone = true;
              if(response.Data.CorrelationId) {
                this.fsFormData.quoteFormData.corelationId = response.Data.CorrelationId;
                this.healthDataStorageService.setHealthDataStorage(this.fsFormData);
              }
              if(!this.savedQuoteData) {
                this.customStepperService.resetStepper(true);
              }
              this.statuscode = true
              // if (response.Data.Policystatus == "CONDITIONALAPPROVE" && this.healthAdvantageForm.controls['EMI'].value == 'Yes') {
              this.setFsFormData(response?.Data);
              if (response.Data.TotalPremiumOfMembers < 500) {
                let popupData: PopupModal = {
                  popupType: popupType.generic,
                  imgName: popupImgPath.alertGif,
                  header: popupHeader.error,
                  description: 'The premium can not be less than 500. please select a diffrent combination of covers and/or SI',
                  buttonLabel: popupButton.ok,
                  buttonAction: popupButtonAction.close
                }
                this.popupService.openGenericPopup(popupData);
                return
              }
              // this.customStepperService.emitStepData({ status: true, buttonId: 'f3' });
              // this.showPremiumDetails(true);
  
              // this.errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.emiNotApplicable);
              // this.popupService.openGenericPopup(this.errorPopupData);
  
              // this.emiValueChanged();
              // }
              // else {
              // this.disableButton = true;
              // this.success = true;
              this.patchingDone = true;
              // If premium exists then only allow user to proceed forward
              if (!this.utilityService.isEmptyOrNull(this.fsFormData.premiumFormData.totalPremium) && this.fsFormData.premiumFormData.totalPremium != '0') {
                this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
                this.showPremiumDetails(true);
              }
              else {
                this.customStepperService.emitStepData({ status: true, buttonId: 'f1' });
              }
              this.showPremiumDetails(true);
            }
            // }
            else if (response.StatusCode != Constants.statusCode_success) {
              // this.success = false;
              // this.disableButton = false;
              // this.errorPopupData = this.popupService.fetchErrorPopupMsg(response.StatusDesc);
              // this.popupService.openGenericPopup(this.errorPopupData);
              // this.statuscode = false;
              this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
            }
          },
          error: (err) => {
            // this.success = false;
            this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
            // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
            // this.popupService.openGenericPopup(this.errorPopupData);
          }
        }))
      }
   
  }
  showPremiumDetails(data: boolean) {
    this.customFooterService.showPremiumDetails(data);
  }
  ngOnDestroy() {
    this.unsubscribeSubscription();
  }

  unsubscribeSubscription() {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    })
  }
  setFsFormData(premiumData: ZeroTatHealthDetails) {
    let resetFlag: boolean = this.checkMemberDetails();

    // this.fsFormData.quoteFormData.addonList = this.familyShieldForm.controls['CoverDetails'].value;
    // this.fsFormData.insuredFormData.befitCovers = this.hapMasterData.Data.befitCovers;
    // this.fsFormData.insuredFormData.BefitApplicableCities = this.hapMasterData.Data.BefitApplicableCities;

    // if (this.healthAdvantageForm.value.productType != 'New') {
    //   this.fsFormData.portabilityDataSet = true;
    //   this.fsFormData.quoteFormData.InstallmentApplicable = "No"
    //   this.fsFormData.quoteFormData.InstallmentFrequency = ""
    // }
    // else {
    // this.fsFormData.portabilityDataSet = false;
    // }

    this.fsFormData.quoteFormData.productName = this.familyShieldForm.controls['Product'].value;
    this.fsFormData.quoteFormData.fsFormData = this.familyShieldForm.value;
    this.fsFormData.quoteFormData.bucketList = this.bucketList
    this.fsFormData.quoteFormData.isEnableCoverPart = this.isEnableCoverPart
    // this.fsFormData.quoteFormData.productType = this.familyShieldForm.controls['productType'].value;
    this.fsFormData.quoteFormData.pincode = this.familyShieldForm.controls['Pincode'].value;
    this.fsFormData.quoteFormData.adultDetails = this.memberDetails.value;
    // this.fsFormData.quoteFormData.childDetails = this.childDetails.value;
    this.fsFormData.quoteFormData.cityId = this.stateDetails.cityId;
    this.fsFormData.quoteFormData.cityName = this.stateDetails.cityName;
    this.fsFormData.quoteFormData.stateId = this.stateDetails.stateId;
    this.fsFormData.quoteFormData.totalMemberData = this.memberDetails.value;
    this.fsFormData.quoteFormData.policyTenure = this.familyShieldForm.controls['Tenure'].value;
    // this.fsFormData.quoteFormData.policyRange = this.healthAdvantageForm.controls['policyRange'].value;
    // this.fsFormData.quoteFormData.applicantAnnualSum = this.healthAdvantageForm.controls['applicantAnnualSum'].value;
    // this.fsFormData.quoteFormData.pedWaitingPeriod = this.healthAdvantageForm.controls['pedWaitingPeriod'].value;
    // this.fsFormData.quoteFormData.conditionWaitingPeriod = this.healthAdvantageForm.controls['conditionWaitingPeriod'].value;
    // this.fsFormData.quoteFormData.preHospitalDuration = this.healthAdvantageForm.controls['preHospitalDuration'].value;
    // this.fsFormData.quoteFormData.postHospitalDuration = this.healthAdvantageForm.controls['postHospitalDuration'].value;
    this.fsFormData.quoteFormData.cityState = this.familyShieldForm.controls['CityState'].value;
    // this.fsFormData.quoteFormData.applicantAnnualIncome = this.healthAdvantageForm.controls['applicantAnnualIncome'].value;
    // this.fsFormData.quoteFormData.softCopyDiscount = this.healthAdvantageForm.controls['softCopyDiscount'].value;
    // this.fsFormData.quoteFormData.pedWaitingPeriod = this.healthAdvantageForm.controls['pedWaitingPeriod'].value;
    // this.fsFormData.quoteFormData.copaymentPercentTaken = this.healthAdvantageForm.controls['copaymentPercentTaken'].value == 'No' ? null : this.healthAdvantageForm.controls['copaymentPercentTaken'].value.replace('%', '');
    // this.fsFormData.quoteFormData.underWritterApproved = premiumData.Policystatus == "CONDITIONALAPPROVE" ? 'true' : 'false';
    this.fsFormData.quoteFormData.healthProposalProductType = healthProposalProductCode.fs;
    this.fsFormData.quoteFormData.productCode = this.MasterData.PRODUCT_CODE;
    this.fsFormData.quoteFormData.correlationId = premiumData.CorrelationId;
    // this.fsFormData.quoteFormData.isBefitApplicable = this.stateDetails.isBefitApplicable;
    // this.fsFormData.policyDetails.DealId = this.dealId;
    // if (this.healthAdvantageForm.controls['EMI'].value == 'Yes') {
    //   this.fsFormData.quoteFormData.InstallmentApplicable = 'Yes';
    //   this.fsFormData.quoteFormData.InstallmentFrequency = this.healthAdvantageForm.controls['EMIInstallment'].value;
    // }
    // else {
    //   this.fsFormData.quoteFormData.InstallmentApplicable = 'No';
    // }
    // this.setAddonsFormValue();
    // this.fsFormData.quoteFormData.zone = this.zoneMappedWithPincode;
    this.fsFormData.quoteFormData.addons = this.CoverDetails;
    // this.fsFormData.quoteFormData.personalAccidentAddons = this.personalAccidentAddons;
    // this.fsFormData.quoteFormData.maternityAddons = this.maternityAddons;
    // this.fsFormData.quoteFormData.nonMemberBasedAddons = this.nonMemberBasedAddons;
    // this.fsFormData.quoteFormData.adultRelationShips = this.adultRelationShips;
    // this.fsFormData.quoteFormData.childRelationShips = this.childRelationShips;
    this.fsFormData.quoteFormData.subProductType = 23;
    // this.fsFormData.quoteFormData.zoneUpgrade = this.healthAdvantageForm.controls['zoneUpgrade'].value == "Yes" ? true : false;
    // this.fsFormData.quoteFormData.reviseZone = this.healthAdvantageForm.controls['zoneUpgrade'].value == "Yes" ? this.healthAdvantageForm.controls['revisedZone'].value : this.zoneMappedWithPincode;
    this.fsFormData.quoteDataSet = true;

    // If emi is true reset insured details
    // let emiFlag: boolean = false;
    // if (this.healthAdvantageForm.controls['EMI'].value == healthToggle.Yes) {
    //   if (this.fsFormData.insuredDataSet) {
    //     emiFlag = true;
    //   }
    // }
    if (resetFlag ) {
      this.setOtherFormFlags();
    }
    this.fsFormData.quoteFormData.quoteSummary = this.allMemberDetails;
    // this.constructAddonDetailsForSummary();

    this.constructPremiumData(premiumData);
    this.healthDataStorageService.setHealthDataStorage(this.fsFormData);
  }
  checkMemberDetails(): boolean {
    let nonEqualMembers = [];
    let resetFlag: boolean = false;
    // Adult Details
    // If new adult details and child details length are same then we need to check the info
    if (this.memberDetails.length == this.fsFormData.quoteFormData.adultDetails.length) {
      let existingAdultDetails = this.fsFormData.quoteFormData.adultDetails;
      existingAdultDetails.forEach((adultData, index) => {
        let tempAdultDetails = adultData;
        // When there is self detected there is change in format of dob.
        if (this.fsFormData.kycStatus.ovdSelf || this.fsFormData.kycStatus.normalSelf) {
          // convert dob in respected format
          for (let keys in tempAdultDetails) {
            // There are chances date is patched along with orignal time stamp
            if (keys.includes('dob') && !tempAdultDetails[keys].includes('T')) {
              tempAdultDetails[keys] = (new Date(tempAdultDetails[keys]));
            }
          }
        }
        // Looping through keys
        if (JSON.stringify(tempAdultDetails)[index]['dob'] != JSON.stringify(this.memberDetails.value[index]['dob'] )) {
          nonEqualMembers.push(index);
        }
      });
    }
    // If the length is only not equal that means adult data is been modified
    else if (this.memberDetails.length != this.fsFormData.quoteFormData.adultDetails.length) {
      nonEqualMembers.push(0);
    }

    // Child Details


    

    else {
      // Adult members found to be equal
      nonEqualMembers = [];
    }

    // If there is mismatch of data in terms of length or details
    if (nonEqualMembers.length > 0) {
      resetFlag = true;
    }
    else {
      resetFlag = false;
    }
    return resetFlag;
  }



  setOtherFormFlags() {
    this.fsFormData.insuredDataSet = false;
    this.fsFormData.kycDataSet = false;
    this.fsFormData.kycStatus.ekycMandatory = false;
    this.fsFormData.kycStatus.normal = false;
    this.fsFormData.kycStatus.normalSelf = false;
    this.fsFormData.kycStatus.ovd = false;
    this.fsFormData.kycStatus.ovdSelf = false;
    this.fsFormData.kycStatus.isKYCDoneSuccessfully = false;
    this.fsFormData.kycStatus.idType = false;
    this.fsFormData.kycStatus.certNumber = '';
    this.fsFormData.kycStatus.isPilotUser = false;
    this.fsFormData.kycFormData = this.healthDataStorageService.resetKYCdata();
  }
  constructPremiumData(response: ZeroTatHealthDetails) {
    this.fsFormData.premiumFormData.totalPremium = response.TotalPremiumOfMembers;
    this.fsFormData.quoteFormData.correlationId = response.CorrelationId
    this.fsFormData.premiumFormData.basicPremium = response.TotalBasicPremiumOfMembers;
    this.fsFormData.premiumFormData.totalTax = response.TotalTaxOfMembers;
    this.fsFormData.premiumFormData.tenure = this.familyShieldForm.controls['Tenure'].value;
    // this.fsFormData.premiumFormData.QuoteId = response.QuoteId;
    this.fsFormData.premiumFormData.SubProductCode += 23;
    // this.fsFormData.premiumFormData.PfQuoteId = response.PFQuoteID;
    this.fsFormData.premiumFormData.InstallmentNetPremium = response.TotalSiOfMembers.toString();
    // this.fsFormData.premiumFormData.InstallmentTaxAmount = response.InstallmentTaxAmount;
    // this.fsFormData.premiumFormData.InstallmentTotalPremium = response.InstallmentTotalPremium;
    // let c = response.Zone == "Zone_III" ? 'C' : ''; 
    // let c1 = response.Zone == "Zone_II" ? 'B' : c;
    // this.fsFormData.premiumFormData.zone = response.Zone == "Zone_I" ? 'A' : c1;
    this.fsFormData.premiumDataSet = true;
    // this.formValid = true;
  }

  // constructAddonDetailsForSummary() {
  //   let mergedArray = [];
  //   //Merged All Members covers;
  //   for (let i = 0; i < this.allMemberDetails.length; i++) {
  //     mergedArray = [...mergedArray, ...this.allMemberDetails[i].CoverDetails];
  //   }
  //   const coverNameOccurrences = {};
  //   mergedArray.forEach(item => {
  //       const coverName = item.CoverName;
  //       coverNameOccurrences[coverName] = (coverNameOccurrences[coverName] || 0) + 1;
  //   });
  //   // Convert occurrences to key-value pairs
  //   const coverNameKeyValuePairs = Object.entries(coverNameOccurrences);
  //   const arrayOfObjects = coverNameKeyValuePairs.map(([coverName, count]) => ({ CoverName: coverName, Count: count }));
  //   this.allMemberDetails.forEach((element,i)=>{
  //     arrayOfObjects.forEach(coverName=>{
  //       const hasDeathBenefit = element.CoverDetails.some(item => item.CoverName === coverName.CoverName)
  //       coverName['AdultAtatus'] = coverName['AdultAtatus'] || [];
  //       coverName['AdultAtatus'].push(hasDeathBenefit);
  //     })
  //     // console.log("Array of Objects:", adultstatus);
  //   })
  //   console.log("Array of Objects:", arrayOfObjects);
  // }


  getMaxSI(IsChecked, coverIndex, memberIndex) {
    if(IsChecked.checked === true && coverIndex.CvrId === 11){
    // this.familyShieldForm.controls['CoverDetails'].value[memberIndex]['isEnhansement_childern'+13].setValue(false);
    this.familyShieldForm.controls['CoverDetails']['controls'][memberIndex].controls['isEnhansement_childern'+13].setValue(true)
     } else if(IsChecked.checked === false && coverIndex.CvrId === 11) {
      // this.familyShieldForm.controls['CoverDetails'].value[memberIndex]['isEnhansement_childern'+13].setValue(true);
    this.familyShieldForm.controls['CoverDetails']['controls'][memberIndex].controls['isEnhansement_childern'+13].setValue(false)
    }
      if (typeof coverIndex === 'object') {
      //for WebView getting object as CoverName.Because of design perspective it has been implemented.
      let getindex;
      this.bucketList[memberIndex].forEach((element, i) => {
        element.coverDetails.forEach(cover => {
          if (cover.CoverName === coverIndex.CoverName) {
            getindex = i;
          }
        });
      });
      // console.log(getindex);
    } else {
      //for MobileView getting index as Cover Number 
    }
  }


  isValid: boolean = false;
  isAmountInvalid(maxSI, multipleofammount, index: number, coverId: string) {
    let isMultiple = this.familyShieldForm.controls['CoverDetails'].value[index]['CoverSI_' + coverId] % multipleofammount === 0;
    // console.log(isMultiple)
    if (!isMultiple) {
      this.familyShieldForm.controls['CoverDetails'].value[index]['isValidamtentered' + coverId] = true;
    } else {
      this.familyShieldForm.controls['CoverDetails'].value[index]['isValidamtentered' + coverId] = false
    }
  }
}


@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.html',
})
export class dialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public infodata: Array<any>, public dialogRef: MatDialogRef<dialog>,
  ) {
    // console.log(this.infodata)
  }
  Onclose() {

    this.dialogRef.close();
  }

}