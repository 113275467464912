<section class="container-fluid">
    <div class="row">
        <div class="container panaMaincontainer">
            <div class="marginbox">

                <!-- header starts-->

                <div class="myTransactionTitle">
                    <div class="bbrheading">
                        <span [innerHTML]="updateKYCImg"></span>
                        <span class="titleSpanWidth">
                            <h3 class="titleH3Text">Update KYC</h3>
                        </span>
                    </div>
                </div>

                <!-- header ends-->

                <!-- card 1 starts-->

                <mat-card class="innerbox borderinnerbox">
                    <div class="row fieldsdiv" [formGroup]="updateKYCForm">

                        <div class="col-sm-4 form-group">
                            <mat-label class=" input-group__label" for="CustomerName">Customer Name</mat-label>
                            <input matInput type="text" class="input-group__input" placeholder="Customer Name"
                                autocomplete="off" formControlName="CustomerName" id="CustomerName">
                            <div *ngIf="updateForm['CustomerName'].invalid">
                                <div class="errorMessage noPadding"
                                    *ngIf="updateForm['CustomerName'].errors['pattern']">
                                    Name is invalid
                                </div>
                            </div>
                            <div class="errorMessage noPadding"
                                *ngIf="!updateForm['CustomerName'].value && !updateForm['PFCustomerId'].value && isSubmitted">
                                Name is required
                            </div>
                        </div>

                        <div class="col-sm-4 form-group">
                            <mat-label class=" input-group__label" for="Email">Email</mat-label>
                            <input matInput type="text" class="input-group__input" placeholder="Email"
                                autocomplete="off" formControlName="Email" id="Email">
                            <div *ngIf="updateForm['Email'].invalid">
                                <div class="errorMessage noPadding" *ngIf="updateForm['Email'].errors['email']">
                                    Email is invalid
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 form-group">
                            <mat-label class=" input-group__label" for="MobileNumber">Mobile Number</mat-label>
                            <input matInput type="text" class="input-group__input" placeholder="Mobile Number"
                                autocomplete="off" formControlName="MobileNumber" id="MobileNumber">
                            <div *ngIf="updateForm['MobileNumber'].invalid">
                                <div class="errorMessage noPadding"
                                    *ngIf="updateForm['MobileNumber'].errors['pattern'] ">
                                    Mobile Number is invalid
                                </div>
                            </div>
                            <div class="errorMessage noPadding"
                                *ngIf="(!updateForm['MobileNumber'].value && !updateForm['Email'].value && !updateForm['PFCustomerId'].value)  && isSubmitted">
                                Mobile Number is required
                            </div>
                        </div>

                        <div class="col-sm-12 orDiv form-group">
                            <h2 class="orHeading">OR</h2>
                        </div>

                        <div class="col-sm-4 form-group">
                            <mat-label class=" input-group__label" for="PFCustomerID">PF Customer ID</mat-label>
                            <input matInput type="text" class="input-group__input" placeholder="PF Customer ID"
                                autocomplete="off" formControlName="PFCustomerId" id="PFCustomerID">
                            <div *ngIf="updateForm['PFCustomerId'].invalid">
                                <div class="errorMessage noPadding"
                                    *ngIf="updateForm['PFCustomerId'].errors['pattern'] ">
                                    PF Customer ID is invalid
                                </div>
                            </div>
                            <div class="errorMessage noPadding"
                                *ngIf="(!updateForm['PFCustomerId'].value && !updateForm['CustomerName'].value && !updateForm['Email'].value && !updateForm['MobileNumber'].value)  && isSubmitted">
                                PF Customer ID is required
                            </div>
                        </div>
                    </div>

                    <div class="row fieldsdiv form-group-top">
                        <div class="col form-group">
                            <button class="search-button" (click)="onSearch()">
                                <span>Search Customer</span>
                            </button>
                        </div>
                    </div>
                </mat-card>

                <!-- card 1 ends-->

                <!-- card 2 starts-->

                <!-- <mat-card class="innerbox borderinnerbox" *ngIf="showCustomer">
                    <ng-container *ngFor="let customer of customerDetails">
                        <div class="paddingDiv">
                            <div class="row customDiv">

                                <div class="col-sm-4 form-group">
                                    <mat-label class="mtlabel">Email</mat-label>
                                    <p>{{customer.CustomerEmail}}</p>
                                </div>

                                <div class="col-sm-4 form-group">
                                    <mat-label class="mtlabel">Mobile Number</mat-label>
                                    <p>{{customer.CustomerMobile}}</p>
                                </div>


                                <div class="col form-group">
                                    <button class=" search-button" (click)="onUpdate()">
                                        <span>Update KYC</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </mat-card> -->

                <div class="mb-1" *ngIf="showCustomer">
                    <span class="numberOfRecords">
                        {{customerList.length}} record(s) matching your search criteria were found.
                    </span>
                    <div class="kyccustomerTable">
                        <table class="kycTable">
                            <caption class="hidden-caption">Details</caption>
                            <td class="hidden-caption">KYC Details</td>
                            <thead>
                                <tr>
                                    <th>Serial No.</th>
                                    <th>Customer Name</th>
                                    <th>Email</th>
                                    <th>Mobile Number</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of customerList; let index = index">
                                    <td>{{ index + 1 }}</td>
                                    <td class="titlecase">{{ item.CustomerName.toLowerCase()}}</td>
                                    <td>{{ item.CustomerEmail | lowercase}}</td>
                                    <td>{{ item.CustomerMobile }}</td>
                                    <td>
                                        <button class="search-button blue-button" (click)="onUpdate(item)">
                                            <span>Update KYC</span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- card 2 ends -->
            </div>
        </div>
    </div>
</section>

<!-- Kyc Modal -->
<div class="modal fade" id="externalUrlModal" tabindex="-1" role="dialog" aria-labelledby="externalUrlModalLabel"
    aria-hidden="true">
    <div class="modal-dialog my-0" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <iframe #externalUrlFrame width="100%" height="600px"></iframe>
            </div>
        </div>
    </div>
</div>

<!-- <ng-template #externalUrlModal>
    <iframe [src]="externalUrl" width="100%" height="600px"></iframe>
</ng-template> -->