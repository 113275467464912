import { Component, OnDestroy, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HealthDataStorage } from '../../health.modal';
import { RelationshipRequestPayload, RelationshipResponsePayload } from 'src/app/shared/interface/healthMaster';
import { AgentMasterStructure } from 'src/app/shared/interface/agentV1';
import { EmitButtonData, UpdatePremiumData } from 'src/app/shared/interface/custom-footer';
import { HealthMasterService } from 'src/app/shared/proxy-services/health-master.service';
import { HealthDataStorageService } from '../../health.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { HealthService } from 'src/app/shared/proxy-services/health.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { AgentV1Service } from 'src/app/shared/proxy-services/agent-v1.service';
import MedicalTestGuidelinesData from 'src/assets/json/medical-test-guidelines.json';
import { PaymentService } from 'src/app/shared/proxy-services/payment.service';
import { LoaderService } from 'src/app/layout/loader/loader.service';
import { WrapperService } from 'src/app/shared/proxy-services/wrapper.service';
import { DatePipe } from '@angular/common';
import { CustomStepperService } from 'src/app/shared/services/custom-stepper.service';
import { CustomFooterService } from 'src/app/shared/services/custom-footer.service';
import { healthMembers, insuredDiseaseID, remarkList } from 'src/app/shared/enums/healthEnums';
import { Subscription } from 'rxjs/internal/Subscription';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { Constants } from 'src/app/shared/constants/constants';
import { HAPModal } from '../../products/hap/hap-modal';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { routeEnums } from 'src/app/shared/enums/routeEnums';
import { popupButton, popupButtonAction, popupDescriptionMsg, popupHeader, popupImgPath, popupType } from 'src/app/shared/enums/popupEnums';
import { Member, ZeroTatProposalRequestPayload } from 'src/app/shared/interface/zerotat';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { PaymodePaymentService } from 'src/app/shared/proxy-services/paymode-payment.service';
import { DeclarationsService } from 'src/app/shared/services/declarations.service';
import { ChannelData } from 'src/app/shared/interface/swapPrameters';
import { ConvertSavedQuotesResponsePayload, SavedQuote } from 'src/app/shared/interface/savedQuotes';
import { StepData } from 'src/app/shared/interface/custom-stepper';
import { PaymentStatusRequestPayload } from 'src/app/shared/interface/payment';
import { RenewalModal } from 'src/app/shared/components/sidemenu/renewals/renewal-modal';
import { logKYCData } from 'src/app/shared/interface/utilityApi';
import { UtilityApiService } from 'src/app/shared/proxy-services/utility-api.service';

@Component({
  selector: 'app-zero-tat-summary-details',
  templateUrl: './zero-tat-summary-details.component.html',
  styleUrls: ['./zero-tat-summary-details.component.scss'],
  providers: [DatePipe]
})
export class ZeroTatSummaryDetailsComponent implements OnDestroy {

  ZerotatSummary: FormGroup;
  healthFormData: HealthDataStorage;
  MemberDetailsData = [];
  adultMemberData = [{
    'applicantRelationship': '',
    'dob': '',
    'feetHeight': '',
    'fullName': '',
    'inchesHeight': '',
    'insuredSince': '',
    'pneumococcalDOB': '',
    'preExistinDisease': '',
    'previouslyInsured': '',
    'sumInsured': '',
    'title': '',
    'waiverPeriod': '',
    'weight': '',
    'coverName': '',
    'riskcategory': ''

  }]
  relationshipDetails: RelationshipResponsePayload;
  agentDetails: AgentMasterStructure;
  hapDataModal: HAPModal = new HAPModal();
  renewalDataModal: RenewalModal = new RenewalModal();
  medicaltest: string;
  isBMIValid: boolean;
  private subscription: Subscription[] = [];
  memberDetails = [];
  errorPopupData: PopupModal;
  invalid: boolean = false;
  insuredData: [];
  SIinsured: string = '';
  routeData: string = '';
  // saveHeathRes: SaveHealthPolicyResponsePayload;
  selectedCoverName: string;
  rnAgent: any;
  productType: number;
  channelData: ChannelData;
  quoteDetails: ConvertSavedQuotesResponsePayload;
  steps: StepData[] = [];
  saveButtonShow: boolean = true;
  productTypeData: string = '';

  constructor(private fb: FormBuilder,
    private healthMasterService: HealthMasterService,
    private healthDataStorageService: HealthDataStorageService,
    private storageService: StorageService,
    public utilitySerice: UtilityService,
    private utilityAPIService:UtilityApiService,
    private healthService: HealthService,
    public router: Router,
    private popupService: PopupService,
    private agentV1Service: AgentV1Service,
    public paymentService: PaymentService,
    private spinner: LoaderService,
    private wrapperService: WrapperService,
    private customStepperService: CustomStepperService,
    private customFooterService: CustomFooterService,
    private renderer: Renderer2,
    private datePipe: DatePipe,
    private utilityService: UtilityService,
    private paymode_payment_service: PaymodePaymentService,
    private declarationService: DeclarationsService
  ) {
  }

  ngOnInit() {
    this.popupService.closeAllDialog(true);
    this.quoteDetails = this.storageService.getAESDecryptedData(userEnums.ConverToProposal);
    this.productTypeData = this.storageService.getAESDecryptedData(userEnums.ProductType);
    this.routeData = (this.router.url).split('/')[2];
    setTimeout(() => {
      this.popupService.closeAllDialog(false);
    }, 0);
    window.scrollTo(0, 0);
    this.createForm();
    setTimeout(() => {
      this.insuredMemberDetails(this.MemberDetailsData);
      this.constructInsuredMembers();
      // this.manageRelationShip();
      this.MedicaltestGuideLines();
    }, 0);
    this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
    this.subscription.push(this.popupService.paymentValue$.subscribe(x => {
      this.proposalCreation(x);
    })
    );
    if (this.channelData.CIP == 'NYSAPRO' || this.channelData.CIP == 'NYSABANCA') {
      this.saveButtonShow = false;
    } else if (this.quoteDetails && this.quoteDetails.Data) {
      this.saveButtonShow = false;
      this.updateConvertSummaryData();
    } else {
      this.saveButtonShow = true;
    }
  }

  createForm() {
    this.ZerotatSummary = this.fb.group({
      medicalDeclaration1: [true, [Validators.required]],
      medicalDeclaration2: [true, [Validators.required]],
    })

    this.healthFormData = this.healthDataStorageService.getHealthDataStorage();

    //agent data storage
    this.agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
    this.rnAgent = this.agentDetails.AgentName;
    this.productType = this.healthFormData.quoteFormData.subProductType;

    this.MemberDetailsData.push(this.healthFormData.insuredFormData.insuredMemberDetails);
  }

  updateConvertSummaryData() {
    // this.subscription.push(this.windowResizeService.getResizeObservable().subscribe(data => {
    // }));
    this.steps = this.storageService.getAESDecryptedData(userEnums.StepperDetails);
    this.subscription.push(this.customStepperService.stepData$.subscribe(data => {
      this.customStepperService.activatedComponentCheck(data, this.steps);
    }));
    this.customFooterService.showPremiumDetails(true);
  }


  insuredMemberDetails(value: any) {
    value.forEach((item, index) => {
      for (const key in item) {
        if (key.includes('Adult' + (index + 1))) {
          this.adultMemberData.push(item);
        }
      }
    });
  }

  constructInsuredMembers() {
    if (this.healthFormData.quoteFormData.totalMemberData.length > 0) {
      let childCount = 0;
      let adultCount = 0;
      this.healthFormData.quoteFormData.totalMemberData.forEach((data, index) => {
        let adultDetails = this.healthFormData.quoteFormData.adultDetails[adultCount];
        let chidlDetails = this.healthFormData.quoteFormData.childDetails[childCount];
        if (adultDetails && (adultDetails['MemberType'] == healthMembers.Adult || data['MemberType'] == healthMembers.Adult)) {
          let adultMemberType = healthMembers.Adult + (adultCount + 1);
          let height = (this.healthFormData.insuredFormData.insuredMemberDetails[index]['feetInchesHeight' + adultMemberType])
          let feetHeight = height ? height.split('.')[0] : 0;
          let inchesHeight = height ? height.split('.')[1] : 0;
          let tempMemberObj = {
            'fullName': this.healthFormData.insuredFormData.insuredMemberDetails[index]['fullName' + adultMemberType],
            'Relationship': this.healthFormData.insuredFormData.insuredMemberDetails[index]['applicantRelationship' + adultMemberType],
            'dob': this.healthFormData.insuredFormData.insuredMemberDetails[index]['dob' + adultMemberType],
            'feetHeight': feetHeight,
            'inchesHeight': inchesHeight,
            'height': feetHeight + '.' + inchesHeight,
            'weight': this.healthFormData.insuredFormData.insuredMemberDetails[index]['weight' + adultMemberType],
            'preExistingDisease': this.healthFormData.insuredFormData.insuredMemberDetails[index]['preExistinDisease' + adultMemberType],
            'pneumoccalDOB': !this.utilitySerice.isEmptyOrNull(data['VaccinationDate']) ? data['VaccinationDate'] : '',
            'memberTypeLabel': adultMemberType,
            'memberType': healthMembers.Adult,
            'coverName': this.healthFormData.quoteFormData.subProductType === 23 ? this.healthFormData.quoteFormData.quoteSummary[index]['CoverDetails'] : [],
            "riskcategory": this.healthFormData.quoteFormData.subProductType === 23 ? this.healthFormData.quoteFormData.quoteSummary[index]['RiskCategory'] : '',
            "totalsumOfCoverSI": this.healthFormData.quoteFormData.subProductType === 23 ? this.healthFormData.quoteFormData.quoteSummary[index]['CoverDetails'].reduce((accumulator, cover) => accumulator + cover.CoverSI, 0) : ''
          }
          adultCount += 1;
          this.memberDetails.push(tempMemberObj);
        } else if (chidlDetails && (chidlDetails['MemberType'] == healthMembers.Child || data['MemberType'] == healthMembers.Child)) {
          let childMemberType = healthMembers.Child + (childCount + 1);
          // let feetHeight = ((this.healthFormData.insuredFormData.insuredMemberDetails[index]['feetInchesHeight' + childMemberType]).split('.')[0]);
          // let inchesHeight = ((this.healthFormData.insuredFormData.insuredMemberDetails[index]['feetInchesHeight' + childMemberType]).split('.')[1]);
          let childheight = (this.healthFormData.insuredFormData.insuredMemberDetails[index]['feetInchesHeight' + childMemberType])
          let childfeetHeight = childheight ? childheight.split('.')[0] : 0;
          let childinchesHeight = childheight ? childheight.split('.')[1] : 0;
          let tempMemberObj = {
            'fullName': this.healthFormData.insuredFormData.insuredMemberDetails[index]['fullName' + childMemberType],
            'Relationship': this.healthFormData.insuredFormData.insuredMemberDetails[index]['applicantRelationship' + childMemberType],
            'dob': this.healthFormData.insuredFormData.insuredMemberDetails[index]['dob' + childMemberType],
            'height': childfeetHeight + '.' + childinchesHeight,
            'feetHeight': childfeetHeight,
            'inchesHeight': childinchesHeight,
            'weight': this.healthFormData.insuredFormData.insuredMemberDetails[index]['weight' + childMemberType],
            'preExistingDisease': this.healthFormData.insuredFormData.insuredMemberDetails[index]['preExistinDisease' + childMemberType],
            'pneumoccalDOB': '',
            'memberTypeLabel': childMemberType,
            'memberType': healthMembers.Child,
            'coverName': this.healthFormData.insuredFormData.insuredMemberDetails[index]['selectedCoverName' + childMemberType],
            "riskcategory": this.healthFormData.quoteFormData.subProductType === 23 ? this.healthFormData.quoteFormData.quoteSummary[index]['RiskCategory'] : '',
          }

          childCount = childCount + 1;
          this.memberDetails.push(tempMemberObj);
        }
      })
    }
  }
  // manageRelationShip() {
  //   let product: RelationshipRequestPayload = {
  //     product: this.healthFormData.quoteFormData.healthProposalProductType,
  //   }
  //   this.subscription.push(this.healthMasterService.getHealthProposalRelationships(product).subscribe(response => {
  //     if (response.StatusCode == 1 && response.StatusMsg == "Success") {
  //       this.relationshipDetails = response;
  //     }
  //   }))
  // }

  updatePremiumData(premium: string) {
    let premiumData: UpdatePremiumData = {
      update: false,
      data: '',
      value: 'summary'
    };
    premiumData.update = true;
    premiumData.data = premium;
    this.customFooterService.updatePremiumDetails(premiumData);
  }


  calculateAge(dob: string) {
    const today = new Date();
    const birthDate = new Date(dob);
    const timeDiff = today.getTime() - birthDate.getTime();
    const ageInMilliseconds = timeDiff;

    // Calculate the age in years
    const ageInYears = Math.floor(ageInMilliseconds / (1000 * 3600 * 24 * 365.25));

    return ageInYears;
  }

  setProposalData(data) {
    this.healthFormData.policyDetails.BasicPremium = data.Data.TotalBasicPremiumOfMembers;
    this.healthFormData.policyDetails.CustomerId = data.Data.CustomerID;
    // this.healthFormData.policyDetails.EPolicyID = data.Data.EPolicyID;
    this.healthFormData.policyDetails.PfCustomerId = data.Data.PFCustomerId;
    this.healthFormData.policyDetails.PfProposalNumber = data.Data.MembersDetails[0].PFProposalNumber;
    // this.healthFormData.policyDetails.PfQuoteId = data.Data.PfQuoteId;
    // this.healthFormData.policyDetails.PolicyEndDate = data.Data.PolicyEndDate;
    // this.healthFormData.policyDetails.PolicyID = data.Data.PolicyIds;
    // if(data.Data.PolicyIds.includes(':'))
    // {
    //   this.healthFormData.policyDetails.PolicyID = data.Data.PolicyIds.split(':')[0]; // data.Data.PolicyIds;
    // }
    // else
    // {
    this.healthFormData.policyDetails.PolicyID = data.Data.PolicyIds;
    // }
    // this.healthFormData.policyDetails.PolicyStartDate = data.Data.PolicyStartDate;
    // this.healthFormData.policyDetails.PolicyStatus = data.Data.PolicyStatus;
    // this.healthFormData.policyDetails.PolicySubType = data.Data.PolicySubType;
    this.healthFormData.policyDetails.ProposalNumber = data.Data.MembersDetails[0].PFProposalNumber;
    // this.healthFormData.policyDetails.QuoteId = data.Data.QuoteId;
    // this.healthFormData.policyDetails.Tenure = data.Data.Tenure;
    this.healthFormData.policyDetails.TotalPremium = data.Data.TotalPremiumOfMembers;
    this.healthFormData.policyDetails.TotalTax = data.Data.TotalTaxOfMembers;
    // this.healthFormData.policyDetails.PolicyID = data.Data.PolicyID;
    // setting transaction details needed in payment confirmation
    this.healthFormData.paymentConfirmationDetails.PfProposalNumber = data.Data.MembersDetails.PFProposalNumber;
    this.healthFormData.paymentConfirmationDetails.productType = this.healthFormData.quoteFormData.productType;
    //email id  && moblie no 
    this.healthDataStorageService.setHealthDataStorage(this.healthFormData);
  }

  paymentModeSelected(buttonData: EmitButtonData) {
    switch (buttonData['label']) {
      case 'Payment Link': {
        this.proposalCreation('PaymentLink');
        break;
      }
      case 'Pay Now': {
        this.proposalCreation('PayNow');
        break;
      }
      case 'Pay Later': {
        this.proposalCreation('PayLater');
        break;
      }
      default: {
        break;
      }
    }
  }

  kycDataDetails() {
    let obj: logKYCData = {
      "ckyc_no": this.healthFormData.kycFormData.CKYCID,
      "id_type": this.healthFormData.kycFormData.certificateType,
      "ekyc_no": this.healthFormData.kycFormData.EKYCID,
      "ilreference_no": this.healthFormData.kycFormData.ilKycReferenceNumber,
      "customer_name": this.healthFormData.kycFormData.applicantDetails.applicantFullName,
      "cust_dob": this.healthFormData.kycFormData.applicantDetails.applicantDOB,
      "cust_emailid": this.healthFormData.kycFormData.permanentAddress.permanentEmail,
      "Mobile_no": this.healthFormData.kycFormData.permanentAddress.permanentMobileNumber,
      "response": "",
      "correlation_id": this.healthFormData.kycFormData.kycCorrelationId,
      "action": "Summary"
    }
    return obj;
  }

  proposalCreation(payMode) {
    let kycData = this.kycDataDetails();
    this.subscription.push(this.utilityAPIService.logDataDetails(kycData).subscribe({
      next: (res) => {
        if (res.StatusCode == Constants.statusMsg_SuccessCode) {
          console.log('log succesfully done');
        }
      }
    }));
    if (this.ZerotatSummary.controls['medicalDeclaration1'].value && this.ZerotatSummary.controls['medicalDeclaration2'].value) {

      this.commonProposal(payMode);
    }
    else {
      this.invalid = true;
    }
  }

  commonProposal(payMode) {
    let request: ZeroTatProposalRequestPayload = this.constructProposalRequest(this.healthFormData, payMode);
    let subscribtion = this.healthService.ZeroTatProposal(request).subscribe({
      next: proposalResponse => {
        if (proposalResponse.StatusCode == Constants.statusCode_success) {
          this.setProposalData(proposalResponse);
          subscribtion.unsubscribe();
          let req = {
            totalPremium: proposalResponse.Data.TotalBasicPremiumOfMembers,
            pfProposalNUmber: proposalResponse.Data.MembersDetails[0].PFProposalNumber,
            policyIds: proposalResponse.Data.PolicyIds
          }
          this.paymentLink(payMode, req);
        }
      },
      error: error => {
        // Handle the error here
      }
    })
  }


  //common payment
  paymentLink(payMode: string, req) {
    let data: PaymentStatusRequestPayload = {
      paymode: payMode,
      befitPlan: false,
      homePlan: false,
      zeroTatProduct: true,
      proposalPolicyID: req.policyIds,
      bundalPolicyID: '',
      totalPremium: req.totalPremium,
      proposalNumber: req.pfProposalNUmber,
      bundleProposalNumber: '',
    }
    if (payMode == 'PayNow') {
      //common payment service paynow
      this.paymode_payment_service.CreateBundlePayment(data);
    }
    else {
      //common payment service paylater/payment link
      this.paymode_payment_service.commonPayLaterRequest(data);
    }
  }

  fetchDetailsAsPaymentTransactionId(paymentTransactionId: string) {
    this.healthFormData.paymentConfirmationDetails.PfTransactionId = paymentTransactionId;
    this.healthDataStorageService.setHealthDataStorage(this.healthFormData);
    localStorage.clear();
    if (this.healthFormData.portabilityDataSet) {
      // this.router.navigate([routeEnums.DocumentUpload]);
      this.utilityService.redirectionToUploadDocument();
    }
    else {
      this.router.navigate(['.', routeEnums.PaymentConfirmation]);
    }
  }
  constructPaymentLinkRequest() {
    let request;
    request = {
      EmailID: this.healthFormData.kycFormData.permanentAddress.permanentEmail,
      PolicyID: this.healthFormData.policyDetails.PolicyID.toString(),
      RequestType: "2",
      MobileNo: this.healthFormData.kycFormData.permanentAddress.permanentMobileNumber,
    }
    return request;
  }

  constructCommonPaymentRequest() {
    let request;
    request = {
      "TransType": "POLICY_PAYMENT",
      "GatewayReturnURL": "",
      "PolicyIDs": this.healthFormData.policyDetails.PolicyID.toString(),
      "PayerType": "Customer",
      "ModeID": 0,
      "UserRole": this.utilitySerice.isUndefinedORNull(this.channelData.UT) ? "AGENT" : this.channelData.UT,
      "IPAddress": this.utilitySerice.isUndefinedORNull(this.channelData.CIP) ? this.hapDataModal.ipAddress : this.channelData.CIP,
      "PaymentMode": "None",
      "PaymentAmount": 0
    }

    return request;
  }

  constructPayNowRequest() {
    let request;
    request = {
      PolicyID: this.healthFormData.policyDetails.PolicyID.toString(),
      UserType: 'Agent',
      CorrelationID: null
      // }
    }
    return request;
  }

  constructProposalRequest(saveEditResponse: HealthDataStorage, PayMode) {
    let request: any = {
      ProductCode: this.healthFormData.quoteFormData.productCode,
      PolicyTenure: Number(this.healthFormData.premiumFormData.tenure),
      PayMode: PayMode.toLowerCase(),
      NomineeName: this.healthFormData.kycFormData.nomineeDetails.nomineeFullName,
      NomineeRelationship: this.healthFormData.kycFormData.nomineeDetails.nomineeRelationship,
      NomineeDOB: this.utilityService.formatDateWithDash(this.healthFormData.kycFormData.nomineeDetails.nomineeDOB),
      AppointeeName: this.healthFormData.kycFormData.appointeeDetails.appointeeFullName,
      AppointeeRelationship: this.healthFormData.kycFormData.appointeeDetails.appointeeRelationship,
      AppointeeDOB: this.healthFormData.kycFormData.appointeeDetails.appointeeDOB,
      CustomerDetails: {
        "CustomerType": this.healthFormData.kycFormData.customerType,
        "CustomerName": this.healthFormData.kycFormData.applicantDetails.applicantFullName,
        "DateOfBirth": this.utilityService.formatDateWithDash(this.healthFormData.kycFormData.applicantDetails.applicantDOB),
        "PinCode": this.healthFormData.kycFormData.correspondenceAddress.correspondencePincode,
        "PANCardNo": this.healthFormData.kycFormData.applicantDetails.applicantPanCardNumber == "" ? null : this.healthFormData.kycFormData.applicantDetails.applicantPanCardNumber,
        "Email": this.healthFormData.kycFormData.permanentAddress.permanentEmail,
        "MobileNumber": this.healthFormData.kycFormData.permanentAddress.permanentMobileNumber,
        "AddressLine1": this.healthFormData.kycFormData.correspondenceAddress.correspondenceAddress1,
        "CountryCode": 100,
        "StateCode": +this.healthFormData.quoteFormData.stateId,
        "CityCode": +this.healthFormData.quoteFormData.cityId,
        "Gender": this.utilitySerice.titleBasedGender(this.healthFormData.kycFormData.applicantDetails.applicantTitle, this.routeData),
        "CustomerID": Number(this.healthFormData.kycFormData.PFCustomerID),
        // "GSTDetails":null,
        "GSTDetails": this.healthFormData.kycFormData.otherDetails.business != "" ? {
          "GSTExemptionApplicable": this.healthFormData.kycFormData.otherDetails.gstApplicable,
          "GSTInNumber": this.healthFormData.kycFormData.otherDetails.gstin,
          "GSTToState": this.healthFormData.kycFormData.KYCStateId,
          // "ConstitutionOfBusiness": this.healthFormData.kycFormData.otherDetails.business,
          // "CustomerType": this.healthFormData.kycFormData.otherDetails.otherCustomerType,
          // "PanDetails": this.healthFormData.kycFormData.otherDetails.panNumber,
          // "GSTRegistrationStatus": this.healthFormData.kycFormData.otherDetails.gstStatus,
        } : null,
        "IpartnerCustomerID": 0,
        "CKYCID": this.healthFormData.kycFormData.CKYCID,
        "EKYCid": this.healthFormData.kycFormData.EKYCID,

        "ilkycReferenceNumber": this.healthFormData.kycFormData.ilKycReferenceNumber,
        "SkipDedupeLogic": "true",
      },
      InsuredDataDetails: this.constructmemberDetails(),
      UserType: this.utilitySerice.isEmptyOrNull(this.channelData.UT) ? "Agent" : this.channelData.UT,
      IpAddress: this.utilitySerice.isEmptyOrNull(this.channelData.CIP) ? this.hapDataModal.ipAddress : this.channelData.CIP,
      CorrelationId: this.healthFormData.quoteFormData.correlationId,//this.utilitySerice.createCorrelationId(),  
      RiskStartDate: this.healthFormData.quoteFormData.subProductType === 23 ? this.utilityService.formatDateWithDash_dmy(this.healthFormData.kycFormData.otherDetails.riskStartDate) : '',//"19-jan-2024",
      PlanCode: this.healthFormData.quoteFormData.PlanCode,
      DigitalPOSUserMobileNo: "",
      isRenewal: false,
      RefNo: "",
      prevPolicyNo: "",
      isProposalModified: false,
      BancaToken: this.utilitySerice.isEmptyOrNull(this.channelData.CIP) ? '' : this.channelData.CIP == 'NYSABANCA' ? this.channelData.BT : '',
      SaveQuoteID: this.quoteDetails ? this.quoteDetails.Data.GenericData.QuoteID : 0
    }
    return request;
  }

  constructmemberDetails(): any[] {
    let membersData = [];
    let fieldgrid = this.getFieldsGridData()

    let adultCount = 0;
    let childCount = 0;
    if (this.healthFormData.quoteFormData.totalMemberData.length > 0) {
      let riskcategory: any;
      let memberno: any;
      this.healthFormData.quoteFormData.totalMemberData.forEach((members, index) => {
        let selectedAddon: any[] = [];
        if (this.healthFormData.quoteFormData.subProductType == 23) {
          this.healthFormData.quoteFormData.quoteSummary[index]['CoverDetails'].forEach(element => {
            let coverdetail: any = {
              column1: "",
              column2: null,
              CoverDescription: element.CoverName,
              SumInsured: element.CoverSI
            }
            selectedAddon.push(coverdetail);
          });
        } else {
          {
            this.healthFormData.quoteFormData.quoteSummary[index]['CoverDetails']['base'].forEach(element => {
              let coverdetail: any = {
                column1: "",
                column2: null,
                CoverDescription: element.baseCoverName,
                SumInsured: element.baseCoverInputValue
              }
              selectedAddon.push(coverdetail);
            });
            this.healthFormData.quoteFormData.quoteSummary[index]['CoverDetails']['addon'].forEach(element => {
              if (element.addonCoverSelected === true) {
                let coverdetail: any = {
                  column1: "",
                  column2: null,
                  CoverDescription: element.addonCoverName,
                  SumInsured: element.addonCoverInputValue
                }
                selectedAddon.push(coverdetail);
              }
            });
          }
        }
        let OccupationType: any;
        riskcategory = this.healthFormData.quoteFormData.quoteSummary[index]['RiskCategory'];
        memberno = this.healthFormData.quoteFormData.quoteSummary[index]['MemberNo'];

        OccupationType = this.healthFormData.quoteFormData.quoteSummary[index]['OccupationType']
        let IncomeSlab: string = members['IncomeSlab']
        let memberType: string = members['MemberType'];
        let insuredDetailsObject = this.healthFormData.insuredFormData.insuredMemberDetails[index];
        let memberTypeCount: string = '';
        if (memberType == healthMembers.Adult) {
          adultCount++;
          memberTypeCount = '';
          memberTypeCount = memberType + adultCount;

        }

        else if (memberType == healthMembers.Child) {
          childCount++;
          memberTypeCount = '';
          memberTypeCount = memberType + childCount;
        }
        let height = (this.healthFormData.insuredFormData.insuredMemberDetails[index]['feetInchesHeight' + memberTypeCount])
        let feetHeight = height ? height.split('.')[0] : 0;
        let inchesHeight = height ? height.split('.')[1] : 0;
        let c1 = (insuredDetailsObject['previouslyInsured' + memberTypeCount] == 'Yes' ? (insuredDetailsObject['sumInsured' + memberTypeCount]) : '1');
        let propDOJ = "";
        if (this.healthFormData.quoteFormData.subProductType == 40) {
          propDOJ = insuredDetailsObject['previouslyInsured' + memberTypeCount] == 'Yes' ? this.utilityService.formatDate(this.healthFormData.portabilityFormData.policyStartDate) : this.utilityService.formatDate(new Date());
        }
        else {
          propDOJ = "";
        }
        let count = 1;
        let gender;
        if (insuredDetailsObject['applicantRelationshipAdult' + (index + 1)] != undefined) {
          // gender = this.healthFormData.insuredFormData.insuredMemberDetails[index]['titleAdult' + (index + 1)] == 'Ms.' || this.healthFormData.insuredFormData.insuredMemberDetails[index]['titleAdult' + (index + 1)] == 'Mrs.' || this.healthFormData.insuredFormData.insuredMemberDetails[index]['titleAdult' + (index + 1)] == 'Mx.' ? 'FEMALE' : 'MALE';
          gender = this.utilitySerice.titleBasedGender(this.healthFormData.insuredFormData.insuredMemberDetails[index]['titleAdult' + (index + 1)], this.routeData);
        } else {
          gender = this.utilitySerice.titleBasedGender(this.healthFormData.insuredFormData.insuredMemberDetails[index]['titleChild' + (count)], this.routeData);
          // gender = this.healthFormData.insuredFormData.insuredMemberDetails[index]['titleChild' + (count)] == 'Ms.' || this.healthFormData.insuredFormData.insuredMemberDetails[index]['titleChild' + (count)] == 'Mrs.' || this.healthFormData.insuredFormData.insuredMemberDetails[index]['titleChild' + (count)] == 'Mx.' ? 'FEMALE' : 'MALE';
          count++
        }
        membersData.push({
          "riskcategory": riskcategory,
          "PlanCode": this.healthFormData.quoteFormData.PlanCode,
          "TotalSi": this.healthFormData.premiumFormData.InstallmentNetPremium,
          "MemberNo": memberno,
          "InsuredName": insuredDetailsObject['applicantRelationshipAdult' + (index + 1)] === "SELF" ? this.healthFormData.kycFormData.applicantDetails.applicantFullName : insuredDetailsObject['fullName' + memberTypeCount],
          "InsuredDOB": insuredDetailsObject['applicantRelationshipAdult' + (index + 1)] === "SELF" ? this.utilityService.formatDateWithDash(this.healthFormData.kycFormData.applicantDetails.applicantDOB) : this.utilityService.formatDateWithDash(insuredDetailsObject['dob' + memberTypeCount]),
          "InsuredAge": '',
          "InsuredGender": gender,
          // "InsuredRelation": members['applicantRelationship'+memberTypeCount],
          "InsuredRelation": insuredDetailsObject['applicantRelationship' + memberTypeCount],
          "PreIllness": this.getPreIllness(this.healthFormData.insuredFormData.totalData[index], index),
          "InsuredPAN": '',
          "InsuredAadhar": "",
          "InsuredOccupation": "",
          "InsuredEmailID": "",
          "InsuredPassportNo": "",
          "InsuredMobileNo": "",
          "FieldsGrid": this.getFieldsGridData()[index],
          "CoverDetails": selectedAddon,
          "IncomeSlab": this.healthFormData.quoteFormData.quoteSummary[index]['adultIncome1'],
          "OccupationType": this.healthFormData.quoteFormData.quoteSummary[index]['adultOccupation1'],
          "InsuredWeight": insuredDetailsObject['weight' + memberTypeCount],
          "InsuredHeightInFeet": feetHeight,
          "InsuredHeightInInches": inchesHeight
        })
        this.SIinsured = (insuredDetailsObject['sumInsured' + memberTypeCount]);
      })
    }
    return membersData;
  }
  getFieldsGridData() {
    let fieldgrid: any = [];
    let adultCount = 0;
    let childCount = 0;
    // let childCount = 0;
    let formgrid = [];
    if (this.healthFormData.quoteFormData.totalMemberData.length > 0) {
      this.healthFormData.quoteFormData.totalMemberData.forEach((Data, index) => {
        fieldgrid = []
// this.healthFormData.insuredFormData.insuredMemberDetails[index]['titleAdult' + (index + 1)] == 'Ms.' || this.healthFormData.insuredFormData.insuredMemberDetails[index]['titleAdult' + (index + 1)] == 'Mrs.' || this.healthFormData.insuredFormData.insuredMemberDetails[index]['titleAdult' + (index + 1)] == 'Mx.' ? 'FEMALE' : 'MALE'
        let adultDetails = this.healthFormData.quoteFormData.adultDetails[adultCount];
        let childDetails = this.healthFormData.quoteFormData.childDetails[childCount];
        if (adultDetails && adultDetails['MemberType'] == healthMembers.Adult) {
          let adultMemberType = healthMembers.Adult + (adultCount + 1);
          fieldgrid.push({ "FieldName": "InsuredName", "FieldValue": this.healthFormData.insuredFormData.insuredMemberDetails[index]['fullName' + adultMemberType] },
            { "FieldName": "RelationshipwithApplicant", "FieldValue": this.healthFormData.insuredFormData.insuredMemberDetails[index]['applicantRelationship' + adultMemberType] },
            { "FieldName": "InsuredDateofBirth", "FieldValue": this.utilityService.formatDateWithDash(this.healthFormData.insuredFormData.insuredMemberDetails[index]['dob' + adultMemberType]) },
            { "FieldName": "Gender", "FieldValue": this.utilitySerice.titleBasedGender(this.healthFormData.insuredFormData.insuredMemberDetails[index]['titleAdult' + (index + 1)], this.routeData) },
            { "FieldName": "PEDillness", "FieldValue": this.healthFormData.insuredFormData.insuredMemberDetails[index]['preExistinDisease' + adultMemberType] },
          )
          adultCount = 1;
        } else if (childDetails && childDetails['MemberType'] == healthMembers.Child) {
          let childMemberType = healthMembers.Child + (childCount + 1);
          fieldgrid.push({ "FieldName": "InsuredName", "FieldValue": this.healthFormData.insuredFormData.insuredMemberDetails[index]['fullName' + childMemberType] },
            { "FieldName": "RelationshipwithApplicant", "FieldValue": this.healthFormData.insuredFormData.insuredMemberDetails[index]['applicantRelationship' + childMemberType] },
            { "FieldName": "InsuredDateofBirth", "FieldValue": this.utilityService.formatDateWithDash(this.healthFormData.insuredFormData.insuredMemberDetails[index]['dob' + childMemberType]) },
            { "FieldName": "Gender", "FieldValue": this.utilitySerice.titleBasedGender(this.healthFormData.insuredFormData.insuredMemberDetails[index]['titleAdult' + (index + 1)], this.routeData) },
            { "FieldName": "PEDillness", "FieldValue": this.healthFormData.insuredFormData.insuredMemberDetails[index]['preExistinDisease' + childMemberType] },
          )
          childCount += 1;
        }
        fieldgrid.push(
          { "FieldName": "NomineeName", "FieldValue": this.healthFormData.kycFormData.nomineeDetails.nomineeFullName },
          { "FieldName": "NomineeRelationshipwithInsured", "FieldValue": this.healthFormData.kycFormData.nomineeDetails.nomineeRelationship },
          { "FieldName": "NomineeDateofBirth", "FieldValue": this.utilityService.formatDateWithDash(this.healthFormData.kycFormData.nomineeDetails.nomineeDOB) },
        )
        formgrid.push(fieldgrid)
      })
    }



    return formgrid;

  }
  getPreIllness(member, index?) {
    //append to array then join
    if (!this.utilitySerice.isEmptyOrNull(member)) {
      let selectIllnesses = member.existingDisease.filter(ele => ele.showRemarks === true && ele.Name)
      let additionalAddon = selectIllnesses.map(ele => ele.type == 'text')

      selectIllnesses = selectIllnesses.map(ele => ele.Name + '*')
      additionalAddon = selectIllnesses.map(ele => ele.showRemarks)
      // selectIllnesses = selectIllnesses.map(ele => ele.diseaseName +"$"+ele.additionalDiseaseVal)
      selectIllnesses = selectIllnesses.join("|")
      if (additionalAddon[0] === true)
        selectIllnesses = selectIllnesses + "|Do you have any other Critical Illness policy (Group Secure Mind/Secure Mind/Critical Illness/other health policy) with ICICI Lombard GIC Ltd?"
      if (additionalAddon[1] === true)
        selectIllnesses = selectIllnesses + "|Do you have any other current or pending Critical Illness Insurance / Proposal with another Company?"
      return selectIllnesses;
    }
  }
  getAilments(memberCount: string) {
    let ailmentList: any = [];
    if (this.healthFormData.insuredFormData.preExistingDisease.length > 0 && this.healthFormData.insuredFormData.existingDisease.length > 0) {
      this.healthFormData.insuredFormData.existingDisease.forEach(disease => {
        this.healthFormData.insuredFormData.preExistingDisease.forEach(preExistingControls => {
          if (preExistingControls[disease.Value + memberCount]) {
            ailmentList.push({
              "AilmentID": 0,  // by default
              "Month": "1", // by default
              "Year": "1", // by default
              "AilmentName": disease.Name,
              "PEDCode": (disease.Value).toString(),
              "PEDRemarks": (memberCount.includes(healthMembers.Adult) && disease.Value == insuredDiseaseID.Fourty || disease.Value == insuredDiseaseID.FourtyOne) ? this.findRemark(preExistingControls, memberCount, disease.Value) : ''
            });
          }
        })
      })
    }
    return ailmentList;
  }

  isPedSelected() {
    let memberData = this.healthFormData.insuredFormData;
    let countPed = 0;
    memberData.preExistingDisease.forEach(element => {
      if (!this.utilitySerice.isEmptyOrNull(element)) {
        countPed += element ? 1 : countPed;
      }
    });
    return countPed > 0 ? true : false;
  }

  isUnderwritterApproval() {
    let uwSI = this.utilitySerice.isEmptyOrNull(this.healthFormData.quoteFormData.applicantAnnualSum);
    if (uwSI) {
      return ((this.isBMIValid) && !this.isPedSelected());
    } else if ((parseInt(this.healthFormData.quoteFormData.applicantAnnualSum) > 2000000)) {
      return (!this.isPedSelected());
    }
    else {
      return ((this.isBMIValid) && !this.isPedSelected());
    }
  }

  findRemark(preExistingControls: {}, memberCount: string, id: number): string {
    let remarks = '';
    if (id == insuredDiseaseID.Fourty || id == insuredDiseaseID.FourtyOne) {
      for (let keys in preExistingControls) {
        if (keys == `${remarkList.Remark1 + memberCount}` || keys == `${remarkList.Remark2 + memberCount}`) {
          remarks = (id == insuredDiseaseID.Fourty) ? preExistingControls[remarkList.Remark1 + memberCount] : preExistingControls[remarkList.Remark2 + memberCount]
          return remarks;
        }
      }
    }
    return remarks;
  }

  requestDataFromMultipleSources() {
    this.subscription.push(this.agentV1Service.agentAppMasterData().subscribe({
      next: (agentAppResponse) => {
        if (agentAppResponse.StatusCode == Constants.statusCode_success) {

        }
        if (agentAppResponse.StatusCode != Constants.statusCode_success) {
          this.errorPopupData = this.popupService.fetchErrorPopupMsg(agentAppResponse.StatusDesc);
          this.popupService.openGenericPopup(this.errorPopupData);
        }
      },
      error: err => {
      }
    }))
  }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  navigateToPage(value: string) {
    this.customStepperService.redirectToStep(value, this.healthFormData.portabilityDataSet);
  }

  //to fecth medical test guideline as per the product

  MedicaltestGuideLines() {
    let subProductType;
    if (this.quoteDetails && this.quoteDetails.Data) {
      subProductType = this.quoteDetails.Data.SubProductType;
    } else {
      subProductType = this.healthFormData.quoteFormData.subProductType;
    }
    if (this.healthFormData.quoteFormData.subProductType == 38) {
      let data = MedicalTestGuidelinesData.MedicalGuidelines;
      data.forEach(element => {
        if (element.subProductType == subProductType) {
          this.medicaltest = element.Data;
        }
      });
    }
  }
  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  ngOnDestroy() {
    this.healthFormData = undefined;
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  openDeclarationPopup(declarationType: string) {
    let data = {
      rnAgent: this.rnAgent,
      productType: this.productType
    }

    this.declarationService.openDeclarationPopup(declarationType, data);
  }

  // ipaa-176: Save button to saved quotes
  saveQuotesButtonClick() {
    this.savedProposal();
  }

  savedProposal() {
    let request = this.constructSavedQuotesRequest();
    this.subscription.push(this.healthService.savedQuote(request).subscribe({
      next: response => {
        if (response.StatusCode == Constants.statusCode_success) {
          setTimeout(() => {
            let popupData: PopupModal = {
              popupType: popupType.generic,
              imgName: popupImgPath.successGif,
              header: popupHeader.quoteSuccess,
              description: `${popupDescriptionMsg.savedQuotesSuccessMessage} ${response.Data.QuoteId}`,
              buttonLabel: popupButton.ok,
              buttonAction: popupButtonAction.dashboard
            }
            this.popupService.openGenericPopup(popupData);
          }, 0);
        }
      },
      error: error => {
      }
    }))
  }

  constructSavedQuotesRequest() {
    const alteredDate = new Date(this.healthFormData.kycFormData.applicantDetails.applicantDOB)
    const formattedDate = this.datePipe.transform(alteredDate, 'yyyy-MM-ddTHH:mm:ss');
    let savedQuotesAdultDetails = [];
    let savedQuotesChildDetails = [];
    let adultDetails = this.healthFormData.quoteFormData.adultDetails;
    if (adultDetails) {
      savedQuotesAdultDetails = adultDetails.map((member, i) => ({
        memberCount: i + 1,
        dob: this.utilitySerice.formatDate(member[`dobAdult${(i + 1)}`]),
        relationship: member[`adultRealtionship${(i + 1)}`],
        vaccineDate: this.utilityService.formatDate(member[`adultVaccinationDate${(i + 1)}`]),
        vaccineStatus: member[`adultVaccinationStatus${(i + 1)}`]
      }));
    }
    let childDetails = this.healthFormData.quoteFormData.childDetails;
    if (childDetails) {
      savedQuotesChildDetails = childDetails.map((member, i) => ({
        memberCount: i + 1,
        dob: this.utilitySerice.formatDate(member[`dobChild${(i + 1)}`]),
        relationship: member[`childRelationship${(i + 1)}`]
      }));
    }

    let genericData = {
      pfQuoteId: this.healthFormData.premiumFormData.PfQuoteId,
      dealID: this.healthFormData.policyDetails.DealId,
      cityId: this.healthFormData.quoteFormData.cityId,
      stateId: this.healthFormData.quoteFormData.stateId,
      kycAppointee: this.healthFormData.showAppointee,
      kycStateId: this.healthFormData.kycFormData.KYCStateId,
      befitPremium: 0,
      homePremium: 0,
      productCode: this.healthFormData.quoteFormData.productCode,
      productType: this.productTypeData,
      insuredPED: this.healthFormData.insuredFormData.preExistingDisease,
      kycApplicantTitleID: this.healthFormData.kycFormData.applicantDetails.applicantTitle
    };
    this.healthFormData.quoteFormData.SumInsured = parseInt(this.healthFormData.quoteFormData.applicantAnnualSum);
    let saveProposalRequest: SavedQuote = {
      "UserType": "AGENT",
      "IpAddress": "IPARTNER-Msite",
      "ProductType": this.healthFormData.quoteFormData.productType ? this.healthFormData.quoteFormData.productType : null,
      "SubProductType": this.healthFormData.quoteFormData.subProductType,
      "SubProductCode": this.healthFormData.quoteFormData.subProductCode,
      "AdultDetails": savedQuotesAdultDetails,
      "AdultRelationShips": [],
      "ChildRelationShips": [],
      "ChildDetails": savedQuotesChildDetails,
      "City": '',
      "PolicyRange": this.healthFormData.quoteFormData.policyRange,
      "CityState": this.healthFormData.quoteFormData.cityState,
      "Quotesummary": this.healthFormData.quoteFormData.quoteSummary,
      "isIbankRelationship": false,
      "BankRelationship": {
        "IsIbankRelationship": false,
        "ApsId": "",
        "CustomerRefNo": ""
      },
      "Members": this.constructmemberDetails(),
      // "NomineeTitleID": this.healthFormData.kycFormData.nomineeDetails.NomineeTitleID,
      "NomineeDOB": this.utilitySerice.formatDate(this.healthFormData.kycFormData.nomineeDetails.nomineeDOB),
      "NomineeName": this.healthFormData.kycFormData.nomineeDetails.nomineeFullName,
      "NomineeRelationShipID": this.healthFormData.kycFormData.nomineeDetails.NomineeRelationshipID ? this.healthFormData.kycFormData.nomineeDetails.NomineeRelationshipID : 0,
      "NomineeRelationShipText": this.healthFormData.kycFormData.nomineeDetails.nomineeRelationship,
      "HUF": "N",
      "isAppointeeRequired": this.healthFormData.showAppointee,
      "AppointeeName": this.healthFormData.kycFormData.appointeeDetails.appointeeFullName ?? '',
      "AppointeeTitleID": this.healthFormData.kycFormData.appointeeDetails.appointeeTitleID ?? 0,
      "AppointeeRelationShipID": this.healthFormData.kycFormData.appointeeDetails.appointeeRelationShipID ?? 0,
      "AppointeeRelationShip": this.healthFormData.kycFormData.appointeeDetails.appointeeRelationship ?? '',
      "AppointeeDOB": this.healthFormData.kycFormData.appointeeDetails.appointeeDOB ? this.utilitySerice.formatDate(this.healthFormData.kycFormData.appointeeDetails.appointeeDOB) : '',
      "CustomerID": this.utilitySerice.isUndefinedORNull(this.healthFormData.kycFormData.CustomerID) ? 0 : this.healthFormData.kycFormData.CustomerID,
      "PfCustomerId": this.healthFormData.kycFormData.PFCustomerID,
      "PortabilityTypeOfPolicy": this.healthFormData.portabilityFormData.typeOfPolicy,
      "PortabilitySumInsured": this.healthFormData.portabilityFormData.portabilitySumInsured,
      "PreviousPolicies": this.healthFormData.portabilityFormData.previousPolicyClaims ? this.healthFormData.portabilityFormData.previousPolicyClaims : null,
      "PortabilityWaiver": this.healthFormData.portabilityFormData.typeOfPolicy,
      "PortabilityDOJ": this.healthFormData.portabilityFormData.policyStartDate,
      "PortabilitySI": this.healthFormData.portabilityFormData.portabilitySumInsured,
      "PreviousPoliciesClaims": this.healthFormData.portabilityFormData.previousPolicyClaims ? this.healthFormData.portabilityFormData.previousPolicyClaims : null,
      "PreviousPolicyPED": this.healthFormData.portabilityFormData.previousPolicyPED,
      "SpDetails": null,
      "SaveProposal": true,
      "Product24": "",
      "ProposalDate": "",
      "SubLimit": "",
      "isCombo": false,
      "ComboLanNo": "",
      "IsOtherLoadingDiscount": false,
      "OtherLoadingDiscountPercentage": "",
      "IsBefit": false,
      "BefitPlan": '',
      "IsSoftCopyDiscountApplicable": false,
      "PreHospitalization": "",
      "PostHospitalization": "",
      "PEDWaitingPeriod": "",
      "SpecificConditionWaiting": "",
      // this.healthFormData.quoteFormData.conditionWaitingPeriod
      "ProposalAnnualIncome": "",
      "RevisedZone": "",
      "Zone": "",
      "CPolicyNumber": "",
      "VoluntaryCopaymentPercentage": "",
      "ZoneUpgrade": false,
      "ProposalAnnualIncomeRange": "",
      "Pincode": this.healthFormData.quoteFormData.pincode,
      "DigitalPOSUserMobileNo": "",
      "IsPruCombi": false,
      "IPruCombiCoRelation": "",
      "IPruCombiAppNo": "",
      "IsCHIRefile": false,
      "InstallmentApplicable": "",
      "InstallmentFrequency": "",
      "InstallmentTotalPremium": this.healthFormData.premiumFormData.InstallmentTotalPremium ? this.healthFormData.premiumFormData.InstallmentTotalPremium.toString() : null,
      "BancaToken": "",
      "ProductName": this.healthFormData.quoteFormData.productName,
      "TotalPremium": this.healthFormData.premiumFormData.totalPremium,
      "BasicPremium": this.healthFormData.premiumFormData.basicPremium,
      "TotalTax": this.healthFormData.premiumFormData.totalTax,
      "PlanCode": this.healthFormData.quoteFormData.PlanCode,
      "PlanName": "",
      "Tenure": parseInt(this.healthFormData.quoteFormData.policyTenure),
      "hapAddonsFormData": {},
      "hepAddonsFormData": {},
      "hbAddonsFormData": [],
      "gsAddonsFormData": [],
      "CustomerDetails": {
        "CustomerType": this.healthFormData.kycFormData.customerType,
        "CustomerName": this.healthFormData.kycFormData.applicantDetails.applicantFullName,
        "DateOfBirth": formattedDate,
        "PinCode": this.healthFormData.quoteFormData.pincode,
        "PANCardNo": this.healthFormData.kycFormData.applicantDetails.applicantPanCardNumber == "" ? null : this.healthFormData.kycFormData.applicantDetails.applicantPanCardNumber,
        "Email": this.healthFormData.kycFormData.permanentAddress.permanentEmail,
        "MobileNumber": this.healthFormData.kycFormData.permanentAddress.permanentMobileNumber,
        "AddressLine1": this.healthFormData.kycFormData.permanentAddress.permanentAddress1,
        "AddressLine2": this.healthFormData.kycFormData.permanentAddress.permanentAddress2,
        "CountryCode": 100,
        "StateCode": +this.healthFormData.quoteFormData.stateId,
        "CityCode": +this.healthFormData.quoteFormData.cityId,
        "Gender": this.utilitySerice.titleBasedGender(this.healthFormData.kycFormData.applicantDetails.applicantTitle, this.routeData),
        "MobileISD": "91",
        "State": this.healthFormData.kycFormData.permanentAddress.permanentState,
        "City": this.healthFormData.kycFormData.permanentAddress.permanentCity,
        "Landmark": this.healthFormData.kycFormData.permanentAddress.permanentLandmark,
        "MaritalStatus": this.healthFormData.kycFormData.permanentAddress.permanentMaritalStatus,
        "PermanentSameAddress": this.healthFormData.kycFormData.permanentAddress.permanentSameAddress ? 'true' : 'false',
        "CorrespondingAddress": this.healthFormData.kycFormData.correspondenceAddress ? {
          "CorrespondenceLandmark": this.healthFormData.kycFormData.correspondenceAddress.correspondenceLandmark,
          "CorrespondenceAddressLine1": this.healthFormData.kycFormData.correspondenceAddress.correspondenceAddress1,
          "CorrespondenceAddressLine2": this.healthFormData.kycFormData.correspondenceAddress.correspondenceAddress2,
          "CorrespondencePincode": this.healthFormData.kycFormData.correspondenceAddress.correspondencePincode,
          "CorrespondenceState": this.healthFormData.kycFormData.correspondenceAddress.correspondenceState,
          "CorrespondenceCity": this.healthFormData.kycFormData.correspondenceAddress.correspondenceCity,
        } : null,
        "GSTDetails": this.healthFormData.kycFormData.otherDetails.business != "" ? {
          "GSTRegistered": this.healthFormData.kycFormData.otherDetails.gstRegistered,
          "GSTExemptionApplicable": this.healthFormData.kycFormData.otherDetails.gstApplicable,
          "GSTInNumber": this.healthFormData.kycFormData.otherDetails.gstin,
          "GSTToState": this.healthFormData.kycFormData.KYCStateId,
          "ConstitutionOfBusiness": this.healthFormData.kycFormData.otherDetails.business,
          "CustomerType": this.healthFormData.kycFormData.otherDetails.otherCustomerType,
          "PanDetails": this.healthFormData.kycFormData.otherDetails.panNumber,
          "GSTRegistrationStatus": this.healthFormData.kycFormData.otherDetails.gstStatus,
          "insuredHIP": this.healthFormData.kycFormData.otherDetails['insuredHIP'] ? this.healthFormData.kycFormData.otherDetails['insuredHIP'] : "",
          "taxExemptionHB": this.healthFormData.kycFormData.otherDetails['taxExemptionHB'] ? this.healthFormData.kycFormData.otherDetails['taxExemptionHB'] : "",
          "UINNumber": this.healthFormData.kycFormData.otherDetails.uin,
        } : null,
        "AadharNumber": null,
        "IsCollectionofform60": false,
        "AadharEnrollmentNo": null,
        "eIA_Number": null,
        "CorelationId": this.healthFormData.quoteFormData.corelationId ? this.healthFormData.quoteFormData.corelationId : '',
        "CustomerID": "",
        "CKYCId": null,
        "EKYCid": null,
        "PEPFlag": false,
        "ILKYCReferenceNumber": null,
        "SkipDedupeLogic": null,
        "DateOfIncorporation": null,
        "SourceOfFunds": null,
        "OtherFunds": null,
        "CIN": null
      },
      "CritiShield": this.healthFormData.quoteFormData.subProductType == 38 ? {
        "savedQuoteData": this.healthFormData.quoteFormData,
        "insuredData": this.healthFormData.insuredFormData,
        "premiumFormData": this.healthFormData.premiumFormData
      } : null,
      "FamilyShield": this.healthFormData.quoteFormData.subProductType == 23 ? {
        "savedQuoteData": this.healthFormData.quoteFormData,
        "insuredData": this.healthFormData.insuredFormData,
        "premiumFormData": this.healthFormData.premiumFormData,
        "kycRiskDate": this.healthFormData.kycFormData.otherDetails.riskStartDate
      } : null,
      "GenericData": genericData,
      "VoluntaryDeductible": "",
      "ProductTypeHB": "",
    };

    // if (this.healthFormData.quoteFormData.productType == "Portability") {
    //   saveProposalRequest.PortabilityTypeOfPolicy = this.healthFormData.portabilityFormData.typeOfPolicy;
    //   saveProposalRequest.PortabilitySumInsured = this.healthFormData.portabilityFormData.typeOfPolicy == policyType.Floater ? this.healthFormData.portabilityFormData.portabilitySumInsured : this.SIinsured;
    //   saveProposalRequest.PortabilityWaiver = "0";
    //   saveProposalRequest.PortabilityDOJ = this.utilityService.formatDate(this.healthFormData.portabilityFormData.policyStartDate);
    //   saveProposalRequest.PortabilitySI = "0";
    //   saveProposalRequest.PreviousPolicies = {
    //     InsurerCompany: this.healthFormData.portabilityFormData.insuranceCompanyShortName,
    //     PolicyNo: this.healthFormData.portabilityFormData.policyNumber,
    //     PolicyStartDate: this.utilityService.formatDate(this.healthFormData.portabilityFormData.policyStartDate),
    //     PolicyEndDate: this.utilityService.formatDate(this.healthFormData.portabilityFormData.policyEndDate),
    //     TotalPremium: this.healthFormData.portabilityFormData.premiumAmount,
    //     SumInsured: "0", // By default goes 0 in existing
    //     Waiver: 0, // By default goes 0
    //     ReasonOfPortability: this.healthFormData.portabilityFormData.portabilityReason,
    //   }
    // }
    this.healthDataStorageService.setHealthDataStorage(this.healthFormData);

    return saveProposalRequest;

  }
}
