import { HealthAilmentResponseStructure, InsuredRelationship, Relationship } from "src/app/shared/interface/healthMaster";
import { NewAddOnCoverDetails } from "src/app/shared/interface/renewal";

export interface RenewalDataStorageRequestPayload {
  UserType: string,
  PolicyNo: string,
  Addon1: boolean,
  Addon3: boolean,
  IsHigherSiRequired: boolean,
  SubProductType: number,
  InsuredDOB?: string,
}

export interface ZerotatDataStorageRequestPayload {
  PolicyNo: string,
  InsuredDOB?: string,
}

export interface HomeRenewalRequestPayload {
  policy_number: string,
  policy_end_date: string,
}

export interface RenewalDataStorage {
  ProductCode?:string;
  CibilDiscountPercentage?: number;
  diseaseList: HealthAilmentResponseStructure[];
  occupationList: any[];
  addonList: NewAddOnCoverDetails[];
  NomineeAppointeeRelationList: Relationship[];
  InsuredRelationList: InsuredRelationship[];
  recommendedSI?: number,
  InsuredLoading?:number,
  VDValues?: VDValueForDeductible[];
  UWStatusCode?: string
  instantrenewal?: boolean
  listingRenewal?: boolean
  proposalRenewal?: boolean
  changeZone?: boolean
  IsHaapRefiling?: boolean
  ZoneRefilingflag?: string
  EMIOptionVisibleFlag?: boolean
  InstallmentFrequencyName?: string
  IsRenewalEdited: boolean
  IsMultiPlanPolicy: boolean
  IsProductSiCrossedSiLimit: boolean
  IsProductCoverted: boolean
  panRequired?: boolean
  emiValidationPopup: boolean
  SubLimit: any
  NewASI: number
  PrevASI: number
  ClaimStatus: string,
  HospitalizationCoverSI: any
  multiPlanDtls: MultiPlanDtl[]
  InstallmentEMI?: any
  loadingPercentage: number
  loadingDiscount: number
  IsHigherSiRequired: boolean
  higherPremiumDetails: any
  befitCovers: BefitCover[]
  IsBefitCityApplicabilityChkON: boolean
  BefitApplicableCities: any
  Zone: string
  RevisedZone: string
  EDealId: string
  EPolicyNo: string
  VoluntaryCopay: number
  PolicyTenure: string[]
  PEDWaitingperiod: string[]
  CoPay: number[]
  PolicyPlan: PolicyPlan[]
  SumInsuredDetails: SumInsuredDetails[]
  PolicyRange: string
  IsBefitApplicable: boolean
  BefitPlan: string
  Occupation: Occupation
  InstallmentApplicable: boolean
  InstallmentFrequency: string[]
  InstallmentFlag: boolean
  IncomeRange: IncomeRange[]
  SubSequentSIPremiums: any
  PolicyNo: string
  ProductType: string
  SubProductType: number
  SubProductCode: string
  ProductName: string
  SumInsured: number
  ILkycReferenceNumber: string
  CKYCID:string
  EKYCID:string
  PrevPolicySI: number
  DealId: string
  VoluntaryDeductible: number
  VoluntaryDeductable: number
  Tenure: number
  isJammuAndKashmir: boolean
  NoOfPlans: number
  PfProposalNo: any
  AgeOfEldest: any
  Premium1YearFlag: boolean
  Premium1Year: Premium1Year
  NomineeName: string
  NomineeTitle: string
  NomineeRelationShipId: string
  NomineeRelationShip: any
  NomineeDOB: string
  AppointeeName: string
  AppointeeTitle: string
  AppointeeRelationShipId: string
  AppointeeRelationShip: string
  AppointeeDOB: string
  Spdetails: Spdetails
  customerDetails: CustomerDetails
  PrevPolicyStartDate: string
  PrevPolicyEndDate: string
  isCentralizedDeal: string
  SipProtectCoverValue: number
  SuperNCBCoverValue: number
  Premium2YearFlag: boolean
  Premium2Year: Premium2Year
  Premium3YearFlag: boolean
  Premium3Year: Premium3Year
  AgentName: string
  AgentId: string
  StatusCode: number
  StatusMsg: string
  StatusDesc: string
  ErrorText: any
  StatusType: any
  TotalPremium: number
}

export interface VDValueForDeductible {
  value: any;
}

export interface MultiPlanDtl {
  insuredDetails: InsuredDetail[]
  SumInsured: number
  DeductibleSI: number
  AgeOfEldest: number
  AdditionalSI: number
  NoOfMember: number
  NoOfAdults: number
  NoOfKids: number
  isFloater: boolean
  SipProtectCoverValue?: string
  SuperNCBCoverValue?: string
}

export interface InsuredDetail {
  AddOnCoverDetails: any[]
  AddOn1: boolean
  AddOn3: boolean
  AddOn2: boolean
  AddOn4: boolean
  AddOn5: boolean
  AddOn6: boolean
  AddOn7: boolean
  AddOn8: boolean
  AddOn9: boolean
  AddOn10: boolean
  AddOn11: boolean
  AddOn12: boolean
  AddOn13: boolean
  NewAddOnCoverDetails: any[]
  VaccineDate: any
  MemberType: string
  UWPedIlness: any
  Occupation: any
  TitleId: number
  InsuredName: string
  DateofBirth: string
  RelationShipID: number
  RelationShipName: string
  PedIllness: any[]
  Height: number
  HeightInInches: number
  HeightInFeet: number
  Weight: number
  KidAdultType: number
  DateOfJoining: string
  IsExisting: boolean
  Gender: string
  IsPneumococcalVaccination: boolean
  VaccinationDate: any
}

export interface BefitCover {
  CoverId: number
  CoverName: string
  DisplayName: string
  IsHMP: boolean
  befitCoverBenefits: BefitCoverBenefit[]
}

export interface BefitCoverBenefit {
  BenefitName: string
  BenefitValue: string
  BenefitValueType: string
}

export interface PolicyPlan {
  POLICY_PLAN: string
  SI_VALUE: string
}

export interface SumInsuredDetails {
  SumAmount: number
  PolicyRange: string
  VDValues: VDValue[]
  SumAmountDisplay:string
}

export interface VDValue {
  value: number
}

export interface Occupation {
  "Parking Enforcement Officer ": string
  "Insurance Agent": string
  "Police": string
  "Farmer (manual labour)": string
  "Fishmonger ": string
  "Hairstylist/Hairdresser ": string
  "Bargeman / Barge Operator ": string
  "Fashion Model (Females)": string
  "Technician ": string
  "Engineer (Office duties only) ": string
  "Building and Contruction Workers ": string
  "Miners": string
  "IT professional ": string
  "Welder ": string
  "Forklift Driver ": string
  "Cleaner (domestic/ Business premises) ": string
  "Computer Specialist ": string
  "Juvenile ": string
  "Jockey ": string
  "Harbour Pilot ": string
  "Seamstress ": string
  "Politician ": string
  "Receptionist ": string
  "Carpenter ": string
  "Clerks-of-Works ": string
  "Cashier ": string
  "Tug Boat Operator ": string
  "Aircraft Crew": string
  "Agent/Outdoor Sales Persons ": string
  "Driver (Private Car)": string
  "Admin Assistant": string
  "Printer ": string
  "Public Utilities Technical Personnel ": string
  "Nuclear reactor workers": string
  "Gardener ": string
  "Shipping Admin Personnel ": string
  "Office Executive ": string
  "Defence forces": string
  "Detective ": string
  "Bus Driver ": string
  "Chef/Cook ": string
  "Banker ": string
  "Fireman ": string
  "Student": string
  "Housing and Maintenance Inspector ": string
  "Statistician ": string
  "Loss Adjuster ": string
  "Zoo Keeper ": string
  "Dance Hostess ": string
  "Pastor ": string
  "Odd Job Labourer ": string
  "Construction Foreman ": string
  "Sales Personnel ": string
  "Domestic Servant/Maid ": string
  "Manager ": string
  "President (Company) ": string
  "Shop Keeper (General/confectionary/sweets)": string
  "Research and Development Engineer (Non- hazardous)": string
  "Nurse (psychiatric) ": string
  "Tutor (Private) ": string
  "Craftsman ": string
  "Bus Inspector ": string
  "Optician ": string
  "Construction Worker (labourer)": string
  "Draughtsman ": string
  "Timber Industry Sawyer ": string
  "Diplomat ": string
  "Oil Refinery Admin Personnel ": string
  "Aircraft Mechanic ": string
  "Director ": string
  "Actor/Actress (no stunts)": string
  "Author ": string
  "Aircraft Engineer ": string
  "Clerk ": string
  "Customer Service Officer ": string
  "Site Supervisors ": string
  "Remover (House/Office) ": string
  "General Manager ": string
  "Operator Explosives": string
  "Administrator ": string
  "Flight Steward/Stewardess ": string
  "Heavy Vehicles Driver ": string
  "Butcher in supermarket ": string
  "Land Surveyor ": string
  "Lecturer ": string
  "Managing Director ": string
  "Coach (Sports) ": string
  "Jeweller ": string
  "Merchant Navy": string
  "Mechanic (Car) ": string
  "Ship Building Technical Personnel ": string
  "Crane Operators ": string
  "Rail/MRT Worker (Platform announcer, Station Master) ": string
  "Underwriter": string
  "Veterinary Surgeons ": string
  "Factory Worker (non-hazardous)": string
  "Florist ": string
  "Bouncer": string
  "Chief Executive/Marketing/Finance Officer ": string
  "Tailor ": string
  "Remiser/Stockbroker ": string
  "Bookkeeper ": string
  "Enterpreneur ": string
  "Window Cleaner ": string
  "Railway/MRT driver": string
  "Artist ": string
  "Store Keeper / Storeman (Manufacturing industry) ": string
  "Aircraft Pilot": string
  "Oil Refinery Plant Operator ": string
  "Pharmacist ": string
  "Lawyer ": string
  "Store Keeper / Storeman (Retail) ": string
  "Goldsmith ": string
  "Renovation Contractor (without manual work) ": string
  "Training Officer  (Non technical/non-defense)": string
  "Secretary ": string
  "Journalist/Reporter (no overseas/dangerous assignments) ": string
  "Ship crew": string
  "FX Dealers ": string
  "Janitor": string
  "Retiree ": string
  "Teacher ": string
  "Postman (with driving) ": string
  "Sports player (physical activity)": string
  "Trainee Technician ": string
  "Promoter (Department store) ": string
  "Personal Security": string
  "Architect ": string
  "Bartender ": string
  "Librarian ": string
  "Blacksmith ": string
  "Restaurant Waiter/Waitress ": string
  "Barber ": string
  "Chemist (not dealing with nuclear/ flammable or corrosive chemicals) ": string
  "Taxi Driver ": string
  "Hawker ": string
  "Butcher ": string
  "Nurse (except psychiatric) ": string
  "Business Executive ": string
  "Marketing Personnel (no sales) ": string
  "Baker ": string
  "Dancer (Professional) ": string
  "Typist ": string
  "Masseur/Masseuse ": string
  "Rail/MRT Worker (Line inspector)": string
  "Fireworks worker": string
  "Plumber ": string
  "Café-Proprietor ": string
  "Telephone Operator ": string
  "Clerical Officer ": string
  "Professor": string
  "Computer Engineer ": string
  "Designer ": string
  "Technical Officer ": string
  "Lorry/Truck Driver ": string
  "Accounts Assistant": string
  "Lifeguard (Professional/Full-time) ": string
  "Bar Waiter/Waitress ": string
  "Hotel (Public Relations Officer) ": string
  "Driving Instructor ": string
  "Fisherman ": string
  "Dock Worker ": string
  "Fashion Model (males)": string
  "Chauffeur ": string
  "Cruise Liner - Crew ": string
  "Machinist ": string
  "Oil Refinerry/Onsite structural engineer ": string
  "Indoor Sales Person ": string
  "Security Guard ": string
  "Machine Operator ": string
  "Accountant": string
  "Interior Designer ": string
  "Dealer Explosives": string
  "Metallurgical Surveyor ": string
  "Real Estate Agent ": string
  "Tour Guide ": string
  "Photographer ": string
  "Postman (without driving) ": string
  "Manager in Hotel/Restaurant/Theater ": string
  "Auditor ": string
  "Despatch/Delivery Man ": string
  "Painter (Non artistic)": string
  "Hospital Attendants ": string
  "Doctor": string
  "Locksmith ": string
  "Consultant ": string
  "Fitter ": string
  "Retailer ": string
  "Port Chemist ": string
  "Financial Controller ": string
  "Electrician - Industrial/Household": string
  "Construction Foremen ": string
  "Economist ": string
  "Canteen Assistant ": string
  "Beautician ": string
  "Tax Officer ": string
  "Salvage Vessel - Officer and Crew ": string
  "Boat Builder ": string
  "Wholesale Merchant ": string
  "Principal (School) ": string
  "Editor ": string
  "Electrical Engineers (not dealing with high power cables) ": string
  "Car Park Attendant ": string
}

export interface IncomeRange {
  Id: number
  Name: string
  INCOME_LOWER_LIMIT: string
}

export interface Premium1Year {
  BasicPremium: number
  TotalTax: number
  TotalPremium: number
  PolicyStatus: string
  SumInsured: any
  Tenure: number
  InstallmentEMI: any
}

export interface Spdetails {
  AlternateRMCode: string
  AlternateRMName: string
  CustomerReferenceNumber: string
  ChannelName: string
  PrimaryRMCode: string
  SecondaryRMCode: string
  BancaField01: string
  BancaField02: string
  BancaField03: string
}

export interface CustomerDetails {
  CKYCID: string
  PEPFLAG: boolean
  EKYCID: string
  ReferenceNo: string
  IpartnerCustomerId: string
  CustomerName: string
  CustomerId: string
  CustomerType: string
  Gender: string
  DOB: string
  PAN: string
  EmailId: string
  AlternateEmailId: string
  MOBILENUMBER: string
  AlternateMobileNo: string
  TELEPHONENUMBER: string
  MOTHER_NAME: string
  MAILINGCITYDISTRICT: string
  MAILING_CITYDISTRICT_NAME: string
  MAILINGAREAVILLAGE_CD: string
  MAILINGAREAVILLAGE_NAME: string
  MAILINGCOUNTRY_CD: string
  MAILINGCOUNTRY_NAME: string
  MAILINGADDRESSDETAIL: string
  MAILINGPINCODE: string
  MAILING_STATE_NAME: string
  MAILINGSTATE_CODE: string
  MAILINGLANDMARK: string
  PERMANENTCITYCODE: string
  PERMANENTADDRESSDETAIL: string
  PERMANENTPINCODE: string
  PERMANENTCITYDISTRICT: string
  PERMANENTAREAVILLAGE_CD: string
  PERMANENTAREAVILLAGE_NAME: string
  PERMANENTCOUNTRY_CD: string
  PERMANENTCOUNTRY_NAME: string
  PERMANENTSTATE: string
  PERMANENTSTATECODE: string
  PERMANENTLANDMARK: string
  OCCUPATIONDESC: string
  NATIONALITY: string
  RESIDENTIALSTATUS: string
  EDUCATIONQUALIFICATION: string
  ANNUALINCOME: string
  PASSPORTNO: string
  AADHARNO: string
  MARITALSTATUS: string
  MAILINGADDRESSLINE1: string
  MAILINGADDRESSLINE2: string
  PERMANENTADDRESSLINE1: string
  PERMANENTADDRESSLINE2: string
  PanExemptedApplicable: boolean
  AadhaarExemptedApplicable: boolean
  GSTInApplicable: boolean
  UInApplicable: boolean
  GSTInData: any[]
  Status: string
  Error_Id: string
  ErrorText: string
}

export interface Premium2Year {
  BasicPremium: number
  TotalTax: number
  TotalPremium: number
  PolicyStatus: string
  SumInsured: number
  Tenure: number
  InstallmentEMI: any
}

export interface Premium3Year {
  BasicPremium: number
  TotalTax: number
  TotalPremium: number
  PolicyStatus: string
  SumInsured: any
  Tenure: number
  InstallmentEMI: any
}


export class RenewalDataModal {
  renewalDataStorage: RenewalDataStorage;
  setRenewalDataStorage(form: RenewalDataStorage) {
    this.renewalDataStorage = form;
  }

  //   zeroTatDataStorage: any
  // export class setZerotatDataStorage {
  //   this.zeroTatDataStorage = form;
  // }

  getRenewalDataStorage(): RenewalDataStorage {
    if (this.renewalDataStorage) {
      return this.renewalDataStorage;
    }
    else {
      this.resetRenewalDataStorage();
      return this.renewalDataStorage;
    }
  }

  resetRenewalDataStorage(): RenewalDataStorage {
    this.renewalDataStorage = {
      InsuredRelationList: [],
      NomineeAppointeeRelationList:[],
      addonList:[],
      diseaseList:[],
      occupationList:[],
      EMIOptionVisibleFlag: false,
      InstallmentEMI: {},
      InstallmentFrequencyName: "",
      IsRenewalEdited: false,
      emiValidationPopup: false,
      IsMultiPlanPolicy: false,
      IsProductSiCrossedSiLimit: false,
      IsProductCoverted: false,
      SubLimit: 0,
      NewASI: 0,
      PrevASI: 0,
      ClaimStatus: '',
      HospitalizationCoverSI: 0,
      ILkycReferenceNumber: '',
      CKYCID:'',
      EKYCID:'',
      multiPlanDtls: [{
        insuredDetails: [{
          AddOnCoverDetails: [],
          AddOn1: false,
          AddOn3: false,
          AddOn2: false,
          AddOn4: false,
          AddOn5: false,
          AddOn6: false,
          AddOn7: false,
          AddOn8: false,
          AddOn9: false,
          AddOn10: false,
          AddOn11: false,
          AddOn12: false,
          AddOn13: false,
          NewAddOnCoverDetails: [],
          VaccineDate: 0,
          MemberType: '',
          UWPedIlness: 0,
          Occupation: 0,
          TitleId: 0,
          InsuredName: '',
          DateofBirth: '',
          RelationShipID: 0,
          RelationShipName: '',
          PedIllness: [],
          Height: 0,
          HeightInInches: 0,
          HeightInFeet: 0,
          Weight: 0,
          KidAdultType: 0,
          DateOfJoining: '',
          IsExisting: false,
          Gender: '',
          IsPneumococcalVaccination: false,
          VaccinationDate: 0
        }],
        SumInsured: 0,
        DeductibleSI: 0,
        AgeOfEldest: 0,
        AdditionalSI: 0,
        NoOfMember: 0,
        NoOfAdults: 0,
        NoOfKids: 0,
        isFloater: false,
        SipProtectCoverValue: "",
        SuperNCBCoverValue: ""
      }],
      loadingPercentage: 0,
      loadingDiscount: 0,
      IsHigherSiRequired: false,
      higherPremiumDetails: 0,
      befitCovers: [{
        CoverId: 0,
        CoverName: '',
        DisplayName: '',
        IsHMP: false,
        befitCoverBenefits: [{
          BenefitName: '',
          BenefitValue: '',
          BenefitValueType: ''
        }]
      }],
      IsBefitCityApplicabilityChkON: false,
      BefitApplicableCities: 0,
      Zone: '',
      RevisedZone: '',
      ZoneRefilingflag: 'no',
      EDealId: '',
      EPolicyNo: '',
      VoluntaryCopay: 0,
      PolicyTenure: [],
      PEDWaitingperiod: [],
      CoPay: [],
      PolicyPlan: [],
      SumInsuredDetails: [],
      PolicyRange: '',
      IsBefitApplicable: false,
      BefitPlan: '',
      Occupation: {
        "Parking Enforcement Officer ": "",
        "Insurance Agent": "",
        "Police": "",
        "Farmer (manual labour)": "",
        "Fishmonger ": "",
        "Hairstylist/Hairdresser ": "",
        "Bargeman / Barge Operator ": "",
        "Fashion Model (Females)": "",
        "Technician ": "",
        "Engineer (Office duties only) ": "",
        "Building and Contruction Workers ": "",
        "Miners": "",
        "IT professional ": "",
        "Welder ": "",
        "Forklift Driver ": "",
        "Cleaner (domestic/ Business premises) ": "",
        "Computer Specialist ": "",
        "Juvenile ": "",
        "Jockey ": "",
        "Harbour Pilot ": "",
        "Seamstress ": "",
        "Politician ": "",
        "Receptionist ": "",
        "Carpenter ": "",
        "Clerks-of-Works ": "",
        "Cashier ": "",
        "Tug Boat Operator ": "",
        "Aircraft Crew": "",
        "Agent/Outdoor Sales Persons ": "",
        "Driver (Private Car)": "",
        "Admin Assistant": "",
        "Printer ": "",
        "Public Utilities Technical Personnel ": "",
        "Nuclear reactor workers": "",
        "Gardener ": "",
        "Shipping Admin Personnel ": "",
        "Office Executive ": "",
        "Defence forces": "",
        "Detective ": "",
        "Bus Driver ": "",
        "Chef/Cook ": "",
        "Banker ": "",
        "Fireman ": "",
        "Student": "",
        "Housing and Maintenance Inspector ": "",
        "Statistician ": "",
        "Loss Adjuster ": "",
        "Zoo Keeper ": "",
        "Dance Hostess ": "",
        "Pastor ": "",
        "Odd Job Labourer ": "",
        "Construction Foreman ": "",
        "Sales Personnel ": "",
        "Domestic Servant/Maid ": "",
        "Manager ": "",
        "President (Company) ": "",
        "Shop Keeper (General/confectionary/sweets)": "",
        "Research and Development Engineer (Non- hazardous)": "",
        "Nurse (psychiatric) ": "",
        "Tutor (Private) ": "",
        "Craftsman ": "",
        "Bus Inspector ": "",
        "Optician ": "",
        "Construction Worker (labourer)": "",
        "Draughtsman ": "",
        "Timber Industry Sawyer ": "",
        "Diplomat ": "",
        "Oil Refinery Admin Personnel ": "",
        "Aircraft Mechanic ": "",
        "Director ": "",
        "Actor/Actress (no stunts)": "",
        "Author ": "",
        "Aircraft Engineer ": "",
        "Clerk ": "",
        "Customer Service Officer ": "",
        "Site Supervisors ": "",
        "Remover (House/Office) ": "",
        "General Manager ": "",
        "Operator Explosives": "",
        "Administrator ": "",
        "Flight Steward/Stewardess ": "",
        "Heavy Vehicles Driver ": "",
        "Butcher in supermarket ": "",
        "Land Surveyor ": "",
        "Lecturer ": "",
        "Managing Director ": "",
        "Coach (Sports) ": "",
        "Jeweller ": "",
        "Merchant Navy": "",
        "Mechanic (Car) ": "",
        "Ship Building Technical Personnel ": "",
        "Crane Operators ": "",
        "Rail/MRT Worker (Platform announcer, Station Master) ": "",
        "Underwriter": "",
        "Veterinary Surgeons ": "",
        "Factory Worker (non-hazardous)": "",
        "Florist ": "",
        "Bouncer": "",
        "Chief Executive/Marketing/Finance Officer ": "",
        "Tailor ": "",
        "Remiser/Stockbroker ": "",
        "Bookkeeper ": "",
        "Enterpreneur ": "",
        "Window Cleaner ": "",
        "Railway/MRT driver": "",
        "Artist ": "",
        "Store Keeper / Storeman (Manufacturing industry) ": "",
        "Aircraft Pilot": "",
        "Oil Refinery Plant Operator ": "",
        "Pharmacist ": "",
        "Lawyer ": "",
        "Store Keeper / Storeman (Retail) ": "",
        "Goldsmith ": "",
        "Renovation Contractor (without manual work) ": "",
        "Training Officer  (Non technical/non-defense)": "",
        "Secretary ": "",
        "Journalist/Reporter (no overseas/dangerous assignments) ": "",
        "Ship crew": "",
        "FX Dealers ": "",
        "Janitor": "",
        "Retiree ": "",
        "Teacher ": "",
        "Postman (with driving) ": "",
        "Sports player (physical activity)": "",
        "Trainee Technician ": "",
        "Promoter (Department store) ": "",
        "Personal Security": "",
        "Architect ": "",
        "Bartender ": "",
        "Librarian ": "",
        "Blacksmith ": "",
        "Restaurant Waiter/Waitress ": "",
        "Barber ": "",
        "Chemist (not dealing with nuclear/ flammable or corrosive chemicals) ": "",
        "Taxi Driver ": "",
        "Hawker ": "",
        "Butcher ": "",
        "Nurse (except psychiatric) ": "",
        "Business Executive ": "",
        "Marketing Personnel (no sales) ": "",
        "Baker ": "",
        "Dancer (Professional) ": "",
        "Typist ": "",
        "Masseur/Masseuse ": "",
        "Rail/MRT Worker (Line inspector)": "",
        "Fireworks worker": "",
        "Plumber ": "",
        "Café-Proprietor ": "",
        "Telephone Operator ": "",
        "Clerical Officer ": "",
        "Professor": "",
        "Computer Engineer ": "",
        "Designer ": "",
        "Technical Officer ": "",
        "Lorry/Truck Driver ": "",
        "Accounts Assistant": "",
        "Lifeguard (Professional/Full-time) ": "",
        "Bar Waiter/Waitress ": "",
        "Hotel (Public Relations Officer) ": "",
        "Driving Instructor ": "",
        "Fisherman ": "",
        "Dock Worker ": "",
        "Fashion Model (males)": "",
        "Chauffeur ": "",
        "Cruise Liner - Crew ": "",
        "Machinist ": "",
        "Oil Refinerry/Onsite structural engineer ": "",
        "Indoor Sales Person ": "",
        "Security Guard ": "",
        "Machine Operator ": "",
        "Accountant": "",
        "Interior Designer ": "",
        "Dealer Explosives": "",
        "Metallurgical Surveyor ": "",
        "Real Estate Agent ": "",
        "Tour Guide ": "",
        "Photographer ": "",
        "Postman (without driving) ": "",
        "Manager in Hotel/Restaurant/Theater ": "",
        "Auditor ": "",
        "Despatch/Delivery Man ": "",
        "Painter (Non artistic)": "",
        "Hospital Attendants ": "",
        "Doctor": "",
        "Locksmith ": "",
        "Consultant ": "",
        "Fitter ": "",
        "Retailer ": "",
        "Port Chemist ": "",
        "Financial Controller ": "",
        "Electrician - Industrial/Household": "",
        "Construction Foremen ": "",
        "Economist ": "",
        "Canteen Assistant ": "",
        "Beautician ": "",
        "Tax Officer ": "",
        "Salvage Vessel - Officer and Crew ": "",
        "Boat Builder ": "",
        "Wholesale Merchant ": "",
        "Principal (School) ": "",
        "Editor ": "",
        "Electrical Engineers (not dealing with high power cables) ": "",
        "Car Park Attendant ": "",
      },
      InstallmentApplicable: false,
      InstallmentFrequency: [],
      InstallmentFlag: false,
      IncomeRange: [{
        Id: 0,
        Name: '',
        INCOME_LOWER_LIMIT: ''
      }],
      SubSequentSIPremiums: {
        SumInsured: 0,
        PremiumDetails: 0,
        ErrorText: ''
      },
      PolicyNo: '',
      ProductType: '',
      SubProductType: 0,
      SubProductCode: '',
      ProductName: 'hap',
      SumInsured: 100000,
      PrevPolicySI: 0,
      DealId: '',
      VoluntaryDeductible: 0,
      VoluntaryDeductable: 0,
      Tenure: 0,
      isJammuAndKashmir: false,
      NoOfPlans: 0,
      PfProposalNo: 0,
      AgeOfEldest: 0,
      Premium1YearFlag: false,
      Premium1Year: {
        BasicPremium: 0,
        TotalTax: 0,
        TotalPremium: 0,
        PolicyStatus: "APPROVE",
        SumInsured: null,
        Tenure: 0,
        InstallmentEMI: null
      },
      NomineeName: '',
      NomineeTitle: '',
      NomineeRelationShipId: '',
      NomineeRelationShip: 0,
      NomineeDOB: '',
      AppointeeName: '',
      AppointeeTitle: '',
      AppointeeRelationShipId: '',
      AppointeeRelationShip: '',
      AppointeeDOB: '',
      Spdetails: {

        AlternateRMCode: '',
        AlternateRMName: '',
        CustomerReferenceNumber: '',
        ChannelName: '',
        PrimaryRMCode: '',
        SecondaryRMCode: '',
        BancaField01: '',
        BancaField02: '',
        BancaField03: '',
      },
      customerDetails: {
        CKYCID: '',
        PEPFLAG: false,
        EKYCID: '',
        ReferenceNo: '',
        IpartnerCustomerId: '',
        CustomerName: '',
        CustomerId: '',
        CustomerType: '',
        Gender: '',
        DOB: '',
        PAN: '',
        EmailId: '',
        AlternateEmailId: '',
        MOBILENUMBER: '',
        AlternateMobileNo: '',
        TELEPHONENUMBER: '',
        MOTHER_NAME: '',
        MAILINGCITYDISTRICT: '',
        MAILING_CITYDISTRICT_NAME: '',
        MAILINGAREAVILLAGE_CD: '',
        MAILINGAREAVILLAGE_NAME: '',
        MAILINGCOUNTRY_CD: '',
        MAILINGCOUNTRY_NAME: '',
        MAILINGADDRESSDETAIL: '',
        MAILINGPINCODE: '',
        MAILING_STATE_NAME: '',
        MAILINGSTATE_CODE: '',
        MAILINGLANDMARK: '',
        PERMANENTCITYCODE: '',
        PERMANENTADDRESSDETAIL: '',
        PERMANENTPINCODE: '',
        PERMANENTCITYDISTRICT: '',
        PERMANENTAREAVILLAGE_CD: '',
        PERMANENTAREAVILLAGE_NAME: '',
        PERMANENTCOUNTRY_CD: '',
        PERMANENTCOUNTRY_NAME: '',
        PERMANENTSTATE: '',
        PERMANENTSTATECODE: '',
        PERMANENTLANDMARK: '',
        OCCUPATIONDESC: '',
        NATIONALITY: '',
        RESIDENTIALSTATUS: '',
        EDUCATIONQUALIFICATION: '',
        ANNUALINCOME: '',
        PASSPORTNO: '',
        AADHARNO: '',
        MARITALSTATUS: '',
        MAILINGADDRESSLINE1: '',
        MAILINGADDRESSLINE2: '',
        PERMANENTADDRESSLINE1: '',
        PERMANENTADDRESSLINE2: '',
        PanExemptedApplicable: false,
        AadhaarExemptedApplicable: false,
        GSTInApplicable: false,
        UInApplicable: false,
        GSTInData: [],
        Status: '',
        Error_Id: '',
        ErrorText: '',
      },
      PrevPolicyStartDate: '',
      PrevPolicyEndDate: '',
      isCentralizedDeal: '',
      SipProtectCoverValue: 0,
      SuperNCBCoverValue: 0,
      Premium2YearFlag: false,
      Premium2Year: {
        BasicPremium: 0,
        TotalTax: 0,
        TotalPremium: 0,
        PolicyStatus: "APPROVE",
        SumInsured: null,
        Tenure: 0,
        InstallmentEMI: null
      },
      Premium3YearFlag: false,
      Premium3Year: {
        BasicPremium: 0,
        TotalTax: 0,
        TotalPremium: 0,
        PolicyStatus: "APPROVE",
        SumInsured: null,
        Tenure: 0,
        InstallmentEMI: null
      },
      AgentName: '',
      AgentId: '',
      StatusCode: 0,
      StatusMsg: '',
      StatusDesc: '',
      ErrorText: 0,
      StatusType: 0,
      TotalPremium: 0,
    }
    return this.renewalDataStorage;
  }

}