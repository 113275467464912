import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, Subscription } from 'rxjs';

import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { DownloadService } from 'src/app/shared/services/download.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-brochure',
  templateUrl: './brochure.component.html',
  styleUrls: ['./brochure.component.scss']
})
export class BrochureComponent implements OnInit {
  errorPopupData: PopupModal;
  uparrow = false;
  scrolling: boolean = false;
  clickCounter = 0;
  subscription: Subscription[] = [];

  constructor(private downloadService: DownloadService, private router: Router) { }

  productDetails = [
    { productName: 'Elevate', isDropdownOpen: false, productCode: 45, arrowDown: true, brocpdf: "BROC_NEW_Elevate.pdf", pwpdf: "PW_Elevate.pdf", cfpdf: "CF_Elevate.pdf", imagePath: "assets/images/dashboard/elevate.svg", menuItems: ['Brochure', 'Policy Wording', 'Claim Form'] },
    { productName: 'Health AdvantEdge+', isDropdownOpen: false, productCode: 42, arrowDown: true, brocpdf: "HA_BROC.pdf", pwpdf: "HA_PW.pdf", cfpdf: "HA_CF.pdf", faqpdf: "HA_FAQ.pdf", imagePath: "assets/images/dashboard/health_adv.svg", menuItems: ['Brochure', 'Policy Wording', 'Claim Form', 'FAQs'] },
    { productName: 'Health Elite Plus', isDropdownOpen: false, productCode: 33, arrowDown: true, brocpdf: "CHI_BROC.pdf", pwpdf: "CHI_PW.pdf", cfpdf: "CHI_CF.pdf", faqpdf: "CHI_FAQ.pdf", imagePath: "assets/images/dashboard/Health_Elite.svg", menuItems: ['Brochure', 'Policy Wording', 'Claim Form', 'FAQs'] },
    { productName: 'Max Protect', isDropdownOpen: false, productCode: 34, arrowDown: true, brocpdf: "MAX_BROC.pdf", pwpdf: "MAX_PW.pdf", imagePath: "assets/images/dashboard/max_protect.svg", menuItems: ['Brochure', 'Policy Wording'] },
    { productName: 'Health Booster', isDropdownOpen: false, productCode: 20, arrowDown: true, brocpdf: "HB_BROC.pdf", pwpdf: "HB_PW.pdf", imagePath: "assets/images/dashboard/Health_Booster.svg", menuItems: ['Brochure', 'Policy Wording'] },
    { productName: 'Arogya Sanjeevani', isDropdownOpen: false, productCode: 22, arrowDown: true, brocpdf: "ASP_BROC.pdf", pwpdf: "ASP_PW.pdf", cfpdf: "ASP_CF.pdf", imagePath: "assets/images/dashboard/Arogya_sanjeevani.svg", menuItems: ['Brochure', 'Policy Wording', 'Claim Form'] },
    { productName: 'Golden Shield', isDropdownOpen: false, productCode: 40, arrowDown: true, brocpdf: "GS_BROC.pdf", pwpdf: "GS_PW.pdf", faqpdf: "GS_FAQ.pdf", imagePath: "assets/images/dashboard/Golden_Shield.svg", menuItems: ['Brochure', 'Policy Wording', 'FAQs'] },
    { productName: 'Criti Shield', isDropdownOpen: false, productCode: 38, arrowDown: true, brocpdf: "CS_BROC.pdf", pwpdf: "CS_PW.pdf", cfpdf: "CS_CF.pdf", imagePath: "assets/images/dashboard/criti_shield.svg", menuItems: ['Brochure', 'Policy Wording', 'Claim Form'] },
    { productName: 'Family Shield', isDropdownOpen: false, productCode: 23, arrowDown: true, brocpdf: "FS_BROC.pdf", pwpdf: "FS_PW.pdf", imagePath: "assets/images/dashboard/Family_Shield.svg", menuItems: ['Brochure', 'Policy Wording'] },
    { productName: 'Home Insurance', isDropdownOpen: false, productCode: 4, arrowDown: true, brocpdf: "HOME_BROC.pdf", pwpdf: "HOME_PW.pdf", imagePath: "assets/images/home_dashboard.svg", menuItems: ['Brochure', 'Policy Wording'] },
    { productName: 'International Travel', isDropdownOpen: false, productCode: 3717, arrowDown: true, brocpdf: "INT_TRAVEL_BROC.pdf", imagePath: "assets/images/internationaltravel.svg", menuItems: ['Brochure'] },
    // { productName: 'Befit Rider', arrowDown: true, brocpdf: "BR_BROC.pdf", pwpdf: "BR_PW.pdf", cfpdf: "BR_CF.pdf", faqpdf: "BR_FAQ.pdf", imagePath: "assets/images/healthProducts/CritiShield.svg", menuItems: ['Brochure', 'Policy Wording', 'Claim Form', 'FAQs'] }
  ]
  

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.subscription.push(fromEvent(window, 'scroll')
      .subscribe(() => {
        this.onWindowScroll();
      }));
    this.subscription.push(fromEvent(window, 'click')
      .subscribe((res) => {
        
          if (this.clickCounter == 0) {
            this.clickCounter++;
          } else {
            this.clickCounter = 0;
            this.productDetails.forEach((product) => {
              product.isDropdownOpen = false;
              product.arrowDown = true;
            });
          }
        
      }
      ));
  }

  onWindowScroll() {
    this.scrolling = true;

    if (window.scrollY === 0) {
      this.scrolling = false;
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  toggleDropdown(index: number): void {
    console.log("toggle");
    this.productDetails.forEach((product, i) => {
      if (i!= index && product.isDropdownOpen) {
        this.clickCounter = 0;
        product.isDropdownOpen = false;
        product.arrowDown = true;
      }
      if (i === index) {
        product.isDropdownOpen = !product.isDropdownOpen;
        product.arrowDown = !product.isDropdownOpen; // Toggle arrow direction
      }else {
        product.isDropdownOpen = false; // Close other dropdowns
        product.arrowDown = true; // Reset arrow direction for other products
      }
    });
    console.log("toggle end");
  }

  onFetchDoc(productPos: number, docPos: number) {
    let prdId = '';
    this.clickCounter = 0;
    if (this.productDetails[productPos].menuItems[docPos] == 'Brochure') {
      prdId = this.productDetails[productPos].brocpdf;
    }
    else if (this.productDetails[productPos].menuItems[docPos] == 'Policy Wording') {
      prdId = this.productDetails[productPos].pwpdf;
    }
    else if (this.productDetails[productPos].menuItems[docPos] == 'Claim Form') {
      prdId = this.productDetails[productPos].cfpdf;
    }
    else {
      prdId = this.productDetails[productPos].faqpdf;
    }
    this.downloadPdfFile1(prdId);
  }

  downloadPdfFile1(filename: string) {
    this.downloadService.downloadBrochure(filename).subscribe({
      next: (data) => {
        if (data.type == 'application/pdf') {
          const blob = new Blob([data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.addEventListener('click', () => {
            // Revoke the object URL after a short delay
            setTimeout(() => {
              URL.revokeObjectURL(link.href);
            }, 100);
          });

          link.download = filename;
          document.body.appendChild(link);
          link.click();
        }
      },
    });
  }
}