import { Component, NgZone, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription, catchError, map, throwError } from 'rxjs';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { Constants } from 'src/app/shared/constants/constants';

import { getDocDetailsRequest } from 'src/app/shared/interface/uploadDocuments';

// import { generateHtNumberRequest, generateHtNumberResponse, getDocDetailsRequest } from 'src/app/shared/interface/generatehtnumber';
// import { updatefolderindexRequest, updatefolderindexResonse ,ResponseStructure } from 'src/app/shared/interface/updatefolderindexforhtnumber';
import { PopupService } from 'src/app/shared/services/popup.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { HealthDataStorage } from '../../health.modal';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { popupButton, popupButtonAction, popupHeader, popupImgPath, popupType } from 'src/app/shared/enums/popupEnums';
import { HealthDataStorageService } from '../../health.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { environment } from 'src/environments/environment';
import { ResponseStructure } from 'src/app/shared/interface/responseStructure';
import { CreateFolerToUploadResponsePayload, CreateFolerToUploadRequestPayload } from 'src/app/shared/interface/utilityApi';
import { GenerateHtNumberResponsePayload, GenerateHtNumberRequestPayload, SubmitCaseRequestPayload } from 'src/app/shared/interface/health';
import { UpdateFolderIndexResponsePayload, UpdateFolderIndexRequestPayload } from 'src/app/shared/interface/health';
import { UtilityApiService } from 'src/app/shared/proxy-services/utility-api.service';
import { HealthService } from 'src/app/shared/proxy-services/health.service';

declare const $: any;

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent implements OnInit {
  sanitizedImageUrl: SafeUrl;
  imageArray = {};
  previewImageUrl: string = "";
  uploadImgData = [];
  selectedDoc: number;
  private subscription: Subscription[] = [];
  errorPopupData: PopupModal;
  htNumberDetails: GenerateHtNumberResponsePayload;
  createFolderDetails: CreateFolerToUploadResponsePayload;
  updateFolderIndex: UpdateFolderIndexResponsePayload;
  healthDataStorage: HealthDataStorage;
  progress: number;
  myPolicyDetails: any;
  isSuccess: boolean;
  count = 0;
  modalTitle: string;

  constructor(public router: Router,
    public domSanitization: DomSanitizer,
    private popupService: PopupService,
    public utility_service: UtilityService,
    private http: HttpClient,
    private storageService: StorageService,
    private zone: NgZone,
    private healthDataStorageService: HealthDataStorageService,
    private utilityApiService: UtilityApiService,
    private healthService: HealthService) {
  }

  ngOnInit() {
    this.healthDataStorage = this.healthDataStorageService.getHealthDataStorage();
    this.myPolicyDetails = this.storageService.getAESDecryptedDataLocal(userEnums.MyPolicyDetails)
    if (this.myPolicyDetails) {
      this.healthDataStorage.myPolicyDetails.policyId = Number(this.myPolicyDetails);
    }
    this.getDocumentMaster().then(res => {
      this.generateHtNumber();
    });
    // if (!this.utility_service.isEmptyOrNull(this.healthDataStorage.myPolicyDetails.htNumber)) {
    //   this.getDocFilesDetailsFromDMS();
    // } else {
    //   this.generateHtNumber();
    // }
    // this.uploadImgData = [

    //   { id: 1, label: 'First Year Policy', name: 'first_year_policy', type: 'radio', uploadText: false, previewIcon: false, closeIcon: false, progressBar: false, fileName: '', isManditory: true },
    //   { id: 2, label: 'Second Year Policy', name: 'second_year_policy', type: 'radio', uploadText: false, previewIcon: false, closeIcon: false, progressBar: false, fileName: '', isManditory: false },
    //   { id: 3, label: 'Third Year Policy', name: 'third_year_policy', type: 'radio', uploadText: false, previewIcon: false, closeIcon: false, progressBar: false, fileName: '', isManditory: false },
    //   { id: 4, label: 'Cheque', name: 'cheque_copy', type: 'radio', uploadText: false, previewIcon: false, closeIcon: false, progressBar: false, fileName: '', isManditory: false },
    //   { id: 5, label: 'ID Proof', name: 'id_proof', type: 'radio', uploadText: false, previewIcon: false, closeIcon: false, progressBar: false, fileName: '', isManditory: true },
    //   { id: 6, label: 'Portability Form', name: 'portability_form', type: 'radio', uploadText: false, previewIcon: false, closeIcon: false, progressBar: false, fileName: '', isManditory: false },
    //   { id: 7, label: 'Claim Discharge (If Any)', name: 'claim_discharge', type: 'radio', uploadText: false, previewIcon: false, closeIcon: false, progressBar: false, fileName: '', isManditory: false },
    //   { id: 8, label: 'Other Document', name: 'other_docs', type: 'radio', uploadText: false, previewIcon: false, closeIcon: false, progressBar: false, fileName: '', isManditory: false }
    // ]
  }

  getDocuments(docDetails) {
    let data = [];
    let obj = {};
    docDetails.forEach((x, index) => {
      obj = { id: index, label: x.label, name: x.docType, extention: x.docExtention, type: 'radio', uploadText: false, previewIcon: false, closeIcon: false, progressBar: false, fileName: '', isManditory: x.isRequired }
      data.push(obj);
    })

    return data;
  }

  getDocumentMaster(): Promise<void> {
    return new Promise((resolve) => {
      this.subscription.push(this.healthService.fetchDocumentMaster().subscribe({
        next: (res: any) => {
          if (res.StatusCode == Constants.statusCode_success) {
            this.uploadImgData = this.getDocuments(res.Data.QCDocumentDetails);
            resolve(res.Data);
          } else {
            // this.errorPopupData = this.popupService.fetchErrorPopupMsg(res.StatusMsg);
            // this.popupService.openGenericPopup(this.errorPopupData);
          }
        },
        error: error => {
          // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
          // this.popupService.openGenericPopup(this.errorPopupData);
        }
      }))
    })
  }

  selectDocument(data, event, index, typ: any) {
    let file = event.target.files[0];
    let extention = file.name.split('.')[1];
    if (extention.toLowerCase() == 'pdf' || extention.toLowerCase() == 'jpg' || extention.toLowerCase() == 'png') {
      this.imageArray[typ] = event.target.files[0];
      this.uploadImgData[index]['uploadText'] = false;
      this.uploadImgData[index]['previewIcon'] = true;
      this.uploadImgData[index]['closeIcon'] = true;
      this.uploadImgData[index]['progressBar'] = true;
      this.uploadImgData[index]['fileName'] = `${file.name}`;
      this.uploadImgData[index]['extention'] = extention.toLowerCase();
      this.uploadImgData[index]['file'] = event.target.files[0];
      this.dmsDocUpload(data, index, event);
    } else {
      this.errorPopupData = this.popupService.fetchErrorPopupMsg('Unsupported file type');
      this.popupService.openGenericPopup(this.errorPopupData);
    }
  }

  changeUploadType(index) {
    this.uploadImgData[index]['uploadText'] = true;
  }

  cancelUpload(index) {
    this.uploadImgData[index]['uploadText'] = true;
    this.uploadImgData[index]['previewIcon'] = false;
    this.uploadImgData[index]['closeIcon'] = false;
    this.uploadImgData[index]['progressBar'] = false;
    this.uploadImgData[index]['fileName'] = '';
    this.uploadImgData[index]['extention'] = '';
  }

  previewImage(i) {

    let file = this.uploadImgData[i].file;
    let type = file['type'];
    this.previewImageUrl = URL.createObjectURL(file);
    if (type != 'application/pdf') {
      this.modalTitle = this.uploadImgData[i].label
      $('#exampleModal').modal('show');
    } else {
      window.open(this.previewImageUrl, "_blank");
    }

  }

  constructgeneratehtnumberRequest(): GenerateHtNumberRequestPayload {
    let request: GenerateHtNumberRequestPayload = {
      "PolicyId": this.healthDataStorage.myPolicyDetails.policyId.toString()//'15164155'
    }
    return request;
  }

  constructDocFilesDetailsRequest() {
    let request: getDocDetailsRequest = {
      "AppName": "HEALTHPORT",
      "FolderIndex": null,
      "FolderName": this.healthDataStorage.myPolicyDetails.htNumber,
      "searchFolder": false
    }
    return request;
  }

  getDocFilesDetailsFromDMS() {
    let request = this.constructDocFilesDetailsRequest()
    this.subscription.push(this.utilityApiService.docFilesDetailsFromDMS(request).subscribe({
      next: (res: any) => {
        if (res.StatusCode == Constants.statusCode_success) {
          this.createFolderDetails = res.Data.folderDocDetails[0];
        } else {
          // this.errorPopupData = this.popupService.fetchErrorPopupMsg(res.StatusMsg);
          // this.popupService.openGenericPopup(this.errorPopupData);
        }
      },
      error: error => {
        // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
        // this.popupService.openGenericPopup(this.errorPopupData);
      }
    }))
  }

  generateHtNumber() {
    let request = this.constructgeneratehtnumberRequest()
    this.subscription.push(this.healthService.generateHtNumber(request).subscribe({
      next: (res: any) => {
        if (res.StatusCode == Constants.statusCode_success) {
          this.htNumberDetails = res.Data;
          console.log('generate ht number ', res.Data)

          // this.createFolerToUpload();
        } else {

          // this.errorPopupData = this.popupService.fetchErrorPopupMsg(res.StatusMsg);
          // this.popupService.openGenericPopup(this.errorPopupData);
        }
      },
      error: error => {

        // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
        // this.popupService.openGenericPopup(this.errorPopupData);
      }
    }))
  }

  constructCreateFolerToUploadRequest(): CreateFolerToUploadRequestPayload {
    let request: CreateFolerToUploadRequestPayload = {
      "AppName": "HEALTHPORT",
      "FolderIndex": null,
      "FolderName": this.htNumberDetails?.HtNumber,
      "searchFolder": false
    }
    return request;
  }

  createFolerToUpload() {
    let folderIndexReq = this.constructCreateFolerToUploadRequest()

    this.subscription.push(this.utilityApiService.createFolerToUpload(folderIndexReq).subscribe({
      next: (res: any) => {
        if (res.StatusCode == Constants.statusCode_success) {
          this.createFolderDetails = res.Data;
        } else {
          // this.errorPopupData = this.popupService.fetchErrorPopupMsg(res.StatusMsg);
          // this.popupService.openGenericPopup(this.errorPopupData);
        }
      },
      error: error => {
        // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
        // this.popupService.openGenericPopup(this.errorPopupData);
      }
    }))
  }

  // dmsDocUpload(data: any, ev: any) {

  //   let formData = new FormData();

  //   formData.append("AppName", "HEALTHPORT");
  //   formData.append("FolderIndex", this.createFolderDetails?.FolderIndex);
  //   formData.append("DocName", `${data.name}_${new Date().getTime()}.${data.name}`);
  //   formData.append("file", data.file);

  //   this.subscription.push(this.file_Upload_service.fileUploadData3(formData).subscribe({
  //     next: res => {

  //       if (res.StatusCode == Constants.statusCode_success) {

  //       } else {

  //         this.errorPopupData = this.popupService.fetchErrorPopupMsg(res.StatusDesc);
  //         this.popupService.openGenericPopup(this.errorPopupData);
  //       }
  //     },
  //     error: error => {

  //       this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
  //       this.popupService.openGenericPopup(this.errorPopupData);
  //     }
  //   }))


  // }

  getFormDataRequest(data: any, index, ev: any) {
    let formData = new FormData();
    //old request
    // formData.append("AppName", "HEALTHPORT");
    // formData.append("FolderIndex", this.createFolderDetails?.FolderIndex);
    // formData.append("DocName", `${data.name}_${new Date().getTime()}.${data.name}`);
    // formData.append("file", data.file);

    formData.append("PolicyId", this.healthDataStorage.myPolicyDetails.policyId.toString());
    formData.append("docType", `${data.name}`);
    formData.append("file", data.file);
    return formData;
  }

  dmsDocUpload(data: any, index, ev: any) {
    // this.spinner.show();
    let formData = this.getFormDataRequest(data, index, ev);
    
    let endpoint = 'Qc/DocUpload'; //'Utility/DmsDocUpload';
    this.http
      .post(`${environment.baseURL}${endpoint}`, formData, {
        reportProgress: true,
        observe: "events"
      })
      .subscribe(event => {
        if (event instanceof HttpResponse) {
          let res = event.body as ResponseStructure;
          if (res.StatusCode != Constants.statusCode_success) {
            this.isSuccess = false;
            this.progress = null;
            this.uploadImgData[index]['previewIcon'] = false
            this.uploadImgData[index]['progress'] = null;
            this.uploadImgData[index]['fileName'] = '';
            this.uploadImgData[index]['closeIcon'] = false;
            this.errorPopupData = this.popupService.fetchErrorPopupMsg(res.StatusDesc);
            this.popupService.openGenericPopup(this.errorPopupData);
          } else {
            this.isSuccess = true;

          }

        }
        this.zone.run(() => {
          if (event.type == HttpEventType.UploadProgress) {
            this.progress = Math.round((100 / event.total) * event.loaded);
            this.uploadImgData[index]['previewIcon'] = this.progress == 100;
            this.uploadImgData[index]['progress'] = Math.round((100 / event.total) * event.loaded);
          } else if (event.type == HttpEventType.Response) {
            this.progress = null;
            this.uploadImgData[index]['progress'] = null;
          }
        })
      })
  }

  constructupdatefolderindexRequest(): UpdateFolderIndexRequestPayload {
    let request: UpdateFolderIndexRequestPayload = {
      "PolicyId": this.healthDataStorage?.myPolicyDetails?.policyId.toString(), //'15164155',
      "HtNumber": this.htNumberDetails?.HtNumber,
      "FolderIndex": this.createFolderDetails?.FolderIndex
    }
    return request;
  }

  updateFolderIndexForHT() {
    let request = this.constructupdatefolderindexRequest();
    this.subscription.push(this.healthService.updateFolderIndex(request).subscribe({
      next: res => {
        if (res.StatusCode == Constants.statusCode_success) {
          this.count++;
          if (this.count > 2) {
            let popupData: PopupModal = {
              popupType: popupType.generic,
              imgName: popupImgPath.successGif,
              header: popupHeader.success,
              description: 'Documents uploaded successfully',
              buttonLabel: popupButton.ok,
              buttonAction: popupButtonAction.dashboard
            }
            this.popupService.openGenericPopup(popupData);

          } else {
            let popupData: PopupModal = {
              popupType: popupType.uploadDox,
              imgName: popupImgPath.successGif,
              header: popupHeader.success,
              description: 'Documents uploaded successfully,Do you want to re-upload document',
              buttonLabel: popupButton.ok,
              buttonAction: popupButtonAction.dashboard
            }
            this.popupService.openGenericPopup(popupData);
          }
        } else {
          // this.errorPopupData = this.popupService.fetchErrorPopupMsg(res.StatusMsg);
          // this.popupService.openGenericPopup(this.errorPopupData);
        }
      },
      error: error => {
        // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
        // this.popupService.openGenericPopup(this.errorPopupData);
      }
    }))

  }

  finalUpload() {
    if (this.isSuccess === false) {
      this.errorPopupData = this.popupService.fetchErrorPopupMsg('Please upload all the mandatory documents');
      this.popupService.openGenericPopup(this.errorPopupData);
      return true;
    }

    let error = false;
    for (let i of this.uploadImgData) {
      if (i.isManditory && !i.fileName) {
        this.errorPopupData = this.popupService.fetchErrorPopupMsg('Please upload all the mandatory documents');
        this.popupService.openGenericPopup(this.errorPopupData);
        return true;
      }
    }
    if (!error) {
      // this.updateFolderIndexForHT();
      this.submitQcCase();
    }
    return false;
  }

  setFileName(name) {
    let filename = name.substring(0, 30);
    return filename + '...';
  }

  constructSubmitCaseRequest(): SubmitCaseRequestPayload {
    let request: SubmitCaseRequestPayload = {
      "PolicyId": this.healthDataStorage.myPolicyDetails.policyId//'15164155'
    }
    return request;
  }

  submitQcCase() {
    let request = this.constructSubmitCaseRequest();
    this.subscription.push(this.healthService.submitCase(request).subscribe({
      next: res => {
        if (res.StatusCode == Constants.statusCode_success) {
          let popupData: PopupModal = {
            popupType: popupType.uploadDox,
            imgName: popupImgPath.successGif,
            header: popupHeader.success,
            description: `Documents uploaded successfully for ${res.Data.HtNumber} ,Do you want to re-upload document`,
            buttonLabel: popupButton.ok,
            buttonAction: popupButtonAction.dashboard
          }
          this.popupService.openGenericPopup(popupData);
         
        } else {
          // this.errorPopupData = this.popupService.fetchErrorPopupMsg(res.StatusMsg);
          // this.popupService.openGenericPopup(this.errorPopupData);
        }
      },
      error: error => {
        // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
        // this.popupService.openGenericPopup(this.errorPopupData);
      }
    }))

  }

}