import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CSMaster } from '../interface/cs-master';
import { GenericService } from '../services/generic.service';
import { HealthAilmentResponsePayload } from '../interface/critiShield';
import { ZeroTatRelationshipResponsePayload } from '../interface/health';

@Injectable({
  providedIn: 'root'
})
export class CritiShieldService {

  constructor(private http: HttpClient,
    private generic: GenericService) {
    
   }

  readonly baseURL = `${environment.baseURL}CritiShield/`
  readonly masterURL = 'MasterData'
  readonly SaveEditQuote = 'SaveEditQuote';
  readonly zerotatped ='ZeroTatPed';
  readonly zeroTatRelationship = 'ZeroTatRelationship'

  getMasterData(): Observable<CSMaster> {
    return this.http.post<CSMaster>(`${this.baseURL}${this.masterURL}`, '');
  }

  saveEditQuoteData(data): Observable<any> {
    const endpoint = `${this.baseURL}${this.SaveEditQuote}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  ZeroTatPed():Observable<HealthAilmentResponsePayload>{
    const endpoint =`${this.baseURL}${this.zerotatped}`;
    return this.generic.genericService<HealthAilmentResponsePayload>(endpoint);
  }

  ZeroTatRelationship():Observable<ZeroTatRelationshipResponsePayload>{
    const endpoint =`${this.baseURL}${this.zeroTatRelationship}`;
    return this.generic.genericService<ZeroTatRelationshipResponsePayload>(endpoint);
  }
}
