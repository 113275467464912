<header class="container-fluid fixed-top">
    <div class="row">
        <div class="container">
            <div class="row">
                <div class="col-6 dash_humberger">
                    <h1>
                        <a title="ICICI Lombard" class="logo">ICICI Lombard</a>
                    </h1>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="errorMessageMainDiv" *ngIf="errorOccured">
    <div class="errorImg">
        <img src="../../../../assets/images/errorimg.gif" alt="">
    </div>
    <div class="errorMessagediv">
        <span class="notFnd">Page Not Found</span>
        <strong>Oh No! Error 404</strong>
        <!-- <span class="lowermsg">Sorry, we're not able to find what you were looking for</span> -->
        <span class="lowermsg">Oops! your page could not be found. Take a deep breath and relax</span>
        <button class="primary-button text-center errorbtn" (click)="goToHome()">Back to home</button>
    </div>
</div>