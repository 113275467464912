export enum PolicyType
{
    Motor = 1,
    Health = 2,
    Travel = 3,
    Home = 4,  
}

 export  enum HealthPolicyType
    {
        None = -1,
        FamilyFloater = 1,
        CriticalCare = 2,
        HealthAdvantagePlus = 3, 
        HEALTH_ADVANTEDGE_PLUS = 42,
        //  HEALTH_GOLDEN_SHIELD = 40,
        //  HEALTH_Elite_Plus = 40
        
    }

    export enum PendingFilterType
{
     ALL = 1,
     PENDING = 2,
     FAILED = 3,
     INSTA_BY_DATE = 4,
     INSTA_ALL = 5,
     INSTANTCOVERNOTE = 6
}

export enum TravelPolicyType
{
    None = -1,
    OverseasRoundTrip = 1,
    OverseasMultiTrip = 2,
    StudentMedical = 3,
    //Added By Soumita -IT
    InternationalRoundTrip = 4,
    InternationalMultiTrip = 5
}

export enum MotorPolicyType
    {
        None = -1,
        TwoWheeler = 1,
        PrivateCar = 2,
        GCV = 3,
        PCV = 4,
        Misc = 5,
    }

    export enum HomePolicyType
    {
        None = -1,
        Home = 1,
        HomeStructure = 2,
        HomeContent = 3,
        HomeStructureContent = 4,
        HomeLossofRentANDAltAccADDON = 5,
    }
    export enum AllRiskPolicyType
    {
        MOBILE = 1
    }
