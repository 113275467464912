
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-payment-link-declarations',
  templateUrl: './payment-link-declarations.component.html',
  styleUrls: ['./payment-link-declarations.component.scss']
})

export class PaymentLinkDeclarationsComponent {
  url:string = "@icicilombard.com";
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any, private dialog: MatDialog) {
  }

  close(): void {
    this.dialog.closeAll();
  }
}
