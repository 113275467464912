import { Injectable } from "@angular/core";
import { UtilityService } from "./utility.service";
import { healthMembers } from "../enums/healthEnums";

@Injectable({
    providedIn: 'root'
})
export class RenewalInsuredDateService {
    insuredMinMaxDate: { maxDate: Date; minDate: Date; };

    constructor(public utilityService: UtilityService,) { }

    initialDate() {
        let depChildMaxDate = this.utilityService.subtractMonths(new Date(), 0);

        let yearDate = this.utilityService.subtractYears(new Date(), 100);
        let monthDate = this.utilityService.subtractMonths(yearDate, 11);
        let depChildMinDate = this.utilityService.subtractDays(monthDate, 25);

        return {
            minDate: depChildMinDate,
            maxDate: depChildMaxDate
        }
    }
    hapInsuredDate(memberType, noOfAdult) {
        if (memberType == 'Adult') {
            let adultMaxDate = this.utilityService.subtractYears(new Date(), 18);

            let yearsdate = this.utilityService.subtractYears(new Date(), 65);
            let monthdate = this.utilityService.subtractMonths(yearsdate, 11);
            let adultMinDate = this.utilityService.subtractDays(monthdate, 25);

            return {
                minDate: adultMinDate,
                maxDate: adultMaxDate
            }
        }
        else {
            if (noOfAdult > 0) {
                //depedent child
                let depChildMaxDate = this.utilityService.subtractMonths(new Date(), 3);

                let yearDate = this.utilityService.subtractYears(new Date(), 25);
                let monthDate = this.utilityService.subtractMonths(yearDate, 11);
                let depChildMinDate = this.utilityService.subtractDays(monthDate, 25);

                return {
                    minDate: depChildMinDate,
                    maxDate: depChildMaxDate
                };
            }
            else {
                //independent child
                let indChildMaxDate = this.utilityService.subtractYears(new Date(), 6);

                let yearDate = this.utilityService.subtractYears(new Date(), 25);
                let monthDate = this.utilityService.subtractMonths(yearDate, 11);
                let indChildMinDate = this.utilityService.subtractDays(monthDate, 25);

                return {
                    minDate: indChildMinDate,
                    maxDate: indChildMaxDate
                }
            }
        }
    }

    hapRefilingInsuredDate(memberType, noOfAdult, relationshipName) {
        let data: any;
        if (memberType == 'Adult') {
            let adultMaxDate = this.utilityService.subtractYears(new Date(), 18);

            let yearsdate = this.utilityService.subtractYears(new Date(), 65);
            let monthdate = this.utilityService.subtractMonths(yearsdate, 11);
            let adultMinDate = this.utilityService.subtractDays(monthdate, 25);

            data = {
                minDate: adultMinDate,
                maxDate: adultMaxDate
            }
        }
        if (memberType == 'Child') {
            if (['son', 'daughter', 'employee son', 'employee daughter'].includes(relationshipName)) {
                //depedent child
                let depChildMaxDate = this.utilityService.subtractYears(new Date(), 6);

                let yearDate = this.utilityService.subtractYears(new Date(), 25);
                let monthDate = this.utilityService.subtractMonths(yearDate, 11);
                let depChildMinDate = this.utilityService.subtractDays(monthDate, 25);

                data = {
                    minDate: depChildMinDate,
                    maxDate: depChildMaxDate
                }
            } else {
                let indChildMaxDate = this.utilityService.subtractYears(new Date(), 6);
                let yearDate = this.utilityService.subtractYears(new Date(), 17);
                let monthDate = this.utilityService.subtractMonths(yearDate, 11);
                let indChildMinDate = this.utilityService.subtractDays(monthDate, 25);

                data = {
                    minDate: indChildMinDate,
                    maxDate: indChildMaxDate
                }
            }
        }
        // else {
        if (noOfAdult > 0) {
            if (memberType == 'Child') {
                if (['son', 'daughter', 'employee son', 'employee daughter'].includes(relationshipName)) {
                    //depedent child
                    let depChildMaxDate = this.utilityService.subtractMonths(new Date(), 3);
                    let yearDate = this.utilityService.subtractYears(new Date(), 25);
                    let monthDate = this.utilityService.subtractMonths(yearDate, 11);
                    let depChildMinDate = this.utilityService.subtractDays(monthDate, 25);

                    data = {
                        minDate: depChildMinDate,
                        maxDate: depChildMaxDate
                    }
                }
                //independent child 
                else {
                    let indChildMaxDate = this.utilityService.subtractMonths(new Date(), 3);
                    let yearDate = this.utilityService.subtractYears(new Date(), 17);
                    let monthDate = this.utilityService.subtractMonths(yearDate, 11);
                    let indChildMinDate = this.utilityService.subtractDays(monthDate, 25);

                    data = {
                        minDate: indChildMinDate,
                        maxDate: indChildMaxDate
                    }
                }
            }
        }
        return data;
    }

    gsInsuredDate() {
        let adultMaxDate = this.utilityService.subtractYears(new Date(), 56);

        let yearsdate = this.utilityService.subtractYears(new Date(), 85);
        let monthdate = this.utilityService.subtractMonths(yearsdate, 11);
        let adultMinDate = this.utilityService.subtractDays(monthdate, 22);
        return {
            minDate: adultMinDate,
            maxDate: adultMaxDate
        }
    }

    aspInsuredDate(memberType, noOfAdult) {
        if (memberType == 'Adult') {
            let adultMaxDate = this.utilityService.subtractYears(new Date(), 21);

            let yearsdate = this.utilityService.subtractYears(new Date(), 65);
            let monthdate = this.utilityService.subtractMonths(yearsdate, 11);
            let adultMinDate = this.utilityService.subtractDays(monthdate, 25);

            return {
                minDate: adultMinDate,
                maxDate: adultMaxDate
            }
        }
        else {
            if (noOfAdult > 0) {
                //depedent child
                let depChildMaxDate = this.utilityService.subtractMonths(new Date(), 3);

                let yearDate = this.utilityService.subtractYears(new Date(), 20);
                let monthDate = this.utilityService.subtractMonths(yearDate, 11);
                let depChildMinDate = this.utilityService.subtractDays(monthDate, 25);

                return {
                    minDate: depChildMinDate,
                    maxDate: depChildMaxDate
                }
            }
            else {
                //independent child
                let indChildMaxDate = this.utilityService.subtractYears(new Date(), 6);

                let yearDate = this.utilityService.subtractYears(new Date(), 20);
                let monthDate = this.utilityService.subtractMonths(yearDate, 11);
                let indChildMinDate = this.utilityService.subtractDays(monthDate, 25);

                return {
                    minDate: indChildMinDate,
                    maxDate: indChildMaxDate
                }
            }
        }
    }

    hbInsuredDate(memberType, noOfAdult) {
        if (memberType == 'Adult') {
            let adultMaxDate = this.utilityService.subtractYears(new Date(), 21);
            //changes on age
            let yearsdate = this.utilityService.subtractYears(new Date(), 100);
            let monthdate = this.utilityService.subtractMonths(yearsdate, 11);
            let adultMinDate = this.utilityService.subtractDays(monthdate, 25);

            return {
                minDate: adultMinDate,
                maxDate: adultMaxDate
            }
        }
        else {
            if (noOfAdult > 0) {
                //depedent child
                let depChildMaxDate = this.utilityService.subtractMonths(new Date(), 3);

                let yearDate = this.utilityService.subtractYears(new Date(), 20);
                let monthDate = this.utilityService.subtractMonths(yearDate, 11);
                let depChildMinDate = this.utilityService.subtractDays(monthDate, 25);

                return {
                    minDate: depChildMinDate,
                    maxDate: depChildMaxDate
                }
            }
            else {
                //independent child
                let indChildMaxDate = this.utilityService.subtractYears(new Date(), 6);

                let yearDate = this.utilityService.subtractYears(new Date(), 20);
                let monthDate = this.utilityService.subtractMonths(yearDate, 11);
                let indChildMinDate = this.utilityService.subtractDays(monthDate, 25);

                return {
                    minDate: indChildMinDate,
                    maxDate: indChildMaxDate
                }
            }
        }
    }

    maxInsuredDate(memberType: string, adultLength: number) {
        if (memberType == 'Adult') {
            let adultMaxDate = this.utilityService.subtractYears(new Date(), 21);

            let yearsdate = this.utilityService.subtractYears(new Date(), 65);
            let monthdate = this.utilityService.subtractMonths(yearsdate, 11);
            let adultMinDate = this.utilityService.subtractDays(monthdate, 25);

            return {
                minDate: adultMinDate,
                maxDate: adultMaxDate
            }
        }
        else {
            if (adultLength > 0) {
                //depedent child
                let depChildMaxDate = this.utilityService.subtractDays(new Date(), 91);

                let yearDate = this.utilityService.subtractYears(new Date(), 20);
                let monthDate = this.utilityService.subtractMonths(yearDate, 11);
                let depChildMinDate = this.utilityService.subtractDays(monthDate, 25);

                return {
                    minDate: depChildMinDate,
                    maxDate: depChildMaxDate
                }
            }
            else {
                //independent child
                let indChildMaxDate = this.utilityService.subtractYears(new Date(), 6);

                let yearDate = this.utilityService.subtractYears(new Date(), 25);
                let monthDate = this.utilityService.subtractMonths(yearDate, 11);
                let indChildMinDate = this.utilityService.subtractDays(monthDate, 29);

                return {
                    minDate: indChildMinDate,
                    maxDate: indChildMaxDate
                }
            }
        }
    }

    hepInsuredDate(memberType, noOfAdult) {
        if (memberType == 'Adult') {
            let adultMaxDate = this.utilityService.subtractYears(new Date(), 21);

            let yearsdate = this.utilityService.subtractYears(new Date(), 99);
            let monthdate = this.utilityService.subtractMonths(yearsdate, 11);
            let adultMinDate = this.utilityService.subtractDays(monthdate, 25);

            return {
                minDate: adultMinDate,
                maxDate: adultMaxDate
            }
        }
        else {
            if (noOfAdult > 0) {
                //depedent child
                let depChildMaxDate = this.utilityService.subtractMonths(new Date(), 3);

                let yearDate = this.utilityService.subtractYears(new Date(), 20);
                let monthDate = this.utilityService.subtractMonths(yearDate, 11);
                let depChildMinDate = this.utilityService.subtractDays(monthDate, 25);

                return {
                    minDate: depChildMinDate,
                    maxDate: depChildMaxDate
                }
            }
            else {
                //independent child
                let indChildMaxDate = this.utilityService.subtractYears(new Date(), 6);

                let yearDate = this.utilityService.subtractYears(new Date(), 20);
                let monthDate = this.utilityService.subtractMonths(yearDate, 11);
                let indChildMinDate = this.utilityService.subtractDays(monthDate, 25);

                return {
                    minDate: indChildMinDate,
                    maxDate: indChildMaxDate
                }
            }
        }
    }
}