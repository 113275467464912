<div class="container-fluid">
    <div class="row">
        <div class="container panaMaincontainer">
            <div class="mainDiv">
                <div class="steppermainDiv">
                    <!-- Pay in slips container Starts here -->
                    <div class="pid marginbox">
                        <div class="myTransactionTitle">
                            <span class="cstmstep"><img src="../../../../../assets/images/MyTransactionIcon/PID.svg"
                                    alt=""></span>
                            <span class="cstmstep">
                                <h3>Payment ID</h3>
                            </span>
                        </div>
                        <!-- <mat-tab-group class="pendingTabs"> -->
                        <!-- Search container Starts here -->
                        <mat-card class="innerbox px-3 pb-2 borderClass" style="border-radius: 15px;">
                            <div class="row">
                                <div class="col-sm-3 form-group search-btn-div">
                                    <mat-label class="input-group__label" for="myInput">Product Name</mat-label>
                                    <mat-select class="input-group__input cstm_select" [(ngModel)]="productName" (selectionChange)="onProductChange($event)">
                                        <ng-container *ngFor="let dropDownData of productNameOptions; let last = last">
                                            <mat-option [value]="dropDownData.value"
                                                *ngIf="dropDownData.isVisible">{{dropDownData.label}}</mat-option>
                                            <mat-divider *ngIf="!last"></mat-divider>
                                        </ng-container>
                                    </mat-select>
                                </div>

                                <ng-container *ngIf="productName === 'health'">
                                    <div class="col-sm-3 form-group search-btn-div" style="max-height: 60px">
                                        <mat-label class="input-group__label">Customer Name/PF Customer ID
                                        </mat-label>
                                        <input class="input-group__input" [formControl]="searchText"
                                            placeholder="Customer Name/PF Customer ID">
                                        <mat-error class="d-flex" style="width: 300px"
                                            *ngIf="searchText.invalid && searchText.touched">Enter Customer
                                            Name/PF Customer ID</mat-error>
                                    </div>
                                    <div class="col-sm-3 form-group search-btn-div" style="max-height: 60px">
                                        <mat-label class="input-group__label">Customer Email/Customer Mobile No.
                                        </mat-label>
                                        <input class="input-group__input" [formControl]="searchEmailMobile"
                                            placeholder="Customer Email/Customer Mobile No.">
                                        <mat-error *ngIf="searchEmailMobile.invalid && searchEmailMobile.touched">Please
                                            enter Customer
                                            Email/Mobile No.</mat-error>
                                    </div>
                                </ng-container>
                               
                                <div class="col-sm-3 form-group d-lg-block d-none search-btn-div"
                                    style="margin-bottom: 0;" *ngIf="productName ==='health'">
                                    <button class="search-button" (click)="onSearch()">
                                        <img src="../../../assets/images/Page-1.svg" alt="" style="width: 18px"><span
                                            class="pl-1">Search</span>
                                    </button>
                                </div>
                            </div>
                        </mat-card>
                        <!-- </mat-tab-group> -->
                        <!-- Search container Ends here -->
                        <!-- Data Container Starts here -->
                        <div class="scrollcontainer">
                            <div class="scrollRow">
                                <div class="tabInnerdiv innerTabs mt-3" *ngIf='customerList?.length == 0 && productName === "health"'>
                                    <h4 class="text-center mb-0"> No Record Found</h4>
                                </div>
                                <div class="tabInnerdiv innerTabs mt-3 hoverDiv"
                                    *ngFor="let item of customerList; let i = index">
                                    <div class="fieldsdiv">
                                        <div class="row customDiv" (click)="selectCustomer(item)">
                                            <div class="col-sm-2 form-group ">
                                                <mat-label class="mtlabel" for="myInput">PF Customer ID</mat-label>
                                                <p>{{item.PfCustomerId}}</p>
                                            </div>
                                            <div class="col-sm-3 form-group ">
                                                <mat-label class="mtlabel" for="myInput">Customer Name</mat-label>
                                                <p>{{item.CustomerName}}</p>
                                            </div>
                                            <div class="col-sm-4 form-group">
                                                <mat-label class="mtlabel" for="myInput">Customer Email</mat-label>
                                                <p>{{item.CustomerEmail}}</p>
                                            </div>
                                            <div class="col-sm-3 form-group ">
                                                <mat-label class="mtlabel" for="myInput">Customer Mobile</mat-label>
                                                <p>{{item.CustomerMobile}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tabInnerdiv innerTabs mt-3" *ngIf='IntermediaryDataList?.length == 0 && productName === "travel" '>
                                    <h4 class="text-center mb-0"> No Record Found</h4>
                                </div>
                                <div class="tabInnerdiv innerTabs mt-3 hoverDiv"
                                    *ngFor="let item of IntermediaryDataList; let i = index">
                                    <div class="fieldsdiv">
                                        <div class="row customDiv" (click)="selectTravelCustomer(item)">
                                            <div class="col-sm-2 form-group ">
                                                <mat-label class="mtlabel" for="myInput">Intermediary Cd</mat-label>
                                                <p>{{item.Intermediary_cd}}</p>
                                            </div>
                                            <div class="col-sm-3 form-group ">
                                                <mat-label class="mtlabel" for="myInput">Name</mat-label>
                                                <p>{{item.Name}}</p>
                                            </div>
                                            <div class="col-sm-4 form-group">
                                                <mat-label class="mtlabel" for="myInput">Email</mat-label>
                                                <p>{{item.Email}}</p>
                                            </div>
                                            <div class="col-sm-3 form-group ">
                                                <mat-label class="mtlabel" for="myInput">Mobile</mat-label>
                                                <p>{{item.Mobile}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Data Container ends here -->
                    </div>
                    <!-- Saved Quotes Ends here -->
                </div>
            </div>
            <div class="footer d-lg-none borderStyling">
                <div class="col-sm-12 text-right">
                    <button mat-raised-button class="primary-button btn-lg btn-block" style="min-width: 140px;"
                        (click)="onSearch()">Search</button>
                </div>
            </div>
        </div>
    </div>
</div>