import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from "rxjs";
import { HealthAilmentResponseStructure, InsuredRelationship } from 'src/app/shared/interface/healthMaster';
import { Constants } from 'src/app/shared/constants/constants';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { fieldTypeDetails, insuredControls, insuredControls1 } from 'src/app/shared/enums/healthEnums';
import travelInsuredControls from "src/assets/json/travel-insured-details.json";
import { FormFields } from 'src/app/shared/interface/formFieldsModal';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { PopupService } from 'src/app/shared/services/popup.service';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomStepperService } from 'src/app/shared/services/custom-stepper.service';
import { EmitButtonData } from 'src/app/shared/interface/custom-footer';
import { CompanyDetails } from "src/app/shared/interface/health";
import { Component, OnInit, Output, EventEmitter, ViewChild, Input, ElementRef, Renderer2, HostListener, ViewChildren, QueryList } from '@angular/core';
import { NewAddOnCoverDetails } from 'src/app/shared/interface/renewal';
import { HAPModal } from '../../../products/hap/hap-modal';
import { TravelDataStorageService } from '../travel.service';
import { TravelDataStorage } from '../travel.modal';
import { TravelService } from 'src/app/shared/services/travel.service';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-travel-insured-details',
  templateUrl: './travel-insured-details.component.html',
  styleUrls: ['./travel-insured-details.component.scss']
})
export class TravelInsuredDetailsComponent implements OnInit {
  @Input() renewalDetailData: any;
  @Output() InsuredvalueEmitter = new EventEmitter<{ FormValid: boolean, stepChange: boolean }>();
  insuredControls = [];
  travelFormData: TravelDataStorage;
  buttonData = {};
  existingDisease: HealthAilmentResponseStructure[] = [];
  insuredDetailsForm: FormGroup;
  private subscription: Subscription[] = [];
  totalData = [];
  isSubmit: boolean = false;
  showAdultTabs: boolean = false;
  showChildTabs: boolean = false;
  serviceError: boolean = false;
  singleMemberCategory: boolean = false;
  memberDataIndex: number = 0;
  patchingDone: boolean = false;
  keyDownListener: () => void;
  tabGroup = [];
  @ViewChild('insuredPanel') insuredPanel: MatExpansionPanel;
  errorPopupData: PopupModal;
  datepickerMobile: boolean = false;
  relationshipDetails: InsuredRelationship[];
  nomineeRelationshipMaster = [];
  productType: string = '';
  loadFormContent: boolean = false;

  constructor(private fb: FormBuilder,
    private travelDataStorageService: TravelDataStorageService,
    public utilityService: UtilityService,
    private popupService: PopupService,
    private el: ElementRef,
    private snackBar: MatSnackBar,
    private customStepperService: CustomStepperService,
    private renderer: Renderer2,
    private travelService: TravelService,
    private storageService: StorageService) { }

  ngOnInit() {
    this.popupService.closeAllDialog(true);
    setTimeout(() => {
      this.popupService.closeAllDialog(false);
    }, 0);
    this.onLoadSteps();
    if (window.innerWidth < 768) {
      this.datepickerMobile = true;
    } else {
      this.datepickerMobile = false;
    }
    // this.insuredDetailsForm.valueChanges.subscribe(data => {
    //   if (this.patchingDone && this.travelFormData.insuredDataSet) {
    //     this.customStepperService.emitStepData({ status: false, buttonId: 'f3' });
    //   }
    // })
  }

  onLoadSteps() {
    this.productType = this.storageService.getAESDecryptedData(userEnums.ProductType);
    this.fetchNomineeRelationshipMaster().then(res => {
      this.travelFormData = this.travelDataStorageService.getTravelDataStorage();
      this.constructMemberDetails();
      this.fetchInsuredControls();
    });
  }

  changeFieldStructure(fieldType: string, controlsData: FormFields) {
    let controls = { ...controlsData };
    switch (fieldType) {
      case fieldTypeDetails.input: {
        controls.type = fieldTypeDetails.input;
        controls.disabled = true;
        controls.inputType = "text";
        controls.maxLength = "";
        controls.maxNumber = "";
        controls.minNumber = "";
        controls.onlyAlphabets = false;
        controls.onlyNumbers = false;
        delete controls.value
        break;
      }
      case fieldTypeDetails.dropdown: {
        controls.value = [];
        controls.type = fieldTypeDetails.dropdown;
        controls.disabled = false;
        delete controls.maxLength;
        delete controls.maxNumber;
        delete controls.minNumber;
        delete controls.onlyAlphabets;
        delete controls.onlyNumbers;
        delete controls.inputType
        break;
      }
      case fieldTypeDetails.datepicker: {
        let applicantMaxYear = new Date().getFullYear() - 18;
        let tempDate = this.utilityService.getMinMaxDate(applicantMaxYear);
        controls['maxDate'] = tempDate.maxDate;
        controls['type'] = 'datepicker';
        delete controls['inputType'];
        delete controls['maxLength'];
        delete controls['maxNumber'];
        delete controls['minNumber'];
        delete controls['onlyAlphabets'];
        delete controls['onlyNumbers'];
        break;
      }
      default: {
        break;
      }
    }
    return controls;
  }

  constructMemberDetails() {
    this.tabGroup = [];
    if (this.travelFormData.quoteFormData.adultDetails.length > 0) {
      this.tabGroup.push({
        'tabLabel': 'Adult',
        'disabled': false,
        'hide': false,
        'selected': 0,
        'imgPath': "../../../../assets/icons/adults.png",
        'memberDetails': []
      })
      this.travelFormData.quoteFormData.adultDetails.forEach((members, index) => {
        this.tabGroup[0]['memberDetails'].push({
          'label': 'Adult ' + (index + 1),
          'selected': index == 0 ? true : false,
          'hide': false
        })
      })
      this.memberChanged('Adult', 0);
      this.showAdultTabs = true;
      this.showChildTabs = false;
    }
    if (this.travelFormData.quoteFormData.childDetails.length > 0) {
      let childTabIndex = this.travelFormData.quoteFormData.adultDetails.length > 0 ? 1 : 0
      this.tabGroup.push({
        'tabLabel': 'Child',
        'disabled': false,
        'hide': false,
        'selected': 0,
        'imgPath': "../../../../assets/icons/child1.png",
        'memberDetails': []
      });
      this.travelFormData.quoteFormData.childDetails.forEach((members, index) => {
        this.tabGroup[childTabIndex]['memberDetails'].push({
          'label': 'Child ' + (index + 1),
          'selected': index == 0 ? true : false,
          'hide': false
        })
      })
    }


    this.totalData = this.travelFormData.quoteFormData['totalMemberData'];
    if (this.travelFormData.quoteFormData.childDetails.length > 0 && this.travelFormData.quoteFormData.adultDetails.length == 0) {
      this.memberChanged('Child', 0);
      this.showChildTabs = true;
      this.showAdultTabs = false;
    }

    // Use below flag to hide label if there is only one category present either adult or child
    this.singleMemberCategory = this.travelFormData.quoteFormData.adultDetails.length == 0 || this.travelFormData.quoteFormData.childDetails.length == 0 ? true : false;
  }

  visibilityOfControls(type, data) {
    data.forEach(control => {
      if (type == 'International Travel') {
        if (control['controlName'] == "nomineeName" || control['controlName'] == "relationshipWithNominee") {
          control['hide'] = false;
        }
        if (control['controlName'] == "motherName") {
          control['hide'] = true;
        }
      } else {
        if (control['controlName'] == "nomineeName" || control['controlName'] == "relationshipWithNominee") {
          control['hide'] = true;
        }
        if (control['controlName'] == "motherName") {
          control['hide'] = false;
        }
      }
    })

    return data.filter(x => x.hide == false);
  }

  // below api responsible to fetch all controls
  fetchInsuredControls() {
    this.insuredControls = this.visibilityOfControls(this.travelFormData?.quoteFormData?.productName, travelInsuredControls.travelInsuredControlsData);
    this.buttonData = travelInsuredControls.buttonData;
    if (this.totalData.length > 0) {
      this.totalData.forEach((data, index) => {
        this.totalData[index].controls = this.insuredControls;
      })
    }
    this.constructInsuredForm();
    this.createMemberDetails(this.insuredControls);
  }

  constructInsuredForm() {
    this.insuredDetailsForm = this.fb.group({
      memberDetails: this.fb.array([])
      // preExistingDisease: this.fb.array([]),
      // addonDetail: this.fb.array([])
    })
  }

  get memberDetails(): FormArray {
    return this.insuredDetailsForm.get('memberDetails') as FormArray;
  }

  get insured(): FormArray {
    return this.insuredDetailsForm.get('insured') as FormArray;
  }

  // insuredDisease(index: number): FormArray {
  //   return this.memberDetails.at(index).get('controls') as FormArray;
  // }

  insuredControlDetails(index: number): FormArray {
    return this.memberDetails.at(index).get('controls') as FormArray;
  }

  createMemberDetails(controls) {
    this.memberDetails.clear();
    let membersData = [...this.travelFormData.quoteFormData.adultDetails, ...this.travelFormData.quoteFormData.childDetails]
    membersData.forEach((member, index) => {
      let formControl = { controls: this.fb.array([]) }
      this.memberDetails.push(this.fb.group(formControl));
      this.createControls(controls, member, index).then(res => {
        this.patchingDone = true;
        this.loadFormContent = true;
      });
    })
    this.insuredDetailsForm.valueChanges.subscribe(data => {
      if (this.patchingDone && this.travelFormData.insuredDataSet) {
        this.customStepperService.emitStepData({ status: false, buttonId: 'f3' });
      }
    })
  }

  createControls(controls, member, index): Promise<void> {
    return new Promise((resolve) => {
      let final = this.insuredControlDetails(index);
      let insuredFormData = this.travelDataStorageService.getTravelDataStorage().insuredFormData;
      let isInsuredDataSet = this.travelDataStorageService.getTravelDataStorage().insuredDataSet;
      let finalControls = isInsuredDataSet ? insuredFormData.insuredMemberDetails[index]['controls'] : controls;
      finalControls.forEach((formFields, controlsIndex) => {
        let formControlValue;
        let formControls = {};
        let disabled = false;
        if (!isInsuredDataSet) {
          if (formFields.controlName == insuredControls.applicantRelationship) {
            formControlValue = [{ value: member['RelationshipwithApplicant'], disabled: true }, Validators.required];
          } else if (formFields.controlName == insuredControls.dob) {
            formControlValue = [{ value: member['DOB'], disabled: true }, Validators.required];
          } else {
            formControlValue = isInsuredDataSet ? '' : '';
          }
          disabled = (formFields.controlName == insuredControls.applicantRelationship || formFields.controlName == insuredControls.dob) ? true : false;
        }
        formControls['controlName'] = isInsuredDataSet ? formFields['controlName'] : formFields.controlName;
        let finalValue = isInsuredDataSet ? formFields[formFields['controlName']] :
          ((formFields.controlName == insuredControls.applicantRelationship || formFields.controlName == insuredControls.dob)) ? formControlValue[0].value : formControlValue;
        formControls[formFields.controlName] = [finalValue, Validators.required];
        formControls['label'] = [isInsuredDataSet ? formFields['label'] : formFields.label];
        formControls['type'] = [isInsuredDataSet ? formFields['type'] : formFields.type];
        formControls['placeholder'] = [isInsuredDataSet ? formFields['placeholder'] : formFields.placeholder];
        formControls['disabled'] = [isInsuredDataSet ? formFields['disabled'] : disabled];
        formControls['id'] = [isInsuredDataSet ? formFields['id'] : `${formFields.controlName}_${controlsIndex}_${formFields.id}`];
        if (formFields.type == 'input') {
          formControls['inputType'] = [isInsuredDataSet ? formFields['inputType'] : formFields.inputType];
          formControls['maxLength'] = [isInsuredDataSet ? formFields['maxLength'] : formFields.maxLength];
          formControls['maxNumber'] = [isInsuredDataSet ? formFields['maxNumber'] : formFields.maxNumber];
          formControls['minNumber'] = [isInsuredDataSet ? formFields['minNumber'] : formFields.minNumber];
          formControls['onlyAlphabets'] = [isInsuredDataSet ? formFields['onlyAlphabets'] : formFields.onlyAlphabets];
          formControls['onlyNumbers'] = [isInsuredDataSet ? formFields['onlyNumbers'] : formFields.onlyNumbers];
        }
        if (formFields.type == 'dropdown') {
          let title;
          if (formControls['controlName'] == 'title') {
            if (member.gender == 'MF') {
              title = formFields.value;
            } else {
              title = formFields.value.filter(x => x.gender == member.gender || x.gender == 'MF');
            }
            formControls['value'] = [isInsuredDataSet ? formFields['value'] : title];
          } else {
            let value = formControls['controlName'] == 'relationshipWithNominee' ? this.nomineeRelationshipMaster : formFields.value;
            formControls['value'] = [isInsuredDataSet ? formFields['value'] : value];
          }
        }
        final.push(this.fb.group(formControls));
      })
      resolve();
    });
  }

  // To detect which member is selected
  memberTypeSelected(type) {
    if (type.index == 0) {
      this.memberChanged('Adult', 0);
      this.showChildTabs = false;
      this.showAdultTabs = true;
    }
    else {
      this.memberChanged('Child', 0);
      this.showAdultTabs = false;
      this.showChildTabs = true;
    }

  }

  // memberChanged(type: string, index) {
  //   if (type == healthMembers.Adult && this.travelFormData.quoteFormData.adultDetails.length > 0) {
  //     // if (index == 0) {
  //     //   this.memberDataIndex = 0;
  //     // }

  //     // else if (index == 1) {
  //     //   this.memberDataIndex = 1;
  //     // }
  //     this.memberDataIndex = index;

  //     this.tabGroup.forEach((ele, ind) => {
  //       if (ele['tabLabel'] == type) {
  //         ele['memberDetails'].forEach((ele2, ind2) => {
  //           if (ind2 == index) {
  //             ele2['selected'] = true;
  //           } else {
  //             ele2['selected'] = false;
  //           }
  //         })
  //       }
  //     })
  //   }

  //   else {
  //     let adultLength = this.travelFormData.quoteFormData.adultDetails.length;
  //     if (this.travelFormData.quoteFormData.adultDetails.length == 0 && this.travelFormData.quoteFormData.childDetails.length > 0) {
  //       if (index == 0) {
  //         this.memberDataIndex = adultLength;
  //       }
  //       else if (index == 1) {
  //         this.memberDataIndex = adultLength + 1;
  //       }
  //     }

  //     else if (this.travelFormData.quoteFormData.adultDetails.length > 0 && this.travelFormData.quoteFormData.childDetails.length > 0) {
  //       // If child is 0 
  //       if (index == 0) {
  //         this.memberDataIndex = adultLength;
  //       }

  //       else if (index == 1) {
  //         if (adultLength == 0) {
  //           this.memberDataIndex = adultLength;
  //         }

  //         else if (adultLength == 1 || adultLength == 2) {
  //           this.memberDataIndex = adultLength + 1;
  //         }
  //       }

  //       else if (index == 2) {
  //         this.memberDataIndex = this.totalData.length - 1;
  //       }
  //     }

  //     // for child
  //     this.tabGroup.forEach((ele, ind) => {
  //       if (ele['tabLabel'] == type) {
  //         ele['memberDetails'].forEach((ele2, ind2) => {
  //           if (ind2 == index) {
  //             ele2['selected'] = true;
  //           } else {
  //             ele2['selected'] = false;
  //           }
  //         })
  //       }
  //     })
  //   }
  // }

  memberChanged(type: string, index) {
    this.memberDataIndex = index;
    if (type == 'Child') {
      this.memberDataIndex = index == 0 ? this.travelFormData.quoteFormData.adultDetails.length : this.travelFormData.quoteFormData.adultDetails.length + index;

    }

  }

  // Feet height modification methods
  feetHeightFormatting(eventData, data, parenIndex, index, alphaCheck: boolean, numberCheck: boolean) {
    // let memberDetails = this.memberDetails.controls[index] as FormGroup;
    let memberDetails = this.insuredDetailsForm.controls['memberDetails']['controls'][parenIndex]['controls']['controls']['controls'][index] as FormGroup;
    let insuredHeightData = memberDetails.get(`feetInchesHeight`);
    if (eventData.target.id.includes('feetInchesHeight')) {
      this.utilityService.dynamicControlsNumberDecimalOnly(eventData, numberCheck);
      if (!isNaN(eventData.target.value)) {
        let heightInput = insuredHeightData.value;
        let heightInputArr = heightInput.split('');
        if (heightInputArr[1] != '.' && heightInput.length == 2) {
          insuredHeightData.setValue(heightInputArr[0] + '.' + heightInputArr[1]);
        }
      }
    }
    else {
      this.utilityService.dynamicControlsAlphaOnly(eventData, alphaCheck);
      this.utilityService.dynamicControlsNumberOnly(eventData, numberCheck);
      if ((data.controlName != insuredControls.fullName && data.controlName != insuredControls.motherName && data.controlName != insuredControls.nomineeName)) {
        this.utilityService.restrictSpace(eventData);
      }
    }
  }

  // This method is created to find controls and pass a comparison value so this method can return the index of that control
  findControlsByIndex(value: string): number {
    let index: number = this.insuredControls.findIndex(data => data.controlName == value);
    return index;
  }

  onSubmit(event: EmitButtonData) {
    this.insuredDetailsForm.markAllAsTouched();
    this.isSubmit = true;
    let adultCount = 0;
    if (!this.insuredDetailsForm.valid) {
      let memberDetails = this.insuredDetailsForm.controls['memberDetails']['controls'];
      for (let i = 0; i < memberDetails.length; i++) {
        let memberDetailsStatus = this.insuredDetailsForm.controls['memberDetails']['controls'][i].valid;
        let internalKeys = this.insuredDetailsForm.controls['memberDetails']['controls'][i].value;
        // let type = internalKeys[0].includes('Adult') ? 'Adult' : 'Child';
        let type = this.checkAdultChildType(internalKeys['controls'][3].dob)
        if (type == 'Adult') {
          adultCount = adultCount + 1;
        }
        let typeLen = memberDetails.length > 2 ? ((type == 'Adult') ? i + 1 : (adultCount == 1) ? i : i - 1) : ((type == 'Adult') ? i + 1 : (adultCount == 0) ? i + 1 : i);
        if (!memberDetailsStatus) {
          // let controls = this.insuredDetailsForm.controls['memberDetails']['controls'][i]['controls']['controls']['controls'];
          // for (let j = 0; j < controls.length; j++) {
          //   let key = controls[j]['controls']['controlName'].value;
          //   if (controls[j]['controls'][controls[j]['controls']['controlName'].value].invalid) {
          //     const invalidControl = this.el.nativeElement.querySelector('#' + key + '.ng-invalid');
          //     invalidControl.scrollIntoView({ behaviour: "instant", block: "center", inline: "center" });
          //     invalidControl.focus();
          //     break;
          //   }
          // }
          let msg = 'Please fill details for ' + type + ' ' + typeLen;
          this.snackBar.open(
            msg, 'Ok', {
            duration: 2000,
            verticalPosition: 'bottom',
            horizontalPosition: 'right'
          });
          return;
        }
      }
    } else {
      this.ailmentValidation();
      if (this.isSubmit) {
        let passportNumbers = this.getPassportNumbers();
        let isDuplicate = this.utilityService.checkDuplicate(passportNumbers, 'passport')
        if (isDuplicate) {
          let msg = 'Duplicate passport number.'
          this.errorPopupData = this.popupService.fetchErrorPopupMsg(msg);
          this.popupService.openGenericPopup(this.errorPopupData);
          return;
        }
        this.customStepperService.emitStepData({ status: false, buttonId: 'f3' });
        this.setInusredFormData();
      }
    }

  }
  
  checkAdultChildType(dob) {
    let age = (new Date().getFullYear() - new Date(dob).getFullYear());
    let type = age > 18 ? 'Adult' : 'Child';
    return type;
}

  getPassportNumbers() {
    let passport = [];
    this.insuredDetailsForm.value.memberDetails.forEach(member => {
      member.controls.forEach(control => {
        if (control.controlName == 'passportNumber')
          passport.push({ 'passport': control['passportNumber'] })
      })
    })
    return passport;
  }

  setInusredFormData() {
    this.travelFormData.insuredDataSet = true;
    this.travelFormData.insuredFormData.insuredMemberDetails = this.insuredDetailsForm.value.memberDetails;
    this.insuredDetailsForm.value.memberDetails.forEach((member, index) => {
      member.controls.forEach((control, subIndex) => {
        if (control?.controlName == 'applicantRelationship') {
          let applicantRelationship = this.travelFormData.quoteFormData.relationshipDetails.filter(x => x['RelationshipName'] == control[control['controlName']]);
          this.travelFormData.insuredFormData.insuredMemberDetails[index]['controls'][subIndex]['drpId'] = applicantRelationship[0]['RelationshipID'];
          this.travelFormData.insuredFormData.insuredMemberDetails[index]['controls'][subIndex]['drpName'] = applicantRelationship[0]['RelationshipName'];
        } else if (control?.controlName == 'title') {
          let title = control.value.filter(x => x.value == control[control['controlName']]);
          this.travelFormData.insuredFormData.insuredMemberDetails[index]['controls'][subIndex]['drpId'] = title[0].value;
          this.travelFormData.insuredFormData.insuredMemberDetails[index]['controls'][subIndex]['drpName'] = title[0].label;
        } else if (control?.controlName == 'relationshipWithNominee') {
          let nomineeRelationship = this.nomineeRelationshipMaster.filter(x => x.value == control[control['controlName']]);
          this.travelFormData.insuredFormData.insuredMemberDetails[index]['controls'][subIndex]['drpId'] = nomineeRelationship[0].value;
          this.travelFormData.insuredFormData.insuredMemberDetails[index]['controls'][subIndex]['drpName'] = nomineeRelationship[0].label;
        }
      })
      // 
    })
    this.travelDataStorageService.setTravelDataStorage(this.travelFormData);
    this.customStepperService.emitStepData({ status: true, buttonId: 'f3' });
  }

  ailmentValidation() {
    this.insuredDetailsForm.value.memberDetails.forEach(member => {
      member.controls.forEach(control => {
        if (control['controlName'] == 'ailment') {
          if (control[control['controlName']] != "none") {
            this.showPopupToRestrictAilments();
            this.isSubmit = false;
          }
        }
      })
    })
  }

  checkMemberValidation() {
    for (const key of Object.keys(this.insuredDetailsForm.controls)) {
      let a = this.insuredDetailsForm.controls[key]['controls'];
      for (let i = 0; i < a.length; i++) {
        let status = this.insuredDetailsForm.controls[key]['controls'][i].valid;
        let internalKeys = Object.keys(this.insuredDetailsForm.controls[key]['controls'][i].value);
        let type = internalKeys[0].includes('Adult') ? 'Adult' : 'Child';
        let typeRes = (type == 'Adult') ? i + 1 : i - 1;
        let typeLen = a.length > 2 ? typeRes : i + 1;
        if (!status) {
          let msg = 'Please fill details for ' + type + ' ' + typeLen;
          this.snackBar.open(
            msg, 'Ok', {
            duration: 3000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center'
          });
          return;
        }
      }
    }
  }

  changeInDropdownValue(eventData, data) {
    if (data.controlName == insuredControls1.ailment) {
      if (data[data.controlName] != "none") {
        this.isSubmit = false;
        this.showPopupToRestrictAilments();
      }
    }

  }

  showPopupToRestrictAilments() {
    let msg = 'Due to the ailment, We will not be able to process your request online. Please contact our customer support for further details.'
    this.errorPopupData = this.popupService.fetchErrorPopupMsg(msg);
    this.popupService.openGenericPopup(this.errorPopupData);
  }

  fetchNomineeRelationshipMaster(): Promise<any> {
    return new Promise((resolve: any) => {
      let request = '';
      this.subscription.push(this.travelService.fetchNomineeRelationshipMasterDetails(request).subscribe({
        next: (response: any) => {
          if (response.StatusCode == Constants.statusCode_success) {
            let details;
            if (response.Data.length > 0) {
              details = response.Data.map(detail => ({
                value: detail.NomineeID,
                label: detail.NomineeName
              }));
            }
            this.nomineeRelationshipMaster = response.Data.length > 0 ? details : [];
            this.serviceError = false;
            resolve();
          } else {
            this.serviceError = true;
          }
        },
        error: error => {
        }
      })
      )
    });
  }

  @HostListener('window:keydown', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    const focusedElement = this.el.nativeElement.ownerDocument.activeElement as HTMLElement;
    const focusedElementId = focusedElement.id;
    const remainingString = focusedElementId.replace('fullName', "");
    const newString = "dob" + remainingString;
    if (focusedElementId.includes('fullName') && event.key === 'Tab') {
      const nextElement = this.el.nativeElement.ownerDocument.querySelector(`#${newString}`) as HTMLInputElement;
      if (nextElement) {
        nextElement.focus();
      }
    }
  }

  removeKeyListener() {
    if (this.keyDownListener) {
      window.removeEventListener('keydown', this.keyDownListener);
      this.keyDownListener = null;
    }
  }

  onRefresh(status) {
    if (status) {
      this.onLoadSteps();
    }
  }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.removeKeyListener();
  }
}