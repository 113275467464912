<div class="fieldsdiv">
   <div class="row customerType">
      <div class="col-sm-3">
         <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="custom-toggle-group"
            style="min-width: 148px;">
            <mat-button-toggle class="custom_togglebtn" value="bold" [checked]="customerType == 'New' ? true : false"
               (change)="changeInCustomerType('New')">New</mat-button-toggle>
            <mat-button-toggle class="custom_togglebtn" value="italic" [checked]="customerType != 'New' ? true : false"
               (change)="changeInCustomerType('Existing')">Existing</mat-button-toggle>
         </mat-button-toggle-group>
      </div>
   </div>
</div>
<div class="fieldsdiv">

   <div class="row" *ngIf="showEKYC && !showSearchPopup">
      <div class="col-sm-12 updateKYC">
         <!-- If kyc is not completed -->
         <div class="verifyDiv verifyNew" *ngIf="!isKYCDoneSuccessfully">
            <span><img src="../../../assets/images/verifyIndicator.gif" alt=""></span>
            <span> Please complete your KYC verification </span>
            <button class="secondarybtn_2 ml-2" (click)="callKYC()">Update</button>
         </div>

         <!-- If kyc is not completed for pilot user-->
         <div class="verifyDiv bgVerificationError" *ngIf="!isKYCDoneSuccessfullyForPilotuser && !isKYCDoneSuccessfully" style="margin-bottom: 3;">
            <span><img src="../../../assets/images/verifyIndicator.gif" alt=""></span>
            <span>Kindly complete your KYC verification using a govt. approved ID proof.</span>
         </div>

         <!-- If kyc is completed -->
         <div class="verifyDiv bgVerify" *ngIf="isKYCDoneSuccessfully" style="margin-bottom: 3;">
            <span><img src="../../../assets/images/complete.gif" alt=""></span>
            <span>KYC Verification successfully completed</span>
         </div>

      </div>
   </div>
</div>
<!-- Search Customer Popup -->
<form [formGroup]="searchCustomerForm" *ngIf="showSearchPopup">
   <div class="fieldsdiv">
      <div class="row pt-3">
         <div [ngClass]="controlsData['controlName'] == 'or' ? 'col-sm-12 orDiv' : 'col-sm-4 form-group'"
            *ngFor=" let controlsData of searchForCustomerControls">
            <h2 class="orHeading" *ngIf="controlsData['controlName'] == 'or'">OR</h2>
            <!-- If type is input field -->
            <div class="form-group" *ngIf="controlsData['type'] == 'input' && !controlsData['hide']">
               <mat-label class="input-group__label" for="myInput">{{controlsData['label']}}</mat-label>
               <input type="{{controlsData['inputType']}}" [maxlength]="controlsData['maxLength']"
                  [readonly]="controlsData['disabled']" [max]="controlsData['maxNumber']"
                  [id]="controlsData['controlName']" [min]="controlsData['minNumber']"
                  formControlName="{{controlsData['controlName']}}" placeholder="{{controlsData['placeholder']}}"
                  (input)="validationOfInputField($event, controlsData.controlName, controlsData['onlyAlphabets'], controlsData['onlyNumbers'])"
                  (paste)="utilityService.onPaste($event,controlsData['onlyAlphabets'],controlsData['onlyNumbers'])"
                  class="input-group__input" autocomplete="off" />
               <mat-error *ngIf="hasError(controlsData['controlName'], 'required','searchCustomer')">
                  {{controlsData['label']}} is required
               </mat-error>
               <mat-error *ngIf="hasError(controlsData['controlName'], 'minlength','searchCustomer')">
                  {{controlsData['label']}} is invalid minlength
               </mat-error>
               <mat-error *ngIf="hasError(controlsData['controlName'], 'maxlength','searchCustomer')">
                  {{controlsData['label']}} is invalid maxlength
               </mat-error>
               <mat-error *ngIf="hasError(controlsData['controlName'], 'pattern','searchCustomer')">
                  {{controlsData['label']}} is invalid pattern
               </mat-error>
            </div>
         </div>
      </div>
   </div>
   <!-- Search Button Click -->
   <div class="fieldsdiv">
      <div class="row">
         <div class="col-md-12 form-group">
            <button mat-raised-button class="primary-button" (click)="searchButtonClick('proceed')"
               style="min-width:140px; background: #EC6625 !important;">Search
               customer</button>
         </div>
      </div>
   </div>
   <mat-divider *ngIf="displayCustomerList"></mat-divider>
   <!-- List of customers table -->
   <div *ngIf="displayCustomerList" class="mt-3" style="max-width: 1150px; margin-left: 12px;">
      <p style="font-size: 13px;">
         {{numberOfRecords}} record(s) matching your search criteria were found.
      </p>
      <div id="tableDiv" class="kyccustomerTable" *ngIf="customerListData.length >= 1;  else noDataFound ">
         <table class="kycTable">
            <caption class="hidden-caption">Details</caption>
            <td class="hidden-caption">KYC Details</td>
            <thead>
               <tr>
                  <th>Serial No.</th>
                  <th>Customer Name</th>
                  <th>Email Id</th>
                  <th>Mobile No.</th>
               </tr>
            </thead>
            <tbody>
               <tr *ngFor="let item of customerListData" (click)="getCustomerRecord(item)">
                  <td>{{ item.id }}</td>
                  <td>{{ item.label }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.mobileNumber }}</td>
               </tr>
            </tbody>
         </table>
      </div>
      <ng-template #noDataFound>
         <h1>No Data Found!</h1>
      </ng-template>
      <!-- Error msg if no customer gets selected -->
      <p *ngIf="customerSelected.length == 0" style="font-size: 11px; color: #B02A30" class="mt-1">
         Note: Atleast one customer needs to be selected for KYC verification.
      </p>
   </div>
</form>
<!-- Kyc Modal -->
<div class="modal fade kycPopupScreen" id="externalUrlModal" tabindex="-1" role="dialog" aria-labelledby="externalUrlModalLabel"
   aria-hidden="true">
   <div class="modal-dialog my-0" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <!-- <h5 class="modal-title">Modal title</h5> -->
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">
            <iframe id="externalUrlFrame" width="100%" height="600px"></iframe>
         </div>
      </div>
   </div>
</div>