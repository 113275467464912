import { NgModule } from '@angular/core';
import { IPushnotificationComponent } from './i-pushnotification.component';
import { NotificationUiComponent } from './notification-ui/notification-ui.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { CommonModule } from '@angular/common';
import { env } from '../public-api';
import { initializeApp } from "firebase/app";
import {MatTabsModule} from '@angular/material/tabs';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { InterceptorService } from '../libservices/interceptor.service';
import { HeaderComponent } from 'src/app/layout/header/header.component';

initializeApp(env.firebase);

@NgModule({
  declarations: [
    IPushnotificationComponent,
    NotificationUiComponent
  ],
  imports: [
    MatCardModule,
    MatIconModule,
    MatListModule,
    MatTabsModule,
    CommonModule,
    HttpClientModule
  ],
  providers:[
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
  ],
  exports: [
    IPushnotificationComponent,
    NotificationUiComponent,
  ]
})
export class IPushnotificationModule { }
