import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { StorageService } from 'src/app/shared/services/storage.service';
import { userEnums } from 'src/app/shared/enums/userEnums';
@Injectable({
  providedIn: 'root'
})
export class InterceptorService {

  count = 0;
  constructor(private storageService: StorageService,){
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let url = req.url.toLowerCase();
    // if(req.url.includes('SaveToken') || req.url.includes('ViewNotificationHistory')){
    if (url.includes('plutus') && !(url.includes('createplutuslogintoken'))) {
      let fcmauthtoken = this.storageService.getAESDecryptedDataLocal('FCMAuthtoken');
      // fcmauthtoken = fcmauthtoken.token;
      let count = parseInt(sessionStorage.getItem('count'));
      if (count == 0) {
        // Define your new headers
        // const newHeaders = new HttpHeaders()
        //   .set('Content-Type', 'application/json charset=utf-8')
        //   .set('Authorization', `'Bearer'+ ${fcmauthtoken}`)
        //   .set('Cache-Control', 'no-cache');

        const newRequest = req.clone({
          headers:new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8',
                'Authorization': "Bearer " + fcmauthtoken,
                'Cache-Control': 'no-cache',
                'sesss':'sygshgsS'
          })
          //   setHeaders: {
          //     'Content-Type': 'application/json; charset=utf-8',
          //     'Authorization': "Bearer " + fcmauthtoken,
          //     'Cache-Control': 'no-cache'
          //     // newHeaders
          //   }
        });
        sessionStorage.setItem('count', (1).toString());
      }

    }
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          setTimeout(() => {
          }, 1000);
        }
        return event;
      }),
      catchError((refreshError) => {
        return throwError(() => refreshError);
      })
    );
  }
}
