import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UtilityService } from 'src/app/shared/services/utility.service';
@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss']
})
export class CustomTableComponent {
  @Input() reportList: any = [];
  @Input() buttonData = [];
  @Input() displayedColumns = [];
  @Input() tableSize: string = 'max-content';
  @Output() policyNo = new EventEmitter<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageSizeOptions: number[] = [10, 25, 100];
  pageSize: number = this.pageSizeOptions[0];
  pageIndex: number = 0;
  length: number = this.reportList.length;
  dataSource: MatTableDataSource<any>;
  displayCol: any;
  selectedRows: any = [];

  constructor(
    public utilityService: UtilityService
  ) {
    this.dataSource = new MatTableDataSource<any>(this.reportList);
    this.displayCol = this.displayedColumns.map(c => c.columnDef);
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource<any>(this.reportList);
    this.dataSource.paginator = this.paginator;
    this.displayCol = this.displayedColumns.map(c => c.columnDef);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  onPageChange(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
  }

  emitData(data, identifier) {
    data = { data, identifier };
    this.policyNo.emit(data);
    if (identifier == 'Add To Pay') {
      this.applyPageSize(this.pageSizeOptions[2]);
    }
  }

  applyPageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.dataSource.paginator = this.paginator;
  }

  evalConditionForMyPolicy(data, condition) {
    return eval(condition);
  }

  addSpace(inputString: string) {
    if (inputString == 'PendingForUnderWriter') {
      return 'Pending for Approval with UW1';
    }
    return inputString;
  }
}
