import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MFAService {
  readonly baseURI = `${environment.baseURL}`;
  readonly mfaSignOut = 'Wrapper/DashboardSignOut';
  readonly mfaRedirectToDashbord = 'Wrapper/DashboardRedirect';
  constructor(
    private generic: GenericService,
    private storageService: StorageService,
    private http: HttpClient
  ) {}
  mfaSignout(data: any): Observable<any> {
    const endpoint = `${this.baseURI}${this.mfaSignOut}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  mfaDashbordRedirect(data: any): Observable<any> {
    const endpoint = `${this.baseURI}${this.mfaRedirectToDashbord}`;
    return this.generic.genericService<any>(endpoint, data);
  }
}
