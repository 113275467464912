import { Component, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Subscription } from 'rxjs/internal/Subscription';
import { HAPModal } from 'src/app/modules/health/products/hap/hap-modal';
import { Constants } from 'src/app/shared/constants/constants';
import { InsuredRelationship } from 'src/app/shared/interface/healthMaster';
import { KycData, KycDetailsResponse } from 'src/app/shared/interface/kycDetails';
import { ApiTokenResponsePayload, AryaAPIKycResp } from 'src/app/shared/interface/utilityApi';
import { RenewalService } from 'src/app/shared/proxy-services/renewal-api.service';
import { WrapperService } from 'src/app/shared/proxy-services/wrapper.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { environment } from 'src/environments/environment';
import { AgentMasterStructure } from 'src/app/shared/interface/agentV1';
import { StorageService } from 'src/app/shared/services/storage.service';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { PaymodePaymentService } from 'src/app/shared/proxy-services/paymode-payment.service';
import { RenewalDataStorageService } from '../renewal.service';
import { DeclarationsService } from 'src/app/shared/services/declarations.service';
import { ChannelData } from 'src/app/shared/interface/swapPrameters';
import { PaymentStatusRequestPayload } from 'src/app/shared/interface/payment';
import { RenewalModal } from '../renewal-modal';
import { fromEvent } from 'rxjs';
import { UtilityApiService } from 'src/app/shared/proxy-services/utility-api.service';
declare const $: any;
declare const AJL: any;

export interface insuredDataStructure {
  InsuredName: string,
  InsuredID: string,
  InsuredDOB: string,
  InsuredAge: number,
  InsuredGender: string,
  InsuredRelation: string,
  PreIllness: string
}

@Component({
  selector: 'app-renewal-zerotat-summary',
  templateUrl: './renewal-zerotat-summary.component.html',
  styleUrls: ['./renewal-zerotat-summary.component.scss']
})
export class RenewalZerotatSummaryComponent {

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;

  NomineeDetailsForm: FormGroup;
  AppointeeDetailsForm: FormGroup;
  policiesConditionForm: FormGroup;
  renewalZerotatSummary: any = {};
  selectedCoverageIndex: number = 0;
  insuredDetails: [] = [];
  premiumData: any = [];
  insuredData: insuredDataStructure[] = [];
  ApplName: any;
  Addressl1: any;
  Addressl2: any;
  pincode: any;
  City: any;
  State: any;
  DOB: any;
  Email: any;
  Mobile: any;
  healthDataModal: HAPModal = new HAPModal();
  renewalDataModal: RenewalModal = new RenewalModal();
  nomineeRelationshipDetails: any;
  insuredRelationshipDetails: InsuredRelationship[];
  showAppointee: boolean;
  nomineeMinDate: Date;
  nomineeMaxDate: Date;
  appointeeMinDate: Date;
  appointeeMaxDate: Date;
  subscription: Subscription[] = [];
  nomineeRelationship: any;
  ekycMandatory: boolean = false;
  ilKycReferenceNumber: string = '';
  cKycId: string = '';
  eKycId: string = '';
  eKycPan: string = '';
  isKYCDoneSuccessfully: boolean = false;
  pepFlag: any;
  agentDetails: AgentMasterStructure;
  rnAgent: any;
  productType: number;
  channelData: ChannelData;

  constructor(private utilityService: UtilityService, private fb: FormBuilder, private renewalService: RenewalService, private wrapperService: WrapperService, private renderer: Renderer2, private storageService: StorageService, private paymode_payment_service: PaymodePaymentService, private renewal_service_storage: RenewalDataStorageService, private declarationService: DeclarationsService,
    private utilityApiService: UtilityApiService) {
  }

  ngOnInit() {
    this.renewalZerotatSummary = this.renewal_service_storage.getRenewalDataStorage();

    this.policiesConditionForm = this.fb.group({
      medicalDeclaration: [true, [Validators.required]],
      tncAcceptance: [true, [Validators.required]],
    })
    this.agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
    this.getNomineeMinMaxDate()
    this.getAppointeeMinMaxDate();
    this.patchData();
    this.getZeroTatRelationship();
    this.calculateNomineeAge();
    this.rnAgent = this.renewalZerotatSummary.AgentName;
    this.productType = this.renewalZerotatSummary.ProductType;
    if (this.renewalZerotatSummary.CustomerDetails.CKYCID != '' || this.renewalZerotatSummary.CustomerDetails.EKYCID != '' || this.renewalZerotatSummary.CustomerDetails.ReferenceNo != '') {
      this.isKYCDoneSuccessfully = true;
    }
    this.subscription.push(fromEvent(window, 'message')
      .subscribe((event) => {
        this.receiveMessage(event);
      }));
    this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
  }

  patchData() {
    //On arrival of page need to auto select premium which is recieved from Rnfetch
    Object.entries(this.renewalZerotatSummary).forEach(([key, value], index) => {
      if (this.renewalZerotatSummary[`Premium${index + 1}Year`]) {
        this.premiumData.push(this.renewalZerotatSummary[`Premium${index + 1}Year`]);
      }
    });

    //InsuredData
    let insuredDetails = this.renewalZerotatSummary.policyDetails.insuredDetails;
    this.insuredData.push(insuredDetails)
    // Applicant Details
    this.ApplName = this.renewalZerotatSummary.CustomerDetails.CustomerName;
    this.Addressl1 = this.renewalZerotatSummary.CustomerDetails.MailingAddressLine1;
    this.Addressl2 = this.renewalZerotatSummary.CustomerDetails.MailingAddressLine2;
    this.pincode = this.renewalZerotatSummary.CustomerDetails.MailingPinCode;
    this.City = this.renewalZerotatSummary.CustomerDetails.Mailing_CityDistrict_Name;
    this.State = this.renewalZerotatSummary.CustomerDetails.Mailing_State_Name;
    this.Email = this.renewalZerotatSummary.CustomerDetails.EmailId;
    this.Mobile = this.renewalZerotatSummary.CustomerDetails.MobileNumber;
    this.DOB = this.renewalZerotatSummary.CustomerDetails.DOB;

    //Nominee Form
    this.NomineeDetailsForm = this.fb.group({
      nomineeFullName: [this.renewalZerotatSummary.policyDetails.NomineeName, Validators.required],
      nomineeRelationship: [this.renewalZerotatSummary.policyDetails.NomineeRelationship, Validators.required],
      nomineeDOB: [this.utilityService.formatDateWithDash(this.renewalZerotatSummary.policyDetails.NomineeDOB), Validators.required]
    })

    // Appointee Form
    this.AppointeeDetailsForm = this.fb.group({
      appointeeFullName: [this.renewalZerotatSummary.policyDetails.AppointeeName],
      appointeeRelationship: [this.renewalZerotatSummary.policyDetails.AppointeeRelationship],
      appointeeDOB: [this.utilityService.formatDateWithDash(this.renewalZerotatSummary.policyDetails.AppointeeDOB)]
    })
  }

  // relationship api call
  getZeroTatRelationship() {
    this.subscription.push(this.renewalService.ZeroTatRelationship().subscribe({
      next: (response: any) => {
        this.nomineeRelationshipDetails = response.Data.NomineeRelationShip;
      }
    })
    )
  }

  selectCoverage(index) {
    this.selectedCoverageIndex = index;
  }

  calculateNomineeAge() {
    let nomineeAge = this.utilityService.calculateAge(this.NomineeDetailsForm.controls['nomineeDOB'].value);
    if (nomineeAge < 18) {
      this.showAppointee = true;
      this.AppointeeDetailsForm.controls['appointeeFullName'].addValidators(Validators.required);
      this.AppointeeDetailsForm.controls['appointeeRelationship'].addValidators(Validators.required);
      this.AppointeeDetailsForm.controls['appointeeDOB'].addValidators(Validators.required);
      this.AppointeeDetailsForm.updateValueAndValidity();
    } else {
      this.showAppointee = false;
      this.AppointeeDetailsForm.controls['appointeeFullName'].removeValidators(Validators.required);
      this.AppointeeDetailsForm.controls['appointeeRelationship'].removeValidators(Validators.required);
      this.AppointeeDetailsForm.controls['appointeeDOB'].removeValidators(Validators.required);
      this.AppointeeDetailsForm.updateValueAndValidity();
    }
  }

  getNomineeMinMaxDate() {
    let nomineeMinMaxDate = this.utilityService.getMinMaxDate(new Date().getFullYear());
    this.nomineeMinDate = nomineeMinMaxDate.minDate;
    this.nomineeMaxDate = this.utilityService.subtractYears(new Date(), 18);
  }

  getAppointeeMinMaxDate() {
    let appointeeMinMaxDate = this.utilityService.getMinMaxDate(new Date().getFullYear());
    this.appointeeMinDate = appointeeMinMaxDate.minDate;
    this.appointeeMaxDate = this.utilityService.subtractYears(new Date(), 18);
  }

  callKYC() {
    // this.aryaApiKycCalling(); //arya kyc changes
    this.newKycCalling(); //flutter kyc changes
  }

  newKycCalling() {
    this.subscription.push(this.wrapperService.createKYCToken().subscribe({
      next: (res: ApiTokenResponsePayload) => {
        if (res.StatusCode == Constants.statusCode_success) {
          let kycToken = !this.utilityService.isEmptyOrNull(res.Data) ? res.Data.split("=")[1] : '';
          let source = this.utilityService.identifyEnvironmentType(kycToken);
          let resourceParams = JSON.stringify(source);
          const externalUrlFrame = document.getElementById('externalUrlFrame') as HTMLIFrameElement;
          externalUrlFrame.src = environment.kycUrl + '?rsrc=' + resourceParams;
          $('#externalUrlModal').modal('show');
        } else {
        }
      },
      error: error => {
      }
    }));
  }

  aryaApiKycCalling() {
    const getAryaApiResp = (res, status) => {
      if (status.toLowerCase() == Constants.statusMsg_Success) {
        // setTimeout(() => {
        let kycData: KycDetailsResponse = this.kycResponseMapping(res);
        this.getKYCDetails(kycData);
        // }, 0);
      }
      else {
        this.isKYCDoneSuccessfully = false;
        this.ekycMandatory = false;
        this.utilityService.showSnackbar('Kindly complete E-KYC/C-KYC to proceed further.');
      }
    }


    this.subscription.push(this.utilityApiService.getApiToken().subscribe({
      next: (res: ApiTokenResponsePayload) => {
        if (res.StatusCode == Constants.statusCode_success) {
          let kycToken = !this.utilityService.isEmptyOrNull(res.Data) ? (res.Data).split(' ')[1] : '';
          let environmentType: string = '';
          environmentType = environment.environment == 'production' ? 'production' : 'uat';
          AJL.aryafns.initMod(kycToken, getAryaApiResp, environmentType);
        }
      }
    }));
  }

  kycResponseMapping(oldKycResp: AryaAPIKycResp): KycDetailsResponse {
    let statusCode: number = 0;
    let alternateAddressPresent: boolean = false;
    alternateAddressPresent = !this.utilityService.isEmptyOrNull(oldKycResp.api_response.kyc_details.alternate_address);
    if (((oldKycResp.api_response.statusMessage).toLowerCase() == Constants.statusMsg_Success) || (oldKycResp.api_response.statusMessage == Constants.statusMsg_Manual)) {
      statusCode = 1;
    }
    else {
      statusCode = 0;
    }
    let kycResp: KycDetailsResponse = {
      StatusCode: statusCode,
      StatusMessage: oldKycResp.api_response.statusMessage,
      data: {
        correlationId: oldKycResp.api_response.correlationId,
        customer_type: oldKycResp.api_response.kyc_details.customer_type,
        pep_flag: oldKycResp.user_data.pep_flag == 'No' ? false : true,
        status: oldKycResp.api_response.status,
        statusMessage: oldKycResp.api_response.statusMessage,
        message: oldKycResp.api_response.message,
        kyc_details: {
          il_kyc_ref_no: oldKycResp.api_response.kyc_details.il_kyc_ref_no,
          certificate_type: oldKycResp.api_response.kyc_details.certificate_type,
          certificate_number: oldKycResp.api_response.kyc_details.certificate_number,
          certificate_type_poa: this.utilityService.isEmptyOrNull(oldKycResp.api_response.kyc_details.certificate_type_poa) ? oldKycResp.api_response.kyc_details.certificate_type_poa : '',
          certificate_number_poa: this.utilityService.isEmptyOrNull(oldKycResp.api_response.kyc_details.certificate_number_poa) ? oldKycResp.api_response.kyc_details.certificate_number_poa : '',
          first_name: oldKycResp.api_response.kyc_details.first_name,
          middle_name: '', //Not present
          last_name: oldKycResp.api_response.kyc_details.last_name,
          full_name: oldKycResp.api_response.kyc_details.full_name,
          gender: oldKycResp.api_response.kyc_details.gender,
          dob: oldKycResp.api_response.kyc_details.dob,
          mobile_number: oldKycResp.api_response.kyc_details.mobile_number,
          email: oldKycResp.api_response.kyc_details.email,
          ckyc_number: oldKycResp.api_response.kyc_details.ckyc_number,
          il_customer_id: '',
          permanent_address: {
            address_line_1: oldKycResp.api_response.kyc_details.permanent_address.address_line_1,
            address_line_2: oldKycResp.api_response.kyc_details.permanent_address.address_line_2,
            pin_code: oldKycResp.api_response.kyc_details.permanent_address.pin_code,
            city: oldKycResp.api_response.kyc_details.permanent_address.city,
            state: oldKycResp.api_response.kyc_details.permanent_address.state,
          },
          alternate_address: {
            address_line_1: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.address_line_1 : '',
            address_line_2: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.address_line_2 : '',
            pin_code: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.pin_code : '',
            city: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.city : '',
            state: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.state : '',
          },
          udf_1: '',
          udf_2: '',
          udf_3: '',
          udf_4: '',
          udf_5: '',
          pep_flag: oldKycResp.user_data.pep_flag == 'No' ? false : true,
        }
      }
    }
    return kycResp;
  }

  receiveMessage(event) {
    $('#externalUrlModal').modal('hide');
    if (!this.utilityService.isEmptyOrNull(event.data)) {
      let res = typeof (event.data) == 'string' ? JSON.parse(event.data) : event.data;
      if (!this.utilityService.isEmptyOrNull(res)) {
        this.getKYCDetails(res);
      }
    }
  }

  getKYCDetails(res: KycDetailsResponse) {
    if ((res != null) && (res.StatusCode == Constants.statusCode_success)) {
      let tempResponse: KycDetailsResponse = typeof (res) == 'string' ? JSON.parse(res) : res;
      this.ekycMandatory = true;
      this.ilKycReferenceNumber = tempResponse.data.hasOwnProperty('kyc_details') ? tempResponse.data.kyc_details.il_kyc_ref_no : '';
      this.cKycId = tempResponse.data.hasOwnProperty('kyc_details') ? tempResponse.data.kyc_details.ckyc_number : '';
      this.isKYCDoneSuccessfully = tempResponse.data.hasOwnProperty('kyc_details') && ((!this.utilityService.isEmptyOrNull(this.cKycId) || !this.utilityService.isEmptyOrNull(this.ilKycReferenceNumber) || !this.utilityService.isEmptyOrNull(this.eKycId))) ? true : false;
      this.setKYCDetails(tempResponse.data);
    }
  }

  setKYCDetails(response: KycData) {
    if (response) {
      this.eKycId = '';
      let customer_type = response.customer_type;
      this.eKycPan = response.kyc_details.certificate_type != 'PAN' ? '' : response.kyc_details.certificate_number;
      this.getZeroTatRelationship["CKYCID"] = response.kyc_details.ckyc_number;
      this.getZeroTatRelationship["PepFlag"] = !this.utilityService.isEmptyOrNull(response.kyc_details.pep_flag)?response.kyc_details.pep_flag: false;
      this.renewalZerotatSummary.customerDetails.PEPFLAG = !this.utilityService.isEmptyOrNull(response.kyc_details.pep_flag) ?response.kyc_details.pep_flag: false;
      this.getZeroTatRelationship["EKYCID"] = this.eKycId;
      this.getZeroTatRelationship["ILkycReferenceNumber"] = response.kyc_details.il_kyc_ref_no;
      this.getZeroTatRelationship["isKYCDoneSuccessfully"] = this.isKYCDoneSuccessfully;

    }
    else {
      this.getZeroTatRelationship["CKYCID"] = "";
      this.getZeroTatRelationship["PepFlag"] = false;
      this.renewalZerotatSummary.customerDetails.PEPFLAG = false;
      this.getZeroTatRelationship["EKYCID"] = "";
      this.getZeroTatRelationship["ILkycReferenceNumber"] = "";
      this.getZeroTatRelationship["isKYCDoneSuccessfully"] = false;
    }
  }

  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  getCoverDetails() {
    let covers = [];
    this.renewalZerotatSummary.policyDetails.coverDetails.forEach(element => {
      covers.push({
        "Column1": element.Column1,
        "CoverDescription": element.CoverName,
        "SumInsured": element.SumInsured,
        "column2": null,
        "column3": null,
        "column4": null,
        "column5": null,
        "Applicability80D": null,
        "Rate": 0,
        "Premium": 0,
        "DifferentialSI": 0,
        "EndorsementAmount": 0
      })
    });
    return covers;
  }

  getCritishieldCover() {
    let covers = [];
    this.renewalZerotatSummary.policyDetails.coverDetails.forEach(element => {
      covers.push({
        "Column1": element.Column1,
        "CoverDescription": element.CoverName,
        "SumInsured": element.SumInsured,
        "column2": null,
      })
    });
    return covers;
  }

  Onsubmit(payMode) {
    this.NomineeDetailsForm.markAllAsTouched();
    this.AppointeeDetailsForm.markAllAsTouched();

    if (this.NomineeDetailsForm.valid && this.AppointeeDetailsForm.valid && this.policiesConditionForm.controls['medicalDeclaration'].value == true && this.policiesConditionForm.controls['tncAcceptance'].value == true) {
      if (this.renewalZerotatSummary.ProductType == '38') {
        this.critishieldRequest(payMode);
      } else {
        this.familyShieldRequest(payMode);
      }
    }
  }

  familyShieldRequest(payMode) {
    let request: any = this.familyShieldProposalRequest();
    this.subscription.push(this.renewalService.ZeroTatProposal(request).subscribe({
      next: response => {
        if (response.StatusCode == Constants.statusCode_success) {
          let res = response.Data;
          let req = {
            policyIds: res.policy_id,
            totalPremium: this.premiumData[0].TotalPremium,
            pfProposalNumber: res.proposalNumber
          }
          this.paymentCreation(payMode, req);
        }
      }
    }))
  }

  critishieldRequest(payMode) {
    let request: any = this.critiShieldProposalRequest();
    this.subscription.push(this.renewalService.critishieldProposal(request).subscribe({
      next: response => {
        if (response.StatusCode == Constants.statusCode_success) {
          let res = response.Data;
          let req = {
            policyIds: res.PolicyIds,
            totalPremium: this.premiumData?.coverageData?.TotalPremium,
            pfProposalNumber: res.MembersDetails[0].PFProposalNumber
          }
          this.paymentCreation(payMode, req);
        }
      }
    }))
  }

  paymentCreation(payMode, req) {
    let data: PaymentStatusRequestPayload = {
      paymode: payMode,
      befitPlan: false,
      homePlan: false,
      zeroTatProduct: true,
      proposalPolicyID: req.policyIds,
      bundalPolicyID: '',
      totalPremium: req.totalPremium,
      proposalNumber: req.pfProposalNumber,
      bundleProposalNumber: '',
    }
    switch (payMode) {
      case 'PaymentLink': {
        //payment service
        this.paymode_payment_service.commonPayLaterRequest(data);
        break;
      }
      case 'PayNow': {
        //payment service 
        this.paymode_payment_service.CreateBundlePayment(data)
        break;
      }
      case 'PayLater': {
        //payment service
        this.paymode_payment_service.commonPayLaterRequest(data);
        break;
      }
      default : {
        break;
      }
    }
  }

  SetResponseData() {
    this.renewalZerotatSummary.policyDetails.NomineeName = this.NomineeDetailsForm.controls['nomineeFullName'].value;
    this.renewalZerotatSummary.policyDetails.NomineeRelationship = this.NomineeDetailsForm.controls['nomineeRelationship'].value;
    this.renewalZerotatSummary.policyDetails.NomineeDOB = this.NomineeDetailsForm.controls['nomineeDOB'].value
    this.renewalZerotatSummary.policyDetails.AppointeeName = this.AppointeeDetailsForm.controls['appointeeFullName']
    this.renewalZerotatSummary.policyDetails.AppointeeRelationship = this.AppointeeDetailsForm.controls['appointeeRelationship']
    this.renewalZerotatSummary.policyDetails.AppointeeDOB = this.AppointeeDetailsForm.controls['appointeeDOB'].value
  }

  familyShieldProposalRequest() {
    this.renewalZerotatSummary.policyDetails.NomineeName = this.NomineeDetailsForm.controls['nomineeFullName'].value;
    this.renewalZerotatSummary.policyDetails.NomineeRelationship = this.NomineeDetailsForm.controls['nomineeRelationship'].value;
    this.renewalZerotatSummary.policyDetails.NomineeDOB = this.NomineeDetailsForm.controls['nomineeDOB'].value;
    this.renewalZerotatSummary.policyDetails.AppointeeName = this.AppointeeDetailsForm.controls['appointeeFullName'].value
    this.renewalZerotatSummary.policyDetails.AppointeeRelationship = this.AppointeeDetailsForm.controls['appointeeRelationship'].value
    this.renewalZerotatSummary.policyDetails.AppointeeDOB = this.AppointeeDetailsForm.controls['appointeeDOB'].value
    let startDate;
    let endDate;
    let endndDate = new Date(this.renewalZerotatSummary.policyDetails.PolicyEndDate);
    if (endndDate.getTime() >= new Date().getTime()) {
      startDate = new Date(endndDate).setDate(endndDate.getDate() + 1);
    } else {
      startDate = new Date();
    }
    let setYear = new Date(startDate).setFullYear(new Date(startDate).getFullYear() + (this.renewalZerotatSummary['policyDetails']['Tenure']));
    setYear = new Date(setYear).setDate(new Date(setYear).getDate() - 1);
    endDate = new Date(setYear);
    let PolicyStartDate = this.utilityService.formatDateWithDash(startDate);
    let PolicyEndDate = this.utilityService.formatDateWithDash(endDate);
    const UserID: string = String(this.agentDetails.AgentID);
    let nomineeFormValue = this.NomineeDetailsForm.value;
    let nomineerelation = nomineeFormValue.nomineeRelationshipWithApplicant;

    let request =
    {
      "userid": UserID,
      'ipartner_user_id': this.renewalZerotatSummary.CustomerDetails.iPartCustomerId,
      "BusinessType": "Renewal Business",
      "ModeOfOperation": this.renewalZerotatSummary.isProposalModified ? "PROPOSALMODIFICATION" : "RENEWPOLICY",
      "PolicyTenure": this.renewalZerotatSummary['policyDetails']['Tenure'],
      "PolicyNumberChar": this.renewalZerotatSummary.policyDetails.PolicyNo,
      "PolicyStartDate": PolicyStartDate,
      "PolicyEndDate": PolicyEndDate,
      "ILProductCode": "4172",
      "SubProductCode": this.renewalZerotatSummary.policyDetails.SubProductCode,
      "AlternatePolicyNo": this.renewalZerotatSummary.policyDetails.PolicyNo,
      "SpecialConditionDescription": null,
      "CoinsuranceType": null,
      "FinancierDetails_Remarks": null,
      "FinancierDetails_PrefixSufix": null,
      "FinancierDetails": null,
      "ReferenceNumber": this.renewalZerotatSummary.ProposalNo,
      "InteractionID": null,
      "EffectiveDate": null,
      "EffectiveTime": null,
      "EndorsementWording": null,
      "NILEndorsementType": null,
      "TypeofEndorsement": null,
      "TypeOfCalculation": null,
      "RetainCancellationPremium": null,
      "insuredDataDetails": [
        {
          "InsuredName": this.renewalZerotatSummary.policyDetails.insuredDetails.InsuredName,
          "InsuredID": this.renewalZerotatSummary.policyDetails.insuredDetails.InsuredID,
          "InsuredDOB": this.utilityService.formatDateWithDash(this.renewalZerotatSummary.policyDetails.insuredDetails.InsuredDOB),
          "InsuredAge": this.renewalZerotatSummary.policyDetails.insuredDetails.InsuredAge ? this.renewalZerotatSummary.policyDetails.insuredDetails.InsuredAge : '',
          "InsuredGender": this.renewalZerotatSummary.policyDetails.insuredDetails.InsuredGender,
          "InsuredRelation": this.renewalZerotatSummary.policyDetails.insuredDetails.InsuredRelation,
          "PreIllness": this.renewalZerotatSummary.policyDetails.insuredDetails.PreIllness,
          "InsuredPAN": "",
          "InsuredAadhar": "",
          "InsuredOccupation": "",
          "InsuredEmailID": "",
          "InsuredPassportNo": "",
          "InsuredMobileNo": ""
        }
      ],
      "warranty": null,
      "exclusionDetails": null,
      "conditionDetails": null,
      "clauseDetails": null,
      "coinsuranceDetails": null,
      "financierDetailse": null,
      "loadingDiscount": {
        "Applicable": false,
        "Description": "Other Discount",
        "Rate": "0"
      },
      "installmentUserData": null,
      "DealId": this.renewalZerotatSummary.policyDetails.DealId,
      "StampDutyApplicability": false,
      "Commission": 0,
      "NILEndorsementTypeCode": 0,
      "EndorsementTypeCode": 0,
      "FieldsGrid": [
        {
          "FieldName": "NomineeName",
          "FieldValue": this.renewalZerotatSummary.policyDetails.NomineeName
        },
        {
          "FieldName": "NomineeRelationshipwithInsured",
          "FieldValue": this.renewalZerotatSummary.policyDetails.NomineeRelationship
        },
        {
          "FieldName": "NomineeDateofBirth",
          "FieldValue": this.utilityService.formatDateWithDash(this.renewalZerotatSummary.policyDetails.NomineeDOB)
        },
        {
          "FieldName": "InsuredName",
          "FieldValue": this.renewalZerotatSummary.policyDetails.insuredDetails.InsuredName
        },
        {
          "FieldName": "RelationshipwithApplicant",
          "FieldValue": this.renewalZerotatSummary.policyDetails.insuredDetails.InsuredRelation
        },
        {
          "FieldName": "InsuredDateofBirth",
          "FieldValue": this.utilityService.formatDateWithDash(this.renewalZerotatSummary.policyDetails.insuredDetails.InsuredDOB)
        },
        {
          "FieldName": "Gender",
          "FieldValue": this.renewalZerotatSummary.policyDetails.insuredDetails.InsuredGender
        },
        {
          "FieldName": "PEDillness",
          "FieldValue": ""
        },
        {
          "FieldName": "Occupation",
          "FieldValue": ""
        },
        {
          "FieldName": "PANNo",
          "FieldValue": ""
        },
        {
          "FieldName": "MobileNo",
          "FieldValue": this.renewalZerotatSummary.CustomerDetails.MobileNumber
        },
        {
          "FieldName": "BatchModePrint",
          "FieldValue": "No"
        },
        {
          "FieldName": "ChannelName",
          "FieldValue": ""
        },
        {
          "FieldName": "PaymentDate",
          "FieldValue": "2024-01-28"
        },
        {
          "FieldName": "ChannelName",
          "FieldValue": "Health Agency"
        },
        {
          "FieldName": "80DApplicable",
          "FieldValue": ""
        },
        {
          "FieldName": "80DBasicPremium",
          "FieldValue": ""
        },
        {
          "FieldName": "80DTax",
          "FieldValue": ""
        },
        {
          "FieldName": "80DTotalPremium",
          "FieldValue": ""
        },
        {
          "FieldName": "AlternatePolicyNo",
          "FieldValue": this.renewalZerotatSummary.policyDetails.PolicyNo
        },
        {
          "FieldName": "PolicyWordingBatchMode",
          "FieldValue": ""
        },
        {
          "FieldName": "HSPorNonHSP",
          "FieldValue": "Non HSP"
        },
        {
          "FieldName": "LANNo",
          "FieldValue": ""
        },
        {
          "FieldName": "LoanSanctionAmount",
          "FieldValue": ""
        },
        {
          "FieldName": "LoanDisbursalAmount",
          "FieldValue": ""
        },
        {
          "FieldName": "LoanDisbursalDate",
          "FieldValue": ""
        },
        {
          "FieldName": "LoanSanctionDate",
          "FieldValue": ""
        },
        {
          "FieldName": "LoanTenureinYears",
          "FieldValue": ""
        },
        {
          "FieldName": "MasterPolicyNo",
          "FieldValue": ""
        },
        {
          "FieldName": "MasterPolicyStartDate",
          "FieldValue": ""
        },
        {
          "FieldName": "SpecialCondition",
          "FieldValue": ""
        },
        {
          "FieldName": "EmployeeorNonEmployee",
          "FieldValue": "Non Employee"
        },
        {
          "FieldName": "FundedorNonFunded",
          "FieldValue": "Non Funded"
        }
      ],
      "riskDetails": {
        "PlanCode": this.renewalZerotatSummary.policyDetails.PlanCode,
        "InsuredDateOfBirth": this.utilityService.formatDateWithDash(this.renewalZerotatSummary.policyDetails.insuredDetails.InsuredDOB),
        "InwardDate": PolicyStartDate,
        "RisksList": [
          {
            "RiskSIComponent": "Person",
            "BasisOfSumInsured": "Agreed Value",
            "IsOptionalCover": false,
            "coverDetailsList": this.getCoverDetails(),
            "Rate": 0,
            "SumInsured": 0,
            "Premium": 0,
            "DifferentialSI": 0,
            "EndorsementAmount": 0
          }
        ],
        "Product24062544": null,
        "PreExistingIllness": null,
        "AppointeeName": this.renewalZerotatSummary.policyDetails.AppointeeName ? this.renewalZerotatSummary.policyDetails.AppointeeName : null,
        "AppointeeRelationship": this.renewalZerotatSummary.policyDetails.AppointeeRelationship ? this.renewalZerotatSummary.policyDetails.AppointeeRelationship : null,
        "AppointeeDOB": this.renewalZerotatSummary.policyDetails.AppointeeDOB ? this.utilityService.formatDateWithDash(this.renewalZerotatSummary.policyDetails.AppointeeDOB) : null,
        "NomineeName": this.renewalZerotatSummary.policyDetails.NomineeName,
        "NomineeRelationship": this.renewalZerotatSummary.policyDetails.NomineeRelationship,
        "NomineeDOB": this.utilityService.formatDateWithDash(this.renewalZerotatSummary.policyDetails.NomineeDOB)
      },
      "PreExistingIllness": "",
      "MasterPolicyNumber": "",
      "CorrelationId": this.renewalZerotatSummary.policyDetails.CorelationId,
      "CustomerDetails": {
        "CustomerType": this.renewalZerotatSummary.CustomerDetails.CustomerType == 'I' ? 'INDIVIDUAL' : this.renewalZerotatSummary.CustomerDetails.CustomerType,
        "CustomerName": this.renewalZerotatSummary.CustomerDetails.CustomerName,
        "DateOfBirth": this.utilityService.formatDateWithDash(this.renewalZerotatSummary.CustomerDetails.DOB),
        "PinCode": this.renewalZerotatSummary.CustomerDetails.PermanentPinCode,
        "PANCardNo": this.renewalZerotatSummary.CustomerDetails.Pan,
        "Email": this.renewalZerotatSummary.CustomerDetails.EmailId,
        "MobileNumber": this.renewalZerotatSummary.CustomerDetails.MobileNumber,
        "AddressLine1": this.renewalZerotatSummary.CustomerDetails.PermanentAddressLine1,
        "CountryCode": "100",
        "StateCode": this.renewalZerotatSummary.CustomerDetails.PermanentStateCode,
        "CityCode": this.renewalZerotatSummary.CustomerDetails.PermanentCityCode,
        "Gender": this.renewalZerotatSummary.CustomerDetails.Gender,
        "MobileISD": null,
        "GSTDetails": null,//this.renewalZerotatSummary.CustomerDetails.GSTInData,
        "AadharNumber": "",
        "IsCollectionofform60": false,
        "AadharEnrollmentNo": null,
        "eIA_Number": null,
        "CorelationId": null,
        "CustomerID": this.renewalZerotatSummary.CustomerDetails.CustomerId,
        "ILkycReferenceNumber": this.utilityService.isEmptyOrNull(this.ilKycReferenceNumber) ? this.renewalZerotatSummary.CustomerDetails.ReferenceNo : this.ilKycReferenceNumber,
        "CKYCID": this.utilityService.isEmptyOrNull(this.cKycId) ? this.renewalZerotatSummary.CustomerDetails.CKYCID : this.cKycId,
        "EKYCID": this.utilityService.isEmptyOrNull(this.eKycId) ? this.renewalZerotatSummary.CustomerDetails.EKYCID : this.eKycId,
        "SkipDedupeLogic": "true"
      }
    }
    return request;
  }

  critiShieldProposalRequest() {

    this.renewalZerotatSummary.policyDetails.NomineeName = this.NomineeDetailsForm.controls['nomineeFullName'].value;
    this.renewalZerotatSummary.policyDetails.NomineeRelationship = this.NomineeDetailsForm.controls['nomineeRelationship'].value;
    this.renewalZerotatSummary.policyDetails.NomineeDOB = this.NomineeDetailsForm.controls['nomineeDOB'].value;

    this.renewalZerotatSummary.policyDetails.AppointeeName = this.AppointeeDetailsForm.controls['appointeeFullName'].value
    this.renewalZerotatSummary.policyDetails.AppointeeRelationship = this.AppointeeDetailsForm.controls['appointeeRelationship'].value
    this.renewalZerotatSummary.policyDetails.AppointeeDOB = this.AppointeeDetailsForm.controls['appointeeDOB'].value

    // let riskStartDate = new Date(this.renewalZerotatSummary.policyDetails.PolicyEndDate).getTime() >= new Date().getTime() ? new Date(new Date(this.renewalZerotatSummary.policyDetails.PolicyEndDate).getDate() + 1) : new Date();

    let riskStartDate;
    let policyEndDate = new Date(this.renewalZerotatSummary.policyDetails.PolicyEndDate);
    if (new Date(this.renewalZerotatSummary.policyDetails.PolicyEndDate) >= new Date()) {
      riskStartDate = policyEndDate.setDate(policyEndDate.getDate() + 1);
    } else {
      riskStartDate = new Date();
    }


    const endDate = new Date(this.utilityService.formatDateWithDash(this.renewalZerotatSummary.policyDetails.PolicyEndDate));
    const pEndDate = endDate.setFullYear(endDate.getFullYear() + 1);
    const PolicyEndDate = this.utilityService.formatDateWithDash(pEndDate);
    let req = {

      "UserType": this.utilityService.isEmptyOrNull(this.channelData.UT) ? "AGENT" : this.channelData.UT,
      "DealId": this.renewalZerotatSummary.policyDetails.DealId,
      "ProductCode": this.renewalZerotatSummary.policyDetails.SubProductCode,
      "IpAddress": this.utilityService.isEmptyOrNull(this.channelData.CIP) ? this.healthDataModal.ipAddress : this.channelData.CIP,
      "CorrelationId": this.renewalZerotatSummary.policyDetails.CorelationId,
      "PolicyTenure": this.renewalZerotatSummary['policyDetails']['Tenure'],
      "isRenewal": true,
      "RefNo": this.renewalZerotatSummary.ProposalNo,
      "isProposalModified": this.renewalZerotatSummary.isProposalModified,
      // "ReferenceNumber": this.renewalZerotatSummary.ProposalNo,
      "prevPolicyNo": this.renewalZerotatSummary.policyDetails.PolicyNo,
      "NomineeName": this.renewalZerotatSummary.policyDetails.NomineeName,
      "NomineeRelationship": this.renewalZerotatSummary.policyDetails.NomineeRelationship,
      "NomineeDOB": this.utilityService.formatDateWithDash(this.renewalZerotatSummary.policyDetails.NomineeDOB),
      "RiskStartDate": this.utilityService.formatDateWithDash(riskStartDate),
      "AppointeeName": this.renewalZerotatSummary.policyDetails.AppointeeName,
      "AppointeeRelationship": this.renewalZerotatSummary.policyDetails.AppointeeRelationship, //appointeeFormValue.appointeeRelationshipWithApplicant,
      "AppointeeDOB": this.renewalZerotatSummary.policyDetails.AppointeeDOB ? this.utilityService.formatDateWithDash(this.renewalZerotatSummary.policyDetails.AppointeeDOB) : null,
      "CustomerDetails": {
        "CustomerType": this.renewalZerotatSummary.CustomerDetails.CustomerType == "I" ? "INDIVIDUAL" : "COMPANY",
        "CustomerName": this.renewalZerotatSummary.CustomerDetails.CustomerName,
        "DateOfBirth": this.utilityService.formatDateWithDash(this.renewalZerotatSummary.CustomerDetails.DOB),
        "PinCode": this.renewalZerotatSummary.CustomerDetails.MailingPinCode,
        "Email": this.renewalZerotatSummary.CustomerDetails.EmailId,
        "MobileNumber": this.renewalZerotatSummary.CustomerDetails.MobileNumber,
        "AddressLine1": this.renewalZerotatSummary.CustomerDetails.MailingAddressLine1,
        "AddressLine2": this.renewalZerotatSummary.CustomerDetails.MailingAddressLine2,
        "StateCode": this.renewalZerotatSummary.CustomerDetails.MailingState_Code,
        "CityCode": this.renewalZerotatSummary.CustomerDetails.MailingCityDistrict,
        "CountryCode": 100,
        "Gender": this.renewalZerotatSummary.CustomerDetails.Gender,
        "GSTDetails": null,
        "CustomerID": this.renewalZerotatSummary.CustomerDetails.CustomerId,
        "IpartnerCustomerID": this.renewalZerotatSummary.CustomerDetails.iPartCustomerId,
        "MaritalStatus": this.renewalZerotatSummary.CustomerDetails.MaritalStatus,
      },
      "PlanCode": this.renewalZerotatSummary.policyDetails.PlanCode,
      "InsuredDataDetails": [{
        "RiskCategory": 1,
        "TotalSi": 900001,
        "MemberNo": 1,
        "InsuredName": this.renewalZerotatSummary.policyDetails.insuredDetails.InsuredName,
        // "InsuredID": this.renewalZerotatSummary.policyDetails.insuredDetails.InsuredID,
        "InsuredDOB": this.utilityService.formatDateWithDash(this.renewalZerotatSummary.policyDetails.insuredDetails.InsuredDOB),
        "InsuredAge": this.renewalZerotatSummary.policyDetails.insuredDetails.InsuredAge ? this.renewalZerotatSummary.policyDetails.insuredDetails.InsuredAge : '',
        "InsuredGender": this.renewalZerotatSummary.policyDetails.insuredDetails.InsuredGender,
        "InsuredRelation": this.renewalZerotatSummary.policyDetails.insuredDetails.InsuredRelation,
        "PreIllness": this.renewalZerotatSummary.policyDetails.insuredDetails.PreIllness,
        "InsuredPAN": "",
        "InsuredAadhar": "",
        "InsuredOccupation": "Salaried-riskCategory-1",
        "InsuredEmailID": "",
        "InsuredPassportNo": "",
        "InsuredMobileNo": "",
        "InsuredWeight": "70",
        "InsuredHeightInFeet": "5",
        "InsuredHeightInInches": "5",
        "FieldsGrid":
          [{
            "FieldName": "InsuredName", "FieldValue": this.renewalZerotatSummary.policyDetails.insuredDetails.InsuredName
          },
          { "FieldName": "RelationshipwithApplicant", "FieldValue": this.renewalZerotatSummary.policyDetails.insuredDetails.InsuredRelation },
          { "FieldName": "InsuredDateofBirth", "FieldValue": this.utilityService.formatDateWithDash(this.renewalZerotatSummary.policyDetails.insuredDetails.InsuredDOB) },
          { "FieldName": "Gender", "FieldValue": this.renewalZerotatSummary.policyDetails.insuredDetails.InsuredGender },
          { "FieldName": "NomineeName", "FieldValue": this.renewalZerotatSummary.policyDetails.NomineeName },
          { "FieldName": "NomineeRelationshipwithInsured", "FieldValue": '', },
          { "FieldName": "NomineeDateofBirth", "FieldValue": this.utilityService.formatDateWithDash(this.renewalZerotatSummary.policyDetails.NomineeDOB) },
          { "FieldName": "PEDillness", "FieldValue": "" },
          { "FieldName": "80DApplicable", "FieldValue": "Yes" }
          ],
        "CoverDetails": this.getCritishieldCover(),
        "IncomeSlab": "700001 - 800000"

      }]
    }
    if (this.isKYCDoneSuccessfully) {
      req["CustomerDetails"]["PepFlag"] = !this.utilityService.isEmptyOrNull(this.pepFlag) ? this.pepFlag : false;
      req["CustomerDetails"]["SkipDedupeLogic"] = "true";
      req["CustomerDetails"]["CKYCID"] = this.utilityService.isEmptyOrNull(this.cKycId) ? this.renewalZerotatSummary.CustomerDetails.CKYCID : this.cKycId;
      req["CustomerDetails"]["EKYCID"] = this.utilityService.isEmptyOrNull(this.eKycId) ? this.renewalZerotatSummary.CustomerDetails.EKYCID : this.eKycId;
      req["CustomerDetails"]["ILkycReferenceNumber"] = this.utilityService.isEmptyOrNull(this.ilKycReferenceNumber) ? this.renewalZerotatSummary.CustomerDetails.ReferenceNo : this.ilKycReferenceNumber;

    }
    let customerDetails = {
      EmailAddress: this.renewalZerotatSummary.CustomerDetails.EmailId,
      MobileNumber: this.renewalZerotatSummary.CustomerDetails.MobileNumber
    }


    return req;
  }

  openDeclarationPopup(declarationType: string) {
    let data = {
      rnAgent: this.rnAgent,
      productType: this.productType
    }

    this.declarationService.openDeclarationPopup(declarationType, data);
  }

  onRefresh(status) {
    if (status) {
      this.ngOnInit();
    }
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
