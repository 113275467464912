import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DownloadResponsePayload } from '../interface/file';
import { DownloadCouponRequestPayload, FetchPoliciesRequestPayload, FetchPoliciesResponsePayload, ProposalStatusRequestPayload, ProposalStatusResponsePayload, fetchPropToModifyPayload, fetchPropToModifyResponse } from '../interface/mypolicy';
import { GenericService } from './generic.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class MyPolicyService {
  private editPortabilityFlag = new Subject<boolean>();
  editPortabilityFlag$ = this.editPortabilityFlag.asObservable();

  constructor(private generic: GenericService, private storageService: StorageService,
    private http: HttpClient) { }
  readonly baseURI = `${environment.baseURL}`;
  readonly fetchPolicies = 'Policy/FetchMyPoliciesV1';
  readonly downloadCoupon = 'HealthMaster/DownloadHealthCoupons';
  readonly download = 'File/DownloadPDF';
  readonly getProposalStatus = 'Policy/GetProposalStatus';
  readonly fetchSelectedPolicy = 'Health/FetchPropToModifyIhealth';

  emitValue(value: boolean) {
    this.editPortabilityFlag.next(value);
  }

  fetchMypoliciesDetails(data: FetchPoliciesRequestPayload): Observable<FetchPoliciesResponsePayload> {
    const endpoint = `${this.baseURI}${this.fetchPolicies}`;
    return this.generic.genericService<FetchPoliciesResponsePayload>(endpoint, data);
  }

  fetchSelectedPolicyDetails(request: fetchPropToModifyPayload): Observable<fetchPropToModifyResponse> {
    return this.http.post<fetchPropToModifyResponse>(this.baseURI + this.fetchSelectedPolicy, request);
  }

  downloadCoupons(data: DownloadCouponRequestPayload): Observable<DownloadResponsePayload> {
    const endpoint = `${this.baseURI}${this.downloadCoupon}`;
    return this.generic.genericService<DownloadResponsePayload>(endpoint, data);
  }

  getUnderWritterStatus(data: ProposalStatusRequestPayload): Observable<ProposalStatusResponsePayload> {
    const endpoint = `${this.baseURI}${this.getProposalStatus}`;
    return this.generic.genericService<ProposalStatusResponsePayload>(endpoint, data);
  }


}
