import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { GenericService } from "../services/generic.service";
import { Observable } from "rxjs";
import { GetAddOnListRequestPayload, GetAddOnListResponsePayload, GetHEPAddOnListRequestPayload, GetHEPAddOnListResponsePayload, GetRNRenewalNotificationRequestPayload, GetRenewalProposalResponsePayload, GetRenewalRecalculateRequestPayload, GetRenewalRecalculateResponsePayload, GetRenewalReminderSearchRequestPayload, GetRenewalReminderSearchResponsePayload, GetRenewalSendMailRequestPayload, GetRenewalSendMailResponsePayload, PincodeIDRequestPayload, PincodeIDResponsePayload, RenewalPreProposalRequestPayload, RenewalPreProposalResponse, SaveEditCustomerRequestPayload, SaveEditCustomerResponsePayload, renewalBefitApplicableRequestPayload, renewalBefitApplicableResponsePayload, renewalBefitRequestPayload, renewalBefitResponsePayload, salesGenieComponentPayload, salesGenieComponentResponse } from "../interface/renewal";
import { ZeroTatRelationshipResponsePayload } from "../interface/health";

@Injectable({
  providedIn: 'root'
})
export class RenewalService {

  constructor(private generic: GenericService) { }

  readonly baseURI = `${environment.baseURL}`;
  readonly getRenewalSearchURL = 'HealthMaster/GetRenewalReminderSearch';
  readonly getRNRenewalNotificationURL = 'RenewalHealth/RnNotification';
  readonly getSendMailRenewalURL = 'RenewalHealth/SendMailRnNotification';
  readonly getAddOnListURL = 'RenewalHealth/RenewalAddonMaster';
  readonly getHEPAddOnListURL = 'RenewalHealth/GetAddonApplicablityBySubProductCodeFromILESB';
  readonly getRenewalRecalculateURL = 'RenewalHealth/HealthRenewalRecalculate';
  readonly getRenewalProposalURL = 'renewalhealth/HealthRenewalSaveEditProposal';
  readonly salesGeniecomponentURL = 'RenewalHealth/SalesGenieComponent';
  readonly getSaveCustomerUrl = 'customer/saveeditcustomer';
  readonly getPincodeIdURL = 'RTOList/GetPincodeID'
  readonly getRenewalBefitPlanURL = 'RenewalHealth/CheckIsBefitApplicable'
  readonly zeroTatRelationship = 'CritiShield/ZeroTatRelationship'
  readonly zeroTatProposalURL = `renewalhealth/ZerotatRenewalProposal`
  readonly critishieldProposalURL = `Health/ZeroTatProposal`
  readonly renewalPreProposalListURL =`Health/RNPreproposalList`
  readonly homeProposalURL = `HomeRenewal/HomeRenewalCreateProposal`

  getRenewalSearchDetails(data: GetRenewalReminderSearchRequestPayload): Observable<GetRenewalReminderSearchResponsePayload> {
    const endpoint = `${this.baseURI}${this.getRenewalSearchURL}`;
    return this.generic.genericService<GetRenewalReminderSearchResponsePayload>(endpoint, data);
  }

  getRNRenewalNotification(data: GetRNRenewalNotificationRequestPayload): Observable<[]> {
    const endpoint = `${this.baseURI}${this.getRNRenewalNotificationURL}`;
    return this.generic.genericService<[]>(endpoint, data);
  }

  renewalSendMail(data: GetRenewalSendMailRequestPayload): Observable<GetRenewalSendMailResponsePayload> {
    const endpoint = `${this.baseURI}${this.getSendMailRenewalURL}`;
    return this.generic.genericService<GetRenewalSendMailResponsePayload>(endpoint, data);
  }

  getAddonList(data: GetAddOnListRequestPayload): Observable<GetAddOnListResponsePayload> {
    const endpoint = `${this.baseURI}${this.getAddOnListURL}`;
    return this.generic.genericService<GetAddOnListResponsePayload>(endpoint, data);
  }

  getHEPAddonList(data: GetHEPAddOnListRequestPayload): Observable<GetHEPAddOnListResponsePayload> {
    const endpoint = `${this.baseURI}${this.getHEPAddOnListURL}`;
    return this.generic.genericService<GetHEPAddOnListResponsePayload>(endpoint, data);
  }

  renewalRecalculate(data: GetRenewalRecalculateRequestPayload): Observable<GetRenewalRecalculateResponsePayload> {
    const endpoint = `${this.baseURI}${this.getRenewalRecalculateURL}`;//this.getRenewalRecalculateURL;
    return this.generic.genericService<GetRenewalRecalculateResponsePayload>(endpoint, data);
  }

  renewalProposal(data: any): Observable<GetRenewalProposalResponsePayload> {
    const endpoint = `${this.baseURI}${this.getRenewalProposalURL}`;//this.getRenewalProposalURL;
    return this.generic.genericService<GetRenewalProposalResponsePayload>(endpoint, data);

  }

  salesGenieComponent(payload: salesGenieComponentPayload): Observable<salesGenieComponentResponse> {
    return this.generic.genericService<salesGenieComponentResponse>(`${this.baseURI}${this.salesGeniecomponentURL}`, payload);
  }

  renewalSaveCustomer(data: SaveEditCustomerRequestPayload) {
    const endpoint = `${this.baseURI}${this.getSaveCustomerUrl}`;//this.getSaveCustomerUrl;
    return this.generic.genericService<SaveEditCustomerResponsePayload>(endpoint, data);
  }

  getPincodeID(data: PincodeIDRequestPayload) {
    const endpoint = `${this.baseURI}${this.getPincodeIdURL}`;
    return this.generic.genericService<PincodeIDResponsePayload>(endpoint, data);
  }

  getBefitPlanPremiumDetails(data: renewalBefitRequestPayload) {
    const endpoint = `${this.baseURI}${this.getRenewalBefitPlanURL}`;
    return this.generic.genericService<renewalBefitResponsePayload>(endpoint, data);
  }

  getBefitApplicableDetails(data: renewalBefitApplicableRequestPayload) {
    const endpoint = `${this.baseURI}${this.getRenewalBefitPlanURL}`;
    return this.generic.genericService<renewalBefitApplicableResponsePayload>(endpoint, data);
  }

  ZeroTatRelationship(): Observable<ZeroTatRelationshipResponsePayload> {
    const endpoint = `${this.baseURI}${this.zeroTatRelationship}`;
    return this.generic.genericService<ZeroTatRelationshipResponsePayload>(endpoint);
  }

  ZeroTatProposal(data: any): Observable<any> {
    const endpoint = `${this.baseURI}${this.zeroTatProposalURL}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  critishieldProposal(data: any): Observable<any> {
    const endpoint = `${this.baseURI}${this.critishieldProposalURL}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  renewalPreProposalList(data:RenewalPreProposalRequestPayload): Observable<RenewalPreProposalResponse> {
    const endpoint = `${this.baseURI}${this.renewalPreProposalListURL}`;
    return this.generic.genericService<RenewalPreProposalResponse>(endpoint,data);
  }

  homeProposal(data: any): Observable<any> {
    const endpoint = `${this.baseURI}${this.homeProposalURL}`;
    return this.generic.genericService<any>(endpoint, data);
  }

}