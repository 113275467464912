<mat-tab-group class="tabInnerdiv" *ngIf="loadPanel && tabGroup!=undefined  && !serviceError"
    (selectedTabChange)="memberTypeSelected($event)">

    <!-- Creation of tabs -->
    <mat-tab class="" *ngFor="let tabData of tabGroup">
        <!-- Adult & Child tab -->
        <ng-template mat-tab-label>
            <!-- For Adult & Child step -->
            <!-- <mat-icon matPrefix class="cstm_icon">
                <img src="{{tabData.imgPath}}" alt=""
                    [ngClass]="{'adultIcon' : tabData.tabLabel == 'Adult', 'childIcon' : tabData.tabLabel == 'Child'}"
                    class="icon">
            </mat-icon> -->
            {{tabData.tabLabel}}
        </ng-template>

        <!-- Creation of Adult & Child buttons -->
        <div class="fieldsdiv" *ngIf="tabData.memberDetails.length > 1">
            <div class="row">
                <div class="col-sm-12 mt-3 form-group groupingbtn">
                    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="policyToggle_group">
                        <mat-button-toggle class="PolicyBtn" (click)="memberChanged(tabData.tabLabel,k)"
                            *ngFor="let data of tabData.memberDetails; let k = index" [checked]="data.selected">
                            {{ data.label}}
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </div>
        </div>

        <!-- Details Of Members -->
        <mat-divider></mat-divider>
        <!-- <h3>{{tabData.tabLabel }} Details</h3> -->
        <form [formGroup]="zerotatinsuredDetailsForm" *ngIf="totalData[memberDataIndex].controls!=undefined">
            <div [class.scrollcontainer]="hidePED" [class.scrollcontainerPED]="!hidePED">
                <div class="scrollRow">
                    <div class="fieldsdiv">
<!-- [ngClass]="{'col-sm-3 form-group': !totalData[memberDataIndex].controls[k].hide}" -->
                        <div class="row" formArrayName='memberDetails'>
                            <div style="margin-bottom: 15px;"  [ngClass]="getClassObject(totalData[memberDataIndex].controls[k])"
                                *ngFor="let formKeys of memberDetails.controls[memberDataIndex]['controls'] | keyvalue; let k = index;"
                                [formGroupName]="memberDataIndex">

                                <!-- If type is radio -->
                                <div class="col-sm-12 form-group"
                                    *ngIf="totalData[memberDataIndex].controls[k].type == 'radio' && !totalData[memberDataIndex].controls[k].hide">
                                    <label class="singlelabel mb-2"
                                        style="width: 100%;">{{totalData[memberDataIndex].controls[k].label}}</label>
                                    <div class="group_btn">
                                        <mat-button-toggle-group name="fontStyle" aria-label="Font Style"
                                            class="custom-toggle-group"
                                            formControlName="{{totalData[memberDataIndex].controls[k].controlName + (totalData[memberDataIndex].memberTypeCount)}}"
                                            (change)="changeRadioValue(totalData[memberDataIndex].controls[k].controlName,$event.value,memberDataIndex,totalData[memberDataIndex].memberTypeCount)">
                                            <mat-button-toggle [value]="radioData.value" [disabled]="radioData.disabled"
                                                class="custom_togglebtn" value="{{k ==0 ?'bold': 'italic'}}"
                                                *ngFor="let radioData of totalData[memberDataIndex].controls[k].value; let j = index">
                                                {{radioData.label }}</mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </div>
                                </div>

                                <!-- If type is dropdown -->
                                <mat-label class="input-group__label" for="myInput"
                                    *ngIf="totalData[memberDataIndex].controls[k].type == 'dropdown' && !totalData[memberDataIndex].controls[k].hide">{{totalData[memberDataIndex].controls[k].label}}</mat-label>
                                <mat-select class="input-group__input cstm_select"
                                    [id]="totalData[memberDataIndex].controls[k].controlName + (totalData[memberDataIndex].memberTypeCount)"
                                    required (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                                    *ngIf="totalData[memberDataIndex].controls[k].type == 'dropdown' && !totalData[memberDataIndex].controls[k].hide"
                                    formControlName="{{totalData[memberDataIndex].controls[k].controlName + (totalData[memberDataIndex].memberTypeCount)}}"
                                    [disabled]="totalData[memberDataIndex].controls[k].disabled"
                                    [placeholder]="totalData[memberDataIndex].controls[k].placeholder">
                                    <ng-container
                                        *ngFor="let dropdownData of totalData[memberDataIndex].controls[k].value; let last = last">
                                        <mat-option value="{{dropdownData.label}}">{{utilityService.capitalize(dropdownData.label)}}</mat-option>
                                        <mat-divider *ngIf="!last"></mat-divider>
                                    </ng-container>
                                </mat-select>

                                <!-- Error for dropdown -->
                                
                                    <mat-error
                                        *ngIf="memberDetails.controls[memberDataIndex]['controls'][totalData[memberDataIndex].controls[k].controlName + (totalData[memberDataIndex].memberTypeCount)].hasError('required') && isSubmit && totalData[memberDataIndex].controls[k].type == 'dropdown'&& !totalData[memberDataIndex].controls[k].hide">
                                        {{totalData[memberDataIndex].controls[k].label}} is required
                                    </mat-error>

                                <!-- If type is input -->
                                <mat-label class="input-group__label" for="myInput"
                                    *ngIf="totalData[memberDataIndex].controls[k].type == 'input' && !totalData[memberDataIndex].controls[k].hide">{{totalData[memberDataIndex].controls[k].label}}</mat-label>
                                <input type="text" class="input-group__input"
                                    *ngIf="totalData[memberDataIndex].controls[k].type == 'input' && !totalData[memberDataIndex].controls[k].hide"
                                    [type]="totalData[memberDataIndex].controls[k].inputType" matInput
                                    [id]="totalData[memberDataIndex].controls[k].controlName + (totalData[memberDataIndex].memberTypeCount)"
                                    [maxlength]="totalData[memberDataIndex].controls[k].maxLength"
                                    [readonly]="totalData[memberDataIndex].controls[k].disabled"
                                    [max]="totalData[memberDataIndex].controls[k].maxNumber"
                                    [min]="totalData[memberDataIndex].controls[k].minNumber"
                                    formControlName="{{totalData[memberDataIndex].controls[k].controlName + (totalData[memberDataIndex].memberTypeCount)}}"
                                    placeholder="{{totalData[memberDataIndex].controls[k].placeholder}}" required
                                    (paste)="utilityService.onPaste($event,totalData[memberDataIndex].controls[k].onlyAlphabets,totalData[memberDataIndex].controls[k].onlyNumbers)"
                                    (input)="feetHeightFormatting($event,totalData[memberDataIndex],memberDataIndex, totalData[memberDataIndex].controls[k].onlyAlphabets, totalData[memberDataIndex].controls[k].onlyNumbers)"  autocomplete="off"/>

                                <!-- Error for input values -->
                                <mat-error
                                    *ngIf="memberDetails.controls[memberDataIndex]['controls'][totalData[memberDataIndex].controls[k].controlName + (totalData[memberDataIndex].memberTypeCount)].hasError('required') && isSubmit && totalData[memberDataIndex].controls[k].type == 'input' && !totalData[memberDataIndex].controls[k].hide">
                                    {{totalData[memberDataIndex].controls[k].label}} is required
                                </mat-error>

                                <!-- This needs to be checked  temp validation  is applied for weight and height in feet can't be 0-->
                                <mat-error
                                    *ngIf="memberDetails.controls[memberDataIndex]['controls'][totalData[memberDataIndex].controls[k].controlName + (totalData[memberDataIndex].memberTypeCount)].hasError('invalidNumber') && (totalData[memberDataIndex].controls[k].controlName == 'weight' || totalData[memberDataIndex].controls[k].controlName == 'feetHeight') && isSubmit && totalData[memberDataIndex].controls[k].type == 'input' && !totalData[memberDataIndex].controls[k].hide">
                                    {{totalData[memberDataIndex].controls[k].label}} can't be 0
                                </mat-error>

                                <!-- Invalid pattern error -->
                                <mat-error
                                    *ngIf="memberDetails.controls[memberDataIndex]['controls'][totalData[memberDataIndex].controls[k].controlName + (totalData[memberDataIndex].memberTypeCount)].hasError('pattern') && isSubmit && totalData[memberDataIndex].controls[k].type == 'input' && !totalData[memberDataIndex].controls[k].hide">
                                    {{totalData[memberDataIndex].controls[k].label}} is invalid
                                </mat-error>
                                <!-- If type is datepicker -->
                                <label class="input-group__label" for="myInput"
                                    *ngIf="totalData[memberDataIndex].controls[k].type == 'datepicker' && !totalData[memberDataIndex].controls[k].hide">{{totalData[memberDataIndex].controls[k].label}}</label>
                                <input class="input-group__input dob" matInput
                                    *ngIf="totalData[memberDataIndex].controls[k].type == 'datepicker' && !totalData[memberDataIndex].controls[k].hide"
                                    [matDatepicker]="picker1"
                                    [id]="totalData[memberDataIndex].controls[k].controlName + (totalData[memberDataIndex].memberTypeCount)"
                                    formControlName="{{totalData[memberDataIndex].controls[k].controlName + (totalData[memberDataIndex].memberTypeCount)}}"
                                    [min]="totalData[memberDataIndex].controls[k].minDate"
                                    [max]="totalData[memberDataIndex].controls[k].maxDate"
                                    [disabled]="totalData[memberDataIndex].controls[k].disabled"
                                    placeholder="DD/MM/YYYY" (click)="picker1.open()" readonly autocomplete="off">
                                <mat-datepicker-toggle matIconSuffix [for]="picker1"
                                    *ngIf="totalData[memberDataIndex].controls[k].type == 'datepicker' && !totalData[memberDataIndex].controls[k].hide">
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker1 touchUi></mat-datepicker>

                                <!-- Error for DatePicker -->
                                <mat-error
                                    *ngIf="memberDetails.controls[memberDataIndex]['controls'][totalData[memberDataIndex].controls[k].controlName + (totalData[memberDataIndex].memberTypeCount)].hasError('required') && isSubmit && totalData[memberDataIndex].controls[k].type == 'datepicker'&& !totalData[memberDataIndex].controls[k].hide">
                                    {{totalData[memberDataIndex].controls[k].label}} is required
                                </mat-error>
                            </div>

                        </div>
                        <!-- Pre existing disease -->
                        <mat-divider *ngIf="!totalData[memberDataIndex].existingDiseaseHide"></mat-divider>

                        <h3 class="pedHeading" *ngIf="!totalData[memberDataIndex].existingDiseaseHide">Medical &
                            lifestyle history of
                            {{totalData[memberDataIndex].memberTypeCount}}
                        </h3>

                        <div class="fieldsdiv pedFieldDiv pr-0 pl-0 mb-3">
                            <div *ngIf="!totalData[memberDataIndex].existingDiseaseHide" class="">
                                <div class="scrollRow" formArrayName="preExistingDisease">
                                    <!-- : !totalData[memberDataIndex].existingDisease[k].remarksApplicable && totalData[memberDataIndex].existingDisease[k].type == 'radio' -->
                                    <div class="row innerBorder"
                                        *ngFor="let formKeys of preExistingDisease.controls[memberDataIndex]['controls'] | keyvalue; let k = index;"
                                        [formGroupName]="memberDataIndex">
                                        <div class="col-sm-12">
                                            <table class="diseaseTable">
                                                <caption class="hidden-caption">Details</caption>
                                                <th class="hidden-caption">PED</th>
                                                <td class="hidden-caption">PED Details</td>
                                                <tr>
                                                    <td>
                                                        <!-- Pre existing disease label -->
                                                        <label for="" class="singlelabel" style="min-width: 83%;">
                                                            {{totalData[memberDataIndex].existingDisease[k].Name}}
                                                        </label>

                                                        <!-- If remarks applicable -->
                                                        <div
                                                            *ngIf="totalData[memberDataIndex].existingDisease[k].showRemarks">
                                                            <mat-label class="input-group__label" for="myInput">

                                                            </mat-label>
                                                            <textarea type="text" id="myInput" style="width: 50%;" class="input-group__input"
                                                                matInput
                                                                placeholder="{{totalData[memberDataIndex].existingDisease[k].remarksLabel}}" ></textarea>

                                                            <!-- <mat-error >
                                                                Please enter reason
                                                            </mat-error> -->
                                                        </div>
                                                    </td>

                                                    <!-- Yes No buttons -->
                                                    <td>
                                                        <mat-checkbox class="cstmCheckbox"
                                                            *ngIf="!totalData[memberDataIndex].existingDisease[k].remarksApplicable && totalData[memberDataIndex].existingDisease[k].type == 'radio'"
                                                            formControlName="{{formKeys.key}}"
                                                            (change)="preExistingDiseaseControls(formKeys.key,memberDataIndex,k,totalData[memberDataIndex].existingDisease[k].Value,$event.checked)">
                                                        </mat-checkbox>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </mat-tab>

</mat-tab-group>

<app-error-screen *ngIf="serviceError" (refreshService)="onRefresh($event)">
</app-error-screen>


<app-custom-footer [normalButton]="true"
    [buttonData]="[{'label': 'Save & Proceed', 'type': 'primary', 'source': 'insured', 'id': 'f3','disabled': false , 'hide': false }]"
    (buttonClicked)="onSubmit($event)">
</app-custom-footer>