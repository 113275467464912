<app-header [dashBoardStatus]="true"></app-header>

<div class="paymentconfirmation" *ngIf="loadContent">
  <div class="paymentDiv">
    <div class="paymentmaindiv">
      <img src="../../../../assets/images/pay_animate.gif" alt="" class="paymentIcon">
    </div>

    <div class="paymentInformation">
      <div class="message">
        <span>Congratulations!</span>
        <strong>Thank you! Your payment of <div class="paymentValue">
            ₹{{utilityService.convertStringtoCurrency(this.healthFormData.paymentConfirmationDetails.TotalPremium)}}
          </div>
          has been received</strong>
      </div>

      <mat-divider></mat-divider>

      <div class="transactionDetails">
        <div>
          <span class="title">Payment ID</span>
          <span class="value">{{this.healthFormData.paymentConfirmationDetails.PfTransactionId}}</span>
        </div>

        <div class="border"></div>

        <div
          *ngIf="this.healthFormData.paymentConfirmationDetails.PolicyNumber && this.healthFormData.paymentConfirmationDetails.TransFor != 'R'">
          <span class="title">Policy Number</span>
          <span class="value">{{this.healthFormData.paymentConfirmationDetails.PolicyNumber}}</span>
        </div>

        <div *ngIf="this.healthFormData.paymentConfirmationDetails.PolicyNumber" class="border border-2"></div>

        <div>
          <span class="title">Proposal Number</span>
          <span class="value">{{this.healthFormData.paymentConfirmationDetails.PfProposalNumber}}</span>
        </div>

        <div class="border"></div>

        <div>
          <span class="title">Start Date</span>
          <span class="value">{{this.healthFormData.paymentConfirmationDetails.StartDate}}</span>
        </div>

        <div class="border"></div>

        <div>
          <span class="title">End Date</span>
          <span class="value">{{this.healthFormData.paymentConfirmationDetails.EndDate}}</span>
        </div>
      </div>
    </div>

    <div class="actionButton">
      <div class="buttonDiv"
        *ngIf="!this.healthFormData.paymentConfirmationDetails.PolicyNumber && this.healthFormData.paymentConfirmationDetails.TransFor == 'R'">
        <button mat-raised-button class="primary-button" (click)="goToUploadDocument()">Upload Document</button>
      </div>

      <div class="buttonDiv"
        *ngIf="this.healthFormData.paymentConfirmationDetails.PolicyNumber && this.healthFormData.paymentConfirmationDetails.TransFor != 'R'">
        <button mat-raised-button class="primary-button" (click)="downloadPolicy()">Download Policy</button>
      </div>

      <!-- <div class="buttonDiv"
        *ngIf="((bflag == 'true')?false:true) && this.healthFormData.paymentConfirmationDetails.PolicyNumber && !hideDashboard">
        <button mat-raised-button class="primary-button" (click)="goToDashboard()">Dashboard</button>
      </div> -->
    </div>


    <div class="my_Policy">
      <span>Kindly view your policy on my policies
        <a style="cursor: pointer;" (click)="goToDashboard()">dashboard</a>
      </span>
    </div>
    
  </div>
</div>