<div class="container-fluid">
    <div class="row">
        <div class="container panaMaincontainer">
            <div class="mainDiv marginbox">
                <form>
                    <mat-card class="innerbox borderinnerbox ">
                        <h3>Renewal Premium Payable</h3>
                        <div class="fieldsdiv mb-3">
                            <div class="renewaldiv ">
                                <h2 class=" renewalHeading mt-2">Choose Coverage Period</h2>
                                <div class="plan-radioDiv container p-0">
                                    <div class="scrollable">
                                        <ul class="sumInsRadio">
                                            <li *ngFor="let coverageData of this.premiumData; let i = index">
                                                <input type="radio" id="{{'pickSumIns_'+i+'lacs'}}" name="pickSumIns"
                                                    [checked]="selectedCoverageIndex == i" />

                                                <label for="{{'pickSumIns_'+i+'lacs'}}" (click)="selectCoverage(i)">
                                                    <div class="container plansAmountDiv">
                                                        <div class="row">
                                                            <div class="col-sm-12 form-group">
                                                                <span class="planLabel">Premium for
                                                                    {{this.renewalZerotatSummary['policyDetails']['Tenure']}}
                                                                    year</span>
                                                            </div>
                                                            <div class="col-sm-12 form-group">
                                                                <span
                                                                    class="Planamount">₹{{coverageData.TotalPremium}}</span>
                                                            </div>
                                                            <div class="col-sm-12">
                                                                <table class="viewsummaryTable ">
                                                                    <tr>
                                                                        <td colspan="2">Base premium</td>
                                                                        <td>₹{{coverageData.BasicPremium }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="2">GST@18%</td>
                                                                        <td>₹{{coverageData.TotalTax }}</td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </label>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </form>

                <mat-expansion-panel class="customExpansion kycExpansion mb-2 marginBox" [expanded]="true"
                    disabled="true" hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h3>KYC Details</h3>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="fieldsdiv">
                        <div class="row">
                            <!-- *ngIf="showEKYC && !showSearchPopup" -->
                            <div class="col-sm-12 updateKYC">
                                <!-- If kyc is not completed -->
                                <div class="verifyDiv verifyNew" *ngIf="!isKYCDoneSuccessfully">
                                    <span><img src="../../../assets/images/verifyIndicator.gif" alt=""></span>
                                    <span> Please complete your KYC verification </span>
                                    <button class="secondarybtn_2 ml-2" (click)="callKYC()">Update</button>
                                </div>

                                <!-- If kyc is completed -->
                                <div class="verifyDiv bgVerify" *ngIf="isKYCDoneSuccessfully" style="margin-bottom: 3;">
                                    <span><img src="../../../assets/images/complete.gif" alt=""></span>
                                    <span>KYC Verification successfully completed</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>

                <mat-card class="innerbox plandetailPanel borderinnerbox">
                    <div>
                        <h3>Insured Details</h3>
                    </div>
                    <div class="fieldsdiv" *ngFor=" let insuredData of insuredData; let i = index">
                        <span class="subheading pb-5"> Adult {{i + 1}}</span>
                        <div class="row customDiv planrowData">
                            <div class="col-sm-3 form-group width100 order-1 order-sm-1">
                                <mat-label class="mtlabel " for="myInput">Full Name</mat-label>
                                <p>{{insuredData.InsuredName}}</p>
                            </div>
                            <div class="col-sm-3 form-group  order-3 order-sm-3 ">
                                <mat-label class="mtlabel" for="myInput">Relationship with Applicant</mat-label>
                                <p>{{insuredData.InsuredRelation}}</p>
                            </div>
                            <div class="col-sm-3 form-group  order-2 order-sm-2">
                                <mat-label class="mtlabel" for="myInput">Date of Birth</mat-label>
                                <p>{{insuredData.InsuredDOB}}</p>
                            </div>
                            <div class="col-sm-3 form-group  order-4 order-sm-4">
                                <mat-label class="mtlabel" for="myInput">Gender</mat-label>
                                <p>{{insuredData.InsuredGender}}</p>
                            </div>
                        </div>
                    </div>
                </mat-card>

                <mat-card class="innerbox plandetailPanel borderinnerbox">
                    <div>
                        <h3>Cover Details</h3>
                    </div>
                    <div class="container mb-3">
                        <table class="premTable cstmtable col-md-7 col-12">
                            <thead>
                                <tr>
                                    <th class='pt-0 pb-0'>Cover Name</th>
                                    <th class='pt-0 pb-0'>Sum Insured (₹)</th>
                                    <th class='pt-0 pb-0'>No of Days</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor='let coverData of this.renewalZerotatSummary.policyDetails.coverDetails'>
                                    <td class="py-2">{{coverData.CoverName}}</td>
                                    <td class="py-2">{{coverData.SumInsured}}</td>
                                    <td class="py-2">{{coverData.Column1}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </mat-card>

                <!-- Applicant Details Card -->
                <mat-card class="innerbox plandetailPanel borderinnerbox ">
                    <div>
                        <h3>Applicant Details</h3>
                    </div>
                    <div class="fieldsdiv">
                        <div class="row customDiv planrowData">
                            <div class="col-lg-2 col-md-2 col-sm-2 form-group">
                                <mat-label class="mtlabel planselect" for="myInput">Applicant Full Name</mat-label>
                                <p>{{this.ApplName}}</p>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2 form-group">
                                <mat-label class="mtlabel planselect" for="myInput">Address line 01</mat-label>
                                <p>{{this.Addressl1}}</p>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2 form-group" *ngIf="this.Addressl2">
                                <mat-label class="mtlabel planselect" for="myInput">Address line 02</mat-label>
                                <p>{{this.Addressl2}}</p>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2 form-group">
                                <mat-label class="mtlabel planselect" for="myInput">Pincode</mat-label>
                                <p>{{this.pincode}}</p>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2 form-group">
                                <mat-label class="mtlabel planselect" for="myInput">City</mat-label>
                                <p>{{this.City}}</p>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2 form-group">
                                <mat-label class="mtlabel planselect" for="myInput">State</mat-label>
                                <p>{{this.State}}</p>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2 form-group">
                                <mat-label class="mtlabel planselect" for="myInput">Date of Birth</mat-label>
                                <p>{{this.DOB}}</p>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2 form-group">
                                <mat-label class="mtlabel planselect" for="myInput">Mobile Number</mat-label>
                                <p>{{this.Mobile}}</p>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2 form-group">
                                <mat-label class="mtlabel planselect" for="myInput">Email</mat-label>
                                <p class="wrapping">{{this.Email}}</p>
                            </div>
                        </div>
                    </div>
                </mat-card>

                <!-- Nominee details form -->
                <mat-card class="innerbox reverinnerbox borderinnerbox">
                    <form [formGroup]="NomineeDetailsForm">
                        <h3>Nominee Details</h3>
                        <div class="row">
                            <div class="col-sm-3 form-group order-3 order-sm-3 mwidth">
                                <mat-label class="input-group__label" for="myInput">Full Name</mat-label>
                                <input matInput class="input-group__input" type="text"
                                    formControlName="nomineeFullName">
                                <mat-error
                                    *ngIf="NomineeDetailsForm.controls['nomineeFullName']?.errors?.['required'] && NomineeDetailsForm.controls['nomineeFullName'].touched">
                                    Nominee full name is required
                                </mat-error>
                            </div>
                            <div class="col-sm-3 form-group order-3 order-sm-3 mwidth">
                                <div class="ml-1">
                                    <label class="input-group__label" style="top: 13px;">Relationship with
                                        Applicant</label>
                                    <mat-select class="input-group__input cstm_select"
                                        placeholder="Select nominee relationship" formControlName="nomineeRelationship"
                                        required>
                                        <mat-option *ngFor="let option of nomineeRelationshipDetails"
                                            [value]="option.Name">
                                            {{option.Name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="NomineeDetailsForm.controls['nomineeRelationship'].touched && NomineeDetailsForm.controls['nomineeRelationship']?.errors?.['required'] ">
                                        Nominee relationship is required
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-sm-3 form-group order-3 order-sm-3 mwidth">
                                <mat-label class="input-group__label">Date of Birth </mat-label>
                                <input class="input-group__input dob" matInput [matDatepicker]="nomineeDOB"
                                    formControlName="nomineeDOB" [min]="nomineeMinDate" [max]="nomineeMaxDate"
                                    (click)="nomineeDOB.open()" (dateInput)="calculateNomineeAge()"
                                    placeholder="DD/MM/YYYY">
                                <mat-datepicker-toggle matSuffix [for]="nomineeDOB">
                                </mat-datepicker-toggle>
                                <mat-datepicker #nomineeDOB touchUi>
                                </mat-datepicker>
                                <mat-error
                                    *ngIf="NomineeDetailsForm.controls['nomineeDOB'].touched && NomineeDetailsForm.controls['nomineeDOB']?.errors?.['required'] ">
                                    Nominee dob is required
                                </mat-error>
                            </div>
                        </div>
                    </form>
                </mat-card>

                <!-- Appointee Details Form -->
                <mat-card class="innerbox reverinnerbox borderinnerbox" *ngIf="showAppointee">
                    <form [formGroup]="AppointeeDetailsForm">
                        <h3>Appointee Details</h3>
                        <div class="row">
                            <div class="col-sm-3 form-group order-3 order-sm-3 mwidth">
                                <mat-label class="input-group__label" for="myInput">Full Name</mat-label>
                                <input matInput class="input-group__input" type="text" placeholder="Appointee full name"
                                    formControlName="appointeeFullName">
                                <mat-error
                                    *ngIf="showAppointee && AppointeeDetailsForm.controls['appointeeFullName'].touched && AppointeeDetailsForm.controls['appointeeFullName']?.errors?.['required']">
                                    Appointee full name is required
                                </mat-error>
                            </div>
                            <div class="col-sm-3 form-group order-3 order-sm-3 mwidth">
                                <div class="ml-1">
                                    <label class="input-group__label" style="top: 13px;">Relationship with
                                        Applicant</label>
                                    <mat-select class="input-group__input cstm_select"
                                        placeholder="Select appointee relationship"
                                        formControlName="appointeeRelationship" id="appointeeRelationship" required>
                                        <mat-option *ngFor="let option of nomineeRelationshipDetails"
                                            [value]="option.Name">
                                            {{option.Name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="showAppointee && AppointeeDetailsForm.controls['appointeeRelationship'].touched && AppointeeDetailsForm.controls['appointeeRelationship']?.errors?.['required']">
                                        Appointee relationship is required
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-sm-3 form-group order-3 order-sm-3 mwidth">
                                <mat-label class="input-group__label">Date of Birth </mat-label>
                                <input class="input-group__input dob" matInput [matDatepicker]="appointeeDOB"
                                    formControlName="appointeeDOB" [min]="appointeeMinDate" [max]="appointeeMaxDate"
                                    (click)="appointeeDOB.open()" placeholder="DD/MM/YYYY">
                                <mat-datepicker-toggle matSuffix [for]="appointeeDOB">
                                </mat-datepicker-toggle>
                                <mat-datepicker #appointeeDOB touchUi>
                                </mat-datepicker>
                                <mat-error
                                    *ngIf="showAppointee && AppointeeDetailsForm.controls['appointeeDOB'].touched && AppointeeDetailsForm.controls['appointeeDOB']?.errors?.['required']">
                                    Appointee dob is required
                                </mat-error>
                            </div>
                        </div>
                    </form>
                </mat-card>

                <!-- Declarations -->
                <mat-card class="innerbox borderinnerbox">
                    <div class="fieldsdiv mb-4">
                        <form [formGroup]="policiesConditionForm">
                            <div class="row">
                                <div class="col-sm-12">
                                    <table class="mediTestTable">
                                        <caption class="hidden-caption">Details</caption>
                                        <th class="hidden-caption">Declarations</th>
                                        <td class="hidden-caption">Agent Declaration</td>
                                        <tr>
                                            <td class="customCheckslist">
                                                <ul>
                                                    <li>
                                                        <mat-checkbox class="cstmCheckbox"
                                                            formControlName="medicalDeclaration"></mat-checkbox>
                                                    </li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="productType == 38">
                                                    <p>
                                                        I {{rnAgent}} accept <a
                                                            (click)="openDeclarationPopup('agentConfidentialityReport')">
                                                            <span class="custom-anchor">Agent Declaration
                                                            </span> </a>
                                                    </p>
                                                </ng-container>
                                                <ng-container *ngIf="productType != 38">
                                                    <p>
                                                        I {{rnAgent}} accept <a
                                                            (click)="openDeclarationPopup('agentConfidentialityReport')">
                                                            <span class="custom-anchor">Agent's Confidentiality Report
                                                            </span> </a> Declaration
                                                    </p>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </table>
                                    <mat-error class="ml-4 error-margin"
                                        *ngIf="this.policiesConditionForm.controls['medicalDeclaration'].value==false">
                                        Please accept Confidentiality Report.
                                    </mat-error>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <table class="mediTestTable">
                                        <caption class="hidden-caption">Details</caption>
                                        <th class="hidden-caption">Declarations</th>
                                        <td class="hidden-caption">Agent Declaration</td>
                                        <tr>
                                            <td class="customCheckslist">
                                                <ul>
                                                    <li>
                                                        <mat-checkbox class="cstmCheckbox"
                                                            formControlName="tncAcceptance"></mat-checkbox>
                                                    </li>
                                                </ul>
                                            </td>
                                            <td>
                                                <p>
                                                    I accept the <a
                                                        (click)="openDeclarationPopup('generalDeclaration')"> <span
                                                            class="custom-anchor">Declaration</span></a> and agree to
                                                    the <a (click)="openDeclarationPopup('tncDeclaration')"><span
                                                            class="custom-anchor">Terms & Conditions</span></a> and
                                                    <a (click)="openDeclarationPopup('privacyPolicy')"><span
                                                            class="custom-anchor">Privacy
                                                            Policy </span></a>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                    <mat-error class="ml-4 error-margin"
                                        *ngIf="this.policiesConditionForm.controls['tncAcceptance'].value==false">
                                        Please accept Term and Conditions and Privacy Policy.
                                    </mat-error>
                                </div>
                            </div>
                        </form>
                    </div>
                </mat-card>

                <!-- <button type="button" class="btn btn-primary" (click)="Onsubmit()">Submit</button> -->

            </div>
        </div>
    </div>
</div>
<div class="footer">
    <div class="container footerContainer">
        <div class="row">
            <div *ngIf="isKYCDoneSuccessfully">
                <button mat-raised-button class="secondarybtn_2 secbtnInsured" (click)="Onsubmit('PayNow')">Pay
                    Now</button>&nbsp;
                <button mat-raised-button class="secondarybtn_2 secbtnInsured" (click)="Onsubmit('PayLater')">Pay
                    Later</button>&nbsp;
                <button mat-raised-button class="primary-button" (click)="Onsubmit('PaymentLink')">Payment link</button>
            </div>
        </div>
    </div>
</div>


<!-- Kyc Modal -->
<div class="modal fade kycPopupScreen" id="externalUrlModal" tabindex="-1" role="dialog"
    aria-labelledby="externalUrlModalLabel" aria-hidden="true">
    <div class="modal-dialog my-0" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <h5 class="modal-title">Modal title</h5> -->
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <iframe id="externalUrlFrame" width="100%" height="600px"></iframe>
            </div>
        </div>
    </div>
</div>