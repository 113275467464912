import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { Analytics, Auth } from "aws-amplify";

// const amplifyConfig = {
//   Auth: {
//     identityPoolId: 'ap-south-1:6e6ddc9c-51a1-4611-bd8c-fdcfb565463f',
//     region: 'ap-south-1'
//   }
// }

// // Initialize Amplify
// Auth.configure(amplifyConfig);
// const analyticsConfig = {
//   AWSPinpoint: {
//     // Amazon Pinpoint App Client ID
//     appId: '74050c19f10c4a2e8a472c23fdc5c608',
//     // Amazon service region
//     region: 'ap-south-1',
//     mandatorySignIn: false,
//   }
// }
// Analytics.configure(analyticsConfig)

// // Record a custom event
// Analytics.record({
//   name: 'Album',
//   attributes: { genre: 'Rock', year: '1989' }
// });

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
