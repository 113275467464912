import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { StorageService } from './storage.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { InternationPlanRequestPayload, studentPlanRequestPayload, PlanResponsePayload, RelationshipRequestPayload, RelationshipResponsePayload, SaveEditCustomerRequestPayload, SaveProposalDetails1RequestPayload, SaveQuoteRequestPayload, StateResponsePayload ,SaveEditCustomerResponsePayload} from '../interface/travel';


@Injectable({
  providedIn: 'root'
})
export class TravelService {

  constructor(private generic: GenericService, private storageService: StorageService,
    private http: HttpClient) { }
    readonly baseURI = `${environment.baseURL}`;
    readonly fetchStateMaster = 'Master/GetAllStates';
    readonly fetchRelationshipMaster = 'Travel/GetRelationWithApplicant';
    readonly fetchNomineeRelationshipMaster = 'Travel/GetNomineeRelationList';
    readonly fetchStudentTravelPlans = 'Travel/GetstudentMedicalPlan';
    readonly fetchInternationtravelPlans = 'Travel/GetInternationalTravelPlan';
    readonly saveQuote = 'Travel/STMPremiumCalculation';
    readonly fetchAllCountries = 'Travel/GetAllCountries';
    readonly SaveProposalDetails = 'Travel/SaveProposalDetails1';
    readonly saveInternationalQuote = 'Travel/ITPFCalculatePremium';
    readonly saveEditCustomer = 'customer/saveeditcustomer';
    readonly fetchListofSchengenStates = 'Travel/ListofSchengenStates';
    readonly fetchListofEuropeanUnionMemberStates = 'Travel/ListofEuropeanUnionMemberStates';
    readonly getPincodeId = 'RTOList/GetPincodeID';




    fetchStateMasterDetails(request: any): Observable<StateResponsePayload> {
      const endpoint = `${this.baseURI}${this.fetchStateMaster}`;
      return this.generic.genericService<StateResponsePayload>(endpoint, request);
    }

    ListofEuropeanUnionMemberStatesdetails(request: any): Observable<StateResponsePayload> {
      const endpoint = `${this.baseURI}${this.fetchListofEuropeanUnionMemberStates}`;
      return this.generic.genericService<StateResponsePayload>(endpoint, request);
    }


    fetchListofSchengenStatesdetails(request: any): Observable<StateResponsePayload> {
      const endpoint = `${this.baseURI}${this.fetchListofSchengenStates}`;
      return this.generic.genericService<StateResponsePayload>(endpoint, request);
    }


    fetchRelationshipMasterDetails(request: RelationshipRequestPayload): Observable<RelationshipResponsePayload> {
      const endpoint = `${this.baseURI}${this.fetchRelationshipMaster}`;
      return this.generic.genericService<RelationshipResponsePayload>(endpoint, request);
    }

    fetchNomineeRelationshipMasterDetails(request: any): Observable<RelationshipResponsePayload> {
      const endpoint = `${this.baseURI}${this.fetchNomineeRelationshipMaster}`;
      return this.generic.genericService<RelationshipResponsePayload>(endpoint, request);
    }
    
    fetchPlanDetails(request: studentPlanRequestPayload): Observable<PlanResponsePayload> {
      const endpoint = `${this.baseURI}${this.fetchStudentTravelPlans}`;
      return this.generic.genericService<PlanResponsePayload>(endpoint, request);
    }

    fetchInternationtravelPlanDetails(request: InternationPlanRequestPayload): Observable<any> {
      const endpoint = `${this.baseURI}${this.fetchInternationtravelPlans}`;
      return this.generic.genericService<any>(endpoint, request);
    }

    saveQuoteDetails(request: any): Observable<any> {
      const endpoint = `${this.baseURI}${this.saveQuote}`;
      return this.generic.genericService<any>(endpoint, request);
    }

    saveInternationalQuoteDetails(request: any): Observable<any> {
      const endpoint = `${this.baseURI}${this.saveInternationalQuote}`;
      return this.generic.genericService<any>(endpoint, request);
    }

    fetchAllCountriesDetails(request): Observable<any> {
      const endpoint = `${this.baseURI}${this.fetchAllCountries}`;
      return this.generic.genericService<any>(endpoint,request);
    }
    saveProposalDetail(request: SaveProposalDetails1RequestPayload): Observable<any> {
      const endpoint = `${this.baseURI}${this.SaveProposalDetails}`;
      return this.generic.genericService<any>(endpoint, request);
    }


    saveEditcustomer(request: SaveEditCustomerRequestPayload): Observable<any> {
      const endpoint = `${this.baseURI}${this.saveEditCustomer}`;
      return this.generic.genericService<any>(endpoint, request);
    }

    getPincodeIdDetails(request: any): Observable<any> {
      const endpoint = `${this.baseURI}${this.getPincodeId}`;
      return this.generic.genericService<any>(endpoint, request);
    }
}
