import { Injectable } from '@angular/core';
import { Constants } from '../constants/constants';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ApiTokenRequestPayload, ApiTokenResponsePayload, DmsDocUploadResponsePayload, CreateFolerToUploadRequestPayload, CreateFolerToUploadResponsePayload, DocfilesdetailsfromDMSRequestPayload, DocfilesdetailsfromDMSResponsePayload, logKYCData } from '../interface/utilityApi';
import { GenericService } from '../services/generic.service';

@Injectable({
  providedIn: 'root'
})
export class UtilityApiService {

  constructor(public generic: GenericService) { }

  readonly baseURI = `${environment.baseURL}Utility/`;
  readonly apiToken = 'GetAPIToken';
  readonly fileUploadURL ='DocFilesDetailsFromDMS';
  readonly createFolderOnServer ='DmsSearchCreateFolder';
  readonly documentUpload ='DmsDocUpload';
  readonly logData = 'KycDetails';

  getApiToken(): Observable<ApiTokenResponsePayload> {
    let data:ApiTokenRequestPayload = {
      scope: Constants.esbKycScope
    }
    let endpoint = '';
    if(environment.environment == 'blue'){
     endpoint = 'https://pana.insurancearticlez.com/apigateway/health/Utility/' + this.apiToken;
    }
    else{
    endpoint = `${this.baseURI}${this.apiToken}`;
    }

    return this.generic.genericService<ApiTokenResponsePayload>(endpoint, data);
  }

  docFilesDetailsFromDMS(data: DocfilesdetailsfromDMSRequestPayload): Observable<DocfilesdetailsfromDMSResponsePayload> {
    const endpoint = `${this.baseURI}${this.fileUploadURL}`;
    return this.generic.genericService<DocfilesdetailsfromDMSResponsePayload>(endpoint, data);
  }

  createFolerToUpload(data: CreateFolerToUploadRequestPayload): Observable<CreateFolerToUploadResponsePayload> {
    const endpoint = `${this.baseURI}${this.createFolderOnServer}`;
    return this.generic.genericService<CreateFolerToUploadResponsePayload>(endpoint, data);
  }

  dmsDocUploadResponse(data: FormData): Observable<DmsDocUploadResponsePayload> {
    const endpoint = `${this.baseURI}${this.documentUpload}`;
    return this.generic.genericService<DmsDocUploadResponsePayload>(endpoint, data);
  }

  logDataDetails(data: logKYCData): Observable<any> {
    const endpoint= `${this.baseURI}${this.logData}`;
    return this.generic.genericService<any>(endpoint,data);
  }

}
