// resize.service.ts
import { Injectable } from '@angular/core';
import { fromEvent, BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WindowResizeService {
  private resizeSubject: BehaviorSubject<{ width: number; height: number }>;

  constructor() {
    this.resizeSubject = new BehaviorSubject({ width: window.innerWidth, height: window.innerHeight });

    fromEvent(window, 'resize')
      .pipe(
        map(() => ({ width: window.innerWidth, height: window.innerHeight })),
        startWith({ width: window.innerWidth, height: window.innerHeight })
      )
      .subscribe((size) => {
        this.resizeSubject.next(size);
      });
  }

  getResizeObservable(): Observable<{ width: number; height: number }> {
    return this.resizeSubject.asObservable();
  }
  
}
