
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, fromEvent } from 'rxjs';
import { HealthDataStorage, MyPolicyDetails } from 'src/app/modules/health/health.modal';
import { HealthDataStorageService } from 'src/app/modules/health/health.service';
import { Constants } from 'src/app/shared/constants/constants';
import { UWStatusEnum } from 'src/app/shared/enums/healthEnums';
import { PolicyType, MotorPolicyType, TravelPolicyType, HomePolicyType, HealthPolicyType } from 'src/app/shared/enums/pendingPaymentEnums';
import { popupType, popupImgPath, popupHeader, popupButton, popupButtonAction } from 'src/app/shared/enums/popupEnums';
import { routeEnums } from 'src/app/shared/enums/routeEnums';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { ProposalStatusResponsePayload, FetchPoliciesRequestPayload, fetchPropToModifyResponse, FetchPoliciesResponsePayload, DownloadCouponRequestPayload, ProposalStatusRequestPayload, PolicyDetails_SideNav } from 'src/app/shared/interface/mypolicy';
import { DownloadService } from 'src/app/shared/services/download.service';
import { MyPolicyService } from 'src/app/shared/services/mypolicy.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { PopupModal, EditPortabilityPopupModal } from 'src/app/layout/popups/popup.modal';
import { LoaderService } from 'src/app/layout/loader/loader.service';
import { Component, OnInit, NgZone, ViewChildren, QueryList, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { MatSelect } from '@angular/material/select';
import { AgentV1Service } from 'src/app/shared/proxy-services/agent-v1.service';
import { PopupComponent } from 'src/app/layout/popups/popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-my-policy',
  templateUrl: './my-policy.component.html',
  styleUrls: ['./my-policy.component.scss']
})
export class MyPolicyComponent implements OnInit {
  private interval: any;
  scrolling: boolean = false;
  isSearchVisible: boolean = false;
  productTypeList: { productName: string; productCode: number }[];
  toggleValue: 'table' | 'card' = 'card';
  policyFilter: string[] = [];
  policyFilterList = ['Policy', 'Cancelled', 'Pending for approval'];
  inputValue: string = '';
  showCancel: boolean = false;
  searchPlaceHolder: string;
  isSelected: boolean = false;
  isSubmitted: boolean = false;
  reportList: any = [];
  selectList = [];
  i: number = 0;
  phText: string[] = ['Search for Proposal Number',
    'Search for Customer Name',
    'Search for Policy Number'];
  filteredData: MyPolicyDetails[] = [];
  tableBtnInfo = [{
    label: 'Download Proposal',
    imgPath: '../../../../../../../assets/images/BBR/downloadProposal.svg',
    tooltip: 'Download Proposal',
    expression: 'data.Status && (data.PolicySubType != 37)  && data.PolicyType != 4'
  }, {
    label: 'UW Status',
    imgPath: '../../../../../../../assets/images/BBR/UWStatus.svg',
    tooltip: 'UW Status',
    expression: 'data.PolicyType == 2 && (data.Status.toUpperCase().indexOf("PENDING") > -1 && data.Status.toUpperCase() != "MEDICAL TEST PENDING" && data.Status.toUpperCase() != "PENDING FOR COUNTER OFFER ACCEPTANCE")'
  }, {
    label: 'Upload Document',
    imgPath: '../../../../../../../assets/images/BBR/Upload.svg',
    tooltip: 'Upload',
    expression: '!data.IsBancsPolicy && data.PolicyType == 2 && (data.TransFor == "R" || data.NRIDiscount) && data.PolicySubType != 23' //&& data.HtStatus.toUpperCase() != "REJECTED"
  }, {
    label: 'Download Policy',
    imgPath: '../../../../../../../assets/images/BBR/downloadProposal.svg',
    tooltip: 'Download Policy',
    expression: "(data.Status.toUpperCase() == 'POLICY' || data.Status.toUpperCase() == 'INSTACR') && (data.PFPolicyNo != 0 || data.PFPolicyNo != '')"
  }, {
    label: 'Coupons',
    imgPath: '../../../../../../../assets/images/BBR/Coupon.svg',
    tooltip: 'Coupons',
    expression: "data.PolicyType == 2 && data.Status.toUpperCase() == 'POLICY' && !([21, 22, 23, 24, 25, 37, 38].includes(data.PolicySubType))"
  }, {
    label: 'Edit Portability Details',
    imgPath: '../../../../../../../assets/images/BBR/EditPortability.svg',
    tooltip: 'Edit Portability Details',
    expression: '!data.IsBancsPolicy && data.AllowEdit && (data.PolicySubType == 42 || data.PolicySubType == 33 || data.PolicySubType == 40 || data.PolicySubType == 45)' // && data.HtStatus.toUpperCase() != "REJECTED"
  }]
  cardBtnInfo = [{
    label: 'Download Proposal',
    imgPath: '../../../assets/images/myPolicy/downloadProposal.svg',
    expression: 'data.Status && (data.PolicySubType != 37)  && data.PolicyType != 4'
  }, {
    label: 'UW Status',
    imgPath: '../../../assets/images/myPolicy/uwStatus.svg',
    expression: 'data.PolicyType == 2 && (data.Status.toUpperCase().indexOf("PENDING") > -1 && data.Status.toUpperCase() != "MEDICAL TEST PENDING" && data.Status.toUpperCase() != "PENDING FOR COUNTER OFFER ACCEPTANCE")'
  }, {
    label: 'Upload Document',
    imgPath: '../../../assets/images/myPolicy/upload.svg',
    expression: '!data.IsBancsPolicy && data.PolicyType == 2 && (data.TransFor == "R" || data.NRIDiscount) && data.PolicySubType != 23' //&& data.HtStatus.toUpperCase() != "REJECTED"

  }, {
    label: 'Download Policy',
    imgPath: '../../../assets/images/myPolicy/downloadPolicy.svg',
    expression: "(data.Status.toUpperCase() == 'POLICY' || data.Status.toUpperCase() == 'INSTACR') && (data.PFPolicyNo != 0 || data.PFPolicyNo != '')"
  }, {
    label: 'Coupons',
    imgPath: '../../../assets/images/myPolicy/Coupon.svg',
    expression: "data.PolicyType == 2 && data.Status.toUpperCase() == 'POLICY' && !([21, 22, 23, 24, 25, 37, 38,45].includes(data.PolicySubType))"
  }, {
    label: 'Edit Portability Details',
    imgPath: '../../../assets/images/myPolicy/editPortability.svg',
    expression: '!data.IsBancsPolicy && data.AllowEdit && (data.PolicySubType == 42 || data.PolicySubType == 33 || data.PolicySubType == 40 || data.PolicySubType == 45)' // && data.HtStatus.toUpperCase() != "REJECTED"
  }]
  // displayCol: string[] = ['proposalNumber', 'startDate', 'endDate', 'transactionOnDate', 'totalPremium',
  //   'policyStatus', 'uwStatus','Action']

  displayCol = [
    {
      columnDef: 'CustomerName',
      header: 'Customer Name',
      cell: (element: any) => `${element.CustomerName}`,
    },
    {
      columnDef: 'PFPolicyNo',
      header: 'Policy Number',
      cell: (element: any) => `${element.PFPolicyNo}`,
    },
    {
      columnDef: 'ProposalNumber',
      header: 'Proposal Number',
      cell: (element: any) => `${element.ProposalNumber}`,
    },
    {
      columnDef: 'StartDate',
      header: 'Start Date',
      cell: (element: any) => `${element.StartDate}`,
    },
    {
      columnDef: 'EndDate',
      header: 'End Date',
      cell: (element: any) => `${element.EndDate}`,
    },
    {
      columnDef: 'TransOn',
      header: 'Transaction Date',
      cell: (element: any) => `${element.TransOn}`,
    },
    {
      columnDef: 'TotalPremium',
      header: 'Total Premium',
      cell: (element: any) => `${element.TotalPremium}`,
    },
    {
      columnDef: 'Action',
      header: 'Action',
      cell: '',
    },
    {
      columnDef: 'Status',
      header: 'Policy Status',
      cell: (element: any) => `${element.Status}`,
    },
    {
      columnDef: 'ProposalStatus',
      header: 'UW Status',
      cell: (element: any) => `${element.ProposalStatus}`,
    },
    {
      columnDef: 'HtNumber',
      header: 'HT Number',
      cell: (element: any) => `${element.HtNumber}`,
    },
    {
      columnDef: 'HtStatus',
      header: 'Nysa HT Status',
      expression: 'data.TransFor == "R" && !this.utilityService.isEmptyOrNull(data.HtStatus)',
      cell: (element: any) => `${element.HtStatus}`,
    },
    {
      columnDef: 'HtRemark',
      header: 'QC Remarks',
      expression: '!this.utilityService.isEmptyOrNull(data.HtRemark)',
      cell: (element: any) => `${element.HtRemark}`,
    },
  ]

  mypolicyForm: FormGroup;
  subscription: Subscription[] = [];
  HEROES: { id: number; name: string; }[];
  finalDetails: any = [];
  policyDetails: any = [];
  UWStatus: UWStatusEnum;
  // productList: { productName: string; productCode: number; isProductMapped: any; }[];
  travelList: { productName: string; productCode: number; isProductMapped: any; }[];
  formSubmitted: boolean = false;
  selectedProduct: string = 'Health';
  productCode: number = 42;
  productCodeTravel: number = 3;
  //productList: { productName: string; productCode: number; isProductMapped: any; }[];
  productList = [];
  productDetails = [];
  policyType: number;
  minMax: { maxDate: Date; minDate: Date; };
  underWritterStatus: ProposalStatusResponsePayload[];
  healthFormData: HealthDataStorage;
  policyCount: number;
  myPolicyStartDate: Date;
  myPolicyEndDate: Date;
  myPolicyProduct: string;

  constructor(
    private popupService: PopupService,
    private myPolicyService: MyPolicyService,
    private downloadService: DownloadService,
    private storageService: StorageService,
    private router: Router,
    public utilityService: UtilityService,
    private formBuilder: FormBuilder,
    private healthDataStorageService: HealthDataStorageService,
    private spinner: LoaderService,
    private zone: NgZone,
    private renderer: Renderer2,
    private agentV1Service: AgentV1Service,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.subscription.push(fromEvent(window, 'scroll')
      .subscribe(() => {
        this.onWindowScroll();
      }));
    this.myPolicyService.editPortabilityFlag$.subscribe((value) => {
      this.searchDetails();
    });
    this.popupService.onLoadMyPolicyAPi$.subscribe(data => {
      this.healthFormData = this.healthDataStorageService.getHealthDataStorage();
      this.getProductList();
      // this.getTravelList();
      this.createForm();
      this.setCalendarDates();
      this.setProductType();
      this.searchDetails();
      // this.productListListner('2');
      this.setPlaceHolderText();
      this.interval = setInterval(() => { this.setPlaceHolderText(); }, 2000);
    })
    // this.forQcFlow();
  }

  setProductType() {
    if (sessionStorage.getItem(userEnums.AgentProductMap)) {
      const products = this.storageService.getAESDecryptedData(userEnums.AgentProductMap);
      this.mypolicyForm.controls['productType'].setValue(products[0].productCode);
      if (products[0].productCode != 4) {
        this.mypolicyForm.controls['productName'].setValue(products[0].productList[0].productCode);
      }
      this.setSelectList(products[0].productCode);
    } else {
      this.productListListner('2');
    }
  }

  onWindowScroll() {
    this.scrolling = true;

    if (window.scrollY === 0) {
      this.scrolling = false;
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  setCalendarDates() {
    let minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 10));
    let maxDate = new Date(new Date().setFullYear(new Date().getFullYear())); //removing +1
    this.minMax = { maxDate: maxDate, minDate: minDate }
  }

  // isViewButtonVisible(data) { **Unused**
  //   return !this.isZerotatVisible(data);
  // }

  // isViewPolicyVisible(data) {
  //   return (data.policyStatus == 'POLICY' || data.policyStatus == 'INSTACR') && (data.pfPolicyNo != 0 || data.pfPolicyNo != '');
  // }

  // isViewProposalVisible(data) {
  //   return data.policyStatus &&(data.policySubType !=37 && data.policySubType !=1);
  // }

  // isEditingPortabilityVisible(data) {
  //   return data.allowEdit && (data.policySubType == 42 || data.policySubType == 33 || data.policySubType == 40)
  // }

  // isDocumentUploadVisible(data) {
  //   return data.policyType == 2 && data.transactionFor == "R" && data.policySubType != 23
  // }

  // isTrackClaimVisible(data) { **Unused**
  //   return false;
  // }

  // isEmailCounterOfferVisible(data) { **Unused**
  //   return data.policyType == 2 && data.policySubType != 21 && (data.policyStatus == "MEDICAL TEST PENDING" || data.policyStatus == "PENDING FOR COUNTER OFFER ACCEPTANCE" ||
  //     data.policyStatus == "PENDING FOR APPROVAL") && data.custUWStatus > 0;
  // }

  // isCouponsVisible(data) {
  //   return data.policyType == 2 && data.policyStatus == 'POLICY' && (data.policySubType != 21 && data.policySubType != 22 && this.isZerotatVisible(data))
  // }

  // isUnderWritterStatusVisible(data) {
  //   return data.policyType == 2 && (data.policyStatus.indexOf("PENDING") > -1 && data.policyStatus != "MEDICAL TEST PENDING" && data.policyStatus != "PENDING FOR COUNTER OFFER ACCEPTANCE")
  // }

  // isZerotatVisible(data) {
  //   return (data.policySubType != 23 && data.policySubType != 24 && data.policySubType != 25 && data.policySubType != 37 && data.policySubType != 38)
  // }

  getProductList() {
    // let agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
    this.productList = this.agentV1Service.getAllProducts('myPolicy');
  }

  // getTravelList() {
  //   let agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
  //   this.travelList = this.agentV1Service.travelList(agentDetails, 'myPolicy');
  // }

  setSelectList(option) {
    for (let i of this.productList) {
      if (i.productCode == option) {
        this.selectList = i.productList
      }
    }
  }

  productListListner(option) {
    for (let i of this.productList) {
      if (i.productCode == option) {
        this.selectList = i.productList
        if (i.productCode != 4) {
          this.mypolicyForm.controls['productName'].setValue(this.selectList[0].productCode);
        }
      }
    }
  }

  // getTravelList() {
  //   let agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
  //   this.travelList = this.agentV1Service.travelList(agentDetails, 'myPolicy');
  // }

  createForm() {
    let startDate = this.utilityService.subtractDays(new Date(), 15);
    this.mypolicyForm = this.formBuilder.group({
      productType: [2, [Validators.required]],
      productName: [42, [Validators.required]],
      startDate: [startDate, [Validators.required]],
      endDate: [new Date(), [Validators.required]],
    })
    this.patchBanner();
  }

  onClick(type) {
    this.policyDetails = [];
    this.selectedProduct = type.tab.textLabel;
    this.searchDetails();
  }

  selectproduct(productCode: number) {
    this.productCode = productCode;
  }

  selectproductTravel(productCode: number) {
    this.productCodeTravel = productCode;
  }
  patchBanner() {
    this.myPolicyStartDate = this.mypolicyForm.value.startDate;
    this.myPolicyEndDate = this.mypolicyForm.value.endDate;
    this.myPolicyProduct = this.getProductNameFromCode(this.mypolicyForm.value.productType);
  }

  getProductNameFromCode(x: number) {
    if (x == 2) {
      return 'Health'
    } else if (x == 3) {
      return 'Travel'
    } else if (x == 4) {
      return 'Home'
    }
    return '';
  }

  // onClick(type) {
  //   this.policyDetails = [];
  //   this.selectedProduct = type.tab.textLabel;
  // }

  // selectproduct(productCode: number) {
  //   this.productCode = productCode;
  // }

  constructRequest(): FetchPoliciesRequestPayload {
    let request = {
      "StartDate": this.utilityService.formatDate(this.mypolicyForm.value.startDate),
      "EndDate": this.utilityService.formatDate(this.mypolicyForm.value.endDate),
      "PolicyType": PolicyType.Health,
      // "MotorPolicyType": MotorPolicyType.None,
      "HealthPolicyType": this.mypolicyForm.value.productName,
      "TravelPolicyType": TravelPolicyType.None,
      "HomePolicyType": HomePolicyType.Home
    }
    return request;
  }
  constructRequest1(): FetchPoliciesRequestPayload {
    let request = {
      "StartDate": this.utilityService.formatDate(this.mypolicyForm.value.startDate),
      "EndDate": this.utilityService.formatDate(this.mypolicyForm.value.endDate),
      "PolicyType": PolicyType.Travel,
      // "MotorPolicyType": MotorPolicyType.None,
      "HealthPolicyType": HealthPolicyType.None,
      "TravelPolicyType": this.mypolicyForm.value.productName,
      "HomePolicyType": HomePolicyType.Home
    }
    return request;
  }
  constructRequest2(): FetchPoliciesRequestPayload {
    let request = {
      "StartDate": this.utilityService.formatDate(this.mypolicyForm.value.startDate),
      "EndDate": this.utilityService.formatDate(this.mypolicyForm.value.endDate),
      "PolicyType": PolicyType.Home,
      // "MotorPolicyType": MotorPolicyType.None,
      "HealthPolicyType": HealthPolicyType.None,
      "TravelPolicyType": TravelPolicyType.None,
      "HomePolicyType": HomePolicyType.Home
    }
    return request;
  }


  searchDetails() {
    this.clearInputField();
    let body;
    if (this.mypolicyForm.value.productType == 2) {
      body = this.constructRequest();
    } else if (this.mypolicyForm.value.productType == 3) {
      body = this.constructRequest1();
    } else if (this.mypolicyForm.value.productType == 4) {
      body = this.constructRequest2();
    }

    this.subscription.push(this.myPolicyService.fetchMypoliciesDetails(body).subscribe({
      next: (response: FetchPoliciesResponsePayload) => {
        if (response.StatusCode === Constants.statusCode_success) {
          if (response.Data.Health) {
            this.policyDetails = response.Data.Health;
            this.policyCount = response.Data.Health.length;
          } else if (response.Data.Travel) {
            this.policyDetails = response.Data.Travel;
            this.policyCount = response.Data.Travel.length;
          }
          else if (response.Data.Home) {
            this.policyDetails = response.Data.Home;
            this.policyCount = response.Data.Home.length;
          }
          this.policyDetails = this.policyDetails.map((data) => {
            return {
              ...data,
              HtNumber: data.HtNumber === null ? '' : data.HtNumber,
              HtStatus: !this.utilityService.isEmptyOrNull(data.HtStatus) && data.TransFor == 'R' ? data.HtStatus : '-'
            };
          });
          this.filteredData = this.policyDetails;
          this.patchBanner();
        } else {
          this.policyCount = 0;
          this.policyDetails = [];
          this.filteredData = [];
        }
      }
    }))
  }

  receiveDataAndCall(event) {
    if (event.identifier == 'Download Policy') {
      this.downloadPolicy(event.data);
    } else if (event.identifier == 'Download Proposal') {
      this.downloadProposal(event.data);
    } else if (event.identifier == 'Upload Document') {
      this.uploadDocument(event.data);
    } else if (event.identifier == 'UW Status') {
      this.getUnderWritterStatus(event.data);
    } else if (event.identifier == 'Coupons') {
      this.downloadCoupons(event.data)
    } else if (event.identifier == 'Edit Portability Details') {
      this.editingPortabilityDetails(event.data)
    }
    else if ((this.mypolicyForm.valid) && (this.selectedProduct == 'Travel')) {


      this.policyDetails = [];

      let body = this.constructRequest1();
      this.subscription.push(this.myPolicyService.fetchMypoliciesDetails(body).subscribe({
        next: (response: FetchPoliciesResponsePayload) => {
          if (response.StatusCode === Constants.statusCode_success) {
            let policyDetails = response.Data.Travel;
            this.zone.run(() => {
              policyDetails.forEach(item => {
                let record: any = {
                  product: 'Travel',
                  customerName: item?.CustomerName,
                  policyType: item?.PolicyType,
                  policySubType: item?.PolicySubType,
                  policyStatus: item?.Status.toUpperCase(),
                  pfPolicyNo: item.PFPolicyNo == '0' ? '' : item?.PFPolicyNo,
                  policyID: item?.PolicyID,
                  ePolicyID: item?.EPolicyID,
                  proposalNumber: item?.ProposalNumber,
                  startDate: item?.StartDate,
                  endDate: item?.EndDate,
                  transactionOnDate: item?.TransOn,
                  totalPremium: item?.TotalPremium,
                  isHTNumberRequired: (item.TransFor == "R" && item.HtNumber),
                  htNumber: item?.HtNumber,
                  folderIndex: '',
                  isRecalculateRequired: false,
                  isEmiRequired: false,
                  transactionFor: item?.TransFor,
                  uwStatus: 'Pending for Approval with UW1',
                  custUWStatus: item?.CUUWSTATUS ? Number(item?.CUUWSTATUS) : 0,
                  allowEdit: item?.AllowEdit,
                  bundleID: item?.BundleID
                }
                this.policyDetails.push(record);
              })
            })
          }
        },
        error: error => {
        }
      })

      )

    }
    else {

      this.policyDetails = [];

      let body = this.constructRequest2();
      this.subscription.push(this.myPolicyService.fetchMypoliciesDetails(body).subscribe({
        next: (response: FetchPoliciesResponsePayload) => {
          if (response.StatusCode === Constants.statusCode_success) {
            let policyDetails = response.Data.Home;
            this.zone.run(() => {
              policyDetails.forEach(item => {
                let record: any = {
                  product: 'Home',
                  customerName: item?.CustomerName,
                  policyType: item?.PolicyType,
                  policySubType: item?.PolicySubType,
                  policyStatus: item?.Status.toUpperCase(),
                  pfPolicyNo: item.PFPolicyNo == '0' ? '' : item?.PFPolicyNo,
                  policyID: item?.PolicyID,
                  ePolicyID: item?.EPolicyID,
                  proposalNumber: item?.ProposalNumber,
                  startDate: item?.StartDate,
                  endDate: item?.EndDate,
                  transactionOnDate: item?.TransOn,
                  totalPremium: item?.TotalPremium,
                  isHTNumberRequired: (item.TransFor == "R" && item.HtNumber),
                  htNumber: item?.HtNumber,
                  folderIndex: '',
                  isRecalculateRequired: false,
                  isEmiRequired: false,
                  transactionFor: item?.TransFor,
                  uwStatus: 'Pending for Approval with UW1',
                  custUWStatus: item?.CUUWSTATUS ? Number(item?.CUUWSTATUS) : 0,
                  allowEdit: item?.AllowEdit,
                  bundleID: item?.BundleID
                }
                this.policyDetails.push(record);
              })
            })
          }
        },
        error: error => {
        }
      })

      )

    }
  }

  downloadPolicy(data) {
    let policyType = (data.CUUWSTATUS != '' && data.CUUWSTATUS > 0 && (data.policySubType == 19 || data.policySubType == 20)) ? 'HBCUWPDF' :
      data.policySubType == 21 ? 'POLICY' : (data.CUUWSTATUS != '' && data.CUUWSTATUS > 0) ? 'CHICUWPDF' : 'POLICY';
    // let policyId = data.policySubType == 38 || data.policySubType == 23 ? data.policyID : data.ePolicyID;
    let policyId = data.EPolicyID;
    let params = { policyType: policyType, policySubType: data.policySubType, policyId: policyId, ePolicyID: data.EPolicyID, bundleId: data.BundleList, policyID: data.PolicyID }
    let details = this.downloadService.downloadPolicy(params);
    this.generatePdf(details, data.PFPolicyNo);
  }

  downloadProposal(data) {
    let policyId = data.EPolicyID;
    let params = { policyType: 'PROPOSAL', policySubType: data.PolicySubType, policyId: policyId, ePolicyID: data.EPolicyID, bundleId: data.BundleID, policyID: data.PolicyID }
    let details = this.downloadService.downloadProposal(params);
    this.generatePdf(details, data.ProposalNumber);
  }

  generatePdf(params, name) {
    this.subscription.push(this.downloadService.generatePDF(params.endPoint, params.body).subscribe({
      next: (response) => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.downloadService.downloadPdf(response.Data, name)
        }
        // let fileName = params.policyId + '.pdf';
        // const fileReader = new FileReader();

        // fileReader.onloadend = () => {
        //   const base64Data = fileReader.result as string;

        //   // Trigger the download
        //   this.downloadFile(base64Data, fileName);
        // };
        // fileReader.readAsDataURL(new Blob([response], { type: 'application/octet-stream' }));
        // //this.downloadService.saveBlob(response, fileName);
      },
      error: error => {
        let errorPopupData = this.popupService.fetchErrorPopupMsg('');
        this.popupService.openGenericPopup(errorPopupData);
      }
    })

    )
  }

  downloadFile(base64Data, filename: string) {
    const link = document.createElement('a');
    link.href = base64Data;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  uploadDocument(data) {
    this.healthFormData.myPolicyDetails.htNumber = data?.HtNumber || '';
    this.healthFormData.myPolicyDetails.htFolderIndex = data?.HtFolderIndex || '';
    this.healthFormData.myPolicyDetails.policyId = data?.PolicyID;
    this.healthDataStorageService.setHealthDataStorage(this.healthFormData);
    // this.router.navigate([routeEnums.DocumentUpload]);
    this.utilityService.redirectionToUploadDocument(data?.HtNumber);
  }

  constructProposalStatusRequest(data): ProposalStatusRequestPayload {
    let request = { ProposalNumber: data.ProposalNumber }
    // let request = { ProposalNumber : '2153870932' }
    return request;
  }

  getUnderWritterStatus(data) {
    let body = this.constructProposalStatusRequest(data);
    this.subscription.push(this.myPolicyService.getUnderWritterStatus(body).subscribe({
      next: (response: ProposalStatusResponsePayload) => {
        this.underWritterStatus = response.Data;
        let popupData: PopupModal = {
          popupType: popupType.generic,
          imgName: popupImgPath.successGif,
          header: popupHeader.success,
          description: 'Policy Underwriter Status of ' + data.ProposalNumber + ' : ' + this.underWritterStatus,
          buttonLabel: popupButton.ok,
          buttonAction: popupButtonAction.close
        }
        this.popupService.openGenericPopup(popupData);
      },
      error: error => {
      }
    })

    )
  }

  constructDownloadCouponsRequest(policyId, productType): DownloadCouponRequestPayload {
    let request = { EPolicyID: policyId, Type: productType }
    return request;
  }

  downloadCoupons(data) {
    let policySubType = data.policySubType == 21 ? 'PPAP' : 'CHI';
    let productType = (data.policySubType == 19 || data.policySubType == 20) ? 'HBOOSTER' : policySubType;
    let body = this.constructDownloadCouponsRequest(data.PolicyID, productType);
    this.subscription.push(this.myPolicyService.downloadCoupons(body).subscribe({
      next: (response) => {
        let fileName = 'coupon_' + data.ProposalNumber + '.pdf';
        this.downloadService.downloadPdf(response.Data, fileName);
      },
      error: error => {
        let errorPopupData = this.popupService.fetchErrorPopupMsg('');
        this.popupService.openGenericPopup(errorPopupData);
      }
    })
    )
  }

  fetchSelectedpolicyApiCall(data) {
    let body = { 'E_PolicyID': data.EPolicyID }
    this.spinner.show();
    this.subscription.push(this.myPolicyService.fetchSelectedPolicyDetails(body).subscribe({
      next: (response: fetchPropToModifyResponse) => {
        if (response.StatusCode === Constants.statusCode_success) {
          this.spinner.hide();
          let portabilityPolicyDetails = response;
          let popupData: EditPortabilityPopupModal = {
            popupType: popupType.editPortability,
            imgName: '',
            header: '',
            description: '',
            buttonLabel: '',
            policyDetails: portabilityPolicyDetails,
            EPolicyID: data.EPolicyID
          }
          this.popupService.openEditPortabilityPopup(popupData);
        } else {
          this.spinner.hide();
          let errorPopupData = this.popupService.fetchErrorPopupMsg(response.StatusMsg);
          this.popupService.openGenericPopup(errorPopupData);
        }
      },
      error: error => {
        this.spinner.hide();
        let errorPopupData = this.popupService.fetchErrorPopupMsg('');
        this.popupService.openGenericPopup(errorPopupData);
      }
    })
    )
  }

  editingPortabilityDetails(data) {
    this.fetchSelectedpolicyApiCall(data);
  }

  onSubmit() {
    this.isSubmitted = true;
    this.policyDetails();
  }

  // onRefresh(status) {
  //   if (status) {
  //     this.policyDetails();
  //   }
  // }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  editImage: string = 'assets/images/BBR/Edit.svg'; // Initial image

  openSearchBottomSheet() {
    if (this.editImage === 'assets/images/BBR/Edit.svg') {
      this.editImage = 'assets/images/BBR/EditActive.svg';
    } else {
      this.editImage = 'assets/images/BBR/Edit.svg';
    }
    const x = this.productList.filter(item => item.productCode == this.mypolicyForm.value.productType);
    const y = x[0].productList;
    const searchBottomSheet = this.dialog.open(PopupComponent, {
      panelClass: 'my-custom-payment',
      maxWidth: '100vw',
      // height: '50%',
      disableClose: true,
      data: {
        popupType: popupType.search,
        data: {
          controls: [{
            type: 'myDropdown',
            label: 'Product Type',
            list: this.productList,
            value: this.mypolicyForm.value.productType
          },
          {
            type: 'myDependentDropdown',
            label: 'Product Name',
            list: y,
            value: this.mypolicyForm.value.productName
          },
          {
            type: 'rangepicker',
            label: 'Select date range',
            startDate: this.mypolicyForm.value.startDate,
            endDate: this.mypolicyForm.value.endDate,
          }],
          dates: {
            minDate: this.minMax.minDate,
            maxDate: this.minMax.maxDate,
          },
        }
      }
    }
    );
    // searchBottomSheet.afterClosed().subscribe((data) => this.patchSearchParameters(data));
    searchBottomSheet.afterClosed().subscribe((data) => {
      this.editImage = 'assets/images/BBR/Edit.svg';
      this.patchSearchParameters(data);
    });
  }

  patchSearchParameters(data) {
    if (data) {
      for (let i of data) {
        if (i.type == 'myDropdown') {
          this.mypolicyForm.controls['productType'].setValue(i.value);
        }
        else if (i.type == 'myDependentDropdown' && i.value) {
          this.mypolicyForm.controls['productName'].setValue(i.value);
        }
        else if (i.type == 'rangepicker') {
          this.mypolicyForm.controls['startDate'].setValue(i.startDate);
          this.mypolicyForm.controls['endDate'].setValue(i.endDate);
        }
      }
      this.searchDetails();
    }
  }

  // getReportList() {
  //   // const payload = {
  //   //   IMID: this.bookFormGroup.value.IMID,
  //   //   StartDate: this.utilityService.formatDateWithDash(this.bookFormGroup.value.startDate),
  //   //   EndDate: this.utilityService.formatDateWithDash(this.bookFormGroup.value.endDate),
  //   //   productClass: this.bookFormGroup.value.productType == "Health" ? "HEALTH" : "HOME"
  //   // }
  //   this.updateInfoCard();
  // }

  // updateInfoCard() {
  //   // this.product = this.bookFormGroup.value.productType;
  //   // this.startDate = this.bookFormGroup.value.startDate;
  //   // this.endDate = this.bookFormGroup.value.endDate
  // }

  searchFilter() {
    this.isSearchVisible = true;
  }

  onToggle(event) {
    this.toggleValue = event.value;
  }

  applyPolicyFilter(isChecked: boolean, value: string) {
    value = value.toLowerCase();
    if (isChecked) {
      if (!this.policyFilter.includes(value)) {
        this.policyFilter.push(value);
      }
    } else {
      const index = this.policyFilter.indexOf(value);
      if (index >= 0) {
        this.policyFilter.splice(index, 1);
      }
    }
    this.applyFilter();
  }

  filterImage: string = 'assets/images/BBR/FilterMobile.svg'; // Initial image

  openFilterBottomSheet() {
    if (this.filterImage === 'assets/images/BBR/FilterMobile.svg') {
      this.filterImage = 'assets/images/BBR/FilterActive.svg';
    } else {
      this.filterImage = 'assets/images/BBR/FilterMobile.svg';
    }
    const filterBottomSheet = this.dialog.open(PopupComponent, {
      panelClass: 'my-custom-payment',
      maxWidth: '100vw',
      // height: '44%',
      disableClose: true,
      data: {
        popupType: popupType.filter,
        data: [
          {
            label: 'Status Type',
            list: this.policyFilterList,
            filter: this.policyFilter
          }]
      },
    }
    );
    filterBottomSheet.afterClosed().subscribe((data) => {
      // Reset the image back to FilterMobile.svg
      this.filterImage = 'assets/images/BBR/FilterMobile.svg';
      this.patchFilterParameters(data);
    });
  }

  patchFilterParameters(data) {
    if (data) {
      this.policyFilter = data[0].filter;
    }
    this.applyFilter();
  }

  clearInputField() {
    this.inputValue = '';
    this.queryData();
  }

  applyFilter() {
    this.clearInputField();
    this.filterLogic();
  }

  setPlaceHolderText() {
    this.searchPlaceHolder = this.phText[this.i];
    this.i++;
    if (this.i == this.phText.length) {
      this.i = 0;
    }
  }

  inputFocus() {
    this.showCancel = true;
  }

  inputBlur() {
    this.showCancel = false;
  }

  closeClear() {
    this.isSearchVisible = false;
    this.clearInputField();
  }

  filterLogic() {
    if (!(this.utilityService.isEmptyOrNull(this.policyFilter))) {
      this.filteredData = this.policyDetails.filter(item => this.policyFilter.includes(item['Status'].toLowerCase()))
    } else {
      this.filteredData = this.policyDetails;
    }
  }

  queryData() {
    const query = this.inputValue.trim();
    if (!query && this.utilityService.isEmptyOrNull(this.policyFilter)) {
      this.filteredData = this.policyDetails;
    }
    else if (!query && !(this.utilityService.isEmptyOrNull(this.policyFilter))) {
      this.filterLogic();
    }
    else if (query && this.utilityService.isEmptyOrNull(this.policyFilter)) {
      this.filteredData = this.policyDetails.filter(item => {
        const customerName = item['CustomerName'] ? item['CustomerName'].toString().toLowerCase() : '';
        const proposalNumber = item['ProposalNumber'] ? item['ProposalNumber'].toString().toLowerCase() : '';
        const pfPolicyNo = item['PFPolicyNo'] ? item['PFPolicyNo'].toString().toLowerCase() : '';
        return customerName.includes(query.toLowerCase()) ||
          proposalNumber.includes(query.toLowerCase()) ||
          pfPolicyNo.includes(query.toLowerCase());
      });
    }
    else if (query && !(this.utilityService.isEmptyOrNull(this.policyFilter))) {
      this.filteredData = this.filteredData.filter(item => {
        const customerName = item['CustomerName'] ? item['CustomerName'].toString().toLowerCase() : '';
        const proposalNumber = item['ProposalNumber'] ? item['ProposalNumber'].toString().toLowerCase() : '';
        const pfPolicyNo = item['PFPolicyNo'] ? item['PFPolicyNo'].toString().toLowerCase() : '';
        return customerName.includes(query.toLowerCase()) ||
          proposalNumber.includes(query.toLowerCase()) ||
          pfPolicyNo.includes(query.toLowerCase());
      });
    }

  }

  forQcFlow() {
    let QcFlow = this.storageService.getAESDecryptedData(userEnums.QcFlow) || false;
    if (QcFlow) {
      let col = {
        columnDef: 'HtStatus',
        header: 'Nysa HT Status',
        expression: 'data.TransFor == "R" && !this.utilityService.isEmptyOrNull(data.HtStatus)',
        cell: (element: any) => `${element.HtStatus}`,
      }
      this.displayCol.push(col);
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}