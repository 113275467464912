import { routeEnums } from "../enums/routeEnums"


export const mappedRoutes = [
   {
      paramName: '',
      routeName: routeEnums.DASHBOARD
   },
   {
      paramName: 'dash',
      productName: 'Dashboard',
      routeName: routeEnums.DASHBOARD
   },
   {
      paramName: 'upload',
      productName: 'Upload-Document',
      routeName: routeEnums.DocumentUpload
   },
   {
      paramName: 'plts',
      productName: 'Plutos',
      routeName: routeEnums.PLUTOS
   },
   {
      paramName: 'hap',
      productName: 'Health Advantedge Plus',
      routeName: routeEnums.HAP
   },
   {
      paramName: 'elevate',
      productName: 'Elevate',
      routeName: routeEnums.Elevate
   },
   {
      paramName: 'calculate-elevate',
      productName: 'Calculate-Elevate',
      routeName: routeEnums.CalculateEevate
   },
   {
      paramName: 'gs',
      productName: 'Golden Shield',
      routeName: routeEnums.GoldenShield
   },
   {
      paramName: 'asp',
      productName: 'Arogya Sanjeevani',
      routeName: routeEnums.ASP
   },
   {
      paramName: 'hep',
      productName: 'Health Elite Plus',
      routeName: routeEnums.HealthElitePlus
   },
   {
      paramName: 'max',
      productName: 'Max Protect',
      routeName: routeEnums.MaxProtect
   },
   {
      paramName: 'hb',
      productName: 'Health Booster',
      routeName: routeEnums.HEALTH_BOOSTER
   },
   {
      paramName: 'cs',
      productName: 'Criti Shield',
      routeName: routeEnums.CritiShield
   },
   {
      paramName: 'fs',
      productName: 'Criti Shield',
      routeName: routeEnums.Familyshield
   },
   {
      paramName: 'renewalDue',
      productName: 'Renewal Due',
      routeName: routeEnums.Renewals
   },
   {
      paramName: 'instantRenewal',
      productName: 'Instant Renewal',
      routeName: routeEnums.InstantRenewal
   },
   {
      paramName: 'savedQuotes',
      productName: 'Saved Quotes',
      routeName: routeEnums.SavedQuotes
   },
   {
      paramName: 'student',
      productName: 'Student Travel',
      routeName: routeEnums.STUDENT_MEDICAL_TRAVEL
   },
   {
      paramName: 'international',
      productName: 'International Travel',
      routeName: routeEnums.INTERNATIONAL_TRAVEL
   }
]