import { Component, ElementRef, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TravelService } from 'src/app/shared/services/travel.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/shared/constants/constants';
import { CustomStepperService } from 'src/app/shared/services/custom-stepper.service';
import { CustomFooterService } from 'src/app/shared/services/custom-footer.service';
import { TravelDataStorageService } from '../travel.service';
import { TravelDataStorage, TravelModal, UserType, travelProducts } from '../travel.modal';
import { HealthDetails, studentPlanRequestPayload, RelationshipRequestPayload, RelationshipResponsePayload, SaveQuoteRequestPayload, StateResponsePayload, PlanResponsePayload } from 'src/app/shared/interface/travel';
import { healthMembers } from 'src/app/shared/enums/healthEnums';
import { StorageService } from 'src/app/shared/services/storage.service';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { popupButton, popupButtonAction, popupHeader, popupImgPath, popupType } from 'src/app/shared/enums/popupEnums';
import { PopupService } from 'src/app/shared/services/popup.service';
import { ChannelData } from 'src/app/shared/interface/swapPrameters';
import { userEnums } from 'src/app/shared/enums/userEnums';
import * as _ from 'underscore';
import { routeEnums } from 'src/app/shared/enums/routeEnums';

@Component({
  selector: 'app-student-medical-travel',
  templateUrl: './student-medical-travel.component.html',
  styleUrls: ['./student-medical-travel.component.scss']
})
export class StudentMedicalTravelComponent implements OnInit {
  studentMedicalTravelForm: FormGroup;
  policyDurationDetails = [{ label: '30 days', value: 30 }, { label: '60 days', value: 60 }, { label: '90 days', value: 90 }, { label: '120 days', value: 120 }, { label: '180 days', value: 180 }, { label: '270 days', value: 270 }, { label: '365 days', value: 365 }, { label: 'Custom', value: 1 }];
  subscription: Subscription[] = [];
  stateMaster: any[] = [];
  planMaster: any[] = [];
  relationshipMaster: any = [];
  travelFormData: TravelDataStorage;
  formValid: boolean;
  leavingMinMaxDate: { maxDate: Date; minDate: Date; };
  returningMinMaxDate: { maxDate: Date; minDate: Date; };
  departureMinMaxDate: { maxDate: Date; minDate: Date; };
  memberMinMaxDate: { maxDate: Date; minDate: Date; };
  isSubmitted: boolean = false;
  patchingDone: boolean = false;
  datepickerMobile: boolean = false;
  channelData: ChannelData;
  travelDataModal: TravelModal = new TravelModal(); //travel data modal 
  errorPopupData: PopupModal;
  multipleServiceError: boolean = false;
  loadFormContent: boolean = false;

  constructor(private fb: FormBuilder,
    public utilityService: UtilityService,
    private travelService: TravelService,
    private customStepperService: CustomStepperService,
    private customFooterService: CustomFooterService,
    private travelDataStorageService: TravelDataStorageService,
    private storageService: StorageService,
    private popupService: PopupService,
    private el: ElementRef
  ) {
  }

  ngOnInit() {
    this.popupService.closeAllDialog(true);
    setTimeout(() => {
      this.popupService.closeAllDialog(false);
    }, 0);
    this.onLoadSteps();
    if (window.innerWidth < 768) {
      this.datepickerMobile = true;
    } else {
      this.datepickerMobile = false;
    }
  }

  onLoadSteps() {
    this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
    this.createStudentMedicalForm().then(() => {
      this.fetchStateMaster().then(() => {
        this.fetchRelationshipMaster().then(() => {
          this.setMinMaxdate();

          if (this.travelFormData?.quoteDataSet == true) {
            this.patchingDone = true;
          }
        });
      });

    })
    this.travelFormData = this.travelDataStorageService.getTravelDataStorage();
    this.studentMedicalTravelForm.valueChanges.subscribe(data => {
      if (this.patchingDone && this.travelFormData.quoteDataSet) {
        // premium data set flag is used beacsuse user can come back and try to change the values.
        // In that case also we need to reset the stepper

        // And if user refreshes that means existing value is getting patched. In that case we are using the patchingDone flag
        // and resetting the premium footer
        this.showPremiumDetails(false);
        // Here we are resetting the stepper. Just to be sure if the quote is changed.
        // User needs to traverse through each steps
        this.customStepperService.resetStepper(true);
      }
    })
  }

  createStudentMedicalForm() {
    return new Promise((resolve, reject) => {

      let obj: any = {};
      let travelFormData = this.travelDataStorageService.getTravelDataStorage().quoteFormData;
      let isPremiumDataSet = this.travelDataStorageService.getTravelDataStorage().premiumDataSet;
      if (isPremiumDataSet) {
        obj = {
          productName: travelFormData.productName, state: travelFormData.state, isAlreadyTraveledFromIndia: travelFormData.isAlreadyTraveledFromIndia,
          isVisitingduringStudies: travelFormData.isVisitingduringStudies, isKeralaGST: travelFormData.isKeralaGST, departureDateFromIndia: travelFormData.departureDateFromIndia,
          policyStartDate: travelFormData.policyStartDate, leavingIndiaOn: travelFormData.leavingIndiaOn, policyDuration: travelFormData.policyDuration,
          policyDurationInDays: travelFormData.policyDurationInDays, policyEndDate: travelFormData.policyEndDate, studentDateOfBirth: travelFormData.studentDateOfBirth,
          plan: travelFormData.plan, sumInsured: travelFormData.sumInsured, noOfTraveller: travelFormData.noOfTraveller, coverageType: travelFormData.coverageType, coverageSubType: travelFormData.coverageSubType,
          relationshipDetails: travelFormData.relationshipDetails, planDetails: travelFormData.planDetails, stateDetails: travelFormData.stateDetails, members: travelFormData.members
        }
      } else {
        obj = {
          productName: travelProducts.studentMedicalTravel, state: '', isAlreadyTraveledFromIndia: false,
          isVisitingduringStudies: true, isKeralaGST: false, departureDateFromIndia: '',
          policyStartDate: '', leavingIndiaOn: '', policyDuration: '',
          policyDurationInDays: '', policyEndDate: '', studentDateOfBirth: '',
          plan: '', sumInsured: '', noOfTraveller: 1, coverageType: 'INDIVIDUAL', coverageSubType: 4,
          relationshipDetails: [], planDetails: [], stateDetails: [], members: this.fb.array([])
        }
      }
      this.studentMedicalTravelForm = this.fb.group({
        productName: [{ value: obj.productName, disabled: true }, Validators.required],
        state: [obj.state, Validators.required],
        isAlreadyTraveledFromIndia: [obj.isAlreadyTraveledFromIndia, Validators.required],
        isVisitingduringStudies: [obj.isVisitingduringStudies, Validators.required],
        isKeralaGST: [obj.isKeralaGST],
        departureDateFromIndia: [obj.departureDateFromIndia],
        policyStartDate: [{ value: obj.policyStartDate, disabled: true }],
        leavingIndiaOn: [obj.leavingIndiaOn, Validators.required],
        policyDuration: [obj.policyDuration, Validators.required],
        policyDurationInDays: [obj.policyDurationInDays],
        policyEndDate: [{ value: obj.policyEndDate, disabled: true }, Validators.required],
        // studentDateOfBirth: [obj.studentDateOfBirth, Validators.required],
        members: this.fb.array([]),
        plan: [obj.plan, Validators.required],
        sumInsured: [{ value: obj.sumInsured, disabled: false }],
        noOfTraveller: [obj.noOfTraveller],
        coverageType: [obj.coverageType],
        coverageSubType: [obj.coverageSubType],
        relationshipDetails: [obj.relationshipDetails],
        planDetails: [obj.planDetails],
        stateDetails: [obj.stateDetails]
      })
      // this.disableControls();
      if (!isPremiumDataSet) {
        this.changeIsVisitingduringStudies(this.studentMedicalTravelForm.value.isVisitingduringStudies);
      }
      this.members?.clear();
      this.createMembers(obj?.noOfTraveller).then(res => {
        // if (this.travelFormData?.quoteDataSet == true) {
        //   this.patchingDone = true;
        // }
        resolve(true)
      });

    })
  }

  // disableControls() {
  //   this.studentMedicalTravelForm.controls['productName'].disable();
  //   this.studentMedicalTravelForm.controls['policyStartDate'].disable();
  //   this.studentMedicalTravelForm.controls['policyEndDate'].disable();
  //   this.studentMedicalTravelForm.get('sumInsured').disable();
  // }

  get members(): FormArray {
    return this.studentMedicalTravelForm.get('members') as FormArray;
  }

  createMembers(data: any): Promise<void> {
    return new Promise((resolve) => {
      this.members?.clear();
      let fields: any = {};
      let obj: any = {};
      let travelFormData: any = this.travelDataStorageService.getTravelDataStorage().quoteFormData;
      let isPremiumDataSet = this.travelDataStorageService.getTravelDataStorage().premiumDataSet;
      for (let i = 0; i < data; i++) {
        if (isPremiumDataSet) {
          obj = {
            dob: travelFormData.members[i].DOB, age: travelFormData.age, relationship: travelFormData.members[i].RelationshipwithApplicant, type: travelFormData.members[i].MemberType,
            gender: travelFormData.members[i].gender
          }
        } else {
          obj = { dob: '', age: 0, relationship: '', type: '', gender: '' }
        }
        fields = {
          DOB: [obj.dob, Validators.required], age: [obj.age], RelationshipwithApplicant: [obj.relationship, Validators.required], MemberType: [obj.type], memberTypeCount: '',
          gender: [obj.gender]
        }
        this.members.push(this.fb.group(fields));
      }
      resolve();
    });
  }

  setMinMaxdate() {
    this.changePolicyStartDate();
    let nextYear = new Date().getFullYear() + 2;
    this.leavingMinMaxDate = { minDate: new Date(), maxDate: new Date(new Date().setDate(new Date().getDate() + 180)) };
    let minDate = this.studentMedicalTravelForm.value.leavingDate ? this.studentMedicalTravelForm.value.leavingDate : new Date();
    this.returningMinMaxDate = { minDate: minDate, maxDate: new Date(nextYear, 11, 31) };
    this.departureMinMaxDate = { minDate: new Date(new Date().setDate(new Date().getDate() - 60)), maxDate: new Date() };
    let memberMinDate = new Date(new Date().getFullYear() - 35, new Date().getMonth(), new Date().getDate());
  }

  addDays(date: Date, days: number) {
    let result = new Date(date);
    result.setDate(result.getDate() + (days - 1));
    return result;
  }

  changeState(state) {
    if (state == 'KERALA') {
      this.studentMedicalTravelForm.controls['isKeralaGST'].setValidators([Validators.required]);
      this.studentMedicalTravelForm.controls['isKeralaGST'].updateValueAndValidity();
    } else {
      this.studentMedicalTravelForm.controls['isKeralaGST'].clearValidators();
      this.studentMedicalTravelForm.controls['isKeralaGST'].updateValueAndValidity();
    }
  }

  changePlan(plan) {
    if (plan) {
      let sumInsured = this.studentMedicalTravelForm.value.planDetails.find(x => x.PlanName == plan).SumInsured;
      this.studentMedicalTravelForm.patchValue({ sumInsured: sumInsured });
      this.studentMedicalTravelForm.get('sumInsured').disable();
      this.studentMedicalTravelForm.controls['sumInsured'].setValidators([Validators.required]);
      this.studentMedicalTravelForm.controls['sumInsured'].updateValueAndValidity();
    } else {
      this.studentMedicalTravelForm.controls['sumInsured'].clearValidators();
      this.studentMedicalTravelForm.controls['sumInsured'].updateValueAndValidity();
    }
  }

  changeIsAlreadyTraveledFromIndia(value) {
    this.resetDetails();
    if (value) {
      this.studentMedicalTravelForm.patchValue({ policyStartDate: new Date() })
      this.studentMedicalTravelForm.controls['policyStartDate'].setValidators([Validators.required]);
      this.studentMedicalTravelForm.controls['policyStartDate'].updateValueAndValidity();
      this.studentMedicalTravelForm.controls['leavingIndiaOn'].clearValidators()
      this.studentMedicalTravelForm.controls['leavingIndiaOn'].updateValueAndValidity();
      this.studentMedicalTravelForm.controls['departureDateFromIndia'].setValidators([Validators.required]);
      this.studentMedicalTravelForm.controls['departureDateFromIndia'].updateValueAndValidity();
      this.studentMedicalTravelForm.controls['policyStartDate'].setValidators([Validators.required]);
      this.studentMedicalTravelForm.controls['policyStartDate'].updateValueAndValidity();
    } else {
      this.studentMedicalTravelForm.patchValue({ policyStartDate: '' })
      this.studentMedicalTravelForm.controls['leavingIndiaOn'].setValidators([Validators.required]);
      this.studentMedicalTravelForm.controls['leavingIndiaOn'].updateValueAndValidity();
      this.studentMedicalTravelForm.controls['departureDateFromIndia'].clearValidators();
      this.studentMedicalTravelForm.controls['departureDateFromIndia'].updateValueAndValidity();
      this.studentMedicalTravelForm.controls['policyStartDate'].clearValidators();
      this.studentMedicalTravelForm.controls['policyStartDate'].updateValueAndValidity();
    }
  }

  changeIsVisitingduringStudies(value) {
    this.studentMedicalTravelForm.controls['planDetails'].patchValue([]);
    this.studentMedicalTravelForm.controls['plan'].patchValue('');
    this.studentMedicalTravelForm.controls['sumInsured'].patchValue('');
    this.fetchPlans();
  }

  changePolicyDuration(value?) {
    this.studentMedicalTravelForm.patchValue({ policyEndDate: '' });
    let date = this.studentMedicalTravelForm.value.isAlreadyTraveledFromIndia ? this.studentMedicalTravelForm.controls['policyStartDate'].value : this.studentMedicalTravelForm.value.leavingIndiaOn;
    let policyDuration = value ? value : this.studentMedicalTravelForm.value.policyDuration;
    let endDate = this.addDays(new Date(date), policyDuration);
    if (date && policyDuration != 1) {
      this.studentMedicalTravelForm.patchValue({ policyEndDate: new Date(endDate) });
    }

    let policyDurationInDays = this.travelFormData?.quoteDataSet ? this.travelFormData.quoteFormData.policyDurationInDays : '';
    let conditionValue = this.travelFormData?.quoteDataSet ? this.travelFormData.quoteFormData.policyDuration : value;
    this.studentMedicalTravelForm.controls['policyDurationInDays'].patchValue(policyDurationInDays);
    if (conditionValue == 1) {
      this.studentMedicalTravelForm.get('policyDurationInDays').setValidators([Validators.required]);
      this.studentMedicalTravelForm.get('policyDurationInDays').updateValueAndValidity();
    } else {
      this.studentMedicalTravelForm.get('policyDurationInDays').clearValidators();
      this.studentMedicalTravelForm.get('policyDurationInDays').updateValueAndValidity();
    }
  }

  changeDateOfBirth(index) {
    let dob = this.studentMedicalTravelForm.value.members[index].DOB;
    let age = (new Date().getFullYear() - new Date(dob).getFullYear());
    let type = age > 18 ? 'Adult' : 'Child';
    this.studentMedicalTravelForm.controls['members']['controls'][index].get('age').patchValue(age);
    this.studentMedicalTravelForm.controls['members']['controls'][index].get('MemberType').patchValue(type);
  }

  changePolicyStartDate(selectedDate?) {
    let date = selectedDate ? selectedDate : new Date();
    let minDate = new Date(date.getFullYear() - 35, date.getMonth(), date.getDate());
    let maxDate = new Date(date.getFullYear() - 16, date.getMonth(), date.getDate());
    this.memberMinMaxDate = { minDate: minDate, maxDate: maxDate };
    this.changePolicyDuration();
  }
  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  changeCustomDuration(duration) {
    if (duration >= 30) {
      if (duration > 365) {
        let msg = 'Policy duration cannot be greater than 365 days';
        this.showGenericErrorPopup(msg).then(res => {
          this.studentMedicalTravelForm.controls['policyEndDate'].patchValue('');
          this.studentMedicalTravelForm.controls['policyDurationInDays'].patchValue('');
        })
      } else {
        let date = this.studentMedicalTravelForm.controls['isAlreadyTraveledFromIndia'].value ? this.studentMedicalTravelForm.controls['departureDateFromIndia'].value : this.studentMedicalTravelForm.controls['leavingIndiaOn'].value;
        let endDate = this.addDays(new Date(date), duration);
        if (!this.studentMedicalTravelForm.value.policyDurationInDays) {
          this.studentMedicalTravelForm.controls['policyEndDate'].patchValue('');
        } else {
          this.studentMedicalTravelForm.controls['policyEndDate'].patchValue(endDate);
        }
      }
    } else {
      let msg = 'Policy duration cannot be less than 30 days';
      this.showGenericErrorPopup(msg).then(res => {
        this.studentMedicalTravelForm.controls['policyEndDate'].patchValue('');
        this.studentMedicalTravelForm.controls['policyDurationInDays'].patchValue('');
      })
    }
  }

  changeRelationship(relationship, index) {
    // console.log('inside changeRelationship ',relationship)
    let relationData = this.relationshipMaster.filter(x => x.RelationshipName == relationship);
    let gender = relationData[0].Gender
    this.studentMedicalTravelForm.controls['members']['controls'][0].patchValue({ gender: gender });
  }

  resetDetails() {
    this.studentMedicalTravelForm.controls['departureDateFromIndia'].patchValue('');
    this.studentMedicalTravelForm.controls['leavingIndiaOn'].patchValue('');
    this.studentMedicalTravelForm.controls['policyStartDate'].patchValue('');
    this.studentMedicalTravelForm.controls['policyEndDate'].patchValue('');
    this.studentMedicalTravelForm.controls['policyDuration'].patchValue('');
    this.studentMedicalTravelForm.controls['isVisitingduringStudies'].patchValue(true);
    this.studentMedicalTravelForm.controls['plan'].patchValue('');
    this.studentMedicalTravelForm.controls['planDetails'].patchValue([]);
    this.studentMedicalTravelForm.controls['sumInsured'].patchValue('');
    this.studentMedicalTravelForm.controls['members']['controls'][0]['controls']['DOB'].patchValue('');
    this.studentMedicalTravelForm.controls['members']['controls'][0]['controls']['RelationshipwithApplicant'].patchValue('');
    this.changeIsVisitingduringStudies(true);
  }

  fetchStateMaster() {
    return new Promise((resolve) => {
      this.subscription.push(this.travelService.fetchStateMasterDetails('').subscribe({
        next: (response: StateResponsePayload) => {
          if (response.StatusCode == Constants.statusCode_success) {
            this.stateMaster = response.Data;
            resolve(true);
            // this.studentMedicalTravelForm.patchValue({ stateDetails: [] });
            // this.studentMedicalTravelForm.patchValue({ stateDetails: response.Data });
          } else {
            this.multipleServiceError = true;
            this.stateMaster = [];
            resolve(true)
          }
        },
        error: error => {
        }
      })
      )
    })
  }

  constructFetchRelationshipRequest(): RelationshipRequestPayload {
    let request = { "CoverageType": this.studentMedicalTravelForm.value.coverageType, "SubType": this.studentMedicalTravelForm.value.coverageSubType }
    return request;
  }

  fetchRelationshipMaster(): Promise<void> {
    return new Promise((resolve) => {
      let request = this.constructFetchRelationshipRequest();
      this.subscription.push(this.travelService.fetchRelationshipMasterDetails(request).subscribe({
        next: (response: RelationshipResponsePayload) => {
          if (response.StatusCode == Constants.statusCode_success) {
            this.relationshipMaster = response.Data;
            this.studentMedicalTravelForm.patchValue({ relationshipDetails: [] });
            this.studentMedicalTravelForm.patchValue({ relationshipDetails: response.Data })
            this.loadFormContent = true;
            resolve();
          } else {
            this.multipleServiceError = true;
            this.studentMedicalTravelForm.patchValue({ relationshipDetails: [] });
            resolve();
          }
        },
        error: error => {
        }
      })
      )
    })
  }

  constructFetchPlansRequest(): studentPlanRequestPayload {
    let locCode = this.studentMedicalTravelForm.value.isVisitingduringStudies ? 'WORLDWIDE' : 'EXCLUSCANADA';
    let request = { "GeoCode": locCode, "UserType": this.channelData.UT || this.travelDataModal.agent }
    return request;
  }

  fetchPlans() {
    let request = this.constructFetchPlansRequest();
    this.subscription.push(this.travelService.fetchPlanDetails(request).subscribe({
      next: (response: PlanResponsePayload) => {
        if (response.StatusCode == Constants.statusCode_success) {
          // this.planMaster = response.Data;
          this.studentMedicalTravelForm.patchValue({ planDetails: [] });
          this.studentMedicalTravelForm.patchValue({ planDetails: response.Data });
        } else {
          // this.multipleServiceError = true;
        }
      },
      error: error => {
      }
    })
    )
  }

  constructSaveQuoteRequest(): SaveQuoteRequestPayload {
    let stateDetails = this.stateMaster.find((x: any) => x.StateName == this.studentMedicalTravelForm.value.state);
    let isGSTRegistered = this.studentMedicalTravelForm.value.isKeralaGST;
    let isJammuKashmir = stateDetails.StateName == 'JAMMU & KASHMIR';
    let planDetails = this.studentMedicalTravelForm.value.planDetails.find(x => x.PlanName == this.studentMedicalTravelForm.value.plan);
    let leavindDate = this.studentMedicalTravelForm.value.isAlreadyTraveledFromIndia ? this.studentMedicalTravelForm.controls['policyStartDate'].value : this.studentMedicalTravelForm.value.leavingIndiaOn;
    let firstDeparture = this.studentMedicalTravelForm.value.isAlreadyTraveledFromIndia ? this.studentMedicalTravelForm.value.departureDateFromIndia : this.studentMedicalTravelForm.value.leavingIndiaOn;
    let dob = this.studentMedicalTravelForm.value.members[0].DOB;

    let request = {
      "UserType": this.utilityService.isEmptyOrNull(this.channelData.UT) ? "AGENT" : this.channelData.UT,
      "ipaddress": this.utilityService.isEmptyOrNull(this.channelData.CIP) ? this.travelDataModal.ipAddress : this.channelData.CIP,
      "IsResIndia": "Yes",
      "PolicyStartDate": "",
      "PolicyEndDate": "",
      "IsPlanUpgrade": "No",
      "NoOfTravelers": 1,
      "IsImmigrantVisa": "No",
      "planName": planDetails.PlanName,
      "GSTStateCode": stateDetails.StateId.toString(),
      "GSTStateName": stateDetails.StateName,
      "isGSTRegistered": isGSTRegistered,
      "IsJammuKashmir": isJammuKashmir,
      // "productName": travelProducts.studentMedicalTravel,
      "HasTravelled": this.studentMedicalTravelForm.value.isAlreadyTraveledFromIndia ? "Yes" : "No",
      "FirstDeparture": this.utilityService.getFormattedDate(firstDeparture, 'dd-MMM-yyyy'),
      "LeavingIndia": this.utilityService.getFormattedDate(leavindDate, 'dd-MMM-yyyy'),
      "ReturnIndia": this.utilityService.getFormattedDate(this.studentMedicalTravelForm.controls['policyEndDate'].value, 'dd-MMM-yyyy'),
      "EndDate": this.utilityService.getFormattedDate(this.studentMedicalTravelForm.controls['policyEndDate'].value, 'dd-MMM-yyyy'),
      "PolicyDuration": this.studentMedicalTravelForm.value.policyDuration.toString(),
      "VisitingUSCanada": this.studentMedicalTravelForm.value.isVisitingduringStudies ? "Yes" : "No",
      "DOB": this.utilityService.getFormattedDate(dob, 'dd-MMM-yyyy'),
      "PlanID": planDetails.PlanCode,
      "InsuredAmount": this.studentMedicalTravelForm.controls['sumInsured'].value,
      "Members": [{
        'DateOfBirth': this.utilityService.getFormattedDate(dob, 'dd-MMM-yyyy'),
        'PlanID': planDetails.PlanCode
      }]
    }
    return request;
  }

  getQuote(event) {
    this.isSubmitted = true;
    if (!this.studentMedicalTravelForm.valid) {
      for (const key of Object.keys(this.studentMedicalTravelForm.controls)) {
        let controlName = this.studentMedicalTravelForm.controls[key]['controls'];
        if (this.studentMedicalTravelForm.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('#' + key + '.ng-invalid');
          invalidControl.scrollIntoView({ behaviour: "instant", block: "center", inline: "center" });
          invalidControl.focus();
          break;
        }
      }
      this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
    } else if (this.studentMedicalTravelForm.valid) {
      let request = this.constructSaveQuoteRequest();
      this.subscription.push(this.travelService.saveQuoteDetails(request).subscribe({
        next: (response) => {
          if (response.StatusCode == Constants.statusCode_success) {
            this.setStudentFormData(response?.Data);
            if (!this.utilityService.isEmptyOrNull(this.travelFormData.premiumFormData.totalPremium) && this.travelFormData.premiumFormData.totalPremium != '0') {
              this.customStepperService.emitStepData({ status: true, buttonId: 'f1' });
            }
            else {
              this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
            }
            this.showPremiumDetails(true);
          } else if (response.StatusCode != Constants.statusCode_success) {
            // this.multipleServiceError = true;
            this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          }
        },
        error: error => {
        }
      })
      )
    }
  }

  getMemberRequest() {
    let members = [];
    let adultDetails = this.getAdultDetails(); let childDetails = this.getChildDetails();
    if (adultDetails.length > 0) {
      adultDetails.forEach((element, i) => {
        members.push({ DOB: this.utilityService.formatDate(element.dob), MemberType: healthMembers.Adult, RelationshipwithApplicant: element.relationship })
      });
    }
    if (childDetails.length > 0) {
      childDetails.forEach((element, j) => {
        members.push({ DOB: this.utilityService.formatDate(element.dob), MemberType: healthMembers.Child, RelationshipwithApplicant: element.relationship })
      });
    }

    return members;
  }

  getAdultDetails() {
    return this.studentMedicalTravelForm.value.members.filter(x => x.MemberType == healthMembers.Adult) || [];
  }

  getChildDetails() {
    return this.studentMedicalTravelForm.value.members.filter(x => x.MemberType == healthMembers.Child) || [];
  }

  setStudentFormData(response: HealthDetails) {
    let resetFlag: boolean = this.checkMemberDetails();
    this.travelFormData.quoteFormData = this.studentMedicalTravelForm.value;
    this.travelFormData.quoteFormData.productName = this.studentMedicalTravelForm.controls['productName'].value;
    this.travelFormData.quoteFormData.sumInsured = this.studentMedicalTravelForm.controls['sumInsured'].value;
    this.travelFormData.quoteFormData.policyEndDate = this.studentMedicalTravelForm.controls['policyEndDate'].value;
    this.travelFormData.quoteFormData.policyStartDate = this.studentMedicalTravelForm.controls['policyStartDate'].value;
    this.travelFormData.quoteFormData.stateId = this.stateMaster.find(x => x.StateName == this.studentMedicalTravelForm.value.state).StateId;
    this.travelFormData.quoteFormData.planCode = this.studentMedicalTravelForm.value.planDetails.find(x => x.PlanName == this.studentMedicalTravelForm.value.plan).PlanCode;
    this.travelFormData.quoteFormData.adultDetails = this.getAdultDetails();
    this.travelFormData.quoteFormData.childDetails = this.getChildDetails();
    this.travelFormData.quoteFormData.totalMemberData = this.getMemberRequest();
    this.studentMedicalTravelForm.value.members.forEach((member, index) => {
      let relationshipData = this.relationshipMaster.filter(x => x.RelationshipName == member['RelationshipwithApplicant'])
      this.travelFormData.quoteFormData['members'][index]['relationshipId'] = relationshipData[0].RelationshipID;
    })
    this.travelFormData.quoteFormData.quoteSummary = [];
    this.travelFormData.quoteDataSet = true;
    this.constructPremiumData(response);
    this.setOtherFormFlags();

    this.travelDataStorageService.setTravelDataStorage(this.travelFormData);
  }

  constructPremiumData(response: HealthDetails) {
    this.travelFormData.premiumFormData.totalPremium = response.TotalPremium;
    this.travelFormData.premiumFormData.basicPremium = response.BasicPremium;
    this.travelFormData.premiumFormData.totalTax = response.TotalGSTAmt;
    this.travelFormData.premiumFormData.quoteId = response.QuoteID;
    this.travelFormData.premiumFormData.startDate = response.StartDate;
    this.travelFormData.premiumFormData.endDate = response.EndDate;
    this.travelFormData.premiumFormData.travelType = this.studentMedicalTravelForm.value.travelType;
    this.travelFormData.premiumFormData.travelSubType = this.studentMedicalTravelForm.value.travelSubType;
    this.travelFormData.premiumFormData.numberOfTraveller = this.studentMedicalTravelForm.value.numberOfTraveller;
    this.travelFormData.premiumFormData.totalDays = this.travelFormData.quoteFormData.policyDuration ? parseInt(this.travelFormData.quoteFormData.policyDuration) :
      this.travelFormData.quoteFormData.policyDurationInDays;
    this.travelFormData.premiumFormData.country = this.studentMedicalTravelForm.value.isVisitingduringStudies ? 'USA / Canada' : '-';
    this.travelFormData.premiumDataSet = true;
    this.formValid = true;
  }

  showPremiumDetails(data: boolean) {
    this.customFooterService.showPremiumDetails(data);
  }


  checkMemberDetails(): boolean {
    let nonEqualMembers = [];
    let resetFlag: boolean = false;
    // Adult Details
    // If new adult details and child details length are same then we need to check the info
    if (this.members.length == this.travelFormData.quoteFormData.adultDetails.length) {
      let existingAdultDetails = this.travelFormData.quoteFormData.adultDetails;
      existingAdultDetails.forEach((adultData, index) => {
        let tempAdultDetails = adultData;
        // When there is self detected there is change in format of dob.
        if (this.travelFormData.kycStatus.ovdSelf || this.travelFormData.kycStatus.normalSelf) {
          // convert dob in respected format
          for (let keys in tempAdultDetails) {
            // There are chances date is patched along with orignal time stamp
            if (keys.includes('dob') && !tempAdultDetails[keys].includes('T')) {
              tempAdultDetails[keys] = (new Date(tempAdultDetails[keys]));
            }
          }
        }
        // Looping through keys
        if (JSON.stringify(tempAdultDetails)[index]['dob'] != JSON.stringify(this.members.value[index]['dob'])) {
          nonEqualMembers.push(index);
        }
      });
    }
    // If the length is only not equal that means adult data is been modified
    else if (this.members.length != this.travelFormData.quoteFormData.adultDetails.length) {
      nonEqualMembers.push(0);
    }

    // Child Details

    if (this.members.length == this.travelFormData.quoteFormData.childDetails.length) {
      let existingChildDetails = this.travelFormData.quoteFormData.childDetails;
      existingChildDetails.forEach((childData, index) => {
        // Looping through keys
        if (JSON.stringify(childData) != JSON.stringify(this.members.value[index])) {
          nonEqualMembers.push(index);
        }
      });
    }

    // If the length is only not equal that means adult data is been modified
    else if (this.members.length != this.travelFormData.quoteFormData.childDetails.length) {
      nonEqualMembers.push(0);
    }

    else {
      // Adult members found to be equal
      nonEqualMembers = [];
    }

    // If there is mismatch of data in terms of length or details
    if (nonEqualMembers.length > 0) {
      resetFlag = true;
    }
    else {
      resetFlag = false;
    }
    return resetFlag;
  }

  // setOtherFormFlags() {
  //   // this.travelFormData.kycStatus.ekycMandatory = false;
  //   // this.travelFormData.kycStatus.normal = false;
  //   // this.travelFormData.kycStatus.normalSelf = false;
  //   // this.travelFormData.kycStatus.ovd = false;
  //   // this.travelFormData.kycStatus.ovdSelf = false;
  //   // this.travelFormData.kycStatus.isKYCDoneSuccessfully = false;
  //   // this.travelFormData.kycStatus.idType = false;
  //   // this.travelFormData.kycStatus.certNumber = '';
  //   if (this.travelFormData?.insuredFormData?.insuredMemberDetails.length) {

  //     this.travelFormData.insuredFormData.insuredMemberDetails.forEach((member, index) => {
  //       member['controls'].forEach((control, controlIndex) => {
  //         let controlName = control['controlName'];
  //         if (controlName == 'applicantRelationship') {
  //           this.travelFormData.insuredFormData.insuredMemberDetails[index]['controls'][controlIndex][controlName] =
  //             this.travelFormData.quoteFormData.members[index]['RelationshipwithApplicant'];
  //         }
  //         else if (controlName == 'dob') {
  //           this.travelFormData.insuredFormData.insuredMemberDetails[index]['controls'][controlIndex][controlName] =
  //             this.travelFormData.quoteFormData.members[index]['DOB'];
  //         }
  //       })
  //     })
  //     // this.travelFormData.insuredDataSet = false;
  //     // this.travelFormData.kycDataSet = false;
  //     // this.travelFormData.kycFormData = this.travelDataStorageService.resetKYCdata();
  //     this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
  //   }
  // }
  setOtherFormFlags() {
    if (this.travelFormData?.insuredFormData?.insuredMemberDetails.length) {

      this.travelFormData.insuredFormData.insuredMemberDetails.forEach((member, index) => {
        member['controls'].forEach((control, controlIndex) => {
          let controlName = control['controlName'];
          if (controlName == 'applicantRelationship') {
            if (this.travelFormData.insuredFormData.insuredMemberDetails[index]['controls'][controlIndex][controlName] != this.travelFormData.quoteFormData.members[index]['RelationshipwithApplicant']) {
              this.travelFormData.insuredDataSet = false;
            }
            this.travelFormData.insuredFormData.insuredMemberDetails[index]['controls'][controlIndex][controlName] =
              this.travelFormData.quoteFormData.members[index]['RelationshipwithApplicant'];
          }
          else if (controlName == 'dob') {
            if (this.travelFormData.insuredFormData.insuredMemberDetails[index]['controls'][controlIndex][controlName] != this.travelFormData.quoteFormData.members[index]['DOB']) {
              this.travelFormData.insuredDataSet = false;
            }
            this.travelFormData.insuredFormData.insuredMemberDetails[index]['controls'][controlIndex][controlName] =
              this.travelFormData.quoteFormData.members[index]['DOB'];
          }
        })
      })
      this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
    }
  }

  showGenericErrorPopup(msg): Promise<any> {
    return new Promise((resolve: any) => {
      this.errorPopupData = this.popupService.fetchErrorPopupMsg(msg);
      this.popupService.openGenericPopup(this.errorPopupData);
      resolve();
    })
  }

  onRefresh(status) {
    if (status) {
      // this.fetchStateMaster();
      this.onLoadSteps();
    }
  }

  unsubscribeSubscription() {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

}
