import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MasterStateResponsePayload } from '../interface/moterMaster';
import { GenericService } from '../services/generic.service';

@Injectable({
  providedIn: 'root'
})
export class MotorMasterService_M {

  constructor(private generic: GenericService) {}
  
  readonly baseURI = `${environment.baseURL}master/MotorMaster/`;
  readonly getAllStatesServiceName = 'GetAllStates';

  motorMasterGetAllStates(): Observable<MasterStateResponsePayload> {
    const endpoint = `${this.baseURI}${this.getAllStatesServiceName}`;
    return this.generic.genericService<MasterStateResponsePayload>(endpoint, {});
  }
}
