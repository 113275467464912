import { Injectable } from '@angular/core';
import { SessionStorageService, StorageService } from 'src/app/shared/services/storage.service';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { KycFormData, TravelDataModal, TravelDataStorage, TravelPremiumDetails } from './travel.modal';

@Injectable({
  providedIn: 'root'
})
export class TravelDataStorageService {
  travelDataModal: TravelDataModal = new TravelDataModal();

  constructor(private storageService: StorageService, private sessionService: SessionStorageService) { }


  setTravelDataStorage(form: TravelDataStorage) {
    this.travelDataModal.setTravelDataStorage(form);
    this.storageService.setAESEncryptedData(userEnums.HealthFormData, form);
  }

  getTravelDataStorage(): TravelDataStorage {
    let travelStorageForm;
    // Below logic is written so that we should fetch initial data when user lands on component.
    // But if the form structure is already present and user clicks on refresh then we don't want our data to reset
    // So due to this reason we have written below logic
    if (!this.sessionService.has(userEnums.HealthFormData)) {
      travelStorageForm = this.travelDataModal.getTravelDataStorage();
    }
    else {
      travelStorageForm = this.storageService.getAESDecryptedData(userEnums.HealthFormData);
    }

    this.storageService.setAESEncryptedData(userEnums.HealthFormData, travelStorageForm);
    return this.storageService.getAESDecryptedData(userEnums.HealthFormData);
  }

  resetTravelDataStorage() {
    let form: TravelDataStorage = this.travelDataModal.resetTravelDataStorage();
    this.setTravelDataStorage(form);
  }

  resetKYCdata(): KycFormData {
    return this.travelDataModal.resetKycFormData();
  }

  initializePremiumData(): TravelPremiumDetails {
    let premiumDetails: TravelPremiumDetails = {
      productName: '',
      travelType: '',   
      travelSubType: '', 
      numberOfTraveller: 0,
      startDate: '',
      endDate: '',
      plan: '',
      country: '',
      sumInsured: '',
      totalPremium: '',
      basicPremium: '',
      totalTax: '',
      quoteId: 0,
      totalDays: 0
    };
    return premiumDetails
  }
}
