import { Component, ElementRef, EventEmitter, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { concatMap, forkJoin, Observable, of, Subscription } from 'rxjs';
import { Constants } from 'src/app/shared/constants/constants';
import { MPZone, healthMembers, healthProducts, healthToggle, healthYears, patterns } from 'src/app/shared/enums/healthEnums';
import { SaveEditQuoteV1Request } from 'src/app/shared/interface/transactionHealthAdvantege';
import { HealthAdvantedgeService } from 'src/app/shared/proxy-services/health-advantedge.service';
import { HealthMasterService } from 'src/app/shared/proxy-services/health-master.service';
import { RTOListService } from 'src/app/shared/proxy-services/rto-list.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MaxProtectModal } from './max-protect.modal';
import { RTOListRequestPayload, RTOListResponsePayload } from 'src/app/shared/interface/rto-list';
import { ZoneRequestPayload, ZoneResponsePayload, StepperCheckDataQuote } from 'src/app/shared/interface/health-advantedge';
import { RelationshipRequestPayload, RelationshipResponsePayload } from 'src/app/shared/interface/healthMaster';
import { HealthDataStorageService } from '../../health.service';
import { HealthDetailsData, MaxProtectResponsePayload, SubProdDetail, SumInsuredDetail } from 'src/app/shared/interface/max-protect';
import { HealthDataStorage, PremiumDetailsData } from '../../health.modal';
import { PopupService } from 'src/app/shared/services/popup.service';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { MatSelect } from '@angular/material/select';
import { MaxProtectService } from 'src/app/shared/proxy-services/max-protect.service';
import { CustomStepperService } from 'src/app/shared/services/custom-stepper.service';
import { CustomFooterService } from 'src/app/shared/services/custom-footer.service';
import { EmitButtonData } from 'src/app/shared/interface/custom-footer';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { SessionStorageService, StorageService } from 'src/app/shared/services/storage.service';
import { environment } from 'src/environments/environment';
import { AgentService_A } from 'src/app/shared/proxy-services/AuthService/agent.service';
import { ChannelData } from 'src/app/shared/interface/swapPrameters';
import { ConvertSavedQuotesResponsePayload, SavedQuote } from 'src/app/shared/interface/savedQuotes';
import { StepData } from 'src/app/shared/interface/custom-stepper';

@Component({
  selector: 'app-max-protect',
  templateUrl: './max-protect.component.html',
  styleUrls: ['./max-protect.component.scss']
})

export class MaxProtectComponent implements OnInit {
  patchingDone: boolean = false;
  initialFormState = {};
  maxProtectForm: FormGroup;
  formSubmitted: boolean = false;
  healthProducts = healthProducts;
  maxProtectDataModal: MaxProtectModal = new MaxProtectModal();
  maxProtectFormData: HealthDataStorage;
  maxProtectMasterData: MaxProtectResponsePayload;
  pType: SubProdDetail[] = [];
  planType = '';
  classicAddons: string[] = [];
  premiumAddons: string[] = [];
  toggleAddons: boolean = true;
  copayArray = [];
  tempAddons = {};
  adultRelationShips = [];
  childRelationShips = [];
  adultCountData = this.maxProtectDataModal.adultCount;
  childCountData = this.maxProtectDataModal.childCount;
  subscription: Subscription[] = [];
  sumInsuredDetails: SumInsuredDetail[] = [];
  cityData = [];
  premiumDetails: PremiumDetailsData;
  stateDetails = this.maxProtectDataModal.stateDetails;
  zoneMappedWithPincode: string = '';
  invalidPinCodeMessage: string;
  stateFetched: boolean = false;
  adult2DateError: boolean = false;
  multipleServiceError: boolean = false;
  loadFormContent: boolean = false;
  tenureDuration: number = 0;
  errorPopupData: PopupModal;
  channelData: ChannelData;
  quoteDetails: ConvertSavedQuotesResponsePayload;
  quoteConvertData: SavedQuote;
  isConvertedQuote = false;
  reviseZone = [];
  saveQuoteCounter = 0;
  steps: StepData[] = [];

  constructor(private fb: FormBuilder,
    public utilityService: UtilityService,
    private healthAdvantedgeService: HealthAdvantedgeService,
    private healthMasterService: HealthMasterService,
    private maxProtectService: MaxProtectService,
    private rtoListService: RTOListService,
    private snackBar: MatSnackBar,
    private popupService: PopupService,
    private healthDataStorageService: HealthDataStorageService,
    private renderer: Renderer2,
    private customStepperService: CustomStepperService,
    private customFooterService: CustomFooterService,
    private sessionStorageService: SessionStorageService,
    private el: ElementRef,
    private storageService: StorageService,
    private agentService: AgentService_A) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    // Get Max Protect form data structure
    this.onLoadSteps();
    this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
  }

  onLoadSteps() {
    this.premiumDetails = this.healthDataStorageService.initializePremiumData()
    this.maxProtectFormData = this.healthDataStorageService.getHealthDataStorage();
    this.quoteDetails = this.storageService.getAESDecryptedData(userEnums.ConverToProposal);
    if (this.quoteDetails) {
      this.isConvertedQuote = true;
      this.quoteConvertData = this.quoteDetails.Data;
      this.updateSavedQuotesConvertData();
      if (this.maxProtectFormData.quoteDataSet) {
        if (this.maxProtectFormData.quoteFormData.subProductCode == '1132' || this.maxProtectFormData.quoteFormData.subProductCode == '1498') {
          this.toggleAddons = true;
        }
        else {
          this.toggleAddons = false;
        }
      }
      this.customFooterService.showPremiumDetails(true);
    }
    if (this.maxProtectFormData.quoteDataSet) {
      this.patchingDone = false;
      // DISPLAYING ADDITIONAL COVERS BASED ON THE SELECTED PLAN
      if (this.isConvertedQuote == false) {
        if (this.maxProtectFormData.quoteFormData.subProductCode == this.maxProtectFormData.quoteFormData.SubProdDetails[0].Code) {
          this.toggleAddons = true;
        }
        else {
          this.toggleAddons = false;
        }
      }
    }
    this.createMaxProtectForm();
    this.fetchMaxProtectDetails();
    if (!this.maxProtectFormData.quoteDataSet && this.maxProtectFormData.quickquoteDataSet) {
      this.patchQuickQuoteData();
    }
  }

  setAdult(adult) {
    const dob = 'dobAdult' + `${adult.memberCount}`;
    const relationship = 'adultRealtionship' + `${adult.memberCount}`;
    const vaccineStatus = 'adultVaccinationStatus' + `${adult.memberCount}`;
    const vaccineDate = 'adultVaccinationDate' + `${adult.memberCount}`;
    let newAdult = {
      [dob]: adult.dob,
      [relationship]: adult.relationship,
      [vaccineStatus]: adult.vaccineStatus,
      [vaccineDate]: adult.vaccineDate
    };

    return newAdult;
  }

  setChild(child) {
    const dob = 'dobChild' + `${child.memberCount}`;
    const relationship = 'childRelationship' + `${child.memberCount}`;

    let newChild = {
      [dob]: child.dob,
      [relationship]: child.relationship
    };

    return newChild;
  }

  setAddonProperly(Quotesummary) {
    let len = Quotesummary.length;
    if (len == 0) {
      return {};
    } else if (len == 1) {
      // CLASSIC OR PREMIUM
      let addon = Quotesummary[0];
      return {
        [addon.coverText]: addon.isSelected
      }
    }
    else {
      // PAKKA CLASSIC
      return {
        [Quotesummary[0].coverText]: Quotesummary[0].isSelected,
        [Quotesummary[1].coverText]: Quotesummary[1].isSelected
      }
    }
  }

  updateSavedQuotesConvertData() {
    this.maxProtectFormData.quoteDataSet = true;
    let quoteFormData = this.maxProtectFormData.quoteFormData;
    let addons = this.setAddonProperly(this.quoteConvertData.Quotesummary);
    quoteFormData.addons = addons;
    quoteFormData.productName = this.quoteConvertData.ProductName;
    quoteFormData.productType = this.quoteConvertData.ProductType;
    quoteFormData.subProductType = this.quoteConvertData.SubProductType;
    quoteFormData.subProductCode = this.quoteConvertData.SubProductCode;
    quoteFormData.zone = this.quoteConvertData.Zone;
    quoteFormData.reviseZone = this.quoteConvertData.RevisedZone;
    quoteFormData.TotalPremium = +this.quoteConvertData.TotalPremium;
    quoteFormData.BasicPremium = +this.quoteConvertData.BasicPremium;
    quoteFormData.TotalTax = +this.quoteConvertData.TotalTax;
    quoteFormData.pincode = this.quoteConvertData.Pincode;
    quoteFormData.SumInsured = +this.quoteConvertData.Members[0].SumInsured;
    quoteFormData.totalMemberData = this.quoteConvertData.Members;
    quoteFormData.adultDetails = this.quoteConvertData.AdultDetails.map(adult => {
      adult = this.setAdult(adult);
      return adult;
    });
    quoteFormData.childDetails = this.quoteConvertData.ChildDetails.map(child => {
      child = this.setChild(child);
      return child;
    });
    // quoteFormData.cityId = this.quoteConvertData.City;
    quoteFormData.cityName = this.quoteConvertData.City;
    // quoteFormData.stateId = this.stateDetails.stateId;
    quoteFormData.policyRange = this.quoteConvertData.PolicyRange;
    quoteFormData.policyTenure = this.tenureCheck(this.quoteConvertData.Tenure);
    quoteFormData.applicantAnnualSum = this.quoteConvertData.Members[0].SumInsured;
    quoteFormData.cityState = this.quoteConvertData.CityState;
    quoteFormData.corelationId = this.quoteConvertData.CustomerDetails.CorelationId;
    quoteFormData.PlanName = this.quoteConvertData.PlanName;
    quoteFormData.PlanCode = this.quoteConvertData.PlanCode;
    quoteFormData.copaymentPercentTaken = '0';
    quoteFormData.healthProposalProductType = "CHI";
    quoteFormData.SubProdDetails = this.pType;
    quoteFormData.zoneUpgrade = this.quoteConvertData.ZoneUpgrade;
    quoteFormData.zoneUpgradeToggle = this.quoteConvertData.ZoneUpgrade == true ? healthToggle.Yes : healthToggle.No;
    quoteFormData.reviseZoneCheck = this.quoteConvertData.ZoneUpgrade == true ? this.quoteConvertData.RevisedZone : '';
    quoteFormData.reviseZone = this.quoteConvertData.ZoneUpgrade == true ? this.quoteConvertData.RevisedZone : this.quoteConvertData.Zone;
    quoteFormData.subProductType = this.maxProtectDataModal.subProductType;
    this.maxProtectFormData.premiumFormData.PfQuoteId = this.quoteConvertData.GenericData.pfQuoteId;
    quoteFormData.stateId = this.quoteConvertData.CustomerDetails.StateCode.toString();
    quoteFormData.cityId = this.quoteConvertData.CustomerDetails.CityCode.toString();
    quoteFormData.copaymentPercentTaken = this.quoteConvertData.VoluntaryCopaymentPercentage;
    quoteFormData.adultRelationShips = this.quoteConvertData.AdultRelationShips;
    quoteFormData.childRelationShips = this.quoteConvertData.ChildRelationShips;
    this.healthDataStorageService.setHealthDataStorage(this.maxProtectFormData);
  }

  tenureCheck(tenure) {
    if (tenure == 1) {
      return this.maxProtectDataModal.tenurePeriod[0];
    }
    else if (tenure == 2) {
      return this.maxProtectDataModal.tenurePeriod[1];
    }
    else if (tenure == 3) {
      return this.maxProtectDataModal.tenurePeriod[2];
    }
    return tenure;
  }

  onZoneUpgrade() {
    this.maxProtectForm.controls['revisedZone'].patchValue('');
    if (this.maxProtectForm.controls['zoneUpgrade'].value == 'No') {
      this.maxProtectForm.controls['zoneUpgrade'].patchValue('No');
      this.maxProtectForm.controls['revisedZone'].clearValidators();
      this.maxProtectForm.controls['revisedZone'].updateValueAndValidity();
    } else {
      this.maxProtectForm.controls['zoneUpgrade'].patchValue('Yes');
      this.maxProtectForm.controls['revisedZone'].setValidators(Validators.required);
      this.maxProtectForm.controls['revisedZone'].updateValueAndValidity();
    }
  }

  setZoneData() {
    this.reviseZone = [];
    if (!this.maxProtectFormData.quoteDataSet) {
      this.maxProtectForm.controls['zoneUpgrade'].setValue(healthToggle.No);
    }
    if (this.zoneMappedWithPincode == MPZone.Zone2) {
      this.reviseZone.push(MPZone.Zone1);
    }
    else if (this.zoneMappedWithPincode == MPZone.Zone3) {
      this.reviseZone.push(MPZone.Zone1);
      this.reviseZone.push(MPZone.Zone2);
    } else {
      this.reviseZone = [];
    }
  }

  // Form construction
  createMaxProtectForm() {
    // Patch annual sum insured if quote data is already present
    this.maxProtectForm = this.fb.group({
      productName: [healthProducts.maxProtect],
      planTypeMP: [this.planType, Validators.required],
      productType: [this.maxProtectFormData.quoteDataSet ? this.maxProtectFormData.quoteFormData.productType : this.maxProtectDataModal.productType[0]],
      pincode: [this.maxProtectFormData.quoteFormData.pincode, [Validators.required, Validators.pattern(patterns.pincode), Validators.minLength(6)]],
      cityState: ['', Validators.required],
      adultDetails: this.fb.array([]),
      childDetails: this.fb.array([]),
      policyTenure: [this.maxProtectFormData.quoteDataSet ? this.maxProtectFormData.quoteFormData.policyTenure : this.maxProtectDataModal.tenurePeriod[0], Validators.required],
      applicantAnnualSum: [this.maxProtectFormData.quoteDataSet ? this.maxProtectFormData.quoteFormData.applicantAnnualSum : '10000000', [Validators.required]],
      zoneUpgrade: [this.maxProtectFormData.quoteDataSet && this.maxProtectFormData.quoteFormData.zoneUpgrade ? 'Yes' : 'No'],
      revisedZone: [this.maxProtectFormData.quoteDataSet ? this.maxProtectFormData.quoteFormData.reviseZone : ''],
      conditionWaitingPeriod: [healthYears.TwoYear],
      softCopyDiscount: ['Yes'],
      copaymentPercentTaken: ["0"],
      addonsClassic: this.fb.array([]),
      addonsPremium: this.fb.array([]),
    })
    // Creating adult and child controls and patching existing data if present
    this.patchExistingMemberData();

    this.maxProtectForm.valueChanges.subscribe((formValue) => {
      if (this.patchingDone) {
        // premium data set flag is used beacsuse user can come back and try to change the values.
        // In that case also we need to reset the stepper

        // And if user refreshes that means existing value is getting patched. In that case we are using the patchingDone flag
        // and resetting the premium footer
        if (JSON.stringify(this.maxProtectForm.value) != JSON.stringify(this.initialFormState)) {
          this.showPremiumDetails(false);
          // Here we are resetting the stepper. Just to be sure if the quote is changed.
          // User needs to traverse through each steps
          if (this.quoteDetails) {
            // Below block is written to identify value changes due to save quote
            if (this.saveQuoteCounter == 0) {
              this.customStepperService.saveQuoteDataChanges(true);
              this.sessionStorageService.remove(userEnums.ConverToProposal);
              this.saveQuoteCounter++;
              this.quoteDetails = null;
            }
          }
          this.customStepperService.resetStepper(true);
        }
      }
    });
  }

  get zoneUpgradeGroup() {
    return this.maxProtectForm.get('zoneUpgradeGroup') as FormGroup;
  }

  get zoneUpgradeToggle() {
    return this.maxProtectForm.get('zoneUpgradeGroup.zoneUpgradeToggle');
  }

  get zoneUpgradeDropdown() {
    return this.maxProtectForm.get('zoneUpgradeGroup.zoneUpgradeDropDown');
  }

  showPremiumDetails(data: boolean) {
    this.customFooterService.showPremiumDetails(data);
  }

  patchExistingMemberData() {
    if (this.maxProtectFormData.quoteDataSet) {
      if (this.maxProtectFormData.quoteFormData.adultDetails.length > 0) {
        this.maxProtectFormData.quoteFormData.adultDetails.forEach((data, index) => {
          this.adultDetails.push(this.createAdultDetails(index));
          // Patch values of adults
          // Below block is written because when there is self data that get's patched from kyc is not in date format
          // So to handle it we need to convert in into date type
          for (let adultKeys in data) {
            if (adultKeys.includes('dob')) {
              let dobValue = !data[adultKeys].includes('T') ? new Date(data[adultKeys]) : data[adultKeys]
              this.adultDetails.at(index).get(adultKeys).setValue(dobValue);
            }
            else {
              this.adultDetails.at(index).get(adultKeys).setValue(data[adultKeys]);
            }
          }
        })

        // We need to highlight the selected adult also
        let selectedAdultIndex = this.maxProtectFormData.quoteFormData.adultDetails.length - 1;
        this.adultCountData.forEach((adultCount, index) => {
          if (index == selectedAdultIndex) {
            adultCount.selected = true;
          }
          else {
            adultCount.selected = false;
          }
        })
      }
      if (this.maxProtectFormData.quoteFormData.childDetails.length > 0) {
        this.maxProtectFormData.quoteFormData.childDetails.forEach((data, index) => {
          this.childDetails.push(this.createChildDetails(index));
          // Patch values of adults
          for (let childKeys in data) {
            if (childKeys.includes('dob')) {
              let dobValue = !data[childKeys].includes('T') ? new Date(data[childKeys]) : data[childKeys]
              this.childDetails.at(index).get(childKeys).setValue(dobValue);
            }
            else {
              this.childDetails.at(index).get(childKeys).setValue(data[childKeys]);
            }

          }
        })

        // We need to highlight the selected child also
        // If user has selected only one child then other child needs to hide that would be in case of 0 adults
        let selectedChildIndex = this.maxProtectFormData.quoteFormData.childDetails.length - 1;
        this.childCountData.forEach((childCount, index) => {
          if (this.maxProtectFormData.quoteFormData.adultDetails.length == 0) {
            // Disabling adult selected
            this.adultCountData[0].selected = false;
            this.adultCountData[1].selected = false;
            if (index == 0) {
              childCount.selected = true;
              childCount.hide = false;
            }
            else {
              childCount.selected = false;
              childCount.hide = true;
            }
          }
          else {
            if (index == selectedChildIndex) {
              childCount.selected = true;
              childCount.hide = false;
            }
            else {
              childCount.selected = false;
            }
          }
        })
      }
      // IPAA-176: Patch totalMemeber object for insured page Convert API
      if (this.quoteConvertData && (this.adultDetails.length > 0 || this.childDetails.length > 0)) {
        this.maxProtectFormData.quoteFormData.totalMemberData = this.getMemberRequest();
        this.maxProtectFormData.quoteFormData.totalMemberData[0]['AddonCoverDetails'] = this.quoteDetails.Data.Members[0].AddOnCoverDetails;
        this.healthDataStorageService.setHealthDataStorage(this.maxProtectFormData);
      }
    }
    else if (this.maxProtectFormData.quickquoteDataSet) {
      this.getadultchilddataforquickquote();
    }
    else {
      this.adultDetails.push(this.createAdultDetails(0));
    }
  }

  get adultDetails(): FormArray {
    return this.maxProtectForm.get('adultDetails') as FormArray;
  }

  get childDetails(): FormArray {
    return this.maxProtectForm.get('childDetails') as FormArray;
  }

  createAdultDetails(index?): FormGroup {
    return new FormGroup({
      ['dobAdult' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['adultRealtionship' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['adultVaccinationStatus' + `${index + 1}`]: new FormControl(healthToggle.No, [Validators.required]),
      ['adultVaccinationDate' + `${index + 1}`]: new FormControl(''),
      ['Gender' + `${index + 1}`]: new FormControl('')
    })
  }

  createChildDetails(index?): FormGroup {
    return new FormGroup({
      ['dobChild' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['childRelationship' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['Gender' + `${index + 1}`]: new FormControl('')
    })
  }

  createAddons(control, defaultValue): FormGroup {
    return new FormGroup({
      [`${control}`]: new FormControl(defaultValue)
    })
  }

  // On load service calls
  fetchMaxProtectDetails() {

    // If below solution does not work try calling api individually
    this.subscription.push(this.requestDataFromMultipleSources().subscribe({
      next: (responseList: [MaxProtectResponsePayload, RelationshipResponsePayload]) => {
        this.multipleServiceError = false;
        this.handleMultipleResponse(responseList)

      },
      error: error => {
        this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
        this.loadFormContent = false;
        this.multipleServiceError = true;
      }
    })
    )
  }

  handleMultipleResponse(responseList: [MaxProtectResponsePayload, RelationshipResponsePayload]) {
    if (responseList[0].StatusCode != Constants.statusCode_success || responseList[1].StatusCode != Constants.statusCode_success) {
      this.multipleServiceError = true;
    }
    else {
      this.multipleServiceError = false;
      this.manageMaxProtectMasterData(responseList[0]);
      this.manageHealthRelationShipsData(responseList[1]);
    }
  }

  requestDataFromMultipleSources(): Observable<[MaxProtectResponsePayload, RelationshipResponsePayload]> {
    let proposalData: RelationshipRequestPayload = { product: Constants.healthProposalProductCodeMP };
    let agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);

    if (agentDetails.UserID == undefined || agentDetails.UserID == null) {
      this.agentService.logoutRedirectToLogin();
    }

    let masterDataRequest = {
      "SubType": this.maxProtectDataModal.subProductType.toString(),
      "UserID": agentDetails.UserID
    };
    return this.maxProtectService.getMaxProtectMasterData(masterDataRequest).pipe(
      // concatMap operator to sequentially call getHealthAdvantagePlusMasterData
      concatMap(healthResponse => {
        // forkJoin to concurrently call the other two methods
        // The responses from these three requests are combined into an array and emitted as a single observable
        return forkJoin([
          // of(healthResponse) within the forkJoin to include the result of the first API call in the final array of responses. 
          of(healthResponse),
          this.healthMasterService.getHealthProposalRelationships(proposalData)
        ]);
      })
    );
  }

  togglePlans(plan: string) {
    if (plan.includes('C')) {
      this.toggleAddons = true;
      for (let i = 0; i < this.premiumAddons.length; i++) {
        const find = this.addonPremiumArray.at(i);
        find.get(this.premiumAddons[i]).setValue(false);
        this.tempAddons = {};
      }
    }
    else {
      this.toggleAddons = false;
      for (let i = 0; i < this.classicAddons.length; i++) {
        const find = this.addonClassicArray.at(i);
        if (i == 1) {
          find.get(this.classicAddons[i]).setValue(true);
        } else {
          find.get(this.classicAddons[i]).setValue(false);
        }
        this.tempAddons = {};
      }
    }
  }

  // HANDLES MAX PROTECT MASTER DATA
  manageMaxProtectMasterData(maxProtectResponse: MaxProtectResponsePayload) {
    if (maxProtectResponse.StatusCode == Constants.statusCode_success) {
      this.multipleServiceError = false;
      this.maxProtectMasterData = maxProtectResponse;
      this.pType = this.maxProtectMasterData.Data.SubProdDetails;
      this.maxProtectFormData.quoteFormData.SubProdDetails = this.maxProtectMasterData.Data.SubProdDetails;
      this.healthDataStorageService.setHealthDataStorage(this.maxProtectFormData);

      // HANDLING SELECTED PLAN TYPE
      if (!this.maxProtectFormData.quoteDataSet) {
        this.planType = this.pType[0].Name;
      }
      else {
        if (!this.isConvertedQuote) {
          if (this.maxProtectFormData.quoteFormData.subProductCode == this.maxProtectFormData.quoteFormData.SubProdDetails[0].Code) {
            this.planType = this.maxProtectFormData.quoteFormData.SubProdDetails[0].Name;
          }
          else {
            this.planType = this.maxProtectFormData.quoteFormData.SubProdDetails[1].Name;
          }
        } else {
          if (this.quoteConvertData.SubProductCode == '1132' || this.quoteConvertData.SubProductCode == '1498') {
            this.planType = maxProtectResponse.Data.SubProdDetails[0].Name;
          } else {
            this.planType = maxProtectResponse.Data.SubProdDetails[1].Name;
          }
        }
      }
      let plans = this.maxProtectMasterData.Data.SumInsuredDetails;
      this.sumInsuredDetails = plans;

      const copay = this.maxProtectMasterData.Data.CoPay;
      if (copay != null) {
        this.copayArray = copay.split(',').map(Number);
      }

      if (this.maxProtectFormData.quoteDataSet) {
        this.maxProtectForm.controls['applicantAnnualSum'].setValue(this.maxProtectFormData.quoteFormData.applicantAnnualSum);
        this.fetchDataAsPerPincode(null, this.maxProtectFormData.quoteFormData.pincode);

        // HANDLING SELECTED ADDONS ON RETURNING TO QUOTE PAGE
        // FOR CLASSIC ADDONS
        if (this.maxProtectFormData.quoteFormData.subProductCode == this.maxProtectFormData.quoteFormData.SubProdDetails[0].Code) {
          if (this.maxProtectFormData.quoteFormData.addons['Waiting Period']) {
            this.addonClassicArray.push(
              this.fb.group({
                ['Waiting Period']: [true],
              })
            )
            this.classicAddons.push('Waiting Period');
          } else {
            this.addonClassicArray.push(
              this.fb.group({
                ['Waiting Period']: [false],
              })
            )
            this.classicAddons.push('Waiting Period');
          }
          if (this.maxProtectFormData.quoteFormData.addons['Room Upgrade']) {
            this.addonClassicArray.push(
              this.fb.group({
                ['Room Upgrade']: [true],
              })
            )
            this.classicAddons.push('Room Upgrade');
          } else {
            this.addonClassicArray.push(
              this.fb.group({
                ['Room Upgrade']: [false],
              })
            )
            this.classicAddons.push('Room Upgrade');
          }
          this.addonPremiumArray.push(
            this.fb.group({
              ['Waiting Period']: [false],
            })
          )
          this.premiumAddons.push('Waiting Period');
        }
        // FOR PREMIUM ADDONS
        else {
          if (this.maxProtectFormData.quoteFormData.addons['Waiting Period']) {
            this.addonPremiumArray.push(
              this.fb.group({
                ['Waiting Period']: [true],
              })
            )
            this.premiumAddons.push('Waiting Period');
          } else {
            this.addonPremiumArray.push(
              this.fb.group({
                ['Waiting Period']: [false],
              })
            )
            this.premiumAddons.push('Waiting Period');
          }
          this.addonClassicArray.push(
            this.fb.group({
              ['Waiting Period']: [false],
            })
          )
          this.classicAddons.push('Waiting Period');
          this.addonClassicArray.push(
            this.fb.group({
              ['Room Upgrade']: [false],
            })
          )
          this.classicAddons.push('Room Upgrade');
        }
      }
      else {
        for (let i = 0; i < this.maxProtectMasterData.Data.AddonDetails.length; i++) {
          for (let j = 0; j < this.maxProtectMasterData.Data.AddonDetails[i].ApplicableSubProductList.length; j++) {
            if (this.maxProtectMasterData.Data.AddonDetails[i].ApplicableSubProductList[j].includes(this.pType[0].Code)) {
              const coverText = this.maxProtectMasterData.Data.AddonDetails[i].CoverText;
              this.classicAddons.push(coverText);
              if (coverText == 'Room Upgrade') {
                this.addonClassicArray.push(
                  this.fb.group({
                    [coverText]: [true],
                  })
                )
              } else {
                this.addonClassicArray.push(
                  this.fb.group({
                    [coverText]: [false],
                  })
                )
              }
            }
            else {
              const coverText = this.maxProtectMasterData.Data.AddonDetails[i].CoverText;
              this.premiumAddons.push(coverText);
              this.addonPremiumArray.push(
                this.fb.group({
                  [coverText]: [false],
                })
              )
            }
          }
        }
      }

      this.maxProtectFormData.insuredFormData.befitCovers = maxProtectResponse.Data.befitCovers;
      this.maxProtectFormData.quoteFormData.productType = maxProtectResponse.Data.ProductCode;
      this.maxProtectFormData.insuredFormData.BefitApplicableCities = maxProtectResponse.Data.BefitApplicableCities;
      this.maxProtectFormData.quoteFormData.addonList = this.maxProtectMasterData.Data.AddonDetails;
      this.healthDataStorageService.setHealthDataStorage(this.maxProtectFormData);
    }
  }

  get addonPremiumArray(): FormArray {
    return (<FormArray>this.maxProtectForm.get('addonsPremium'));
  }

  get addonClassicArray(): FormArray {
    return (<FormArray>this.maxProtectForm.get('addonsClassic'));
  }

  // Handles Member Relationships
  manageHealthRelationShipsData(response: RelationshipResponsePayload) {
    let adultRelations = [];
    let childRelations = [];
    if (response.StatusCode == Constants.statusCode_success) {
      this.multipleServiceError = false;
      if (response.Data.InsuredRelationship.length > 0) {
        adultRelations = response.Data.InsuredRelationship.filter(x => x.KidAdultType == healthMembers.Adult);
        childRelations = response.Data.InsuredRelationship.filter(x => x.KidAdultType == healthMembers.Child);

        this.adultRelationShips = adultRelations.length > 0 ? adultRelations.sort(function (a, b) {
          return a.RelationshipName.localeCompare(b.RelationshipName)
        }) : [];
        this.childRelationShips = childRelations.length > 0 ? childRelations.sort(function (a, b) {
          return a.RelationshipName.localeCompare(b.RelationshipName)
        }) : [];
        this.loadFormContent = true;
      }
      else {
        this.adultRelationShips = [];
        this.childRelationShips = [];
        this.loadFormContent = false;
        this.multipleServiceError = true;
      }
    }
  }

  hasError(controlName: string, errorCode: string): boolean {
    return !this.maxProtectForm.valid && this.maxProtectForm.hasError(errorCode, [controlName]);
  }

  // Fetch statename according to pincode
  fetchDataAsPerPincode(event, existingPincode) {
    if (existingPincode == null) {
      this.maxProtectFormData.quoteDataSet = false;
      this.reviseZone = [];
    }

    this.stateFetched = false;
    this.invalidPinCodeMessage = '';
    this.cityData = [];
    if ((existingPincode != null ? existingPincode : event.target.value.length == 6) && !this.maxProtectForm.controls['pincode'].hasError('pattern')) {
      let data: RTOListRequestPayload = {
        "Pincode": existingPincode != null ? existingPincode.toString() : event.target.value,
        "CityID": ""
      }
      this.invalidPinCodeMessage = '';
      this.maxProtectForm.controls['cityState'].reset();
      this.subscription.push(this.rtoListService.getStatesCityByPin(data).subscribe({
        next: (response: RTOListResponsePayload) => {
          if (response.StatusCode == Constants.statusCode_success) {
            if (response.Data.length > 0) {
              this.stateDetails.stateId = response.Data[0].StateId ? response.Data[0].StateId : '';
              this.stateDetails.stateName = response.Data[0].StateName ? response.Data[0].StateName : '';
              this.stateDetails.pincode = existingPincode != null ? existingPincode : event.target.value;
              response.Data.forEach(cityList => {
                this.cityData.push({ 'cityLabel': cityList['CityName'], 'stateLabel': this.stateDetails.stateName, 'value': cityList['CityID'] });
              });
            }
            if (this.cityData.length == 1) {
              this.stateDetails.cityName = this.cityData[0].cityLabel;
              this.stateDetails.cityId = this.cityData[0].value;
              this.maxProtectForm.controls['cityState'].setValue(this.stateDetails.stateName + ' - ' + this.stateDetails.cityName);
            }
            // IPAA-176: Patch City and State for Convert API
            if (this.quoteDetails && this.cityData.length > 0) {
              this.stateDetails.cityName = this.cityData[0].cityLabel;
              this.stateDetails.cityId = this.cityData[0].value;
              this.maxProtectForm.controls['cityState'].setValue(this.stateDetails.stateName + ' - ' + this.stateDetails.cityName);
              this.maxProtectFormData.quoteFormData.cityId = this.stateDetails.cityId;
            }
            this.getZoneData(false);

            // Need to check below in backward patching
            if (this.maxProtectFormData.quoteDataSet) {
              this.zoneMappedWithPincode = this.maxProtectFormData.quoteFormData.zone;
              this.maxProtectForm.controls['cityState'].setValue(this.maxProtectFormData.quoteFormData.cityState);
              this.changeCityName(this.maxProtectFormData.quoteFormData.cityId, false);
            }
            this.stateFetched = true;
            this.setZoneData();
            if (this.maxProtectFormData.quoteDataSet && !this.patchingDone) {
              this.patchingDone = true;
            }
          }

          else if (response.StatusCode == Constants.statusCode_failure) {
            this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
            this.stateFetched = false;
            this.invalidPinCodeMessage = response.StatusDesc;
          }

          else {
            this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
            // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
            // this.popupService.openGenericPopup(this.errorPopupData);
          }
        },
        error: error => {
          this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          this.invalidPinCodeMessage = '';
          this.stateFetched = false;
          // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
          // this.popupService.openGenericPopup(this.errorPopupData);
        }
      })
      )
    }
    this.utilityService.numberOnly(event);
    this.utilityService.restrictSpace(event);
  }

  getZoneData(selectionChange: boolean) {
    let params: ZoneRequestPayload = {
      "productcode": this.maxProtectMasterData.Data.ProductCode,
      "State": this.stateDetails.stateName,
      "City": this.maxProtectFormData.quoteDataSet ? this.maxProtectFormData.quoteFormData.cityName : this.stateDetails.cityName
    }
    this.subscription.push(this.healthAdvantedgeService.getZoneFromCityData(params).subscribe({
      next: (response: ZoneResponsePayload) => {
        if (response.StatusCode === Constants.statusCode_success) {
          let zoneData = response.Data.zone;
          this.zoneMappedWithPincode = zoneData;
          this.setZoneData();
        }
        else {
          this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          this.zoneMappedWithPincode = '';
          // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
          // this.popupService.openGenericPopup(this.errorPopupData);
        }
      },
      error: error => {
        this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
        // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
        // this.popupService.openGenericPopup(this.errorPopupData);
      }
    }))
  }

  changeCityName(cityValue: string, showSpinner: boolean) {
    this.stateDetails.cityId = cityValue;
    this.stateDetails.cityName = this.cityData.length > 0 ? (this.cityData.find(data => data.value == cityValue).cityLabel) : '';
    if (showSpinner) {
      this.getZoneData(showSpinner);
    }
  }

  // Adult count selected
  adultCountSelected(adultValue: string, event) {
    // adult count entire logic is set on adult data present in Max Protect modal
    // toggle is handled by selected sttribute for each object
    let index = parseInt(adultValue) - 1;
    let selectedChild = this.fetchSelectedChild();
    switch (adultValue) {

      // If adult 1 is selected then we need to set selected attribute true
      case '1': {
        if (!this.adultCountData[index].selected) {
          this.adultCountData[index].selected = true;
          this.adultCountData[index + 1].selected = false;

          // Also enable second child because we have a condition for 0A 1C only should be there
          // SO if it's hidden unhide it
          if (this.childCountData[1].hide) {
            this.childCountData[1].hide = false;
            this.childCountData[1].selected = false;
          }

          // If two adults present in form array remove second adult as only 1A needed
          if (this.adultDetails.length > 1) {
            this.adult2DateError = false;
            this.adultDetails.removeAt(this.adultDetails.length - 1);
          }

          // If no adults presents in form array as again 1A is selected then we need to push in array
          // Push one adult in form array
          else if (this.adultDetails.length == 0) {
            this.adult2DateError = false;
            this.adultDetails.push(this.createAdultDetails(0));
          }

          // If one adult is selected then make third child hidden. SO for 1A 2C can be selected and also unselect them
          this.childCountData[2].hide = true;
          this.childCountData[2].selected = false;

          // if 3C is presnt in form array then remove third child from form array
          if (this.childDetails.length == 3) {
            // If third child was selected then we need to remove all child details from form array
            if (selectedChild[0].value == '3') {
              this.childDetails.clear();
            }
            else {
              this.childDetails.removeAt(this.childDetails.length - 1);
            }
          }
        }
        // But if user tries to click again on adult 1 and if it is already selected that means if selected is true
        // then stop checkbox behaviour
        else {
          // Now if he tries to uncheck adult. We need to allow uncheck only when any of the child is selcted otherwise we won't allow
          // Uncheck to be allowed only when child is present
          if (selectedChild.length == 0) {
            event.preventDefault();
            event.stopPropagation();
            this.snackBar.open('Atleast one member is required', 'Ok', {
              duration: 2000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
            });
          }
          // If user tries to uncheck 1A but he has selected 1c/2C. Then we can't allow uncheck
          else if (selectedChild.length > 0 && selectedChild[0].value == '1') {
            event.preventDefault();
            event.stopPropagation();
            this.snackBar.open('Atleast one adult is required', 'Ok', {
              duration: 2000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
            });
          }
          // If user tries to uncheck 1A but he has selected 1C/2C. Then we can't allow uncheck
          else if (selectedChild.length > 0 && selectedChild[0].value == '2') {
            event.preventDefault();
            event.stopPropagation();
            this.snackBar.open('Atleast one adult is required', 'Ok', {
              duration: 2000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
            });
          }
          // If uncheck can happen set the value of that adult as false.
          else {
            // Remove all adult details
            this.adultDetails.clear();
            this.adultCountData[index].selected = false;
            // If no adult is selected then only 1 child should be visible
            this.childCountData.forEach((childData, index) => {
              childData.hide = index == 0 ? false : true;
              childData.selected = index == 0 ? true : false;
            })

            // Remove child elements if present
            if (this.childDetails.length == 2) {
              this.childDetails.removeAt(this.childDetails.length - 1);
            }

          }
        }
        break;
      }

      // If adult 2 is selected then we need to set selected attribute true
      case '2': {
        if (!this.adultCountData[index].selected) {
          this.adultCountData[index].selected = true;
          this.adultCountData[index - 1].selected = false;

          // Push one adult in form array if one adult is already present
          if (this.adultDetails.length == 1) {
            this.adult2DateError = false;
            this.adultDetails.push(this.createAdultDetails(1));
          }

          // If no adult is present then push two adults
          else if (this.adultDetails.length == 0) {
            this.adult2DateError = false;
            this.adultDetails.push(this.createAdultDetails(0));
            this.adultDetails.push(this.createAdultDetails(1));
          }

          // If two adult is selected then make one child visible. SO for 2A 3C can be selected and also unselect them
          this.childCountData[2].selected = false;
          this.childCountData[2].hide = false;

          // If first child is hidden unhide it
          if (this.childCountData[1].hide) {
            this.childCountData[1].hide = false;
            this.childCountData[1].selected = false;
          }
        }
        // But if user tries to click again on adult 2 and if it is already selected that means if selected is true
        // then stop checkbox behaviour
        else {
          // Now if he tries to uncheck adult. We need to allow uncheck only when any of the child is selcted otherwise we won't allow
          // Uncheck to be allowed only when child is present
          if (selectedChild.length == 0) {
            event.preventDefault();
            event.stopPropagation();
            this.snackBar.open('Atleast one member is required', 'Ok', {
              duration: 2000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
            });
          }
          // If user tries to uncheck 1A but he has selected 1C/2C. Then we can't allow uncheck
          else if (selectedChild.length > 0 && selectedChild[0].value == '1') {
            event.preventDefault();
            event.stopPropagation();
            this.snackBar.open('Atleast one adult is required', 'Ok', {
              duration: 2000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
            });
          }
          // If user tries to uncheck 2A but he has selected 2C. Then we can't allow uncheck
          else if (selectedChild.length > 0 && selectedChild[0].value == '2') {
            event.preventDefault();
            event.stopPropagation();
            this.snackBar.open('Atleast one adult is required', 'Ok', {
              duration: 2000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
            });
          }
          // If user tries to uncheck 2A but he has selected 3C. Then we can't allow uncheck
          else if (selectedChild.length > 0 && selectedChild[0].value == '3') {
            event.preventDefault();
            event.stopPropagation();
            this.snackBar.open('3C can be selected on 2A only. To uncheck Please select 1A', 'Ok', {
              duration: 2000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
            });
          }
          else if (selectedChild.length > 0) {
            // Remove all adult details
            this.adultDetails.clear();
            this.adultCountData[index].selected = false;
            // If no adult is selected then only 1 child should be visible
            this.childCountData.forEach((childData, index) => {
              childData.selected = index == 0 ? true : false;
              childData.hide = index == 0 ? false : true;
            })
            // Remove child elements if present
            if (this.childDetails.length == 2) {
              this.childDetails.removeAt(this.childDetails.length - 1);
            }
          }
        }
        break;
      }
      default : {
        break;
      }
    }
  }
  // Child count selected
  childCountSelected(childValue: string, event) {
    // child count entire logic is set on child data present in Max Protect modal
    // toggle is handled by selected attribute for each object
    let index = parseInt(childValue) - 1;
    let selectedAdult = this.fetchSelectedAdult();
    switch (childValue) {
      // If child 1 is selected then we need to set selected attribute true
      case '1': {
        if (!this.childCountData[index].selected) {
          this.childCountData[index].selected = true;

          // As it is check box and 1C is selected we need to ensure other 2C & 3C is unchecked
          this.childCountData[index + 1].selected = false;
          this.childCountData[index + 2].selected = false;

          // If 0C is present push one data in child array
          if (this.childDetails.length == 0) {
            this.childDetails.push(this.createChildDetails(0));
          }

          // If 2C is present in child details form array remove second child
          else if (this.childDetails.length == 2) {
            this.childDetails.removeAt(this.childDetails.length - 1);
          }

          // If 3C is present then remove last two child
          else if (this.childDetails.length == 3) {
            this.childDetails.removeAt(this.childDetails.length - 1);
            this.childDetails.removeAt(this.childDetails.length - 1);
          }

        }

        // And if no adult is selected then child can't be unchecked otherwise uncheck can happen
        else if (selectedAdult.length == 0) {
          event.preventDefault();
          event.stopPropagation();
          this.snackBar.open('Atleast one member is required', 'Ok', {
            duration: 2000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
          });
        }
        // if it can be unchecked set value as false of that unchecked child
        else if (selectedAdult.length > 0) {
          this.childCountData[index].selected = false;
          // clear child details
          this.childDetails.clear();
        }
        break;
      }

      // If child 2 is selected then we need to set selected attribute true
      case '2': {
        if (!this.childCountData[index].selected) {
          this.childCountData[index].selected = true;
          // As it is check box and 2C is selected we need to ensure other 1C & 3C is unchecked
          this.childCountData[index - 1].selected = false;
          this.childCountData[index + 1].selected = false;

          // If 0C is present push two data in child array
          if (this.childDetails.length == 0) {
            this.childDetails.push(this.createChildDetails(0));
            this.childDetails.push(this.createChildDetails(1));
          }

          // If 1C is present push one data in child array
          else if (this.childDetails.length == 1) {
            this.childDetails.push(this.createChildDetails(1));
          }

          // If 3C is present in child details form array remove third child
          else if (this.childDetails.length == 3) {
            this.childDetails.removeAt(this.childDetails.length - 1);
          }
        }

        // And if no adult is selected then child can't be unchecked otherwise uncheck can happen
        else if (selectedAdult.length == 0) {
          event.preventDefault();
          event.stopPropagation();
          this.snackBar.open('Atleast one member is required', 'Ok', {
            duration: 2000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
          });
        }
        // if it can be unchecked set value as false of that unchecked child
        else if (selectedAdult.length > 0) {
          this.childCountData[index].selected = false;
          // clear child details
          this.childDetails.clear();
        }
        break;
      }

      // If child 3 is selected then we need to set selected attribute true
      case '3': {
        if (!this.childCountData[index].selected) {
          this.childCountData[index].selected = true;
          // As it is check box and 3C is selected we need to ensure other 1C & 2C is unchecked
          this.childCountData[index - 2].selected = false;
          this.childCountData[index - 1].selected = false;

          // If 0C is present then push 3 child data in form array
          if (this.childDetails.length == 0) {
            this.childDetails.push(this.createChildDetails(0));
            this.childDetails.push(this.createChildDetails(1));
            this.childDetails.push(this.createChildDetails(2));
          }
          // If 1C is present push two data in child array
          else if (this.childDetails.length == 1) {
            this.childDetails.push(this.createChildDetails(1));
            this.childDetails.push(this.createChildDetails(2));
          }
          // If 2C is present push one data in child array
          else if (this.childDetails.length == 2) {
            this.childDetails.push(this.createChildDetails(2));
          }
        }
        // But if user tries to click again on child 3 and if it is already selected that means if selected is true
        // then stop checkbox behaviour
        //In this case 2A is required and if it is checked it can't be unchecked
        // else {
        //   event.preventDefault();
        //   event.stopPropagation();
        //   this.snackBar.open('3C can be selected on 2A only. To uncheck Please select 1A', 'Ok', {
        //     duration: 2000,
        //     verticalPosition: 'bottom',
        //     horizontalPosition: 'center',
        //   });
        // }
        break;
      }
      default : {
        break;
      }
    }
  }

  fetchSelectedAdult() {
    return this.adultCountData.filter(element => element.selected);
  }

  fetchSelectedChild() {
    return this.childCountData.filter(element => element.selected);
  }

  adultsDateComparison() {
    if (this.adultDetails.length == 2) {
      if (this.adultDetails.value[1]['dobAdult2'] != '' && this.utilityService.dateDiff(this.adultDetails.value[1]['dobAdult2'], this.adultDetails.value[0]['dobAdult1'])) {
        this.adult2DateError = true;
      }
      else {
        this.adult2DateError = false;
      }
    }
    else {
      this.adult2DateError = false;
    }

  }

  getInsuredMinMaxDate(memberType: string, minMaxType: string) {
    if (memberType == 'Adult') {
      let adultMaxDate = this.utilityService.subtractYears(new Date(), 21);

      let yearsdate = this.utilityService.subtractYears(new Date(), 65);
      let monthdate = this.utilityService.subtractMonths(yearsdate, 11);
      let adultMinDate = this.utilityService.subtractDays(monthdate, 25);

      return minMaxType == 'min' ? adultMinDate : adultMaxDate;
    }
    else {
      let noOfAdults = this.adultDetails.length;
      if (noOfAdults > 0) {
        //depedent child
        let depChildMaxDate = this.utilityService.subtractDays(new Date(), 91);

        let yearDate = this.utilityService.subtractYears(new Date(), 20);
        let monthDate = this.utilityService.subtractMonths(yearDate, 11);
        let depChildMinDate = this.utilityService.subtractDays(monthDate, 25);

        return minMaxType == 'min' ? depChildMinDate : depChildMaxDate;
      }
      else {
        //independent child
        let indChildMaxDate = this.utilityService.subtractYears(new Date(), 6);

        let yearDate = this.utilityService.subtractYears(new Date(), 25);
        let monthDate = this.utilityService.subtractMonths(yearDate, 11);
        let indChildMinDate = this.utilityService.subtractDays(monthDate, 29);

        return minMaxType == 'min' ? indChildMinDate : indChildMaxDate;
      }
    }
  }

  createQuoteSummaryForm() {
    let addonsData: any = this.maxProtectFormData.quoteFormData.addonList;
    let request = this.maxProtectFormData;

    const addonList = [];
    const addonDetails = request?.quoteFormData?.addons;

    for (let i = 0; i < addonsData.length; i++) {
      if (addonsData[i].ApplicableSubProductList[0] == this.maxProtectFormData.quoteFormData.subProductCode)
        addonList.push({ isSelected: addonDetails[addonsData[i].CoverText], [addonsData[i].CoverId]: addonDetails[addonsData[i].CoverText], coverCode: addonsData[i].CoverId, isAddonMemberBasis: false, coverText: addonsData[i].CoverText });
    }
    return addonList;
  }

  getQuote(event: EmitButtonData) {
    let objstepperCheckData: StepperCheckDataQuote = new StepperCheckDataQuote();
    this.formSubmitted = true;
    this.maxProtectForm.markAllAsTouched();

    //self case validation for more than 1 adult/child
    if (this.adultDetails.length > 0 || this.childDetails.length > 0) {
      let adultRelationshipArrCount = 0;
      let childRelationshipArrCount = 0;
      if (this.adultDetails.value.length > 0) {
        this.adultDetails.value.map((ele, index) => {
          if (ele[`adultRealtionship${index + 1}`] == 'SELF') {

            adultRelationshipArrCount++;
          }
        })
      }

      if (this.childDetails.value.length > 0) {
        this.childDetails.value.map((ele, index) => {
          if (ele[`childRelationship${index + 1}`] == 'SELF') {
            childRelationshipArrCount++;
          }
        })
      }

      if ((adultRelationshipArrCount > 1 || childRelationshipArrCount > 1) || (adultRelationshipArrCount > 0 && childRelationshipArrCount > 0)) {
        this.errorPopupData = this.popupService.fetchErrorPopupMsg('SELF can be selected only for one Insured');
        this.popupService.openGenericPopup(this.errorPopupData);
        return;
      }
    }

    // If child details length is only 1 . we need to check dob present in child is valid or not
    if (this.childDetails.length == 1 && this.adultDetails.length == 0) {
      // Read Dob value if it's invalid then reset
      if (this.childDetails.at(0).get('dobChild1').status == 'INVALID') {
        this.childDetails.at(0).get('dobChild1').reset();
        this.childDetails.at(0).get('dobChild1').setValue('');
        this.childDetails.at(0).get('dobChild1').setValidators(Validators.required);
        this.childDetails.at(0).get('dobChild1').updateValueAndValidity();
      }
    }
    if (!this.maxProtectForm.valid || this.adult2DateError || this.invalidPinCodeMessage !== '') {
      for (const key of Object.keys(this.maxProtectForm.controls)) {
        if (this.maxProtectForm.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('#' + key + '.ng-invalid');
          invalidControl.scrollIntoView({ behaviour: "instant", block: "center", inline: "center" });
          invalidControl.focus();
          break;
        }
      }
      this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
    }

    else if (this.invalidPinCodeMessage == '') {
      this.formSubmitted = false;
      let request: SaveEditQuoteV1Request = this.constructRequest();
      this.maxProtectFormData.quoteFormData.subProductType = +request.SubProductCode;
      this.maxProtectFormData.quoteFormData.subProductCode = this.maxProtectForm.value.planTypeMP.includes('Classic') ? this.pType[0].Code.toString() : this.pType[1].Code.toString();
      this.healthDataStorageService.setHealthDataStorage(this.maxProtectFormData);
      this.subscription.push(this.maxProtectService.saveEditQuoteData(request).subscribe({
        next: response => {
          if (response.StatusCode == Constants.statusCode_success) {
            if (response.Data.HealthDetails.CorrelationId) {
              this.maxProtectFormData.quoteFormData.corelationId = response.Data.HealthDetails.CorrelationId;
              this.healthDataStorageService.setHealthDataStorage(this.maxProtectFormData);
            }
            if (response?.Data.HealthDetails.TotalPremium == '0') {
              this.errorPopupData = this.popupService.fetchErrorPopupMsg('Error occurred in calculating Premium. Please try again.');
              this.popupService.openGenericPopup(this.errorPopupData);
              this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
            }
            else {
              objstepperCheckData.isSuccess = true;
              this.setHapFormData(response?.Data);
              this.patchingDone = true;
              this.initialFormState = this.maxProtectForm.value;
              // If premium exists then only allow user to proceed forward
              if (!this.utilityService.isEmptyOrNull(this.maxProtectFormData.premiumFormData.totalPremium) && this.maxProtectFormData.premiumFormData.totalPremium != '0') {
                this.customStepperService.emitStepData({ status: true, buttonId: 'f1' });
              }
              else {
                this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
              }
              this.showPremiumDetails(true);
            }
          }
          else if (response.StatusCode != Constants.statusCode_success) {
            // this.errorPopupData = this.popupService.fetchErrorPopupMsg(response.StatusDesc);
            // this.popupService.openGenericPopup(this.errorPopupData);
            this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          }

        },
        error: (err) => {
          this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
          // this.popupService.openGenericPopup(this.errorPopupData);
        }
      }))
    }
  }

  constructRequest(): SaveEditQuoteV1Request {
    let tenure = (this.maxProtectForm.value.policyTenure).split(' ')[0];
    this.tenureDuration = parseInt(tenure);
    let vaccinationtaken = this.adultDetails.value.filter((vaccinationStatus, i) =>
      vaccinationStatus['adultVaccinationStatus' + (i + 1)] == 'Yes'
    )
    let adult1DOB = this.adultDetails.value.length > 0 ? this.utilityService.formatDate(this.adultDetails.value[0]['dobAdult1']) : '';
    let child1DOB = this.childDetails.value.length > 0 ? this.utilityService.formatDate(this.childDetails.value[0]['dobChild1']) : '';
    let adult1Age = this.adultDetails.value.length > 0 ? this.utilityService.calculateAge(this.adultDetails.value[0]['dobAdult1']) : '';
    let child1Age = this.childDetails.value.length > 0 ? this.utilityService.calculateAge(this.childDetails.value[0]['dobChild1']) : '';
    let requestData: SaveEditQuoteV1Request = {
      "ProductType": this.maxProtectForm.value.productType == 'New' ? 'N' : 'R',
      "SubProductType": this.maxProtectDataModal.subProductType,
      "SubProductCode": this.maxProtectForm.value.planTypeMP.includes('Classic') ? this.pType[0].Code.toString() : this.pType[1].Code.toString(),
      "UserType": this.utilityService.isEmptyOrNull(this.channelData.UT) ? "AGENT" : this.channelData.UT,
      "IpAddress": this.utilityService.isEmptyOrNull(this.channelData.CIP) ? this.maxProtectDataModal.ipartnerMsite : this.channelData.CIP,
      "NoOfAdults": (this.adultDetails.length).toString(),
      "NoOfKids": (this.childDetails.length).toString(),
      "AgeGroup": null,
      "SubLimit": null,
      // "YearPremium": 1,
      "Tenure": this.tenureDuration,
      "IsJammuKashmir": this.checkIsGSTRegistered(),
      "VolDedutible": this.maxProtectDataModal.volDedutible,
      "GSTStateCode": this.stateDetails.stateId,
      "SumInsured": this.maxProtectForm.value.applicantAnnualSum,
      "GSTStateName": this.stateDetails.stateName ? this.stateDetails.stateName : '',
      "isGSTRegistered": this.checkIsGSTRegistered(),
      "Members": this.getMemberRequest(),
      "SaveQuote": true,  // let's send it true in premium we are setting true
      "CalculateHigherSI": false,
      // "IsEmployeeProduct": false,
      "EldestMemberAge": this.adultDetails.value.length == 0 ? (child1Age).toString() : (adult1Age).toString(),
      "DobOfEldestMember": this.adultDetails.value.length == 0 ? child1DOB : adult1DOB,
      // "health_product_type": false,
      "IsOtherLoadingDiscount": ((this.adultDetails.value.length !== 0) && (this.adultDetails.value.length == vaccinationtaken.length)) ? true : false,
      "Pincode": (this.maxProtectForm.value.pincode).toString(),
      "City": this.stateDetails.cityName,
      "OtherLoadingDiscountPercentage": "",
      "VoluntaryCopaymentPercentage": this.maxProtectForm.value.copaymentPercentTaken == 'No' ? null : this.maxProtectForm.value.copaymentPercentTaken,
      "CityCode": this.stateDetails.cityId,
      "Zone": this.zoneMappedWithPincode,
      "ZoneUpgrade": this.maxProtectForm.controls['zoneUpgrade'].value == "Yes" ? true : false,
      "RevisedZone": this.maxProtectForm.controls['zoneUpgrade'].value == "Yes" ? this.maxProtectForm.controls['revisedZone'].value : this.zoneMappedWithPincode,
      "IsCHIRefile": false,
      "InstallmentApplicable": "No",
      "InstallmentFrequency": ""
    }

    Object.assign(requestData);
    return requestData;
  }

  // Quote request formation 
  checkIsGSTRegistered() {
    return this.stateDetails.stateId != this.maxProtectDataModal.stateId ? false : true;
  }

  getMemberRequest() {
    let members = [];
    let addonBlock = [];

    if (this.maxProtectForm.value.planTypeMP.includes('Classic')) {
      for (let i = 0; i < this.classicAddons.length; i++) {
        const find = this.addonClassicArray.at(i);
        this.tempAddons[this.classicAddons[i]] = false;
        if (find.get(this.classicAddons[i]).value) {
          const len = this.maxProtectMasterData.Data.AddonDetails
          for (let j = 0; j < len.length; j++) {
            if (len[j].CoverText == this.classicAddons[i] && (len[j].CoverId == 64 || len[j].CoverId == 65)) {
              len[j].IsAddonSelected = true;
              len[j].IsVisible = true;
              len[j].IsForFemaleAdult = true;
              len[j].maxAgeAllowed = 65;
              this.tempAddons[this.classicAddons[i]] = true;
              addonBlock.push(len[j])
            }
          }
        }
      }
    } else {
      for (let i = 0; i < this.premiumAddons.length; i++) {
        const find = this.addonPremiumArray.at(i);
        this.tempAddons[this.premiumAddons[i]] = false;
        if (find.get(this.premiumAddons[i]).value) {
          const len = this.maxProtectMasterData.Data.AddonDetails
          for (let j = 0; j < len.length; j++) {
            if (len[j].CoverText == this.premiumAddons[i] && len[j].CoverId == 66) {
              len[j].IsAddonSelected = true;
              len[j].IsVisible = true;
              len[j].IsForFemaleAdult = true;
              len[j].maxAgeAllowed = 65;
              this.tempAddons[this.premiumAddons[i]] = true;
              addonBlock.push(len[j])
            }
          }
        }
      }
    }

    if (this.adultDetails.length > 0) {
      this.adultDetails.value.forEach((element, i) => {
        this.adultDetails.value[i]['Gender' + `${i + 1}`] = this.utilityService.setGender(element['adultRealtionship' + (i + 1)], this.adultRelationShips);
        members.push(
          {
            DOB: this.utilityService.formatDate(element['dobAdult' + (i + 1)]),
            MemberType: healthMembers.Adult,
            RelationshipwithApplicant: element['adultRealtionship' + (i + 1)],
            VaccinationDate: this.utilityService.formatDate(element['adultVaccinationDate' + (i + 1)]),
            AddonCoverDetails: addonBlock,
            Gender: this.utilityService.setGender(element['adultRealtionship' + (i + 1)], this.adultRelationShips)
          }
        )
      });
    }
    if (this.childDetails.length > 0) {
      this.childDetails.value.forEach((element, j) => {
        this.childDetails.value[j]['Gender' + `${j + 1}`] = this.utilityService.setGender(element['childRelationship' + (j + 1)], this.childRelationShips);
        members.push(
          {
            DOB: this.utilityService.formatDate(element['dobChild' + (j + 1)]),
            MemberType: healthMembers.Child,
            RelationshipwithApplicant: element['childRelationship' + (j + 1)],
            AddonCoverDetails: addonBlock,
            Gender: this.utilityService.setGender(element['childRelationship' + (j + 1)], this.childRelationShips)
          }
        )
      });
    }

    return members;
  }

  setHapFormData(premiumData: HealthDetailsData) {
    let resetFlag: boolean = this.checkMemberDetails();
    this.maxProtectFormData.quoteFormData.productName = this.maxProtectForm.controls['productName'].value;
    this.maxProtectFormData.quoteFormData.productType = this.maxProtectForm.controls['productType'].value;
    this.maxProtectFormData.quoteFormData.pincode = this.maxProtectForm.controls['pincode'].value;
    this.maxProtectFormData.quoteFormData.SumInsured = this.maxProtectForm.controls['applicantAnnualSum'].value;
    this.maxProtectFormData.quoteFormData.adultDetails = this.adultDetails.value;
    this.maxProtectFormData.quoteFormData.childDetails = this.childDetails.value;
    this.maxProtectFormData.quoteFormData.cityId = this.stateDetails.cityId;
    this.maxProtectFormData.quoteFormData.cityName = this.stateDetails.cityName;
    this.maxProtectFormData.quoteFormData.stateId = this.stateDetails.stateId;
    this.maxProtectFormData.quoteFormData.totalMemberData = this.getMemberRequest();
    this.maxProtectFormData.quoteFormData.policyRange = (this.utilityService.easyAmountReader(this.maxProtectForm.controls['applicantAnnualSum'].value)).replace('₹ ', '');
    this.maxProtectFormData.quoteFormData.policyTenure = this.maxProtectForm.controls['policyTenure'].value;
    this.maxProtectFormData.quoteFormData.applicantAnnualSum = this.maxProtectForm.controls['applicantAnnualSum'].value;
    this.maxProtectFormData.quoteFormData.conditionWaitingPeriod = this.maxProtectForm.controls['conditionWaitingPeriod'].value;
    this.maxProtectFormData.quoteFormData.cityState = this.maxProtectForm.controls['cityState'].value;
    this.maxProtectFormData.quoteFormData.softCopyDiscount = this.maxProtectForm.controls['softCopyDiscount'].value;
    this.maxProtectFormData.quoteFormData.copaymentPercentTaken = this.maxProtectForm.controls['copaymentPercentTaken'].value;
    this.maxProtectFormData.quoteFormData.underWritterApproved = premiumData.HealthDetails.Policystatus == "CONDITIONALAPPROVE" ? 'false' : 'true';
    this.maxProtectFormData.quoteFormData.zone = this.zoneMappedWithPincode;
    this.maxProtectFormData.quoteFormData.adultRelationShips = this.adultRelationShips;
    this.maxProtectFormData.quoteFormData.childRelationShips = this.childRelationShips;
    this.maxProtectFormData.quoteFormData.addons = this.tempAddons;
    this.maxProtectFormData.quoteFormData.TotalPremium = +premiumData.HealthDetails.TotalPremium;
    this.maxProtectFormData.quoteFormData.BasicPremium = +premiumData.HealthDetails.BasicPremium;
    this.maxProtectFormData.quoteFormData.TotalTax = +premiumData.HealthDetails.TotalTax;
    this.maxProtectFormData.quoteFormData.corelationId = premiumData.HealthDetails.CorrelationId;
    this.maxProtectFormData.quoteFormData.PlanName = premiumData.HealthDetails.PlanName;
    this.maxProtectFormData.quoteFormData.PlanCode = premiumData.HealthDetails.PlanCode;
    this.maxProtectFormData.quoteFormData.subProductType = 34;
    this.maxProtectFormData.quoteFormData.healthProposalProductType = "CHI";
    this.maxProtectFormData.quoteFormData.SubProdDetails = this.pType;
    this.maxProtectFormData.quoteFormData.zoneUpgrade = this.maxProtectForm.controls['zoneUpgrade'].value == "Yes" ? true : false;
    this.maxProtectFormData.quoteFormData.reviseZone = this.maxProtectForm.controls['zoneUpgrade'].value == "Yes" ? this.maxProtectForm.controls['revisedZone'].value : this.zoneMappedWithPincode;

    //reset data for quickquote
    this.maxProtectFormData.quickquoteData.pincode = '';
    this.maxProtectFormData.quickquoteData.cityState = '';
    this.maxProtectFormData.quickquoteData.adultDetails = [];
    this.maxProtectFormData.quickquoteData.childDetails = [];
    this.maxProtectFormData.quickquoteData.policyTenure = '';
    this.maxProtectFormData.quickquoteData.applicantAnnualSum = '';
    this.maxProtectFormData.quoteDataSet = true;
    this.maxProtectFormData.quoteFormData.quoteSummary = this.createQuoteSummaryForm()
    if (resetFlag) {
      this.setOtherFormFlags();
    }
    this.constructPremiumData(premiumData);
    this.healthDataStorageService.setHealthDataStorage(this.maxProtectFormData);
  }

  checkMemberDetails(): boolean {
    let nonEqualMembers = [];
    let resetFlag: boolean = false;
    // Adult Details
    // If new adult details and child details length are same then we need to check the info
    if (this.adultDetails.length == this.maxProtectFormData.quoteFormData.adultDetails.length) {
      let existingAdultDetails = this.maxProtectFormData.quoteFormData.adultDetails;
      existingAdultDetails.forEach((adultData, index) => {
        let tempAdultDetails = adultData;
        // When there is self detected there is change in format of dob.
        if (this.maxProtectFormData.kycStatus.ovdSelf || this.maxProtectFormData.kycStatus.normalSelf) {
          // convert dob in respected format
          for (let keys in tempAdultDetails) {
            // There are chances date is patched along with orignal time stamp
            if (keys.includes('dob') && !tempAdultDetails[keys].includes('T')) {
              tempAdultDetails[keys] = (new Date(tempAdultDetails[keys]));
            }
          }
        }
        // Looping through keys
        if (JSON.stringify(tempAdultDetails) != JSON.stringify(this.adultDetails.value[index])) {
          nonEqualMembers.push(index);
        }
      });
    }
    // If the length is only not equal that means adult data is been modified
    else if (this.adultDetails.length != this.maxProtectFormData.quoteFormData.adultDetails.length) {
      nonEqualMembers.push(0);
    }

    // Child Details

    if (this.childDetails.length == this.maxProtectFormData.quoteFormData.childDetails.length) {
      let existingChildDetails = this.maxProtectFormData.quoteFormData.childDetails;
      existingChildDetails.forEach((childData, index) => {
        // Looping through keys
        if (JSON.stringify(childData) != JSON.stringify(this.childDetails.value[index])) {
          nonEqualMembers.push(index);
        }
      });
    }

    // If the length is only not equal that means adult data is been modified
    else if (this.childDetails.length != this.maxProtectFormData.quoteFormData.childDetails.length) {
      nonEqualMembers.push(0);
    }

    else {
      // Adult members found to be equal
      nonEqualMembers = [];
    }

    // If there is mismatch of data in terms of length or details
    if (nonEqualMembers.length > 0) {
      resetFlag = true;
    }
    else {
      resetFlag = false;
    }
    return resetFlag;
  }

  setOtherFormFlags() {
    this.maxProtectFormData.insuredDataSet = false;
    this.maxProtectFormData.kycDataSet = false;
    this.maxProtectFormData.kycStatus.ekycMandatory = false;
    this.maxProtectFormData.kycStatus.normal = false;
    this.maxProtectFormData.kycStatus.normalSelf = false;
    this.maxProtectFormData.kycStatus.ovd = false;
    this.maxProtectFormData.kycStatus.ovdSelf = false;
    this.maxProtectFormData.kycStatus.isKYCDoneSuccessfully = false;
    this.maxProtectFormData.kycStatus.idType = false;
    this.maxProtectFormData.kycStatus.certNumber = '';
    this.maxProtectFormData.kycStatus.isPilotUser = false;
    this.maxProtectFormData.kycFormData = this.healthDataStorageService.resetKYCdata();
  }

  constructPremiumData(response: HealthDetailsData) {
    this.maxProtectFormData.premiumFormData.totalPremium = response.HealthDetails.TotalPremium;
    this.maxProtectFormData.premiumFormData.basicPremium = response.HealthDetails.BasicPremium;
    this.maxProtectFormData.premiumFormData.totalTax = response.HealthDetails.TotalTax;
    this.maxProtectFormData.premiumFormData.tenure = this.tenureDuration;
    this.maxProtectFormData.premiumFormData.QuoteId = response.HealthDetails.QuoteId;
    this.maxProtectFormData.premiumFormData.SubProductCode = response.HealthDetails.SubProductCode;
    this.maxProtectFormData.premiumFormData.PfQuoteId = response.HealthDetails.PFQuoteID;
    let zone = '';
    if (this.maxProtectForm.controls['zoneUpgrade'].value == 'Yes') {
      zone = this.maxProtectForm.controls['revisedZone'].value;
    } else {
      zone = this.zoneMappedWithPincode;
    }
    let b = zone == "Zone D" ? 'D' : '';
    let c = zone == "Zone C" ? 'C' : b;
    let c1 = zone == "Zone B" ? 'B' : c;
    this.maxProtectFormData.premiumFormData.zone = zone == "Zone A" ? 'A' : c1;
    this.maxProtectFormData.premiumDataSet = true;
  }

  getadultchilddataforquickquote() {
    if (this.maxProtectFormData.quickquoteData.adultDetails.length > 0) {
      this.maxProtectFormData.quickquoteData.adultDetails.forEach((data, index) => {
        this.adultDetails.push(this.createAdultDetails(index));
        // Patch values of adults
        // Below block is written because when there is self data that get's patched from kyc is not in date format
        // So to handle it we need to convert in into date type
        for (let adultKeys in data) {
          if (adultKeys.includes('dob')) {
            let dobValue = !data[adultKeys].includes('T') ? new Date(data[adultKeys]) : data[adultKeys]
            this.adultDetails.at(index).get(adultKeys).setValue(dobValue);
          }
          else {
            this.adultDetails.at(index).get(adultKeys).setValue(data[adultKeys]);
          }
        }
      })

      // We need to highlight the selected adult also
      let selectedAdultIndex = this.maxProtectFormData.quickquoteData.adultDetails.length - 1;
      this.adultCountData.forEach((adultCount, index) => {
        if (index == selectedAdultIndex) {
          adultCount.selected = true;
        }
        else {
          adultCount.selected = false;
        }
      })
    }
    if (this.maxProtectFormData.quickquoteData.childDetails.length > 0) {
      this.maxProtectFormData.quickquoteData.childDetails.forEach((data, index) => {
        this.childDetails.push(this.createChildDetails(index));
        // Patch values of adults
        for (let childKeys in data) {
          if (childKeys.includes('dob')) {
            let dobValue = !data[childKeys].includes('T') ? new Date(data[childKeys]) : data[childKeys]
            this.childDetails.at(index).get(childKeys).setValue(dobValue);
          }
          else {
            this.childDetails.at(index).get(childKeys).setValue(data[childKeys]);
          }

        }
      })

      // We need to highlight the selected child also
      // If user has selected only one child then other child needs to hide that would be in case of 0 adults
      let selectedChildIndex = this.maxProtectFormData.quickquoteData.childDetails.length - 1;
      this.childCountData.forEach((childCount, index) => {
        if (this.maxProtectFormData.quickquoteData.adultDetails.length == 0) {
          // Disabling adult selected
          this.adultCountData[0].selected = false;
          this.adultCountData[1].selected = false;
          if (index == 0) {
            childCount.selected = true;
            childCount.hide = false;
          }
          else {
            childCount.selected = false;
            childCount.hide = true;
          }
        }
        else {
          if (index == selectedChildIndex) {
            childCount.selected = true;
            childCount.hide = false;
          }
          else {
            childCount.selected = false;
          }
        }
      })
    }
  }

  patchQuickQuoteData() {
    this.healthDataStorageService.getHealthDataStorage();
    this.maxProtectForm.patchValue({
      pincode: this.maxProtectFormData.quickquoteData.pincode,
      cityState: this.maxProtectFormData.quickquoteData.cityState,
      // adultDetails: this.maxProtectFormData.quickquoteData.adultDetails,
      childDetails: this.maxProtectFormData.quickquoteData.childDetails,
      policyTenure: this.maxProtectFormData.quickquoteData.policyTenure ? this.maxProtectFormData.quickquoteData.policyTenure : this.maxProtectDataModal.tenurePeriod[0],
      applicantAnnualSum: this.maxProtectFormData.quickquoteData.applicantAnnualSum
    })
    this.fetchDataAsPerPincode('', this.maxProtectFormData.quickquoteData.pincode)
    this.stateFetched = true;
  }

  unsubscribeSubscription() {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

  onRefresh(status) {
    if (status) {
      this.fetchMaxProtectDetails();
    }
  }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  ngOnDestroy() {
    this.unsubscribeSubscription();
  }

}