import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/services/generic.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BookedBusinessReportService {

  constructor(private generic: GenericService) { }

  readonly baseURI = `${environment.baseURL}`;
  readonly bookedBusinessReport = 'Agent/SasBookedBusiness';//'Intranet/SasBookedBusiness';
  readonly getProducts = 'Master/GetAllBBRProducts';

  getBookedBusinessReport(data: any): Observable<any> {
    const endpoint = `${this.baseURI}${this.bookedBusinessReport}`;
    return this.generic.genericService<any>(endpoint, data);
  }
  getProductsForBBR():Observable<any>{
    const endpoint = `${this.baseURI}${this.getProducts}`;
    return this.generic.genericService<any>(endpoint);
  }
}
