<mat-tab-group class="tabInnerdiv marginbox" *ngIf=" tabGroup!=undefined"
    (selectedTabChange)="memberTypeSelected($event)">
    <mat-tab class="" *ngFor="let tabData of tabGroup">
        <ng-template mat-tab-label>
            {{tabData.tabLabel}}
            <div class="count" *ngIf="tabData.tabLabel=='Adult'">
                <span>{{this.getMembersLength('Adult')}}</span>
            </div>
            <div class="count" *ngIf="tabData.tabLabel=='Child'">
                <span>{{this.getMembersLength('Child')}}</span>
            </div>
        </ng-template>
        <!-- Creation of Adult & Child buttons -->
        <div class="fieldsdiv">
            <div class="row">
                <div class="col-sm-12 mt-3 form-group groupingbtn" *ngIf="tabData.memberDetails.length > 0">
                    <div class="togglefloat">
                        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="policyToggle_group">
                            <mat-button-toggle class="PolicyBtn"
                                (click)="insuredChanged(tabData.tabLabel,k,tab.value.insuredMemberName)"
                                *ngFor="let tab of tabData.memberDetails; let k = index"
                                [checked]="memberLabel == tab.value.insuredMemberName">
                                {{ tab.value.insuredMemberName}}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <!-- buttons -->
                    <button mat-raised-button class="secondarybtn_2 mr-1" type="button"
                        (click)="onclickAddMember(tabData.tabLabel)">
                        Add
                    </button>
                    <!-- <button mat-raised-button class="secondarybtn_2 mr-1" type="button"
                        (click)="onclickDeleteMember(memberLabel)">
                        Delete
                    </button> -->
                    <!-- end here -->
                </div>

            </div>
        </div>
        <div *ngIf="tabData.memberDetails.length > 0">
            <ng-container>
                <mat-divider></mat-divider>
                <!-- Details Of Members -->
                <form [formGroup]="insuredForm">
                    <div class="scrollRow">
                        <div class="fieldsdiv">
                            <div formArrayName='insuredDetails'>
                                <div *ngFor="let insuredData of getInsuredArray.controls;let i = index;"
                                    [formGroupName]="i">
                                    <div *ngIf="memberLabel == insuredData.value.insuredMemberName">
                                        <div class=" row form-group mb-0">
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                                <h4 class="insured">
                                                    <span>{{memberLabel}} Details</span>
                                                    <span class="float-right crossbtn"
                                                        (click)="onclickDeleteMember(memberLabel)">
                                                        <img src="../../../../../../assets/images/Renewal/delete.svg"
                                                            alt="">
                                                    </span>
                                                </h4>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 col-12">
                                                <mat-label class="input-group__label"
                                                    for="insuredRelationWithAppl">Relationship with
                                                    Applicant</mat-label>
                                                <mat-select class="input-group__input cstm_select"
                                                    *ngIf="insuredData.value.memberType == 'Adult'"
                                                    (click)="handleMatSelectClick()"
                                                    (blur)="removeMatSelectScrollListener()"
                                                    formControlName="insuredRelationWithAppl"
                                                    (selectionChange)="onChangeRelationship(insuredForm.controls['insuredDetails']['controls'][i].controls['memberType'].value,insuredData,i,$event.value, true)"
                                                    placeholder="Enter Relationship">
                                                    <ng-container
                                                        *ngFor="let relationData of insuredAdultRelationsList; let last = last">
                                                        <mat-option
                                                            value="{{relationData.RelationshipName}}">{{utilityService.capitalize(relationData.RelationshipName)}}</mat-option>
                                                        <mat-divider *ngIf="!last"></mat-divider>
                                                    </ng-container>
                                                </mat-select>

                                                <mat-select class="input-group__input cstm_select"
                                                    *ngIf="insuredData.value.memberType == 'Child'"
                                                    (click)="handleMatSelectClick()"
                                                    (blur)="removeMatSelectScrollListener()"
                                                    formControlName="insuredRelationWithAppl"
                                                    (selectionChange)="onChangeRelationship(insuredForm.controls['insuredDetails']['controls'][i].controls['memberType'].value,insuredData,i,$event.value, true)"
                                                    placeholder="Enter Relationship">
                                                    <ng-container
                                                        *ngFor="let relationData of insuredChildRelationsList; let last = last">
                                                        <mat-option
                                                            value="{{relationData.RelationshipName}}">{{utilityService.capitalize(relationData.RelationshipName)}}</mat-option>
                                                        <mat-divider *ngIf="!last"></mat-divider>
                                                    </ng-container>
                                                </mat-select>
                                                <mat-error
                                                    *ngIf="insuredForm.controls['insuredDetails']['controls'][i].controls['insuredRelationWithAppl'].hasError('required') && isSubmit">
                                                    Relationship is required.
                                                </mat-error>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 col-12"
                                                *ngIf="!this.utilityService.isEmptyOrNull(insuredForm.controls['insuredDetails']['controls'][i].controls['insuredRelationWithAppl'].value)">
                                                <mat-label class="input-group__label"
                                                    for="insuredTitle">Title</mat-label>
                                                <mat-select class="input-group__input cstm_select"
                                                    (click)="handleMatSelectClick()"
                                                    (blur)="removeMatSelectScrollListener()"
                                                    formControlName="insuredTitle"
                                                    (selectionChange)="onChangeInsuredTitle(insuredData)"
                                                    placeholder="Enter Title"
                                                    *ngIf="this.insuredForm.controls['insuredDetails']['controls'][i].controls['insuredGender'].value.toLowerCase() == 'fmale'">
                                                    <ng-container
                                                        *ngFor="let titleData of renewalModal.FemaleTitleArray let last = last">
                                                        <mat-option value={{titleData.id}} selected>{{titleData.val}}
                                                        </mat-option>
                                                        <mat-divider *ngIf="!last"></mat-divider>
                                                    </ng-container>
                                                </mat-select>
                                                <mat-select class="input-group__input cstm_select"
                                                    (click)="handleMatSelectClick()"
                                                    (blur)="removeMatSelectScrollListener()"
                                                    formControlName="insuredTitle"
                                                    (selectionChange)="onChangeInsuredTitle(insuredData)"
                                                    placeholder="Enter Title"
                                                    *ngIf="this.insuredForm.controls['insuredDetails']['controls'][i].controls['insuredGender'].value.toLowerCase() == 'male'">
                                                    <ng-container
                                                        *ngFor="let titleData of renewalModal.MaleTitleArray let last = last">
                                                        <mat-option value={{titleData.id}} selected>{{titleData.val}}
                                                        </mat-option>
                                                        <mat-divider *ngIf="!last"></mat-divider>
                                                    </ng-container>
                                                </mat-select>
                                                <mat-select class="input-group__input cstm_select"
                                                    (click)="handleMatSelectClick()"
                                                    (blur)="removeMatSelectScrollListener()"
                                                    formControlName="insuredTitle"
                                                    (selectionChange)="onChangeInsuredTitle(insuredData)"
                                                    placeholder="Enter Title"
                                                    *ngIf="this.insuredForm.controls['insuredDetails']['controls'][i].controls['insuredGender'].value.toLowerCase() == 'mf'">
                                                    <ng-container
                                                        *ngFor="let titleData of renewalModal.title , let last = last">
                                                        <mat-option value={{titleData.id}} selected>{{titleData.val}}
                                                        </mat-option>
                                                        <mat-divider *ngIf="!last"></mat-divider>
                                                    </ng-container>
                                                </mat-select>
                                                <mat-error
                                                    *ngIf="insuredForm.controls['insuredDetails']['controls'][i].controls['insuredTitle'].hasError('required') && isSubmit">
                                                    Title is required.
                                                </mat-error>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 col-12">
                                                <mat-label class="input-group__label" for="insuredName">Full
                                                    Name</mat-label>
                                                <input type="text" class="input-group__input" type="input" matInput
                                                    formControlName="insuredName" placeholder="Enter full name"
                                                    autocomplete="off" (keydown)="validationAlpha($event)">
                                                <mat-error
                                                    *ngIf="insuredForm.controls['insuredDetails']['controls'][i].controls['insuredName'].hasError('required') && isSubmit">
                                                    Name is required
                                                </mat-error>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 col-12"
                                                *ngIf="!this.utilityService.isEmptyOrNull(insuredForm.controls['insuredDetails']['controls'][i].controls['insuredRelationWithAppl'].value)">
                                                <label class="input-group__label" for="insuredDOB">DOB</label>
                                                <input class="input-group__input dob" matInput [matDatepicker]="picker1"
                                                    formControlName="insuredDOB"
                                                    (dateChange)="onChangeInsuredDob(i, true)"
                                                    [max]="this.minMaxDate.maxDate" [min]="this.minMaxDate.minDate"
                                                    placeholder="DD/MM/YYYY" (click)="picker1.open()" readonly>
                                                <mat-datepicker-toggle matIconSuffix
                                                    [for]="picker1"></mat-datepicker-toggle>
                                                <mat-datepicker #picker1 touchUi></mat-datepicker>

                                                <!-- Error for DatePicker -->
                                                <mat-error
                                                    *ngIf="insuredForm.controls['insuredDetails']['controls'][i].controls['insuredDOB'].hasError('required') && isSubmit">
                                                    Insured DOB is required.
                                                </mat-error>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 col-12">
                                                <mat-label class="input-group__label" for="insuredHeightInFeet">Height
                                                    in Feet</mat-label>
                                                <mat-select class="input-group__input cstm_select"
                                                    (click)="handleMatSelectClick()"
                                                    (blur)="removeMatSelectScrollListener()"
                                                    formControlName="insuredHeightInFeet"
                                                    placeholder="Enter height in Feet">
                                                    <ng-container
                                                        *ngFor="let feet of renewalModal.heightinFeet; let last = last">
                                                        <mat-option [value]="feet">{{feet}}</mat-option>
                                                        <mat-divider *ngIf="!last"></mat-divider>
                                                    </ng-container>
                                                </mat-select>
                                                <mat-error
                                                    *ngIf="insuredForm.controls['insuredDetails']['controls'][i].controls['insuredHeightInFeet'].hasError('required') && isSubmit">
                                                    Height in feet required.
                                                </mat-error>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 col-12">
                                                <mat-label class="input-group__label" for="insuredHeightInInches">Height
                                                    in Inches</mat-label>
                                                <mat-select class="input-group__input cstm_select"
                                                    (click)="handleMatSelectClick()"
                                                    (blur)="removeMatSelectScrollListener()"
                                                    formControlName="insuredHeightInInches"
                                                    placeholder="Enter height in Inch">
                                                    <ng-container
                                                        *ngFor="let inch of renewalModal.heightinInch; let last = last">
                                                        <mat-option [value]="inch">{{inch}}</mat-option>
                                                        <mat-divider *ngIf="!last"></mat-divider>
                                                    </ng-container>
                                                </mat-select>
                                                <mat-error
                                                    *ngIf="insuredForm.controls['insuredDetails']['controls'][i].controls['insuredHeightInInches'].hasError('required') && isSubmit">
                                                    Height in inches required.
                                                </mat-error>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 col-12">
                                                <mat-label class="input-group__label"
                                                    for="insuredWeight">Weight</mat-label>
                                                <input type="text" class="input-group__input" type="input" matInput
                                                    formControlName="insuredWeight" placeholder="Enter weight"
                                                    autocomplete="off" (input)="validationNumber($event)">
                                                <mat-error
                                                    *ngIf="insuredForm.controls['insuredDetails']['controls'][i].controls['insuredWeight'].hasError('required') && isSubmit">
                                                    Weight is required.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-12 form-group">
                                                <label class="singlelabel mb-2 mt-2" style="width: 100%;">Pre-Existing
                                                    Disease & Habits:</label>
                                                <div class="group_btn">
                                                    <mat-button-toggle-group name="fontStyle" aria-label="Font Style"
                                                        class="custom-toggle-group"
                                                        formControlName="isPreExistingDisease"
                                                        (change)="onclickIsPEDSelected($event.value,i, true)">
                                                        <mat-button-toggle id="ped_{{i}}_yes" class="custom_togglebtn"
                                                            [value]="true">
                                                            Yes</mat-button-toggle>
                                                        <mat-button-toggle [value]="false" class="custom_togglebtn">
                                                            No</mat-button-toggle>
                                                    </mat-button-toggle-group>
                                                </div>
                                            </div>

                                            <div
                                                *ngIf="insuredForm.controls['insuredDetails']['controls'][i].controls['isPreExistingDisease'].value && !insuredData.value.isExistingInsured">
                                                <!-- Pre existing disease -->
                                                <mat-divider></mat-divider>

                                                <h3 class="pedHeading ml-3">Medical &
                                                    lifestyle history of
                                                    {{insuredData.value.insuredMemberName}}
                                                </h3>
                                                <div class="fieldsdiv pedFieldDiv pr-0 pl-0 mb-3">
                                                    <div class="scrollRow" formArrayName="insuredPED">
                                                        <div *ngFor="let disease of getPEDDetailsFormArray(i).controls; let j = index"
                                                            [formGroupName]="j">
                                                            <div class="row innerBorder">
                                                                <div class="col-sm-12">
                                                                    <table class="diseaseTable">
                                                                        <caption class="hidden-caption">Details
                                                                        </caption>
                                                                        <th class="hidden-caption">PED</th>
                                                                        <td class="hidden-caption">PED Details</td>
                                                                        <tr>
                                                                            <td>
                                                                                <label for="" class="singlelabel"
                                                                                    style="min-width: 83%;">
                                                                                    {{disease.value.PEDName}}
                                                                                </label>
                                                                            </td>
                                                                            <td>
                                                                                <mat-checkbox class="cstmCheckbox"
                                                                                    id="{{'disease'+i+j}}-{{disease.value.PEDName}}-yes"
                                                                                    name="PEDSelected{{j}}"
                                                                                    formControlName="PEDSelected{{j}}"
                                                                                    (change)="onClickDisease(i,j,$event.checked)">
                                                                                </mat-checkbox>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Addon -->
                                            <mat-card class="borderinnerbox addons"
                                                *ngIf="this.renewalFormData.SubProductType != 22 && !hideAddon">
                                                <h3>Need Additional Covers</h3>
                                                <div class="fieldsdiv" formArrayName="insuredAddOn">
                                                    <div class="row" style="display: flex; align-items: center;"
                                                        *ngIf="this.renewalFormData.SubProductType==42 || this.renewalFormData.SubProductType==20 ">
                                                        <ng-container
                                                            *ngFor="let cover of getAddonDetailsFormArray(i).controls; let k = index"
                                                            [formGroupName]="k">
                                                            <ng-container
                                                                *ngIf="cover.value.IsVisible && ![76,77,78,79,80,81,82,83].includes(cover.value.CoverId)">
                                                                <!-- class="col-sm-4 customCheckslist" -->
                                                                <ng-container
                                                                    *ngIf="cover.value.CoverId != 39 && cover.value.CoverId != 71">
                                                                    <div
                                                                        [ngClass]="cover.value.CoverId == 34 || cover.value.CoverId == 68? 'col-sm-12 customCheckslist': 'col-sm-4 customCheckslist' ">
                                                                        <mat-divider
                                                                            *ngIf="cover.value.CoverId == 34 || cover.value.CoverId == 68"></mat-divider>
                                                                        <mat-checkbox class="cstmCheckbox"
                                                                            id="{{'cover'+i+k}}-{{cover.value.CoverText}}-yes"
                                                                            name="IsAddonSelected{{k}}"
                                                                            [checked]="cover.value['IsAddonSelected' + k]"
                                                                            formControlName="IsAddonSelected{{k}}"
                                                                            (change)="onClickSelectAddon(insuredData,k,cover,$event)">
                                                                            {{cover.value.CoverText}}
                                                                        </mat-checkbox>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="cover.value.CoverId == 39 || cover.value.CoverId == 71">
                                                                    <div class="col-12">
                                                                        <mat-divider></mat-divider>
                                                                        <div class="row mb-3">
                                                                            <div
                                                                                class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                                                <mat-checkbox class="cstmCheckbox"
                                                                                    id="{{'cover'+i+k}}-{{cover.value.CoverText}}-yes"
                                                                                    name="IsAddonSelected{{k}}"
                                                                                    [checked]="cover.value['IsAddonSelected' + k]"
                                                                                    formControlName="IsAddonSelected{{k}}"
                                                                                    (change)="onClickSelectAddon(insuredData,k,cover,$event)">
                                                                                    {{cover.value.CoverText}}
                                                                                </mat-checkbox>
                                                                            </div>
                                                                            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                                                                                *ngIf="(cover.value.CoverId == 39 || cover.value.CoverId == 71) && cover.value['IsAddonSelected' + k]">
                                                                                <mat-label class="input-group__label"
                                                                                    for="myInput">Occupation</mat-label>
                                                                                <mat-select
                                                                                    class="input-group__input cstm_select"
                                                                                    placeholder="Select Occupation"
                                                                                    (click)="handleMatSelectClick()"
                                                                                    (blur)="removeMatSelectScrollListener()"
                                                                                    formControlName="insuredOccupation"
                                                                                    [(value)]="cover.value.insuredOccupation"
                                                                                    (selectionChange)="onselectOccupationOption($event.value,insuredData,k)">
                                                                                    <ng-container
                                                                                        *ngIf="insuredData.value.memberType == 'Adult'">
                                                                                        <ng-container
                                                                                            *ngFor="let occ of occupationList; let last = last ">
                                                                                            <mat-option
                                                                                                [value]="occ.value">
                                                                                                {{occ.value}}
                                                                                            </mat-option>
                                                                                            <mat-divider
                                                                                                *ngIf="!last"></mat-divider>
                                                                                        </ng-container>
                                                                                    </ng-container>
                                                                                    <ng-container
                                                                                        *ngIf="insuredData.value.memberType == 'Child'">
                                                                                        <mat-option [value]="'Student'"
                                                                                            selected>
                                                                                            {{"Student"}}
                                                                                        </mat-option>
                                                                                    </ng-container>
                                                                                </mat-select>
                                                                                <mat-error class="error-margin"
                                                                                    *ngIf="insuredForm.controls['insuredDetails']['controls'][i].controls['insuredAddOn'].controls[k].controls['insuredOccupation'].hasError('required') && isSubmit">
                                                                                    Occupation is required
                                                                                </mat-error>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="!this.utilityService.isEmptyOrNull(this.befitAddon) && (this.befitAddon.length>0) && showBefit">
                                                            <!-- class="col-sm-4 customCheckslist" -->
                                                            <!-- <ng-container> -->
                                                            <div [ngClass]="'col-sm-4 customCheckslist' ">
                                                                <mat-select class="input-group__input cstm_select"
                                                                    placeholder="Select Befit Plan"
                                                                    (click)="handleMatSelectClick()"
                                                                    (blur)="removeMatSelectScrollListener()"
                                                                    [(value)]="insuredBefit"
                                                                    (selectionChange)="onBefitSelectAddon(insuredData,$event.value)">
                                                                    <ng-container
                                                                        *ngFor="let cover of befitAddon; let last = last">
                                                                        <mat-option [value]="cover.CoverText">
                                                                            {{ cover.CoverText }}
                                                                        </mat-option>
                                                                    </ng-container>
                                                                </mat-select>
                                                            </div>
                                                            <!-- </ng-container> -->
                                                        </ng-container>

                                                    </div>
                                                    <!-- asp start declaration -->
                                                    <div class="row" *ngIf="this.renewalFormData.SubProductType == 20">
                                                        <h3>Good Health Declaration:</h3>
                                                        <div class="fieldsdiv mb-4">
                                                            <div class="row">
                                                                <div class="col-sm-12">
                                                                    <td class="customCheckslist">
                                                                        <ul>
                                                                            <li>
                                                                                <mat-checkbox class="cstmCheckbox"
                                                                                    [checked]="true"
                                                                                    [disabled]="true"></mat-checkbox>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                    <td>
                                                                        <p>
                                                                            I hereby declare that there has been no
                                                                            change in my or any other insured's health
                                                                            condition covered under the policy from
                                                                            my/our first health insurance policy with
                                                                            ICICI Lombard.
                                                                        </p>
                                                                    </td>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- MAX addon -->
                                                    <div class="row" style="display: flex; align-items: center;"
                                                        *ngIf="this.renewalFormData.SubProductType==34 && !hideAddon">
                                                        <ng-container
                                                            *ngFor="let cover of getAddonDetailsFormArray(i).controls; let k = index"
                                                            [formGroupName]="k">
                                                            <ng-container>
                                                                <div class="col-sm-4 customCheckslist"
                                                                    *ngIf="cover.value.IsVisible">
                                                                    <mat-checkbox class="cstmCheckbox"
                                                                        id="{{'cover'+i+k}}-{{cover.value.CoverText}}-yes"
                                                                        name="IsAddonSelected{{k}}"
                                                                        [checked]="cover.value['IsAddonSelected' + k]"
                                                                        formControlName="IsAddonSelected{{k}}"
                                                                        (change)="onClickSelectAddon(insuredData,k,cover,$event)">
                                                                        {{cover.value.CoverText}}
                                                                    </mat-checkbox>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                    <!-- addon condition for hep -->
                                                    <div class="row" style="display: flex; align-items: center;"
                                                        *ngIf="this.renewalFormData.SubProductType==30||this.renewalFormData.SubProductType==31||this.renewalFormData.SubProductType==32||this.renewalFormData.SubProductType==33">
                                                        <ng-container
                                                            *ngFor="let cover of getAddonDetailsFormArray(i).controls; let k = index"
                                                            [formGroupName]="k">
                                                            <ng-container
                                                                *ngIf="cover.value.IsVisible && ![47,48,49,50,51,52,53,60].includes(cover.value.CoverId) && (cover.value.IsForFemaleAdult  && !cover.value.IsForAdult) && insuredForm.controls['insuredDetails']['controls'][i].controls['insuredTitle'].value !='Mr.'">
                                                                <!-- class=" col-sm-4 customCheckslist" -->
                                                                <ng-container>
                                                                    <div [ngClass]="'col-sm-4 customCheckslist' ">
                                                                        <mat-checkbox class="cstmCheckbox"
                                                                            id="{{'cover'+i+k}}-{{cover.value.CoverText}}-yes"
                                                                            name="IsAddonSelected{{k}}"
                                                                            [checked]="cover.value['IsAddonSelected' + k]"
                                                                            formControlName="IsAddonSelected{{k}}"
                                                                            (change)="onClickSelectAddon(insuredData,k,cover,$event)">
                                                                            {{cover.value.CoverText}}
                                                                        </mat-checkbox>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="cover.value.IsVisible && ![47,48,49,50,51,52,53,60].includes(cover.value.CoverId) && cover.value.IsForAdult && insuredData.value.memberType == 'Adult'">
                                                                <!-- class="col-sm-4 customCheckslist" -->
                                                                <ng-container>
                                                                    <div [ngClass]="'col-sm-4 customCheckslist' ">
                                                                        <mat-checkbox class="cstmCheckbox"
                                                                            id="{{'cover'+i+k}}-{{cover.value.CoverText}}-yes"
                                                                            name="IsAddonSelected{{k}}"
                                                                            [checked]="cover.value['IsAddonSelected' + k]"
                                                                            formControlName="IsAddonSelected{{k}}"
                                                                            (change)="onClickSelectAddon(insuredData,k,cover,$event)">
                                                                            {{cover.value.CoverText}}
                                                                        </mat-checkbox>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="cover.value.IsVisible && ![47,48,49,50,51,52,53,60].includes(cover.value.CoverId) && cover.value.IsForChild && insuredData.value.memberType == 'Child'">
                                                                <!-- class="col-sm-4 customCheckslist" -->
                                                                <ng-container>
                                                                    <div [ngClass]="'col-sm-4 customCheckslist' ">
                                                                        <mat-checkbox class="cstmCheckbox"
                                                                            id="{{'cover'+i+k}}-{{cover.value.CoverText}}-yes"
                                                                            name="IsAddonSelected{{k}}"
                                                                            [checked]="cover.value['IsAddonSelected' + k]"
                                                                            formControlName="IsAddonSelected{{k}}"
                                                                            (change)="onClickSelectAddon(insuredData,k,cover,$event)">
                                                                            {{cover.value.CoverText}}
                                                                        </mat-checkbox>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="cover.value.CoverId == 60 && cover.value.IsVisible && cover.value.IsForAdult && insuredData.value.memberType == 'Adult'">
                                                                <div class="col-12">
                                                                    <mat-divider></mat-divider>
                                                                    <div class="row mb-3">
                                                                        <div
                                                                            class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                                            <mat-checkbox class="cstmCheckbox"
                                                                                id="{{'cover'+i+k}}-{{cover.value.CoverText}}-yes"
                                                                                name="IsAddonSelected{{k}}"
                                                                                [checked]="cover.value['IsAddonSelected' + k]"
                                                                                formControlName="IsAddonSelected{{k}}"
                                                                                (change)="onClickSelectAddon(insuredData,k,cover,$event)">
                                                                                {{cover.value.CoverText}}
                                                                            </mat-checkbox>
                                                                        </div>
                                                                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                                                                            *ngIf="(cover.value.CoverId == 60) && cover.value['IsAddonSelected' + k]">
                                                                            <mat-label class="input-group__label"
                                                                                for="myInput">Occupation</mat-label>
                                                                            <mat-select
                                                                                class="input-group__input cstm_select"
                                                                                placeholder="Select Occupation"
                                                                                (click)="handleMatSelectClick()"
                                                                                (blur)="removeMatSelectScrollListener()"
                                                                                formControlName="insuredOccupation"
                                                                                [(value)]="cover.value.insuredOccupation"
                                                                                (selectionChange)="onselectOccupationOption($event.value,insuredData,k)">
                                                                                    <ng-container
                                                                                        *ngIf="insuredData.value.memberType == 'Adult'">
                                                                                        <ng-container
                                                                                            *ngFor="let occ of occupationList; let last = last ">
                                                                                            <mat-option
                                                                                                [value]="occ.value">
                                                                                                {{occ.value}}
                                                                                            </mat-option>
                                                                                            <mat-divider
                                                                                                *ngIf="!last"></mat-divider>
                                                                                        </ng-container>
                                                                                    </ng-container>
                                                                                    <!-- <ng-container
                                                                                        *ngIf="insuredData.value.memberType == 'Child'">
                                                                                        <mat-option [value]="'Student'"
                                                                                            selected>
                                                                                            {{"Student"}}
                                                                                        </mat-option>
                                                                                    </ng-container> -->
                                                                                </mat-select>
                                                                                <mat-error class="error-margin"
                                                                                    *ngIf="insuredForm.controls['insuredDetails']['controls'][i].controls['insuredAddOn'].controls[k].controls['insuredOccupation'].hasError('required') && isSubmit">
                                                                                    Occupation is required
                                                                                </mat-error>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="!this.utilityService.isEmptyOrNull(this.befitAddon) && this.befitAddon.length>0">
                                                            <!-- class="col-sm-4 customCheckslist" -->
                                                            <ng-container>
                                                                <div [ngClass]="'col-sm-4 customCheckslist' ">
                                                                    <mat-select class="input-group__input cstm_select"
                                                                        placeholder="Select Befit Plan"
                                                                        (click)="handleMatSelectClick()"
                                                                        (blur)="removeMatSelectScrollListener()"
                                                                        [(value)]="insuredBefit"
                                                                        (selectionChange)="onBefitSelectAddon(insuredData,$event.value)">
                                                                        <ng-container
                                                                            *ngFor="let cover of befitAddon; let last = last">
                                                                            <mat-option [value]="cover.CoverText">
                                                                                {{ cover.CoverText }}
                                                                            </mat-option>
                                                                        </ng-container>
                                                                    </mat-select>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                    <!-- addon condition for GS -->
                                                    <div class="row" style="display: flex; align-items: center;"
                                                        *ngIf="this.renewalFormData.SubProductType==40">
                                                        <ng-container
                                                            *ngFor="let cover of getAddonDetailsFormArray(i).controls; let k = index"
                                                            [formGroupName]="k">
                                                            <ng-container
                                                                *ngIf="![28,29].includes(cover.value.CoverId)">
                                                                <!-- class=" col-sm-4 customCheckslist" -->
                                                                <ng-container>
                                                                    <div [ngClass]="'col-sm-4 customCheckslist' ">
                                                                        <mat-checkbox class="cstmCheckbox"
                                                                            id="{{'cover'+i+k}}-{{cover.value.CoverText}}-yes"
                                                                            name="IsAddonSelected{{k}}"
                                                                            [checked]="cover.value['IsAddonSelected' + k]"
                                                                            [disabled]="cover.value.CoverId==30"
                                                                            formControlName="IsAddonSelected{{k}}"
                                                                            (change)="onClickSelectAddon(insuredData,k,cover,$event)">
                                                                            {{cover.value.CoverText}}
                                                                        </mat-checkbox>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                        <mat-divider></mat-divider>
                                                        <ng-container
                                                            *ngFor="let cover of getAddonDetailsFormArray(i).controls; let k = index"
                                                            [formGroupName]="k">
                                                            <ng-container *ngIf="[28].includes(cover.value.CoverId)">
                                                                <!-- class=" col-sm-4 customCheckslist" -->
                                                                <ng-container>
                                                                    <div [ngClass]="'col-sm-12 customCheckslist' ">
                                                                        <ul>
                                                                            <li class="col-sm-4 px-0">
                                                                                <mat-checkbox class="cstmCheckbox"
                                                                                    id="{{'cover'+i+k}}-{{cover.value.CoverText}}-yes"
                                                                                    name="IsAddonSelected{{k}}"
                                                                                    [checked]="cover.value['IsAddonSelected' + k]"
                                                                                    formControlName="IsAddonSelected{{k}}"
                                                                                    (change)="onClickSelectAddon(insuredData,k,cover,$event)">
                                                                                    {{cover.value.CoverText}}
                                                                                </mat-checkbox>
                                                                            </li>
                                                                            <li
                                                                                *ngIf="cover.value.CoverId==28 && cover.value['IsAddonSelected' + k]">
                                                                                <label class="input-group__label"
                                                                                    style="top: 13px;">Co-Payment</label>
                                                                                <mat-select
                                                                                    class="input-group__input cstm_select"
                                                                                    placeholder="Select co-payment"
                                                                                    id="coPayment"
                                                                                    formControlName="insuredCoPay"
                                                                                    [(value)]="cover.value.insuredCoPay"
                                                                                    (selectionChange)="onVoluntaryCopayChange($event.value)"
                                                                                    required>
                                                                                    <ng-container
                                                                                        *ngFor="let item of this.renewalFormData.CoPay; let last = last">
                                                                                        <mat-option [value]="item"
                                                                                            selected>
                                                                                            {{item}}
                                                                                        </mat-option>
                                                                                        <mat-divider
                                                                                            *ngIf="!last"></mat-divider>
                                                                                    </ng-container>

                                                                                </mat-select>
                                                                                <mat-error class="error-margin"
                                                                                    *ngIf="insuredForm.controls['insuredDetails']['controls'][i].controls['insuredAddOn'].controls[k].controls['insuredCoPay'].hasError('required') && isSubmit">
                                                                                    copay is required
                                                                                </mat-error>

                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                        <mat-divider></mat-divider>
                                                        <ng-container
                                                            *ngFor="let cover of getAddonDetailsFormArray(i).controls; let k = index"
                                                            [formGroupName]="k">

                                                            <ng-container *ngIf="[29].includes(cover.value.CoverId)">
                                                                <!-- class=" col-sm-4 customCheckslist" -->
                                                                <ng-container>
                                                                    <div [ngClass]="'col-sm-12 customCheckslist' ">
                                                                        <ul>
                                                                            <li class="col-sm-4 px-0">
                                                                                <mat-checkbox class="cstmCheckbox"
                                                                                    id="{{'cover'+i+k}}-{{cover.value.CoverText}}-yes"
                                                                                    name="IsAddonSelected{{k}}"
                                                                                    [checked]="cover.value['IsAddonSelected' + k]"
                                                                                    formControlName="IsAddonSelected{{k}}"
                                                                                    (change)="onClickSelectAddon(insuredData,k,cover,$event)">
                                                                                    {{cover.value.CoverText}}
                                                                                </mat-checkbox>
                                                                            </li>
                                                                            <li
                                                                                *ngIf="cover.value.CoverId == 29 && cover.value['IsAddonSelected' + k]">
                                                                                <label class="input-group__label">VD
                                                                                    Values</label>
                                                                                <input class="input-group__input"
                                                                                    matInput type="text" readonly
                                                                                    autocomplete="off"
                                                                                    formControlName="insuredVDValue">
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </mat-card>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </ng-container>
        </div>

        <!-- asp start declaration -->
        <div class="row" *ngIf="this.renewalFormData.SubProductType == 22 &&  tabData.memberDetails.length > 0">
            <h3>Good Health Declaration:</h3>
            <div class="fieldsdiv mb-4">
                <div class="row">
                    <div class="col-sm-12">
                        <td class="customCheckslist">
                            <ul>
                                <li>
                                    <mat-checkbox class="cstmCheckbox" [checked]="true"
                                        [disabled]="true"></mat-checkbox>
                                </li>
                            </ul>
                        </td>
                        <td>
                            <p>
                                I hereby declare that there has been no
                                change in my or any other insured's health
                                condition covered under the policy from
                                my/our first health insurance policy with
                                ICICI Lombard.
                            </p>
                        </td>
                    </div>
                </div>
            </div>
        </div>
        <!-- asp ends -->
        <div *ngIf="tabData.memberDetails.length <= 0">
            <button mat-raised-button class="secondarybtn_2 mr-1" type="button"
                (click)="onclickAddMember(tabData.tabLabel)">
                Add
            </button>
        </div>

    </mat-tab>
</mat-tab-group>