import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GenericService } from './generic.service';
import { DownloadResponsePayload, DownloadpdfRequestPayload, downloadPDFGenericRequestPayload } from '../interface/file';
import { Observable } from 'rxjs';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(private generic: GenericService) { }
  readonly baseURI = `${environment.baseURL}`;
  readonly downloadPDF = 'File/DownloadPDF';
  readonly downloadProposalPDF_ishield = 'IPRUIPS/ViewProposal';
  readonly downloadPolicyPDF_ishield = 'IPRUIPS/DownloadHAPPolicy';
  readonly downloadProposalPDF_CS = 'file/GetCritiProposalFormPDF';
  readonly downloadProposalPDF_FS = 'file/GetFSProposalFormPDF';
  readonly ipartnerURI = "https://cldilmobilapp01.insurancearticlez.com/mobileapi/";
  readonly uatBrochureURL = 'https://panacf.insurancearticlez.com/Brochures/';
  readonly prodBrochureURL = 'https://pana.icicilombard.com/Brochures/';
  readonly genericPDFDownload = 'File/DownloadPDFGeneric';
  readonly quotePDF = 'File/DownloadQuotePDF'

  downloadPDFGeneric(data: downloadPDFGenericRequestPayload): Observable<any> {
    const endpoint = `${this.baseURI}${this.genericPDFDownload}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  downloadBrochure(prdId): Observable<Blob> {
    let url = '';
    if (environment.sanity || environment.uat) {
      url = this.uatBrochureURL + prdId;
    } else {
      url = this.prodBrochureURL + prdId;
    }
    return this.generic.downloadFile(url);
  }

  downloadProposal(data) {
    let endPoint; let body = {};
    // if (data.policySubType == 23 || data.policySubType == 38) {
    //   endPoint = data.policySubType == 23 ? this.downloadProposalPDF_FS : this.downloadProposalPDF_CS;
    //   body = { PolicyId: data.policyId }
    // } else {
    endPoint = data.policySubType == 44 ? this.downloadProposalPDF_ishield : this.downloadPDF;
    body = data.policySubType == 44 ? { BundleID: data.bundleId } :
      { type: 'PROPOSAL', value: Number.isInteger(data.policyId) ? data.policyId : data.policyId.trim() }
    // }
    return { endPoint: endPoint, body: body, policyId: data.policyID }
  }

  downloadPolicy(data,) {
    let body = {}; let endPoint = '';
    // if (data.policySubType == 23 || data.policySubType == 38) {
    //   body = { PolicyId: data.policyId }
    // } else {
    endPoint = data.policySubType == 44 ? this.downloadPolicyPDF_ishield : this.downloadPDF;
    body = data.policySubType == 44 ? { hadvPolicyID: data.policyId } :
      // { type: data.policyType, value: Number.isInteger(data.policyId) ? data.policyId : data.policyId.trim() }
      { type: data.policyType, value: data.policyId.trim() }
    // }
    return { endPoint: endPoint, body: body, policyId: data.policyID }
  }

  downloadPDfRequest(data: DownloadpdfRequestPayload): Observable<DownloadResponsePayload> {
    const endpoint = `${this.baseURI}${this.downloadPDF}`;
    return this.generic.genericService<DownloadResponsePayload>(endpoint, data);
  }

  generatePDF(endPoint, body): Observable<DownloadResponsePayload> {
    const endpoint = `${this.baseURI}${endPoint}`;
    return this.generic.genericService<DownloadResponsePayload>(endpoint, body);
  }

  downloadQuotePDF(data: any): Observable<any> {
    const endpoint = `${this.baseURI}${this.quotePDF}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  // downloadPdf(base64String:string, fileName: string) {
  //   // Create a data URL for the base64 string
  //   const dataUrl = `data:application/pdf;base64,${base64String}`;

  //   // Create a download link
  //   const link = document.createElement('a');
  //   link.href = dataUrl;
  //   link.download = fileName;

  //   // Append the link to the body and trigger the click event
  //   document.body.appendChild(link);
  //   link.click();

  //   // Clean up after a short delay
  //   setTimeout(() => {
  //     document.body.removeChild(link);
  //   }, 100);
  // }

  //I have changed downloadPDF & base64ToUint8Array, for reference.
  downloadPdf(base64String: string, fileName: string): void {
    const dataUrl = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement('a');

    // Check if the browser supports the Blob constructor
    const blobSupported = typeof Blob !== 'undefined';

    // Check if the browser is Safari or Edge
    const isSafariOrEdge = navigator.userAgent.match(/Version\/[\d\.]+.*Safari/) || navigator.userAgent.match(/Edg/);

    if (!isSafariOrEdge || !blobSupported) {
      // For non-Safari/Edge browsers or when Blob is not supported, proceed as usual
      link.addEventListener('click', () => {
        // Clean up after a short delay
        setTimeout(() => {
          document.body.removeChild(link);
        }, 100);
      });
      link.href = dataUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
    } else {
      // Safari or Edge, use Blob-based approach
      const blob = new Blob([this.base64ToUint8Array(base64String)], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      link.addEventListener('click', () => {
        // Revoke the object URL after a short delay
        setTimeout(() => {
          URL.revokeObjectURL(url);
        }, 100);
      });

      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
    }
  }


  webDownload(base64String: string, fileName: string): void {
    const dataUrl = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement('a');

    // Check if the browser supports the Blob constructor
    const blobSupported = typeof Blob !== 'undefined';

    // Check if the browser is Safari or Edge
    const isSafariOrEdge = navigator.userAgent.match(/Version\/[\d\.]+.*Safari/) || navigator.userAgent.match(/Edg/);

    if (!isSafariOrEdge || !blobSupported) {
      // For non-Safari/Edge browsers or when Blob is not supported, proceed as usual
      link.addEventListener('click', () => {
        // Clean up after a short delay
        setTimeout(() => {
          document.body.removeChild(link);
        }, 100);
      });
      link.href = dataUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
    } else {
      // Safari or Edge, use Blob-based approach
      const blob = new Blob([this.base64ToUint8Array(base64String)], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      link.addEventListener('click', () => {
        // Revoke the object URL after a short delay
        setTimeout(() => {
          URL.revokeObjectURL(url);
        }, 100);
      });

      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
    }
  }

  //I have changed downloadPDF & base64ToUint8Array, for reference.
  // Helper function to convert base64 string to Uint8Array
  // private base64ToUint8Array(base64String: string): Uint8Array {
  //   const padding = '='.repeat((4 - base64String.length % 4) % 4);
  //   const base64 = (base64String + padding)
  //     .replace(/\-/g, '+')
  //     .replace(/_/g, '/');

  //   const rawData = window.atob(base64);
  //   const outputArray = new Uint8Array(rawData.length);

  //   for (let i = 0; i < rawData.length; ++i) {
  //     outputArray[i] = rawData.charCodeAt(i);
  //   }
  //   return outputArray;
  // }

  private base64ToUint8Array(base64String: string): Uint8Array {
    const normalizedBase64 = base64String.replace(/\s/g, ''); // Remove line breaks and other whitespace
    const rawData = window.atob(normalizedBase64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
  }

  // downloadPdf(base64String: string, fileName: string) {
  //   // Convert the base64 string to a Blob
  //   const byteCharacters = atob(base64String);
  //   const byteNumbers = new Array(byteCharacters.length);

  //   for (let i = 0; i < byteCharacters.length; i++) {
  //     byteNumbers[i] = byteCharacters.charCodeAt(i);
  //   }

  //   const byteArray = new Uint8Array(byteNumbers);
  //   const blob = new Blob([byteArray], { type: 'application/pdf' });

  //   // Use FileSaver.js to save the Blob as a file
  //   saveAs(blob, fileName);
  // }


  // const link = document.createElement('a');
  //   link.href = base64Data;
  //   link.download = filename;
  //   document.body.appendChild(link);
  //   console.log(link);
  //   link.click();
  //   document.body.removeChild(link);
}

