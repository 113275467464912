import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GenericService } from '../services/generic.service';
import { validateUserResoposePayload } from '../interface/calculate-elevate';
@Injectable({
  providedIn: 'root'
})
export class OfflineCalculateElevateService {

  constructor(private generic: GenericService) {

  }
  readonly baseURI = `${environment.baseURL}`;
  readonly validateelevateuser = 'Utility/ValidateElevateUser';


  getValidUserData(data:any): Observable<any> {
    const endpoint = `${this.baseURI}${this.validateelevateuser}`;
    return this.generic.genericService<validateUserResoposePayload>(endpoint, data);
  }
}