import { environment } from "src/environments/environment";

export const env = {
    production: false,
    firebase: {
        apiKey: "AIzaSyB5Yo2FubYBSzYQtnvuqsnSIea6kXZcj58",
        authDomain: "notificationsystem-9f542.firebaseapp.com",
        projectId: "notificationsystem-9f542",
        storageBucket: "notificationsystem-9f542.appspot.com",
        messagingSenderId: "590012640990",
        appId: "1:590012640990:web:d661c4fbb4276b0b13dc4f",
        measurementId: "G-M81S96FP8L",
        vapidKey: "BN8u0jV5aTODz3eHk-qcGTzta6J70KZvVFOHs0cRILjtcCOKK5vaFRZABTdOohsDRGoI3ifQ4o8XuGqLdprnxkY"
    },
    baseUrl: environment.environment != 'sanity' ? 'https://nysa.icicilombard.com/plutusapp/api/' : 'https://plutus.insurancearticlez.com/plutusapp/api/'
};
